import React from 'react';
import Constants from 'Constants';
import { SignerEntity } from 'types/Signer';
import Analytics from 'Common/Analytics';

export type Props = {
    signer: SignerEntity;
    file: any;
};

// @todo: Allow customizing the event name as a prop to be able to know when an attachment
// was downloaded in the context of KYC details
const trackDownload = (fileId: number, signerId: number) => {
    Analytics.track('casefile - signer attachment downloaded', {
        fileId,
        signerId,
    });
};

const CasefileAttachment: React.FunctionComponent<Props> = ({
    signer,
    file,
}): JSX.Element => (
    <a
        onClick={trackDownload.bind(null, file.id, signer.id)}
        href={`${Constants.PENNEO_ORIGIN}/signer/${signer.signingRequest.id}/file/download/${file.id}`}>
        <div className="signer-details-file">
            <div className="signer-details-file-container">
                <div className="signer-details-file-hover-overlay">
                    <div className="signer-details-file-hover-overlay-content">
                        <i className="far fa-file-download" />
                    </div>
                </div>
                <div className="signer-details-file-title">{file.title}</div>
            </div>
        </div>
    </a>
);

export default CasefileAttachment;
