import uniqid from 'uniqid';
import axios, { ResponseType } from 'axios';
import { Dispatcher } from 'Core';
import { env } from 'Constants';
import Constants from '../Constants';
import { SigningAPI } from 'Api';
import lodash from 'lodash';
import {
    deleteFile,
    temporaryPDFDownload,
    temporaryPDFUpload,
    temporaryPDFUploadMultipart,
} from 'Casefiles/utils/file';
import {
    UploadResult,
    UploadStatus,
} from 'Casefiles/components/casefiles2/types';
import CaseFileActions from './CasefilesV2ActionCreators';
import analytics from 'Common/Analytics';
import {
    getUploadError,
    getUploadPDFErrorDescription,
} from 'Casefiles/components/casefiles2/utils';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';

const documentActionCreators = {
    _cancelTokens: new Map(),
    // *************************************************************************
    // GET REQUESTS
    // *************************************************************************
    fetchDocumentContent(documentId, documentName) {
        let query = {
            signed: false,
        };

        let options = {
            responseType: 'blob' as ResponseType,
            headers: {
                Accept: 'application/pdf',
            },
        };

        return SigningAPI.get(
            `/documents/${documentId}/content`,
            query,
            options
        )
            .then((blob) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.PDF_BLOB_LOADED,
                    blob: blob,
                    name: documentName,
                });
            })
            .catch((error) => {
                console.log('Document ERROR: ', error);
            });
    },

    fetchDocumentPagesImages(documentId, limit = 1) {
        let query = {
            limit: limit,
        };

        return SigningAPI.get(
            `/documents/${documentId}/images`,
            query
        ).catch(() =>
            console.log('Failed to fetch images of the document pages')
        );
    },

    // *************************************************************************
    // USER INPUT ACTIONS
    // *************************************************************************
    setDocuments(documents, resetDefaults = true) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.DOCUMENTS_CHANGED,
            resetDefaults: resetDefaults,
            documents: documents,
        });
    },

    loadFiles(files) {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.LOCAL_FILES_LOADED,
            files: files,
        });
    },

    addDocument(doc) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.DOCUMENT_ADDED,
            doc: doc,
        });
    },

    onDocumentUploadProcess(docId, percent) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.DOCUMENT_UPLOAD_PROCESS,
            docId,
            percent,
        });
    },

    editDocument(docIndex, payload) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.DOCUMENT_EDITED,
            index: docIndex,
            payload: payload,
        });
    },

    convertToUploadedDocument(tempId, newId, fileContent) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.DOCUMENT_UPLOADED,
            tempId,
            newId,
            fileContent,
        });
    },

    updateDocumentRole(docIndex, payload) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.DOCUMENT_ROLE_UPDATED,
            index: docIndex,
            payload: payload,
        });
    },

    // @deprecated:
    // This updates the option of the first document type
    // that has options.
    //
    // This option is deprecated and is only used for setting
    // the number of board members that will be able to sign a document
    updateDocumentOptions(documentTypeId, value) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.DOCUMENT_OPTIONS_CHANGED,
            documentTypeId: documentTypeId,
            value: value,
        });
    },

    updateAvailableDocumentTypes(documentTypes, resetDefaults = true) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.AVAILABLE_DOCUMENT_TYPES_UPDATED,
            documentTypes: documentTypes,
            resetDefaults: resetDefaults,
        });
    },

    reorderDocument(currentIndex, newIndex) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.DOCUMENT_REORDER,
            index: currentIndex,
            newIndex: newIndex,
        });
    },

    async clearStore() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CLEAR_DOCUMENT_STORE,
        });
    },

    setBoardSignCountOnDocuments(
        shouldBeEnabled: boolean,
        count: number,
        role: string
    ) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SET_CONDITIONAL_SIGNING_COUNT,
            shouldBeEnabled,
            count,
            role,
        });
    },

    // *************************************************************************
    // URI loaded document data
    // *************************************************************************
    async URIUpdateDocumentStore(documentsData) {
        if (!documentsData) {
            return;
        }

        let _documents = documentsData.map((doc) => {
            let newDocument = {
                name: doc.name,
                order: doc.order,
                documentTypeId: doc.typeId,
                localPath: doc.localPath,
                metaData: doc.metaData,
            };

            return lodash.omitBy(newDocument, lodash.isUndefined);
        });

        if (env.electron) {
            const {
                DOCUMENT_FILE_REPLY,
                DOCUMENT_FILE_REQUEST,
            } = Constants.ipcEvents;
            const { ipcRenderer } = env.electron as any;

            ipcRenderer.once(DOCUMENT_FILE_REPLY, (event, data) => {
                this.loadFiles(data);
            });

            ipcRenderer.send(DOCUMENT_FILE_REQUEST, _documents);
        }
    },

    populateStore(data) {
        if (!data) {
            return;
        }

        const _documents = data.map((doc) => {
            const newDocument = {
                id: doc.id,
                caseFileId: doc.caseFileId,
                name: doc.name,
                order: doc.order,
                documentTypeId: doc.typeId,
                localPath: doc.localPath,
                metaData: doc.metaData,
                status: doc.status,
                opts: doc.opts,
                format: doc.format,
                fileSize: doc.fileSize,
            };

            return lodash.omitBy(newDocument, lodash.isUndefined);
        });

        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.FETCH_DOCUMENTS_SUCCESS,
            documents: _documents,
        });
    },

    async _uploadTemporaryPDF(doc, cancelToken) {
        const startTime = Date.now();

        try {
            const [uploadedPdf] = await temporaryPDFUpload(doc, cancelToken);
            const uploadTime = (Date.now() - startTime) / 1000;

            analytics.track('casefile - pdf uploaded - success', {
                fileSize: Number((doc.file.size / 1000000).toFixed(3)),
                uploadTime,
            });

            return uploadedPdf.id;
        } catch (error) {
            if (error.cancel) {
                throw error;
            }

            const uploadError = getUploadError(error);

            const errorDescription = getUploadPDFErrorDescription(uploadError);

            analytics.track('casefile - pdf uploaded - error', {
                uploadTime: (Date.now() - startTime) / 1000,
                fileSize: Number((doc.file.size / 1000000).toFixed(3)),
                errorDescription,
            });

            throw uploadError;
        }
    },

    async _multipartUploadDocument(doc, cancelToken) {
        const startTime = Date.now();

        try {
            const onUploadProgress = (progressEvent: ProgressEvent) => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );

                this.onDocumentUploadProcess(doc._id, percentage);
            };

            const [uploadedDocument] = await temporaryPDFUploadMultipart(
                doc,
                onUploadProgress,
                cancelToken
            );
            const uploadTime = (Date.now() - startTime) / 1000;

            analytics.track('casefile - pdf uploaded - success', {
                fileSize: Number((doc.file.size / 1000000).toFixed(3)),
                uploadTime,
            });

            return uploadedDocument.id;
        } catch (error) {
            if (error.cancel) {
                throw error;
            }

            const uploadError = getUploadError(error);

            const errorDescription = getUploadPDFErrorDescription(uploadError);

            analytics.track('casefile - pdf uploaded - error', {
                uploadTime: (Date.now() - startTime) / 1000,
                fileSize: Number((doc.file.size / 1000000).toFixed(3)),
                errorDescription,
            });

            throw uploadError;
        }
    },

    async uploadDocument(
        doc,
        index?,
        casefileId?,
        currentDocuments?,
        uploadId?,
        errorHandler?
    ): Promise<UploadResult> {
        const isMultipartUploadEnabled = LaunchDarkly.variation(
            Flags.ENABLE_NEW_UPLOAD_ENDPOINT
        );

        const tempId = uniqid();
        const cancelTokenSource = axios.CancelToken.source();
        const documentId = uploadId || tempId;

        doc._id = documentId;
        this._cancelTokens.set(documentId, cancelTokenSource);

        // Add document early to the store.
        // In this way document is already visible in the UI but as loading
        this.addDocument(doc);

        try {
            const uploadedDocument = isMultipartUploadEnabled
                ? await this._multipartUploadDocument(
                      doc,
                      cancelTokenSource.token
                  )
                : await this._uploadTemporaryPDF(doc, cancelTokenSource.token);

            // If casefileId is provided, add the document to the draft
            if (casefileId) {
                await CaseFileActions.addDocumentToDraft(
                    casefileId,
                    doc,
                    index,
                    uploadedDocument,
                    currentDocuments
                );
            } else {
                // Update document that has been added early to the table
                // Pre-download the document file
                // Content (binary) of the processed file needs to be fetched
                const fileContent = await temporaryPDFDownload(
                    uploadedDocument
                );

                this.convertToUploadedDocument(
                    doc._id,
                    uploadedDocument,
                    fileContent
                );
            }

            return { success: true, documentId: uploadedDocument };
        } catch (error) {
            errorHandler && errorHandler(doc);

            if (error.cancel) {
                return { success: false, cancelled: true };
            }

            // If the error is already in the correct format (from _uploadTemporaryPDF), use it directly
            if (error.status === 'error') {
                return { success: false, error };
            }

            const uploadError = getUploadError(error);

            return { success: false, error: uploadError };
        } finally {
            this._cancelTokens.delete(documentId);
        }
    },

    cancelUpload(docId) {
        const cancelTokenSource = this._cancelTokens.get(docId);

        if (cancelTokenSource) {
            cancelTokenSource.cancel('Upload cancelled by user');
        }
    },

    async removeDocument(docId, deleteUploaded = true) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.DOCUMENT_REMOVED,
            docId,
        });
        this.removeUploadStatus(docId);

        if (deleteUploaded) {
            await deleteFile(docId);
        }
    },

    // used for desktop app only, different ui
    async removeDocumentByIndex(index) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.DOCUMENT_REMOVED_BY_INDEX,
            index,
        });
    },

    removeNotUploadedDocuments() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.NOT_UPLOADED_DOCUMENTS_REMOVED,
        });
        this.clearUploadStatuses();
    },

    abortDocument(docId) {
        this.cancelUpload(docId);
        this.removeDocument(docId, false);
    },

    async abortAllDocuments() {
        this._cancelTokens.forEach((cancelTokenSource) => {
            cancelTokenSource.cancel('Upload cancelled by user');
        });
        this.removeNotUploadedDocuments();
    },

    async uploadDocuments(documents) {
        const documentsFailed: any[] = [];

        await Promise.all(
            documents.map(async (doc) => {
                await this.uploadDocument(doc, (failed) => {
                    documentsFailed.push(failed);
                });
            })
        );

        return documentsFailed;
    },

    setUploadStatus(docId: string, status: UploadStatus) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SET_UPLOAD_STATUS,
            docId,
            status,
        });
    },

    clearUploadStatuses() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CLEAR_UPLOAD_STATUSES,
        });
    },

    removeUploadStatus(docId: string) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.REMOVE_UPLOAD_STATUS,
            docId,
        });
    },
};

export default documentActionCreators;
