import keyMirror from 'keymirror';

const _prefix = 'TEAMS';
let Actions = keyMirror({
    FETCH_TEAMS_REQUEST: null,
    FETCH_TEAMS_SUCCESS: null,
    FETCH_TEAMS_FAILURE: null,

    FETCH_TEAM_REQUEST: null,
    FETCH_TEAM_SUCCESS: null,
    FETCH_TEAM_FAILURE: null,

    FETCH_USERS_REQUEST: null,
    FETCH_USERS_SUCCESS: null,
    FETCH_USERS_FAILURE: null,

    CREATE_TEAM_REQUEST: null,
    CREATE_TEAM_SUCCESS: null,
    CREATE_TEAM_FAILURE: null,

    UPDATE_TEAM_REQUEST: null,
    UPDATE_TEAM_SUCCESS: null,
    UPDATE_TEAM_FAILURE: null,

    DELETE_TEAM_REQUEST: null,
    DELETE_TEAM_SUCCESS: null,
    DELETE_TEAM_FAILURE: null,

    CLEAR_STORE: null,
    CLEAR_ERRORS: null,
});

// Add Prefix to actions
function addPrefix(actions, prefix) {
    for (let key in actions) {
        if (!actions.hasOwnProperty(key)) {
            continue;
        }

        actions[key] = `${prefix}_${actions[key]}`;
    }

    return actions;
}

let TeamActions = addPrefix(Actions, _prefix);

export default TeamActions;
