import React from 'react';
import { modal } from 'Common/components/Common/Modal';
import { notify } from 'react-notify-toast';
import { i18n } from 'Language';
import CasefileTemplateSelector from './CasefileTemplateSelector';

import DocumentActions from '../../actions/DocumentActionCreators';
import CasefileActions from '../../actions/CasefilesActionCreators';
import SignerActions from '../../actions/SignerActionCreators';
import URIActions from '../../actions/URIActionCreators';

import URIStore from '../../stores/URIStore';
import Loading from 'Common/components/Loaders/LoadingData';

export default class CasefileTemplateSelectorModal extends React.Component {
    state = {
        open: false,
        fetching: false,
        deleting: false,
        templates: null,
    };

    componentDidMount() {
        URIStore.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        URIStore.removeChangeListener(this.onChange);
    }

    onChange = () => {
        this.setState(URIStore.getCasefileTemplateState(), () => {
            // Refresh modal
            this.refreshModal();
        });
    };

    loadData = () => {
        URIActions.fetchCasefileTemplates();
    };

    deleteTemplate = (key, event) => {
        event.stopPropagation();
        URIActions.deleteCasefileTemplate(key);
    };

    clearStores = () => {
        CasefileActions.clearStore();
        SignerActions.clearStore();
        DocumentActions.clearStore();
    };

    casefileTemplateList = () => {
        let { templates, fetching, deleting } = this.state;

        // Before any templates have loaded
        if (!templates) {
            return <Loading />;
        }

        // Render template list
        return (
            <div className="template-selector-modal">
                <div className="casefile-selector-container">
                    <CasefileTemplateSelector
                        deleting={deleting}
                        fetching={fetching}
                        templates={templates}
                    />
                </div>
            </div>
        );
    };

    refreshModal = () => {
        if (!this.state.open) {
            return false;
        }

        // Close modal after success
        if (this.state.success) {
            notify.show(
                <span>
                    {i18n`Template imported`} <i className="fa fa-check" />
                </span>,
                'success',
                2000
            );

            return modal.hide();
        }

        modal.show(this.getModalContent());
    };

    closeModal = () => {
        this.setState({ open: false });
        URIActions.resetCasefileTemplates();
    };

    getModalContent = () => {
        return {
            className: 'casefile-template-selector', // needed for scss namespacing
            title: null,
            body: this.casefileTemplateList(),
            onClose: this.closeModal,
        };
    };

    openTemplateSelector = () => {
        this.setState(
            {
                open: true,
            },
            () => {
                let config = this.getModalContent();

                modal.show(config); // Open modal
            }
        );

        this.loadData();
    };

    render() {
        return (
            <li className="advanced-option" onClick={this.openTemplateSelector}>
                <i className="fa fa-list-ul" />
                {i18n('Select template')}
            </li>
        );
    }
}
