import { i18n } from 'Language';
import React, { useEffect, useState } from 'react';

import { SigningDocument } from 'types/SigningProcess';

import './signing-role.scss';
import { trackSigning } from './utils';

type Props = {
    documents: SigningDocument[];
};

export const SigningRole = ({ documents }: Props) => {
    const [role, setRole] = useState<string>();

    useEffect(() => {
        const docWithRole = documents.find((doc) => doc.signatureLines?.length);

        if (docWithRole) {
            const roleName = docWithRole.signatureLines?.find(
                (sign) => sign.role
            )?.role;

            setRole(roleName);
        }
    }, [documents]);

    return role ? (
        <div
            className="signing-role"
            onClick={() => trackSigning('Signing role click')}>
            <span className="fa-stack">
                <i className="far fa-user"></i>
            </span>
            <span className="signing-as">{i18n`Role`}</span>
            {role}
        </div>
    ) : null;
};
