import React from 'react';
import { AppDispatch } from 'Store';
import { connect } from 'react-redux';
import { TextInput } from 'Common/components';
import { i18n } from 'Language';
import { createFolder, updateCaseFilesItemTitle } from '../redux/reducer';
import { CaseFileItem } from '../Archive';
import './item-modal.scss';
import Analytics from 'Common/Analytics';
import Button from 'Common/components/Button';

type Props = {
    dispatch: AppDispatch & any;
    inputLabel: string;
    closeModal: () => any;
    parentId?: number;
    item?: CaseFileItem;
};

type State = {
    title: string;
};

export class ItemModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { item } = this.props;

        this.state = {
            title: item ? item.title : '',
        };
    }

    handleOnaChange = (title: string) => this.setState({ title });

    handleOnCreateUpdateItem = (event) => {
        event.preventDefault();

        const { title } = this.state;

        this.props.closeModal();

        if (this.props.item) {
            if (this.props.item.type === 'folder') {
                Analytics.track('archive - rename folder', {
                    id: this.props.item.id,
                });
            } else {
                Analytics.track('archive - rename case', {
                    id: this.props.item.id,
                    status: this.props.item.status,
                });
            }

            return this.props.dispatch(
                updateCaseFilesItemTitle(this.props.item, title)
            );
        }

        let eventProperties: any = {};

        if (this.props.parentId) {
            eventProperties.parentId = Number(this.props.parentId);
        }

        Analytics.track('archive - create folder', eventProperties);

        this.props.dispatch(createFolder(title, this.props.parentId));
    };

    handleOnCancel = () => this.props.closeModal();

    render() {
        return (
            <div className="case-files-item-modal">
                <form onSubmit={this.handleOnCreateUpdateItem}>
                    <TextInput
                        label={this.props.inputLabel}
                        value={this.state.title}
                        onChange={this.handleOnaChange}
                        autoFocus
                    />
                    <Button
                        className="default"
                        variant="text"
                        type="button"
                        onClick={this.handleOnCancel}>
                        {i18n('Cancel')}
                    </Button>
                    <Button
                        theme="blue"
                        className="primary pull-right"
                        type="submit"
                        onClick={this.handleOnCreateUpdateItem}>
                        {!!this.props.item ? i18n('Update') : i18n('Create')}
                    </Button>
                </form>
            </div>
        );
    }
}

export default connect()(ItemModal);
