import PropTypes from 'prop-types';
import React from 'react';
import BankIDActions from '../../actions/BankIDNOServerActions';
import ViewActions from '../../actions/BankIDNOViewActions';
import BankIDStore from '../../stores/BankIDNOStore';
import BankIDWidget from './BankIDWidget';
import { Link } from 'react-router';
import { TranslationStore, i18n } from 'Language';

import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import { BANKID_NORWAY_EIDENT } from 'EID/Constants';
import OpenIDActions from '../../actions/OpenIDActions';
import { Intent } from 'OpenID/Constants';

export default class AddContainer extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    static propTypes = {
        location: PropTypes.object,
        redirect: PropTypes.func,
        children: PropTypes.object,
    };

    state = {
        params: null,
        isFetching: null,
        error: null,
        language: TranslationStore.getLanguage(),
    };

    timeout = null;

    componentDidMount() {
        if (launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED)) {
            this.timeout = setTimeout(() => {
                OpenIDActions.init(BANKID_NORWAY_EIDENT.type, Intent.ADD);
            }, 1000);

            return;
        }

        BankIDStore.addChangeListener(this.onChange);
        BankIDStore.addEventListener(
            'on-credential-added',
            this.onCredentialAdded
        );
        TranslationStore.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        BankIDStore.removeChangeListener(this.onChange);
        BankIDStore.removeEventListener(
            'on-credential-added',
            this.onCredentialAdded
        );
        TranslationStore.removeChangeListener(this.onChange);
    }

    componentDidUpdate(prevProps, prevState) {
        // Reset the widget if language is changed
        if (prevState.language !== this.state.language) {
            this.retry();
        }
    }

    onChange = () => {
        this.setState({
            params: BankIDStore.getParams(),
            isFetching: BankIDStore.isFetching(),
            error: BankIDStore.getError(),
            language: TranslationStore.getLanguage(),
        });
    };

    onCredentialAdded = () => {
        let { router } = this.context;

        router.push({
            name: 'bankid-no-credential-list',
        });
    };

    onSuccess = (signatureRef) => {
        BankIDActions.addCredentials(signatureRef);
    };

    init = (languageCode = TranslationStore.getLanguage()) => {
        BankIDActions.initLogin(languageCode);
    };

    retry = () => {
        ViewActions.retry();
        this.init();
    };

    updateError = (error) => {
        this.setState({ error: error });
    };

    render() {
        let { params, error, isFetching } = this.state;

        return (
            <div>
                <Link
                    className="react-modal-back"
                    to="account-auth-methods-providers">
                    <span className="fa fa-chevron-left" />
                </Link>
                <div className="react-modal-header-title">
                    {i18n('Add New BankID')}
                </div>
                <br />

                <div
                    className="bankid-reset-container"
                    style={{
                        maxWidth: '480px',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                    }}>
                    <div className="auth-method-password">
                        {launchDarkly.variation(
                            Flags.BANKID_NORWAY_EIDENT_ENABLED
                        ) ? (
                            <div className="flex flex-col text-center justify-center content-center">
                                <div className="auth-logo">
                                    <img
                                        height="120"
                                        width="120"
                                        src={BANKID_NORWAY_EIDENT.logo}
                                    />
                                </div>
                                <p>{i18n(`Redirecting...`)}</p>
                            </div>
                        ) : (
                            <BankIDWidget
                                params={params}
                                error={error}
                                retry={this.retry}
                                isFetching={isFetching}
                                init={this.init}
                                onSuccess={this.onSuccess}
                                onFailure={this.onFailure}
                                updateError={this.updateError}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
