import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import { LOGIN_METHODS, findByType } from 'EID';
import { modal } from 'Common/components/Common/Modal';
import moment from 'moment';
import AuthMethodsActions from '../../actions/AuthMethodsActions';
import Button from 'Common/components/Button';
import { AuthProvider } from 'Auth/components/CredentialsManagement/AuthMethodsList';

type Props = {
    providers: AuthProvider[];
    configuredMethodsCount: number;
};

export default class AuthProvidersTable extends React.Component<Props> {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    _formatDate = (date: string) => {
        return moment.utc(date).local().format('YYYY-MM-DD H:mm');
    };

    _formatTitle = (title: string) => {
        const method = findByType(LOGIN_METHODS, title);

        return method ? method.title : 'Unknown';
    };

    _promptDelete = (
        credential: AuthProvider,
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        event.preventDefault();

        const provider = this._formatTitle(credential.provider);
        const config = {
            title: i18n`Are you sure?`,
            body: (
                <p>
                    {i18n`If you remove your ${(
                        <b>{i18n(provider)}</b>
                    )} account,
                    you won’t be able to use it to log in to Penneo again.`}
                </p>
            ),
            buttons: (
                <div>
                    <Button onClick={modal.hide}>{i18n`No, cancel`}</Button>
                    <Button
                        theme="red"
                        onClick={this.handleDelete.bind(this, credential.id)}>
                        {i18n`Yes, Remove`}
                    </Button>
                </div>
            ),
            preventClose: true,
        };

        modal.show(config);
    };

    handleDelete = (
        credentialId: number,
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();

        AuthMethodsActions.delete(credentialId);

        modal.hide();
    };

    render() {
        const headers = ['Type', 'Name', 'Date Added', 'Actions'];
        const { providers, configuredMethodsCount } = this.props;

        return (
            <table className="table mt">
                <thead>
                    <tr>
                        {headers.map((header, key) => (
                            <th key={key}>{i18n(header)}</th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {providers.length === 0 && (
                        <tr>
                            <td colSpan={6} className="text-center">
                                <h3>
                                    {i18n`You haven't added any authentication
                                        provider to your account`}
                                </h3>
                            </td>
                        </tr>
                    )}
                    {providers.map((provider, key) => (
                        <tr key={key}>
                            <td>
                                {i18n(this._formatTitle(provider.provider))}
                            </td>
                            <td>
                                {provider.name || provider.serialNumber || '-'}
                            </td>
                            <td>{this._formatDate(provider.createdAt)}</td>
                            <td>
                                {configuredMethodsCount === 1 ? (
                                    <span>--</span>
                                ) : (
                                    <a
                                        onClick={this._promptDelete.bind(
                                            this,
                                            provider
                                        )}
                                        className="text-error">
                                        {i18n`Remove`}
                                        &nbsp;
                                        <i className="fa fa-times"></i>
                                    </a>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}
