import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import Button from 'Common/components/Button';
import { i18n } from 'Language';
import { SigningDocument } from 'types/SigningProcess';
import './signing-footer.scss';

import { isMobile } from 'utils';

type Props = {
    currentDocId: number;
    uploadedDocuments: SigningDocument[];
    changeDocument: (id: number) => void;
};

export const SignFinalizedFooter = ({
    currentDocId,
    uploadedDocuments,
    changeDocument,
}: Props) => {
    const findDocIndex = useCallback(
        () =>
            uploadedDocuments?.findIndex((doc) => doc.id === currentDocId) ??
            -1,
        [currentDocId, uploadedDocuments]
    );

    const [index, setIndex] = useState<number>(findDocIndex());

    useEffect(() => {
        setIndex(findDocIndex());
    }, [currentDocId, findDocIndex]);

    if (!uploadedDocuments || !uploadedDocuments?.length) {
        return null;
    }

    const handleOnPrev = () => {
        changeDocument(uploadedDocuments[index - 1].id);
    };

    const handleOnNext = () => {
        changeDocument(uploadedDocuments[index + 1].id);
    };

    const isFirst = index === 0;
    const isLast = index === uploadedDocuments.length - 1;

    /**
     * Translate 'next' and 'sign' buttons depending on user
     * being on mobile or not
     */
    const nextButtonLabel: string = isMobile()
        ? i18n`Next (${index + 1} of ${uploadedDocuments.length})`
        : i18n`Next document (${index + 1} of ${uploadedDocuments.length})`;

    const hasMultipleDocuments = uploadedDocuments.length > 1;

    return (
        <footer className="signing-footer">
            <div
                className={classnames('signing-footer-content', {
                    'has-multiple-documents': hasMultipleDocuments,
                })}
                data-testid="signing-buttons">
                {hasMultipleDocuments && (
                    <Button
                        className="prev"
                        variant="text"
                        theme="gray"
                        disabled={isFirst}
                        onClick={handleOnPrev}>{i18n`Back`}</Button>
                )}
                <div>
                    {uploadedDocuments.length > 1 && !isLast && (
                        <Button
                            variant="solid"
                            theme="green"
                            onClick={handleOnNext}>
                            {nextButtonLabel}
                        </Button>
                    )}
                </div>
            </div>
        </footer>
    );
};

export default SignFinalizedFooter;
