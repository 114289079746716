import { createComponent } from '@lit/react';
import { Dropdown } from '@penneo/vega-ui';
import React from 'react';

const UIDropdown = createComponent({
    tagName: 'vega-dropdown',
    elementClass: Dropdown,
    react: React,
    displayName: 'VegaDropdown',
});

export default UIDropdown;
