import createReducer from 'create-reducer';
import { Folders, FolderState } from './types';

// Action Types
export const FOLDER_FETCH_REQUEST = 'FOLDER_FETCH_REQUEST';
export const FOLDER_FETCH_SUCCESS = 'FOLDER_FETCH_SUCCESS';
export const FOLDER_CREATE = 'FOLDER_CREATE';
export const FOLDER_UPDATE = 'FOLDER_UPDATE';
export const FOLDER_DELETE = 'FOLDER_DELETE';
export const FOLDER_MOVE = 'FOLDER_MOVE';
export const FOLDER_SET_DEFAULT = 'FOLDER_SET_DEFAULT';

// Initial state
const folderInitialState: FolderState = {
    items: [] as Folders,
    isFetching: false,
    isLoaded: false,
    error: null,
} as FolderState;

// Reducer

export const folderReducer = createReducer<FolderState>(folderInitialState, {
    [FOLDER_FETCH_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        isLoaded: false,
        error: null,
    }),
    [FOLDER_FETCH_SUCCESS]: (state, { items, defaultFolder }) => ({
        ...state,
        items,
        isFetching: false,
        isLoaded: true,
        defaultFolder,
    }),
});

export default folderReducer;
