import React from 'react';
import IntegrationActions from '../../actions/IntegrationActionCreators';

import { translate } from 'Language';
import Button from 'Common/components/Button';
import { TextInput } from 'Common/components';

type Props = {
    clientKey: string;
    secret: string;
};

export default class IntegrationForm extends React.Component<Props> {
    handleResetKeys = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        IntegrationActions.updateCredentials();
    };

    render() {
        const { clientKey, secret } = this.props;

        return (
            <div className="integration-form">
                <div className="form">
                    <label htmlFor="name">{translate('API key')}</label>
                    <TextInput value={clientKey} readOnly />

                    <label htmlFor="name">{translate('API secret')}</label>
                    <TextInput value={secret} readOnly />

                    <Button
                        theme="red"
                        onClick={this.handleResetKeys}
                        icon="far fa-sync">
                        {translate('Reset Keys')}
                    </Button>
                </div>
            </div>
        );
    }
}
