import PropTypes from 'prop-types';
import React from 'react';
import IntegrationForm from '../Integration/IntegrationFormContainer';

import IntegrationActions from '../../actions/IntegrationActionCreators';

import { i18n } from 'Language';
import Button from 'Common/components/Button';
import Message from 'Common/components/Message';
import LoadingData from 'Common/components/Loaders/LoadingData';

export default class IntegrationActivation extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    static propTypes = {
        user: PropTypes.object,
        nextLink: PropTypes.object,
        apiCredentials: PropTypes.object,
        nextOption: PropTypes.string,
        params: PropTypes.object,
    };

    componentDidMount() {
        this.loadData();
    }

    generateKeys = () => {
        IntegrationActions.generateCredentials();
    };

    loadData = () => {
        if (!this.props.apiCredentials) {
            this.generateKeys();
        }
    };

    saveConfirmation = async () => {
        await IntegrationActions.setActivationStatus(true);

        this.context.router.push({
            name: 'invite',
            params: this.props.params,
        });
    };

    render() {
        let { apiCredentials } = this.props;

        if (apiCredentials) {
            return (
                <div>
                    <IntegrationForm credentials={apiCredentials} />
                    <br />
                    <Message type="warning" className="text-left">
                        {i18n`If you are going to use the Penneo API, copy your credentials
                        before continuing. You can view these keys again in your user profile`}
                    </Message>
                    <br />

                    <Button theme="blue" onClick={this.saveConfirmation}>
                        {i18n`Continue`}
                    </Button>
                </div>
            );
        }

        return (
            <div>
                <br />
                <LoadingData />
            </div>
        );
    }
}
