import React, { useMemo, useState } from 'react';
import { i18n } from 'Language';
import { getEnabledLanguages } from 'Language/utils';
import VegaDropdown from 'Common/components/Vega/Dropdown';
import analytics from 'Common/Analytics';
import { LANGUAGES } from 'Language/Constants';
import HelpTooltip from 'Common/components/HelpTooltip';

export enum CaseFileStatus {
    DRAFT = 'draft',
    CREATE = 'create',
    EDIT = 'edit',
}
type Props = {
    caseFileStatus: CaseFileStatus;
    defaultLanguage: string;
    onChange: (language: string | undefined) => void;
    selectedLanguage?: string;
};

export default function LanguagePicker({
    caseFileStatus,
    defaultLanguage,
    selectedLanguage,
    onChange,
}: Props) {
    const [availableLanguages, setAvailableLanguages] = useState<
        typeof LANGUAGES | undefined
    >(undefined);

    useMemo(() => {
        setAvailableLanguages(getEnabledLanguages());
    }, []);

    const handleLanguageChange = (language: string) => {
        onChange(language);
        analytics.track(
            `casefile - signer language - select language dropdown`,
            {
                selectedLanguage: availableLanguages![language].name,
                'casefile mode': caseFileStatus,
                casefileLanguage: defaultLanguage,
            }
        );
    };

    const options = useMemo(() => {
        if (availableLanguages === undefined) {
            return [];
        }

        return Object.keys(availableLanguages).map((key) => {
            return [
                key,
                key === defaultLanguage
                    ? `${availableLanguages[key].name} (${i18n(
                          'signerLanguageDefault'
                      )})`
                    : availableLanguages[key].name,
            ];
        });
    }, [selectedLanguage, availableLanguages]);

    return (
        <div className="mt-2">
            <label>
                {i18n`Language`}&nbsp;
                <HelpTooltip text={i18n`signerLanguageDescription`} />
            </label>
            <VegaDropdown
                id={'language-picker'}
                options={options}
                selectedOption={
                    selectedLanguage === undefined
                        ? defaultLanguage
                        : selectedLanguage
                }
                onInput={(_e, values) => {
                    handleLanguageChange(values.value);
                }}
            />
        </div>
    );
}
