import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    AxiosError,
    CancelToken,
} from 'axios';
import parseLinkHeader from 'parse-link-header';
import Constants from 'Constants';
import { storage } from 'Core';
import { AuthClient } from './AuthClient';
import { V2Validation } from 'Signing/utils';

export type ApiResponse = any;

type ApiClassParameters = {
    baseURL: string;
    isPublic: boolean;
    client?: AxiosInstance;
    defaultVersion: string | null;
    /**
     *  Below property will force validation headers to use the new Autorization: Bearer format
     * Currently that will ONLY apply to Signing V2 endpoint (NewSignerAPI)
     * Any other endpoint with autorizationBearer: true can be migrated to this format though
     */
    requiresV2Validation?: boolean;
    requiresBearerToken?: boolean;
};

type ApiOptions = {
    raw?: boolean;
    paginate?: boolean;
    silent?: boolean;
    token?: string;
    cancelToken?: CancelToken;
    propagateErrors?: boolean;
    onUploadProgress?: (progressEvent: any) => void;
    responseType?: AxiosRequestConfig['responseType'];
    headers?: AxiosRequestConfig['headers'];
};

type RequestConfig = {
    method: Methods;
    url: string;
    params?: any;
    data?: any;
    options: ApiOptions;
};

type RequestHeaders = {
    'X-Auth-Token'?: string;
    'X-Requested-With': string;
    'X-Paginate': boolean | string;
    Authorization?: string;
};

enum Methods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export class ApiClient {
    private public: boolean;
    private requiresV2Validation: boolean;
    private requiresBearerToken: boolean;
    private defaultVersion: ApiClassParameters['defaultVersion'];
    private client: AxiosInstance;

    constructor({
        baseURL,
        isPublic = false,
        client,
        defaultVersion,
        requiresV2Validation = false,
        requiresBearerToken = false,
    }: ApiClassParameters) {
        this.defaultVersion = defaultVersion;
        this.public = isPublic;
        this.requiresV2Validation = requiresV2Validation;
        this.requiresBearerToken = requiresBearerToken;
        this.client =
            client ||
            axios.create({
                baseURL: baseURL,
            });
    }

    private getAccessToken(options: ApiOptions) {
        if (this.requiresV2Validation) {
            return V2Validation.get();
        }

        if (options.token) {
            return options.token;
        }

        return storage.get(Constants.PENNEO_TOKEN_KEY);
    }

    private getHeaders(accessToken: string, options: ApiOptions) {
        let headers: RequestHeaders = {
            'X-Requested-With': 'XHttpRequest',
            'X-Paginate': options.paginate || 'false',
        };

        if (accessToken) {
            if (this.requiresBearerToken) {
                headers.Authorization = `Bearer ${accessToken}`;
            } else {
                headers['X-Auth-Token'] = accessToken;
            }
        }

        if (this.public === false) {
            if (!this.requiresBearerToken) {
                headers.Authorization = 'JWT';
            }
        }

        return headers;
    }

    public getCancelToken() {
        return axios.CancelToken.source();
    }

    public async get(
        url: string,
        params?: any,
        options: ApiOptions = {}
    ): Promise<ApiResponse> {
        return this.makeRequest({ method: Methods.GET, url, params, options });
    }

    public async post(
        url: string,
        data?: any,
        options: ApiOptions = {}
    ): Promise<ApiResponse> {
        return this.makeRequest({ method: Methods.POST, url, data, options });
    }

    public async put(
        url: string,
        data?: any,
        options: ApiOptions = {}
    ): Promise<ApiResponse> {
        return this.makeRequest({ method: Methods.PUT, url, data, options });
    }

    public async patch(
        url: string,
        data?: any,
        options: ApiOptions = {}
    ): Promise<ApiResponse> {
        return this.makeRequest({ method: Methods.PATCH, url, data, options });
    }

    public async delete(
        url: string,
        data?: any,
        options: ApiOptions = {}
    ): Promise<ApiResponse> {
        return this.makeRequest({ method: Methods.DELETE, url, data, options });
    }

    public async file(
        url: string,
        data?: any,
        options: ApiOptions = {}
    ): Promise<ApiResponse> {
        let formData = new FormData();

        for (let key in data) {
            if (!data.hasOwnProperty(key)) {
                break;
            }

            formData.append(key, data[key], data[key].name);
        }

        return this.makeRequest({
            method: Methods.POST,
            url,
            data: formData,
            options,
        });
    }

    // Get endpoint URL
    // To support versioning in API, the endpoints will default to `this.defaultVersion`
    // when no version is prepended in API calls.
    // If /v2, /v3, /v#, etc. is included in the endpoint, it will override the default version value.
    private getRequestEndpoint = (url: string) => {
        if (this.defaultVersion === null) {
            return url;
        }

        return /^(https?:\/\/|\/v\d\/)/.test(url)
            ? url
            : `/${this.defaultVersion}${url}`;
    };

    private async makeRequest(requestConfig: RequestConfig) {
        const { method, url, data, params, options } = requestConfig;
        const { cancelToken, responseType, onUploadProgress } = options;

        const accessToken = this.getAccessToken(options);
        const defaultHeaders = this.getHeaders(accessToken, options);
        const headers = {
            ...defaultHeaders,
            ...(options?.headers || {}),
        };

        try {
            const response = await this.client.request({
                method,
                url: this.getRequestEndpoint(url),
                headers,
                params,
                data,
                cancelToken,
                responseType,
                onUploadProgress,
            });

            return this.handleResponse(response, requestConfig);
        } catch (error) {
            return this.handleError(error, requestConfig);
        }
    }

    handleResponse(response: AxiosResponse, requestConfig: RequestConfig) {
        if (requestConfig.options.raw) {
            return response;
        }

        if (requestConfig.options.paginate) {
            return {
                data: response.data,
                count: Number(response.headers['x-penneo-item-count']) || 0,
                link: parseLinkHeader(response.headers.link),
            };
        }

        return response.data;
    }

    async handleError(error: AxiosError, requestConfig: RequestConfig) {
        if (axios.isCancel(error)) {
            return Promise.reject({
                cancel: true,
            });
        }

        if (requestConfig.options.propagateErrors) {
            throw error;
        }

        if (requestConfig.options.silent) {
            return false;
        }

        // If unauthorized, try to reauthenticate using a refresh token
        // and retry the original request
        // @note: only for authenticated requests.
        if (
            error.response &&
            error.response.status === 401 &&
            this.public === false
        ) {
            try {
                await AuthClient.reauthenticate();

                return await this.retryRequest(requestConfig);
            } catch (e) {
                return AuthClient.logout();
            }
        }

        throw this.createError(error);
    }

    createError(error: AxiosError) {
        if (!error.isAxiosError) {
            return error;
        }

        if (!error.response) {
            return {
                isApiError: true,
                ...error,
            };
        }

        return {
            isApiError: true,
            ...error.response,
        };
    }

    async retryRequest(requestConfig: RequestConfig) {
        requestConfig.options = {
            ...requestConfig.options,
            propagateErrors: true,
        };

        try {
            return await this.makeRequest(requestConfig);
        } catch (error) {
            if (error.response.status === 401) {
                return AuthClient.logout();
            }

            return this.handleError(error, requestConfig);
        }
    }
}

export const AuthAPI = new ApiClient({
    baseURL: Constants.PENNEO_AUTH_API,
    isPublic: false,
    defaultVersion: 'v1',
});
export const SigningAPI = new ApiClient({
    baseURL: Constants.PENNEO_SIGNING_API,
    isPublic: false,
    defaultVersion: 'v3',
});
export const FormAPI = new ApiClient({
    baseURL: Constants.PENNEO_FORMS_API,
    isPublic: false,
    defaultVersion: 'v1',
});
export const WorkflowAPI = new ApiClient({
    baseURL: Constants.PENNEO_WORKFLOW_API,
    isPublic: false,
    defaultVersion: 'v1',
});
export const PublicAuthAPI = new ApiClient({
    baseURL: Constants.PENNEO_AUTH_API,
    isPublic: true,
    defaultVersion: 'v1',
});
export const PublicSigningAPI = new ApiClient({
    baseURL: Constants.PENNEO_SIGNING_API,
    isPublic: true,
    defaultVersion: 'v1',
});
export const PublicSignerAPI = new ApiClient({
    baseURL: Constants.PENNEO_SIGNER_API,
    isPublic: true,
    defaultVersion: 'v1',
});
export const SignerAPI = new ApiClient({
    baseURL: Constants.PENNEO_SIGNER_API,
    isPublic: false,
    defaultVersion: 'v1',
});
export const SessionAPI = new ApiClient({
    baseURL: Constants.PENNEO_ORIGIN,
    isPublic: false,
    defaultVersion: null,
});
export const PublicSessionAPI = new ApiClient({
    baseURL: Constants.PENNEO_ORIGIN,
    isPublic: true,
    defaultVersion: null,
});
export const ClaAPIProxyPrototype = new ApiClient({
    baseURL: Constants.CLA_API_PROXY_PROTOTYPE,
    isPublic: false,
    defaultVersion: null,
});
/**
 * Signing V2 endpoint(custom Authorization: Bearer header)
 */
export const NewSignerAPI = new ApiClient({
    baseURL: Constants.PENNEO_SIGNER_API,
    isPublic: true,
    defaultVersion: 'v2',
    requiresV2Validation: true,
    requiresBearerToken: true,
});

export const MediaApi = new ApiClient({
    baseURL: Constants.PENNEO_MEDIA_API,
    isPublic: false,
    defaultVersion: 'v1',
    requiresBearerToken: true,
});

/**
 * PublicSigningAPI with bearer authentication token for validating signer ssn in NAP image upload
 */
export const PublicSigningAPIWithAuthHeader = new ApiClient({
    baseURL: Constants.PENNEO_SIGNING_API,
    isPublic: true,
    defaultVersion: 'v1',
    requiresV2Validation: true,
    requiresBearerToken: true,
});

export const PublicFormattingServiceApi = new ApiClient({
    baseURL: Constants.PENNEO_ORIGIN,
    isPublic: true,
    defaultVersion: null,
});
