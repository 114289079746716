import React from 'react';
import { KYCListItem } from 'KYC/redux/types';
import Button from 'Common/components/Button';
import { i18n } from 'Language';
import { modal } from 'Common/components/Common/Modal';
import { notify } from 'react-notify-toast';
import Analytics from 'Common/Analytics';
import { FormAPI } from 'Api';

type Props = {
    kyc: KYCListItem;
    onDelete: Function;
    source: string;
};

const KYCDeleteModal: React.FC<Props> = ({ kyc, onDelete, source }) => {
    const handleDelete = async () => {
        try {
            await FormAPI.delete(`/v2/kyc/${kyc.id}`);

            Analytics.track('kyc - deleted', {
                id: kyc.id,
                source,
            });

            notify.show(i18n`KYC deleted successfully`, 'success', 3000);

            onDelete();
        } catch (error) {
            Analytics.track('kyc - delete failed', {
                id: kyc.id,
                source,
            });

            notify.show(i18n`Couldn't delete KYC`, 'error', 3000);
        }

        modal.hide();
    };

    return (
        <div>
            {i18n`Are you sure you want to delete the KYC request sent to`}:
            <h4>
                {kyc.recipient.name}
                &nbsp;
                <span className="text-blue">({kyc.recipient.email})</span>
            </h4>
            <p className="text-warning">
                <i className="far fa-exclamation-triangle"></i>
                &nbsp;
                {i18n`This action cannot be undone`}
            </p>
            <Button icon="far fa times" onClick={modal.hide}>
                {i18n`No, cancel`}
            </Button>
            <Button
                className="ml"
                icon="far fa times"
                theme="red"
                onClick={handleDelete}>
                {i18n`Yes, delete`}
            </Button>
        </div>
    );
};

export const showKYCDeleteModal = (
    kyc: Props['kyc'],
    onDelete: Function,
    source: string
) => {
    const config = {
        title: i18n('Delete KYC'),
        body: <KYCDeleteModal kyc={kyc} onDelete={onDelete} source={source} />,
    };

    modal.show(config);
};
