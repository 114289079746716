import PropTypes from 'prop-types';
import React from 'react';
import { StorageKeys } from 'Constants';
import { storage } from 'Core';
import Loader from 'Common/components/Common/Loader';

export default class RedirectionHandler extends React.Component {
    static propTypes = {
        params: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
    };

    componentDidMount() {
        let { query } = this.props.location;

        this.redirect(query.path);
    }

    redirect = (redirectURL) => {
        if (storage.get(StorageKeys.DEBUG)) {
            return console.log(redirectURL);
        }

        document.location.replace(redirectURL);
    };

    render() {
        return <Loader />;
    }
}
