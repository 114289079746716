import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import TouchActions from 'Auth/actions/TouchActions';

export default class TypedSignature extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        placeholder: 'Type your full name',
    };

    state = {
        signatureText: '',
    };

    componentDidMount() {
        TouchActions.loadWidget('text');

        this.props.onChange(null);
    }

    _onChangeHandler = (event) => {
        let { value } = event.target;

        this.setState({
            signatureText: value,
        });

        this.props.onChange(this._createTextSignature(value));
    };

    _createTextSignature = (text, font = '24pt Caveat') => {
        let imageElem = document.getElementById('image');

        if (text === '') {
            imageElem.src = '';

            return null;
        }

        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');

        // The font property needs to be defined twice. For the first time font is set
        // in order to calculate correct text width and for some reason after setting canvas height
        // the font resets thus it has to be defined again.
        context.font = font;

        canvas.width = context.measureText(text).width + 5;
        canvas.height = 35;

        context.font = font;
        context.fillText(text, 0, 25);

        imageElem.src = canvas.toDataURL();
        let base64image = canvas.toDataURL().split(',')[1];

        return base64image;
    };

    render() {
        return (
            <div
                className="text-signature"
                data-testid="components/VisualSignature/TextSignature">
                <label>{i18n`Create a text-based signature`}</label>
                <div className="text-signature-content">
                    <input
                        type="text"
                        name="typed"
                        autoFocus
                        className="match-parent"
                        placeholder={i18n(this.props.placeholder)}
                        value={this.state.signatureText}
                        onChange={this._onChangeHandler}
                    />
                    <br />
                    <br />
                    <label>{i18n`Signature`}</label>
                    <div className="text-signature-line">
                        <img className="signature-image" id="image" />
                    </div>
                </div>
            </div>
        );
    }
}
