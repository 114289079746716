import PropTypes from 'prop-types';
import React from 'react';
import Constants from '../../Constants';

import FormStore from '../../stores/FormStore';
import WorkflowStore from '../../stores/WorkflowStore';

import FormActions from '../../actions/FormActionCreators';
import WorkflowActions from '../../actions/WorkflowActionCreators';

import DocumentEditor from '../../components/Form/DocumentEditor';
import { modal } from 'Common/components/Common/Modal';
import { translate, TranslationActions } from 'Language';
import Button from 'Common/components/Button';

import { splitAggregatedSSN, cleanAggregatedSSN } from 'utils';

import { SSNs } from 'Constants';

export default class DocumentEditorController extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static propTypes = {
        params: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
    };

    state = {
        formDocument: [],
        pageImages: [],
        fields: [],
        mapping: [],
        attachments: [],
        coSigner: {},
        form: {},
        workflow: {},
        branding: {},
    };

    componentDidMount() {
        // Change Listeners
        FormStore.addChangeListener(this.onChange);
        WorkflowStore.addChangeListener(this.onChange);
        // BrandingStore.addChangeListener(this.onChange);

        // Custom Event Listeners
        WorkflowStore.addEventListener(
            'workflow-instantiated',
            this.onInstantiate
        );
        WorkflowStore.addEventListener(
            'prototype-not-found',
            this.onNotFoundError
        );
        WorkflowStore.addEventListener(
            'instantiate-error',
            this.onInstantiateError
        );

        // Load Initial set of data.
        this.loadData();
    }

    componentWillUnmount() {
        // Change Listeners
        FormStore.removeChangeListener(this.onChange);
        WorkflowStore.removeChangeListener(this.onChange);

        WorkflowStore.removeEventListener(
            'workflow-instantiated',
            this.onInstantiate
        );
        WorkflowStore.removeEventListener(
            'prototype-not-found',
            this.onNotFoundError
        );
        WorkflowStore.removeEventListener(
            'instantiate-error',
            this.onInstantiateError
        );
    }

    /**
     * Listens to Store Change event emisions, and updates all
     * states acccording to the latest store contents.
     */
    onChange = () => {
        this.setState({
            formDocument: FormStore.getFormDocument(),
            pageImages: FormStore.getFormDocumentImages(),
            fields: FormStore.getFormFields(),
            mapping: FormStore.getFormFieldsMapping(),
            attachments: WorkflowStore.getAttachments(),
            coSigner: WorkflowStore.getCoSigner(),
            form: FormStore.getCurrentForm(),
            workflow: WorkflowStore.getCurrentWorkflowPrototype(),
        });
    };

    /**
     * Sets the language based on the workflow when the workflow loads
     */
    componentWillUpdate(nextProps, nextState) {
        let { workflow, fields } = nextState;

        if (!this.state.workflow.id && workflow.id) {
            TranslationActions.setLanguage(workflow.language, {
                persist: false,
            });
        }

        // Fill the fields with data from the query parameters when fields are loaded.
        if (this.state.fields.length === 0 && fields.length > 0) {
            this.applyFieldParameters(fields);
        }
    }

    /**
     * Updates fields with data from query parameters sent in the following format:
     * 'field_{FieldName}={FieldValue}'
     *
     * @param  {Array} fields [description]
     *
     * @return {void}  Updates field state.
     */
    applyFieldParameters = (fields) => {
        let parameters = this.getFieldParameters();

        fields.forEach((field) => {
            field.value = parameters[field.name.toLowerCase()];
        });

        this.setState({ fields: fields });
    };

    /**
     * Extracts parameters from Query Parameters in the URL with the following format:
     * 'field_{FieldName}={FieldValue}'
     *
     * @return {Object} key value pair object of names and values for field replacements
     */
    getFieldParameters = () => {
        let parameters = {};
        let query = this.props.location.query;
        let regex = /^field_.+$/;

        for (let fieldName in query) {
            if (regex.test(fieldName)) {
                let key = fieldName.replace('field_', '').toLowerCase();

                parameters[key] = query[fieldName]; // assign value.
            }
        }

        return parameters;
    };

    /**
     * Loads all the data from store after a prop change. (applies on first load too)
     * @param id {int} form ID prop, references a form ID in the server.
     *
     * Sets initial viewportWidth state.
     */
    loadData = () => {
        let { formToken, workflowToken } = this.props.params;

        FormActions.fetchPublicForm(formToken);
        FormActions.fetchPublicFields(formToken);
        FormActions.fetchPublicMappings(formToken);
        FormActions.fetchPublicDocument(formToken);
        WorkflowActions.getPublicWorkflow(workflowToken);
    };

    /**
     * Creates an element based on the dropped field type on the documents' page.
     * @param event {event} onDrop event from finalized drag action.
     */
    dropHandler = (event) => {
        event.preventDefault();
        // Get DOM Object Bounds
        let rect = event.target.getBoundingClientRect();

        // Calculate pointer position inside target. (Pointer position - Target Position)
        let posX = event.clientX - rect.left;
        let posY = event.clientY - rect.top;

        let fieldId = parseInt(event.dataTransfer.getData('text/plain'), 10); // Parsed to Integer

        // Render Element in position
        this.createElement(event.target, posX, posY, fieldId);
    };

    formatFormFields = (fields) => {
        return fields.reduce((acc, field) => {
            const isSSNField = field.metaData.type === 'ssn';
            const value = isSSNField
                ? // We need only the value of the ssn, not the country
                  splitAggregatedSSN(field.value)?.ssn
                : field.value;

            /**
             * We skip adding an empty -invalid- SSN to the form fields
             * to prevent errors in the backend validation.
             * NOTE: this will only happen when the SSN is not mandatory.
             * If it were, it wouldn't pass the initial validation previous to this step
             */
            if (isSSNField && !value) {
                return acc;
            }

            acc[field.name] = value || '';

            return acc;
        }, {});
    };

    /**
     * Saves all fields in the form and creates workflow structure
     */
    saveFormHandler = () => {
        let { attachments } = this.state;
        let { workflowToken } = this.props.params;
        let { group } = this.props.location.query;

        let { coSigner, workflow } = this.state;

        let fields = this.formatFormFields(this.state.fields);

        // Casefile/Workflow Title Template
        let caseFileTitle = workflow.userData.initial.caseFile.title;
        let titleTemplate = this.buildTemplateTitle(caseFileTitle, fields);

        let caseFile = this.buildCasefileDetails(
            fields,
            coSigner,
            titleTemplate
        );

        let data = {
            title: titleTemplate,
            groupName: group,
            userData: {
                initial: {
                    form: {
                        fields: fields,
                    },
                    caseFile: caseFile,
                },
            },
        };

        WorkflowActions.instantiate(workflowToken, data, attachments);
    };

    // @todo: Remove hardcoded fields
    buildTemplateTitle = (title, variables) => {
        // Double curly bracket matching
        let regex = /\{\{([^}]+)\}\}/g;

        // Fallback to legacy if title is not set. or if it matches placeholder.
        if (!title || title === 'placeholder') {
            return this.buildLegacyTemplateTitle(variables);
        }

        // If title is not a template with variables, return it.
        if (!regex.test(title)) {
            return this.buildLegacyTemplateTitle(variables);
            // return title;
        }

        // Replace variables with form field values.
        return title.replace(regex, (...match) => {
            return variables[match[1]]; // Match first regex argument.
        });
    };

    // @todo: Remove hardcoded fields
    buildLegacyTemplateTitle = (fields) => {
        let title = null;

        // Template
        // <employee_id> - <recipient_name> - <recipient_email>
        if (fields.RECIPIENT_NAME) {
            title = fields.RECIPIENT_NAME;
        }

        if (fields.RECIPIENT_EMAIL) {
            if (title) {
                title += ' - ' + fields.RECIPIENT_EMAIL;
            } else {
                title = fields.RECIPIENT_EMAIL;
            }
        }

        if (fields.EMPLOYEE_ID) {
            if (title) {
                title = fields.EMPLOYEE_ID + ' - ' + title;
            } else {
                title = fields.EMPLOYEE_ID;
            }
        }

        return title;
    };

    // @todo: Remove hardcoded fields
    buildCasefileDetails = (fields, coSigner, title) => {
        let { workflow } = this.state;
        let { caseFile } = workflow.userData.initial;

        let documentTitle;

        if (caseFile.document && caseFile.document.title) {
            documentTitle = caseFile.document.title;
        }

        let caseFileDetails = {
            title: title,
            document: {
                title: this.buildTemplateTitle(documentTitle, fields),
            },
            signers: [],
        };

        // @fixme: RECIPIENT_EMAIL/NAME are being used as hardcoded fields.
        if (fields.RECIPIENT_EMAIL && fields.RECIPIENT_EMAIL.length > 0) {
            let signer = {
                email: fields.RECIPIENT_EMAIL,
                name: fields.RECIPIENT_NAME,
            };

            // Add SSN and Vatin to first signer.
            // Extract the first SSN and Vatin from fields
            let ssnField = this.state.fields.filter(
                (f) => f.metaData.type === 'ssn'
            )[0];
            let vatinField = this.state.fields.filter(
                (f) => f.metaData.type === 'vatin'
            )[0];

            /**
             * Even if we find an SSN field, we need to make sure it has a value before processing it.
             * When the SSN field is present but set as non-editable,
             * it won't have a value prop.
             */
            if (ssnField?.value) {
                const cleanSSN = cleanAggregatedSSN(ssnField.value);

                /**
                 * We must check that the SSN is not empty or
                 * the default starting value ('legacy'). This is useful when the SSN field
                 * is not required and the user leaves it blank. If we send that empty string,
                 * it'll make the request fail.
                 */
                if (cleanSSN !== `${SSNs.LEGACY_SSN.id};`) {
                    signer.ssn = cleanSSN;
                }
            }

            if (vatinField) {
                signer.vatin = vatinField.value;
            }

            caseFileDetails.signers.push(signer);
        }

        // @fixme: COSIGNER_EMAIL/NAME are being used as hardcoded fields.
        if (fields.COSIGNER_EMAIL && fields.COSIGNER_EMAIL.length > 0) {
            let signer = {
                email: fields.COSIGNER_EMAIL,
                name: fields.COSIGNER_NAME,
            };

            caseFileDetails.signers.push(signer);
        }

        // If a new signer is added, patch the workflow.
        if (coSigner.name && coSigner.email) {
            let signer = {
                email: coSigner.email,
                name: coSigner.name,
            };

            caseFileDetails.signers.push(signer);
        }

        return caseFileDetails;
    };

    onInstantiate = (workflow) => {
        let { router } = this.context;

        router.push({
            name: 'workflow',
            params: {
                id: workflow.id,
                token: workflow.metaData.token,
            },
        });
    };

    onInstantiateError = (error) => {
        this.onResumeError(error);
    };

    onNotFoundError = () => {
        let { router } = this.context;

        router.replace({ name: 'page-not-found' });
    };

    onResumeError = (error) => {
        if (error) {
            let config = {
                title: translate('Der opstod en fejl'),
                body: (
                    <span>
                        {translate(
                            'Der opstod en fejl i kommunikationen med serveren.'
                        )}
                        <br />
                        {translate(
                            'Prøv venligst igen, eller kontakt support.'
                        )}
                    </span>
                ),
                buttons: (
                    <div>
                        <a
                            href={Constants.SUPPORT_URL}
                            target="_blank"
                            rel="noopener noreferrer">
                            <Button theme="blue">
                                {translate('Contact Support')}
                            </Button>
                        </a>
                        <Button onClick={modal.hide}>
                            {translate('Close')}
                        </Button>
                    </div>
                ),
            };

            modal.show(config);
        }
    };

    render() {
        // Form Data
        let { form, fields, mapping } = this.state;

        // Documents
        let { formDocument, pageImages, attachments } = this.state;

        // Workflow
        let { workflow, coSigner } = this.state;

        if (!workflow.id) {
            return false;
        }

        return (
            <DocumentEditor
                form={form}
                fields={fields}
                mapping={mapping}
                formDocument={formDocument}
                pageImages={pageImages}
                attachments={attachments}
                workflow={workflow}
                coSigner={coSigner}
                saveFormHandler={this.saveFormHandler}
            />
        );
    }
}
