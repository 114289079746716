import PropTypes from 'prop-types';
import React from 'react';

export default class HoverTooltip extends React.Component {
    static propTypes = {
        text: PropTypes.string,
        children: PropTypes.element,
    };

    static defaultProps = {
        text: null,
    };

    render() {
        let { text } = this.props;

        if (this.props.text !== null) {
            return (
                <span className="tooltip-hover">
                    <div className="tooltip-text">{text}</div>
                    {this.props.children}
                </span>
            );
        }

        return <span>{this.props.children}</span>;
    }
}
