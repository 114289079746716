import { Dispatcher } from 'Core';
import { BankIDNOActions as Actions } from '../ActionTypes';

const bankIDNOViewActions = {
    retry(intent = '') {
        Dispatcher.handleViewAction({
            type: Actions.RETRY,
            intent: intent,
        });
    },

    clearStore() {
        Dispatcher.handleViewAction({
            type: Actions.CLEAR_STORE,
        });
    },
};

export default bankIDNOViewActions;
