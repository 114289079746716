import { Routes, env } from 'Constants';
import AuthStore from 'Auth/stores/AuthStore';
import UserStore from 'Auth/stores/UserStore';
import { debug } from 'Core';
import { sanitizeUrl } from 'utils';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import Constants from 'Constants';

type LoginRoute = {
    type: 'route';
    route: {
        name: string;
    };
};

type LoginURL = {
    type: 'url';
    url: string;
};

export type DefaultLoginRoute = LoginRoute | LoginURL;

const loginUtils = {
    decodedURI(url: string): { relative: boolean; uri: string } {
        let uriDecoded = url;

        if (!url.startsWith('https://') || url.startsWith('%')) {
            uriDecoded = window.decodeURIComponent(url);
        }

        return {
            relative: uriDecoded.startsWith('/'),
            uri: uriDecoded,
        };
    },
    // Only redirect to same domain urls.
    isSameDomain(url: string) {
        const { relative, uri } = this.decodedURI(url);

        if (relative) {
            return true;
        }

        const uriObject = new URL(uri);

        return 'https://' + uriObject.host === Constants.PENNEO_ORIGIN;
    },

    getDefaultRoute(
        redirectUrl = AuthStore.getSuccessUrl()
    ): DefaultLoginRoute {
        const user = UserStore.getCurrentUser();
        const userDetails = AuthStore.getUser();
        const isCustomer = !!userDetails.customerIds.length;

        if (!user) {
            debug.error(
                `'getDefaultRoute' cannot be called before successful authentication`
            );
        }

        // Always redirect desktop app to casefile send out
        if (env.platform === 'desktop') {
            return {
                type: 'route',
                route: {
                    name: Routes.defaultDesktopRoute,
                },
            };
        }

        // If there's a success URL specified. return it
        if (redirectUrl && this.isSameDomain(redirectUrl)) {
            const { uri, relative } = this.decodedURI(redirectUrl);

            // Match /archive or /archive/anything - both relative and absolute
            if (
                /^(?:https?:\/\/[^\/]+)?\/archive(\/.*)?$/.test(uri) &&
                !isCustomer &&
                LaunchDarkly.variation(Flags.ENABLE_SIGNERS_ARCHIVE)
            ) {
                return {
                    type: 'route',
                    route: { name: Routes.defaultSignersArchiveRoute },
                };
            }

            if (relative) {
                return {
                    type: 'url',
                    url: `${Constants.PENNEO_ORIGIN}${uri}`,
                };
            }

            return {
                type: 'url',
                url: uri,
            };
        }

        // If the user has access to the New UI, redirect them to the dashboard.
        if (
            isCustomer &&
            LaunchDarkly.variationIncludes(Flags.UI_V2, ['demo', 'enabled'])
        ) {
            return {
                type: 'route',
                route: {
                    name: Routes.defaultV2RouteName,
                },
            };
        }

        // If the user has access to the Signers Archive, redirect them to the signers archive.
        if (
            !isCustomer &&
            LaunchDarkly.variation(Flags.ENABLE_SIGNERS_ARCHIVE)
        ) {
            return {
                type: 'route',
                route: {
                    name: Routes.defaultSignersArchiveRoute,
                },
            };
        }

        // If everything fails, send the user to web /archive (V1)
        return {
            type: 'url',
            url: Routes.defaultV1Route,
        };
    },

    hardRedirectToDefaultRoute(path: LoginRoute | LoginURL, router: any) {
        const { PENNEO_ORIGIN } = Constants;
        const currentOrigin = PENNEO_ORIGIN;
        const urlSearchParams = new URLSearchParams(window.location.search);

        const redirect = urlSearchParams.get('redirect');
        let redirectUrl = redirect ?? (path as LoginURL)?.url;
        const redirectUrlOrigin = redirectUrl && new URL(redirectUrl).origin;
        const isSameHost = currentOrigin === redirectUrlOrigin;

        redirectUrl = sanitizeUrl(redirectUrl);

        if (!redirect && path?.type === 'route') {
            redirectUrl = router.createHref((path as LoginRoute).route);
        }

        if (!isSameHost || !redirectUrl) {
            redirectUrl = currentOrigin;
        }

        window.location.replace(redirectUrl);
    },
};

export default loginUtils;
