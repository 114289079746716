import { AppThunk } from 'Store';
import { CancelTokenSource } from 'axios';

import { AuthStore } from 'Auth';
import { FormAPI, WorkflowAPI, PublicAuthAPI, SigningAPI } from 'Api';

import { KYCRecipient, DataFields, KYCRequirementItem, KYCList } from './types';
import { TemplateType, EmailTemplate } from 'types/EmailTemplates';

import {
    KYC_RESET_STATE,
    KYC_CLEAR_ERROR,
    KYC_CLEAR_IS_SENDING,
    KYC_SEND_REQUEST,
    KYC_SEND_SUCCESS,
    KYC_SEND_FAILURE,
    KYC_TITLE_UPDATED,
    KYC_DESCRIPTION_UPDATED,
    KYC_FOLDER_UPDATED,
    KYC_LANGUAGE_UPDATED,
    KYC_RECIPIENT_ADDED,
    KYC_RECIPIENT_UPDATED,
    KYC_RECIPIENT_REMOVED,
    KYC_EMAIL_TEMPLATE_UPDATED,
    KYC_DATA_REQUIREMENT_UPDATE,
    KYC_REMINDER_INTERVAL_UPDATED,
    KYC_DATA_UPDATE,
    KYC_DATA_VALIDATED,
    KYC_ATTACHMENT_UPDATE,
    KYC_FETCH_REQUEST,
    KYC_FETCH_SUCCESS,
    KYC_FETCH_FAILURE,
    KYC_SUBMIT_REQUEST,
    KYC_SUBMIT_FAILURE,
    KYC_FETCH_LIST_SUCCESS,
    KYC_FETCH_LIST_FAILURE,
    KYC_FETCH_LIST_REQUEST,
    KYC_FETCH_ITEM_REQUEST,
    KYC_FETCH_ITEM_SUCCESS,
    KYC_FETCH_ITEM_FAILURE,
} from './reducer';
import Analytics from 'Common/Analytics';
import Amplitude from 'Common/Amplitude';
import { splitAggregatedSSN } from 'utils';

// Actions

export const resetState = (): AppThunk => (dispatch) => {
    dispatch({ type: KYC_RESET_STATE });
};

export const clearError = (): AppThunk => (dispatch) => {
    dispatch({ type: KYC_CLEAR_ERROR });
};

export const clearSendingState = (): AppThunk => (dispatch) => {
    dispatch({ type: KYC_CLEAR_IS_SENDING });
};

export const updateTitle = (title: string): AppThunk => (dispatch) => {
    dispatch({
        type: KYC_TITLE_UPDATED,
        payload: {
            title,
        },
    });
};

export const updateLanguage = (language: string) => ({
    type: KYC_LANGUAGE_UPDATED,
    payload: {
        language,
    },
});

export const updateDescription = (description: string): AppThunk => (
    dispatch
) => {
    dispatch({
        type: KYC_DESCRIPTION_UPDATED,
        payload: {
            description,
        },
    });
};

export const updateFolderId = (folderId: number): AppThunk => (dispatch) => {
    dispatch({
        type: KYC_FOLDER_UPDATED,
        payload: {
            folderId,
        },
    });
};

export const updateReminderInterval = (reminderInterval: number): AppThunk => (
    dispatch
) => {
    dispatch({
        type: KYC_REMINDER_INTERVAL_UPDATED,
        payload: {
            reminderInterval,
        },
    });
};

export const addRecipient = (recipient: KYCRecipient): AppThunk => (
    dispatch
) => {
    dispatch({
        type: KYC_RECIPIENT_ADDED,
        payload: {
            recipient,
        },
    });
};

export const updateRecipient = (
    recipient: KYCRecipient,
    index: number
): AppThunk => (dispatch) => {
    dispatch({
        type: KYC_RECIPIENT_UPDATED,
        payload: {
            recipient,
            index,
        },
    });
};

export const removeRecipient = (index: number): AppThunk => (dispatch) => {
    dispatch({
        type: KYC_RECIPIENT_REMOVED,
        payload: {
            index,
        },
    });
};

export const updateRequirements = (name: DataFields, data: any): AppThunk => (
    dispatch
) => {
    dispatch({
        type: KYC_DATA_REQUIREMENT_UPDATE,
        payload: {
            name,
            data,
        },
    });
};

export const updateEmailTemplate = (
    type: TemplateType,
    template: EmailTemplate
): AppThunk => (dispatch) => {
    dispatch({
        type: KYC_EMAIL_TEMPLATE_UPDATED,
        payload: {
            type,
            template,
        },
    });
};

export const updateData = (name: DataFields, value: string): AppThunk => (
    dispatch
) => {
    dispatch({
        type: KYC_DATA_UPDATE,
        payload: {
            name,
            value,
        },
    });
};

export const validateData = (name: DataFields, isValid: boolean): AppThunk => (
    dispatch
) => {
    dispatch({
        type: KYC_DATA_VALIDATED,
        payload: {
            name,
            isValid,
        },
    });
};

export const updateAttachments = (
    name: DataFields,
    value: File | null
): AppThunk => (dispatch) => {
    dispatch({
        type: KYC_ATTACHMENT_UPDATE,
        payload: {
            name,
            value,
        },
    });
};

export const submitKYC = (kycToken: string): AppThunk => async (
    dispatch,
    getState
) => {
    dispatch({
        type: KYC_SUBMIT_REQUEST,
    });

    const reduxState = getState();
    const kyc = reduxState.kyc.instance;

    let kycFields = {
        recipientNameTitle: kyc.recipient.name,
        recipientName: kyc.recipient.name,
        customerName: kyc.sender.company,
    };

    Object.keys(kyc.data).forEach((key) => {
        if (kyc.data[key].value === '') {
            kycFields[key] = '- n/a -';

            return;
        }

        if (kyc.data[key].value) {
            const isSSNField = key === 'ssn';
            const fieldValue = isSSNField
                ? // We need only the value of the ssn, not the country
                  splitAggregatedSSN(kyc.data[key].value)?.ssn
                : kyc.data[key].value;

            kycFields[key] = fieldValue;
        }
    });

    let attachments = Object.keys(kyc.attachments)
        .filter((key) => kyc.attachments[key].value !== null)
        .map((key) => {
            return {
                type: key,
                file: kyc.attachments[key].value,
            };
        });

    let kycRecipient: any = {
        name: kyc.recipient.name,
        email: kyc.recipient.email,
        ssn: kyc.data.ssn.value,
    };

    if (kycRecipient.ssn === '') {
        delete kycRecipient.ssn;
    }

    const payload = {
        title: kyc.title,
        groupName: 'forms',
        userData: {
            initial: {
                form: {
                    fields: kycFields,
                },
                caseFile: {
                    title: kyc.title,
                    document: {
                        title: kyc.title,
                    },
                    signers: [kycRecipient],
                },
            },
        },
    };

    try {
        // Initiate KYC
        const workflowResponse = await FormAPI.post(
            `/v2/prototype/workflows/kyc/${kycToken}/instantiate`,
            payload
        );
        const authResponse = await PublicAuthAPI.post(
            `/token/preshared?compatibility=false`,
            {
                token: workflowResponse.metaData.token,
            }
        );

        // Persist KYC files
        await persistAttachments(
            workflowResponse,
            attachments,
            authResponse.token,
            kyc.recipient.email
        );

        // Redirect to signing page
        const response = await WorkflowAPI.get(
            `/v2/workflows/${workflowResponse.id}/next`,
            null,
            {
                token: authResponse.token,
            }
        );

        document.location.replace(response.url);
    } catch (error) {
        dispatch({
            type: KYC_SUBMIT_FAILURE,
            payload: {
                error,
            },
        });
    }
};

export const fetchKYCData = (kycToken: string): AppThunk => async (
    dispatch
) => {
    dispatch({
        type: KYC_FETCH_REQUEST,
    });

    try {
        const kycData = await FormAPI.get(`/v2/kyc/${kycToken}`);

        dispatch({
            type: KYC_FETCH_SUCCESS,
            payload: kycData,
        });
    } catch (error) {
        dispatch({
            type: KYC_FETCH_FAILURE,
            payload: {
                error,
            },
        });
    }
};

const kycListRequestQueue: CancelTokenSource[] = [];

// @duplicate: A very similar function exists in archiveReducer.ts.
// @todo: Make implementing request cancelling easier.
const removeTokenFromRequestQueue = (cancelToken) => {
    const requestIndex = kycListRequestQueue.indexOf(cancelToken);

    kycListRequestQueue.splice(requestIndex, 1);
};

type KycListQueryParams = {
    sort?: string;
    isFilled?: boolean;
    page?: number;
    limit?: number;
};

type casefileStatusesResponse = {
    error: {
        forbidden: {
            [casefileId: number]: string;
        }[];
    };
    statuses: {
        [casefileId: number]: string;
    };
};

const mergeCasefileStatusData = async (kycItems: KYCList['data']) => {
    const caseFileIds = kycItems.map((kyc) => kyc.caseFileId);
    const casefileStatuses: casefileStatusesResponse = await SigningAPI.post(
        '/v2/casefiles/selected/statuses',
        { caseFileIds }
    );

    return kycItems.map((kyc) => {
        if (kyc.caseFileId) {
            kyc.status = casefileStatuses.statuses[kyc.caseFileId];
        }

        return kyc;
    });
};

export const fetchKYCList = (
    query: KycListQueryParams = {}
): AppThunk => async (dispatch) => {
    // Cancel all pending requests
    kycListRequestQueue.forEach((req) => req.cancel());

    // Add a new cancelToken to allow the request to be aborted
    const cancelToken = FormAPI.getCancelToken();

    kycListRequestQueue.unshift(cancelToken);

    try {
        dispatch({
            type: KYC_FETCH_LIST_REQUEST,
        });

        // Add cancelToken to request
        const options = {
            paginate: true,
            cancelToken: cancelToken.token,
        };

        // Fetch KYC details and requirements data
        let response = await FormAPI.get(`/v2/kycs`, query, options);

        // Enrich KYC data with casefile status when available (if KYCs are filled)
        if (query.isFilled) {
            const kycEnriched = await mergeCasefileStatusData(response.data);

            response.data = kycEnriched;
        }

        removeTokenFromRequestQueue(cancelToken);

        dispatch({
            type: KYC_FETCH_LIST_SUCCESS,
            payload: {
                items: response.data,
                itemCount: response.count,
            },
        });
    } catch (error) {
        removeTokenFromRequestQueue(cancelToken);

        dispatch({
            type: KYC_FETCH_LIST_FAILURE,
            payload: {
                error,
            },
        });
    }
};

export const fetchKYCById = (id: number): AppThunk => async (
    dispatch,
    getState
) => {
    try {
        const cachedItem = getState().kyc.items.data.find((kyc) => kyc.id);

        dispatch({
            type: KYC_FETCH_ITEM_REQUEST,
            payload: cachedItem,
        });

        // Fetch KYC details and requirements data
        const response = await FormAPI.get(`/v2/kycs`, { id });

        // If the KYC isn't found, dispatch custom not found error.
        // When the KYC is not in the response, instead of returning an empty array, this endpoint returns an empty object `{}`
        if (!Array.isArray(response)) {
            return dispatch({
                type: KYC_FETCH_ITEM_FAILURE,
                payload: {
                    error: {
                        status: 404,
                        message: `Couldn't find a KYC with ID ${id}`,
                    },
                },
            });
        }

        dispatch({
            type: KYC_FETCH_ITEM_SUCCESS,
            payload: response[0], // When item is found, it's the only item of the array.
        });
    } catch (error) {
        dispatch({
            type: KYC_FETCH_ITEM_FAILURE,
            payload: {
                error,
            },
        });
    }
};

export const createKYC = (): AppThunk => async (dispatch, getState) => {
    dispatch({
        type: KYC_SEND_REQUEST,
    });

    const reduxState = getState();
    const kyc = reduxState.kyc.__new;
    const user = AuthStore.getUser();
    const customer = AuthStore.getCustomer();
    const validationData = Object.keys(kyc.requirements)
        .filter((key) => kyc.requirements[key].enabled)
        .map((key) => {
            const req: KYCRequirementItem = kyc.requirements[key];

            return {
                name: key,
                type: req.type,
                required: !!req.required,
            };
        });

    const payload = {
        data: {
            kyc: {
                title: kyc.title,
                description: kyc.description,
                language: kyc.language,
                validationData,
                recipients: kyc.recipients,
                messageFormat: 'html',
                messageSubject:
                    kyc.emailTemplates[TemplateType.INITIAL].subject,
                messageText: kyc.emailTemplates[TemplateType.INITIAL].message,
                reminderMessageSubject:
                    kyc.emailTemplates[TemplateType.REMINDER].subject,
                reminderMessageText:
                    kyc.emailTemplates[TemplateType.REMINDER].message,
                reminderInterval: kyc.reminderInterval,
            },
            workflow: {
                initial: {
                    form: {
                        fields: {
                            placeholder: 'placeholder',
                        },
                    },
                    caseFile: {
                        title: 'placeholder',
                        folderId: kyc.folderId,
                        document: {
                            title: 'placeholder',
                        },
                        sensitiveData: false,
                        enableInsecureSigning: false,
                        signers: [
                            {
                                name: 'placeholder',
                                email: 'placeholder@penneo.com',
                            },
                        ],
                    },
                    emails: {
                        sender: {
                            name: user.fullName,
                            email: user.email,
                            company: customer.name,
                        },
                    },
                },
            },
        },
    };

    try {
        // Create KYC
        const response = await FormAPI.post('/v2/kyc', payload);

        Analytics.track('kyc - sent', {
            id: response.id,
            language: kyc.language,
            recipientCount: kyc.recipients.length,
            folderId: kyc.folderId,
            reminderInterval: kyc.reminderInterval,
            requirements: validationData.map((data) => data.name),
        });

        Amplitude.incrementUserProperty('KYCs sent');

        dispatch({
            type: KYC_SEND_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: KYC_SEND_FAILURE,
            payload: {
                error,
            },
        });
    }
};

const persistAttachments = async (workflow, attachments, token, email) => {
    if (!attachments || attachments.length === 0) {
        return Promise.resolve();
    }

    let promises = attachments.map(async (attachment) => {
        await persistAttachment(workflow.id, attachment, token, email);
    });

    return Promise.all(promises);
};

const persistAttachment = async (workflowId, attachment, token, email) => {
    // In case of kyc verification there will always be only one item in the array
    let data = {
        title: getAttachmentTitle(attachment, email),
    };

    let options = {};

    if (token) {
        options = {
            token: token,
        };
    }

    let endpoint = `/v2/workflows/${workflowId}/documents`;
    const doc = await WorkflowAPI.post(endpoint, data, options);
    let content = {
        file: attachment.file,
    };

    await WorkflowAPI.file(`${endpoint}/${doc.id}/content`, content, options);

    return doc;
};

const getAttachmentTitle = (attachment, email) => {
    const fileName = attachment.file.name;

    if (fileName.length > 0) {
        const titleParts: string[] = fileName.split('.');
        const fileExtension: string = titleParts[titleParts.length - 1];

        return `${email}_${attachment.type}.${fileExtension}`;
    }
};
