import { Dispatcher, BaseStore } from 'Core';
import { ActionTypes } from '../Constants';
import assign from 'object-assign';
import lodash from 'lodash';
import moment from 'moment';

let _store = {
    // Server state
    news: [],
    error: null,
    fetching: false,

    // View State
    open: false,
};

function updateUnreadNews() {
    _store.news.forEach((n) => {
        n.unread = false;
    });
}

function updateNews(news, lastRead = 0) {
    _store.fetching = false;

    // Order by published date.
    let _news = lodash.orderBy(news, (n) => moment(n.date_published).unix(), [
        'desc',
    ]);

    _news.forEach((n) => {
        if (moment(n.date_published).unix() > lastRead) {
            n.unread = true;
            n.new = true;
        }
    });

    _store.news = _news;
}

function updateError(error) {
    _store.fetching = false;
    _store.error = error;
}

// Flux Store Creation
const NewsStore = assign({}, BaseStore, {
    getState() {
        return _store;
    },

    getUnreadItems() {
        return _store.news.filter((n) => n.unread);
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case ActionTypes.FETCH_SUCCESS:
                updateNews(action.news, action.lastRead);
                break;
            case ActionTypes.FETCH_FAILURE:
                updateError(action.error);
                break;
            case ActionTypes.FETCH_REQUEST:
                _store.fetching = true;
                _store.error = null;
                break;
            case ActionTypes.TOGGLE_WIDGET:
                _store.open = !_store.open;
                break;
            case ActionTypes.UPDATE_TIMESTAMP_REQUEST:
            case ActionTypes.UPDATE_TIMESTAMP_SUCCESS:
                updateUnreadNews(action.timestamp);
                break;
            default:
                return false;
        }
        NewsStore.emitChange();
    }),
});

export default NewsStore;
