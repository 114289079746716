// Get Login/Sign Parameters actions
export const OPENID_INIT_REQUEST = 'OPENID_INIT_REQUEST';
export const OPENID_INIT_FAILURE = 'OPENID_INIT_FAILURE';
export const OPENID_INIT_SUCCESS = 'OPENID_INIT_SUCCESS';

// Process Requests
export const OPENID_LOGIN_REQUEST = 'OPENID_LOGIN_REQUEST';
export const OPENID_LOGIN_FAILURE = 'OPENID_LOGIN_FAILURE';
export const OPENID_LOGIN_SUCCESS = 'OPENID_LOGIN_SUCCESS';

export const OPENID_COLLECT_REQUEST = 'OPENID_COLLECT_REQUEST';
export const OPENID_COLLECT_SUCCESS = 'OPENID_COLLECT_SUCCESS';
export const OPENID_COLLECT_FAILURE = 'OPENID_COLLECT_FAILURE';

// Fetch
export const OPENID_FETCH_REQUEST = 'OPENID_FETCH_REQUEST';
export const OPENID_FETCH_FAILURE = 'OPENID_FETCH_FAILURE';
export const OPENID_FETCH_SUCCESS = 'OPENID_FETCH_SUCCESS';

// Connect
export const OPENID_ADD_REQUEST = 'OPENID_ADD_REQUEST';
export const OPENID_ADD_FAILURE = 'OPENID_ADD_FAILURE';
export const OPENID_ADD_SUCCESS = 'OPENID_ADD_SUCCESS';

// Delete
export const OPENID_REMOVE_REQUEST = 'OPENID_REMOVE_REQUEST';
export const OPENID_REMOVE_FAILURE = 'OPENID_REMOVE_FAILURE';
export const OPENID_REMOVE_SUCCESS = 'OPENID_REMOVE_SUCCESS';

export const OPENID_SIGN_INIT_REQUEST = 'OPENID_SIGN_INIT_REQUEST';
export const OPENID_SIGN_INIT_SUCCESS = 'OPENID_SIGN_INIT_SUCCESS';
export const OPENID_SIGN_INIT_FAILURE = 'OPENID_SIGN_INIT_FAILURE';

export const OPENID_SIGN_REQUEST = 'OPENID_SIGN_REQUEST';
export const OPENID_SIGN_SUCCESS = 'OPENID_SIGN_SUCCESS';
export const OPENID_SIGN_FAILURE = 'OPENID_SIGN_FAILURE';

// View actions
export const OPENID_CACHE_INTENT = 'OPENID_CACHE_INTENT';
export const OPENID_RESET_STATE = 'OPENID_RESET_STATE';

export const OPENID_DISPLAY_STORAGE_OPTIONS = 'OPENID_DISPLAY_STORAGE_OPTIONS';
export const OPENID_SIGN_DATA_FETCH_SUCCESS = 'OPENID_SIGN_DATA_FETCH_SUCCESS';
