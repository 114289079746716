import { defaultBranding } from 'Branding/Constants';
import { InsecureSigningMethod, SigningRequestEntity } from 'types/Signer';
import { DocumentEntity } from 'types/Document';
import { CaseFileEntity } from 'types/CaseFile';
import { CustomerSigningMethod } from './Customer';
import { Languages } from 'Language/Constants';
import { PersonIdentifierType } from 'EID/types';

export type CustomerBranding = Omit<typeof defaultBranding, 'imageUrl'> & {
    logoUrl: string;
};

export enum signingStages {
    init = 'init',
    signed = 'signed',
    rejected = 'rejected',
}

export enum signatureLineStatus {
    'STATUS_UNKNOWN' = 'STATUS_UNKNOWN',
    'READY_TO_SIGN' = 'READY_TO_SIGN',
    'REQUEST_NOT_ACTIVE' = 'REQUEST_NOT_ACTIVE',
    'NOTHING_TO_SIGN' = 'NOTHING_TO_SIGN',
    'PENDING_OTHER_SIGNERS' = 'PENDING_OTHER_SIGNERS',
    'PENDING_UNTIL_DTM' = 'PENDING_UNTIL_DTM',
    'SIGNATURE_LINE_EXPIRED' = 'SIGNATURE_LINE_EXPIRED',
}

export enum signingRequestStatus {
    signed = 'signed',
    rejected = 'rejected',
    deleted = 'deleted',
    expired = 'expired',
    pending = 'pending',
}

export type SigningCustomer = {
    // Here we are adding the allowedSigningMethods on a customer/company to get a non-influenced list of allowed signing methods for the company
    // which allows us to override the imageUpload settings on individual signers in the context of NAP.
    allowedSimpleSigningMethods: CustomerSigningMethod[];
    branding?: CustomerBranding;
    name: string;
    signerArchiveResponsibility: string;
    temporalStorageDefault: boolean;
    id?: number;
    allowSigningWithNap: boolean;
};

export type SigningCasefile = Pick<
    CaseFileEntity,
    'title' | 'status' | 'language' | 'sensitiveData'
>;

export type SigningCasefileLimited = SigningCasefile &
    Pick<CaseFileEntity, 'caseFileTypeName'>;

export type SigningDocument = Pick<
    DocumentEntity,
    'id' | 'signatureLines' | 'caseFileId'
>;

/**
 * Reduced view of the SigningRequestEntity, that only shows data is that is safe to share
 * publicly.
 */
type PublicSigningRequestData = SignerValidationParameters &
    Pick<
        SigningRequestEntity,
        | 'accessControl'
        | 'enableInsecureSigning'
        | 'id'
        | 'reminderInterval'
        | 'status'
        | 'language'
    > & {
        insecureSigningMethods?: InsecureSigningMethod[];
    };

type SignerData = {
    canAccessMarketingPage: boolean;
};

export type SigningProcess = {
    /** Signing methods enabled for the customer */
    allowedSigningMethods: CustomerSigningMethod[];
    caseFile: SigningCasefile;
    customer: SigningCustomer;
    documents: SigningDocument[];
    isRegisteredLetter: boolean;
    encryptedNIN: string;
    maskedPhoneNumber: string;
    signingRequest: PublicSigningRequestData;
};

export type SigningProcessLimited = {
    customer: SigningCustomer;
    caseFile: SigningCasefileLimited;
};
export type SigningProcessError = Error & {
    status?: number;
    data?: {
        data?: SigningProcess;
        errors: {
            caseFileExpired: boolean;
            signatureLineStatus: signatureLineStatus;
            signingRequestStatus: signingRequestStatus;
            completedCasefileAccessibleViaSigningLink: boolean;
        };
    };
};

export type SigningProcessValidation = Pick<
    SigningProcess,
    | 'customer'
    | 'allowedSigningMethods'
    | 'encryptedNIN'
    | 'maskedPhoneNumber'
    | 'isRegisteredLetter'
    | 'signingRequest'
> &
    SignerValidationParameters & {
        language: Languages;
    };

export type SignerValidationParameters = {
    isSpecificSignerExpected: boolean;
    expectedEid: PersonIdentifierType;
    expectedVatin?: string;
};

export type SigningProcessWithOutcome = Pick<
    SigningProcessLimited,
    'caseFile' | 'customer'
> & { signer: SignerData } & {
    outcome?: Omit<signingStages, signingStages.init>;
};

export type SigningProcessSuccess = Pick<
    SigningProcess,
    'caseFile' | 'customer' | 'documents'
>;
