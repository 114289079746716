// @see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/generateKey
import { algorithm } from 'OpenID/Constants';
import { getCrypto } from './crypto';
import { KeyPair } from 'OpenID/redux/types';

export const createKeyPair = (): Promise<KeyPair> => {
    const crypto = getCrypto();
    const extractable = true; // Indicates whether it will be possible to export the key using SubtleCrypto.exportKey().
    const keyUsages = ['sign', 'verify'];

    return Promise.resolve(
        crypto.getCrypto().generateKey(algorithm, extractable, keyUsages)
    );
};
