import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import UserAvatar from 'Common/components/UserAvatar';
import UserPickerModal from 'Common/components/UserPickerModal';
import { modal } from 'Common/components/Common/Modal.jsx';
import lodash from 'lodash';
import Button from 'Common/components/Button';

export default class MemberList extends React.Component {
    static propTypes = {
        members: PropTypes.array,
        remove: PropTypes.func,
        filter: PropTypes.func,
        availableUsers: PropTypes.array,
        add: PropTypes.func,
        query: PropTypes.string,
    };

    state = {
        canEdit: false,
    };

    addMembersModal = () => {
        let config = {
            title: 'test',
            component: (
                <UserPickerModal
                    users={this.getNonMembers()}
                    title="Add members for this team"
                    successButtonLabel="Add"
                    successButton={this.add}
                />
            ),
            preventClose: true,
        };

        modal.show(config);
    };

    add = (members) => {
        this.props.add(members);
        modal.hide();
    };

    // Filters out team members from the available users
    getNonMembers = () => {
        let { members, availableUsers } = this.props;

        return lodash.differenceBy(availableUsers, members, 'id');
    };

    filterList = (event) => {
        this.props.filter(event.target.value);
    };

    render() {
        let { members, remove, query } = this.props;

        return (
            <div className="member-list">
                <div className="member-list-actions">
                    <span className="search-bar">
                        <input
                            type="text"
                            style={{ width: 200 }}
                            onChange={this.filterList}
                            placeholder={i18n('Type to filter')}
                        />
                    </span>

                    <Button
                        theme="blue"
                        variant="outline"
                        className="pull-right"
                        onClick={this.addMembersModal}
                        icon="fa fa-user-plus"
                        renderIconLeft={true}>
                        {i18n`Add members`}
                    </Button>
                </div>

                <div className="member-list-area">
                    {members.length > 0 ? (
                        members.map((member, index) => (
                            <div className="member-item" key={index}>
                                <div className="member-item-avatar">
                                    <UserAvatar user={member} />
                                </div>

                                <div className="member-item-details">
                                    {member.fullName}
                                    <br />
                                    {member.email}
                                </div>

                                <div className="member-item-actions">
                                    <div className="member-item-remove">
                                        <i
                                            className="fa fa-times-circle"
                                            onClick={remove.bind(
                                                null,
                                                index
                                            )}></i>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="member-list-area-empty">
                            {query === '' ? (
                                <div className="member-list-area-empty-graphic">
                                    <i className="fas fa-users-class" />
                                    <i className="far fa-plus" />

                                    <h2>{i18n`Add members of the team`}</h2>
                                </div>
                            ) : (
                                <h2>{i18n`Could not find any results for "${query}"`}</h2>
                            )}
                        </div>
                    )}
                </div>
                {members.length > 0 && (
                    <div className="text-right pt">
                        {members.length}&nbsp;
                        {members.length === 1 ? i18n`Member` : i18n`Members`}
                    </div>
                )}
            </div>
        );
    }
}
