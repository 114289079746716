import { AppThunk } from 'Store';
import { SigningAPI } from 'Api';
import CustomerUtils from 'Auth/utils/customer';

import lodash from 'lodash';

import {
    CUSTOMER_FETCH_REQUEST,
    CUSTOMER_FETCH_SUCCESS,
    CUSTOMER_FETCH_FAILURE,
    CUSTOMER_UPDATE_REQUEST,
    CUSTOMER_UPDATE_SUCCESS,
    CUSTOMER_UPDATE_ERROR,
} from './reducer';

export const updateCustomer = (
    customerId: number,
    customerData: { [key: string]: boolean | string | number }
): AppThunk => async (dispatch, getState) => {
    dispatch({
        type: CUSTOMER_UPDATE_REQUEST,
    });

    const customer = getState().customer.details.data;
    // Prepare customer object to be updated - amend new data and remove properties that should not be send
    const payload = lodash.omit(
        {
            ...customer,
            ...customerData,
        },
        [
            // Read only
            'id',
            'sdkClassName',

            // Store properties
            'branding',
        ]
    );

    if (typeof payload.status !== 'undefined') {
        payload.status = CustomerUtils.parseCustomerStatus(payload.status);
    }

    try {
        // Fetch customer branding
        const response = await SigningAPI.put(
            `/v2/customers/${customerId}`,
            payload
        );
        const updatedCustomer = {
            ...response,
            allowedSigningMethods: CustomerUtils.fixAllowedSigningMethods(
                response.allowedSigningMethods
            ),
        };

        dispatch({
            type: CUSTOMER_UPDATE_SUCCESS,
            payload: updatedCustomer,
        });
    } catch (error) {
        dispatch({
            type: CUSTOMER_UPDATE_ERROR,
            payload: {
                error,
            },
        });
    }
};

export const fetchCustomer = (customerId: number): AppThunk => async (
    dispatch
) => {
    dispatch({
        type: CUSTOMER_FETCH_REQUEST,
    });

    try {
        // Fetch customer branding
        const response = await SigningAPI.get(`/v2/customers/${customerId}`);
        const customer = {
            ...response,
            allowedSigningMethods: CustomerUtils.fixAllowedSigningMethods(
                response.allowedSigningMethods
            ),
        };

        dispatch({
            type: CUSTOMER_FETCH_SUCCESS,
            payload: customer,
        });
    } catch (error) {
        dispatch({
            type: CUSTOMER_FETCH_FAILURE,
            payload: {
                error,
            },
        });
    }
};
