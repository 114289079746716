import PropTypes from 'prop-types';
import React from 'react';
import Widget from 'Common/components/Widget';
import SignaturePad from 'Common/components/SignaturePad';
import Tabs from 'Common/components/Tabs';
import ImageUpload from './ImageUpload';
import TextSignature from './TextSignature';

export default class VisualSignature extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        signerName: PropTypes.string,
        availableMethods: PropTypes.array,
    };

    constructor(props) {
        //FYI: https://overreacted.io/why-do-we-write-super-props/
        super(props);
        this.state = {
            mounted: false,
        };
    }

    componentDidMount() {
        this.setState({
            mounted: true,
        });
    }

    onChangeHandler = (base64image) => {
        this.props.onChange(base64image);
    };

    methodRender = (method, signerName) => {
        // prettier-ignore
        return ({
            draw: (
                <div label="Draw" icon="far fa-pen-nib" key={method}>
                    <SignaturePad
                        onChange={this.onChangeHandler}
                        clear={true}
                        undo={true}
                    />
                </div>
            ),
            image: (
                <div label="Image" icon="far fa-image" key={method}>
                    <ImageUpload onChange={this.onChangeHandler} />
                </div>
            ),
            text: (
                <div label="Text" icon="far fa-keyboard" key={method}>
                    <TextSignature
                        onChange={this.onChangeHandler}
                        placeholder={signerName}
                    />
                </div>
            ),
        })?.[method] ?? null;
    };

    render() {
        let { signerName, availableMethods } = this.props;
        const { mounted } = this.state;

        return (
            <Widget>
                {mounted && (
                    <Tabs>
                        {[
                            ...((availableMethods?.length > 0 &&
                                availableMethods) || ['draw', 'image', 'text']),
                        ].map((m) => this.methodRender(m, signerName))}
                    </Tabs>
                )}
            </Widget>
        );
    }
}
