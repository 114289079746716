import { CaseFileItem } from '../../';

export type TransformToNestedChildren = CaseFileItem & {
    children?: any[];
};

const getNestedChildren = (arr: any[], id?: number) => {
    let outPut: TransformToNestedChildren[] = [];

    for (let i in arr) {
        let current = arr[i];

        if (
            (id && current.parentId === id) ||
            (typeof id === 'undefined' && !current.parentId)
        ) {
            const children = getNestedChildren(arr, current.id);

            if (children.length) {
                current.children = children;
            }

            outPut.push(current as any);
        }
    }

    return outPut;
};

export default function transformToNestedChildren(array: CaseFileItem[]) {
    return getNestedChildren(array);
}
