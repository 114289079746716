import { Dispatcher } from 'Core';
import AuthActions from './AuthActionCreators';
import { PasswordActions as Actions } from '../ActionTypes';
import { AuthAPI } from 'Api';
import analytics from 'Common/Analytics';

const passwordActionCreators = {
    updateUsername(username) {
        Dispatcher.handleViewAction({
            type: Actions.PASSWORD_UPDATE_USERNAME,
            username: username,
        });
    },

    updatePassword(password) {
        Dispatcher.handleViewAction({
            type: Actions.PASSWORD_UPDATE_PASSWORD,
            password: password,
        });
    },

    login(username, password) {
        let credentials = {
            username: username,
            password: password,
        };

        Dispatcher.handleViewAction({
            type: Actions.AUTHENTICATION_REQUEST,
        });

        const options = {
            onSuccess() {
                analytics.track('log in', {
                    method: 'Global - Classic Credentials',
                });
            },
            onError() {
                analytics.track('log in error', {
                    method: 'Global - Classic Credentials',
                });
            },
        };

        return AuthActions.authenticate(
            '/token/password',
            credentials,
            options
        ).catch((error) => error);
    },

    fetchPasswordCredentials() {
        AuthAPI.get('/cred/password')
            .then((credentials) => {
                Dispatcher.handleServerAction({
                    type: Actions.PASSWORD_FETCH_SUCCESS,
                    credentials: credentials,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: Actions.PASSWORD_FETCH_ERROR,
                    error: error,
                });
            });
    },

    updateCredentials(username, password) {
        let payload = {
            username: username,
            password: password,
        };

        Dispatcher.handleViewAction({
            type: Actions.PASSWORD_CHANGE_REQUEST,
        });

        AuthAPI.put('/cred/password', payload)
            .then((credentials) => {
                Dispatcher.handleServerAction({
                    type: Actions.PASSWORD_CHANGE_SUCCESS,
                    credentials: credentials,
                });
            })
            .catch((error) => {
                if (error.status === 404) {
                    return this.createCredentials(payload);
                }

                Dispatcher.handleServerAction({
                    type: Actions.PASSWORD_CHANGE_ERROR,
                    error: error.data.error,
                });
            });
    },

    createCredentials(credentials) {
        Dispatcher.handleViewAction({
            type: Actions.PASSWORD_CREATE_REQUEST,
        });

        AuthAPI.post('/cred/password', credentials)
            .then((response) => {
                Dispatcher.handleServerAction({
                    type: Actions.PASSWORD_CREATE_SUCCESS,
                    credentials: response,
                });
            })
            .catch((error) => {
                let code;

                // @todo: remove hardcoded error, create generic error message library.
                if (error.status === 409) {
                    code = 'username-taken';
                }

                Dispatcher.handleServerAction({
                    type: Actions.PASSWORD_CREATE_ERROR,
                    error: error,
                    code: code,
                });
            });
    },
};

export default passwordActionCreators;
