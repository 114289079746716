import React from 'react';
import Widget from 'Common/components/Widget';
import WidgetHeader from 'Common/components/WidgetHeader';
import Table, { Config as TableConfig } from 'Common/components/BaseTable';
import { i18n } from 'Language';
import { ContactListPagination } from 'Contacts/redux/types';
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import { changePagination, fetchContacts } from 'Contacts/redux/actions';
import { Contact } from 'types/Contact';
import WidgetBody from 'Common/components/WidgetBody';
import ContactListRowActions from '../ContactListRowActions';
import ContactListRowEditableField from '../ContactListRowEditableField';
import { notify } from 'react-notify-toast';
import './contact-list.scss';
import ContactListHeader from 'Contacts/components/ContactListHeader';

/**
 * Displays a visual confirmation that a row was saved successfully
 * @param rowIndex - number. Row index in the table
 */
const onSaveRow = (rowIndex: number, response: any) => {
    if (response.isApiError) {
        showErrorNotification(response);
        highlightRow(rowIndex, 'error');
    } else {
        highlightRow(rowIndex, 'success');
    }
};

const highlightRow = (rowIndex: number, type: 'success' | 'error') => {
    const rows = document.querySelectorAll('.contact-list-table tr');

    if (!rows) {
        return;
    }

    const row: Element = rows[rowIndex + 1];

    row.classList.add(`highlight-${type}`);

    setTimeout(() => {
        row.classList.remove(`highlight-${type}`);
    }, 500);
};

const showErrorNotification = (error) => {
    notify.show(error.data.message, 'error');
};

const getTableConfig = (): TableConfig => {
    return {
        name: {
            label: i18n`Name`,
            disableAnimation: true,
            component: (contact: Contact) => (
                // @ts-ignore
                <ContactListRowEditableField
                    contact={contact}
                    property={'name'}
                    onSaveRow={onSaveRow}
                    autoFocus
                />
            ),
        },
        email: {
            label: i18n`Email`,
            disableAnimation: true,
            component: (contact: Contact) => (
                // @ts-ignore
                <ContactListRowEditableField
                    contact={contact}
                    property={'email'}
                    onSaveRow={onSaveRow}
                />
            ),
        },
        onBehalfOf: {
            label: i18n`Company`,
            disableAnimation: true,
            component: (contact: Contact) => (
                // @ts-ignore
                <ContactListRowEditableField
                    contact={contact}
                    property={'onBehalfOf'}
                    onSaveRow={onSaveRow}
                />
            ),
        },
        actions: {
            label: i18n`Actions`,
            tdClassName: 'no-padding',
            disableAnimation: true,
            disableSort: true,
            component: (contact: Contact) => (
                // @ts-ignore
                <ContactListRowActions
                    contact={contact}
                    onSaveRow={onSaveRow}
                />
            ),
        },
    };
};

export type Props = {
    contacts: Contact[];
    pagination: ContactListPagination;
    isFetching: boolean;
    isLoaded: boolean;
    error: any;
    dispatch: AppDispatch;
};

export class ContactList extends React.Component<Props> {
    componentDidMount() {
        this.props.dispatch(fetchContacts());
    }

    handleTableChange(property, value) {
        this.props.dispatch(changePagination(property, value));
    }

    render() {
        const { contacts, isFetching, pagination } = this.props;

        return (
            <Widget className="medium">
                <WidgetHeader>
                    <h1>
                        {i18n`Manage contacts`}
                        <span className="dot">.</span>
                    </h1>
                    <p>{i18n`Add, edit or delete contacts for auto suggestions`}</p>
                </WidgetHeader>

                <WidgetBody>
                    <Table
                        className="contact-list-table"
                        limit={pagination.per_page}
                        page={pagination.page}
                        sort={pagination.sort}
                        config={getTableConfig()}
                        dataSource={contacts}
                        dataCount={pagination.count}
                        onLimitChange={(perPage) =>
                            this.handleTableChange('per_page', perPage)
                        }
                        onSortChange={(sort) =>
                            this.handleTableChange('sort', sort)
                        }
                        onNext={(page) => this.handleTableChange('page', page)}
                        onPrev={(page) => this.handleTableChange('page', page)}
                        onPageChange={(page) =>
                            this.handleTableChange('page', page)
                        }
                        isLoading={isFetching}
                        hasError={false}
                        headComponent={
                            <ContactListHeader
                                dataCount={pagination.count}
                                searchValue={pagination.fullSearch}
                                onSearchInputChange={(term) =>
                                    this.handleTableChange('fullSearch', term)
                                }
                                clearSearch={() =>
                                    this.handleTableChange('fullSearch', '')
                                }
                            />
                        }></Table>
                </WidgetBody>
            </Widget>
        );
    }
}

export default connect((state: ReduxState) => {
    return {
        pagination: state.contacts.pagination,
        contacts: state.contacts.data,
        isFetching: state.contacts.isFetching,
        isLoaded: state.contacts.isLoaded,
        error: state.contacts.error,
    };
})(ContactList);
