import React from 'react';
import classnames from 'classnames';

type Props = {
    size?: 'small' | 'large';
    theme?: 'light' | 'inline';
};

const Loading = (props: Props) => (
    <input
        type={'progress'}
        value={'indeterminate'}
        className={classnames('penneo-loader', {
            small: props.size === 'small',
            large: props.size === 'large',
            light: props.theme === 'light',
            inline: props.theme === 'inline',
        })}
    />
);

export default Loading;
