import React from 'react';
import { i18n } from 'Language';
import { CustomerBranding } from './redux/types';
import Button from 'Common/components/Button';

import './image-picker.scss';

type Props = {
    /**
     * Function that triggers on change.
     * Takes in a file as a parameter
     */
    onChange: (file: File) => void;
    /**
     * The label that is going to be displayed above the image picker.
     * Needs to be translated before passing to this component
     */
    label?: string;
    /**
     * Customer Branding object
     */
    branding?: CustomerBranding;
};

export default class ImagePicker extends React.Component<Props> {
    // ref
    fileUploadInput;

    // Document upload by browse
    _onButtonHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            this._handleFile(event.target.files[0]);
        }
    };

    _handleFile(file: File) {
        this.props.onChange(file);
    }

    renderPreview = () => {
        const { branding } = this.props;

        let topBarPreview = {
            background: 'none',
            color: 'none',
        };

        let imagePreview = {
            backgroundImage: 'none',
        };

        if (branding) {
            topBarPreview.background = branding.backgroundColor;
            topBarPreview.color = branding.textColor;
            imagePreview.backgroundImage = `url(${branding.imageUrl})`;
        }

        if (branding && !branding.imageUrl) {
            return i18n`no preview available`;
        }

        return (
            <>
                <label>{i18n`Preview`}</label>
                <div className="top-bar-preview" style={topBarPreview}>
                    <div className="image-wrapper">
                        <div className="image" style={imagePreview} />
                    </div>
                    <div className="top-bar-preview-links">
                        <span className="preview-link">{i18n`Link`}</span>
                    </div>
                </div>
            </>
        );
    };

    render() {
        const { label } = this.props;

        return (
            <div className="image-picker" style={{ position: 'relative' }}>
                {label && <label>{i18n(label)}</label>}
                <input
                    type="file"
                    ref={(ref) => (this.fileUploadInput = ref)}
                    name="fileUploader"
                    className="fileUploader"
                    style={{ display: 'none' }}
                    accept="image/x-png,image/jpeg"
                    onChange={this._onButtonHandler}
                />

                <div className="image-picker-content">
                    <Button
                        variant="outline"
                        theme="blue"
                        icon="far fa-upload"
                        onClick={() => this.fileUploadInput.click()}
                        renderIconLeft={true}>
                        {i18n`Select a file from your computer`}
                    </Button>

                    <div className="image-picker-metadata text-small">
                        {i18n`Max size: 440px by 110px`}
                    </div>

                    {this.renderPreview()}
                </div>
            </div>
        );
    }
}
