import produce from 'immer';

// Types
import { BetterCaseFileType } from 'Labs/BetterFlowOverview/types';
import { ClientSideDocument } from 'types/Document';
import { RecipientSignerRole } from 'types/Recipient';
import { Recipient } from '../RecipientModal';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { RolesAndActivation } from './types';

export const getDocumentsByDocumentTypeId = (
    documents: ClientSideDocument[],
    documentTypeId: number
): ClientSideDocument[] => {
    return (
        documents.filter((doc) => doc.documentTypeId === documentTypeId) || []
    );
};

export const getSignersBySignerTypeId = (
    signers: Recipient[],
    signerTypeId: number
): Recipient[] => {
    return signers.filter((signer) => {
        // Get Only enabled roles.
        const signerTypeIds = signer.role
            .filter((role) => role.enabled)
            .map((role) => role.id);

        if (signerTypeIds.includes(signerTypeId)) {
            return true;
        }

        return false;
    });
};

export const getCustomRole = (
    signerRoles: RecipientSignerRole[],
    signerTypeId: number
): string | false => {
    const signerRole = signerRoles.find((role) => role.id === signerTypeId);

    if (!signerRole) {
        return false;
    }

    if (!signerRole.customName) {
        return false;
    }

    return signerRole.customName;
};

export const filterUnusedTypes = (
    casefileType: BetterCaseFileType,
    documents: ClientSideDocument[],
    signers: any
) => {
    const documentTypeIds = documents.map((doc) => doc.documentTypeId);

    let signerTypeIds = [] as any;

    signers.forEach((signer) => {
        signerTypeIds.push(
            ...signer.role
                .filter((role) => role.enabled)
                .map((signerType) => signerType.id)
        );
    });

    const clean = produce(casefileType, (draft) => {
        draft.documentTypes = draft.documentTypes.filter((documentType) =>
            documentTypeIds.includes(documentType.id)
        );

        draft.signerTypes = draft.signerTypes.filter((signerType) =>
            signerTypeIds.includes(signerType.id)
        );

        // Remove rounds for document types that don't exist.
        draft.rounds = draft.rounds.map((round) => {
            return round.filter((roundMap) =>
                documentTypeIds.includes(roundMap.documentTypeId)
            );
        });

        // Remove signer types for signers that don't exist.
        draft.rounds = draft.rounds.map((round) => {
            return round.map((roundMap) => {
                roundMap.signerTypeIds = roundMap.signerTypeIds.filter(
                    (signerTypeId) => signerTypeIds.includes(signerTypeId)
                );

                return roundMap;
            });
        });

        // Clean unused rounds
        // @note: Maybe it would be good to keep the rounds empty and not show them, to not lose track of the config per round.
        draft.rounds = draft.rounds.filter((round) => round.length !== 0);

        return draft;
    });

    return clean;
};

/**
 * Persist changes to rounds' activation in the backend
 * TODO - call a real endpoint!
 */
export const saveRoundsActivation = async (rounds: RolesAndActivation[]) => {
    // If the flag is off or there are no rounds, immediately return a succesful Promise
    if (
        !LaunchDarkly.variation(Flags.ACTIVATION_DATE_PER_ROUND) ||
        !rounds?.length
    ) {
        return Promise.resolve();
    }

    // TODO - real endpoint! For now just a console log
    console.log('storing rounds', rounds);

    return Promise.resolve();
};
