import PropTypes from 'prop-types';
import React from 'react';

// There is an incompatibility with IE11 when importing signature_pad normally
// @see: https://github.com/szimek/signature_pad/issues/405
import * as signaturePad from 'signature_pad/dist/signature_pad';

import { i18n } from 'Language';
import TouchActions from 'Auth/actions/TouchActions';
import Button from 'Common/components/Button';

export default class SignaturePad extends React.Component {
    static propTypes = {
        getCanvasValue: PropTypes.func,
        onChange: PropTypes.func,
        clear: PropTypes.bool,
        undo: PropTypes.bool,
    };

    state = {
        enabled: true,
    };

    canvas = null;
    signaturePad = null;

    componentDidMount() {
        window.addEventListener('resize', this.setCanvasSize);
        this.props.onChange(null);

        this.initializeCanvas();

        TouchActions.loadWidget('draw');
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setCanvasSize);

        this.canvas = null;
        this.signaturePad = null;
    }

    setCanvasSize = () => {
        if (!this.canvas) {
            return false;
        }

        const canvasHeight = 120;
        let ratio = Math.max(window.devicePixelRatio || 1, 1);

        if (ratio >= 2) {
            ratio = 2;
        }

        this.canvas.width = this.canvas.offsetWidth * ratio;
        this.canvas.height = canvasHeight * ratio * 1.5;
        this.canvas.getContext('2d').scale(ratio, ratio);

        this.clear();
    };

    initializeCanvas = () => {
        this.setCanvasSize();

        this.signaturePad = new signaturePad(this.canvas, {
            onBegin: this.disableSelection,
            onEnd: this.enableSelection,
        });
    };

    // This prevents text selection from happening during the drag actions inside the
    // canvas while the signature is being drawn
    disableSelection = () => {
        this.disableSign();

        document.body.classList.add('prevent-selection');

        /* This component is rendered into a modal window in Penneo's legacy web application
           This class needs to be applied to remove the scrolling possibility inside that modal
           to avoid a problem that affects iPhone devices where the device scrolls as the
           signature is being drawn.
        */
        (
            document.getElementById('genericModal') ||
            document.querySelector('.react-modal')
        ).classList.add('prevent-scroll');
    };

    // This reenables the text-selection capabilities after
    // a stroke is drawn in the signature pad
    enableSelection = () => {
        this.enableSign();

        document.body.classList.remove('prevent-selection');
        (
            document.getElementById('genericModal') ||
            document.querySelector('.react-modal')
        ).classList.remove('prevent-scroll');
    };

    disableSign = () => {
        this.setState({
            enabled: false,
        });
    };

    enableSign = () => {
        this.setState({
            enabled: true,
        });

        this.props.onChange(this._getValue());
    };

    clear = () => {
        if (!this.signaturePad) {
            return false;
        }

        this.signaturePad.clear();
        this.props.onChange(this._getValue());
    };

    undo = () => {
        let data = this.signaturePad.toData();

        if (data) {
            data.pop(); // removes the last dot or line
            this.signaturePad.fromData(data);
        }

        this.props.onChange(this._getValue());
    };

    _getValue = () => {
        if (this.signaturePad.toData().length === 0) {
            return null;
        }

        let dataURL = this.signaturePad.toDataURL();
        let base64image = dataURL.split(',')[1];

        return base64image;
    };

    render() {
        let { clear, undo } = this.props;

        return (
            <div className="form-v2">
                <div className="signature-pad">
                    <label>
                        {i18n('Draw your signature in the box below')}
                    </label>

                    <canvas ref={(ref) => (this.canvas = ref)} />

                    <div className="signature-pad-controls">
                        {clear && (
                            <Button
                                theme="gray"
                                onClick={this.clear}
                                icon="far fa-times-circle">
                                {i18n('Clear')}
                            </Button>
                        )}
                        {undo && (
                            <Button
                                theme="gray"
                                onClick={this.undo}
                                icon="far fa-undo">
                                {i18n('Undo')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
