import React from 'react';
import { i18n } from 'Language';
import { images } from 'Constants';
import { Link } from 'react-router';
import Button from 'Common/components/Button';
import './access-denied.scss';

export type Props = {
    title?: string;
    subtitle?: string;
    pageName?: string;
    linkRoute?: any;
    linkLabel?: string;
};

const AccessDenied: React.FunctionComponent<Props> = ({
    title,
    subtitle,
    pageName,
    linkRoute,
    linkLabel,
}) => (
    <div className="white-container">
        {pageName && (
            <h3 className="title">
                {i18n(pageName)}
                &nbsp;
                <span className="text-blue">
                    <i className="far fa-lock-alt"></i>
                </span>
            </h3>
        )}
        <div className="content text-center">
            <img src={`${images}/graphics/600x600_locked.png`} height="200" />
            <h1>{i18n(title)}</h1>
            <p>{i18n(subtitle)}</p>
            <Link to={linkRoute}>
                <Button theme="blue" className="ml mt">
                    {i18n(linkLabel)}
                </Button>
            </Link>
        </div>
    </div>
);

AccessDenied.defaultProps = {
    title: "You don't have access to view this page",
    subtitle:
        'Please, get in contact with your administrator if you think this may be a failure',
    linkRoute: { name: 'dashboard' },
    linkLabel: 'Back to Dashboard',
};

export default AccessDenied;
