import React from 'react';
import { connect } from 'react-redux';
import { AppDispatch, ReduxState } from 'Store';
import {
    disableContactEdit,
    updateContact,
    toggleContactEdit,
} from 'Contacts/redux/actions';
import { showContactDeleteModal } from '../ContactDeleteModal/ContactDeleteModal';
import { Contact } from 'types/Contact';
import { i18n } from 'Language';
import './contact-list-row-actions.scss';

export type Props = {
    onSaveRow: (rowIndex: number, response: any) => void;
    contact: Contact;
    dispatch: AppDispatch;
    isEditing: boolean;
    rowIndex?: number;
};

export const ContactListRowActions = (props: Props) => {
    const { dispatch, isEditing, rowIndex, onSaveRow, contact } = props;

    const handleSave = async () => {
        const response = await dispatch(updateContact());

        onSaveRow(rowIndex as number, response);
    };

    if (isEditing) {
        return (
            <>
                <span
                    className="contact-list-action-text text-blue mr"
                    onClick={handleSave}>
                    {i18n`Save`}
                </span>
                <span
                    className="contact-list-action-text"
                    onClick={() => dispatch(disableContactEdit())}>
                    {i18n`Cancel`}
                </span>
            </>
        );
    }

    return (
        <div className="text-center">
            <span
                className="contact-list-action-icon contact-list-action-edit"
                onClick={() => dispatch(toggleContactEdit(contact.id))}>
                <i className="far fa-pen text-blue" />
            </span>
            <span
                className="contact-list-action-icon contact-list-action-delete"
                onClick={() => showContactDeleteModal(contact)}>
                <i className="far fa-trash-alt text-blue" />
            </span>
        </div>
    );
};

export default connect((state: ReduxState, props: Props) => {
    const { contactDraft } = state.contacts.viewState;

    if (!contactDraft) {
        return {
            isEditing: false,
        };
    }

    return {
        isEditing: contactDraft.id === props.contact.id,
        rowIndex: state.contacts.data.findIndex(
            (c) => c.id === props.contact.id
        ),
    };
})(ContactListRowActions);
