/*
 * Signing with Itsme QES involves two redirects (called "dances"), and we have to store some
 * data in between those. This data is read and set from a few different components, on different
 * pages.
 *
 * We've chosen session storage for this, because we only need the data for a few minutes while
 * the signing process is ongoing.
 */

import { ItsmeErrorType } from './errors';
import { StorageKeys } from 'Constants';
import { Languages } from 'Language/Constants';

export enum StoredStateType {
    FirstDanceInit = 'FirstDanceInit',
    FirstDanceResponse = 'FirstDanceResponse',
    SecondDanceInit = 'SecondDanceInit',
    SecondDanceResponse = 'SecondDanceResponse',
    Error = 'Error',
}

type FirstDanceRedirect = {
    successUrl: string;
    challengeKey: string;
    language: Languages;
    type: StoredStateType.FirstDanceInit;
};

type FirstDanceResponse = {
    secondDanceRedirectUrl: any;
    type: StoredStateType.FirstDanceResponse;
};

type SecondDanceRedirect = {
    type: StoredStateType.SecondDanceInit;
    /** This is the same one as the previous step. It's used to re-start the second dance */
    secondDanceRedirectUrl: string;
    successUrl: string;
    challengeKey: string;
};

type SecondDanceResponse = {
    type: StoredStateType.SecondDanceResponse;
    signature: string;
};

type StoredError = {
    type: StoredStateType.Error;
    error: ItsmeErrorType;
};

// Session storage was chosen because we don't want weird old data held in storage indefinitely.
// Even if we clear the state on errors and on singing success, it could still be possible for
// signers to circumvent this by just closing the tab.
const storage = window.sessionStorage;

type StoredItsmeState =
    | FirstDanceRedirect
    | FirstDanceResponse
    | SecondDanceRedirect
    | SecondDanceResponse
    | StoredError;

export function loadStoredItsmeState(): StoredItsmeState | null {
    const state = storage.getItem(StorageKeys.ITSME_STATE);

    if (!state) {
        return null;
    }

    // trust me bro, the type is good
    return JSON.parse(state) as StoredItsmeState;
}

export function storeItsmeState(data: StoredItsmeState): void {
    storage.setItem(StorageKeys.ITSME_STATE, JSON.stringify(data));
}

export function clearStoredItsmeState(): void {
    storage.removeItem(StorageKeys.ITSME_STATE);
}
