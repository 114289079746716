import React, { useEffect, useState } from 'react';
import WidgetHeader from 'Common/components/WidgetHeader';
import { images } from 'Constants';
import WidgetBody from 'Common/components/WidgetBody';
import LoadingData from 'Common/components/Loaders/LoadingData';
import { i18n } from 'Language';
import Widget from 'Common/components/Widget';
import type { ItsmeErrorType } from '../../errors';
import {
    getErrorDetails,
    ITSME_INVALID_STATE,
    ITSME_MISSING_RETURN_PARAMS,
} from 'ItsmeQES/errors';
import ItsmeError from '../ItsmeError';
import { PublicSigningAPI } from 'Api';
import {
    getCredentialRedirectUrl,
    getURLSearchParameter,
} from 'ItsmeQES/utils';
import {
    loadStoredItsmeState,
    StoredStateType,
    storeItsmeState,
} from 'ItsmeQES/storedState';

type State = {
    error: ItsmeErrorType | null;
};

function ItsmeServiceCallback() {
    const loadedState = loadStoredItsmeState();
    // We do not clear the state here because it could be relevant for the user to try to refresh, for example when the
    // API call below fails because of network errors.
    // If the situation really is unrecoverable, the user will be able to go back to the main signing page, where the
    // state WILL be cleared.

    const [state, setState] = useState({
        error: null,
    } as State);

    const processAuthorizeIntent = async () => {
        setState({ error: null });

        const code = getURLSearchParameter('code');
        const state = getURLSearchParameter('state');

        if (
            loadedState?.type !== StoredStateType.FirstDanceInit ||
            !loadedState?.successUrl
        ) {
            return setState({ error: ITSME_INVALID_STATE });
        }

        if (code === null || state === null) {
            return setState({
                error: ITSME_MISSING_RETURN_PARAMS,
            });
        }

        try {
            const response = await PublicSigningAPI.post(
                '/v3/sign/itsme.be/prepare-signatures',
                {
                    challengeKey: loadedState.challengeKey,
                    language: loadedState.language,
                    credentialRedirectUrl: getCredentialRedirectUrl(),
                    code,
                    state,
                }
            );

            storeItsmeState({
                type: StoredStateType.FirstDanceResponse,
                secondDanceRedirectUrl: response.redirectUrl,
            });

            window.location.href = loadedState.successUrl;
        } catch (error) {
            console.error(
                'Error trying to collect itsme.be signing certicate',
                error
            );
            const { data, headers } = error;
            const errorDetails = getErrorDetails(data.type);

            setState({
                error: {
                    headers,
                    ...data,
                    ...errorDetails,
                },
            });
        }
    };

    useEffect(() => {
        processAuthorizeIntent();
    }, []); // Using an empty dependencies array to ensure processAuthorizeIntent only runs on initial mount, avoiding triggers from itsmeState or other changes.

    return (
        <Widget className="openid-callback">
            <WidgetHeader>
                <>
                    <br />
                    <img
                        height="18"
                        src={`${images}/penneo-blue.png`}
                        alt="Penneo logo"
                    />
                </>
            </WidgetHeader>
            <WidgetBody>
                {state.error && (
                    <ItsmeError
                        error={state.error}
                        redirectUrl={
                            loadedState?.type === StoredStateType.FirstDanceInit
                                ? loadedState?.successUrl
                                : null
                        }
                    />
                )}

                {!state.error && (
                    <>
                        <img
                            height="90"
                            className="openid-callback-id-service-logo"
                            src={`${images}/eid/belgium_itsme_logo_512x512.png`}
                            alt="itsme logo"
                        />

                        <LoadingData
                            message={i18n`Redirecting back to Penneo...`}
                        />
                    </>
                )}
            </WidgetBody>
        </Widget>
    );
}

export default ItsmeServiceCallback;
