import PropTypes from 'prop-types';
import React from 'react';

export default class SearchInput extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        label: PropTypes.string,
        name: PropTypes.string,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        inline: PropTypes.bool,
        required: PropTypes.bool,
        value: PropTypes.string,
        resultText: PropTypes.string,
        defaultValue: PropTypes.string,
    };

    handleChange = (event) => {
        event.preventDefault();

        let { value } = event.target;

        this.props.onChange(value, event);
    };

    render() {
        // HTML Properties
        let {
            value,
            defaultValue,
            placeholder,
            name,
            disabled,
            required,
            inline,
        } = this.props;

        // Data
        let { className, resultText } = this.props;

        return (
            <div
                className={
                    'search-input ' +
                    (className || '') +
                    (inline ? ' inline-block' : '')
                }>
                <i className="fa fa-search" />
                <input
                    type="text"
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={value || ''}
                    defaultValue={defaultValue}
                    required={required}
                    onChange={this.handleChange}
                />

                {resultText && <div className="result-help">{resultText}</div>}
            </div>
        );
    }
}
