import React, { useState } from 'react';
import Button from '../Button';
import TextInput from '../TextInput';
import './obfuscated-text-field.scss';

export type Props = {
    label?: string;
    value: string;
    obfuscationCharacter?: string;
    obfuscationLength?: number;
};

export const ObfuscatedTextField: React.FC<Props> = ({
    value,
    label,
    obfuscationCharacter = '●',
    obfuscationLength = 12,
}) => {
    const [isObfuscated, setIsObfuscated] = useState<boolean>(true);

    return (
        <div className="obfuscated-text-field">
            <TextInput
                label={label}
                value={
                    isObfuscated
                        ? obfuscationCharacter.repeat(obfuscationLength)
                        : value
                }
                readOnly
            />
            <Button
                className="obfuscated-text-field-icon"
                variant="text"
                onClick={() => setIsObfuscated(!isObfuscated)}
                data-testid="obfuscated-text-field-icon"
                icon={isObfuscated ? 'far fa-eye' : 'far fa-eye-slash'}
            />
        </div>
    );
};
