import React from 'react';
import { i18n } from 'Language';
import { CaseFileType } from 'types/CaseFile';
import { DocumentType } from 'types/Document';

type Props = {
    casefile: CaseFileType;
};

export default class CasefileDetailsTable extends React.Component<Props> {
    getRolesFromCasefile = (): string[] => {
        let { casefile } = this.props;
        let roles: string[] = [];

        casefile.documentTypes.forEach((doc) => {
            doc.signerTypes.forEach((signer) => {
                if (roles.indexOf(signer.role) === -1) {
                    roles.push(signer.role);
                }
            });
        });

        return roles;
    };

    createArray = (roles: string[]): any[] => {
        let { casefile } = this.props;
        let orderArray: any[] = [];

        casefile.documentTypes.forEach((doc) => {
            let docArray: any[] = [];

            docArray[0] = doc.name;
            for (let i = 0; i < roles.length; i++) {
                if (doc.signerTypes.length === 0) {
                    docArray[i + 1] = '';
                }

                this.compareSigner(doc, docArray, roles, i);
            }
            orderArray.push(docArray);
        });

        return orderArray;
    };

    // This is used to copare the signer column to the signer object in order to
    // organize the data that will be shown in the table.
    compareSigner = (
        doc: DocumentType,
        docArray: any[],
        roles: string[],
        i: number
    ) => {
        doc.signerTypes.forEach((signer) => {
            if (signer.role === roles[i]) {
                docArray[i + 1] = signer.signOrder + 1;
            } else if (typeof docArray[i + 1] === 'undefined') {
                docArray[i + 1] = null;
            }
        });
    };

    render() {
        let roles = this.getRolesFromCasefile();
        let docSignOrderArray = this.createArray(roles);

        return (
            <div className="casefile-details-container">
                <p className="casefile-details-info">
                    {i18n`The table below shows the relationship between signer roles and which
                    documents they sign. The numbers in each cell determine the sequence in which
                    the documents will be signed.`}
                </p>
                <div className="casefile-details-table-container">
                    <div className="table-sidebar">
                        <table className="table">
                            <tbody className="even-highlight">
                                <tr>
                                    <td>Document / Role</td>
                                </tr>
                                {docSignOrderArray.map((doc, index) => (
                                    <tr key={index}>
                                        <td title={doc[0]} key={index}>
                                            {doc[0]}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="table-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    {roles.map((role, index) => (
                                        <td key={index}>{role}</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="odd-highlight">
                                {docSignOrderArray.map((doc, index) => (
                                    <tr key={index}>
                                        {doc.map(
                                            (value, index2) =>
                                                index2 !== 0 && (
                                                    <td key={index2}>
                                                        {value}
                                                    </td>
                                                )
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
