import React from 'react';
import { i18n } from 'Language';
import Constants from 'Constants';
import './signing-error.scss';
import Button from 'Common/components/Button';

type Props = {
    code: string;
    message: JSX.Element | string;
    retry: (event) => void;
};

type State = {};

export default class PostSignStorageOptions extends React.Component<
    Props,
    State
> {
    render() {
        const { code, message } = this.props;

        return (
            <div className="signing-error">
                <h3>{i18n`There was an error while processing your request.`}</h3>
                <div className="badge text-bold">CODE: {code}</div>

                <br />

                <div>{message}</div>

                <br />

                <Button
                    theme="blue"
                    icon="far fa-sync"
                    onClick={this.props.retry}>
                    {i18n`Try again`}
                </Button>

                <a
                    href={Constants.sites.support}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Button
                        theme="gray"
                        className="ml"
                        icon="far fa-question-circle">
                        {i18n`Contact support`}
                    </Button>
                </a>
            </div>
        );
    }
}
