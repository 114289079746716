import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'Language';

export default class ActionBar extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
    };

    render() {
        let { title, subtitle } = this.props;

        return (
            <div className="action-bar">
                <span className="title">{translate(title)}</span>
                <span className="action">{translate(subtitle)}</span>
            </div>
        );
    }
}
