import React from 'react';
import Constants from 'Constants';
import Button from 'Common/components/Button';
import FullScreenError from 'Common/components/FullScreenError';

import { i18n } from 'Language';

export type Props = {
    error: any;
    onRetry: Function;
    onBack: Function;
    errorRequestId?: string;
    errorTraceId?: string;
};

type KnownError = {
    marker: string;
    message: string;
};

type KnownErrorList = {
    [field: string]: KnownError;
};

// Expected format: "[{some nested properties}.{field name}] {not user-friendly error message}"
// Example: "[initial.caseFile.signers[0].ssn] Does not match the regex pattern ^[0-9]{6,8}-?[0-9]{4}$"
const PROCESSABLE_ERROR_FORMAT: RegExp = /^\[.+?\.(\w+)\] (.+)$/;

const KNOWN_ERRORS: KnownErrorList = {
    ssn: {
        marker: 'not match the regex',
        message:
            "Your national identification number wasn't formatted correctly",
    },
};

const FullScreenSendingError: React.FunctionComponent<Props> = ({
    error,
    onRetry,
    onBack,
    errorRequestId,
    errorTraceId,
}) => {
    const errors = getKnownErrors(error);

    const title = i18n`Something went wrong`;
    const description = !!errors.length
        ? i18n`We detected the following errors:`
        : i18n`We weren't expecting this to happen either, give it another try or contact support`;

    return (
        <FullScreenError
            title={title}
            description={description}
            errorRequestId={errorRequestId}
            errorTraceId={errorTraceId}>
            {getAdditionalContent()}
        </FullScreenError>
    );

    function getAdditionalContent(): JSX.Element {
        return (
            <>
                {getErrorsList()}
                {getButtons()}
            </>
        );
    }

    function getErrorsList(): JSX.Element | null {
        return errors.length ? (
            <ul>
                {errors.map((error, index) => (
                    <li key={index}>
                        <i className="fa fa-times mr"></i>
                        {error}
                    </li>
                ))}
            </ul>
        ) : null;
    }

    function getButtons(): JSX.Element {
        return (
            <>
                {!errors.length && (
                    <Button
                        data-testid="button-try-again"
                        theme="blue"
                        onClick={() => onRetry()}>
                        {i18n`Try again`}
                    </Button>
                )}
                <Button theme="green" className="ml" onClick={() => onBack()}>
                    {i18n`Go back`}
                </Button>
                <a
                    href={Constants.sites.support}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Button theme="gray" className="ml">
                        {i18n`Contact support`}
                    </Button>
                </a>
            </>
        );
    }
};

export default FullScreenSendingError;

/**
 * Goes through the given error payload and returns a list of user-friendly
 * versions of known errors that we can show to the user
 *
 * @param {object} errorPayload
 * @return {array}
 */
function getKnownErrors(errorPayload: any): Array<string> {
    const { data } = errorPayload;
    const canProcessPayload =
        data && data.error && typeof data.error.message !== 'string';

    return canProcessPayload
        ? data.error.message.reduce(processErrors, [])
        : [];
}

/**
 * A reducer, adds to the accumulator the user-friendly version of the given
 * error message, if we know how to process it
 *
 * The error message can be processed if
 *   1) It adheres to a specific format (see parameter description)
 *   2) The field name we extract from the message is present in KNOWN_ERRORS
 *   3) The original error message contains a "marker" that identifies what the
 *      error is about
 *
 * @param {array} processed The accumulator
 * @param {string} error The individual error message to parse. The format the fn
 *     Expected format: "[{some nested properties}.{field name}] {not user-friendly error message}"
 *     Example: "[initial.caseFile.signers[0].ssn] Does not match the regex pattern ^[0-9]{6,8}-?[0-9]{4}$"
 */
function processErrors(processed: Array<string>, error: string): Array<string> {
    const matches = error.match(PROCESSABLE_ERROR_FORMAT);

    if (matches) {
        const [, field, message] = matches;
        const error = KNOWN_ERRORS[field];

        error &&
            ~message.indexOf(error.marker) &&
            processed.push(error.message);
    }

    return processed;
}
