import cookie from 'react-cookie';
import tours from './tourConfig';
import { updateUserSettings } from 'Settings/redux/actions';
import { dispatch, store } from 'Store';
import LaunchDarkly from 'Common/LaunchDarkly';
import Analytics from 'Common/Analytics';
import { UserSettingKeys } from 'Settings/redux/types';
import { TourConfig } from 'types/Tour';
import Constants, { TOUR_IDS, VIEWPORT_SIZES } from 'Constants';

function start(tourId: TOUR_IDS, force: boolean) {
    const tourConfig = tours.getTourConfig(tourId);
    const viewportSize = getViewportSize();

    if (!tourConfig) {
        return;
    }

    const { public: isPublicTour } = tourConfig;

    const tour: TourDefinition = tourConfig[viewportSize];

    if (!tour || tour.steps.length === 0) {
        return;
    }

    const availabilityFlagEnabled = getTourFlagIsEnabled(tourConfig);

    if (!availabilityFlagEnabled) {
        return;
    }

    const tourWithCallbacks = addTourEventCallbacks(tour, viewportSize);

    if (force) {
        tours.start(tourWithCallbacks);

        return;
    }

    const tourViewLimitIsExceeded = getTourViewLimitIsExceeded(tourConfig);

    if (tourViewLimitIsExceeded) {
        return;
    }

    tours.start(tourWithCallbacks);

    isPublicTour
        ? incrementPublicTourViewsCount(tourConfig)
        : incrementPrivateTourViewsCount(tourConfig);
}

// We use 'user settings' for saving the view count state for tours on private
// paths
async function incrementPrivateTourViewsCount(tourConfig: TourConfig) {
    const { id } = tourConfig;
    const currentViewCount = getPrivateTourViewsCount(tourConfig);
    const newViewCount = currentViewCount + 1;

    const payload = {
        viewedTutorials: {
            [id]: {
                count: newViewCount,
            },
        },
    };

    await dispatch(updateUserSettings(UserSettingKeys.tutorials, payload));
}

// We use cookies for saving the view count state for tours on public paths
function incrementPublicTourViewsCount(tourConfig: TourConfig) {
    const currentViewCount = getPublicTourViewsCount(tourConfig);
    const newViewCount = currentViewCount + 1;

    let tourCookie = 'tour-' + tourConfig.id;

    cookie.save(tourCookie, Number(newViewCount));
}

function getPrivateTourViewsCount(tourConfig: TourConfig) {
    const { id } = tourConfig;
    const userSettings = store.getState().settings.data.user;

    const viewCount = userSettings?.tutorials?.viewedTutorials?.[id]?.count;

    return (viewCount as number) || 0;
}

function getPublicTourViewsCount(tourConfig: TourConfig) {
    const viewCount = cookie.load('tour-' + tourConfig.id);

    return Number(viewCount) || 0;
}

function getTourFlagIsEnabled(tourConfig: TourConfig) {
    return LaunchDarkly.variation(tourConfig.featureFlag);
}

function getTourViewLimitIsExceeded(tourConfig: TourConfig) {
    const { public: isPublicTour } = tourConfig;

    const currentViewCount = isPublicTour
        ? getPublicTourViewsCount(tourConfig)
        : getPrivateTourViewsCount(tourConfig);

    const tourViewLimitIsExceeded =
        currentViewCount >= Constants.VIEW_LIMIT_COUNT;

    return tourViewLimitIsExceeded;
}

// Add callbacks for tour events used for tracking
function addTourEventCallbacks(
    tour: TourDefinition,
    viewportSize: VIEWPORT_SIZES
) {
    const { id } = tour;

    // Invoked when the tour is started
    if (!tour.onStart) {
        tour.onStart = function () {
            Analytics.track('tour - started', { id, viewportSize });
        };
    }

    // Invoked when the tour ends
    if (!tour.onEnd) {
        tour.onEnd = function () {
            Analytics.track('tour - ended', { id, viewportSize });
        };
    }

    // Invoked when the user closes the tour before finishing.
    if (!tour.onClose) {
        tour.onClose = function () {
            Analytics.track('tour - closed before finishing', {
                id,
                viewportSize,
            });
        };
    }

    return tour;
}

function getStepCount(tourId: TOUR_IDS, viewportSize: VIEWPORT_SIZES) {
    const tourConfig = tours.getTourConfig(tourId);

    const tour = tourConfig?.[viewportSize];

    if (!tour) {
        return 0;
    }

    return tour.steps.length;
}

function cancelCurrentTour() {
    tours.endTour();
}

function getViewportSize(): VIEWPORT_SIZES {
    let target = VIEWPORT_SIZES.ALL;

    let viewport = window.innerWidth;

    if (viewport < 480) {
        target = VIEWPORT_SIZES.MOBILE;
    }

    if (viewport > 480 && viewport < 768) {
        target = VIEWPORT_SIZES.TABLET;
    }

    if (viewport >= 768) {
        target = VIEWPORT_SIZES.DESKTOP;
    }

    return target;
}

const tour = {
    start: start,
    steps: getStepCount,
    endTour: cancelCurrentTour,
};

export default tour;
