import React, { useState } from 'react';

type Props = {
    defaultState?: boolean;
    label: string;
    activeLabel?: string;
    children: JSX.Element;
};

export default function Accordion({
    defaultState = false,
    label,
    activeLabel,
    children,
}: Props) {
    const [isOpen, setIsOpen] = useState(true);
    const toggleIsOpen = (e) => {
        setIsOpen(e.target.open);
    };

    return (
        <details
            className="advanced-options"
            onToggle={toggleIsOpen}
            open={defaultState}>
            <summary>
                <p className="underline-link inline-block align-top">
                    {!isOpen ? (
                        <span>
                            {label}&nbsp;
                            <i className="far fa-chevron-down"></i>
                        </span>
                    ) : (
                        <span>
                            {activeLabel ?? label}&nbsp;
                            <i className="far fa-chevron-up"></i>
                        </span>
                    )}
                </p>
            </summary>

            {children}
        </details>
    );
}
