import React from 'react';
import Button from 'Common/components/Button';
import lodash from 'lodash';
import { i18n } from 'Language';

import {
    BetterCaseFileType,
    BetterDocumentType,
    BetterRound,
    BetterSignerType,
} from '../types';

import './better-flow-overview.scss';

type Props = {
    onClose: () => void;
    casefileType: BetterCaseFileType;
};

type State = {
    error: any;
};

export const getSignerTypeById = (
    caseFileType: BetterCaseFileType,
    id: number
): BetterSignerType => {
    return caseFileType.signerTypes.find(
        (st) => st.id === id
    ) as BetterSignerType;
};

export const getDocumentTypeById = (
    caseFileType: BetterCaseFileType,
    id: number
): BetterDocumentType => {
    return caseFileType.documentTypes.find(
        (st) => st.id === id
    ) as BetterDocumentType;
};

export type GroupedRound = {
    documentTypeIds: number[];
    signerTypeIds: number[];
};

/**
 * Groups the signing rounds in groups of documents that are
 * signed by a group of signers. (n to n)
 */
export const getSigningGroupedRound = (
    rounds: BetterRound[]
): GroupedRound[] => {
    const groups = [] as GroupedRound[];

    rounds.forEach((round) => {
        const matchedGroupIndex = groups.findIndex((group) =>
            lodash.isEqual(group.signerTypeIds, round.signerTypeIds)
        );

        // If there is a match, merge it
        if (matchedGroupIndex !== -1) {
            const group = groups[matchedGroupIndex];

            return group.documentTypeIds.push(round.documentTypeId);
        }

        groups.push({
            documentTypeIds: [round.documentTypeId],
            signerTypeIds: round.signerTypeIds,
        });
    });

    return groups;
};

export class BetterFlowOverview extends React.Component<Props, State> {
    state: State = {
        error: null,
    };

    render() {
        const { casefileType } = this.props;
        const { rounds } = casefileType;

        const signableDocuments = casefileType.documentTypes.filter(
            (documentType) => documentType.signable
        );

        const attachments = casefileType.documentTypes.filter(
            (documentType) => !documentType.signable
        );

        return (
            <div className="better-flow-overview">
                <div className="casefile-flow-overview-header">
                    <h1>
                        {i18n`Signing flow`}: {casefileType.name}
                    </h1>
                    <Button
                        variant="outline"
                        theme="blue"
                        onClick={this.props.onClose}>
                        {i18n`Back`}
                    </Button>
                </div>

                <div className="casefile-flow-overview-subheader">
                    <p>
                        {i18n`Signing flows contain information of how the documents
                        and signers will interact in the process. The documents
                        will be delivered for signature in the sequence shown
                        below. Attachments are shown to signers in every round`}
                    </p>

                    <div className="casefile-flow-overview-quick-glance">
                        <span className="casefile-flow-overview-quick-glance-item">
                            <i className="far fa-copy text-red" />
                            {signableDocuments.length}&nbsp;
                            {signableDocuments.length === 1
                                ? i18n`Type of document`
                                : i18n`Types of documents`}
                        </span>
                        <span className="casefile-flow-overview-quick-glance-item">
                            <i className="far fa-users text-blue" />
                            {casefileType.signerTypes.length}&nbsp;
                            {casefileType.signerTypes.length === 1
                                ? i18n`Signer role`
                                : i18n`Signer roles`}
                        </span>
                        <span className="casefile-flow-overview-quick-glance-item">
                            <i className="far fa-sync" />
                            {rounds.length}&nbsp;
                            {rounds.length === 1
                                ? i18n`Signing round`
                                : i18n`Signing rounds`}
                        </span>
                    </div>
                </div>
                <div className="casefile-flow-rounds-container">
                    {rounds.map((round, roundIndex) => {
                        const groupedRound = getSigningGroupedRound(round);

                        return (
                            <div
                                className="signing-round"
                                key={`signing-round-${roundIndex}`}>
                                {roundIndex > 0 && (
                                    <div className="signing-round-separator">
                                        <div className="signing-round-separator-handle-top" />
                                        <div className="signing-round-separator-bar" />
                                        <div className="signing-round-separator-bar" />
                                        <div className="signing-round-separator-bar" />
                                        <div className="signing-round-separator-handle-bottom" />
                                    </div>
                                )}
                                <h2 className="signing-round-heading">
                                    {i18n`Signing round`} {roundIndex + 1}
                                </h2>

                                <div
                                    className={
                                        'signing-round-group' +
                                        (groupedRound.length === 1
                                            ? ' signing-round-one-column'
                                            : '')
                                    }>
                                    {groupedRound.map((round, index) => {
                                        return (
                                            <div
                                                className="signing-round-block-container"
                                                key={`signing-round-group-${roundIndex}-${index}`}>
                                                <div className="signing-round-block">
                                                    <div className="signing-round-documents">
                                                        <h4 className="signing-round-section-header">
                                                            {i18n`Documents`}
                                                        </h4>
                                                        {round.documentTypeIds.map(
                                                            (docTypeId) => (
                                                                <div
                                                                    key={
                                                                        docTypeId
                                                                    }
                                                                    className="signing-round-documents-item">
                                                                    <i className="far fa-file text-red"></i>
                                                                    &nbsp;
                                                                    {
                                                                        getDocumentTypeById(
                                                                            casefileType,
                                                                            docTypeId
                                                                        ).name
                                                                    }
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                    <div className="signing-round-signers">
                                                        <h4 className="signing-round-section-header">
                                                            {i18n`Signed by`}
                                                        </h4>
                                                        {round.signerTypeIds.map(
                                                            (signerTypeId) => (
                                                                <div
                                                                    key={
                                                                        signerTypeId
                                                                    }
                                                                    className="signing-round-signers-item">
                                                                    <i className="far fa-user text-blue"></i>
                                                                    &nbsp;
                                                                    {
                                                                        getSignerTypeById(
                                                                            casefileType,
                                                                            signerTypeId
                                                                        ).role
                                                                    }
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
                {attachments.length > 0 && (
                    <div className="casefile-flow-attachments">
                        <h4 className="casefile-flow-overview-quick-glance-item">
                            <i className="far fa-paperclip" />
                            {attachments.length}&nbsp;
                            {attachments.length === 1
                                ? i18n`Attachment`
                                : i18n`Attachments`}
                        </h4>
                        <div>
                            {attachments.map((attachment) => (
                                <div
                                    key={attachment.id}
                                    className="attachment-item">
                                    <i className="far fa-file text-red"></i>
                                    {attachment.name}
                                </div>
                            ))}
                        </div>

                        <p>
                            <i className="far fa-exclamation-circle text-blue"></i>
                            &nbsp;
                            {i18n`Attachments are documents that aren't signed
                            and are visible for every recipient before signing`}
                        </p>
                    </div>
                )}
            </div>
        );
    }
}
