const createReducer = <T>(
    initialState: T,
    fnMap: { [key: string]: (state: T, payload: any, mainState?: any) => T }
) => {
    return (state = initialState, action?: any, mainState?: any) => {
        if (!action) {
            return state;
        }

        const { type, payload } = action;
        const handle = fnMap[type];

        return handle ? handle(state, payload, mainState) : state;
    };
};

export default createReducer;
