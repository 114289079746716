import PropTypes from 'prop-types';
import React from 'react';
import FormStore from '../../stores/FormStore';
import Constants from '../../Constants';
import { DragSource } from 'react-dnd';

let _startX = null;
let _startWidth = null;

let fieldSource = {
    // return variables to DocumentDrop.jsx as drag begins
    beginDrag(props) {
        let { mapping } = props;
        let data = FormStore.getPreviewMapping(mapping);

        return {
            top: data.y,
            left: data.x,
            id: mapping.id,
            width: data.width,
            height: data.height,
        };
    },
};

// Makes this component draggable with React DnD
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

export default DragSource(
    Constants.ItemTypes.FIELD,
    fieldSource,
    collect
)(
    class extends React.Component {
        static propTypes = {
            index: PropTypes.number,
            field: PropTypes.object,
            mapping: PropTypes.object,
            initialPosition: PropTypes.object,
            connectDragSource: PropTypes.func.isRequired,
            hideSourceOnDrag: PropTypes.bool.isRequired,
            isDragging: PropTypes.bool.isRequired,
            save: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
            moveEventStart: PropTypes.func.isRequired,
            showOrder: PropTypes.bool,
        };

        mouseDownHandler = (event) => {
            let { mapping } = this.props;
            let { moveEventStart } = this.props;

            let element = document.getElementById('mapping-id-' + mapping.id);
            let rect = element.getBoundingClientRect();

            _startWidth = rect.width;
            _startX = event.clientX;

            moveEventStart(mapping, event, _startX, _startWidth);
        };

        removeFieldHandler = (event) => {
            event.preventDefault();
            event.stopPropagation();
            let { mapping } = this.props;

            if (event.buttons !== 0) {
                return;
            }

            this.props.remove(mapping.id);
        };

        /**
         * Renders field on page based on fieldMap state elements.
         * @param data {object} fieldMap object containing field mapping and field type data
         *
         * @return {DOM} Returns the JSX for the Input to be rendered by React's render() function
         */
        renderField = () => {
            let { field, mapping, index, connectDragSource } = this.props;
            let data = FormStore.getPreviewMapping(mapping);

            // Base Style for rendered element.
            const style = {
                width: data.width + 4 + 'px',
                height: data.height + 4 + 'px',
                fontSize: data.height + 'px',
            };

            const divStyle = {
                position: 'absolute',
                top: data.y,
                left: data.x,
                transform: 'translate3d(0,0,0)',
            };

            let fieldJSX = (
                <div
                    className="input-editable"
                    style={divStyle}
                    id={'fieldmap-' + field.id}>
                    {connectDragSource(
                        <div
                            className="bounding-box"
                            style={{
                                position: 'absolute',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: '#0080BF',
                                top: '2px',
                                left: '2px',
                                right: '2px',
                                bottom: '2px',
                            }}></div>
                    )}

                    {this.props.showOrder && (
                        <div
                            className="order"
                            style={{
                                fontSize: data.height + 'px',
                            }}>
                            {this.props.index + 1}
                        </div>
                    )}
                    <input
                        key={index}
                        id={'mapping-id-' + mapping.id}
                        style={style}
                        type="text"
                        className="editor-field"
                        data-fd={field.id}
                        data-map-id={mapping.id}
                        placeholder={field.label}
                        readOnly></input>
                    <div
                        className="resize-handle"
                        onMouseDown={this.mouseDownHandler}>
                        <i className="fa fa-arrows-h"></i>
                    </div>
                    <div
                        className="remove-handle"
                        onClick={this.removeFieldHandler}
                        draggable="false">
                        <i className="fa fa-times"></i>
                    </div>
                </div>
            );

            return fieldJSX;
        };

        render() {
            let { field, isDragging, hideSourceOnDrag } = this.props;

            if (!field) {
                return false;
            }

            if (isDragging && hideSourceOnDrag) {
                return null;
            }

            return this.renderField();
        }
    }
);
