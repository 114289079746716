import createReducer from 'create-reducer';
import produce from 'immer';

import { Settings, SettingsState, ManagedSettings } from './types';
import { ActionTypes } from './actionTypes';
import CasefileConstants from 'Casefiles/Constants';
import { Integrations } from 'Constants';

// Initial state
export const settingsInitialState: SettingsState = {
    data: {
        user: {
            defaultEmailTemplates: {
                casefile: {
                    signing_request: false,
                    completed: false,
                    reminder: false,
                    carbon_copy_recipient: false,
                },
                kyc: {
                    initial: false,
                    reminder: false,
                },
            },
            casefileTemplates: {},
            tours: {},
            tutorials: {},
            storeNewContactsByDefault: {
                active: true,
            },
            whatsNew: {},
        },
        managed: {
            features: {
                teams: false,
                kyc: false,
            },
        },
        customer: {
            silverfin: {
                name: Integrations.Silverfin.name,
                enabled: false,
                enabledBy: null,
                enabledAt: null,
            },
            casefile: {
                reminderInterval: CasefileConstants.DEFAULT_REMINDER_INTERVAL,
            },
        },
    } as Settings,
    isLoaded: false,
    isFetching: false,
    error: null,
};

// Reducer
export const settingsReducer = createReducer<SettingsState>(
    settingsInitialState,
    {
        [ActionTypes.SETTINGS_FETCH_REQUEST]: (state) =>
            produce(state, (draftState) => {
                draftState.isFetching = true;
            }),
        [ActionTypes.SETTINGS_FETCH_SUCCESS]: (state, settings: Settings) =>
            produce(state, (draftState) => {
                draftState.data = settings;
                draftState.isLoaded = true;
                draftState.isFetching = false;
                // Put default value for domain if it is missing.
                // This is to maintain backwards compatibility with previous format
                // without subdomains (v2).
                const { silverfin } = draftState.data.customer;

                if (silverfin.enabled && !silverfin.subdomain) {
                    silverfin.subdomain = 'live';
                }

                // Another temporary code for migrating from previous data format
                // New data format does not have dev property
                if (silverfin.dev) {
                    delete silverfin.dev;
                }
            }),
        [ActionTypes.SETTINGS_FETCH_FAILURE]: (state, error) =>
            produce(state, (draftState) => {
                draftState.isLoaded = true;
                draftState.isFetching = false;
                draftState.error = error;
            }),
        [ActionTypes.SETTINGS_MANAGED_FETCH_REQUEST]: (state) =>
            produce(state, (draftState) => {
                draftState.isFetching = true;
            }),
        [ActionTypes.SETTINGS_MANAGED_FETCH_SUCCESS]: (
            state,
            managedSettings: ManagedSettings
        ) =>
            produce(state, (draftState) => {
                draftState.data.managed = managedSettings;
                draftState.isLoaded = true;
                draftState.isFetching = false;
            }),
        [ActionTypes.SETTINGS_MANAGED_FETCH_FAILURE]: (state, error) =>
            produce(state, (draftState) => {
                draftState.isLoaded = true;
                draftState.isFetching = false;
                draftState.error = error;
            }),
        [ActionTypes.SETTINGS_MANAGED_UPDATE_SUCCESS]: (
            state,
            { key, settings }
        ) =>
            produce(state, (draftState) => {
                draftState.data.managed[key] = settings;
            }),
        [ActionTypes.SETTINGS_CUSTOMER_UPDATE_SUCCESS]: (
            state,
            { key, settings }
        ) =>
            produce(state, (draftState) => {
                draftState.data.customer[key] = settings;
            }),
        [ActionTypes.SETTINGS_USER_UPDATE_SUCCESS]: (
            state,
            { key, settings }
        ) =>
            produce(state, (draftState) => {
                draftState.data.user[key] = settings;
            }),
    }
);
