import moment, { Moment } from 'moment';

/**
 * DateTimePicker utils
 */
type ProcessedDateNumber = {
    date: number;
    type: 'unix' | 'timestamp';
};

const normalizeDateNumber = (date: number | string): ProcessedDateNumber => {
    const asNumber = Number(date);
    const digits = asNumber.toString().length;

    return { date: asNumber, type: digits === 13 ? 'timestamp' : 'unix' };
};

/**
 * This method will return a date (moment) object from a timestamp. If no timestamp
 * is passed, it will return the current date time
 */
export const getDate = (date?: number | string | null) => {
    if (!date) {
        return moment();
    }

    const parsed = normalizeDateNumber(date);

    if (parsed.type === 'timestamp') {
        return moment(parsed.date);
    }

    return moment.unix(parsed.date);
};

/**
 * This method will return a date string with the right format
 * for the DateTimePicker.
 */
export const getDateTime = (date: Moment) =>
    date.toISOString(true).slice(0, 16);

/**
 * The DateTimePicker returns a UTC timestamp,
 * which we need to offset with the local timezone difference
 * in order to save the right value
 */
export const getDateWithTimezoneOffset = (timestamp?: number) => {
    const timeSelected = getDate(timestamp);

    if (!timestamp) {
        return timeSelected;
    }

    const offset = timeSelected.utcOffset();
    const timeAdjusted = timeSelected.subtract(offset, 'minutes');

    return timeAdjusted;
};
