/* eslint max-len:0*/
// Disable linter max length. Routing Table nesting makes difficult avoiding long lines
// and breaking entries into several lines doesn't help readability.

import React from 'react';
import ReactDOM from 'react-dom';
import {
    Router,
    Route,
    IndexRedirect,
    useRouterHistory as routerHistory,
} from 'react-router';
import { Provider } from 'react-redux';
import { ToastProvider } from 'Common/components/ToastContext';
import ToastList from 'Common/components/Toasts/ToastList';
import { store } from 'Store';
import browserHistory from 'history/lib/createBrowserHistory';
import hashHistory from 'history/lib/createHashHistory';

import namedRoutes from 'use-named-routes';

import AppContainer from 'Common/components/AppContainer';

import { AuthRoutes } from 'Auth';
import { FormRoutes } from 'Forms';
import { EntitiesRoutes } from 'Entities';

import { CasefilesRoutes } from 'Casefiles';
import TrashCan from 'Casefiles/components/TrashCan/TrashCan';

import HeaderLayout from 'Common/components/Header/HeaderLayout';
import DashboardContainer from 'Common/components/DashboardContainer/DashboardContainer';

import ProfileSelector from 'Common/components/ProfileSelector/ProfileSelector';

import Dashboard from 'Common/components/Dashboard/Dashboard';

// NEW Signing page
import Signing from 'Signing/Signing';
import SigningDone from 'Signing/SigningDone';
import SigningValidation from 'Signing/SigningValidation';

// Error Pages
import NotFound from 'Common/components/Common/NotFound';
import SessionHandler from 'Common/components/Common/SessionHandler';
import UnderMaintenance from 'Common/components/Common/UnderMaintenance';

import BootstrappingContainer from 'Common/components/Bootstrapping/BootstrappingContainer';
import Constants from 'Constants';

import RedirectionHandler from 'Common/components/Common/RedirectionHandler';
import DebugInformation from 'Common/components/DebugInformation';

// Placeholder routes for features not yet built.
import { EmailTemplatesPage } from 'EmailTemplates';
import CreateKYCPage from 'KYC/views/CreateKYCPage';
import KYCRecipientModal from 'KYC/components/KYCRecipientModal';
import CreateRegisteredLetterPage from 'RegisteredLetter/views/CreateRegisteredLetterPage';
import RegisteredLetterRecipientModal from 'RegisteredLetter/components/RegisteredLetterRecipientModal';
import KYCList from 'KYC/components/KYCList';
import TestSign from 'Common/components/TestSign';
import EutlSign from 'Eutl';
import { debug } from 'Core';
import KYCItemDetails from 'KYC/components/KYCItemDetails';
import CasefileDocumentDetails from 'Casefiles/CasefileDetails/CasefileDocumentDetails';
import ContactList from 'Contacts/components/ContactList';
import CustomerIntegrationsPenneoAuth from '../Auth/components/CustomerIntegrations/CustomerIntegrationsPenneoAuth';
import ExternalCustomerDataDashboard from '../Admin/TableauEmbed';
import { ClientsEditor, ClientsList, ClientView } from 'Clients';
import MitIDNAPInterventionScreen from '../MitID/components/MitIDNAPInterventionScreen';
import SignFinalized from 'Signing/SignFinalized';
import launchDarkly, { Flags } from './LaunchDarkly';
import Formatter from 'Formatter';
import { SignersArchiveRoutes } from 'SignersArchive';
import useCustomerRedirect from 'Common/hooks/useCustomerRedirect';
// Support
import { HelpCenter } from '../Support/HelpCenter';

// Test page
import TestPage from 'Common/components/TestPage';

const defaultParameters = {
    requireAuth: true,
    requireLanguage: true,
    requireBranding: true,
};

// Use browser history wherever possible.
const pushStateSupported = window.history && window.history.pushState;
const createHistory = pushStateSupported ? browserHistory : hashHistory;

// Wrapper needed for styling purposes
const TrashCanWrapper = ({ router }) => {
    useCustomerRedirect({ router, shouldRedirectNonCustomer: false });

    return (
        <div className="penneo-casefiles">
            <TrashCan />
        </div>
    );
};

/**
 * Handles legacy versions of the application routes that had hash navigation prefixes.
 * which makes it impossible to handle as a network redirection.
 *
 * @todo: remove once we have made sure all external links have been updated by customers.
 */

function handleHashRoutes(nextState, replace, callback) {
    // If pushstate is not supported, don't modify hash navigation links.
    if (!pushStateSupported) {
        callback();
    }

    let { href } = window.location;
    let legacyRoutes = ['/forms/#', '/workflow/#'];

    for (let i = 0; i < legacyRoutes.length; i++) {
        if (href.indexOf(legacyRoutes[i]) !== -1) {
            let route = href.replace(legacyRoutes[i], '');

            window.location.replace(route);

            return;
        }
    }

    callback();
}

/**
 * Wraps component in a bootstrapping component that takes parameters to verify the existence of
 * certain states within the application. It will also show a loader until all the parameter
 * conditions are fulfilled.
 *
 * @param  {Component} Component  - React Component to render if authentication is successful.
 * @param  {object}    parameters - Contains boolean values of the conditions the bootstrapping
 *                                  component will check before rendering the application.
 *
 * @return {function} Returns a ReactComponent that will verify authentication.
 */

function bootstrapApplication(Component, parameters = defaultParameters) {
    return BootstrappingContainer(Component, parameters);
}

/* Application Routes for Web */
const getAppRoutes = () => (
    <Route component={AppContainer} onEnter={handleHashRoutes}>
        {/* Handle redirection to initial page */}
        <Route path="/" component={bootstrapApplication(SessionHandler)} />

        {debug.isDebugEnabled && (
            <Route path="test/component" component={TestPage} />
        )}

        {/* Profile selector */}
        <Route
            name="profile-select"
            path="profile-select"
            component={bootstrapApplication(ProfileSelector)}
        />

        {/* Debug information */}
        <Route
            name="debug"
            path="debug"
            component={bootstrapApplication(DebugInformation)}
        />

        {/* Integrations authorization */}
        <Route
            path="integrations/silverfin/auth"
            component={bootstrapApplication(CustomerIntegrationsPenneoAuth)}
        />

        {/* Render Dashboard Content */}
        <Route name="home" component={bootstrapApplication(DashboardContainer)}>
            <Route name="dashboard" path="dashboard" component={Dashboard} />

            {/* Signers Archive */}
            <Route
                name="personal-archive-trash"
                path="/personal-archive/trash"
                component={TrashCanWrapper}
            />
            {SignersArchiveRoutes.userRoutes}

            {/* Form Routes */}
            {FormRoutes.userRoutes}

            {/* Credential Management Routes */}
            {AuthRoutes.getUserRoutes()}

            {/* Casefile Routes */}
            {CasefilesRoutes.userRoutes}

            {/* Entities Routes */}
            {EntitiesRoutes}

            <Route
                name="registered-letter-create"
                path="registeredletter/new"
                component={CreateRegisteredLetterPage}>
                <Route
                    name="registered-letter-recipient-add"
                    path="recipient/add"
                    components={{ modal: RegisteredLetterRecipientModal }}
                />
                <Route
                    name="registered-letter-recipient-edit"
                    path="recipient/:index/edit"
                    components={{ modal: RegisteredLetterRecipientModal }}
                />
            </Route>

            {/* KYC Routes */}
            <Route name="kyc-create" path="kyc/new" component={CreateKYCPage}>
                <Route
                    name="kyc-recipient-add"
                    path="recipient/add"
                    components={{ modal: KYCRecipientModal }}
                />
                <Route
                    name="kyc-recipient-edit"
                    path="recipient/:index/edit"
                    components={{ modal: KYCRecipientModal }}
                />
            </Route>

            <Route name="manage-kyc" path="manage/kyc">
                <IndexRedirect to="pending" />
                <Route name="kyc-list" path=":status" component={KYCList} />
                <Route
                    name="kyc-item-details"
                    path="details/:id"
                    component={KYCItemDetails}>
                    <Route
                        name="kyc-document-details"
                        path="document/:documentId"
                        components={{ modal: CasefileDocumentDetails }}
                    />
                </Route>
            </Route>

            {/* Client Routes */}
            <Route name="clients-main" path="clients">
                <IndexRedirect to="list" />
                <Route name="client-list" path="list" component={ClientsList} />
                <Route
                    name="client-view"
                    path="view/:clientId/:entityType"
                    component={ClientView}
                />
                <Route
                    name="client-edit"
                    path="edit/:clientId"
                    component={ClientsEditor}
                />
                <Route
                    name="client-creation"
                    path="new"
                    component={ClientsEditor}
                />
            </Route>

            <Route
                name="email-templates"
                path="templates/email"
                forCustomer={false}
                component={EmailTemplatesPage}
            />

            <Route name="contacts" path="contacts" component={ContactList} />

            <Route
                name="help-center"
                path="help-center"
                component={HelpCenter}
            />
        </Route>

        <Route component={bootstrapApplication(null)}>
            {/* Tableau Dashboard */}
            <Route
                name="analytics"
                path="analytics"
                component={ExternalCustomerDataDashboard}
            />
        </Route>

        {/* Casefile Management Routes */}
        <Route component={bootstrapApplication(HeaderLayout)}>
            {CasefilesRoutes.userRoutes}
        </Route>

        {/* Render in Header View @todo: wrap in auth component */}
        <Route
            component={bootstrapApplication(null, {
                requireAuth: true,
                requireBranding: true,
                requireLanguage: true,
            })}>
            {FormRoutes.signerRoutes}
        </Route>

        {/* Public Routes */}
        <Route
            component={bootstrapApplication(null, {
                requireAuth: false,
                requireBranding: false,
                requireLanguage: true,
            })}>
            {AuthRoutes.getPublicRoutes()}
        </Route>

        {/* Form Public Routes */}
        <Route
            component={bootstrapApplication(null, {
                requireAuth: false,
                requireBranding: true,
                requireLanguage: true,
            })}>
            {FormRoutes.publicRoutes}
        </Route>

        {/* Preshared Routes */}
        <Route component={bootstrapApplication(null)}>
            {AuthRoutes.getPresharedRoutes()}
        </Route>

        {/* Redirection Handler */}
        <Route component={bootstrapApplication(null)}>
            {/* Expects query parameters: path */}
            <Route path="redirect" component={RedirectionHandler} />
        </Route>

        {debug.isDebugEnabled && (
            <>
                <Route
                    path="test/sign/:serviceId/:challengeKey"
                    component={TestSign}
                />
            </>
        )}
        {debug.isDebugEnabled && (
            <Route path="test/sign/eutl" component={EutlSign} />
        )}

        {/* SIGNING MVP */}
        <Route
            path="signing/validation/:challengeKey"
            component={SigningValidation}
        />
        <Route path="signing/:challengeKey" component={Signing} />
        {launchDarkly.variation(
            Flags.VIEW_COMPLETED_CASEFILES_VIA_SIGNING_LINK
        ) && (
            <Route
                path="signing/:challengeKey/finalized"
                component={SignFinalized}
            />
        )}
        <Route
            path="signing/:challengeKey/:requestStatus"
            component={SigningDone}
        />
        <Route
            path="name-address-protection/:challengeKey"
            component={MitIDNAPInterventionScreen}
        />

        {launchDarkly.variation(Flags.ENABLE_FORMATTING_SERVICE) && (
            <Route path="formatting-service" component={Formatter} />
        )}

        {/* Not found */}
        <Route path="*" component={NotFound} />
        <Route path="404" name="page-not-found" component={NotFound} />
    </Route>
);

/* Application Routes for Desktop */
const getDesktopRoutes = () => (
    <Route component={AppContainer}>
        <Route path="/" component={bootstrapApplication(SessionHandler)} />

        {/* Profile selector */}
        <Route
            name="profile-select"
            path="profile-select"
            component={bootstrapApplication(ProfileSelector)}
        />

        <Route component={bootstrapApplication(HeaderLayout)}>
            {/* Casefile Management Routes */}

            {/* Casefile Routes */}
            {CasefilesRoutes.userRoutes}
        </Route>

        {/* Public Routes */}
        <Route
            component={bootstrapApplication(null, {
                requireAuth: false,
                requireBranding: false,
                requireLanguage: true,
            })}>
            {AuthRoutes.getPublicRoutes()}
        </Route>

        {/* Not found */}
        <Route path="*" component={NotFound} />
        <Route path="404" name="page-not-found" component={NotFound} />
    </Route>
);

/**
 * Extracts the PR number from the URL (if present)
 */
const getQAPreviewNumber = () => {
    const matches = /^\/(PR-\d+)/.exec(window.location.pathname);

    if (matches) {
        return matches[1]; // Return ticket number without traling slash (First capturing group)
    }

    return null;
};

/**
 * If there is a PR number in URL, the application is considered to be loaded as a QA release.
 * A deployment that is only used for testing purposes. The app needs to load with the ticket number
 * as a base for react-router, so that all redirections are performed relative to the URL
 * containing the ticket number.
 *
 * i.e. If the PR number is 666, all redirections must be relative
 * to https://app.penneo.com/PR-666 this is achieved by setting the router basename as `/PR-666`
 */
const getBaseName = () => {
    const qaPreviewNumber = getQAPreviewNumber();
    const isQARelease = !!qaPreviewNumber;

    if (isQARelease) {
        return '/' + qaPreviewNumber;
    }

    return '';
};

const getHistory = (routes) => {
    // Enhance history with route names
    return namedRoutes(routerHistory(createHistory))({
        routes: routes,
        basename: getBaseName(),
    });
};

/* Loads router into a target HTML element */
const getRouter = (platform) => {
    // Load Web Routing table
    let routes = getAppRoutes();

    // Load Desktop Routing table
    if (platform === 'desktop') {
        routes = getDesktopRoutes();
    }

    const history = getHistory(routes); // Load history module

    return (
        <ToastProvider>
            <ToastList />
            <Provider store={store}>
                <Router history={history} routes={routes} />
            </Provider>
        </ToastProvider>
    );
};

const initRouter = (platform, target) => {
    // If there's no configuration, display maintenance page.
    if (!Constants.configAvailable) {
        ReactDOM.render(<UnderMaintenance />, target);

        return;
    }

    // Get router configuration based on environment and start application
    let AppRouter = getRouter(platform);

    // Render Router.
    ReactDOM.render(AppRouter, target);
    store.dispatch({ type: Constants.APPLICATION_LOADED });
};

const routing = {
    initRouter,
};

export default routing;
