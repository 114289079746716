const MAX_WIDTH = 800;
const MAX_HEIGHT = 800;

type Size = {
    width: number;
    height: number;
};

export default async function downsize(file: File): Promise<string> {
    const image = await loadFromFile(file);

    const originalSize = getImageSize(image);
    const newSize = calculateCappedSize(originalSize, MAX_WIDTH, MAX_HEIGHT);

    return resize(image, newSize);
}

const loadFromFile = (file: File): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const img = new Image();
        const url = URL.createObjectURL(file);

        img.onerror = reject;
        img.onabort = reject;

        img.onload = function () {
            resolve(this as HTMLImageElement);
        };
        img.src = url;
    });

const getImageSize = (image: HTMLImageElement) => {
    // the size may be unavailable, if the image doesn't specify it or retrieving it is not possible
    if (!image.naturalHeight || !image.naturalWidth) {
        throw new Error('Cannot read image size!');
    }

    return {
        width: image.naturalWidth,
        height: image.naturalHeight,
    };
};

const calculateCappedSize = (
    original: Size,
    maxWidth: number,
    maxHeight: number
): Size => {
    let newWidth = original.width;
    let newHeight = original.height;

    if (newWidth > maxWidth) {
        newWidth = maxWidth;
        newHeight = (original.height * newWidth) / original.width;
    }

    if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = (original.width * newHeight) / original.height;
    }

    return { width: Math.floor(newWidth), height: Math.floor(newHeight) };
};

function resize(image: HTMLImageElement, targetSize: Size): string {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        throw new Error('Cannot get 2d context!');
    }

    canvas.height = targetSize.height;
    canvas.width = targetSize.width;

    // use a white background for images with transparency
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, targetSize.width, targetSize.height);

    ctx.drawImage(image, 0, 0, targetSize.width, targetSize.height);

    return canvas.toDataURL('image/jpeg', 0.75);
}
