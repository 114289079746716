import lodash from 'lodash';
import { Settings } from 'Settings/redux/types';

/**
 * Returns a flat key value map for managed feature settings Nested in (settings > managed > features)
 * nested values are rewritten to dot notation
 *
 * This transformation is done to be able to send these values
 * to LaunchDarkly and Amplitude, which don't support nested data structures.
 *
 * @param settings  Result from api/v1/settings/all
 * @return flattened key value of settings. example:
 *  {
 *    settings: {
 *      managed: {
 *         teams: true,
 *         kyc: false
 *      }
 *  }
 *
 *  Gets converted to:
 *  {
 *    'settings.managed.teams': true
 *    'settings.managed.kyc': false
 *  }
 */
export const getFlatManagedFeatures = (
    settings: Settings
): { [key: string]: any } => {
    // Safe getter for supporting non existent managed features.
    const data = lodash.get(settings, 'managed.features', false);

    return Object.keys(data).reduce<any>((result, key) => {
        result[`settings.managed.${key}`] = data[key];

        return result;
    }, {});
};
