import Button from 'Common/components/Button';
import { i18n } from 'Language';
import React, { ChangeEvent, useState } from 'react';
import { signingRedirectUrl, trackSigning, signingData } from './utils';

import { PublicSessionAPI } from 'Api';
import './signing-reject-modal.scss';
import { SigningCasefile, signingStages } from 'types/SigningProcess';

type Props = {
    caseFile?: SigningCasefile;
    challengeKey: string;
    closeModal: () => void;
};

export const SigningRejectModal = ({
    caseFile,
    challengeKey,
    closeModal,
}: Props) => {
    const [message, setMessage] = useState<string>();
    const [error, setError] = useState<string>();

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
        setMessage(event.target.value);

    const handleOnCancel = () => {
        closeModal();
    };

    const handleReject = async () => {
        try {
            const rejectResult = await PublicSessionAPI.post(
                `/casefile/action/reject/${challengeKey}`,
                {
                    reason: message,
                }
            );
            const localData = signingData.get(challengeKey);

            if (localData) {
                signingData.set(challengeKey, {
                    ...localData,
                    ...{ outcome: signingStages.rejected },
                });
            }

            const defaultRedirectUrl = await signingRedirectUrl(
                signingStages.rejected
            );

            window.location.href =
                rejectResult?.redirectUrl ?? defaultRedirectUrl;
        } catch (error) {
            setError(error);
            trackSigning('Signing Reject Modal - Reject fail');
        }
    };

    return (
        <div className="signing-reject-modal">
            <div className="signing-reject-modal-header">
                <h1>
                    {error
                        ? i18n`System error`
                        : caseFile
                        ? i18n`Reject "${caseFile.title}"?`
                        : i18n`Reject this document?`}
                </h1>
            </div>
            <div className="signing-reject-modal-content">
                {error ? (
                    <>
                        <p>
                            {i18n`Unfortunately an error has occured. We are so sorry about this.`}
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            {caseFile
                                ? i18n`Do you wish to reject to sign "${caseFile.title}"?`
                                : i18n`Do you wish to reject signing this document?`}
                        </p>
                        <p>
                            {i18n`State the reason why you don't want to sign:`}{' '}
                            <span className="optional">{i18n`(Optional)`}</span>
                        </p>
                        <textarea
                            name="message"
                            rows={6}
                            onChange={handleChange}
                            value={message}
                        />
                    </>
                )}
            </div>
            <div className="signing-reject-modal-footer">
                {error ? (
                    <>
                        <Button
                            theme={'blue'}
                            variant={'outline'}
                            className="mr"
                            onClick={handleOnCancel}>
                            {i18n`Close`}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            theme={'blue'}
                            variant={'outline'}
                            className="mr"
                            onClick={handleOnCancel}>
                            {i18n`Cancel`}
                        </Button>
                        <Button
                            theme={'red'}
                            className="ml"
                            onClick={handleReject}>
                            {i18n`I don't want to sign`}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default SigningRejectModal;
