import { createComponent } from '@lit/react';
import { Footer } from '@penneo/vega-ui';
import React from 'react';

const UIModalFooter = createComponent({
    tagName: 'vega-modal-footer',
    elementClass: Footer,
    react: React,
});

export default UIModalFooter;
