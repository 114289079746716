import { OIDs } from 'OpenID/Constants';
import {
    OpenIDObj,
    OpenIDCertificate,
    OpenIDToken,
    OpenIDSubjectIdentifiers,
} from 'OpenID/redux/types';
import { EIDENT_AMRS, makeEIdentCertProcessor } from './eident';

export type BankIDSEToken = OpenIDToken & {
    iss: string;
    amr: 'se_bankid';
    given_name: string;
    ssn: string;
    se_ssn: string;
    surname: string;
    family_name: string;
    name: string;
};

export type BankIDSESubjectIdentifiers = OpenIDSubjectIdentifiers & {
    [OIDs.OPENID_FULL_NAME_V2]: string;
};

export type BankIDSECertificate = OpenIDCertificate<BankIDSESubjectIdentifiers>;

export const bankidSe: OpenIDObj<
    BankIDSEToken,
    BankIDSECertificate
> = makeEIdentCertProcessor(EIDENT_AMRS.BANKID_SE);
