import { Dispatcher, BaseStore, storage } from 'Core';
import { AuthActions as Actions } from '../ActionTypes';
import { CustomerEntity } from 'types/Customer';
import { UserEntity } from 'types/User';
import Globals from 'Constants';
import assign from 'object-assign';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { debug } from 'Core';
import { Settings } from '../../Settings/redux/types';

let _auth = {
    uid: null,
    cid: null,
    isFetching: false,
    isAuthenticated: false,
    token: null,
    error: null,
};

let _user: UserEntity | null = null;
let _customer: CustomerEntity | null = null;
let _successUrl: string | null = null;
let _settings: Settings | null = null;

function decodeJWTToken(token) {
    try {
        return jwtDecode(token);
    } catch (error) {
        return null;
    }
}

function handleAuthenticationRequest() {
    _auth = assign(
        {},
        {
            isAuthenticated: false,
            isFetching: true,
        }
    );
}

function handleAuthenticationSuccess(token, user, customers, settings) {
    const jwt = decodeJWTToken(token) as JwtPayload & {
        uid: number;
        cid: number;
        u_qty?: number;
    };

    // If user or token are undefined, don't authenticate
    if (!jwt || !user) {
        _auth = assign(
            {},
            {
                isAuthenticated: false,
                isFetching: false,
                error: 'ERROR_INVALID_TOKEN',
            }
        );

        _user = null;

        return;
    }

    const authParameters = {
        uid: jwt.uid,
        cid: jwt.cid,
        isFetching: false,
        // if user quantity is not available, consider the user to have only one profile
        userCount: jwt.u_qty || 1,
        isAuthenticated: true,
        token: token,
    };

    _auth = assign({}, authParameters);
    _user = user;

    if (customers) {
        _customer = customers[0];
    }

    _settings = settings;
}

function handleAuthenticationError(error) {
    _auth = assign(
        {},
        {
            isFetching: false,
            isAuthenticated: false,
            error: error,
        }
    );

    _user = null;
    _customer = null;
}

function clearAuthError() {
    _auth.error = null;
}

function updateLoginSuccessUrl(url) {
    _successUrl = url;
}

// Flux Store Creation
const AuthStore = assign({}, BaseStore, {
    isAuthenticated() {
        return _auth.isAuthenticated;
    },

    isFetching() {
        return _auth.isFetching;
    },

    getAuthDetails() {
        return _auth;
    },

    getAuthError() {
        return _auth.error;
    },

    getUser() {
        return _user;
    },

    getCustomer() {
        return _customer;
    },

    getSettings() {
        return _settings;
    },

    isAdmin() {
        return _user && _user.role === 'administrator';
    },

    getCachedToken() {
        return storage.get(Globals.PENNEO_TOKEN_KEY);
    },

    getRefreshToken() {
        return storage.get(Globals.PENNEO_REFRESH_KEY);
    },

    getSuccessUrl() {
        return _successUrl;
    },

    clearState() {
        _auth = {
            uid: null,
            cid: null,
            isFetching: false,
            isAuthenticated: false,
            token: null,
            error: null,
        };

        _user = null;
        _customer = null;
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Actions.AUTHENTICATION_REQUEST:
                handleAuthenticationRequest();
                break;
            case Actions.AUTHENTICATION_SUCCESS:
                handleAuthenticationSuccess(
                    action.token,
                    action.user,
                    action.customers,
                    action.settings
                );
                break;
            case Actions.AUTHENTICATION_FAILURE:
                handleAuthenticationError(action.error);
                break;
            case Actions.CLEAR_AUTHENTICATION_ERROR:
                clearAuthError();
                break;
            case Actions.UPDATE_LOGIN_SUCCESS_URL:
                updateLoginSuccessUrl(action.url);
                break;
            case Actions.UNAUTHORIZED:
                handleAuthenticationError(action.error);
                break;
            case Actions.LOGOUT:
                AuthStore.clearState();
                break;
            default:
                return false;
        }
        AuthStore.emitChange(action.type);
    }),
});

debug.export('AuthStore', AuthStore);
export default AuthStore;
