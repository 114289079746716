import PropTypes from 'prop-types';
import React from 'react';
import NewsActions from 'News/actions/NewsActions';
import NewsStore from 'News/stores/NewsStore';
import assign from 'object-assign';
import { i18n } from 'Language';

export default class NewsToggleWidget extends React.Component {
    static propTypes = {
        simple: PropTypes.bool,
    };

    state = NewsStore.getState();

    componentDidMount() {
        NewsStore.addChangeListener(this.onChange);
        this.loadData();
    }

    componentWillUnmount() {
        NewsStore.removeChangeListener(this.onChange);
    }

    loadData = () => {
        NewsActions.fetchNews();
    };

    onChange = () => {
        let state = assign(
            {
                unreadItems: NewsStore.getUnreadItems(),
            },
            NewsStore.getState()
        );

        this.setState(state);
    };

    toggleNews = () => {
        NewsActions.toggleNews();
    };

    render() {
        let { unreadItems = [] } = this.state;
        let unreadClassName = unreadItems.length > 0 ? 'unread-items' : '';

        if (this.props.simple) {
            return (
                <a
                    onClick={this.toggleNews}
                    className={'news-toggle-widget-simple ' + unreadClassName}>
                    {unreadItems.length > 0 && (
                        <span className="unread-count">
                            {unreadItems.length}
                        </span>
                    )}

                    <i className="fa fa-feed"></i>
                </a>
            );
        }

        return (
            <div className={'news-toggle-widget ' + unreadClassName}>
                <a onClick={this.toggleNews}>
                    <i className="fa fa-feed"></i>
                    &nbsp;
                    {i18n`What's New?`}
                    <span className="unread-count">{unreadItems.length}</span>
                </a>
            </div>
        );
    }
}
