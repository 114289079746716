import React, { useEffect, useRef, useState } from 'react';
import analytics from 'Common/Analytics';
import { defaultBranding } from 'Branding/Constants';
import {
    ArrowUpTrayIcon,
    ArrowTopRightOnSquareIcon,
} from '@heroicons/react/20/solid';
import {
    DocumentArrowUpIcon,
    DocumentCheckIcon,
} from '@heroicons/react/24/outline';
import UIButton from 'Common/components/Vega/Button';
import DocumentFile from './DocumentFile';
import { PublicFormattingServiceApi } from 'Api/ApiClient';

export type UtilityState =
    | 'idle'
    | 'uploading'
    | 'uploadError'
    | 'notPenneoDocument'
    | 'uploadSuccess'
    | 'noActionNeeded'
    | 'validationError';

type State = {
    caseDocument: File | null;
    utilityState: UtilityState;
    downloadUrl?: string;
};

const resolveErrorCode = (errorCode: number) => {
    switch (errorCode) {
        case 4:
            return 'notPenneoDocument';
        case 6:
            return 'validationError';
        case 99:
            return 'noActionNeeded';
        default:
            return 'uploadError';
    }
};

const timestampFileName = (fileName: string) => {
    const now = new Date();
    const date = now.toISOString().split('T')[0];
    const time = now.toTimeString().slice(0, 8);

    const [name, extension] = fileName
        .split('.')
        .reverse()
        .slice(0, 2)
        .reverse();

    return `${name}-formatted-${date}-${time}.${extension}`;
};

const timeOut = 10000;

const Formatter = () => {
    const [state, setState] = useState<State>({
        caseDocument: null,
        utilityState: 'idle',
        downloadUrl: undefined,
    });
    const { caseDocument, utilityState } = state;

    const timeoutIdRef = useRef<number | NodeJS.Timeout | null>(null);

    const trackAction = (action: string, description?: string) => {
        analytics.track(`formatting-service - ${action}`, {
            description: description || 'not specified',
        });
    };
    const activateFileAddHandler = () => {
        const fileUpload = document.getElementById('fileupload') as HTMLElement;

        fileUpload.click();

        trackAction('upload-document - clicked');
    };

    const handleUpload = async (file: File) => {
        try {
            const response = await PublicFormattingServiceApi.file(
                '/public/api/v1/pdf-amend',
                { file },
                { responseType: 'arraybuffer' }
            );

            const url = window.URL.createObjectURL(
                new Blob([response], { type: 'application/pdf' })
            );

            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', timestampFileName(file.name));
            document.body.appendChild(link);
            link.click();
            link.remove();

            setState((prevState) => ({
                ...prevState,
                utilityState: 'uploadSuccess',
                downloadUrl: url,
            }));

            trackAction('document-formatted');
            timeoutIdRef.current = setTimeout(() => {
                reset();
            }, timeOut);
        } catch (error) {
            const decoder = new TextDecoder('utf-8');
            const jsonString = decoder.decode(error.data);

            error.data = JSON.parse(jsonString);

            setState((prevState) => ({
                ...prevState,
                utilityState: resolveErrorCode(error.data.errorCode),
            }));

            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current as number);
            }

            timeoutIdRef.current = setTimeout(() => {
                reset();
            }, timeOut);
        }
    };

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        trackAction('file selected for upload');

        const files = Array.from(event.target.files || []);

        if (files.length > 0) {
            setState((prevState) => ({
                ...prevState,
                utilityState: 'uploading',
                caseDocument: files[0],
            }));

            handleUpload(files[0]);
        }
    };

    const reset = () => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current as number);
        }

        setState((prevState) => ({
            ...prevState,
            utilityState: 'idle',
            caseDocument: null,
            downloadUrl: undefined,
        }));
    };

    const clearExistingTimeout = () => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current as number);
            timeoutIdRef.current = null;
        }
    };

    useEffect(() => {
        return () => clearExistingTimeout();
    }, []);

    return (
        <>
            <div className="h-[50px] p-2 fixed top-0 w-full z-20 bg-[#04224c]">
                <div className="flex justify-between items-center h-full max-w-[1170px] mx-auto">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="signing-header-logo-link"
                        href={'https://penneo.com'}>
                        <img
                            src={defaultBranding.imageUrl}
                            className="signing-header-logo"
                        />
                    </a>
                </div>
            </div>
            <div className="mt-[50px] px-4 py-8 max-w-[1170px] min-h-[calc(100vh-50px)] max-h-[calc(100vh-50px)] mx-auto bg-white">
                <div className="px-4 py-8 max-w-[904px] mx-auto">
                    <div className="text-[30px] font-semibold mb-6 text-[#086AFA]">
                        Formatting service
                    </div>
                    <div className="bg-[#F3F4F6] h-[1px] w-100 mb-6" />
                    <p className="mb-8 text-[#111827]">
                        You may have experienced a validation issue with the
                        signed document via Penneo. It appears that a system
                        error occurred during the processing of your signed
                        document. The error only relates to the formatting of
                        the document. The signature and PDF data were not
                        affected. To resolve this issue, the XML attachments to
                        this document require formatting. Please follow the
                        instructions below. If you have any questions,{' '}
                        <a
                            href={'https://www.support.penneo.com'}
                            target="_blank"
                            rel="noreferrer"
                            className="text-[#086AFA] font-semibold"
                            onClick={() => {
                                trackAction('contact-support - clicked');
                            }}>
                            Contact support
                            <ArrowTopRightOnSquareIcon
                                className="h-4 mt-[-2px] ml-1 text-3xl 
         inline-block align-middle"></ArrowTopRightOnSquareIcon>
                        </a>
                    </p>

                    <div className="text-[24px] text-[#111827] font-semibold mb-8">
                        How to do it
                    </div>

                    <div className="flex flex-col bg-[#F3F4F6] radius-6 p-6">
                        <div className="inline-flex mb-6">
                            {utilityState === 'uploadSuccess' ? (
                                <DocumentCheckIcon className="h-5 align-middle" />
                            ) : (
                                <DocumentArrowUpIcon className="h-5 align-middle" />
                            )}
                            <span className="ml-2 text-[#111827]">
                                {utilityState === 'uploading'
                                    ? 'Formatting in progress. Please wait...'
                                    : 'Upload the document to format'}
                            </span>
                        </div>
                        {!caseDocument && (
                            <>
                                <input
                                    hidden
                                    type="file"
                                    data-testid="fileupload"
                                    id="fileupload"
                                    name="files[]"
                                    className="file"
                                    accept="application/pdf"
                                    onChange={onFileInputChange}
                                    onClick={(event) =>
                                        ((event.target as HTMLInputElement).value =
                                            '')
                                    }
                                    multiple={false}
                                />
                                <UIButton
                                    tabIndex={0}
                                    onClick={activateFileAddHandler}
                                    priority="primary"
                                    size="md">
                                    <ArrowUpTrayIcon className="h-5 ml-[-8px]" />
                                    <span className="text-[14px]">
                                        Upload document
                                    </span>
                                </UIButton>
                            </>
                        )}
                        {caseDocument && (
                            <DocumentFile
                                documentState={utilityState}
                                fileName={caseDocument.name}
                                onReset={
                                    utilityState !== 'uploading'
                                        ? reset
                                        : undefined
                                }
                            />
                        )}
                    </div>
                    <p className="mt-4">
                        <span className="font-semibold text-[#111827]">
                            Please note:
                        </span>{' '}
                        You can only check documents signed using{' '}
                        <span className="font-semibold text-[#111827]">
                            Penneo.com
                        </span>{' '}
                        and only one document at a time.
                    </p>
                </div>
            </div>
        </>
    );
};

export default Formatter;
