import React, { useState } from 'react';

type Props = {
    variant: 'danger' | 'warning' | 'success';
    content: string | JSX.Element;
    icon?: JSX.Element;
};

export default function Banner({ variant, content, icon }: Props) {
    const [showBanner, setShowBanner] = useState(true);

    const handleClick = () => {
        setShowBanner((prevState) => !prevState);
    };

    const getBackgroundColor = () => {
        switch (variant) {
            case 'danger':
                return 'bg-danger-700';
            case 'success':
                return 'bg-success-700';
            case 'warning':
                return 'bg-warning-700';
        }
    };

    return (
        <>
            {showBanner && (
                <div
                    className={`w-full mt-14 py-4 ${getBackgroundColor()} text-white`}>
                    <div className="flex items-center justify-center gap-4">
                        {icon}
                        <div className="text-center font-semibold">
                            {content}
                        </div>

                        <i
                            className="fa fa-times cursor-pointer"
                            aria-hidden="true"
                            onClick={handleClick}></i>
                    </div>
                </div>
            )}
        </>
    );
}
