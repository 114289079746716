/* eslint camelcase: 0 */
import { OIDs } from 'OpenID/Constants';
import {
    OpenIDObj,
    OpenIDCertificate,
    OpenIDToken,
    OpenIDSubjectIdentifiers,
} from 'OpenID/redux/types';
import { EIDENT_AMRS, makeEIdentCertProcessor } from './eident';

export type BaseFTNToken = OpenIDToken & {
    iss: string;
    birthdate: string;
    amr: string;
    pid: string;
    given_name: string;
    nonce: string;
    ssn: string;
    fi_ssn: string;
    surname: string;
    givenname: string;
    auth_time: string;
    name: string;
    fullname: string;
    family_name: string;
    jti: string;
};

export type BankIDFIToken = BaseFTNToken & {
    amr: 'fi_tupas';
    fi_tupas_bank?: string;
    fi_tupas_pid?: string;
};

export type MobiilivarmenneToken = BaseFTNToken & {
    amr: 'fi_mobiilivarmenne';
    fi_trx_code?: string;
};

export type FTNToken = BankIDFIToken | MobiilivarmenneToken;

export type FTNSubjectIdentifiers = OpenIDSubjectIdentifiers & {
    [OIDs.OPENID_FULL_NAME_V2]: string;
};

export type FTNCertificate = OpenIDCertificate<FTNSubjectIdentifiers>;

export const ftn: OpenIDObj<FTNToken, FTNCertificate> = makeEIdentCertProcessor(
    EIDENT_AMRS.FTN_FI
);
