import { pickBy } from 'lodash';
import LaunchDarkly from 'Common/LaunchDarkly';
import { Language } from './types';
import { Languages, LANGUAGES } from './Constants';
import { translationsPreservedSpaces } from 'Language';
import axios from 'axios';
import Constants from 'Constants';

export let getLanguageList = () => {
    let arr: Language[] = [];

    for (let key in getEnabledLanguages()) {
        if (!LANGUAGES.hasOwnProperty(key)) {
            continue;
        }

        arr.push(LANGUAGES[key]);
    }

    return arr;
};

export const getEnabledLanguages = (): typeof LANGUAGES =>
    pickBy(LANGUAGES, (language: Language) => {
        if (!language.availabilityFlag) {
            return true;
        }

        return LaunchDarkly.variation(language.availabilityFlag);
    });

export async function i18nCustomLanguage(
    textToTranslate: string,
    language: Languages
) {
    const { i18n } = translationsPreservedSpaces;

    translationsPreservedSpaces.changeLanguage(language);

    return i18n(textToTranslate);
}

export async function getDictionaryByLanguage(language: Languages) {
    const client = axios.create({
        baseURL: Constants.PENNEO_LANGUAGE_ENDPOINT,
    });
    const { data } = await client.get(`/${language}.json`);

    return data;
}
