import React from 'react';
import { i18n } from 'Language';
import Widget from 'Common/components/Widget';
import { parseCertificate } from 'OpenID/utils/certificate';
import { getCertificateDisplayInfo } from 'OpenID/utils/openIdCertificate';

type Props = {
    certificate: string;
};

export default function Certificate({ certificate }: Props) {
    const certificateData = parseCertificate(certificate);
    const displayInfo = getCertificateDisplayInfo(certificateData);

    return (
        <Widget>
            <div className="border border-[rgba(22,188,101)] rounded-[3px] border-solid text-xs">
                <div className="mt-4 mr-4 mb-4 ml-4">
                    <div className="flex items-center">
                        <i className="far fa-file-certificate fa-2x text-green"></i>
                        <span className="ml-2 text-[14px] font-semibold leading-[20px] text-[#111827]">{i18n`Signing Certificate`}</span>
                    </div>
                    {displayInfo && (
                        <span className="block mt-1">
                            {displayInfo.organization && (
                                <>
                                    <span className="block text-[12px] font-normal leading-[16px] ml-8 mb-1">
                                        {displayInfo.organization.name} <br />
                                        {displayInfo.organization.idType}:{' '}
                                        {displayInfo.organization.id}
                                    </span>
                                </>
                            )}
                            {displayInfo.issuer && (
                                <span className="block text-[12px] font-normal leading-[16px] ml-8">
                                    {i18n(
                                        `openIdCertificateIssuedBy`,
                                        displayInfo.issuer
                                    )}
                                </span>
                            )}
                        </span>
                    )}

                    {displayInfo && (
                        <span className="block mt-1 ml-8 text-[14px] font-semibold uppercase text-[#111827]">
                            {displayInfo.title}
                        </span>
                    )}
                </div>
            </div>
        </Widget>
    );
}
