import { flatMap, uniqBy, curryRight } from 'lodash';
import { SignerRole } from 'types/SignerRole';
import { CaseFileEntity } from 'types/CaseFile';
import { SignerEntity } from 'types/Signer';
import { SignatureLineEntity } from 'types/Document';

/**
 * Extracts a list of roles of a signer from the signature lines of a casefile
 *
 * @param signer
 * @param caseFile
 * @return A list of roles, each with both the original and custom name (if any)
 */
export const getSignerRolesFromSignatureLines = (
    signer: SignerEntity,
    caseFile: CaseFileEntity
): SignerRole[] => {
    return getSignerSignatureLines(caseFile, signer.id).map(
        (line): SignerRole => {
            const {
                role,
                signerTypeOriginalRole: originalName,
                activeAt,
            } = line;
            const hasCustomName = originalName && originalName !== role;

            return {
                role: hasCustomName ? originalName : role,
                ...(hasCustomName && { customName: role }),
                ...(activeAt && { activeAt }),
            };
        }
    );
};

const getSignerSignatureLines = (
    casefile: CaseFileEntity,
    signerId: number
): SignatureLineEntity[] => {
    const { documents } = casefile;
    const uniqByRole = curryRight(uniqBy)('role'); // RealProgramming™

    const result = flatMap(documents, ({ signatureLines }) => {
        const signerLines = signatureLines.filter(
            (line) => line.signerId === signerId
        );

        // Deduplicate roles by name. This is done due to the payload in signaturelines missing the
        // signer type ID, and it could potentially lead to role data being missing from the UI,
        // However, the chances are pretty slim.
        return uniqByRole(signerLines);
    });

    // Deduplicate roles from different documents with the same role.
    return uniqByRole(result);
};
