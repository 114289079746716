import React, { useEffect } from 'react';

import { i18n } from 'Language';

// Redux
import { connect } from 'react-redux';
import { ReduxState } from 'Store';

// Components
import FullScreenSendingError from 'Common/components/FullScreenSendingError';
import FullScreen from 'Common/components/FullScreen';

export type Props = {
    onBack: Function;
    onRetry: Function;
    error?: any;
    sent?: boolean;
    router: any;
};

const RegisteredLetterSendingState: React.FunctionComponent<Props> = (
    props
) => {
    const { error, sent, onRetry, onBack, router } = props;
    const errorRequestId = error?.headers?.['x-penneo-request-id'];
    const errorTraceId = error?.headers?.['x-b3-traceid'];

    useEffect(() => {
        /**
         * If the casefile is set to 'sent', redirect user to Dashboard.
         */
        if (sent) {
            router.push({
                name: 'dashboard',
            });
        }
    }, [sent]);

    const renderLoader = () => (
        <div>
            <h1>
                <i className="far fa-sync fa-spin"></i>
                <br />
                {i18n`Packing things up!`}
            </h1>
        </div>
    );

    return error ? (
        <FullScreenSendingError
            error={error}
            onRetry={onRetry}
            onBack={onBack}
            errorRequestId={errorRequestId}
            errorTraceId={errorTraceId}
        />
    ) : (
        <FullScreen>{renderLoader()}</FullScreen>
    );
};

export default connect((state: ReduxState) => ({
    sent: state.registeredLetter.view.isSent,
    error: state.registeredLetter.view.error,
}))(RegisteredLetterSendingState);
