import React, { useEffect } from 'react';
import OpenIDSignText from 'OpenID/components/OpenIDSignText';
import { i18n } from 'Language';
import Button from 'Common/components/Button';
import { useAppDispatch, useAppSelector } from 'Store';
import {
    loadState,
    secondDance,
    showError,
    StateType,
} from 'ItsmeQES/redux/reducer';
import LoadingData from 'Common/components/Loaders/LoadingData';
import { invalidState } from 'ItsmeQES/errors';

export function ItsmeDataToBeSignedComponent() {
    const state = useAppSelector((state) => state.itsmeQESSigning);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (state.type !== StateType.ShowingDataToSign) {
            dispatch(
                showError({
                    error: invalidState(
                        StateType.ShowingDataToSign,
                        'showing sign text'
                    ),
                    base: (state as any).base,
                })
            );
        }
    }, []);

    if (state.type !== StateType.ShowingDataToSign) {
        // Console log and a Loading spinner is not enough, but we dispatch showError() in useEffect, so
        // users will see something better on the next frame.
        console.error(
            'Invalid state in ItsmeDataToBeSignedComponent. Expected ShowingDataToSign',
            state
        );

        return <LoadingData></LoadingData>;
    }

    const { signText } = state;
    const { challengeKey, language } = state.base;

    const handleSign = () => {
        dispatch(secondDance());
    };

    const handleRestart = () => {
        dispatch(loadState(challengeKey, language));
    };

    return (
        <>
            <OpenIDSignText signText={signText} />

            <p>
                {i18n`Reference`} <strong>{challengeKey}</strong>
            </p>

            <br />
            <Button
                theme="green"
                className="itsme-qes-sign-button"
                data-testid="itsme-qes-sign-button"
                size="large"
                onClick={handleSign}>
                {i18n`Sign Documents`}
            </Button>
            <div className="mt">
                <a
                    className="openid-sign-link"
                    data-testid="itsme-qes-sign-switch-acc"
                    onClick={handleRestart}>
                    {i18n`Or use another account`}
                </a>
            </div>
        </>
    );
}
