import { images } from 'Constants';
import { i18n } from 'Language';
import { Languages } from 'Language/Constants';
import React from 'react';
import { CustomerSigningMethod } from 'types/Customer';
import { InsecureSigningMethod } from 'types/Signer';

import './signing-component.scss';

export enum ComponentType {
    sign = 'sign',
    validate = 'validate',
}

export type SigningComponentProps = {
    challengeKey?: string;
    languageCode: Languages;
    serviceId?: CustomerSigningMethod;
    legacyServiceName?: string;
    insecureMethod?: InsecureSigningMethod;
    encryptedNIN?: string;
};

type ValidationComponentProps = {
    maskedPhoneNumber?: string;
};

type Props = SigningComponentProps &
    ValidationComponentProps & {
        type?: ComponentType;
        children: React.ComponentType<SigningComponentProps> | null;
    };

export const SigningComponent = ({
    type = ComponentType.sign,
    challengeKey,
    languageCode,
    serviceId,
    legacyServiceName,
    insecureMethod,
    children,
    maskedPhoneNumber,
    encryptedNIN,
}: Props) => {
    const childrenProps =
        type === ComponentType.sign
            ? {
                  challengeKey,
                  languageCode,
                  serviceId,
                  legacyServiceName,
                  ...(insecureMethod && {
                      insecureMethod,
                  }),
                  encryptedNIN,
              }
            : {
                  languageCode,
                  maskedPhoneNumber,
                  challengeKey,
              };

    return (
        <div
            className={`penneo-react-context signing-components ui-v2 ${
                serviceId ?? ''
            }`}>
            <div className="penneo-auth penneo-casefiles ui-v2">
                {children && React.createElement(children, childrenProps)}
                <br />

                <p className="text-center">
                    <a
                        href="https://penneo.com"
                        target="_blank"
                        rel="noopener noreferrer">
                        <span
                            style={{
                                opacity: 0.75,
                                fontSize: '12px',
                                color: '#333',
                            }}>
                            {i18n`Service provided by`}
                            &nbsp;
                        </span>
                        <img
                            style={{
                                position: 'relative',
                                top: '1px',
                                left: '2px',
                            }}
                            src={`${images}/penneo-blue.png`}
                            height="10"
                            alt="penneo.com"
                        />
                    </a>
                </p>
            </div>
        </div>
    );
};
