import React from 'react';
import { i18n } from 'Language';
import './modal-confirm.scss';
import Button from 'Common/components/Button';
import Message from 'Common/components/Message';

export type Props = {
    message: string;
    onCancel: () => any;
    onOk: () => any;
    confirmButtonText?: string;
    helperMessage?: string | JSX.Element;
};

/**
 * Shows a modal with a message.
 *
 * The user can choose between clicking a button to confirm or cancel. If a helper message prop is
 * passed, it shows up in a Message component in the modal which might be used to
 * clarify what the user is actually confirming. The text used for the confirmation
 * button can either be passed as a prop, or it will default to 'Confirm'.
 *
 * @example
 *   const message = 'Are you sure you want to do this?';
 *   const helperMessage = 'If you do this, it will have these consequences.'
 *   const confirmButtonText = 'Confirm'
 *   const onOk = () => { console.log('You confirmed') }
 *   const onCancel = () => { console.log('You canceled') }
 *
 *   <ModalConfirm message={message} helperMessage={helperMessage} onOk={onOk} onCancel={onCancel} />
 */
const ModalConfirm = ({
    onOk,
    onCancel,
    message,
    helperMessage,
    confirmButtonText = i18n('Confirm'),
}: Props) => (
    <div className="modal-confirm">
        <div className="messages">
            {message && (
                <div className="message" data-testid="message">
                    {message}
                </div>
            )}
            {helperMessage && (
                <div className="helper-message" data-testid="helper-message">
                    <Message type="info">{helperMessage}</Message>
                </div>
            )}
        </div>

        <div className="actions">
            <Button
                className="pull-left"
                onClick={onCancel}
                data-testid="cancel-button">
                {i18n('Cancel')}
            </Button>
            <Button
                theme="red"
                className="pull-right"
                onClick={onOk}
                data-testid="confirm-button">
                {confirmButtonText}
            </Button>
        </div>
    </div>
);

export default ModalConfirm;
