import React from 'react';
import { i18n } from 'Language';
import { EID_METHODS } from 'EID';
import { Link } from 'react-router';
import Button from 'Common/components/Button';
import { Intent, ServiceIDs } from 'OpenID/Constants';
import { clearOpenIdState, getOpenIdState } from 'OpenID/utils/openIdState';
import OpenIDErrorDetails from './OpenIDErrorDetails';
import { ERROR_OPENID_005, OpenIDClientError } from 'OpenID/Errors';

import { PublicAuthAPI } from 'Api';
import Analytics from 'Common/Analytics';
import { EIDButtonWrapper } from '../../../EID/components/EIDButtonWrapper';
import launchDarkly, { Flags } from '../../../Common/LaunchDarkly';
import analytics from 'Common/Analytics';

export type { OpenIDClientError } from 'OpenID/Errors';

type Props = {
    serviceId: ServiceIDs;
    intent?: Intent;
    error: OpenIDClientError;
};

type State = {
    isSending: boolean;
    success: boolean | null;
    resendToken: string | null;
};

export default class OpenIDError extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isSending: false,
            success: null,
            resendToken: null,
        };
    }
    getAuthenticationMethod = () => {
        const { serviceId } = this.props;

        return EID_METHODS.filter((m) => serviceId === m.type)[0];
    };
    componentDidMount() {
        this.getResendToken();
    }
    getResendToken = () => {
        const { data } = this.props.error;

        if (!data?.error?.data?.resendToken) {
            return;
        }

        this.setState({ resendToken: data?.error?.data?.resendToken ?? null });
    };

    getMessages = () => {
        const { code, details } = this.props.error;

        if (!this.props.intent) {
            return {
                title: i18n`There was a problem processing your request to authenticate to Penneo`,
                subtitle: i18n`Please, restart the process or contact support if this keeps on happening`,
                linkText: null,
            };
        }

        if (code === ERROR_OPENID_005.code) {
            const { resendToken } = this.state;

            if (resendToken) {
                return {
                    title: i18n`Please, finish setting up your account`,
                    subtitle: i18n`You weren't able to log in because you haven’t completed your account activation`,

                    linkText: i18n`Request new activation link`,
                };
            }
        }

        const method = this.getAuthenticationMethod();

        const messages = {
            [Intent.LOGIN]: {
                title: i18n`There was a problem using ${method.title} to authenticate to Penneo`,
                subtitle: i18n`Please, go to the login page and try again or contact support if the problem persists`,
                linkText: i18n`Go to Login Page`,
            },
            [Intent.VALIDATE_ID]: {
                title: i18n`There was a problem using ${method.title} to authenticate to Penneo`,
                subtitle: i18n`Please, go to the login page and try again or contact support if the problem persists`,
                linkText: i18n`Go to Login Page`,
            },
            [Intent.ADD]: {
                title: i18n`There was a problem connecting this ${method.title} account to Penneo`,
                subtitle: i18n`Please, return to manage your credentials and try again. If the problem persists, contact support`,
                linkText: i18n`Return to Manage Credentials`,
            },
            [Intent.SIGN]: {
                title: i18n`There was a problem processing the signing request`,
                subtitle: i18n`Please, return to sign your documents and try again. If the problem persists, contact support`,
                linkText: i18n`Return to documents`,
            },
            [Intent.ACTIVATION]: {
                title: i18n`There was a problem connecting this ${method.title} account to Penneo`,
                subtitle: i18n`Please, return to the activation process and try again. If the problem persists, contact support`,
                linkText: i18n`Return to Account Activation`,
            },
        };

        if (this.userDoesNotHaveMitIDArchive(details)) {
            return {
                ...messages[this.props.intent],
                title: i18n`You don't have a Penneo document archive with MitID.`,
                subtitle: i18n`If you've signed Penneo documents with NemID, press the button to reclaim your NemID account using MitID`,
            };
        }

        return messages[this.props.intent];
    };

    userDoesNotHaveMitIDArchive = (
        details: string | null,
        method = this.getAuthenticationMethod(),
        intent = this.props.intent
    ) => {
        return (
            launchDarkly.variation(Flags.RECLAIM_NEMID_ACCOUNT) &&
            this.props.intent === Intent.LOGIN &&
            method.credentialType() === 'mitid.dk' &&
            details === 'Could not set up login identity'
        );
    };

    redirect = () => {
        const openIdState = getOpenIdState();

        if (!openIdState) {
            return false;
        }

        const { successUrl } = openIdState;

        // Clear the OpenID State before going back to the initial URL
        clearOpenIdState();

        return window.location.replace(successUrl);
    };

    resendSetup = async () => {
        this.setState({
            isSending: true,
        });

        try {
            Analytics.track('request new activation link');

            await PublicAuthAPI.post(
                `/cred/requests/resend/${this.state.resendToken}`
            );
            this.setState({
                success: true,
                isSending: true,
            });
        } catch (error) {
            Analytics.track('error requesting activation link');

            this.setState({
                success: false,
                isSending: false,
            });
        }
    };

    renderButton = () => {
        const { intent } = this.props;
        const message = this.getMessages();

        if (intent === Intent.LOGIN && this.state.resendToken) {
            return (
                <Button
                    theme="blue"
                    onClick={this.resendSetup}
                    disabled={
                        (this.state.isSending || this.state.success) ??
                        undefined
                    }>
                    {this.state.isSending && i18n`Sending activation link`}
                    {!this.state.isSending && message.linkText}
                    {this.state.success && i18n`Check your email inbox`}
                </Button>
            );
        }

        if (this.userDoesNotHaveMitIDArchive(this.props.error.details)) {
            const mitidFlowWithCPR = EID_METHODS.find(
                (method) => method.type === 'any.mitid.dk'
            );

            const handleSubmitClick = () => {
                analytics.track('reclaim-nemid-account', {
                    type: 'button clicked in openid error page',
                });
            };

            return (
                <div className="flex flex-col space-y-3">
                    <EIDButtonWrapper
                        method={mitidFlowWithCPR!}
                        intent={Intent.LOGIN}>
                        <Button
                            onClick={handleSubmitClick}
                            theme="blue">{i18n`Reclaim NemID account`}</Button>
                    </EIDButtonWrapper>

                    <Link className="text-secondary-600" to="login-route">
                        {message.linkText}
                    </Link>
                </div>
            );
        }

        if (intent === Intent.LOGIN) {
            return (
                <Link to="login-route">
                    <Button theme="blue">{message.linkText}</Button>
                </Link>
            );
        }

        return (
            <Button theme="blue" onClick={this.redirect}>
                {message.linkText}
            </Button>
        );
    };

    render() {
        const { error } = this.props;
        const message = this.getMessages();

        return (
            <div>
                <div>
                    <h2 className="title">{message.title}</h2>
                </div>
                <OpenIDErrorDetails error={error} />

                <p className="subtitle">{message.subtitle}</p>
                {this.state.resendToken && (
                    <p>
                        {i18n`You can request a new link to finish activating your account to be sent to your email by clicking the button below.`}
                    </p>
                )}

                <div>
                    {this.state.success === false && (
                        <p>
                            <strong>{i18n`Could not create a new activation link`}</strong>
                        </p>
                    )}
                    {this.state.success ? (
                        <p>
                            <strong>{i18n`We have sent you a new activation link`}</strong>
                        </p>
                    ) : (
                        this.renderButton()
                    )}
                </div>
            </div>
        );
    }
}
