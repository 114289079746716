import { findIndex, cloneDeep } from 'lodash';
import { Dispatcher, BaseStore } from 'Core';
import Constants from '../Constants';
import assign from 'object-assign';
import uniqid from 'uniqid';
import lodash from 'lodash';

import validations from '../components/validation/inputValidation.jsx';

import { API as CasefileStoreAPI } from './CasefileStore';
import CasefileStore from './CasefileStore';
import { debug } from 'Core';
import { SignerValidation } from 'types/DataValidation';

// Validation
import {
    validateSignerSetup,
    validateSigners,
    validateSignerTypes,
    validateCcRecipients,
} from 'Casefiles/utils/casefileValidation';
import DocumentStore from './DocumentStore';

// Collection of signers in a store
let _signers: any[] = [];

export const emptySigner = {
    name: null,
    email: null,
    onBehalfOf: undefined,
    role: [],
    ssn: undefined,
    vatin: undefined,
    accessControl: false,
    reminderInterval: Constants.DEFAULT_REMINDER_INTERVAL,
    enableInsecureSigning: false,
    insecureSigningMethods: [],
};

let _signerTemplate = cloneDeep(emptySigner) as typeof emptySigner;

// Signer Roles
let _availableSignerRoles: any[] = []; // Depends on Selected Document Types
let _signerRules: any | null = null; // Validation rules for signers

function updateValidation(recipients = _signers): SignerValidation {
    const casefile = CasefileStore ? CasefileStore.getCasefile() : {};
    const signers = recipients.filter((r) => !r.type || r.type === 'signer');
    const ccRecipients = recipients.filter((r) => r.type === 'copyrecipient');
    const documents = DocumentStore.getDocuments();
    const casefileType = CasefileStore.getCaseTypeById(casefile.caseFileTypeId);

    return {
        setup: validateSignerSetup(signers, documents, casefileType),
        signers: validateSigners(signers, casefile),
        ccRecipients: validateCcRecipients(ccRecipients),
        signerTypes: validateSignerTypes(signers, _availableSignerRoles),
    };
}

let _validation: SignerValidation = {
    setup: {},
    signers: {},
    ccRecipients: {},
    signerTypes: {},
};

/**
 * Checks if a signer row is empty*.
 * We define empty* as a signer that the user hasn't manually modified the most relevant properties for
 * @param signer
 */
function isEmptySigner(signer): boolean {
    // If these properties are the same in both objects, we consider the signer to be empty.
    const comparisonProperties = [
        'name',
        'email',
        'onBehalfOf',
        'role',
        'accessControl',
        'reminderInterval',
    ];

    return lodash.isEqual(
        lodash.pick(signer, comparisonProperties),
        lodash.pick(_signerTemplate, comparisonProperties)
    );
}

/**
 * Set reminder interval on all signers in the store that haven't been modified manually
 * and update the signer template to make the default interval be applied to all new signers.
 *
 * @param reminderInterval - default reminder interval from the company settings
 */
function setReminderInterval(reminderInterval) {
    if (!reminderInterval) {
        return;
    }

    // Set reminder interval to the initial signer
    _signerTemplate.reminderInterval = reminderInterval;

    // Only override initial settings on signers that are empty/haven't been manually modified.
    // This fixes an issue where users would overwrite data for all signers with an incoming URI
    _signers.forEach((signer) => {
        if (isEmptySigner(signer)) {
            signer.reminderInterval = reminderInterval;
        }
    });
}

function clearStore() {
    _availableSignerRoles = [];
    _signers = [];
    _signerRules = null;
}

function clearSigners() {
    _signers = [];
}

function updateSignerRoles(roles) {
    _availableSignerRoles = roles;

    if (roles.length === 1) {
        _setInitialRole();
    }

    // Check invalid roles
    refreshRoles(roles);
}

function refreshRoles(roles) {
    _signers.forEach((signer) => {
        if (signer.possibleRoles) {
            setPossibleSignerRoles(signer);
        }

        findSignerRole(roles, signer);
    });
}
// Checks for adding possible role to signer
function addPossibleRole(signer, role, index) {
    if (signer.possibleRoles) {
        signer.possibleRoles.push(role);
    } else {
        signer.possibleRoles = [role];
    }

    signer.role.splice(index, 1);
}

function findSignerRole(roles, signer) {
    let roleIds = roles.map((r) => r.id);

    signer.role.forEach((role, index) => {
        if (isNaN(role.id)) {
            let roleId = findSignerRoleByName(roles, role.id);

            if (typeof roleId !== 'undefined' && roleId !== null) {
                signer.role[index].id = roleId;

                return;
            }

            addPossibleRole(signer, role, index);

            return;
        }

        let roleId = Number(role.id);

        if (roleIds.indexOf(roleId) === -1) {
            // Remove non-available roles
            addPossibleRole(signer, role, index);

            return;
        }

        signer.role[index] = { ...role, id: roleId };
    });
}

// This is used when roles are passed as strings from the URI and stored when signer types aren't
// loaded
function setPossibleSignerRoles(signer) {
    for (let i = 0; i < signer.possibleRoles.length; i++) {
        let role = signer.possibleRoles[i];
        let roleId;

        if (!role) {
            continue;
        }

        if (isNaN(role.id)) {
            roleId = findSignerRoleByName(_availableSignerRoles, role.id);
        } else {
            roleId = checkRoleAvailability(Number(role.id));
        }

        if (typeof roleId !== 'undefined' && roleId !== null) {
            role.id = roleId;

            processPossibleRoles(signer, role);
            delete signer.possibleRoles[i];
        }
    }
}

function processPossibleRoles(signer, possibleRole) {
    const { role: roles } = signer;
    const matchIndex = findIndex(roles, (role) => role.id === possibleRole.id);

    // If the possible role is the same (= same id) of one already selected...
    if (matchIndex > -1) {
        const hasCustomName = !!possibleRole.customName;

        // If the possible role has a custom name, then it should replace
        // the one already selected so that we don't lose the custom name
        if (hasCustomName) {
            signer.role = roles.slice();
            signer.role.splice(matchIndex, 1, possibleRole);
        }
    } else {
        // If it doesn't match any already-selected role, then add it to the list
        signer.role.push(possibleRole);
    }
}

function findSignerRoleByName(roles, signerRole) {
    for (let i = roles.length - 1; i >= 0; i--) {
        if (roles[i].role === signerRole) {
            return roles[i].id;
        }
    }
}

// Creates an empty signer row in a table of signers
function addSigner(signer = {}) {
    let template = JSON.parse(JSON.stringify(_signerTemplate));
    let newSigner = assign({}, template, signer, { tempId: uniqid() });

    // If sensitive data is enabled for the casefile, add it for the new signer
    // but only if the signer has not itself the sensitive data setting set to true
    const casefileSensitive =
        CasefileStore && CasefileStore.getCasefile().sensitiveData;

    newSigner.accessControl =
        newSigner.accessControl === true || casefileSensitive || false;

    _signers.push(newSigner);

    // If there are signers, assign the first role.
    if (_signers.length > 1) {
        _setInitialRole();
    }
}

// Removes signer by index
function removeSigner(index, force) {
    _signers.splice(index, 1);

    if (force) {
        return false;
    }
}

// Update Signer
function updateSigner(index, signerData) {
    _signers[index] = assign({}, _signers[index], signerData);
}

function validateSigner(index, editedField) {
    let validationErrors = CasefileStore.getValidationErrors();
    const casefile = CasefileStore.getCasefile();

    if (Object.keys(validationErrors.signers).length !== 0) {
        let errors = assign({}, validationErrors.signers);
        let key = Object.keys(editedField)[0];
        let signerErrors = validations._signerFieldValidations(
            index,
            _signers,
            key,
            errors,
            casefile
        );

        validationErrors.signers = signerErrors;
    }
}

function refreshValidation() {
    let validationErrors = CasefileStore.getValidationErrors();

    if (Object.keys(validationErrors.signers).length !== 0) {
        CasefileStoreAPI.signersValidation();
    }
}

function checkRoleAvailability(signerRoleId) {
    let fetchedRole = _availableSignerRoles.filter(
        (role) => role.id === signerRoleId
    )[0];

    if (fetchedRole) {
        return fetchedRole.id;
    }
}

function updateSignerRules() {
    _signerRules = getSignerRules();
}

function getSignerRules() {
    if (!CasefileStore) {
        return null;
    }

    const casefile = CasefileStore.getCasefile();

    if (!casefile) {
        return null;
    }

    let casefileType = CasefileStore.getCaseTypeById(casefile.caseFileTypeId);

    if (!casefileType) {
        return null;
    }

    let { documentTypes } = casefileType;

    let rules = {};
    let ruleCount = 0;

    // @note: Only take the selected doctypes.
    documentTypes.forEach((documentType) => {
        documentType.signerTypes.forEach((signerType) => {
            if (signerType.lowerLimit > 0 || signerType.upperLimit !== 0) {
                rules[signerType.id] = assign({}, signerType);
                rules[signerType.id].count = 0;

                ruleCount++;
            }
        });
    });

    if (ruleCount === 0) {
        return null;
    }

    return rules;
}

/**
 * Resets signer roles when the document types or casefile types are changed.
 */
function _resetSignerRoles() {
    _signers.forEach((signer) => {
        signer.role = [];
        delete signer.possibleRoles;
    });
}

// Updates a role assigned to a signer.
function updateSignerRole(signerIndex, payload) {
    let signer = _signers[signerIndex];

    let roleExists =
        signer.role.filter((role) => role.id === payload.id).length > 0;

    if (!roleExists) {
        signer.role.push(payload);

        return;
    }

    // If role exists, find item and replace value
    signer.role.forEach((role) => {
        if (role.id === payload.id) {
            assign(role, payload);
        }
    });
}

// If only one role exists, assign it to the first user.
// This should do a smarter matching between available roles and signers.
// Taking into account the lower/upper limits of roles per signer.
function _setInitialRole() {
    // Skip if there is more than 1 role available.
    if (_availableSignerRoles.length !== 1) {
        return false;
    }

    // Skip if there are no signers.
    if (_signers.length === 0) {
        return false;
    }

    // Refresh roles before attempting to automatically select initial roles
    // (There may be already a role selected from an incoming URI at this point)
    refreshRoles(_availableSignerRoles);

    // Assign role to all signers
    _signers.forEach((signer, index) => {
        // Skip if the signer already has a selected role
        if (signer.role.length > 0) {
            return false;
        }

        // Set role on signer
        updateSignerRole(index, {
            id: _availableSignerRoles[0].id,
            enabled: true,
        });
    });
}

function _updateSigners(signers = []) {
    // Don't skip existing signers if there is no new signers to be added
    const currentSigners = SignerStore.getSigners(
        signers.length === 0 ? false : true
    );

    _signers = currentSigners.concat(signers); // Add new signers into array.

    const casefileSensitive =
        CasefileStore && CasefileStore.getCasefile().sensitiveData;

    // Apply the access control properties if casefile has sensitiveData: true.
    if (casefileSensitive) {
        updateAccessControl(true);
    }

    if (_availableSignerRoles.length > 0) {
        refreshRoles(_availableSignerRoles);
    }
}

function updateAccessControlOnSigner(signer, sensitiveData: boolean) {
    // Toggle access control
    signer.accessControl = sensitiveData;

    // Remove SES if accessControl has been turned on
    if (sensitiveData) {
        signer.insecureSigningMethods = [];
        signer.enableInsecureSigning = false;
    }

    if (
        CasefileStore &&
        CasefileStore.getCasefile().sensitiveData !== sensitiveData
    ) {
        CasefileStore.updateCasefile({ sensitiveData });
    }

    return signer;
}

function updateAccessControl(sensitiveData: boolean) {
    // Update signers.
    _signers.forEach((signer) => {
        signer = updateAccessControlOnSigner(signer, sensitiveData);
    });

    // Update signer template.
    _signerTemplate = updateAccessControlOnSigner(
        _signerTemplate,
        sensitiveData
    );
}

/* Flux Store */
const SignerStore = assign({}, BaseStore, {
    getSigners(skipEmpty = false) {
        // Return only signers that have been modified.
        if (skipEmpty) {
            return _signers.filter((signer) => {
                // if signer has any of the following: name, email, ssn or vatin then he is
                // considered not empty
                let isModified = !!(
                    signer.name ||
                    signer.email ||
                    signer.ssn ||
                    signer.vatin
                );

                return isModified;
            });
        }

        return _signers;
    },

    getSigner(index) {
        return _signers[index];
    },

    getSignerRole(signerRoleId) {
        return _availableSignerRoles.filter(
            (role) => role.id === signerRoleId
        )[0];
    },

    getSignerRoleByDocumentType(docType, signerRoleId) {
        return _availableSignerRoles.filter(
            (role) => role.id === signerRoleId
        )[0];
    },

    getAvailableSignerTypes() {
        return _availableSignerRoles;
    },

    getSignerTemplate() {
        return JSON.parse(JSON.stringify(_signerTemplate));
    },

    getSignerRules() {
        return _signerRules;
    },

    getValidation() {
        return _validation;
    },

    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.SIGNER_RULES_UPDATED:
                updateSignerRules();
                break;
            case Constants.ActionTypes.SIGNER_CHANGED:
                updateSigner(action.index, action.data);
                validateSigner(action.index, action.data);
                break;
            case Constants.ActionTypes.SIGNER_ADDED:
                addSigner(action.data);
                break;
            case Constants.ActionTypes.SIGNER_REMOVED:
                removeSigner(action.index, action.force);
                refreshValidation();
                break;
            case Constants.ActionTypes.SIGNERS_CLEARED:
                clearSigners();
                break;
            case Constants.ActionTypes.REMINDER_INTERVAL_SET:
                setReminderInterval(action.reminderInterval);
                break;
            case Constants.ActionTypes.UPDATED_SIGNER_ROLE:
                updateSignerRole(action.index, action.role);
                SignerStore.emit(Constants.ActionTypes.UPDATED_SIGNER_ROLE, {
                    index: action.index,
                    roles: _signers[action.index].role,
                });
                break;
            case Constants.ActionTypes.SIGNER_ROLES_UPDATED:
                updateSignerRoles(action.roles);

                SignerStore.emit(Constants.ActionTypes.SIGNER_ROLES_UPDATED);

                /**
                 * When a casefile type changes and we get the new available roles' list,
                 * if there's only one item, this same role will be applied by default to all signers.
                 * By triggering the UPDATED_SIGNER_ROLE event with a null index,
                 * all signers will be updated as well,
                 * showing the role currently applied to them.
                 */
                if (action.roles.length === 1) {
                    SignerStore.emit(
                        Constants.ActionTypes.UPDATED_SIGNER_ROLE,
                        {
                            index: null,
                            roles: [
                                { id: action.roles?.[0].id, enabled: true },
                            ],
                        }
                    );
                }

                break;
            case Constants.ActionTypes.SIGNER_ROLES_RESET:
                _resetSignerRoles();
                SignerStore.emit(Constants.ActionTypes.SIGNER_ROLES_RESET);
                break;
            case Constants.ActionTypes.SIGNERS_CHANGED:
                _updateSigners(action.signers);
                break;
            case Constants.ActionTypes.SENSITIVE_DATA_CHANGED:
                // Update all signers. Toggle private mode
                updateAccessControl(action.sensitiveData);
                break;
            case Constants.ActionTypes.CLEAR_SIGNER_STORE:
                clearStore();
                break;

            case Constants.ActionTypes.REMOVE_COPY_RECIPIENTS_V2:
                _signers = _signers.filter((s) => s.type === 'signer');
                break;

            // Force refresh validation after casefile types are loaded.
            case Constants.ActionTypes.CASEFILE_TYPES_LOADED:
            case Constants.ActionTypes.DOCUMENT_ADDED:
            case Constants.ActionTypes.DOCUMENT_ROLE_UPDATED:
            case Constants.ActionTypes.DOCUMENT_REMOVED:
            case Constants.ActionTypes.DOCUMENT_REMOVED_BY_INDEX:
            case Constants.ActionTypes.DOCUMENT_EDITED:
                _validation = updateValidation();
                break;
            default:
                return false;
        }

        _validation = updateValidation();

        // If action was responded to, emit change event
        SignerStore.emitChange();

        return true;
    }),
});

debug.export('SignerStore', SignerStore);
export default SignerStore;
