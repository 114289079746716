import AuthActions from './AuthActionCreators';
import { PublicAuthAPI } from 'Api';
import { ERRORS } from 'Core/Errors';

const presharedActionCreators = {
    authenticate(presharedToken) {
        let payload = {
            token: presharedToken,
        };

        PublicAuthAPI.post('/token/preshared?compatibility=false', payload)
            .then((response) => {
                return AuthActions.reauthenticate({ token: response.token });
            })
            .catch((error) => {
                AuthActions.handleAuthenticationError(
                    error,
                    ERRORS.AUTH_PRESHARED_TOKEN_INVALID
                );
            });
    },
};

export default presharedActionCreators;
