import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import ClickDropdown from 'Common/components/Common/ClickDropdown';
import { i18n, TranslationStore } from 'Language';
import Constants from 'Constants';
import { Platforms } from 'Constants';
import { getPlatform } from 'utils/misc';
import { Incident } from 'Common/components/StatusPageMessage/StatusPageMessage';
import { AuthProvider } from 'Auth/components/CredentialsManagement/AuthMethodsList';
import {
    AnimatedElement,
    AnimationType,
} from 'Common/components/Common/AnimatedElement';

type State = {
    userAuthMethods: AuthProvider[];
    platform?: Platforms;
};

type Props = {
    placement: string;
    offset: number;
    incidents: Incident[];
};

export default class StatusNotification extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    static propTypes = {
        placement: PropTypes.string,
        offset: PropTypes.number,
    };

    static defaultProps = {
        placement: 'bottom left',
        offset: -34,
    };

    state: State = {
        userAuthMethods: [],
    };

    async componentDidMount() {
        const platform = await getPlatform();

        this.setState({ platform });
    }

    renderLastUpdate = (updates: any[] = []) => {
        if (updates.length === 0) {
            return false;
        }

        let update = updates[0]; // most recent update.

        return (
            <div>
                <p style={{ whiteSpace: 'pre-line' }}>
                    {update.body}
                    &nbsp;
                    <span className="link">Read more</span>
                </p>
            </div>
        );
    };

    _setMomentLocale = () => {
        let languageCode = TranslationStore.getLanguage();

        if (languageCode === 'no') {
            languageCode = 'nb';
        }

        moment.locale(languageCode);
    };

    renderContentList() {
        const { incidents } = this.props;

        this._setMomentLocale();

        return (
            <>
                <div className="incident-list">
                    {incidents.length === 0 && (
                        <div className="text-center">
                            {i18n`All Penneo services are working as expected`}
                            &nbsp;
                            <i className="fa fa-check" />
                        </div>
                    )}
                    {(incidents ?? []).map((incident) => (
                        <a
                            href={incident.shortlink}
                            key={incident.id}
                            className={`incident no-underline`}
                            target="_blank"
                            rel="noopener noreferrer">
                            <span className={`label ${incident.status}`}>
                                {incident.status}
                            </span>
                            <span className="time">
                                {moment(incident.updated_at).fromNow()}
                            </span>
                            <h3>{incident.name}</h3>
                            {this.renderLastUpdate(incident.incident_updates)}
                        </a>
                    ))}

                    <div className="text-center">
                        <a
                            className="link-bottom"
                            href={Constants.sites.statusPage}
                            target="_blank"
                            rel="noopener noreferrer">
                            {i18n`Visit our status page to sign up for updates`}
                        </a>
                    </div>
                </div>
            </>
        );
    }

    // Return the highgest level incident state.
    getIncidentsState = (incidents: Incident[]) => {
        let statuses = incidents.map((i: Incident) => i.status);

        if (
            statuses.indexOf('investigating') >= 0 ||
            statuses.indexOf('identified') >= 0
        ) {
            return 'error';
        }

        if (statuses.indexOf('monitoring') >= 0) {
            return 'warning';
        }

        if (statuses.indexOf('resolved') >= 0) {
            return 'success';
        }

        return 'normal';
    };

    getNotificationIcon() {
        const { incidents } = this.props;
        const incidentState = this.getIncidentsState(incidents);
        const classes = ['far notification-icon', 'fa-bell'];

        const unread =
            (incidentState === 'error' || incidentState === 'warning') &&
            'unread';

        return (
            <div className="status-notification-content">
                <AnimatedElement
                    tag="i"
                    className={classNames([...classes, incidentState, unread])}
                    options={{
                        type: AnimationType.shake,
                        duration: 2,
                        times: unread ? 5 : 0,
                    }}
                />
                {unread && (
                    <AnimatedElement
                        tag="span"
                        className="notification-circle"
                        options={{
                            type: AnimationType.beat,
                            duration: 1.2,
                            easing: 'ease-in-out',
                        }}
                    />
                )}
            </div>
        );
    }

    render() {
        return (
            <div className="status-notification">
                <ClickDropdown
                    placement={this.props.placement}
                    offset={this.props.offset}
                    content={this.renderContentList()}>
                    {this.getNotificationIcon()}
                </ClickDropdown>
            </div>
        );
    }
}
