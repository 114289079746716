import keyMirror from 'keymirror';

// Add Prefix to actions
let addPrefix = (prefix, actions) => {
    for (let key in actions) {
        if (!actions.hasOwnProperty(key)) {
            continue;
        }

        actions[key] = `${prefix}_${actions[key]}`;
    }

    return actions;
};

// Create action list
let createActions = (prefix = '', actionsArray = []) => {
    let _actions = {};

    actionsArray.forEach((a) => (_actions[a] = null));

    return addPrefix(prefix, keyMirror(_actions));
};

export { createActions };
