import PropTypes from 'prop-types';
import React from 'react';
import HoverTooltip from '../../Forms/components/Common/HoverTooltip.jsx';
import UserAvatar from 'Common/components/UserAvatar';
import { i18n } from 'Language';
import Button from 'Common/components/Button';

export default class Team extends React.Component {
    static propTypes = {
        team: PropTypes.object,
        onEditButton: PropTypes.func,
        editButtonVisible: PropTypes.bool,
    };

    static defaultProps = {
        team: {
            members: [],
        },
    };

    renderTeam = (team) => {
        let { onEditButton, editButtonVisible } = this.props;

        return (
            <div>
                <div className="team-name">
                    {team.name}&nbsp;
                    <span className="text-small text-medium">
                        {team.description && `| ` + team.description}
                    </span>
                </div>
                <div className="member-count">
                    {team.members.length}&nbsp;
                    {team.members.length === 1 ? i18n`Member` : i18n`Members`}
                </div>

                {editButtonVisible && (
                    <Button
                        theme="blue"
                        variant="text"
                        className="pull-right"
                        icon="far fa-edit"
                        onClick={onEditButton.bind(null, team)}
                        renderIconLeft={true}>
                        {i18n`Edit`}
                    </Button>
                )}

                {this.renderTeamPile(team.members, 5)}
            </div>
        );
    };

    renderTeamPile = (members, pileSize) => {
        if (typeof members !== 'undefined' && members.length < 0) {
            return null;
        }

        let membersToDisplay = members.slice(0, pileSize);
        let membersLeftover = members.slice(pileSize, members.length + 1);

        return (
            <div className="member-group">
                {membersToDisplay.map((member, index) => (
                    <div
                        className="member"
                        key={index}
                        style={{ zIndex: membersToDisplay.length - index }}>
                        <HoverTooltip text={member.fullName}>
                            <UserAvatar user={member} />
                        </HoverTooltip>
                    </div>
                ))}

                {membersLeftover.length > 0 && (
                    <div className="more-members">
                        + {membersLeftover.length}
                    </div>
                )}
            </div>
        );
    };

    render() {
        let { team } = this.props;

        return (
            <div className="team">
                {typeof team.parentId === 'undefined' && (
                    <div
                        className={`team-item team-parent ${
                            team.filtered && 'highlight'
                        }`}>
                        {this.renderTeam(team)}
                    </div>
                )}

                {!!team.children.length &&
                    team.children.map((child, index) => (
                        <div
                            className={`team-item team-child ${
                                child.filtered && 'highlight'
                            }`}
                            key={index}>
                            {this.renderTeam(child)}
                        </div>
                    ))}
            </div>
        );
    }
}
