import CryptoJS from 'crypto-js';

type FileHash = string;

function getFileHash(file: Blob): Promise<FileHash> {
    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.readyState === FileReader.DONE) {
                const wordArray = CryptoJS.lib.WordArray.create(reader.result);
                const hash = CryptoJS.SHA256(wordArray);

                resolve(hash.toString());
            }
        };

        reader.readAsArrayBuffer(file);
    });
}

export { getFileHash };
