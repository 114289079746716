import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import './submit-kyc-page.scss';
import bytes from 'bytes';
import analytics from 'Common/Analytics';

import { SSNs } from 'Constants';
import { MAX_ATTACHMENT_SIZE } from 'KYC/Constants';

import { aggregateSSN, isSSNValid, splitAggregatedSSN } from 'utils';

// Redux
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import {
    updateData,
    validateData,
    updateAttachments,
    submitKYC,
    fetchKYCData,
    resetState,
    clearError,
    clearSendingState,
} from 'KYC/redux/actions';
import { KYCInstance, DataFields } from 'KYC/redux/types';

// Components
import { i18n, TranslationActions, TranslationStore } from 'Language';
import { TextInput } from 'Common/components';
import FullScreenError from 'Common/components/FullScreenError';
import ImageUploader from 'Common/components/ImageUploader';
import Loader from 'Common/components/Common/Loader';
import KYCSendingState from 'KYC/components/KYCSendingState';
import Button from 'Common/components/Button';
import WidgetBody from 'Common/components/WidgetBody';
import Widget from 'Common/components/Widget';
import SsnInput from 'Common/components/SsnInput';
import InputValidation from 'Common/components/InputValidation';

export type Props = {
    dispatch: AppDispatch;
    location: any;
    params: {
        kycToken: string;
        formToken: string;
        workflowToken: string;
    };
    kyc: KYCInstance;
    isLoaded: boolean;
    isFetching: boolean;
    isSending: boolean;
    error: boolean;
};

export class KYCVerification extends React.Component<Props> {
    static contextTypes = {
        router: PropTypes.object,
    };

    componentDidMount() {
        TranslationStore.addChangeListener(this.onLanguageChange);

        const { dispatch } = this.props;

        dispatch(resetState());
        this.loadData();
    }

    /**
     * When the flux store updates, it doesn't cause the page to re-render.
     * We need to force React to re-render the page so that i18n use the language from the KYC object.
     */
    onLanguageChange = () => {
        this.forceUpdate();
    };

    componentDidUpdate(prevProps) {
        if (this.props.isLoaded && !prevProps.isLoaded) {
            TranslationActions.setLanguage(this.props.kyc.language, {
                persist: false,
            });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        TranslationStore.removeChangeListener(this.onLanguageChange);

        dispatch(resetState());
    }

    loadData() {
        const { dispatch, params } = this.props;
        const { kycToken } = params;

        dispatch(fetchKYCData(kycToken));
    }

    handleInputChange(name: DataFields, value: string) {
        const { dispatch } = this.props;

        dispatch(updateData(name, value));
    }

    handleAttachment(name: DataFields, attachment: File | null) {
        const { dispatch } = this.props;

        dispatch(updateAttachments(name, attachment));
    }

    onSubmit = () => {
        const { dispatch, params } = this.props;
        const { kycToken } = params;

        dispatch(submitKYC(kycToken));
    };

    retry = () => {
        const { dispatch, params, isSending } = this.props;
        const { kycToken } = params;

        if (isSending) {
            dispatch(submitKYC(kycToken));
        } else {
            dispatch(fetchKYCData(kycToken));
        }

        dispatch(clearError());
    };

    back = () => {
        const { dispatch, isSending } = this.props;

        if (isSending) {
            dispatch(clearError());
            dispatch(clearSendingState());
        } else {
            dispatch(clearError());
        }
    };

    renderSSNInputField() {
        const { dispatch, kyc } = this.props;
        const { requirements, data } = kyc;

        const splitValue = splitAggregatedSSN(data.ssn.value);

        const InputField = (
            <SsnInput
                value={splitValue?.ssn}
                ssnType={splitValue?.typeId}
                required={requirements.ssn.required}
                label={i18n`National identification number`}
                placeholder={i18n`Type SSN`}
                onChange={({ ssnType, value }) => {
                    const newValue = aggregateSSN(value, ssnType);

                    this.handleInputChange(DataFields.SSN, newValue);
                }}
            />
        );

        const rules = [
            {
                error: {
                    message: i18n`Enter a valid national identification number`,
                },
                test: () => !!requirements.ssn.required && !splitValue?.ssn,
            },
            {
                error: { message: i18n`Please select a country` },
                test: () =>
                    !!splitValue?.ssn &&
                    splitValue?.typeId === SSNs.LEGACY_SSN.id,
            },
            {
                error: {
                    message: i18n`Enter a valid national identification number`,
                },
                test: () =>
                    !!splitValue?.ssn &&
                    !isSSNValid(splitValue?.ssn, splitValue?.typeId),
            },
        ];

        return (
            <div className="input-field">
                <div className="input">
                    <InputValidation
                        triggers={data.ssn.value}
                        rules={rules}
                        onValidation={(isValid) => {
                            dispatch(validateData(DataFields.SSN, isValid));
                        }}>
                        {InputField}
                    </InputValidation>
                </div>
            </div>
        );
    }

    renderInputFields = () => {
        const { requirements, data } = this.props.kyc;

        return (
            <div className="form">
                {requirements.ssn.enabled && this.renderSSNInputField()}

                {requirements.address.enabled && (
                    <div className="input-field">
                        <TextInput
                            label={i18n`Address`}
                            value={data.address.value}
                            required={requirements.address.required}
                            onChange={(value: string) =>
                                this.handleInputChange(
                                    DataFields.ADDRESS,
                                    value
                                )
                            }
                        />
                    </div>
                )}

                {requirements.phone.enabled && (
                    <div className="input-field">
                        <TextInput
                            label={i18n`Phone number`}
                            value={data.phone.value}
                            required={requirements.phone.required}
                            onChange={(value: string) =>
                                this.handleInputChange(DataFields.PHONE, value)
                            }
                        />
                    </div>
                )}

                {this.renderDocsUploadSection()}
            </div>
        );
    };

    /**
     * Renders a "documents upload" section with a file upload field for
     * each document-related field that is enabled for the current KYC request
     *
     * If none of them are enabled, it renders nothing
     *
     * @return {JSX.Element | null}
     */
    renderDocsUploadSection = (): JSX.Element | null => {
        const { requirements, attachments } = this.props.kyc;
        const DOC_FIELDS = [
            DataFields.PASSPORT,
            DataFields.DRIVER_LICENSE,
            DataFields.SSN_CARD,
        ];

        const uploadFields = Object.keys(requirements)
            .map((field: string) => field as DataFields)
            .filter(
                (field: DataFields) =>
                    DOC_FIELDS.includes(field) && requirements[field].enabled
            )
            .map((field: DataFields) => (
                <div key={field} className="files-upload-field">
                    <ImageUploader
                        type={field}
                        i18n={i18n}
                        file={attachments[field].value}
                        required={requirements[field].required}
                        maxSize={bytes(MAX_ATTACHMENT_SIZE)}
                        onChange={(value: File) =>
                            this.handleAttachment(field, value)
                        }
                    />
                </div>
            ));

        return uploadFields.length ? (
            <>
                <div className="files-upload">
                    <div className="files-upload-header">
                        <h4 className="mb0">
                            {i18n`Please upload a visible picture of the following documents`}
                        </h4>
                        <p className="mt0 text-small">
                            <i className="far fa-info-circle"></i>{' '}
                            {i18n`All image formats are supported`}
                        </p>
                    </div>
                </div>

                {uploadFields}
            </>
        ) : null;
    };

    renderDescription = () => {
        const { description, recipient, sender } = this.props.kyc;

        if (description) {
            return description;
        }

        if (!description && recipient && sender) {
            return i18n`Hello ${recipient.name}, ${sender.name} at ${sender.company} has requested you to verify your identity. Please follow the steps below.`;
        }
    };

    isFormInvalid = () => {
        const { requirements, data, attachments } = this.props.kyc;

        for (let key in data) {
            if (requirements[key].required && data[key].value === '') {
                return true;
            }

            if (data[key].isValid === false) {
                return true;
            }
        }

        for (let key in attachments) {
            if (requirements[key].required && !attachments[key].value) {
                return true;
            }
        }

        return false;
    };

    render() {
        const { kyc, isFetching, isLoaded, isSending, error } = this.props;

        if (isSending || error) {
            return <KYCSendingState onRetry={this.retry} onBack={this.back} />;
        }

        if (!isLoaded || isFetching) {
            return <Loader />;
        }

        if (!!kyc.filledAt) {
            const error = i18n`The link is no longer valid`;
            const description = i18n`It can be due to the process not being fully completed the first time. Please request a new link.`;

            analytics.track('kyc - link already used', {
                id: kyc.id,
            });

            return <FullScreenError title={error} description={description} />;
        }

        return (
            <>
                <Helmet>
                    <title>{kyc.title}</title>
                </Helmet>
                <Widget className="submit-kyc-page-container">
                    <WidgetBody className="submit-kyc-page-content">
                        <h1 className="submit-kyc-page-title">{kyc.title}</h1>

                        <p className="preserve-newlines">
                            {this.renderDescription()}
                        </p>

                        {this.renderInputFields()}

                        <div className="mt">
                            <Button
                                theme="green"
                                onClick={this.onSubmit}
                                icon="far fa-arrow-right"
                                disabled={this.isFormInvalid()}>
                                {i18n`Validate with your electronic ID`}
                            </Button>
                        </div>
                    </WidgetBody>
                </Widget>
            </>
        );
    }
}

export default connect((state: ReduxState) => {
    const { isFetching, isSending, isLoaded, error } = state.kyc.__states;
    const kyc = state.kyc.instance;

    return {
        kyc,
        isLoaded,
        isFetching,
        isSending,
        error,
    };
})(KYCVerification);
