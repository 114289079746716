import daDK from 'rc-calendar/lib/locale/da_DK';
import daDK2 from '../time-picker/da_DK';
import assign from 'object-assign';

let locale = {
    lang: assign(
        {
            placeholder: 'Vælg tidspunkt',
            rangePlaceholder: ['Start date', 'End date'],
        },
        daDK
    ),
    timePickerLocale: assign({}, daDK2),
};

export default locale;
