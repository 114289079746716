import { Dispatcher, BaseStore } from 'Core';
import assign from 'object-assign';
import { BankIDNOActions as Actions } from '../ActionTypes';
import amplitude from 'Common/Amplitude';
import analytics from 'Common/Analytics';
import utils from '../utils';

let _default = {
    _fetching: false,
    status: null,
    sessionId: null,
    params: null,
    credentials: [],
    error: null,
};

// Initialize Store Data
let _store = {};

resetState(); // sets initial values

const analyticsProps = {
    method: 'Norway - BankID',
};

function updateState(state) {
    _store = assign(_store, state, { _fetching: false });
}

function resetState() {
    _store = assign(_store, _default);
}

function addCredentials(credentials) {
    let found = utils.find(_store.credentials, 'id', credentials.id);

    if (found) {
        found = credentials;

        return;
    }

    _store.credentials.push(credentials);
}

function updateCredentials(credentialList) {
    if (!credentialList) {
        return false;
    }

    updateState({ credentials: credentialList });
}

function deleteCredentials(credentialId) {
    let credentials = utils.remove(_store.credentials, 'id', credentialId);

    updateState({ credentials: credentials });
}

function updateParams(params) {
    updateState({ params: params });
}

function updateError(error) {
    updateState({ error: error });
}

const Store = assign({}, BaseStore, {
    getSessionId() {
        return _store.sessionId;
    },

    getStatus() {
        return _store.status;
    },

    getParams() {
        return _store.params;
    },

    getError() {
        return _store.error;
    },

    getCredentials() {
        return _store.credentials;
    },

    isFetching() {
        return _store._fetching;
    },

    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Actions.PARAMS_SUCCESS:
                analytics.track('sign widget loaded', analyticsProps);
                updateParams(action.params);
                break;
            case Actions.SIGN_SUCCESS:
                analytics.track('sign success', analyticsProps);
                amplitude.incrementUserProperty('casefiles signed');
                Store.emit(Actions.SIGN_SUCCESS);
                break;
            case Actions.ADD_SUCCESS:
                addCredentials(action.credentials);
                Store.emit('on-credential-added');
                break;
            case Actions.FETCH_SUCCESS:
                updateCredentials(action.credentials);
                break;
            case Actions.DELETE_SUCCESS:
                deleteCredentials(action.credentialId);
                break;
            case Actions.RESET_SUCCESS:
                Store.emit(Actions.RESET_SUCCESS);
                break;

            // Request Actions
            case Actions.PARAMS_REQUEST:
            case Actions.COLLECT_REQUEST:
            case Actions.LOGIN_REQUEST:
            case Actions.SIGN_REQUEST:
            case Actions.ADD_REQUEST:
            case Actions.FETCH_REQUEST:
            case Actions.RESET_REQUEST:
                _store._fetching = true;
                break;

            // Failure Actions
            case Actions.PARAMS_FAILURE:
            case Actions.COLLECT_FAILURE:
            case Actions.LOGIN_FAILURE:
            case Actions.ADD_FAILURE:
            case Actions.FETCH_FAILURE:
            case Actions.DELETE_FAILURE:
            case Actions.RESET_FAILURE:
                // _store._fetching = false;
                updateError(action.error);
                break;

            case Actions.SIGN_FAILURE:
                analytics.track('sign error', analyticsProps);
                updateError(action.error);
                break;

            // View Actions
            case Actions.RETRY:
                if (action.intent === 'sign') {
                    analytics.track('sign retry', analyticsProps);
                }

                updateError(null);
                break;
            case Actions.CLEAR_STORE:
                resetState();
                break;
            default:
                return false;
        }

        Store.emitChange(action.type);
    }),
});

export default Store;
