import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import RecipientRow from './RecipientRow.jsx';
import { modal } from 'Common/components/Common/Modal';
import CasefileActions from '../../actions/CasefilesActionCreators';
import Tooltip from 'Common/components/Tooltip';
import Button from 'Common/components/Button';

export default class CopyRecipients extends React.Component {
    static propTypes = {
        recipients: PropTypes.array,
        inputErrors: PropTypes.object,
        isCasefileSensitive: PropTypes.bool,
    };

    _addNewRecipientHandler = (event) => {
        event.preventDefault();

        let { isCasefileSensitive } = this.props;

        if (isCasefileSensitive) {
            return this.displayWarning();
        }

        CasefileActions.addRecipient();
    };

    displayWarning = () => {
        let config = {
            title: i18n('Error'),
            body: (
                <div>
                    <p>{i18n`You cannot add copy recipients to a case
                        file with sensitive data enabled.`}</p>
                    <p>
                        {i18n`If you need to add copy recipients,
                        disable sensitive data in the previous page`}
                    </p>
                </div>
            ),
            buttons: (
                <div>
                    <Button onClick={modal.hide}>{i18n('Close')}</Button>
                </div>
            ),
        };

        modal.show(config);
    };

    removeRecipientHandler = (index, event) => {
        event.preventDefault();
        CasefileActions.removeRecipient(index);
    };

    updateRecipientHandler = (index, event) => {
        let { value, name } = event.target;

        let recipient = {};

        recipient[name] = value;

        CasefileActions.updateRecipient(recipient, index);
    };

    render() {
        let { recipients, inputErrors } = this.props;

        let columns = [
            { title: 'Name', width: '50%' },
            { title: 'Email', width: '50%' },
            { title: '', width: 0 },
        ];

        return (
            <div className="casefile-copy-recipients">
                <div className="casefile-row-form">
                    <div className="fieldset">
                        <div className="summary-legend">
                            {i18n('Copy Recipients (optional)')}
                            &nbsp;
                            <Tooltip
                                direction="down"
                                showArrow={false}
                                text={i18n`A copy-recipient does not sign any documents in the
                                case file. The copy-recipient receives all of the signed documents
                                and appendixes automatically once they have been signed by
                                all parties.`}>
                                <i className="fa fa-info-circle"></i>
                            </Tooltip>
                        </div>
                        <div className="section-content">
                            <Button
                                theme="blue"
                                onClick={this._addNewRecipientHandler}
                                variant="outline">
                                {i18n('Add Copy Recipient')}
                            </Button>
                            <br />
                            <div className="copy-recipient-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {columns.map((column, index) => (
                                                <th
                                                    key={index}
                                                    width={column.width}>
                                                    {i18n(column.title)}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {recipients.map((recipient, index) => (
                                            <RecipientRow
                                                key={index}
                                                recipient={recipient}
                                                index={index}
                                                remove={
                                                    this.removeRecipientHandler
                                                }
                                                update={
                                                    this.updateRecipientHandler
                                                }
                                                inputErrors={inputErrors}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
