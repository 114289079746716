import React from 'react';
import { CaseFileItem } from '../../';
import { TransformToNestedChildren } from './transform-to-nested-children';
import { i18n } from 'Language';
import './tree-selection.scss';

type Props = {
    dataSource: TransformToNestedChildren[];
    onSelectFolder: (item: any) => any;
    selectedFolderId?: number | null;
    childClassName?: string;
};

type State = {
    displayChildren: any;
};

class TreeSelection extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            displayChildren: {},
        };
    }

    handleFolderOnClick = (item) => () => {
        this.props.onSelectFolder(item);
    };

    toggleChildrenFolder = (item: CaseFileItem) => () => {
        this.setState({
            displayChildren: {
                ...this.state.displayChildren,
                [item.id]: !this.state.displayChildren[item.id],
            },
        });
    };

    renderFolderSubTree = (folder) => {
        const { onSelectFolder, selectedFolderId } = this.props;
        let { displayChildren } = this.state;

        if (
            folder.children &&
            folder.children.length > 0 &&
            displayChildren[folder.id]
        ) {
            return (
                <TreeSelection
                    dataSource={folder.children}
                    onSelectFolder={onSelectFolder}
                    selectedFolderId={selectedFolderId}
                    childClassName={'child'}
                />
            );
        }
    };

    render() {
        const { childClassName, selectedFolderId, dataSource } = this.props;

        return dataSource.map((folder) => {
            let isSelected = selectedFolderId === folder.id;
            let hasChildren = folder.children;
            let subfoldersCount = folder.children ? folder.children.length : 0;

            return (
                <div
                    key={folder.id}
                    className={`tree-selection ${
                        childClassName && childClassName
                    }`}>
                    <div className={`tree-item ${isSelected && 'selected'}`}>
                        <div
                            className="icon-button"
                            onClick={this.toggleChildrenFolder(folder)}>
                            {hasChildren && (
                                <i
                                    className={`far ${
                                        this.state.displayChildren[folder.id]
                                            ? 'fa-angle-right'
                                            : 'fa-angle-down'
                                    }`}
                                />
                            )}
                            <i
                                className={`far ${
                                    this.state.displayChildren[folder.id]
                                        ? 'fa-folder-open'
                                        : 'fa-folder'
                                }`}
                            />
                        </div>

                        <div
                            className="title-button"
                            onClick={this.handleFolderOnClick(folder)}>
                            {folder.title}
                            {hasChildren && (
                                <span>
                                    (+{subfoldersCount}&nbsp;
                                    {subfoldersCount > 1
                                        ? i18n('folders')
                                        : i18n('folder')}
                                    )
                                </span>
                            )}
                        </div>
                    </div>

                    {hasChildren && this.renderFolderSubTree(folder)}
                </div>
            );
        });
    }
}

export default TreeSelection;
