import Button from 'Common/components/Button';
import { i18n } from 'Language/i18n';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { V2Validation } from 'Signing/utils';
import { dispatch, ReduxState } from 'Store';
import OTPValidationDisplay from './OTPValidationDisplay';
import { sendSMS, setError, validateOTP } from './redux/reducer';
import { SMSError } from './types';
import analytics from '../../../Common/Analytics';

export default function AuthenticationCodeForm() {
    const otpLength = 6;

    const { challengeKey } = useSelector(
        (state: ReduxState) => state?.smsValidation
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [codeWasResent, setCodeWasResent] = useState<boolean>(false);

    const [otp, setOTP] = useState<string[]>(new Array(otpLength).fill(''));
    const [otpIsValid, setOtpIsValid] = useState<boolean | null>(null);

    const timeLeft = useRef<number>(60);
    const [secondsLeft, setSecondsLeft] = useState<number>(timeLeft.current);

    const otpInput = useRef({});

    const handleChange = (eventTarget, index: number) => {
        if (eventTarget.value.length === otpLength) {
            setOTP(eventTarget.value.split(''));

            return;
        }

        eventTarget.maxLength = 1;

        setOTP(otp.map((d, idx) => (idx === index ? eventTarget.value : d)));

        if (eventTarget.nextSibling) {
            eventTarget.nextSibling.focus();
        }
    };

    const handlePaste = (e: {
        preventDefault: () => void;
        clipboardData: { getData: (arg0: string) => string };
    }) => {
        e.preventDefault();
        const pastedData = e.clipboardData
            .getData('text/plain')
            .trim()
            .split('');

        setOTP([
            ...pastedData.slice(0, otpLength),
            ...Array(otpLength).fill(' ').slice(pastedData.length),
        ]);
    };

    const handleKeyDown = (event, index: number) => {
        setOtpIsValid(null);

        const eventTarget = event.currentTarget;

        if (event.key === 'Backspace') {
            if (!eventTarget.value && eventTarget.previousSibling) {
                event.preventDefault();
                eventTarget.previousSibling.focus();
            } else {
                const newOtp = [...otp];

                newOtp[index] = '';

                setOTP(newOtp);
            }
        }
    };

    const handleSendCodeAgain = () => {
        analytics.track('SMS - Click on "Send me the code"');

        if (!challengeKey) {
            return dispatch(
                setError({ message: 'Invalid challengeKey' } as SMSError &
                    Error)
            );
        }

        dispatch(sendSMS(challengeKey));

        // Restarting the countdown
        timeLeft.current = 60;
        setCodeWasResent(true);
    };

    const getOtpValue = () => {
        return otp.join('');
    };

    const clearOtp = () => {
        setOTP(new Array(otpLength).fill(''));

        if (otpInput.current[0]) {
            otpInput.current[0].focus();
        }
    };

    const getInputValidationClass = () => {
        if (otpIsValid === null) {
            return;
        }

        return otpIsValid ? 'border-green-600' : 'border-red-600';
    };

    const verifyOTP = async () => {
        if (otpIsValid !== null) {
            return;
        }

        setIsLoading(true);

        if (!challengeKey) {
            return dispatch(
                setError({ message: 'Invalid challengeKey' } as SMSError &
                    Error)
            );
        }

        try {
            const { validation_token } = await validateOTP(
                challengeKey,
                getOtpValue()
            );

            setIsLoading(false);
            setOtpIsValid(true);

            V2Validation.set(validation_token);

            window.location.href = `/signing/${challengeKey}`;
        } catch (error) {
            console.error(error);

            setIsLoading(false);
            setOtpIsValid(false);

            clearOtp();
        }
    };

    useEffect(() => {
        if (otpInput.current[0]) {
            otpInput.current[0].focus();
        }
    }, []);

    useEffect(() => {
        if (otp.every((value) => !!value)) {
            verifyOTP();
        }
    }, [otp]);

    useEffect(() => {
        const countdown = () => {
            if (timeLeft.current <= 0) {
                clearInterval(interval);
                setSecondsLeft(0);

                return;
            }

            setSecondsLeft(timeLeft.current);
            timeLeft.current--;
        };

        countdown();

        const interval = setInterval(countdown, 1000);

        return () => clearInterval(interval);
    }, [codeWasResent]);

    useEffect(() => {
        if (otpIsValid === null) {
            return;
        }

        analytics.track('SMS - Code entered', {
            validationStatus: otpIsValid ? 'valid' : 'invalid',
        });
    }, [otpIsValid]);

    return (
        <div data-testid="validate-authentication-code-form">
            <p className="text-neutral-900 mb-6">
                {i18n`A one-time code has been sent via SMS.`}{' '}
                {i18n`Type the code to view your document`}:
            </p>
            <div className="flex justify-between">
                {otp.map((data, index) => {
                    return (
                        <input
                            data-testid="otp-input"
                            key={index}
                            ref={(el) => (otpInput.current[index] = el)}
                            name="otp"
                            type="text"
                            inputMode="numeric"
                            maxLength={1}
                            className={`w-12 h-12 text-center ${getInputValidationClass()}`}
                            disabled={isLoading}
                            value={data}
                            onChange={(e) => handleChange(e.target, index)}
                            onBeforeInput={(e) => {
                                e.currentTarget.maxLength = otpLength;
                            }}
                            onPaste={handlePaste}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                    );
                })}
            </div>

            <div className="mt-6 text-center">
                {otpIsValid !== null && (
                    <OTPValidationDisplay isValid={otpIsValid} />
                )}

                <Button
                    className="min-w-[14rem]"
                    theme="blue"
                    disabled={secondsLeft > 0}
                    onClick={handleSendCodeAgain}>
                    {i18n`Send me the code again`}{' '}
                    {secondsLeft > 0 && `(${secondsLeft})`}
                </Button>
            </div>

            {/** This input is only for testing purposes. We have this to be able to test the value of `otp`, without having to move it in to redux */}
            <input data-testid="otp-value-input" value={getOtpValue()} hidden />
        </div>
    );
}
