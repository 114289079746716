import { Dispatcher } from 'Core';
import { SigningAPI } from 'Api';
import Constants, { DEFAULT_BOARD_COUNT_DESKTOP_APP } from '../Constants';
import lodash from 'lodash';
import assign from 'object-assign';
import moment from 'moment';
import Analytics from 'Common/Analytics';
import { SSNs, env } from 'Constants';

const casefilesActionCreators = {
    // *************************************************************************
    // PRIVATE FUNCTIONS
    // *************************************************************************
    _arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;

        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }

        return window.btoa(binary);
    },

    // FILE ENCODING
    _getBase64EncodedDocument(file) {
        if (!file) {
            return Promise.reject();
        }

        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            // FileReader callback
            reader.onload = () => {
                // Encode result
                let base64 = this._arrayBufferToBase64(reader.result);

                resolve(base64);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    },

    // *************************************************************************
    // GET REQUESTS
    // *************************************************************************

    async fetchCasefileTypes() {
        return (
            SigningAPI.get('/casefile/casefiletypes')
                // Replace the default board sign count as we don't longer want
                // to use the defaults set in backend
                .then((casefileTypes) => {
                    return setDefaultMinimumBoardCount(casefileTypes);
                })
                .then((transformedCasefileTypes) => {
                    this.setCasefileTypes(transformedCasefileTypes);
                })
        );
    },

    async fetchFolders(permissions = 'read-write') {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.FETCH_FOLDERS_REQUEST,
        });

        let query = {
            permissions: permissions,
        };

        return SigningAPI.get('/folders', query).then((folders) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.FETCH_FOLDERS_SUCCESS,
                folders: folders,
            });
        });
    },

    async fetchDefaultFolder() {
        return SigningAPI.get('/folders/default').then((folder) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.FETCH_DEFAULT_FOLDER_SUCCESS,
                folderId: folder.id,
            });
        });
    },

    fetchMessageTemplates() {
        return SigningAPI.get('/casefile/message/templates').then(
            (messageTemplates) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.FETCH_MESSAGE_TEMPLATES_SUCCESS,
                    messageTemplates: messageTemplates,
                });
            }
        );
    },

    fetchCasefileLog(casefileId) {
        return SigningAPI.get(`/casefiles/${casefileId}/history`).then(
            (response) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.FETCH_CASEFILE_LOG_SUCCESS,
                    casefileLog: response,
                });
            }
        );
    },

    // MIGHT NOT BE EVEN USED - CHECK - REMOVE IF NOT USED!!!!
    _fetchSigners(casefileId) {
        return SigningAPI.get(`/casefiles/${casefileId}/signers`).then(
            (signers) => {
                return signers;
            }
        );
    },

    _fetchSigningRequests(casefileId, signers) {
        let _promises = [];

        signers.forEach((signer) => {
            _promises.push(this._fetchSigningRequest(casefileId, signer.id));
        });

        return Promise.all(_promises).catch((error) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.CREATE_CASEFILE_FAILURE,
                error: error,
            });
        });
    },

    _fetchSigningRequest(casefileId, signerId) {
        return SigningAPI.get(
            `/casefiles/${casefileId}/signers/${signerId}/signingrequests`
        );
    },

    // *************************************************************************
    // POST REQUESTS
    // *************************************************************************
    createFolder(folderName) {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.CREATE_FOLDER_REQUEST,
        });

        let payload = {
            title: folderName,
        };

        let _folderId = null;

        SigningAPI.post('/folders', payload)
            .then((folder) => {
                _folderId = folder.id;

                return this.fetchFolders();
            })
            .then(() => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.CREATE_FOLDER_SUCCESS,
                });

                return this.changeFolder(_folderId);
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.CREATE_FOLDER_FAILURE,
                    error: error,
                });
            });
    },

    _createCasefile(data) {
        // If casefile metadata is not set, use the platform (desktop | web).
        if (!data.metaData) {
            data.metaData = env.platform;
        }

        return SigningAPI.post('/casefiles', data);
    },

    _linkFolder(casefileId, folderId) {
        return SigningAPI.post(`/folders/${folderId}/casefiles/${casefileId}`);
    },

    /**
     * @param caseFileId {int} Case file id
     * @param documents {array} Array of case file documents
     * @param source {string} Value forwarded only for analytics purposes
     */
    _addDocuments(caseFileId, documents, source) {
        const promises = documents.map((doc) =>
            this._addDocument(doc, caseFileId, source)
        );

        return Promise.all(promises);
    },

    /**
     * @param doc {Object} Case file document that is uploaded
     * @param casefileId {int} Case file id
     * @param source {string} Value only for analytics purposes, null by default
     */
    _addDocument(doc, casefileId, source = null) {
        let payload = {
            title: doc.name,
            caseFileId: casefileId,
            metaData: doc.metaData,
            documentOrder: doc.order,
            documentTypeId: doc.documentTypeId,
        };

        if (doc.opts) {
            payload.opts = doc.opts;
        }

        let _getBase64;

        if (doc.base64File) {
            _getBase64 = Promise.resolve(doc.base64File);
        } else {
            _getBase64 = this._getBase64EncodedDocument(doc.file);
        }

        return _getBase64
            .then((base64Document) => {
                payload.pdfFile = base64Document;

                const promise = SigningAPI.post('/documents', payload);

                // @todo: Since the promises are fulfilled in the _addDocuments function, we are unable to track the documentId for this event here, which could be useful to track.
                Analytics.track('casefile - document upload success', {
                    caseFileId: casefileId,
                    documentTypeId: doc.documentTypeId,
                });

                return promise;
            })
            .catch((error) => {
                let eventProperties = {
                    caseFileId: casefileId,
                    documentTypeId: doc.documentTypeId,
                    statusCode: error.status,
                };

                if (!!source) {
                    eventProperties.source = source;
                }

                Analytics.track(
                    'casefile - document upload error',
                    eventProperties
                );

                throw new this.CasefileException({
                    error: error,
                    payload: payload,
                    sourceType: 'DOCUMENT',
                });
            });
    },

    _addSigners(casefileId, signers) {
        const promises = signers.map((signer) =>
            this._addSigner(casefileId, signer)
        );

        return Promise.all(promises);
    },

    _addSigner(casefileId, signer) {
        let payload = {
            name: signer.name,
            socialSecurityNumberPlain: signer.ssn,
            ssnType: signer.ssnType ?? SSNs.LEGACY_SSN.id,
            vatin: signer.vatin,
            onBehalfOf: signer.onBehalfOf,
        };

        return SigningAPI.post(
            `/casefiles/${casefileId}/signers`,
            payload
        ).then((response) => {
            let _promises = signer.role
                .filter((role) => role.enabled)
                .map((role) => {
                    return this._linkSignerType(casefileId, response.id, role);
                });

            return Promise.all(_promises)
                .then(() => {
                    signer.id = response.id;

                    return signer;
                })
                .catch((error) => {
                    throw new this.CasefileException({
                        error: error,
                        payload: payload,
                        sourceType: 'SIGNER',
                    });
                });
        });
    },

    _linkSignerType(casefileId, signerId, signerType) {
        let payload = {};
        let endpoint = `/casefiles/${casefileId}/signers/${signerId}/signertypes/${signerType.id}`;

        // Set if signerType has a custom name
        if (signerType.name) {
            payload.role = signerType.name;
        }

        // @todo: add support for expiration/send dates

        return SigningAPI.post(endpoint, payload).catch((error) => {
            throw new this.CasefileException({
                error: error,
                payload: payload,
                sourceType: 'SIGNER_TYPE',
            });
        });
    },

    _addCopyRecipients(casefileId, recipients) {
        const promises = recipients.map((recipient) =>
            this._addCopyRecipient(casefileId, recipient)
        );

        return Promise.all(promises);
    },

    _addCopyRecipient(casefileId, recipient) {
        if (recipient.name === null && recipient.email === null) {
            return Promise.resolve();
        }

        return SigningAPI.post(
            `/casefiles/${casefileId}/recipients`,
            recipient
        ).catch((error) => {
            throw new this.CasefileException({
                error: error,
                sourceType: 'COPY_RECIPIENT',
            });
        });
    },

    // *************************************************************************
    // PUT REQUESTS
    // *************************************************************************

    setDefaultFolder(folderId) {
        return SigningAPI.put(`/folders/default/${folderId}`).then((folder) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.FETCH_DEFAULT_FOLDER_SUCCESS,
                folderId: folder.id,
            });
        });
    },

    _updateCasefile(casefile) {
        let updatedCasefile = assign({}, casefile);

        delete updatedCasefile.id;
        delete updatedCasefile.createdAt;

        return SigningAPI.put(`/casefiles/${casefile.id}`, updatedCasefile);
    },

    _updateFolder(casefileId, newFolderId, prevFolderId) {
        let promises = [];

        if (newFolderId === prevFolderId) {
            return Promise.resolve();
        }

        if (newFolderId) {
            promises.push(this._linkFolder(casefileId, newFolderId));
        }

        if (typeof prevFolderId !== 'undefined') {
            promises.push(this._unlinkFolder(casefileId, prevFolderId));
        }

        return Promise.all(promises);
    },

    _updateDocuments(caseFileId, documents, fetchedDocuments) {
        let documentsToDelete = lodash.differenceBy(
            fetchedDocuments,
            documents,
            'id'
        );

        let _promises = documents.map((doc) => {
            if (doc.id) {
                return this._updateDocument(doc);
            }

            return this._addDocument(doc, caseFileId);
        });

        _promises = _promises.concat(
            documentsToDelete.map((doc) => this._deleteDocument(doc.id))
        );

        return Promise.all(_promises);
    },

    _updateDocument(doc) {
        if (doc.status !== 0) {
            // @todo: handle this error
            throw Error(
                `The file can not be persisted, its status is: ${doc.status}`
            );
        }

        let payload = {
            title: doc.name,
        };

        if (doc.documentTypeId) {
            payload.documentTypeId = doc.documentTypeId;
        }

        if (doc.opts) {
            payload.opts = doc.opts;
        }

        if (doc.metaData) {
            payload.metaData = doc.metaData;
        }

        if (doc.options) {
            payload.options = doc.options;
        }

        if (doc.order) {
            payload.documentOrder = doc.order;
        }

        return SigningAPI.put(`/documents/${doc.id}`, payload).catch(
            (error) => {
                throw new this.CasefileException({
                    error: error,
                    payload: payload,
                    sourceType: 'DOCUMENT',
                });
            }
        );
    },

    _updateSigners(casefileId, signers, fetchedSigners) {
        let signersToDelete = lodash.differenceBy(
            fetchedSigners,
            signers,
            'id'
        );

        let _promises = signers.map((signer) => {
            if (signer.id) {
                return this._updateSigner(casefileId, signer, fetchedSigners);
            }

            return this._addSigner(casefileId, signer);
        });

        _promises = _promises.concat(
            signersToDelete.map((signer) =>
                this._deleteSigner(casefileId, signer.id)
            )
        );

        return Promise.all(_promises);
    },

    _updateSigner(casefileId, signer, fetchedSigners) {
        let payload = {
            name: signer.name,
            socialSecurityNumberPlain: signer.ssn,
            ssnType: signer.ssnType ?? SSNs.LEGACY_SSN.id,
            vatin: signer.vatin,
            onBehalfOf: signer.onBehalfOf,
        };

        // Signer roles that has been fetched from the database must be compared with
        // the current roles that are in the Store, it's due to determining which roles are new
        // and should be persisted and which should be deleted.
        let fetchedRoles = fetchedSigners.find((obj) => obj.id === signer.id)
            .roles;

        // Roles to persist are the ones that are not among the fetched ones
        // and have enabled set to true
        let rolesToPersist = signer.role.filter(
            (sr) => fetchedRoles.indexOf(sr.id) === -1 && sr.enabled
        );

        // Roles to delete are the ones that are among the fetched ones
        // but are not selected as signer's current roles
        let signerRoles = signer.role.map((role) => role.id);
        let rolesToDelete = fetchedRoles.filter((fr) => {
            if (signerRoles.indexOf(fr) === -1) {
                return fr;
            }

            return false;
        });

        // Update the signer
        return SigningAPI.put(
            `/casefiles/${casefileId}/signers/${signer.id}`,
            payload
        ).then((response) => {
            let _promises = rolesToPersist.map((role) => {
                return this._linkSignerType(casefileId, response.id, role);
            });

            _promises = _promises.concat(
                rolesToDelete.map((roleId) => {
                    return this._unlinkSignerType(
                        casefileId,
                        response.id,
                        roleId
                    );
                })
            );

            return Promise.all(_promises)
                .then(() => {
                    return signer;
                })
                .catch((error) => {
                    throw new this.CasefileException({
                        error: error,
                        payload: payload,
                        sourceType: 'SIGNER',
                    });
                });
        });
    },

    _updateCopyRecipients(casefileId, recipients, fetchedCopyRecipients) {
        let recipientsToDelete = lodash.differenceBy(
            fetchedCopyRecipients,
            recipients,
            'id'
        );

        let _promises = recipients.map((recipient) => {
            if (recipient.id) {
                return this._updateCopyRecipient(casefileId, recipient);
            }

            return this._addCopyRecipient(casefileId, recipient);
        });

        _promises = _promises.concat(
            recipientsToDelete.map((recipient) => {
                return this._deleteCopyRecipient(casefileId, recipient.id);
            })
        );

        return Promise.all(_promises);
    },

    _updateCopyRecipient(casefileId, recipient) {
        if (recipient.name === null && recipient.email === null) {
            return Promise.resolve();
        }

        const payload = {
            name: recipient.name,
            email: recipient.email,
        };

        return SigningAPI.put(
            `/casefiles/${casefileId}/recipients/${recipient.id}`,
            payload
        ).catch((error) => {
            throw new this.CasefileException({
                error: error,
                sourceType: 'COPY_RECIPIENT',
            });
        });
    },

    _editSigningRequests(signingRequests, messages, signers) {
        let _promises = [];
        let mergedSigningRequests = [].concat.apply([], signingRequests);

        signers.forEach((signer, index) => {
            _promises.push(
                this._editSigningRequest(
                    mergedSigningRequests[index].id,
                    messages,
                    signer
                )
            );
        });

        return Promise.all(_promises);
    },

    _editSigningRequest(signingRequestId, messages, signer) {
        let payload = {
            email: signer.email,
            reminderInterval: signer.reminderInterval,
            enableInsecureSigning: signer.enableInsecureSigning,
            accessControl: signer.accessControl,
            insecureSigningMethods: signer.insecureSigningMethods,
            ...messages,
        };

        return SigningAPI.put(`/signingrequests/${signingRequestId}`, payload);
    },

    // *************************************************************************
    // DELETE REQUESTS
    // *************************************************************************

    _unlinkFolder(casefileId, folderId) {
        return SigningAPI.delete(
            `/folders/${folderId}/casefiles/${casefileId}`
        );
    },

    _deleteDocument(documentId) {
        // We avoid calling the API if we don't have a valid doc id
        if (!documentId) {
            return;
        }

        return SigningAPI.delete(`/documents/${documentId}`).catch((error) => {
            throw new this.CasefileException({
                error: error,
                sourceType: 'DOCUMENT',
            });
        });
    },

    _deleteSigner(casefileId, signerId) {
        return SigningAPI.delete(
            `/casefiles/${casefileId}/signers/${signerId}`
        ).catch((error) => {
            throw new this.CasefileException({
                error: error,
                sourceType: 'SIGNER',
            });
        });
    },

    _unlinkSignerType(casefileId, signerId, signerTypeId) {
        let endpoint = `/casefiles/${casefileId}/signers/${signerId}/signertypes/${signerTypeId}`;

        return SigningAPI.delete(endpoint).catch((error) => {
            throw new this.CasefileException({
                error: error,
                sourceType: 'SIGNER_TYPE',
            });
        });
    },

    _deleteCopyRecipient(casefileId, recipientId) {
        return SigningAPI.delete(
            `/casefiles/${casefileId}/recipients/${recipientId}`
        ).catch((error) => {
            throw new this.CasefileException({
                error: error,
                sourceType: 'COPY_RECIPIENT',
            });
        });
    },

    // *************************************************************************
    // PATCH REQUESTS
    // *************************************************************************

    _sendSigningRequest(casefileId) {
        return SigningAPI.patch(`/casefiles/${casefileId}/send`);
    },

    // *************************************************************************
    // REQUEST CHAINING
    // *************************************************************************

    sendCasefile(data) {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.CREATE_CASEFILE_REQUEST,
        });

        this._setupCasefile(data)
            .then((response) => {
                let payload = {
                    signers: response.signers,
                    messages: data.messages,
                };

                return this._setupSigningRequests(
                    response.casefile.id,
                    payload
                ).then(() => {
                    return response;
                });
            })
            .then((response) => {
                return this._sendSigningRequest(response.casefile.id).then(
                    () => {
                        return response.casefile;
                    }
                );
            })
            .then((casefile) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.CREATE_CASEFILE_SUCCESS,
                    casefile: casefile,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.CREATE_CASEFILE_FAILURE,
                    error: error,
                });
            });
    },

    _setupCasefile(data) {
        return this._createCasefile(data.casefile).then((casefile) => {
            // Setup Casefile Information
            let promises = [
                this._addSigners(casefile.id, data.signers),
                this._addDocuments(casefile.id, data.documents, data.source),
                this._linkFolder(casefile.id, data.selectedFolderId),
                this._addCopyRecipients(casefile.id, data.recipients),
            ];

            return Promise.all(promises).then((response) => {
                return {
                    casefile: casefile,
                    signers: response[0],
                    documents: response[1],
                    folder: response[2],
                    ccRecipients: response[3],
                };
            });
        });
    },

    sendUpdatedCasefile(casefileData, fetchedCasefileData) {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.CREATE_CASEFILE_REQUEST,
        });

        this._setupUpdatedCasefile(casefileData, fetchedCasefileData)
            .then((response) => {
                let payload = {
                    signers: response.signers,
                    message: casefileData.message,
                };

                return this._setupSigningRequests(
                    response.casefile.id,
                    payload
                ).then(() => {
                    return response;
                });
            })
            .then((response) => {
                return this._sendSigningRequest(response.casefile.id).then(
                    () => {
                        return response.casefile;
                    }
                );
            })
            .then((casefile) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.CREATE_CASEFILE_SUCCESS,
                    casefile: casefile,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.CREATE_CASEFILE_FAILURE,
                    error: error,
                });
            });
    },

    _setupUpdatedCasefile(casefileData, fetchedCasefileData) {
        // Specifying 'send at' and 'expire at' properties are  not supported yet
        // so they're are being removed so the updated case file is send immediately.
        delete casefileData.casefile.expireAt;
        delete casefileData.casefile.sendAt;

        return this._updateCasefile(casefileData.casefile).then((casefile) => {
            // Setup Updated Casefile Information
            let promises = [
                this._updateSigners(
                    casefile.id,
                    casefileData.signers,
                    fetchedCasefileData.signers
                ),
                this._updateDocuments(
                    casefile.id,
                    casefileData.documents,
                    fetchedCasefileData.documents
                ),
                this._updateFolder(
                    casefile.id,
                    casefileData.selectedFolderId,
                    fetchedCasefileData.folderId
                ),
                this._updateCopyRecipients(
                    casefile.id,
                    casefileData.recipients,
                    fetchedCasefileData.copyRecipients
                ),
            ];

            return Promise.all(promises).then((response) => {
                return {
                    casefile: casefile,
                    signers: response[0].filter((signer) => signer), // filter out falsy values
                    documents: response[1].filter((doc) => doc), // filter out falsy values
                    folder: response[2],
                    ccRecipients: response[3],
                };
            });
        });
    },

    async updateCasefileDraft(casefileData, fetchedCasefileData) {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.SAVE_DRAFT_REQUEST,
        });

        try {
            const response = await this._setupUpdatedCasefile(
                casefileData,
                fetchedCasefileData
            );

            await this._setupSigningRequests(response.casefile.id, {
                signers: response.signers,
                message: casefileData.message,
            });

            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.SAVE_DRAFT_SUCCESS,
            });

            return Promise.resolve();
        } catch (error) {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.SAVE_DRAFT_FAILURE, // not registered in store yet
                error: error,
            });

            return Promise.reject();
        }
    },

    async createCasefileDraft(casefileData) {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.SAVE_DRAFT_REQUEST,
        });

        try {
            const response = await this._setupCasefile(casefileData);

            await this._setupSigningRequests(response.casefile.id, {
                signers: response.signers,
                message: casefileData.message,
            });

            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.SAVE_DRAFT_SUCCESS,
            });
        } catch (error) {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.SAVE_DRAFT_FAILURE, // not registered in store yet
                error: error,
            });
        }
    },

    _setupSigningRequests(casefileId, data) {
        if (data.signers.length < 0) {
            return;
        }

        return this._fetchSigningRequests(casefileId, data.signers).then(
            (signingRequests) => {
                return this._editSigningRequests(
                    signingRequests,
                    data.messages,
                    data.signers
                );
            }
        );
    },

    CasefileException(data) {
        let { error, payload, sourceType } = data;

        if (sourceType === 'SIGNER') {
            switch (error.status) {
                case 404:
                    return {
                        errorId: 'SIGNER_NOT_FOUND',
                        payload: payload,
                        reason: `Signer ${payload.name} not found`,
                        status: error.status,
                        statusText: error.statusText,
                    };
                default:
                    return {
                        errorId: 'ERROR_ADD_SIGNER_FAILURE',
                        payload: payload,
                        reason: `Could not add signer ${payload.name}`,
                        status: error.status,
                        statusText: error.statusText,
                    };
            }
        }

        if (sourceType === 'DOCUMENT') {
            switch (error.status) {
                case 413:
                    return {
                        errorId: 'ERROR_DOCUMENT_TOO_LARGE',
                        payload: payload,
                        reason: `Document ${payload.title} is too large`,
                        status: error.status,
                        statusText: error.statusText,
                    };
                case 404:
                    return {
                        errorId: 'ERROR_DOCUMENT_NOT_FOUND',
                        payload: payload,
                        reason: `Document not found`,
                        status: error.status,
                        statusText: error.statusText,
                    };
                default:
                    return {
                        errorId: 'ERROR_ADD_DOCUMENT_FAILURE',
                        reason: `Could not add document ${payload.title}`,
                        payload: payload,
                        status: error.status,
                        statusText: error.statusText,
                    };
            }
        }

        if (sourceType === 'SIGNER_TYPE') {
            return {
                errorId: 'LINK_SIGNER_TYPE',
                reason: 'Could not link signer type',
                payload: payload,
                status: error.status,
                statusText: error.statusText,
            };
        }

        if (sourceType === 'COPY_RECIPIENT') {
            return {
                errorId: 'ADD_COPY_RECIPIENT',
                reason: 'Could not add copy recipient',
                message: error.data.message,
                status: error.status,
                statusText: error.statusText,
            };
        }
    },

    // *************************************************************************
    // USER INPUT ACTIONS
    // *************************************************************************
    setCasefileTypes(casefileTypes) {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.FETCH_CASEFILE_TYPES_SUCCESS,
            casefileTypes: casefileTypes,
        });
    },

    setCaseTypeId(caseTypeId, resetDefaults = true) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CASEFILE_TYPE_CHANGED,
            caseTypeId: caseTypeId,
            resetDefaults: resetDefaults,
        });
    },

    setSelectedMessageTemplate(selectedMessageTemplate) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.MESSAGE_TEMPLATE_SELECTED,
            selectedMessageTemplate: selectedMessageTemplate,
        });
    },

    setSendAtDate(date) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SEND_AT_DATE_CHANGED,
            date: date,
        });
    },

    sendLaterToggle(isSendLater) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SEND_LATER_TOGGLE,
            isSendLater: isSendLater,
        });
    },

    clearSendDate() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SEND_DATE_CLEARED,
        });
    },

    setExpireAtDate(date) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.EXPIRE_AT_DATE_CHANGED,
            date: date,
        });
    },

    expireEnableToggle(isEnabled) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.ENABLE_EXPIRE_TOGGLE,
            isEnabled: isEnabled,
        });
    },

    clearExpireDate() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.EXPIRE_DATE_CLEARED,
        });
    },

    setRecipients(recipients) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.RECIPIENTS_CHANGED,
            recipients: recipients,
        });
    },

    addRecipient(recipient = {}) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.RECIPIENT_ADDED,
            recipient: recipient,
        });
    },

    removeRecipient(index) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.RECIPIENT_REMOVED,
            index: index,
        });
    },

    updateRecipient(recipient, index) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.RECIPIENT_UPDATED,
            recipient: recipient,
            index: index,
        });
    },

    updateCasefile(casefile) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CASEFILE_CHANGED,
            casefile: casefile,
        });
    },

    setSensitiveData(sensitiveData = false) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SENSITIVE_DATA_CHANGED,
            sensitiveData: sensitiveData,
        });
    },

    removeCopyRecipients() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.RECIPIENTS_REMOVED,
        });
    },

    changeFolder(selectedFolderId) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.FOLDER_CHANGED,
            selectedFolderId: selectedFolderId,
        });
    },

    setVisibilityMode(visibilityMode) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.VISIBILITY_MODE_CHANGED,
            visibilityMode: visibilityMode,
        });
    },

    validateInputs() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.VALIDATE_INPUTS,
        });
    },

    async clearStore() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CLEAR_CASEFILE_STORE,
        });
    },

    setSignOnMeeting(signOnMeeting) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SIGN_AT_MEETING,
            data: signOnMeeting,
        });
    },

    setDataSource(source) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SET_DATA_SOURCE,
            data: source,
        });
    },

    // *************************************************************************
    // URI loaded casefile data
    // *************************************************************************
    async URIUpdateCasefileStore(data) {
        if (data.templateId) {
            this.setCaseTypeId(data.templateId, false); // Force refresh of document types
        }

        // new Case file object state
        let state = {
            id: data.id,
            title: data.name,
            language: data.language,
            metaData: data.metaData,
            reference: data.reference,
            sendAt: data.sendAt,
            expireAt: data.expireAt,
            createdAt: data.created,
            completedAt: data.completed,
            signOnMeeting: data.signOnMeeting,
        };

        // Remove undefined values
        Object.keys(state).forEach(
            (key) => state[key] === undefined && delete state[key]
        );

        // Update Case File Data
        if (!lodash.isEmpty(state)) {
            this.updateCasefile(state);
        }

        if (!!data.source) {
            this.setDataSource(data.source);
        }

        // Allow setting sensitive date to 'false/true'
        if (typeof data.sensitiveData !== 'undefined') {
            this.setSensitiveData(!!data.sensitiveData);
        }

        // Update folder
        if (data.folderId) {
            this.changeFolder(data.folderId);
        }

        // Update visibility mode
        if (data.visibilityMode) {
            lodash.forEach(Constants.VISIBILITY_MODES, (value, key) => {
                if (value === data.visibilityMode) {
                    this.setVisibilityMode(key);
                }
            });
        }

        // Add casefile recipients
        if (data.copyRecipients) {
            let _recipients = data.copyRecipients.map((recipient) => {
                return lodash.omitBy(
                    {
                        // omitBy function removes undefined values
                        id: recipient.id,
                        name: recipient.name,
                        email: recipient.email,
                        type: recipient.type,
                    },
                    lodash.isUndefined
                );
            });

            this.setRecipients(_recipients);
        }

        // Update date of sending the casefile
        if (data.sendAt) {
            this.setSendAtDate(moment(data.sendAt));
        }

        // Update date of expiring the casefile
        if (data.expireAt) {
            this.setExpireAtDate(moment(data.expireAt));
        }
    },

    clearError() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CASEFILE_CLEAR_ERROR,
        });
    },
};

const setDefaultMinimumBoardCount = (caseFileTypes) => {
    return caseFileTypes.map((caseFileType) => {
        const transformedDocumentTypes = caseFileType.documentTypes.map(
            (documentType) => {
                if (documentType?.opts?.[0]?.name === 'boardSignCount') {
                    documentType.opts[0].value = DEFAULT_BOARD_COUNT_DESKTOP_APP;
                }

                return documentType;
            }
        );

        return { ...caseFileType, documentTypes: transformedDocumentTypes };
    });
};

export default casefilesActionCreators;
