import { AuthAPI, PublicAuthAPI, PublicSigningAPI } from 'Api';
import { PublicSigningAPIWithAuthHeader } from 'Api/ApiClient';
import utils from 'Auth/utils';
import analytics from 'Common/Analytics';
import { Dispatcher } from 'Core';
import { signingRedirectUrl } from 'Signing/utils';
import { BankIDNOActions as Actions } from '../ActionTypes';
import AuthActions from './AuthActionCreators';

const BankIDNOActions = {
    initLogin(language = 'en') {
        Dispatcher.handleServerAction({
            type: Actions.PARAMS_REQUEST,
        });

        let payload = {
            language: language,
        };

        PublicAuthAPI.post('/bankidno/init', payload)
            .then((params) => {
                Dispatcher.handleServerAction({
                    type: Actions.PARAMS_SUCCESS,
                    params: params,
                });
            })
            .catch((error) => {
                this._dispatchError(error, Actions.PARAMS_FAILURE);
            });
    },

    initSign(challengeKey, language = 'en') {
        Dispatcher.handleServerAction({
            type: Actions.PARAMS_REQUEST,
        });

        let payload = {
            challengeKey: challengeKey,
            language: language,
        };
        let _params: any = {};

        PublicSigningAPI.post('/sign/bankidno/init', payload)
            .then((params) => {
                _params = params;

                return _params.sessionId;
            })
            .then((sessionId) => {
                console.log(_params, sessionId);

                return PublicAuthAPI.post('/bankidno/collect', {
                    sessionId: sessionId,
                });
            })
            .then((signatureRef) => {
                _params.signatureRef = signatureRef;
                Dispatcher.handleServerAction({
                    type: Actions.PARAMS_SUCCESS,
                    params: _params,
                });
            })
            .catch((error) => {
                this._dispatchError(error, Actions.PARAMS_FAILURE);
            });
    },

    login(signatureRef) {
        let payload = {
            signatureRef: signatureRef,
        };

        Dispatcher.handleServerAction({
            type: Actions.LOGIN_REQUEST,
        });

        const options = {
            onSuccess() {
                analytics.track('log in', { method: 'Norway - BankID' });
            },
            onError() {
                analytics.track('log in error', { method: 'Norway - BankID' });
            },
        };

        return AuthActions.authenticate('/token/bankidno', payload, options);
    },

    async getSignature(signatureRef) {
        try {
            const payload = {
                signatureRef: signatureRef,
            };

            const { token, signature } = await PublicAuthAPI.post(
                '/bankidno/signatures',
                payload
            );

            return {
                token: token,
                signature: signature,
            };
        } catch (error) {
            analytics.track('sign error', { method: 'Norway - BankID' });
            this._dispatchError(error, Actions.SIGN_FAILURE);
        }
    },

    /**
     * Posts signature to server to finish signing process.
     * @param  {object}   signature   Signature to be posted to server
     * @param  {boolean}  disposable  User choice to store documents in a Penneo account or let them expire
     * @param  {number}   userId      User ID to store documents if user has chosen permanent storage
     * @return {Promise}              Signature post status
     */
    sign(signature, disposable = false, userId = null) {
        Dispatcher.handleServerAction({
            type: Actions.SIGN_REQUEST,
        });

        const payload = {
            ...signature,
            userId,
            disposable,
        };

        // Post signature to signing service.
        return PublicSigningAPIWithAuthHeader.post(
            '/sign/bankidno/signatures',
            payload
        )
            .then(async (res) => {
                analytics.track('sign success', { method: 'Norway - BankID' });
                analytics.amplitude.incrementUserProperty('casefiles signed');

                Dispatcher.handleServerAction({
                    type: Actions.SIGN_SUCCESS,
                    signature: signature,
                });

                const url = await signingRedirectUrl();

                window.location.href = res.successUrl || url;
            })
            .catch((error) => {
                analytics.track('sign error', { method: 'Norway - BankID' });
                this._dispatchError(error, Actions.SIGN_FAILURE);
            });
    },

    // Credential Management

    addCredentials(signatureRef) {
        let payload = {
            signatureRef: signatureRef,
        };

        Dispatcher.handleServerAction({
            type: Actions.ADD_REQUEST,
        });

        AuthAPI.post('/cred/bankidno', payload)
            .then((response) => {
                Dispatcher.handleServerAction({
                    type: Actions.ADD_SUCCESS,
                    credentials: response,
                });

                // Add to the AuthMethods Store
                Dispatcher.handleServerAction({
                    type: 'AUTH_METHODS_CREATE_SUCCESS',
                    credential: response,
                });
            })
            .catch((error) => {
                this._dispatchError(error, Actions.ADD_FAILURE);
            });
    },

    fetchCredentials() {
        Dispatcher.handleServerAction({
            type: Actions.FETCH_REQUEST,
        });

        AuthAPI.get('cred/bankidno')
            .then((credentials) => {
                Dispatcher.handleServerAction({
                    type: Actions.FETCH_SUCCESS,
                    credentials: credentials,
                });
            })
            .catch((error) => {
                this._dispatchError(error, Actions.FETCH_FAILURE);
            });
    },

    // Delete Credentials
    deleteCredential(id) {
        AuthAPI.delete(`cred/bankidno/${id}`)
            .then(() => {
                Dispatcher.handleServerAction({
                    type: Actions.DELETE_SUCCESS,
                    id: id,
                });
            })
            .catch((error) => {
                this._dispatchError(error, Actions.DELETE_FAILURE);
            });
    },

    // Reset Credentials
    resetCredentials(userId) {
        let payload = {
            userId: userId,
            type: 'bankid_no',
        };

        Dispatcher.handleServerAction({
            type: Actions.RESET_REQUEST,
        });

        AuthAPI.post('/cred/requests', payload)
            .then((request) => {
                Dispatcher.handleServerAction({
                    type: Actions.RESET_SUCCESS,
                    request: request,
                });
            })
            .catch((error) => {
                this._dispatchError(error, Actions.RESET_FAILURE);
            });
    },

    // Handle all dispatched errors
    _dispatchError(error, action) {
        switch (action) {
            case Actions.PARAMS_FAILURE:
            case Actions.COLLECT_FAILURE:
            case Actions.LOGIN_FAILURE:
            case Actions.SIGN_FAILURE:
            case Actions.ADD_FAILURE:
            case Actions.FETCH_FAILURE:
            case Actions.DELETE_FAILURE:
            case Actions.RESET_FAILURE:
            default:
                Dispatcher.handleServerAction({
                    type: action,
                    error: {
                        status: error.status,
                        statusText: error.statusText,
                        data: error.data,
                    },
                });
                break;
        }
    },

    async validate(signatureRef) {
        const analyticsProps = { method: 'Norway - BankID' };

        await utils.validateId(signatureRef, 'bankidno', analyticsProps);
    },
};

export default BankIDNOActions;
