import { i18n } from 'Language';
import { validationRules as rules } from 'Constants';
import { isSSNValid } from 'utils';

// Extract rules from Constants
let emailRegex = rules.email[0].rule;
let emailErrorMessage = rules.email[0].message;

const inputValidation = {
    // FORM VALIDATION
    casefileFormValidation(casefile) {
        let errors = {};

        if (casefile) {
            if (typeof casefile.caseFileTypeId === 'undefined') {
                let message = i18n`Select case file type`;

                errors.caseFileTypeId = message;
            }

            if (!casefile.title || casefile.title.length < 3) {
                let message = i18n`Enter case file name`;

                errors.title = message;
            }
        }

        return errors;
    },

    _documentLimitValidator(documents, casefile, casefileTypes) {
        let errors = {};
        let casefileType;

        // FIND CASEFILE
        for (let i = 0; i < casefileTypes.length; i++) {
            if (casefileTypes[i].id === casefile.caseFileTypeId) {
                casefileType = casefileTypes[i];
                break;
            }
        }

        // FIND DOCUMENT
        if (casefileType) {
            casefileType.documentTypes.forEach((checkedDocument) => {
                let documentCount = 0;
                let lowerLimit = checkedDocument.lowerLimit;
                let upperLimit = checkedDocument.upperLimit;

                documents.forEach((selectedDocument) => {
                    if (
                        checkedDocument.id === selectedDocument.documentTypeId
                    ) {
                        documentCount++;
                    }
                });

                if (documentCount < lowerLimit && lowerLimit !== 0) {
                    let message = i18n`Upload at least ${lowerLimit} document(s) of
                                       type ${checkedDocument.name}`;

                    errors.document = message;
                }

                if (documentCount > upperLimit && upperLimit !== 0) {
                    let message = `You can only upload a maximum of ${upperLimit}
                                   documents of type "${checkedDocument.name}"`;

                    errors.document = message;
                }
            });
        } else if (typeof casefileType === 'undefined') {
            let message = i18n`Select document type`;

            errors.document = message;
        }

        return errors;
    },

    _documentFormValidation(documents, casefile, casefileTypes) {
        let errors = {};

        if (typeof document !== 'undefined') {
            errors = this._documentLimitValidator(
                documents,
                casefile,
                casefileTypes
            );
        }

        if (documents.length < 1) {
            let message = i18n`Upload a document`;

            errors.document = message;
        }

        return errors;
    },

    _ssnValidator(receivedSsn, errors, index) {
        // Skip validation if the SSN field is empty.
        if (!receivedSsn || receivedSsn.length === 0) {
            return true;
        }

        if (!isSSNValid(receivedSsn)) {
            errors['ssn' + index] = 'Enter a valid SSN';

            return false;
        }

        return true;
    },

    _signerFormValidation(signer, index, errors, casefile) {
        let {
            name,
            ssn,
            vatin,
            onBehalfOf,
            reminderInterval,
            email,
            role,
        } = signer;
        let digitReg = /^$|^\d+$/;

        if (!name || name.length < 2) {
            let message = 'Enter a name';

            errors['name' + index] = message;
        }

        if (ssn && ssn.length > 0) {
            this._ssnValidator(ssn, errors, index, casefile);
        }

        if (
            typeof vatin !== 'undefined' &&
            vatin !== null &&
            ((vatin.length >= 1 && vatin.length !== 8) || !digitReg.test(vatin))
        ) {
            let message = 'Vatin must be 8 digits';

            errors['vatin' + index] = message;
        }

        if (!email || !emailRegex.test(email)) {
            errors['email' + index] = emailErrorMessage;
        }

        if (
            typeof reminderInterval !== 'undefined' &&
            reminderInterval !== null &&
            !digitReg.test(reminderInterval)
        ) {
            let message = 'Enter a digit';

            errors['reminderInterval' + index] = message;
        }

        if (
            typeof role === 'undefined' ||
            !role ||
            role.length < 1 ||
            role.every((r) => !r.enabled)
        ) {
            let message = 'Select a signer role';

            errors['role' + index] = message;
        }

        if (
            typeof onBehalfOf !== 'undefined' &&
            onBehalfOf !== null &&
            onBehalfOf.length >= 1 &&
            onBehalfOf.length < 2
        ) {
            let message = 'Enter a name';

            errors['onBehalfOf' + index] = message;
        }

        return errors;
    },

    _signerFieldsValidation(signer, index, errors, editedValue, casefile) {
        let { name, ssn, vatin, onBehalfOf, reminderInterval, email } = signer;
        let digitReg = /^$|^\d+$/;

        if (editedValue === 'name') {
            if (name && name.length < 2) {
                let message = 'At least 2 characters';

                errors['name' + index] = message;

                return errors;
            }

            delete errors['name' + index];
        }

        if (editedValue === 'ssn') {
            let ssnValid = this._ssnValidator(ssn, errors, index, casefile);

            if (ssnValid) {
                delete errors['ssn' + index];
            }
        }

        if (editedValue === 'vatin') {
            if (
                vatin &&
                vatin.length >= 1 &&
                (vatin.length !== 8 || !digitReg.test(vatin))
            ) {
                let message = 'Vatin must be 8 digits';

                errors['vatin' + index] = message;

                return errors;
            }

            delete errors['vatin' + index];
        }

        if (editedValue === 'email') {
            if (email && !emailRegex.test(email)) {
                errors['email' + index] = emailErrorMessage;

                return errors;
            }

            delete errors['email' + index];
        }

        if (editedValue === 'reminderInterval') {
            if (reminderInterval && !digitReg.test(reminderInterval)) {
                let message = 'Enter a digit';

                errors['reminderInterval' + index] = message;

                return errors;
            }

            delete errors['reminderInterval' + index];
        }

        if (editedValue === 'onBehalfOf') {
            if (onBehalfOf && onBehalfOf.length >= 1 && onBehalfOf.length < 2) {
                let message = 'Enter a name';

                errors['onBehalfOf' + index] = message;

                return errors;
            }

            delete errors['onBehalfOf' + index];
        }

        return errors;
    },

    _signerArrayFormValidation(signers, casefile) {
        let errors = {};

        if (signers.length > 0) {
            for (let i = 0; i < signers.length; i++) {
                errors = this._signerFormValidation(
                    signers[i],
                    i,
                    errors,
                    casefile
                );
            }
        }

        return errors;
    },

    _signerFieldValidations(index, signers, editedValue, errors, casefile) {
        let error = {};

        error = this._signerFieldsValidation(
            signers[index],
            index,
            errors,
            editedValue,
            casefile
        );

        return error;
    },

    // CASEFILE SUMMARY VALIDATION

    _recipientArrayFormValidation(recipients) {
        let errors = {};

        if (recipients.length > 0) {
            for (let i = 0; i < recipients.length; i++) {
                errors = this._recipientFormValidation(
                    recipients[i],
                    i,
                    errors,
                    true
                );
            }
        }

        return errors;
    },

    _recipientFormValidation(recipient, index, errors) {
        let { name, email } = recipient;

        if (recipient) {
            if (
                typeof name !== 'undefined' &&
                name !== null &&
                name.length >= 1 &&
                name.length < 2
            ) {
                let message = 'Please enter a name';

                errors['name' + index] = message;
            }

            if (
                (typeof email !== 'undefined' && !email) ||
                !emailRegex.test(email)
            ) {
                errors['email' + index] = emailErrorMessage;
            }
        }

        return errors;
    },

    _messageFormValidation(selectedMessageTemplate) {
        let errors = {};

        // If template is unselected. Default message will be used
        if (typeof selectedMessageTemplate === 'undefined') {
            return errors;
        }

        const { subject, message } = selectedMessageTemplate;

        if (!subject) {
            errors.subject = 'Subject cannot be empty';
        }

        if (!message) {
            errors.message = 'Message cannot be empty';
        }

        return errors;
    },
};

export default inputValidation;
