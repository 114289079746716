import React from 'react';
import { Helmet } from 'react-helmet';
import { i18n } from 'Language';
import AccessDenied from 'Common/components/AccessDenied';
import {
    isCustomerAdministrator,
    isPenneoAdministrator,
} from 'Common/utils/userPermissions';
import CredentialManagementTabs from 'Auth/components/CredentialsManagement/CredentialManagementTabs';
import CustomerStore from 'Auth/stores/CustomerStore';
import CustomerActions from 'Auth/actions/CustomerActionCreators';

import { connect } from 'react-redux';
import { AppDispatch } from 'Store';
import { fetchCustomer } from 'Auth/redux/customer/actions';
import { CustomerEntity as Customer } from 'types/Customer';
import { UserEntity as User } from 'types/User';
import { TabbedRoute } from 'types/TabbedRoute';

export type Props = {
    dispatch: AppDispatch;
    user: User;
    children: JSX.Element;
    params: {
        customerId: string;
    };
    customerId: number;
    route: {
        name: string;
        path: string;
        tabs: TabbedRoute[];
    };
};

type State = {
    isAllowed: boolean;
    customer: Customer | null;
};

export class CustomerManagement extends React.Component<Props, State> {
    state: State = {
        isAllowed: false,
        customer: null,
    };

    componentWillMount() {
        const { user } = this.props;

        if (isPenneoAdministrator(user) || isCustomerAdministrator(user)) {
            CustomerStore.addChangeListener(this.onChange);

            this.loadData();
            this.setState({ isAllowed: true });
        } else {
            this.setState({ isAllowed: false });
        }
    }

    componentWillUnmount() {
        CustomerStore.removeChangeListener(this.onChange);
    }

    /**
     * The component is used in routes that the user can transition directly
     * between (as in, no in-between routes between the two).
     *
     * In that case the component would not be unmounted and mounted back again
     * but just updated, hence the need to check if the route changed
     *
     * @param {Props} prevProps
     */
    componentDidUpdate(prevProps: Props) {
        const { route: oldRoute } = prevProps;
        const { route: newRoute } = this.props;

        if (newRoute.name !== oldRoute.name) {
            this.loadData();
        }
    }

    loadData = () => {
        const { dispatch } = this.props;
        const customerId = this.getActiveCustomerId();

        /**
         * @NOTE
         * Fetching of the customer data is temporarily duplicated as the redux fetch function uses `v2`
         * of the endpoint whereas the flux fetch uses `v1`. The use of `v2` endpoint has been needed when the
         * temporary case file storage has been implemented, as this feature's data field exists only on `v2`.
         */
        CustomerActions.fetchCustomer(customerId);
        dispatch(fetchCustomer(customerId));
    };

    /**
     * Determines if the customer being currently managed is also the customer
     * of the current user
     */
    isActiveCustomerOfCurrentUser = (): boolean => {
        const { user } = this.props;
        const [ownCustomerId] = user.customerIds;

        // @NOTE
        // When an admin user manages his own company in the "Admin" section,
        // this comparison should return TRUE, hence showing the `ownCustomer` tabs.
        //
        // The comparison instead (in that case) returns FALSE because of the
        // strict check (`===`) and the fact that `this.params.customerId`
        // is a string while `ownCustomerId` is a number. This results in
        // the `otherCustomer` tabs being shown instead.
        //
        // Although technically a bug, for the moment we do want its outcome
        // (we always want to show `otherCustomer` tabs in the "Admin" section
        // regardless of which company is shown) until we rethink the routes
        // of company (own and other's) management
        return this.getActiveCustomerId() === ownCustomerId;
    };

    /**
     * Returns the ID for the customer being currently managed.
     * If there's no customerId specified in the URL, picks the current user's customer.
     *
     * Current user's customer is always the first one in the array of {customerIds}
     */
    getActiveCustomerId = (): number => {
        const { user, customerId } = this.props;

        return customerId ? customerId : user.customerIds[0];
    };

    onChange = () => {
        const customerId = this.getActiveCustomerId();

        this.setState({
            customer: CustomerStore.getCustomer(customerId),
        });
    };

    getTitle = (): string => {
        const { customer } = this.state;
        const title =
            'Manage ' +
            (this.isActiveCustomerOfCurrentUser()
                ? 'my company'
                : customer
                ? customer.name
                : '');

        return i18n(title);
    };

    render() {
        const { user, route, children } = this.props;
        const { customer, isAllowed } = this.state;
        const customerId = this.getActiveCustomerId();
        const title = this.getTitle();

        return (
            <>
                <Helmet>
                    <title>{title}</title>
                </Helmet>

                {!isAllowed ? (
                    <AccessDenied pageName="Manage My Company" />
                ) : (
                    <div className="white-container">
                        <h3 className="title">{title}</h3>
                        {customer && (
                            <CredentialManagementTabs
                                options={route.tabs}
                                userId={user.id}
                                customerId={customerId}>
                                {React.cloneElement(children, {
                                    customer: customer,
                                    user: user,
                                })}
                            </CredentialManagementTabs>
                        )}
                    </div>
                )}
            </>
        );
    }
}

export default connect((_: any, props: Props) => ({
    customerId: Number(props.params.customerId),
}))(CustomerManagement);
