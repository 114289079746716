import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Languages } from 'Language/Constants';
import { AppThunk } from 'Store';
import { PublicAuthAPI, PublicSigningAPI } from 'Api';
import { getServiceRedirectUrl } from '../utils';
import { SignText } from 'OpenID/redux/types';
import { fetchSignText } from 'OpenID/utils/signText';
import { signingStages } from 'types/SigningProcess';
import { signingRedirectUrl, V2Validation } from 'Signing/utils';
import { getErrorDetails, invalidState, ItsmeErrorType } from 'ItsmeQES/errors';
import {
    clearStoredItsmeState,
    loadStoredItsmeState,
    StoredStateType,
    storeItsmeState,
} from 'ItsmeQES/storedState';
import { pickBy } from 'lodash';
import { base64Encode } from 'utils/base64';
import { PublicSigningAPIWithAuthHeader } from 'Api/ApiClient';
import analytics from 'Common/Analytics';
import { PersonIdentifierType } from 'EID/types';

export enum StateType {
    /** Right when the component is loaded, but has not yet loaded any stored state or done anything else */
    Uninitialized = 'Uninitialized',
    /** The store has become aware of the challenge key, it has likely dispatched one of the async actions appropriate */
    Initialized = 'Initialized',
    /** The signer has gotten back from the first dance, we are now fetching the data to be signed */
    FetchingSignText = 'FetchingSignText',
    /** The signer is ready to initiate the second dance */
    ShowingDataToSign = 'ShowingDataToSign',
    /** The signer has begun the second dance, and is waiting for the redirect to go through */
    SigningConfirmed = 'SigningConfirmed',
    /** The signer is ready to save the signature, they can now chose a storage option */
    ReadyToSaveSignature = 'ReadyToSaveSignature',
    /** Oh, no! */
    Error = 'Error',
}

type BaseState = {
    base: {
        challengeKey: string;
        language: Languages;
    };
};

type UninitializedState = { type: StateType.Uninitialized };
type InitializedState = BaseState & {
    type: StateType.Initialized;
};
type FetchingSignTextType = BaseState & {
    type: StateType.FetchingSignText;
    secondDanceRedirectUrl: string;
};
type ShowingDataToSignType = BaseState & {
    type: StateType.ShowingDataToSign;
    secondDanceRedirectUrl: string;
    signText: SignText;
};
type SigningConfirmed = BaseState & { type: StateType.SigningConfirmed };
type ReadyToSaveSignature = BaseState & {
    type: StateType.ReadyToSaveSignature;
    signature: string;
    signToken: string;
};

// Partial<> because in some cases we do not have the challenge key or language
type SigningError = Partial<BaseState> & {
    type: StateType.Error;
    error: ItsmeErrorType;
};

export type State =
    | UninitializedState
    | InitializedState
    | FetchingSignTextType
    | ShowingDataToSignType
    | SigningConfirmed
    | ReadyToSaveSignature
    | SigningError;

const itsmeQESSigningSlice = createSlice({
    name: 'itsmeQESSigning',
    reducers: {
        reset: (_) => {
            clearStoredItsmeState();

            return { type: StateType.Uninitialized };
        },
        initialize: (
            _,
            action: PayloadAction<{ challengeKey: string; language: Languages }>
        ) => ({
            type: StateType.Initialized,
            base: {
                challengeKey: action.payload.challengeKey,
                language: action.payload.language,
            },
        }),
        fetchingSignText: (
            _,
            action: PayloadAction<
                {
                    secondDanceRedirectUrl: string;
                } & BaseState
            >
        ) => ({
            type: StateType.FetchingSignText,
            secondDanceRedirectUrl: action.payload.secondDanceRedirectUrl,
            base: action.payload.base,
        }),
        showDataToSign: (
            _,
            action: PayloadAction<
                {
                    secondDanceRedirectUrl: string;
                    signText: SignText;
                } & BaseState
            >
        ) => ({
            type: StateType.ShowingDataToSign,
            secondDanceRedirectUrl: action.payload.secondDanceRedirectUrl,
            signText: action.payload.signText,
            base: action.payload.base,
        }),
        signingConfirmed: (_, action: PayloadAction<BaseState>) => ({
            type: StateType.SigningConfirmed,
            base: action.payload.base,
        }),
        readyToSaveSignature: (
            _,
            action: PayloadAction<
                { signature: string; signToken: string } & BaseState
            >
        ) => ({
            type: StateType.ReadyToSaveSignature,
            signature: action.payload.signature,
            signToken: action.payload.signToken,
            base: action.payload.base,
        }),
        showError: (
            _,
            action: PayloadAction<
                { error: ItsmeErrorType } & Partial<BaseState>
            >
        ) => ({
            type: StateType.Error,
            error: action.payload.error,
            base: action.payload.base,
        }),
    },
    initialState: { type: StateType.Uninitialized } as State,
});

/**
 * Determines if an Itsme QES process was previously started and should be resumed.
 */
export const shouldResumeItsmeQESSigning = (): boolean => {
    const state = loadStoredItsmeState();

    if (state === null) {
        // no previous process started
        return false;
    }

    if (state.type === StoredStateType.FirstDanceInit) {
        // The user started the first dance, but decided to abort by clicking the browser back button. Or they may have
        // refreshed the page before we got a chance to redirect. In both cases showing the base signing page is the
        // expected behavior.
        // We are also clearing the state, to make sure we don't get any weird behavior down the line.
        clearStoredItsmeState();

        return false;
    }

    return true;
};

/**
 * Signing with Itsme QES involves 2 redirects to and from the external provider. This action loads whatever state
 * was kept in storage between them, and decides what to do next.
 */
export const loadState = (
    challengeKey: string,
    language: Languages
): AppThunk => async (dispatch) => {
    const base = { challengeKey, language };

    dispatch(initialize(base));

    const cachedData = loadStoredItsmeState();

    // We always clear the state on when booting up the component in order to avoid loops.
    // If we do not clear the state, we could potentially end up where some state triggers a redirect to Itsme, the
    // signer clicks "Back" or "Deny", gets back to this component, and is automatically redirected again.
    // Clearing the state here means that if you refresh the page, you can start from scratch.
    clearStoredItsmeState();

    if (cachedData === null) {
        dispatch(firstDance());

        return;
    }

    if (cachedData.type === StoredStateType.FirstDanceInit) {
        // The only way to have this state is if the signing process was resumed without checking the
        // shouldResumeItsmeQESSigning() function first.
        //
        // Normally, that function will just clear the (now useless) state, and the process can be re-initiated by the
        // signer. However, if we got here, there's no easy way to recover.

        dispatch(
            showError({
                error: invalidState(
                    StateType.Uninitialized,
                    'found first dance init data'
                ),
                base,
            })
        );

        return;
    }

    if (
        cachedData.type === StoredStateType.FirstDanceResponse ||
        cachedData.type === StoredStateType.SecondDanceInit
    ) {
        // The signer can get here either by correctly coming back from the first dance, by using the back button after
        // the redirect from the second dance, or by immediately refreshing the page after clicking "sign".
        // In each case, we want to show the data to be signed and try to continue the process.
        dispatch(prepareDataToBeSignedView(cachedData.secondDanceRedirectUrl));

        return;
    }

    if (cachedData.type === StoredStateType.SecondDanceResponse) {
        dispatch(
            resumeSignatureSavingProcess({
                signature: cachedData.signature,
                base,
            })
        );

        return;
    }

    if (cachedData.type === StoredStateType.Error) {
        dispatch(
            showError({
                error: cachedData.error,
                base: { challengeKey, language },
            })
        );

        return;
    }

    // The only way to reach this place is if someone adds a new state and forgets to update this code.
    // Hopefully, a test would fail if that's the case.
    console.error('Invalid ItsmeQES signing state', cachedData);
    dispatch(
        showError({
            error: invalidState(
                // casting to "any" because all possibilities are exhausted, it would be a compile error without it.
                (cachedData as any).type,
                'loading signing component'
            ),
            base: { challengeKey, language },
        })
    );
};

/**
 * The signer is redirected to Itsme and is asked to grant us access to  read some of their personal data (the signing
 * certificate).
 *
 * There is a "pause" after this step where the signer can see the XAdES they are about to sign. We chose to do it that
 * way because it aligns better with the signing experience for our other EIDs, and because it allows the signer to
 * see the XAdES together with the certificate that will be used for it.
 */
const firstDance = (): AppThunk => async (dispatch, getState) => {
    const state = getState().itsmeQESSigning;

    if (state.type !== StateType.Initialized) {
        dispatch(
            showError({
                error: invalidState(
                    state.type,
                    'initiating the first dance',
                    StateType.Initialized
                ),
            })
        );
        console.error('Invalid state in firstDance()', state);

        return;
    }

    const { challengeKey, language } = state.base;

    try {
        const response = await PublicSigningAPI.post('/v3/sign/itsme.be/init', {
            challengeKey,
            language,
            serviceRedirectUrl: getServiceRedirectUrl(),
        });

        storeItsmeState({
            type: StoredStateType.FirstDanceInit,
            successUrl: window.location.href,
            challengeKey,
            language,
        });

        window.location.href = response.redirectUrl;
    } catch (error) {
        console.error('Error trying to initialize itsme.be signing', error);
        clearStoredItsmeState();

        const { data, headers } = error;
        const errorDetails = getErrorDetails(data.type);

        dispatch(
            showError({
                error: {
                    headers,
                    ...data,
                    ...errorDetails,
                    details: 'If the problem persists, contact support',
                },
                base: state.base,
            })
        );
    }
};

/**
 * The signer has gotten back from Itsme after the first dance, now they get to see a nice view of the XAdES they will
 * be signing and the certificate they will use do so.
 */
const prepareDataToBeSignedView = (
    secondDanceRedirectUrl: string
): AppThunk => async (dispatch, getState) => {
    const state = getState().itsmeQESSigning;

    if (state.type !== StateType.Initialized) {
        dispatch(
            showError({
                error: invalidState(
                    state.type,
                    'fetching sign text',
                    StateType.Initialized
                ),
                // state might also be uninitialized
                base: (state as any).base,
            })
        );
        console.error(
            'Invalid ItsmeQES state in prepareDataToBeSignedView',
            state
        );

        return;
    }

    dispatch(
        fetchingSignText({
            secondDanceRedirectUrl,
            base: state.base,
        })
    );

    const { challengeKey, language } = state.base;
    let signText: SignText;

    try {
        signText = await fetchSignText(challengeKey, language, true);
    } catch (error) {
        console.error('Error fetching sign text', error);
        clearStoredItsmeState();

        dispatch(
            showError({
                error: {
                    message: error.name,
                    details: error.message,
                },
                base: state.base,
            })
        );

        return;
    }

    dispatch(
        showDataToSign({
            secondDanceRedirectUrl,
            signText,
            base: state.base,
        })
    );
};

/**
 * The second dance is where the signer actually signs. It also involves a redirect to and from Itsme.
 */
export const secondDance = (): AppThunk => async (dispatch, getState) => {
    const state = getState().itsmeQESSigning;

    if (state.type !== StateType.ShowingDataToSign) {
        dispatch(
            showError({
                error: invalidState(
                    state.type,
                    'second dance',
                    StateType.Initialized
                ),
                // state might also be uninitialized
                base: (state as any).base,
            })
        );
        console.error('Invalid ItsmeQES state in secondDance()', state);

        return;
    }

    const { secondDanceRedirectUrl } = state;
    const { challengeKey } = state.base;

    dispatch(signingConfirmed({ base: state.base }));

    storeItsmeState({
        type: StoredStateType.SecondDanceInit,
        secondDanceRedirectUrl: secondDanceRedirectUrl,
        successUrl: window.location.href,
        challengeKey: challengeKey,
    });

    window.location.href = state.secondDanceRedirectUrl;
};

const resumeSignatureSavingProcess = (
    params: {
        signature: string;
    } & BaseState
): AppThunk => async (dispatch) => {
    const { signature, base } = params;

    dispatch(getSignToken({ signature, base }));
};

export type SignatureData = {
    signature: string;
    signToken: string;
};

/**
 * Saving the signature is the last step. The signature is sent over to api-sign where it is validated and stored
 * securely.
 */
export const saveSignature = (params: {
    signData: SignatureData;
    disposable?: boolean;
    userId?: number | null;
    challengeKey: string;
}): AppThunk => async (dispatch) => {
    const { signData, challengeKey, disposable, userId } = params;
    const { signature, signToken } = signData;

    try {
        await PublicSigningAPIWithAuthHeader.post('/v3/sign/itsme.be/sign', {
            challengeKey,
            signature,
            signToken,
            disposable: disposable || true,
            signerUserId: userId || null,
        });
        analytics.track('sign success', { method: 'itsme QES' });
        analytics.amplitude.incrementUserProperty('casefiles signed');

        window.location.href = await signingRedirectUrl(
            signingStages.signed,
            challengeKey
        );
    } catch (error) {
        analytics.track('sign error', { method: 'itsme QES' });
        clearStoredItsmeState();

        const { data, headers } = error;
        const errorDetails = getErrorDetails(data.type);

        dispatch(
            showError({
                error: {
                    headers,
                    ...data,
                    ...errorDetails,
                    details: 'If the problem persists, contact support',
                },
                base: { challengeKey, language: Languages.EN },
            })
        );
    }
};

export const getSignToken = (
    params: { signature: string } & BaseState
): AppThunk => async (dispatch) => {
    const { signature, base } = params;

    let signToken: string;

    try {
        signToken = (
            await PublicAuthAPI.post(
                '/v1/qes.itsme.be/signatures',
                pickBy({
                    signature: base64Encode(signature),
                    validationToken: V2Validation.get(),
                }),
                { propagateErrors: true }
            )
        ).token as string;
    } catch (error) {
        console.log('error validating Itsme QES signature', error);

        dispatch(
            showError({
                error: error,
                base,
            })
        );

        return;
    }

    const validationToken = V2Validation.get();
    const validationProvider = V2Validation.getProvider();

    if (!validationToken || validationProvider === PersonIdentifierType.SMS) {
        // If we don't have an EID validation token, it means there is nothing on the post-signing screen that the user
        // could do, so we save the signature directly.
        dispatch(
            saveSignature({
                signData: {
                    signature,
                    signToken,
                },
                challengeKey: base.challengeKey,
            })
        );

        return;
    }

    // Presents a saving dialogue to the user, where they can customize some saving options.
    dispatch(
        readyToSaveSignature({
            signature,
            signToken,
            base,
        })
    );
};

const {
    initialize,
    fetchingSignText,
    showDataToSign,
    signingConfirmed,
    readyToSaveSignature,
} = itsmeQESSigningSlice.actions;

export const { reset, showError } = itsmeQESSigningSlice.actions;
export default itsmeQESSigningSlice.reducer;
