import { parameters } from 'Constants';
import { initialize } from 'launchdarkly-js-client-sdk';
import { getFlatManagedFeatures } from 'Settings/utils';
import { Settings } from 'Settings/redux/types';
import { Flags, LaunchDarklyUserObject } from 'types/LaunchDarkly';
import { debug, storage } from 'Core';
import { CustomerEntity } from 'types/Customer';
import { UserEntity } from 'types/User';
import uniqid from 'uniqid';

// Reexport flags enum to simplify usage.
export { Flags };

type CustomProps = Record<string, string | number | boolean>;
const PENNEO_LD_USER_KEY = 'penneo-ld-user-key';

let ldclient;
let ldUser: Partial<LaunchDarklyUserObject> = {
    key: getUserKey(),
};

function getldUser(
    user: UserEntity,
    settings?: Settings,
    customer?: CustomerEntity,
    customProps?: CustomProps
): LaunchDarklyUserObject {
    const key = user?.id?.toString() ?? ldUser.key;

    setUserKey(key);

    const data: LaunchDarklyUserObject = {
        key: key,
        custom: {
            language: user.language,
            admin: !!user.admin,
            role: user.role,
            customerId: user?.customerIds?.length ? user.customerIds[0] : false,
            rights: user.rights,
            'customer.status': customer ? customer.status : false,
        },
    };

    // Flatten managed settings to send to LaunchDarkly. (LaunchDarkly doesn't support nested data formats)
    const managedFeatures = getFlatManagedFeatures(settings as Settings);
    const customProperties = () =>
        Object.keys(customProps ?? {}).reduce((properties, key) => {
            properties[`custom.${key}`] = customProps?.[key];

            return properties;
        }, {});

    // Merge values
    return {
        ...data,
        custom: {
            ...customProperties(),
            ...data.custom,
            ...managedFeatures,
        },
    };
}

// Makes sure we have a good LD key. Each user will have a different key,
// even when not logged in.
function getUserKey(): string {
    let key = storage.get(PENNEO_LD_USER_KEY);

    if (!key) {
        key = uniqid('anonymous-');
        setUserKey(key);
    }

    return key;
}

function setUserKey(key: string): void {
    storage.set(PENNEO_LD_USER_KEY, key);
}

function resetUserKey(): void {
    storage.clear(PENNEO_LD_USER_KEY);
}

function init() {
    ldclient = initialize(parameters.ldClientKey, ldUser, {
        sendEvents: false,
    });

    return ready(ldUser);
}

async function identify(
    user: UserEntity,
    settings?: Settings | null,
    customer?: CustomerEntity | null,
    customProperties?: CustomProps | null
) {
    ldUser = getldUser(
        user,
        settings as Settings,
        customer as CustomerEntity,
        customProperties as CustomProps
    );

    return new Promise<void>((resolve) => {
        ldclient.identify(ldUser, null, () => {
            debug.log('[LaunchDarkly identified]', ldUser, ldclient.allFlags());
            resolve();
        });
    }).catch((e) => {
        debug.log(e);

        return Promise.resolve();
    });
}

function ready(user) {
    return ldclient
        .waitUntilReady()
        .then(() => {
            debug.log('[LaunchDarkly initialized]', ldclient);

            return true;
        })
        .catch((error) => {
            debug.log('[LaunchDarkly error]', error, user);

            return true;
        });
}

function variation(flag: Flags, ...args) {
    return ldclient?.variation(flag, ...args);
}

function variationIncludes(flag: Flags, values: string[]): boolean {
    const flagValue = ldclient?.variation(flag);

    return !!~values.indexOf(flagValue);
}

let ldWaitReadyPromise: Promise<void> | null = null;
/**
 * Waits for LD to be ready for use.
 *
 * LD will get flag statuses from LD's remote servers, and may take up to a few hundred ms to be available.
 *
 * Example use:
 * ```ts
 * // used as async
 * await launchDarkly.waitReady();
 * console.log(launchDarkly.variation(someFlag));
 * ```
 *
 * ```ts
 * // used with promise callbacks
 * launchDarkly.waitReady()
 *      .then(() => console.log(launchDarkly.variation(someFlag)));
 * ```
 */

async function waitReady(): Promise<unknown> {
    if (ldWaitReadyPromise) {
        // avoid problems where waitReady() is called multiple times, causing multiple versions of the same callbacks to
        // be run
        return ldWaitReadyPromise;
    }

    let failed = false;

    const listenForReady = (
        resolve: (...args: any[]) => void,
        timeoutTimer: ReturnType<typeof setTimeout>
    ) => {
        if (failed) {
            // without this check, we might end up in an endless loop of waiting around for ldclient to be initialized
            return;
        }

        if (!ldclient) {
            // if waitReady() is called before init(), we will not have a value for ldclient
            // once init() was called, we'll just proxy things to the ldclient event system
            setTimeout(listenForReady.bind(null, resolve, timeoutTimer), 50);

            return;
        }

        ldclient.on('ready', () => {
            clearTimeout(timeoutTimer);
            resolve(null);
        });
    };

    const detectTimeout = (reject: (any) => any) => {
        return setTimeout(() => {
            failed = true; // causes listenForReady to stop running

            return reject('timeout');
        }, 1000);
    };

    return new Promise((resolve, reject) => {
        try {
            const timeoutCancelToken = detectTimeout(reject);

            listenForReady(resolve, timeoutCancelToken);
        } catch (error) {
            console.error('Error occurred in the Feature Flag client', error);
            reject(error);
        }
    }).catch((error) => {
        console.error('Error occurred in the Feature Flag client', error);
    });
}

function reset() {
    resetUserKey();
}

const launchDarkly = {
    client: () => ldclient,
    init,
    identify,
    flags: () => ldclient.allFlags(),
    user: () => ldUser as LaunchDarklyUserObject,
    variation,
    variationIncludes,
    waitReady,
    reset,
};

export default launchDarkly;
