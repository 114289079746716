import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'Language';
// child components
import CasefileTemplateCreatorModal from './CasefileTemplateCreatorModal.jsx';
import CasefileTemplateSelectorModal from './CasefileTemplateSelectorModal';
// action creators
import ClickDropdown from 'Common/components/Common/ClickDropdown';
import Button from 'Common/components/Button';

export default class CasefileAdvancedOptions extends React.Component {
    static propTypes = {
        resetData: PropTypes.func,
        sending: PropTypes.bool,
    };

    renderAdvancedOptions = () => {
        return (
            <div className="click-dropdown-list">
                <ul>
                    <li onClick={this.props.resetData}>
                        <i className="far fa-sync" />
                        &nbsp;&nbsp;
                        {i18n('Clear fields')}
                    </li>
                    <CasefileTemplateCreatorModal />
                    <CasefileTemplateSelectorModal />
                </ul>
            </div>
        );
    };

    render() {
        return (
            <ClickDropdown
                placement="top left"
                width={180}
                align="right"
                offset={20}
                content={this.renderAdvancedOptions()}>
                <Button
                    theme="blue"
                    className="mr"
                    variant="outline"
                    disabled={this.props.sending}>
                    {i18n('Advanced options')}
                </Button>
            </ClickDropdown>
        );
    }
}
