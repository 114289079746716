import { ApiClient, SigningAPI } from 'Api';
import { AppDispatch, AppThunk, GetState } from 'Store';
import { Client, ClientLinkEntity, ClientLinkEntityType } from '../types';
import {
    fetchedClients,
    resetCurrentClient,
    resetCurrentLinks,
    resetLastDeletedClient,
    resetLastSavedClient,
    setCurrentClient,
    setCurrentLinks,
    setError,
    setLastDeletedClient,
    setLastSavedClient,
    setLoadingEnd,
    setLoadingStart,
} from './reducer';
import { CaseFileItem } from 'Casefiles/Archive';
import { EditableClientProps, cleanedUpClientData } from './utils';

export const saveNewClient = (
    newClientData: EditableClientProps
): AppThunk => async (
    dispatch: AppDispatch,
    _: GetState,
    { api }: { api: { SigningAPI: ApiClient } }
) => {
    try {
        dispatch(setLoadingStart());
        dispatch(resetLastSavedClient());

        /**
         * Notice that we remove empty strings from the payload,
         * as an empty string is still considered a valid value
         */
        const client: Client = await api.SigningAPI.post(
            '/clients',
            cleanedUpClientData(newClientData, true)
        );

        dispatch(setLastSavedClient(client));
    } catch (error) {
        dispatch(setError(error));
    } finally {
        dispatch(setLoadingEnd());
    }
};

export type ClientsFetchParams = {
    per_page: number;
    page: number;
    name?: string;
    sort?: 'ASC' | 'DESC';
};

/**
 * We separate the query logic from the action
 * so we can use it independently, without disptaching from Redux
 */
export const fetchClientsQuery = async (params: ClientsFetchParams) => {
    const {
        data: clients,
        count,
    }: { data: Client[]; count: number } = await SigningAPI.get(
        '/clients',
        params,
        {
            paginate: true,
        }
    );

    return { clients, count };
};

export const fetchClients = (params: ClientsFetchParams): AppThunk => async (
    dispatch: AppDispatch
) => {
    try {
        dispatch(setLoadingStart());

        const { clients, count } = await fetchClientsQuery(params);

        dispatch(fetchedClients({ clients, total: count }));
    } catch (error) {
        dispatch(setError(error));
    } finally {
        dispatch(setLoadingEnd());
    }
};

export const fetchClientById = (clientId: number): AppThunk => async (
    dispatch: AppDispatch,
    _: GetState,
    { api }: { api: { SigningAPI: ApiClient } }
) => {
    try {
        dispatch(setLoadingStart());

        const client: Client = await api.SigningAPI.get(`/clients/${clientId}`);

        dispatch(setCurrentClient(client));
    } catch (error) {
        dispatch(setError(error));
    } finally {
        dispatch(setLoadingEnd());
    }
};

export const fetchClientLinkedEntities = (
    clientId: number,
    entityType: ClientLinkEntityType,
    params: ClientsFetchParams
): AppThunk => async (
    dispatch: AppDispatch,
    getState: GetState,
    { api }: { api: { SigningAPI: ApiClient } }
) => {
    const { clients: clientsState } = getState();
    const { currentClient } = clientsState;

    if (!currentClient) {
        return;
    }

    try {
        dispatch(setLoadingStart());

        const {
            data: entities,
            count,
        }: {
            data: Partial<CaseFileItem>[];
            count: number;
        } = await api.SigningAPI.get(
            `/clients/${clientId}/${entityType}s/details`,
            params,
            {
                paginate: true,
            }
        );

        /**
         * TODO 2.0: all these methods must support also Contacts
         */

        dispatch(
            setCurrentClient({
                ...currentClient,
                entities: {
                    type: entityType,
                    total: count,
                    list: entities,
                },
            })
        );
    } catch (error) {
        dispatch(setError(error));
    } finally {
        dispatch(setLoadingEnd());
    }
};

export const updateClient = (
    clientId: number,
    newClientData: EditableClientProps
): AppThunk => async (
    dispatch: AppDispatch,
    _: GetState,
    { api }: { api: { SigningAPI: ApiClient } }
) => {
    try {
        dispatch(setLoadingStart());
        dispatch(resetCurrentClient());

        const client: Client = await api.SigningAPI.put(
            `/clients/${clientId}`,
            cleanedUpClientData(newClientData)
        );

        dispatch(setCurrentClient(client));
    } catch (error) {
        dispatch(setError(error));
    } finally {
        dispatch(setLoadingEnd());
    }
};

export const deleteClient = (client: Client): AppThunk => async (
    dispatch: AppDispatch,
    _: GetState,
    { api }: { api: { SigningAPI: ApiClient } }
) => {
    try {
        dispatch(setLoadingStart());
        dispatch(resetLastDeletedClient());

        await api.SigningAPI.delete(`/clients/${client.id}`);

        dispatch(setLastDeletedClient(client.name));
    } catch (error) {
        dispatch(setError(error));
    } finally {
        dispatch(setLoadingEnd());
    }
};

export const fetchClientsByEntity = (
    entity: ClientLinkEntity
): AppThunk => async (
    dispatch: AppDispatch,
    getState: GetState,
    { api }: { api: { SigningAPI: ApiClient } }
) => {
    /**
     * If this is a new, unsaved entity (fx casefile),
     * we skip fetching and just return empty client lists
     */
    if (!entity.entityId) {
        dispatch(
            setCurrentLinks({
                linkEntity: entity,
                saved: [],
                new: [],
                dirty: false,
            })
        );

        return;
    }

    const { clients: clientsState } = getState();
    const { loading } = clientsState;

    if (loading) {
        return;
    }

    try {
        dispatch(setLoadingStart());
        dispatch(resetCurrentLinks());

        const clients: Client[] = await api.SigningAPI.get('/clients', {
            [entity.entityType]: entity.entityId,
        });

        dispatch(
            setCurrentLinks({
                linkEntity: entity,
                saved: clients,
                new: [],
                dirty: false,
            })
        );
    } catch (error) {
        dispatch(setError(error));
    } finally {
        dispatch(setLoadingEnd());
    }
};

export const saveClientLinks = (entity: ClientLinkEntity): AppThunk => async (
    dispatch: AppDispatch,
    getState: GetState,
    { api }: { api: { SigningAPI: ApiClient } }
) => {
    const { clients: clientsState } = getState();
    const { loading, currentLinks } = clientsState;

    if (loading || !currentLinks || !currentLinks.dirty) {
        return;
    }

    const { saved: savedList, new: newList } = currentLinks;

    try {
        dispatch(setLoadingStart());

        /**
         * We compare both lists and make two lists:
         * one for all the links that must be removed,
         * one for links to be created
         */
        const clientsToRemove = savedList.filter(
            (client) => !newList.find((cli) => cli.id === client.id)
        );

        const clientsToAdd = newList.filter(
            (client) => !savedList.find((cli) => cli.id === client.id)
        );

        /**
         * We now delete all old links and then create thew new ones.
         * NOTE: these endpoints are for one client id at a time
         * and many casefile/contact ids. There's no other way
         * to do this for now, until we get new endpoints,
         * so that's why the entity id is just the one single element in an array
         */
        const unlinks = clientsToRemove.map((client) => ({
            clientId: client.id,
            [entity.entityType === 'casefile' ? 'caseFileIds' : 'contactIds']: [
                entity.entityId,
            ],
        }));

        if (unlinks.length) {
            await api.SigningAPI.delete(
                `/clients/${entity.entityType}s/batch`,
                {
                    clients: unlinks,
                }
            );
        }

        const links = clientsToAdd.map((client) => ({
            clientId: client.id,
            [entity.entityType === 'casefile' ? 'caseFileIds' : 'contactIds']: [
                entity.entityId,
            ],
        }));

        if (links.length) {
            await api.SigningAPI.post(`/clients/${entity.entityType}s/batch`, {
                clients: links,
            });
        }
    } catch (error) {
        dispatch(setError(error));
    } finally {
        dispatch(resetCurrentLinks());
        dispatch(setLoadingEnd());
    }
};
