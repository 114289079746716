import { getFileHash } from './file';
import { debug } from 'Core';
import { DocumentEntity } from 'types/Document';
import { NewSignerAPI, PublicSignerAPI } from 'Api';
import { isV2Signing } from 'Signing/utils';

/**
 * Fetch document metadata for signable documents
 * @param challengeKey - Signing Page Challenge Key
 */
export const fetchSignableDocuments = async (
    challengeKey: string
): Promise<DocumentEntity[]> => {
    try {
        /**
         * If we are coming from the New Signing page, we use the new endpoint
         * and API configuration (to use Bearer token instead of PHP sesh)
         */
        const documents: DocumentEntity[] = isV2Signing.check()
            ? await NewSignerAPI.get(`/v2/documents/${challengeKey}`)
            : await PublicSignerAPI.get(`/documents/${challengeKey}`);

        return documents.filter((doc) => doc.signable);
    } catch (e) {
        throw new Error('Failed to fetch the required data');
    }
};

/**
 * Fetches the PDF document that will be signed.
 *
 * This document can be used as the "base" of the final signed document.
 * It does *not* contain the temporary signer's page.
 *
 * If you want to show signers a preview of which signers have or have not signed,
 * use the fetchDocumentFilePreview() function.
 *
 * @param challengeKey
 * @param documentId
 * @param publicVersion
 */
export const fetchDocumentFileToBeSigned = async (
    challengeKey: string,
    documentId: number,
    newEndpoint?: boolean
): Promise<Blob> => {
    /**
     * When retrieving documents for Signing V2, we need to use the new API endpoint
     */
    const options = newEndpoint
        ? {
              endpoint: NewSignerAPI,
              url: `/v2/signing-process/${challengeKey}/documents/${documentId}/to-be-signed`,
          }
        : {
              endpoint: PublicSignerAPI,
              url: `/documents/${challengeKey}/${documentId}/to-be-signed`,
          };

    try {
        return await options.endpoint.get(options.url, null, {
            responseType: 'blob',
        });
    } catch (error) {
        throw new Error(`Failed to fetch document data - [ID: ${documentId}`);
    }
};

/**
 * Fetches the PDF document *PREVIEW*.
 *
 * This version of the document will have a special page at the end to show pending signers.
 *
 * This version of the PDF is not appropriate for generating signatures, it's only use is to give signers more info
 * on who still needs to sign.
 *
 * The reason this file cannot be used for signatures is that different signers will see slightly different files:
 * - the first signers will see a document full of signers that have not signed yet
 * - the last signer will see a document where every signer apart from them has signed
 * If we were to build signatures from this file, every signer would sign a different file.
 *
 * If you want to generate a signature, use the fetchDocumentFileToBeSigned() function.
 *
 * @param challengeKey
 * @param documentId
 */
export const fetchDocumentFilePreview = async (
    challengeKey: string,
    documentId: number
): Promise<Blob> => {
    try {
        return await NewSignerAPI.get(
            `/v2/signing-process/${challengeKey}/documents/${documentId}/preview`,
            null,
            {
                responseType: 'blob',
            }
        );
    } catch (error) {
        throw new Error(`Failed to fetch document data - [ID: ${documentId}`);
    }
};

export const verifyDocumentHash = async (
    challengeKey: string,
    documentId: number,
    digest: string
) => {
    // if we are signing in V2, we need to use a diff endpoint
    const v2 = isV2Signing.check();
    const file = await fetchDocumentFileToBeSigned(
        challengeKey,
        documentId,
        v2
    );
    const hash = await getFileHash(file);

    // Check if file hash matches the digest.
    if (digest !== hash) {
        debug.error(`Document hash doesn't match digest
            id: ${documentId}
            digest: ${digest}
            hash: ${hash}
        `);

        throw Error(`Document ${documentId} - hash doesn't match digest`);
    }
};
