import React, { useEffect, useState, useRef, useMemo } from 'react';

import Constants from 'Constants';
import { i18n, TranslationStore } from 'Language';
import { CustomerBranding } from 'types/SigningProcess';
import './signing-header.scss';
import { trackSigning, checkColorSchemeValid } from './utils';
import { NewSignerAPI, PublicSignerAPI } from 'Api';
import { images } from 'Constants';
import { Languages, LANGUAGES } from 'Language/Constants';

import { notify } from 'react-notify-toast';

type Props = {
    currentDocId?: number;
    challengeKey?: string;
    branding?: CustomerBranding;
    isValidatedCasefile?: boolean;
    isAccessControlEnabled?: boolean;
    changeLanguage: (languageCode: Languages) => void;
    finalized?: boolean;
    isSigningDoneLeadGen?: boolean;
};

export const createPdfURL = async (
    isValidatedCasefile,
    isAccessControlEnabled,
    challengeKey,
    currentDocId,
    finalized
) => {
    let url = `/signing-process/${challengeKey}/documents/${currentDocId}/to-be-signed`;

    if (isValidatedCasefile && finalized) {
        url = `/signing-process/${challengeKey}/documents/${currentDocId}/preview`;
    }

    const isCaseFileRestricted = isAccessControlEnabled || isValidatedCasefile;

    if (isCaseFileRestricted && currentDocId) {
        const params =
            (isValidatedCasefile &&
                !finalized && { signed: finalized, decrypt: true }) ||
            null;

        const api = isCaseFileRestricted ? NewSignerAPI : PublicSignerAPI;

        const blob = await api
            .get(url, params, {
                raw: true,
                responseType: 'blob',
            })
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                throw err;
            });

        try {
            const blobUrl = window.URL.createObjectURL(blob.data);

            url = blobUrl;

            return url;
        } catch (error) {
            notify.show(
                'Could not prepare the document for download!',
                'error',
                3000
            );
        }
    }

    return `${Constants.PENNEO_SIGNER_API}/v2${url}`;
};

export const SigningHeader = ({
    currentDocId,
    branding,
    challengeKey,
    isValidatedCasefile,
    isAccessControlEnabled,
    finalized,
    changeLanguage,
    isSigningDoneLeadGen = false,
}: Props) => {
    const linkProps = {
        ...(branding &&
            checkColorSchemeValid(branding) && {
                style: { color: branding?.textColor },
            }),
        target: '_blank',
        rel: 'noopener noreferrer',
        'data-testid': `header-link`,
    };
    const [pdfUrl, setPdfUrl] = useState('#');
    const docId = useRef(currentDocId);

    useEffect(() => {
        if (pdfUrl !== '#' && currentDocId === docId.current) {
            return;
        }

        if (docId && docId.current !== currentDocId) {
            docId.current = currentDocId;
        }

        (async () => {
            const url = await createPdfURL(
                isValidatedCasefile,
                isAccessControlEnabled,
                challengeKey,
                currentDocId,
                finalized
            );

            setPdfUrl(url);
        })();

        return () => {
            if (
                pdfUrl &&
                pdfUrl !== '#' &&
                (pdfUrl as unknown) instanceof Blob
            ) {
                window.URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [challengeKey, currentDocId, isValidatedCasefile, pdfUrl]);

    const currentLanguage: string = TranslationStore.getLanguage();

    const getHelpLink = () => {
        if (currentLanguage === 'da') {
            return 'https://www.support.penneo.com/hc/da/articles/20450916545437-Hvordan-underskriver-jeg-med-Penneo';
        } else if (currentLanguage === 'fr') {
            return 'https://www.support.penneo.com/hc/fr/articles/20450916545437-Comment-signer-un-document';
        }

        return 'https://www.support.penneo.com/hc/en-gb/articles/20450916545437-How-to-sign-a-document';
    };

    const luminance = useMemo(() => {
        const hex = branding?.backgroundColor ?? '#000000';
        var r = parseInt(hex.substr(1, 2), 16),
            g = parseInt(hex.substr(3, 2), 16),
            b = parseInt(hex.substr(5, 2), 16);

        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    }, [branding]);

    const luminanceAboveThrehold = luminance > 120;
    const headerClassName = `signing-header ${
        isSigningDoneLeadGen ? 'signing-header-leadgen' : ''
    }`;

    return (
        <div
            className={headerClassName}
            style={{
                ...(branding &&
                    checkColorSchemeValid(branding) && {
                        backgroundColor: branding?.backgroundColor,
                    }),
            }}>
            <div className="signing-header-content">
                <a
                    {...linkProps}
                    className="signing-header-logo-link"
                    href={branding?.siteUrl ?? 'https://penneo.com'}>
                    <img
                        src={
                            branding?.logoUrl ??
                            `${images}/logo-header-white.png`
                        }
                        className="signing-header-logo"
                    />
                </a>
                <div className="signing-header-links">
                    {challengeKey && currentDocId && (
                        <>
                            <a
                                {...linkProps}
                                href={pdfUrl}
                                download={`document-${currentDocId}.pdf`}
                                onClick={() =>
                                    trackSigning('Download PDF button click', {
                                        documentId: currentDocId,
                                    })
                                }>
                                <i className="far fa-arrow-alt-to-bottom"></i>
                                <span>{i18n`Download PDF`}</span>
                            </a>
                            <a
                                {...linkProps}
                                href={pdfUrl}
                                onClick={() =>
                                    trackSigning('View PDF button click', {
                                        documentId: currentDocId,
                                    })
                                }>
                                <i className="far fa-expand-arrows-alt"></i>
                                <span>{i18n`View PDF`}</span>
                            </a>
                            <a
                                {...linkProps}
                                href={getHelpLink()}
                                onClick={() =>
                                    trackSigning('Support link click')
                                }>
                                <i className="far fa-question-circle"></i>
                                <span>{i18n`Support`}</span>
                            </a>
                        </>
                    )}

                    <select
                        onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>
                        ) => changeLanguage(event.target.value as Languages)}
                        className={
                            luminanceAboveThrehold
                                ? 'language-dropdown dark'
                                : 'language-dropdown'
                        }
                        defaultValue={currentLanguage}>
                        {Object.entries(LANGUAGES).map(([key, value]) => (
                            <option key={value.id} value={key}>
                                {value.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};
