import { i18n } from 'Language/i18n';
import React, { useEffect } from 'react';
import AuthenticationCodeForm from './AuthenticationCodeForm';
import SMSErrorBoundary from './SMSErrorBoundary';
import SendAuthenticationCode from './SendAuthenticationCode';
import { dispatch, ReduxState } from 'Store';
import { useSelector } from 'react-redux';
import {
    rejectSigning,
    setChallengeKey,
    setError,
    setShowConfirmModal,
} from './redux/reducer';
import ModalConfirm from 'Casefiles/Archive/ModalConfirm';
import { SMSError } from './types';
import analytics from '../../../Common/Analytics';

export default function SMSValidationContainer({
    maskedPhoneNumber,
    challengeKey,
}) {
    const { error, codeSent, showConfirmModal } = useSelector(
        (state: ReduxState) => state?.smsValidation
    );

    useEffect(() => {
        dispatch(setChallengeKey(challengeKey));
    });

    const handleReject = () => {
        if (!challengeKey) {
            return dispatch(
                setError({ message: 'Invalid challengeKey' } as SMSError &
                    Error)
            );
        }

        analytics.track('SMS - Click on this is not my number "Confirm"');

        rejectSigning(challengeKey);
    };

    return (
        <SMSErrorBoundary error={error}>
            <>
                {showConfirmModal ? (
                    <ModalConfirm
                        message={i18n('Not your number?')}
                        helperMessage={
                            <>
                                {i18n(
                                    "By confirming, the sender will be notified via email that you've rejected to sign the documents due to the phone number not being yours."
                                )}
                                <br />
                                <br />
                                {i18n(
                                    'Please contact them directly for further information.'
                                )}
                            </>
                        }
                        onCancel={() => dispatch(setShowConfirmModal(false))}
                        onOk={handleReject}
                    />
                ) : (
                    <div className="validation-container phone-text-verfication max-w-md m-auto">
                        <h2 className="text-2xl font-bold">{i18n`The sender requests you to confirm your identity`}</h2>

                        {!codeSent ? (
                            <SendAuthenticationCode
                                maskedPhoneNumber={maskedPhoneNumber}
                            />
                        ) : (
                            <AuthenticationCodeForm />
                        )}
                    </div>
                )}
            </>
        </SMSErrorBoundary>
    );
}
