import { Languages } from 'Language/Constants';
import createReducer from 'create-reducer';
import produce from 'immer';
import { validateEmailTemplate } from 'Casefiles/utils/casefileValidation';

// Types
import { TemplateType } from 'types/EmailTemplates';
import {
    NewKYC,
    KYCInstance,
    KYCState,
    KYCData,
    KYCAttachments,
    KYCRecipient,
    KYCSender,
    KYCRequirements,
    KYCActionStates,
    FieldTypes,
} from './types';

// Action Types
export const KYC_RESET_STATE = 'KYC_RESET_STATE';
export const KYC_CLEAR_ERROR = 'KYC_CLEAR_ERROR';
export const KYC_CLEAR_IS_SENDING = 'KYC_CLEAR_IS_SENDING';
export const KYC_SEND_REQUEST = 'KYC_SEND_REQUEST';
export const KYC_SEND_SUCCESS = 'KYC_SEND_SUCCESS';
export const KYC_SEND_FAILURE = 'KYC_SEND_FAILURE';
export const KYC_TITLE_UPDATED = 'KYC_TITLE_UPDATED';
export const KYC_DESCRIPTION_UPDATED = 'KYC_DESCRIPTION_UPDATED';
export const KYC_FOLDER_UPDATED = 'KYC_FOLDER_UPDATED';
export const KYC_REMINDER_INTERVAL_UPDATED = 'KYC_REMINDER_INTERVAL_UPDATED';
export const KYC_LANGUAGE_UPDATED = 'KYC_LANGUAGE_UPDATED';
export const KYC_RECIPIENT_ADDED = 'KYC_RECIPIENT_ADDED';
export const KYC_RECIPIENT_UPDATED = 'KYC_RECIPIENT_UPDATED';
export const KYC_RECIPIENT_REMOVED = 'KYC_RECIPIENT_REMOVED';
export const KYC_EMAIL_TEMPLATE_UPDATED = 'KYC_EMAIL_TEMPLATE_UPDATED';
export const KYC_DATA_REQUIREMENT_UPDATE = 'KYC_DATA_REQUIREMENT_UPDATE';
export const KYC_DATA_UPDATE = 'KYC_DATA_UPDATE';
export const KYC_DATA_VALIDATED = 'KYC_DATA_VALIDATED';
export const KYC_ATTACHMENT_UPDATE = 'KYC_ATTACHMENT_UPDATE';
export const KYC_SUBMIT_REQUEST = 'KYC_SUBMIT_REQUEST';
export const KYC_SUBMIT_FAILURE = 'KYC_SUBMIT_FAILURE';
export const KYC_FETCH_REQUEST = 'KYC_FETCH_REQUEST';
export const KYC_FETCH_SUCCESS = 'KYC_FETCH_SUCCESS';
export const KYC_FETCH_FAILURE = 'KYC_FETCH_FAILURE';

export const KYC_FETCH_LIST_SUCCESS = 'KYC_FETCH_LIST_SUCCESS';
export const KYC_FETCH_LIST_REQUEST = 'KYC_FETCH_LIST_REQUEST';
export const KYC_FETCH_LIST_FAILURE = 'KYC_FETCH_LIST_FAILURE';

export const KYC_FETCH_ITEM_REQUEST = 'KYC_FETCH_ITEM_REQUEST';
export const KYC_FETCH_ITEM_SUCCESS = 'KYC_FETCH_ITEM_SUCCESS';
export const KYC_FETCH_ITEM_FAILURE = 'KYC_FETCH_ITEM_FAILURE';

export const KYC_DELETE_SUCCESS = 'KYC_DELETE_SUCCESS';
export const KYC_DELETE_REQUEST = 'KYC_DELETE_REQUEST';
export const KYC_DELETE_FAILURE = 'KYC_DELETE_FAILURE';

// Initial state
export const kycInitialState: KYCState = {
    __new: {
        title: '',
        description: '',
        language: Languages.EN,
        folderId: null,
        reminderInterval: 2,
        recipients: [] as KYCRecipient[],
        requirements: {
            ssn: {
                type: FieldTypes.TEXT,
                enabled: true,
                required: true,
                isValid: false,
            },
            address: {},
            phone: {},
            passport: {},
            driverLicense: {},
            ssnCard: {},
        } as KYCRequirements,
        emailTemplates: {
            [TemplateType.INITIAL]: {
                id: null,
                title: null,
                subject: '',
                message: '',
                custom: false,
                default: false,
            },
            [TemplateType.REMINDER]: {
                id: null,
                title: null,
                subject: '',
                message: '',
                custom: false,
                default: false,
            },
        },
        validation: {
            emailTemplates: {
                [TemplateType.INITIAL]: {
                    valid: false,
                    errors: {},
                },
                [TemplateType.REMINDER]: {
                    valid: false,
                    errors: {},
                },
            },
        },
    } as NewKYC,
    instance: {
        title: '',
        description: '',
        language: '',
        recipient: {
            name: '',
            email: '',
        } as KYCRecipient,
        requirements: {
            ssn: {},
            address: {},
            phone: {},
            passport: {},
            driverLicense: {},
            ssnCard: {},
        } as KYCRequirements,
        data: {
            ssn: {
                value: '',
            },
            address: {
                value: '',
            },
            phone: {
                value: '',
            },
        } as KYCData,
        attachments: {
            passport: {
                value: null,
            },
            driverLicense: {
                value: null,
            },
            ssnCard: {
                value: null,
            },
        } as KYCAttachments,
        sender: {} as KYCSender,
    } as KYCInstance,
    items: {
        data: [],
        count: 0,
        isFetching: false,
        isLoaded: false,
        error: null,
    },
    __states: {
        isLoaded: false,
        isFetching: false,
        isSending: false,
        sent: false,
        error: null,
    } as KYCActionStates,
};

// Reducer

export const kycReducer = createReducer<KYCState>(kycInitialState, {
    [KYC_RESET_STATE]: () => ({
        ...kycInitialState,
    }),
    [KYC_CLEAR_ERROR]: (state) =>
        produce(state, (draftState) => {
            draftState.__states.error = null;
            draftState.items.error = null;
        }),
    [KYC_CLEAR_IS_SENDING]: (state) =>
        produce(state, (draftState) => {
            draftState.__states.isSending = false;
        }),
    [KYC_SEND_REQUEST]: (state) =>
        produce(state, (draftState) => {
            draftState.__states.isSending = true;
        }),
    [KYC_SEND_SUCCESS]: (state) =>
        produce(state, (draftState) => {
            draftState.__states.sent = true;
        }),
    [KYC_SEND_FAILURE]: (state, { error }) =>
        produce(state, (draftState) => {
            draftState.__states.error = error;
        }),
    [KYC_TITLE_UPDATED]: (state, { title }) =>
        produce(state, (draftState) => {
            draftState.__new.title = title;
        }),
    [KYC_LANGUAGE_UPDATED]: (state, { language }) =>
        produce(state, (draftState) => {
            draftState.__new.language = language;
        }),
    [KYC_DESCRIPTION_UPDATED]: (state, { description }) =>
        produce(state, (draftState) => {
            draftState.__new.description = description;
        }),
    [KYC_EMAIL_TEMPLATE_UPDATED]: (state, { type, template }) =>
        produce(state, (draftState) => {
            draftState.__new.emailTemplates[type] = template;
            draftState.__new.validation.emailTemplates[
                type
            ] = validateEmailTemplate(template);
        }),
    [KYC_FOLDER_UPDATED]: (state, { folderId }) =>
        produce(state, (draftState) => {
            draftState.__new.folderId = folderId;
        }),
    [KYC_REMINDER_INTERVAL_UPDATED]: (state, { reminderInterval }) =>
        produce(state, (draftState) => {
            draftState.__new.reminderInterval = reminderInterval;
        }),
    [KYC_RECIPIENT_ADDED]: (state, { recipient }) =>
        produce(state, (draftState) => {
            draftState.__new.recipients.push(recipient);
        }),
    [KYC_RECIPIENT_UPDATED]: (state, { recipient, index }) =>
        produce(state, (draftState) => {
            draftState.__new.recipients[index] = recipient;
        }),
    [KYC_RECIPIENT_REMOVED]: (state, { index }) =>
        produce(state, (draftState) => {
            draftState.__new.recipients.splice(index, 1);
        }),
    [KYC_DATA_REQUIREMENT_UPDATE]: (state, { name, data }) =>
        produce(state, (draftState) => {
            const { enabled, required, type } = data;

            if (enabled === false) {
                draftState.__new.requirements[name] = {
                    type,
                    enabled: false,
                    required: false,
                };

                return;
            }

            if (required === true) {
                draftState.__new.requirements[name] = {
                    type,
                    enabled: true,
                    required: true,
                };

                return;
            }

            const prevData = draftState.__new.requirements[name];

            draftState.__new.requirements[name] = {
                ...prevData,
                ...data,
            };
        }),
    [KYC_FETCH_REQUEST]: (state) =>
        produce(state, (draftState) => {
            draftState.__states.isLoaded = false;
            draftState.__states.isFetching = true;
        }),
    [KYC_FETCH_SUCCESS]: (state, kycData) =>
        produce(state, (draftState) => {
            draftState.instance = {
                ...state.instance,
                ...kycData,
            };

            // Convert fields to requirements object
            const requirements = kycData.fields.reduce((result, field) => {
                result[field.name] = {
                    type: field.type,
                    required: field.required,
                    enabled: true,
                };

                return result;
            }, {});

            draftState.instance.requirements = {
                ...state.instance.requirements,
                ...requirements,
            };

            draftState.__states.isLoaded = true;
            draftState.__states.isFetching = false;
        }),
    [KYC_FETCH_FAILURE]: (state, { error }) =>
        produce(state, (draftState) => {
            draftState.__states.isLoaded = true;
            draftState.__states.isFetching = false;
            draftState.__states.error = error;
        }),
    [KYC_DATA_UPDATE]: (state, { name, value }) =>
        produce(state, (draftState) => {
            draftState.instance.data[name].value = value;
        }),
    [KYC_DATA_VALIDATED]: (state, { name, isValid }) =>
        produce(state, (draftState) => {
            draftState.instance.data[name].isValid = isValid;
        }),
    [KYC_SUBMIT_REQUEST]: (state) =>
        produce(state, (draftState) => {
            draftState.__states.isSending = true;
        }),
    [KYC_SUBMIT_FAILURE]: (state, { error }) =>
        produce(state, (draftState) => {
            draftState.__states.error = error;
        }),
    [KYC_ATTACHMENT_UPDATE]: (state, { name, value }) =>
        produce(state, (draftState) => {
            draftState.instance.attachments[name].value = value;
        }),
    [KYC_FETCH_LIST_REQUEST]: (state) =>
        produce(state, (draftState) => {
            draftState.items.isFetching = true;
        }),
    [KYC_FETCH_LIST_SUCCESS]: (state, { items, itemCount }) =>
        produce(state, (draftState) => {
            draftState.items.count = itemCount;
            draftState.items.data = items;
            draftState.items.isFetching = false;
            draftState.items.isLoaded = true;
            draftState.items.error = null;
        }),
    [KYC_FETCH_LIST_FAILURE]: (state, { error }) =>
        produce(state, (draftState) => {
            draftState.items.isFetching = false;
            draftState.items.isLoaded = true;
            draftState.items.error = error;
        }),
    [KYC_FETCH_ITEM_REQUEST]: (state, cachedItem) =>
        produce(state, (draftState) => {
            if (cachedItem) {
                draftState.items.isLoaded = true;
                draftState.items.isFetching = false;

                return;
            }

            draftState.items.isLoaded = false;
            draftState.items.isFetching = true;
        }),
    [KYC_FETCH_ITEM_SUCCESS]: (state, kyc) =>
        produce(state, (draftState) => {
            const itemIndex = draftState.items.data.findIndex(
                (item) => item.id === kyc.id
            );

            if (~itemIndex) {
                draftState.items.data[itemIndex] = kyc;
            } else {
                draftState.items.data.push(kyc);
            }

            draftState.items.isFetching = false;
            draftState.items.isLoaded = true;
        }),
    [KYC_FETCH_ITEM_FAILURE]: (state, { error }) =>
        produce(state, (draftState) => {
            draftState.items.isFetching = false;
            draftState.items.isLoaded = true;
            draftState.items.error = error;
        }),
});

export default kycReducer;
