import { Dispatcher, BaseStore } from 'Core';
import Constants from '../Constants';
import assign from 'object-assign';

// Data Storage
let _branding = null;

function updateBranding(branding) {
    if (!branding) {
        return false;
    }

    _branding = branding;
}

// Flux Store Creation
const BrandingStore = assign({}, BaseStore, {
    getBranding() {
        return _branding;
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.BRANDING_UPDATED:
                updateBranding(action.branding);
                break;
            default:
                return false;
        }

        BrandingStore.emitChange();
    }),
});

export default BrandingStore;
