import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import Loader from 'Common/components/Common/Loader';
import Button from 'Common/components/Button';
import { images } from 'Constants';
import { i18n } from 'Language';

export default createReactClass({
    containerId: 'bankidno-widget-container',
    script: null,

    settings: {
        width: '100%',
        height: '400px',
        framemode: 'iframe',
    },

    propTypes: {
        params: PropTypes.object,
        error: PropTypes.object,
        retry: PropTypes.func,
        init: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired,
        updateError: PropTypes.func.isRequired,
        isFetching: PropTypes.bool,
        login: PropTypes.bool,
    },

    getInitialState() {
        return {
            login: false,
            helperReady: false,
        };
    },

    // Load parameters
    componentDidMount() {
        this.props.init();
    },

    // Clear BankID Script
    componentWillUnmount() {
        this.removeHelperScript();
    },

    // Listen to parameters change to start/reload helper.
    componentDidUpdate(prevProps) {
        if (prevProps.params !== this.props.params) {
            this.loadHelper();
        }
    },

    initHelper(clientId) {
        // Update state to render frame container.
        this.setState({ helperReady: true });

        // Initialize BankID frame
        window.bankidhelper.init({
            cid: clientId,
            containerID: this.containerId,
            width: this.settings.width,
            height: this.settings.height,
            framemode: this.settings.frameMode,
            callback: this.handleResponse,
        });
    },

    loadHelper() {
        let { params } = this.props;

        if (!params) {
            return false;
        }

        let { clientId, helperUri } = params;

        if (this.script || window.bankidhelper) {
            return this.initHelper(clientId);
        }

        this.appendHelperScript(clientId, helperUri);
    },

    // Adds helper script tag to DOM
    appendHelperScript(clientId, helperUri) {
        if (!helperUri || !clientId) {
            return false;
        }

        let script = document.createElement('script');

        // Bind the clientId to script loaded callback.
        // @todo: needs onreadystate to support IE.
        script.onload = this.onHelperLoaded.bind(this, clientId);
        script.src = helperUri;

        // Mount script on DOM and assign element reference to local script object
        document.body.appendChild(script);
        this.script = script;
    },

    // Remove helper script tag from DOM
    removeHelperScript() {
        if (!this.script) {
            return false;
        }

        document.body.removeChild(this.script);

        // Clear element reference
        this.script = null;
    },

    onHelperLoaded(clientId) {
        // If bankid helper doesn't exist in global scope, don't initialize.
        if (!window.bankidhelper) {
            return false;
        }

        this.initHelper(clientId);
    },

    /**
     * Callback to handle BankID frame communication
     * @param  {object} error  - errorId: Int
     *                         - error: String
     *                         - errorDetail: String
     *
     * @param  {object} result - status: Int [-1: aborted, 0: failure, 1: success]
     *                         - statusText: String
     */
    handleResponse(error, result) {
        let { params, onSuccess } = this.props;

        if (error) {
            switch (error.errorId) {
                case 2907:
                    return this.updateError(error);
                default:
                    return false;
            }
        }

        switch (result.status) {
            case 1:
                onSuccess(params.signatureRef);
                break;
            case 0:
                this.retry();
                break;
            case -1:
                break;
            default:
                break;
        }
    },

    /**
     * Populates parent prop with BankID Widget Error
     * Used exclusively for cases where the Widget is stuck in a state where
     * there's no retry button built into the iframe application.
     */
    updateError(bankIDError) {
        let { errorId, error, data } = bankIDError;

        let status = {
            status: errorId,
            statusText: `${error} ${data}`,
        };

        this.props.updateError(status);
    },

    retry() {
        this.props.retry();
    },

    render() {
        let { error } = this.props;
        let loaded = !this.props.isFetching && this.state.helperReady;

        return (
            <div className="bankid-no-widget">
                {!error && (
                    <div
                        key="bankid-widget"
                        id={this.containerId}
                        className={!loaded ? 'loading' : ''}
                        style={{ height: this.settings.height }}>
                        {!loaded && (
                            <div className="loading-container">
                                <Loader type="dots" inline={true} />
                            </div>
                        )}
                    </div>
                )}

                {error && (
                    <div
                        key="bankid-error"
                        className="bankid-no-error text-center"
                        style={{ height: this.settings.height }}>
                        <div className="header">
                            <img
                                alt="BankID logo"
                                title="BankID logo"
                                src={`${images}/auth/bankid-gray-logo.svg`}
                                data-svg="bankid_logo"
                            />
                            <span className="title">{i18n`error`}</span>
                        </div>

                        <div className="content">
                            {i18n`There was an error while processing your request.`}
                            <br />
                            {i18n`Please try again or contact support`}
                            <br />
                            <div className="bankid-no-buttons">
                                <Button onClick={this.retry} icon="far fa-sync">
                                    {i18n`Try again`}
                                </Button>
                            </div>
                        </div>

                        <div className="footer">
                            <div className="error-code">
                                <span className="title">Error code:</span>
                                <span className="text">
                                    {error.status} ({error.statusText})
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    },
});
