import React from 'react';
import { TextInput } from 'Common/components';
import Loader from 'Common/components/Loaders/LoadingData';
import { CaseFileItem } from '../';
import Tree from './TreeSelection';
import transformToNestedChildren, {
    TransformToNestedChildren,
} from './TreeSelection/transform-to-nested-children';
import { fromPrefixToObject } from '../utils';
import { i18n } from 'Language';
import { SigningAPI } from 'Api';
import { Type } from '../Archive';
import { FoldersPayload } from '../redux/reducer';
import { debug } from 'Core';
import './move.scss';
import Button from 'Common/components/Button';

/**
 * In case of the top level folders (those that have no parent folder) the `oldFolderId` is undefined.
 */
export type OnMove = (
    newFolderId: number,
    oldFolderId: number | undefined,
    caseFilesIds: number[],
    folders: FoldersPayload
) => void;

type Props = {
    onCancel: () => any;
    selectedItemsIds: {};
    items: CaseFileItem[];
    currentFolderId?: number;
    onMove: OnMove;
};

type State = {
    selectedTitle: string | null;
    items: TransformToNestedChildren[];
    search: string;
    isLoading: boolean;
    selectedFolderId: number | null;
};

class Move extends React.Component<Props, State> {
    allItems: TransformToNestedChildren[] = [];

    state = {
        search: '',
        selectedTitle: null,
        items: [],
        isLoading: true,
        selectedFolderId: null,
    };

    componentDidMount() {
        window.addEventListener('keyup', this.handleKeyUp);
        this.fetchData();
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp);
    }

    handleKeyUp = (e: KeyboardEvent) => {
        const key = e.keyCode || e.which;

        if (key === 27) {
            this.props.onCancel();
        }
    };

    handleOnSelect = (item: TransformToNestedChildren) =>
        this.setState({
            selectedTitle: item.title,
            selectedFolderId: item.id,
        });

    fetchData = async () => {
        try {
            const data: CaseFileItem[] = await SigningAPI.get('/folders', {
                permissions: 'read-write',
            });
            const items = transformToNestedChildren(data);

            this.allItems = items;

            this.setState({
                items,
                isLoading: false,
            });
        } catch (e) {
            // TODO: notify the user that we're not able to load the data.
            debug.log('Error', e);
        }
    };

    handleMoveItems = () => {
        const { selectedItemsIds, currentFolderId } = this.props;
        const { selectedFolderId } = this.state;

        const selectedItemsToMove = Object.keys(selectedItemsIds)
            .filter((x) => selectedItemsIds[x])
            .map((x) => x)
            .map(fromPrefixToObject);

        const folderIds = selectedItemsToMove
            .filter((selectedItem) => selectedItem.type === Type.Folder)
            .reduce((obj, item) => {
                obj[item.id] = true;

                return obj;
            }, {});

        const caseFileIds = selectedItemsToMove
            .filter((selectedItem) => selectedItem.type === Type.CaseFile)
            .map((x) => x.id);

        const foldersPayload: any[] = [];

        this.props.items.forEach((item) => {
            if (folderIds[item.id]) {
                foldersPayload.push({
                    id: item.id,
                    parentId: selectedFolderId,
                });
            }
        });

        if (selectedFolderId !== null) {
            this.props.onMove(
                selectedFolderId as any,
                currentFolderId,
                caseFileIds,
                foldersPayload
            );
        }
    };

    handleSearch = (search: string) => {
        const items = this.allItems.filter(function caseFilesFilter(
            item: TransformToNestedChildren
        ) {
            if (
                item.title
                    .toLocaleLowerCase()
                    .indexOf(search.toLocaleLowerCase()) > -1
            ) {
                return true;
            }

            if (item.children && item.children.length) {
                return item.children.filter(caseFilesFilter).length;
            }

            return false;
        });

        this.setState({
            search,
            items,
        });
    };

    render() {
        const {
            items,
            search,
            selectedTitle,
            isLoading,
            selectedFolderId,
        } = this.state;

        return (
            <div className="move">
                <Button
                    className="float-right mt-12 mr-12"
                    theme="blue"
                    variant="text"
                    onClick={this.props.onCancel}>
                    Back
                </Button>

                <div className="container">
                    <div className="move-header">
                        <div className="title-holder">
                            <span className="title">
                                {i18n('Move to')}
                                {selectedTitle ? (
                                    <span className="selected-title">
                                        {' '}
                                        {selectedTitle}
                                    </span>
                                ) : (
                                    '...'
                                )}
                            </span>
                            <Button
                                theme="green"
                                onClick={this.handleMoveItems}
                                disabled={!selectedFolderId}>
                                {i18n('Move')}
                            </Button>
                        </div>
                        <TextInput
                            value={search}
                            placeholder={i18n('Search')}
                            onChange={this.handleSearch}
                        />
                    </div>
                    <div className="move-tree-holder">
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <Tree
                                dataSource={items}
                                selectedFolderId={this.state.selectedFolderId}
                                onSelectFolder={this.handleOnSelect}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Move;
