import PropTypes from 'prop-types';
import React from 'react';

import { modal } from 'Common/components/Common/Modal';
import ProfilesModal from 'Common/components/ProfileSelector/ProfilesModal';

export class ProfileSelectorDropDown extends React.Component {
    static propTypes = {
        children: PropTypes.object,
        className: PropTypes.string,
        user: PropTypes.object.isRequired,
    };

    state = {
        profiles: null,
    };

    open() {
        const { user } = this.props;

        let config = {
            component: <ProfilesModal user={user} />,
            preventClose: false,
        };

        modal.show(config);
    }

    render() {
        return (
            <span onClick={() => this.open()} className={this.props.className}>
                {this.props.children}
            </span>
        );
    }
}
