import React from 'react';
import { i18n } from 'Language';

import CasefileActions from 'Casefiles/actions/CasefilesActionCreators';
import SignerActions from 'Casefiles/actions/SignerActionCreators';

import { modal } from 'Common/components/Common/Modal';
import Button from 'Common/components/Button';

export default class CopyRecipientWarningModal extends React.Component {
    render() {
        return (
            <div className="casefile-modal-v2">
                <h3 className="casefile-modal-v2-title">
                    {i18n`Activate Sensitive Information`}

                    <div className="close" onClick={modal.hide}>
                        <div className="far fa-times"></div>
                    </div>
                </h3>

                <div className="form-v2">
                    <p>
                        {i18n`You cannot activate sensitive data for this case file if
                             you have added copy recipients.`}
                    </p>

                    <p>
                        {i18n`You can automatically remove copy recipients and activate this
                             feature, or cancel and continue without sensitive data`}
                    </p>

                    <div className="footer-content text-right form-v2">
                        <br />
                        <Button
                            theme="green"
                            className="pull-left"
                            onClick={() => {
                                CasefileActions.removeCopyRecipients();

                                SignerActions.removeCopyRecipientsV2();
                                CasefileActions.setSensitiveData(true);
                                modal.hide();
                            }}>
                            {i18n`Remove and Activate`}
                        </Button>

                        <Button onClick={modal.hide}>{i18n`Cancel`}</Button>
                    </div>
                </div>
            </div>
        );
    }
}
