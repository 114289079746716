export enum ActionTypes {
    CONTACTS_FETCH_REQUEST = 'CONTACTS_FETCH_REQUEST',
    CONTACTS_FETCH_SUCCESS = 'CONTACTS_FETCH_SUCCESS',
    CONTACTS_FETCH_FAILURE = 'CONTACTS_FETCH_FAILURE',
    CONTACT_LIST_PAGINATION_CHANGE = 'CONTACT_LIST_PAGINATION_CHANGE',
    CONTACT_LIST_TOGGLE_ROW_EDIT = 'CONTACT_LIST_TOGGLE_ROW_EDIT',
    CONTACT_LIST_DISABLE_ROW_EDIT = 'CONTACT_LIST_DISABLE_ROW_EDIT',
    CONTACT_DRAFT_UPDATED = 'CONTACT_DRAFT_UPDATED',
    CONTACT_UPDATE_REQUEST = 'CONTACT_UPDATE_REQUEST',
    CONTACT_UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS',
    CONTACT_UPDATE_FAILURE = 'CONTACT_UPDATE_FAILURE',
    CONTACT_CREATE_REQUEST = 'CONTACT_CREATE_REQUEST',
    CONTACT_CREATE_SUCCESS = 'CONTACT_CREATE_SUCCESS',
    CONTACT_CREATE_FAILURE = 'CONTACT_CREATE_FAILURE',
    CONTACT_DELETE_REQUEST = 'CONTACT_DELETE_REQUEST',
    CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS',
    CONTACT_DELETE_FAILURE = 'CONTACT_DELETE_FAILURE',
}
