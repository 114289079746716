/* eslint camelcase: 0 */
import { OIDs } from 'OpenID/Constants';
import {
    OpenIDObj,
    OpenIDCertificate,
    OpenIDToken,
    OpenIDSubjectIdentifiers,
} from 'OpenID/redux/types';

export type ItsmeToken = OpenIDToken & {
    iss: string;
    acr: string;
    name: string;
    family_name: string;
    given_name: string;
    nonce: string;
    'http://itsme.services/v2/claim/BENationalNumber': string;
};

export type ItsmeSubjectIdentifiers = OpenIDSubjectIdentifiers & {
    [OIDs.OPENID_FULL_NAME_V2]: string;
};

export type ItsmeCertificate = OpenIDCertificate<ItsmeSubjectIdentifiers>;

export const itsme: OpenIDObj<ItsmeToken, ItsmeCertificate> = {
    issuers: [
        'https://idp.e2e.itsme.services/v2',
        'https://idp.prd.itsme.services/v2',
    ],
    getSubject(token) {
        return {
            [OIDs.OPENID_ISSUER]: token.iss,
            [OIDs.OPENID_ID]: token.sub,
            [OIDs.OPENID_FULL_NAME_V2]: token.name,
        };
    },
    isOwnToken(token): token is ItsmeToken {
        // We leverage the Itsme specific field to be extra sure
        return (
            this.issuers.includes(token.iss) &&
            !!token['http://itsme.services/v2/claim/BENationalNumber']
        );
    },
    isOwnCertificate(certificate): certificate is ItsmeCertificate {
        const { subject } = certificate;

        return (
            this.issuers.includes(subject[OIDs.OPENID_ISSUER]) &&
            !!subject[OIDs.OPENID_FULL_NAME_V2]
        );
    },
    getCertificateDisplayInfo(certificate) {
        return {
            title: certificate.subject[OIDs.OPENID_FULL_NAME_V2],
            issuer: certificate.issuer[OIDs.OPENID_ORG_NAME_V2],
        };
    },
};
