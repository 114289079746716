/* eslint camelcase:0 */
import { Dispatcher } from 'Core';
import Constants from '../Constants';
import { WorkflowAPI, SigningAPI } from 'Api';

const WorkflowDetailActionCreators = {
    loadAll(params) {
        let options = { raw: true };
        let query;

        // If parameters are set, apply pagination settings
        if (params) {
            options = {
                paginate: true,
                raw: true,
            };
            query = {
                per_page: params.pageSize,
            };
        }

        WorkflowAPI.get('/v2/workflows/all/details', query, options)
            .then((response) => {
                // response.headers.match(/page=(\d+).*$/)[1]

                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.DETAIL_LIST_LOADED,
                    workflows: response.data,
                    nextLink: response,
                    previousLink: response,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    },

    loadById(workflowId) {
        if (!workflowId) {
            return false;
        }

        WorkflowAPI.get('/v2/workflows/' + workflowId + '/details')
            .then((workflow) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.DETAIL_WORKFLOW_LOADED,
                    workflow: workflow,
                });
            })
            .catch((error) => {
                console.log(error);
                // throw new Error(error);
            });
    },

    /**
     * Loads list of statuses based on array of casefile ID's.
     * @param casefileIds {Array} Array of Casefile IDs (i.e: [1, 2, 3, 4]).
     */
    loadCasefileStatuses(caseFileIds) {
        if (!caseFileIds) {
            return false;
        }

        let data = {
            caseFileIds: caseFileIds,
        };

        SigningAPI.post('/v2/casefiles/selected/statuses', data)
            .then((statuses) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.DETAIL_CASEFILE_STATUSES_LOADED,
                    statuses: statuses,
                });
            })
            .catch((error) => {
                console.log(error);
                // throw new Error(error);
            });
    },

    updateFilterQuery(filter) {
        Dispatcher.handleViewAction({
            type: 'FILTER_UPDATED',
            data: filter,
        });
    },

    skipRefresh() {
        Dispatcher.handleViewAction({
            type: 'SKIP_REFRESH',
        });
    },

    updateFormattedData(data) {
        Dispatcher.handleViewAction({
            type: 'FORMATTED_DATA_LOADED',
            data: data,
        });
    },
};

export default WorkflowDetailActionCreators;
