import React from 'react';
import PropTypes from 'prop-types';
import Store from '../../stores/TouchStore';
import TouchActions from '../../actions/TouchActions';
import VisualSignature from 'Common/components/VisualSignature';
import Loader from 'Common/components/Common/Loader';
import { TouchActions as Actions } from '../../ActionTypes';
import { i18n } from 'Language';
import Button from 'Common/components/Button';
import analytics from 'Common/Analytics';

export default class TouchContainer extends React.Component {
    static propTypes = {
        challengeKey: PropTypes.string.isRequired,
        languageCode: PropTypes.string,
    };

    state = {
        signText: null,
        base64image: null,
        loading: false,
        error: null,
        allowedInsecureSigningMethods: ['draw', 'image', 'text'],
    };

    componentDidMount = () => {
        Store.addChangeListener(this.onChange);
        Store.addEventListener(Actions.SIGN_SUCCESS, this.onSuccess);
        this.loadData();
    };

    componentWillUnmount = () => {
        Store.removeChangeListener(this.onChange);
        Store.removeEventListener(Actions.SIGN_SUCCESS, this.onSuccess);

        TouchActions.clearStore();
    };

    loadData = () => {
        let { challengeKey, languageCode } = this.props;

        TouchActions.init(challengeKey, languageCode);
    };

    onChange = () => {
        this.setState({
            signText: Store.getSignText(),
            allowedInsecureSigningMethods: Store.getAllowedSigningMethods(),
            loading: Store.isFetching(),
            base64image: Store.getSignatureImage(),
            error: Store.getError(),
        });
    };

    onSuccess = () => {
        this.setState({
            success: true,
        });
    };

    updateSignatureImage = (base64image) => {
        TouchActions.updateSignatureImage(base64image);
    };

    retry = () => {
        TouchActions.retry();
        this.loadData();
    };

    save = () => {
        analytics.track('Touch signature - Click "Sign Documents" button');
        TouchActions.sign(this.props.challengeKey, this.state.base64image);
    };

    render() {
        let { loading, signText, success, error, base64image } = this.state;
        let isLoaded = signText && !loading && !error;

        return (
            <div className="touch-container">
                <div className="wrapper">
                    {/* This section is temporarily disabled because the header exists in
                        the symfony application */}
                    {/* <div className="title">
                        Penneo touch signature
                        <div className="subtitle">
                            By signing you agree to the following terms:
                        </div>
                    </div>*/}

                    <div className="widget-container form-v2">
                        {error && (
                            <div className="error-message">
                                <div>
                                    {i18n`An error ocurred, please, try again.`}
                                    <br />
                                    <br />
                                    <Button
                                        onClick={
                                            this.retry
                                        }>{i18n`Retry`}</Button>
                                </div>
                            </div>
                        )}
                        {success && !error && (
                            <div className="signing-successful">
                                <h2>{i18n`Signing Successful`}!</h2>
                            </div>
                        )}

                        {isLoaded && !success && (
                            <div className="widget-content">
                                <div className="sign-text">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: signText,
                                        }}
                                    />
                                </div>

                                <VisualSignature
                                    availableMethods={
                                        this.state.allowedInsecureSigningMethods
                                    }
                                    onChange={this.updateSignatureImage}
                                />

                                <div className="controls">
                                    <Button
                                        theme="green"
                                        disabled={!base64image}
                                        onClick={this.save}>
                                        {i18n`Sign Documents`}
                                    </Button>
                                </div>
                            </div>
                        )}

                        {!isLoaded && !error && <Loader />}
                    </div>
                </div>
            </div>
        );
    }
}
