import { Dispatcher, BaseStore } from 'Core';
import { debug } from 'Core';
import assign from 'object-assign';

let _users = {};
let _currentUser = null;

let __status = null; // ['request', 'success', 'failure'], depending on the state of API requests

function storeUser(user) {
    _users[user.id] = user;
}

function storeUserRole(userId, role) {
    _users[userId].role = role;
    _users = assign({}, _users);
}

function updatePreferences(preferences) {
    _currentUser.preferences = preferences;
}

function _updateStatus(status) {
    __status = status;
}

// Flux Store Creation
const UserStore = assign({}, BaseStore, {
    getUser(userId) {
        return _users[userId];
    },

    getUsers() {
        return _users;
    },

    getCurrentUser() {
        return _currentUser;
    },

    isAdmin() {
        if (!_currentUser) {
            return false;
        }

        return _currentUser.role === 'administrator';
    },

    isCurrentUser(userId) {
        return _currentUser.id === userId;
    },

    getStatus() {
        return __status;
    },

    resetStatus() {
        __status = null;
        UserStore.emitChange();
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case 'USER_LOADED':
                storeUser(action.user);
                break;
            case 'CURRENT_USER_LOADED':
                _currentUser = action.user;
                storeUser(action.user);
                break;
            case 'USER_ROLE_LOADED':
                storeUserRole(action.userId, action.role);
                break;
            case 'USER_UPDATE_REQUEST':
            case 'USER_UPDATE_FAILURE':
                _updateStatus(action.status);
                break;
            case 'USER_UPDATE_SUCCESS':
                _updateStatus(action.status);
                storeUser(action.user);
                break;
            case 'USER_ROLES_UPDATE_SUCCESS':
                storeUserRole(action.userId, action.role);
                break;
            case 'USER_PREFERENCES_FETCH_SUCCESS':
            case 'USER_PREFERENCES_FETCH_NOTFOUND':
            case 'USER_PREFERENCES_UPDATE_SUCCESS':
                updatePreferences(action.preferences);
                break;
            default:
                return false;
        }
        UserStore.emitChange(action.type);
    }),
});

debug.export('UserStore', UserStore);
export default UserStore;
