import React, { useState } from 'react';
import axios from 'axios';
//OpenID
import { Intent, ServiceIDs } from 'OpenID/Constants';
import { SignText } from 'OpenID/redux/types';
import OpenIDSignText from 'OpenID/components/OpenIDSignText';
import { getUrlId } from 'OpenID/redux/actions';

import Constants from 'Constants';
import { SigningMethod } from 'EID';
//TODO: Use these components to build skeleton...
import Loader from 'Common/components/Common/Loader';
import Button from 'Common/components/Button';
import { i18n } from 'Language';

//Client
import QRSSClient, { QRSSState } from './QRSSClient';

//Redux
import { useSelector } from 'react-redux';
import { ReduxState } from 'Store';

import { useDispatch } from 'react-redux';
import { init } from 'OpenID/redux/actions';
//Eutl
import EutlErrorHandler from './EutlErrorHandler';
const fetchTranslatedIdToken = async (
    idToken: string,
    serviceId: ServiceIDs
) => {
    //Fetch with public api...
    //for now placeholder...
    //
    const service = getUrlId(serviceId, Intent.SIGN);
    const url = `/auth/api/v1/openid/${service}/eutl-id-token`;

    return await axios.post(url, { idToken }).then((response) => {
        const { translated_id_token } = response?.data;

        return translated_id_token;
    });
};

type EutlSignMessagingProps = {
    signText: SignText;
    serviceId: ServiceIDs;
    signatureCallback?: (signatureData: string) => void;
    method: SigningMethod;
};

export const wsUrl = `wss://${Constants.QRSS_PROXY_ENDPOINT}/v0/signing-process`;
const EutlSignMessaging = ({
    signText,
    serviceId,
    signatureCallback,
    method,
}: EutlSignMessagingProps) => {
    const dispatch = useDispatch();
    const idToken = useSelector(
        (state: ReduxState) => state.openId.intent.data.openIdToken
    );
    const [signing, setSigning] = useState(false);
    const [process, setProcess] = useState<string | null>(null);
    const [error, setError] = useState<string>();
    const client = new QRSSClient();
    const errorFactory = (errorMessage: string) => {
        setSigning(false);
        setError(errorMessage);

        return;
    };

    const reauthenticate = () => {
        dispatch(
            init(serviceId, Intent.SIGN, {
                legacyServiceName: serviceId,
            })
        );
    };

    const setSocketMessage = (state: QRSSState) => {
        const message: string =
            {
                [QRSSState.SIGNATURE_ACTIVATION_DATA_REQUESTED]: i18n`Sending activation data`,
                [QRSSState.SIGNATURE_PRODUCED]: i18n`Receiving produced signature`,
            }?.[state] ?? i18n`Processing`;

        setProcess(message);
    };

    const startSigningProcess = async () => {
        let signatureData: string;

        setSigning(true);

        setProcess(i18n`Starting signing process`);

        if (!idToken) {
            errorFactory('No Id Token generated were generated');
        }

        setProcess(i18n`Getting translated token`);
        const translatedIdToken = await fetchTranslatedIdToken(
            idToken as string,
            serviceId
        ).catch(() => {
            errorFactory('Unable to fetch the translated token');
        });

        if (!translatedIdToken) {
            errorFactory('Unable to fetch the translated token');
        }

        try {
            setProcess(i18n`Connecting to signing service`);
            await client.connect(wsUrl);

            setProcess(i18n`Signing document data`);
            signatureData = await client.sign(
                translatedIdToken,
                signText.xml.xmlData,
                setSocketMessage
            );

            return signatureData;
        } catch (e) {
            errorFactory('Error occurred while generating signature');
        }

        return;
    };

    const handleSign = async () => {
        const signatureData = await startSigningProcess();

        if (signatureData) {
            signatureCallback?.(signatureData);
        }
    };

    const renderContent = () => {
        if (!signText || signing) {
            return (
                <div data-testid="loading">
                    <br />
                    <br />
                    <Loader loadingText={process} />
                </div>
            );
        }

        return (
            signText && (
                <>
                    <OpenIDSignText signText={signText} />
                    <Button
                        theme="green"
                        disabled={signing}
                        className="openid-sign-button"
                        size="large"
                        icon={signing ? 'far fa-sync fa-spin' : 'far'}
                        onClick={handleSign}>
                        {i18n`Sign Documents`}
                    </Button>
                    <div className="mt">
                        <a
                            className="openid-sign-link"
                            onClick={reauthenticate}>
                            {i18n`Or use another account`}
                        </a>
                    </div>
                </>
            )
        );
    };

    return (
        <>
            <EutlErrorHandler error={error}>
                <>{renderContent()}</>
            </EutlErrorHandler>
        </>
    );
};

export default EutlSignMessaging;
