import PropTypes from 'prop-types';
import React from 'react';
import ErrorMessage from '../errors/InputErrorMessage';
import { modal } from 'Common/components/Common/Modal.jsx';
import { i18n } from 'Language';
import SignerModal from './SignerModal';
import SignerStore from '../../stores/SignerStore';
import SignerActions from '../../actions/SignerActionCreators';
import Button from 'Common/components/Button';
import { connect } from 'react-redux';
import { insecureSigningMethodsDefault } from 'EID/types';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import Constants from '../../Constants';
import SsnInput from '../../../Common/components/SsnInput';
import classNames from 'classnames';

class SignerCreator extends React.Component {
    static propTypes = {
        index: PropTypes.number,
        signer: PropTypes.object,
        signerRules: PropTypes.object,
        availableSignerTypes: PropTypes.array,
        isCasefileSensitive: PropTypes.bool,
        remove: PropTypes.func,
        update: PropTypes.func,
        inputErrors: PropTypes.object,
        updateSigner: PropTypes.func,
        customer: PropTypes.object,
        showInsecureMethods: PropTypes.bool,
    };

    state = {
        roles: [],
    };

    /**
     * This method is triggered by Flux whenever the current signer role
     * changes. This is whether it's a manual selection in the modal (including this signer's index)
     * or when the user changes the casefile type and signers get
     * automatically updated (regardless of the index prop).
     */
    updateRoles = ({ index, roles }) =>
        (index === this.props.index || index == null) &&
        this.setState({ roles });

    resetRoles = () => this.setState({ roles: [] });

    updateStaleRoles = () => {
        const { role: roles } = this.props.signer;

        this.setState({ roles });
    };

    componentDidMount() {
        const {
            UPDATED_SIGNER_ROLE,
            SIGNER_ROLES_RESET,
            SIGNER_ROLES_UPDATED,
        } = Constants.ActionTypes;

        SignerStore.addEventListener(UPDATED_SIGNER_ROLE, this.updateRoles);

        /**
         * We listen to when the Flux store resets signer roles
         * (which only happens when selecting a new casefile type.)
         * This event doesn't have any parameters, but we don't need
         * to know an exact index, because Flux will reset all of them, regardless.
         */
        SignerStore.addEventListener(SIGNER_ROLES_RESET, this.resetRoles);

        /**
         * If roles are updated in Flux (when changing document type)
         * we update the state with the (potential) new updated roles.
         */
        SignerStore.addEventListener(
            SIGNER_ROLES_UPDATED,
            this.updateStaleRoles
        );

        /**
         * Update the roles on the state if there are already some
         * when we mount the component (fx. when coming from a payload url)
         */
        const { signer } = this.props;

        if (!signer.role?.length) {
            return;
        }

        this.setState({ roles: signer.role });
    }

    componentWillUnmount() {
        const {
            UPDATED_SIGNER_ROLE,
            SIGNER_ROLES_RESET,
            SIGNER_ROLES_UPDATED,
        } = Constants.ActionTypes;

        SignerStore.removeEventListener(UPDATED_SIGNER_ROLE, this.updateRoles);
        SignerStore.removeEventListener(SIGNER_ROLES_RESET, this.resetRoles);
        SignerStore.removeEventListener(
            SIGNER_ROLES_UPDATED,
            this.updateStaleRoles
        );
    }

    update = (index, event) => {
        let { name, value, type } = event.target;
        const {
            customer: { allowedSimpleSigningMethods },
            signer: { accessControl },
        } = this.props;

        // Checkbox events carry the value in .checked instead of .value
        if (type === 'checkbox') {
            value = event.target.checked;

            // enabling accessControl will reset enableInsecureSigning & insecureSigningMethods
            if (name === 'accessControl' && value) {
                this.props.updateSigner(index, {
                    enableInsecureSigning: false,
                    insecureSigningMethods: [],
                });
            }

            /**
             * We add the array of insecure signing methods when
             * enableInsecureSigning is enabled.
             */
            if (name === 'enableInsecureSigning') {
                // Don't do anything if accessControl is enabled
                if (accessControl) {
                    return;
                }

                /**
                 * We set the standard list of insecure methods as the default value.
                 * If the flag is on, then
                 * we check for customer's settings for an insecure methods list.
                 * If the list is empty, then we keep the default full list
                 */
                let insecureMethods = insecureSigningMethodsDefault;

                if (
                    launchDarkly.variation(
                        Flags.SPLIT_INSECURE_SIGNING_METHODS
                    ) &&
                    allowedSimpleSigningMethods.length
                ) {
                    insecureMethods = allowedSimpleSigningMethods;
                }

                this.props.updateSigner(index, {
                    insecureSigningMethods: value ? insecureMethods : [],
                });
            }
        }

        // Update signer data
        let data = {};

        data[name] = value;

        this.props.updateSigner(index, data);
    };

    checkSignerRoles = (signerTypeId) => {
        let { signer } = this.props;

        let role = signer?.role?.filter((r) => r?.id === signerTypeId)?.[0];

        return role.enabled;
    };

    refreshModal = () => {
        this.updateModal(this.modalContent());
    };

    modalContent = () => {
        let { availableSignerTypes, signerRules, index, signer } = this.props;
        let config = {
            title: i18n('Select signer role(s)'),
            body: (
                <SignerModal
                    availableRoles={availableSignerTypes}
                    selectedRoles={signer.role}
                    rules={signerRules}
                    index={index}
                    check={this.checkSignerRoles}
                    refreshModal={this.refreshModal}
                />
            ),
            buttons: (
                <div>
                    <Button theme="green" onClick={modal.hide}>
                        {i18n('Done')}
                    </Button>
                </div>
            ),
            preventClose: false,
        };

        return config;
    };

    showRoleButtonMessage = (roles) => {
        let { availableSignerTypes } = this.props;
        let enabledRoles = roles.filter((r) => r.enabled);

        let noAvailableRoles =
            !availableSignerTypes || availableSignerTypes.length === 0;

        if (noAvailableRoles || enabledRoles.length === 0) {
            return i18n`Select role(s)`;
        }

        // If we have only one role and a custom name for that role.
        if (
            enabledRoles.length === 1 &&
            enabledRoles?.[0]?.enabled &&
            SignerStore.getSignerRole(enabledRoles?.[0]?.id)?.role
        ) {
            return (
                enabledRoles?.[0]?.name ||
                SignerStore.getSignerRole(enabledRoles?.[0]?.id).role
            );
        }

        return i18n`Selected ${enabledRoles.length} roles`;
    };

    signerRoleSelectionPrompt = (event) => {
        event.preventDefault();
        let { availableSignerTypes, index, signer } = this.props;

        modal.show(this.modalContent(availableSignerTypes, index, signer));
    };

    /**
     * Deletes signer by index
     *
     * If user deletes last signer,
     * Resets first row to be an empty signer
     */
    handleSignerDelete = (index) => {
        this.props.remove(index);

        // If we deleted all signers, re-add an empty signer row.
        if (SignerStore.getSigners().length === 0) {
            SignerActions.addSigner();
        }
    };

    updateModal = (config) => {
        if (!modal.isVisible()) {
            return false;
        }

        modal.show(config);
    };

    updateSignerSsn = async (data) => {
        const { signer, updateSigner } = this.props;
        const index = SignerStore.getSigners().findIndex(
            (iSigner) => iSigner.tempId === signer.tempId
        );

        if (index !== -1) {
            updateSigner(index, data);
        }
    };

    render() {
        let {
            signer,
            index,
            isCasefileSensitive,
            inputErrors,
            availableSignerTypes,
            showInsecureMethods,
        } = this.props;
        const { roles } = this.state;
        let { update } = this;

        return (
            <tr>
                <td>
                    <input
                        type="text"
                        name="name"
                        value={signer.name || ''}
                        className={classNames(
                            'min-w-[200px]',
                            inputErrors['name' + index] ? 'input-error' : null
                        )}
                        onChange={update.bind(null, index)}
                    />
                    {inputErrors['name' + index] ? (
                        <ErrorMessage message={inputErrors['name' + index]} />
                    ) : null}
                </td>
                <td>
                    <input
                        type="email"
                        name="email"
                        className={classNames(
                            'min-w-[200px]',
                            inputErrors['email' + index] ? 'input-error' : null
                        )}
                        value={signer.email || ''}
                        onChange={update.bind(null, index)}
                    />
                    {inputErrors['email' + index] ? (
                        <ErrorMessage message={inputErrors['email' + index]} />
                    ) : null}
                </td>
                <td>
                    <input
                        type="text"
                        name="onBehalfOf"
                        value={signer.onBehalfOf || ''}
                        className={classNames(
                            'min-w-[150px]',
                            inputErrors['onBehalfOf' + index]
                                ? 'input-error'
                                : null
                        )}
                        onChange={update.bind(null, index)}
                    />
                    {inputErrors['onBehalfOf' + index] ? (
                        <ErrorMessage
                            message={inputErrors['onBehalfOf' + index]}
                        />
                    ) : null}
                </td>
                <td>
                    <div>
                        <Button
                            name="role"
                            theme="blue"
                            variant="outline"
                            disabled={availableSignerTypes.length === 0}
                            className={
                                inputErrors['role' + index]
                                    ? 'input-error'
                                    : null
                            }
                            onClick={this.signerRoleSelectionPrompt}>
                            {this.showRoleButtonMessage(roles)}
                        </Button>

                        {inputErrors['role' + index] ? (
                            <ErrorMessage
                                message={inputErrors['role' + index]}
                            />
                        ) : null}
                    </div>
                </td>
                <td>
                    <SsnInput
                        value={signer.ssn}
                        ssnType={signer.ssnType}
                        inputClass={`input-field ${
                            inputErrors['ssn' + index] ? ' input-error' : null
                        }`}
                        placeholder={i18n`Type SSN`}
                        onChange={({ value, ssnType }) =>
                            this.updateSignerSsn({
                                ssn: value,
                                ssnType: ssnType,
                            })
                        }
                    />{' '}
                    {inputErrors['ssn' + index] ? (
                        <ErrorMessage message={inputErrors['ssn' + index]} />
                    ) : null}
                </td>

                <td>
                    <input
                        type="text"
                        name="vatin"
                        value={signer.vatin || ''}
                        className={classNames(
                            'min-w-[150px]',
                            inputErrors['vatin' + index] ? 'input-error' : null
                        )}
                        onChange={update.bind(null, index)}
                    />
                    {inputErrors['vatin' + index] ? (
                        <ErrorMessage message={inputErrors['vatin' + index]} />
                    ) : null}
                </td>
                <td className="align-middle">
                    <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            name="accessControl"
                            checked={
                                isCasefileSensitive
                                    ? true
                                    : signer.accessControl
                            }
                            disabled={isCasefileSensitive}
                            onChange={update.bind(null, index)}
                        />
                        <span className="check">
                            <i className="fas fa-check" />
                        </span>
                    </label>
                </td>
                {showInsecureMethods && (
                    <td className="align-middle">
                        <label className="custom-checkbox">
                            <input
                                type="checkbox"
                                name="enableInsecureSigning"
                                checked={signer.enableInsecureSigning || false}
                                disabled={
                                    isCasefileSensitive || signer.accessControl
                                }
                                onChange={update.bind(null, index)}
                            />
                            <span className="check">
                                <i className="fas fa-check" />
                            </span>
                        </label>
                    </td>
                )}
                <td>
                    <input
                        type="number"
                        className={
                            inputErrors['reminderInterval' + index]
                                ? 'input-error'
                                : null
                        }
                        name="reminderInterval"
                        value={signer.reminderInterval || ''}
                        onChange={update.bind(null, index)}
                        min="0"
                    />
                    {inputErrors['reminderInterval' + index] ? (
                        <ErrorMessage
                            message={inputErrors['reminderInterval' + index]}
                        />
                    ) : null}
                </td>
                <td className="align-middle">
                    <i
                        className="fa fa-times fa-lg"
                        onClick={() => this.handleSignerDelete(index)}></i>
                </td>
            </tr>
        );
    }
}

export default connect((state) => ({
    customer: state.customer.details.data,
}))(SignerCreator);
