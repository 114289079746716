import React, { useCallback, useState } from 'react';

import UIButton from 'Common/components/Vega/Button';
import UIToggle from 'Common/components/Vega/Toggle';
import UIDateTimePicker from 'Common/components/Vega/DateTimePicker';
import UIDatePicker from 'Common/components/Vega/DatePicker';
import UIBanner from 'Common/components/Vega/Banner';
import UIInlineMessage from './Vega/InlineMessage';
import { UIModalBody, UIModalContainer, UIModalOverlay } from './Vega/Modal';

const TestPage = () => {
    const [openModal, setOpenModal] = useState(false);
    const [toggle, setToggle] = useState(false);

    const handleOpenModal = useCallback(() => setOpenModal(!openModal), [
        openModal,
    ]);

    const handleClick = () => {
        alert('I am john john!');
    };

    const handleDateTimeSelection = (
        event: UIEvent,
        values: Pick<HTMLInputElement, 'value' | 'valueAsNumber'>
    ) => {
        console.log(values);
    };

    const handleDateSelection = (
        event: UIEvent,
        values: Pick<
            HTMLInputElement,
            'value' | 'valueAsDate' | 'valueAsNumber'
        >
    ) => {
        console.log(values);
    };

    const handleToggle = () => {
        setToggle(!toggle);
        alert('Toggled');
    };

    return (
        <>
            <div className="flex justify-center p-8">
                <UIButton onClick={handleOpenModal} priority="primary">
                    Click to open modal
                </UIButton>

                {openModal && (
                    <UIModalOverlay onClose={handleOpenModal} closeOnEsc={true}>
                        <UIModalContainer>
                            <UIModalBody>This is a modal!</UIModalBody>
                        </UIModalContainer>
                    </UIModalOverlay>
                )}
            </div>
            <div
                style={{
                    padding: '40px',
                    margin: '0 auto',
                    width: '50%',
                }}>
                <UIInlineMessage
                    variant="accent"
                    title="This is a message"
                    description="This is a message as you can clearly see in this text"
                    onClickClose={handleClick}
                />
            </div>
            <div
                style={{
                    padding: '40px',
                    margin: '0 auto',
                    width: '50%',
                }}>
                <UIBanner
                    variant="accent"
                    layout="vertical"
                    linkText="Learn more"
                    linkUrl="#"
                    closeButton
                    onCloseClick={handleClick}>
                    <span slot="titleText">
                        This is the title of this Banner
                    </span>
                    <span slot="subtitleText">
                        This is the content of this Banner and the text is
                        longer so it looks good
                    </span>
                </UIBanner>
            </div>
            <div
                style={{
                    padding: '40px',
                    margin: '0 auto',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}>
                <UIButton onClick={handleClick} priority="primary">
                    Hello John!
                </UIButton>
            </div>
            <div
                style={{
                    padding: '40px',
                    margin: '0 auto',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}>
                <UIDateTimePicker onInput={handleDateTimeSelection} />
            </div>
            <div
                style={{
                    padding: '40px',
                    margin: '0 auto',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}>
                <UIDatePicker onInput={handleDateSelection} />
            </div>
            <div
                style={{
                    padding: '40px',
                    margin: '0 auto',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}>
                <UIToggle onClick={handleToggle} value={toggle}>
                    Hello Toggle!
                </UIToggle>
            </div>
        </>
    );
};

export default TestPage;
