import { Dispatcher } from 'Core';
import { SigningAPI } from 'Api';
import Constants from '../Constants';

const MessageTemplateActionCreators = {
    async fetchMessageTemplates() {
        return SigningAPI.get('/casefile/message/templates').then(
            (messageTemplates) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.FETCH_MESSAGE_TEMPLATES_SUCCESS,
                    messageTemplates: messageTemplates,
                });
            }
        );
    },

    updateSelectedTemplateId(messageTemplateId) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.MESSAGE_TEMPLATE_SELECTED,
            messageTemplateId: messageTemplateId,
        });
    },

    updateMessageTemplate(messageTemplate) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.MESSAGE_TEMPLATE_UPDATED,
            messageTemplate: messageTemplate,
        });
    },

    clearStore() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.MESSAGE_TEMPLATE_STORE_CLEARED,
        });
    },

    async populateStore(data) {
        const {
            messageSubject,
            messageText,
            messageTemplate,
            signersMessageTemplate,
        } = data;
        let message = {};

        if (messageSubject) {
            message.subject = messageSubject;
        }

        if (messageText) {
            message.message = messageText;
        }

        if (signersMessageTemplate) {
            message.subject = signersMessageTemplate.subject;
            message.message = signersMessageTemplate.message;
        }

        if (!messageTemplate && !messageText && !signersMessageTemplate) {
            return;
        }

        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.MESSAGE_TEMPLATE_URI_UPDATED,
            templateId: messageTemplate,
            messageTemplate: message,
        });
    },
};

export default MessageTemplateActionCreators;
