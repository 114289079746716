import { i18n } from 'Language/i18n';
import React from 'react';

export default function Spinner({
    loadingText = 'Loading...',
}: {
    loadingText?: string;
}) {
    return (
        <div className="flex items-center gap-2">
            <div className="button-spinner" />
            {i18n(loadingText)}
        </div>
    );
}
