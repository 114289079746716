import React from 'react';
import IntegrationForm from './IntegrationForm';
import { IntegrationCredentials } from 'Auth/components/CredentialsManagement/ManageIntegrationCredentials';

type Props = {
    credentials: IntegrationCredentials | null;
};

export default class IntegrationFormContainer extends React.Component<Props> {
    render() {
        const { credentials } = this.props;

        if (!credentials) {
            return false;
        }

        return (
            <IntegrationForm
                clientKey={credentials.key}
                secret={credentials.secret}
            />
        );
    }
}
