import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { useAppDispatch, ReduxState } from 'Store';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { i18n } from 'Language/i18n';
import { SignerRole } from 'types/SignerRole';
import { ActivationDateModify } from '../ActivationDateModify';
import {
    clearSignatureLinesActiveAt,
    updateSignatureLinesActiveAt,
} from 'Casefiles/CasefileDetails/redux/actions';
import { SignatureLineEntity } from 'types/Document';

export type Props = {
    role: SignerRole;
    activationDateEditable?: boolean;
    first?: boolean;
    hasActivatedSigLine?: boolean;
    last?: boolean;
    signerId?: number;
};

export const CasefileSignerRolesListItem: React.FunctionComponent<Props> = (
    props
) => {
    const dispatch = useAppDispatch();

    const {
        activationDateEditable = false,
        hasActivatedSigLine,
        role,
        signerId,
    } = props;

    const { activeAt: activeAtFromRole, customName, role: roleName } = role;

    const [editingActDate, setEditingActDate] = useState(false);

    const primaryRoleName = customName || roleName;
    const secondaryRoleName = customName ? roleName : null;

    const activeAtMoment = activeAtFromRole
        ? moment.unix(activeAtFromRole)
        : undefined;
    const activeAtFormatted = activeAtMoment?.format('YYYY-MM-DD HH:mm');

    const handleOnEditActDateClick = () => {
        setEditingActDate(true);
    };

    const onActivationDateChange = (date: Moment) => {
        if (!signerId) return;

        const unixActivationDate = date.unix();

        dispatch(
            updateSignatureLinesActiveAt(
                unixActivationDate,
                props.role.role,
                signerId
            )
        );
    };

    const onActivationDateClear = () => {
        if (!signerId) return;

        dispatch(clearSignatureLinesActiveAt(props.role.role, signerId));
    };

    return (
        <div className="recipient-item">
            <div className="recipient-item-role-name">
                <span className="casefile-signer-role-name">
                    {primaryRoleName}
                </span>
                {secondaryRoleName && (
                    <span className="casefile-signer-role-name casefile-signer-role-name-secondary">
                        ({secondaryRoleName})
                    </span>
                )}
            </div>
            {activeAtMoment && !hasActivatedSigLine && (
                <div>
                    {editingActDate ? (
                        <ActivationDateModify
                            activationDate={activeAtMoment}
                            onDateChange={onActivationDateChange}
                            onDateClear={onActivationDateClear}
                        />
                    ) : (
                        <div
                            className={
                                !props.last
                                    ? 'recipient-item-active-at-margin'
                                    : undefined
                            }>
                            {i18n('Activates at')}: {activeAtFormatted}
                            {activationDateEditable && (
                                <span
                                    className="item-inline-action"
                                    onClick={handleOnEditActDateClick}>
                                    <i className="far fa-pencil-alt"></i>
                                </span>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default connect((state: ReduxState, props: Props) => {
    const allSigLines = lodash.flatten(
        state.caseFileDetails.casefile.data?.documents?.map(
            (document) => document.signatureLines
        )
    ) as SignatureLineEntity[];

    const filteredSigLines = allSigLines.filter(
        (sigLine) =>
            sigLine.role === props.role.role &&
            sigLine.signerId === props.signerId
    );

    const hasActivatedSigLine =
        filteredSigLines.findIndex((sigLine) => sigLine.activatedAt) >= 0;

    return { hasActivatedSigLine };
})(CasefileSignerRolesListItem);
