import React from 'react';

import CustomerStore from 'Auth/stores/CustomerStore';
import CustomerActions from 'Auth/actions/CustomerActionCreators';

import UserManagementStore from 'Auth/stores/UserManagementStore';
import UserManagementActions from 'Auth/actions/UserManagementActionCreators';
import AuthStore from 'Auth/stores/AuthStore';

import { i18n } from 'Language';
import Button from 'Common/components/Button';
import Select from 'Common/components/Select';
import { UserEntity as User } from 'types/User';

export type Props = {
    allowTransfer: boolean;
    currentUserId: number;
    customerId: number;
    userIds: number[];
    onSuccess?: Function;
    onCancel?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onAccept: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export type State = {
    selectedUser: string;
    transfer: boolean;
    confirm: boolean;
    users: User[];

    // Tracks user deletion state.
    success: boolean;
    loading: boolean;
    error: any;
};

const DeleteConfirmationButtons = ({
    userCount,
    disableConfirm,
    deleteUser,
}) => (
    <div
        className="relative flex justify-end items-center mt-1"
        aria-live="polite">
        <p className="pr-6">{i18n`Are you sure?`}</p>
        <Button
            theme="gray"
            variant="outline"
            onClick={disableConfirm}>{i18n`No, cancel`}</Button>

        <Button theme="red" className="ml" onClick={deleteUser}>
            {userCount > 1
                ? i18n`Yes, delete ${userCount} users`
                : i18n`Yes, delete user`}
        </Button>
    </div>
);

export default class DeleteUserPrompt extends React.Component<Props, State> {
    state = this.getInitialState();

    static defaultProps: Partial<Props> = {
        allowTransfer: false,
    };

    getInitialState(): State {
        return {
            selectedUser: '',
            transfer: true,
            confirm: false,
            users: [],

            // Tracks user deletion state.
            success: false,
            loading: false,
            error: null,
        };
    }

    resetState() {
        this.setState(this.getInitialState());
        this.loadData();
    }

    componentDidMount() {
        CustomerStore.addChangeListener(this.onChange);
        UserManagementStore.addEventListener(
            'ACTION_DISPATCHED',
            this.onChange
        );
        AuthStore.addChangeListener(this.onChange);

        this.loadData();
    }

    componentWillUnmount() {
        CustomerStore.removeChangeListener(this.onChange);
        AuthStore.removeChangeListener(this.onChange);
        UserManagementStore.removeEventListener(
            'ACTION_DISPATCHED',
            this.onChange
        );
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // Execute onSuccess callback when component transitions to success
        if (!prevState.success && this.state.success && this.props.onSuccess) {
            this.props.onSuccess();
        }
    }

    loadData() {
        if (this.props.allowTransfer) {
            CustomerActions.fetchCustomerUsers(this.props.customerId);
        }
    }

    onChange = (action: string, payload: any) => {
        this.setState({
            loading: action === 'USER_DELETE_REQUEST',
            success: action === 'USER_DELETE_SUCCESS',
            error: action === 'USER_DELETE_ERROR' ? payload : null,
            users: CustomerStore.getUsers(this.props.customerId),
            selectedUser: AuthStore.getUser()?.id ?? '',
        });
    };

    enableConfirm = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        this.setState({ confirm: true });
    };

    disableConfirm = () => {
        this.setState({ confirm: false });
    };

    toggleTransfer = () => {
        this.setState(
            { transfer: !this.state.transfer, confirm: false },
            () => {
                if (this.state.transfer) return;

                this.setState({
                    selectedUser: '',
                });
            }
        );
    };

    deleteUser = () => {
        const { allowTransfer, customerId, userIds } = this.props;
        const { transfer, selectedUser } = this.state;

        const userToTransferTo =
            allowTransfer && transfer && selectedUser
                ? Number(selectedUser)
                : undefined;

        UserManagementActions.deleteUser(customerId, userIds, userToTransferTo);
    };

    renderButtons() {
        const { transfer, confirm, selectedUser } = this.state;

        const userCount = this.props.userIds.length;
        const transferData = transfer && this.props.allowTransfer;

        return (
            <div className="buttons">
                {confirm ? (
                    <DeleteConfirmationButtons
                        userCount={userCount}
                        disableConfirm={this.disableConfirm}
                        deleteUser={this.deleteUser}
                    />
                ) : (
                    <div className="flex content-center justify-end gap-2 my-2">
                        {this.props?.onCancel && (
                            <Button
                                theme="gray"
                                variant="text"
                                onClick={this.props?.onCancel}>
                                {i18n`Cancel`}
                            </Button>
                        )}
                        <Button
                            theme="blue"
                            onClick={this.enableConfirm}
                            disabled={transferData && !selectedUser}
                            data-testid="confirm-delete-user-button">
                            {transferData ? (
                                <span>
                                    {userCount > 1
                                        ? i18n`Delete ${userCount} users and transfer data`
                                        : i18n`Delete user and transfer data`}
                                </span>
                            ) : (
                                <span>
                                    {userCount > 1
                                        ? i18n`I understand, delete ${userCount} users`
                                        : i18n`I understand, delete user`}
                                </span>
                            )}
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    handleAdminChange = (value: string) => {
        this.setState({ selectedUser: value });
    };

    getUsers() {
        const { users } = this.state;
        const { userIds } = this.props;

        return users.filter((user) => {
            // Exclude admins that are selected for deletion.
            if (userIds.indexOf(user.id) !== -1) {
                return false;
            }

            return true;
        });
    }

    renderOwnerTransferForm() {
        const { transfer } = this.state;

        const users = this.getUsers().sort((a, b) =>
            a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase())
        );

        return (
            <div>
                {transfer ? (
                    <>
                        {users.length === 0 ? (
                            this.renderLoading()
                        ) : (
                            <>
                                <p>{i18n`Transfer casefiles to`}:</p>

                                <Select
                                    label={'Name and email'}
                                    id="admin"
                                    required
                                    defaultValue={this.state.selectedUser}
                                    onChange={(v) => this.handleAdminChange(v)}>
                                    {users.map((user) => (
                                        <option key={user.id} value={user.id}>
                                            {user.fullName} - {user.email}
                                        </option>
                                    ))}
                                </Select>
                            </>
                        )}
                    </>
                ) : (
                    <div>
                        <p className="text-error">
                            <i className="fa fa-warning" />
                            &nbsp;
                            {i18n`By performing this action, you will be the
                                    owner of all the case files and documents that
                                    currently belong to the selected users`}
                        </p>
                    </div>
                )}

                {users.length > 0 && this.renderButtons()}
            </div>
        );
    }

    renderUserDeleteForm() {
        return (
            <div className="relative flex flex-col">
                <p className="max-w-[480px]">
                    {i18n`By performing this action, you can potentially lose access to the
                        documents that currently belong to the selected users.`}
                </p>

                {this.renderButtons()}
                <p className="text-error justify-self-end self-end m-0">
                    <i className="fa fa-warning" />
                    &nbsp;
                    {i18n`This action cannot be undone`}
                </p>
            </div>
        );
    }

    renderSuccess() {
        const userCount = this.props.userIds.length;

        return (
            <div className="text-center">
                <p>
                    <i className="fa fa-lg fa-check-circle"></i>
                </p>
                <h3>
                    {userCount > 1
                        ? i18n`${userCount} users were deleted successfully`
                        : i18n`User was deleted successfully`}
                </h3>

                <p>
                    <Button theme="blue" onClick={this.props.onAccept}>
                        {i18n`Accept`}
                    </Button>
                </p>
            </div>
        );
    }

    renderError() {
        return (
            <div>
                <div className="text-center">
                    <p>
                        <i className="fa fa-lg fa-times"></i>
                    </p>
                    <h3>{i18n`Could not delete the selected users`}</h3>

                    <Button theme="blue" onClick={this.resetState}>
                        {i18n`Try Again`}
                    </Button>
                </div>
            </div>
        );
    }

    renderUsersToDelete() {
        const { users } = this.state;
        const { userIds } = this.props;
        const userCount = userIds.length;
        const usersInfo: User[] = [];

        userIds.forEach((id) => {
            const user = users.find((u) => u.id === id);

            user && usersInfo.push(user);
        });

        return usersInfo.length ? (
            <div className="delete-user-prompt-to-delete">
                {userCount > 1 ? (
                    <>
                        <span>
                            {i18n`You are deleting the following users:`}&nbsp;
                        </span>
                        <ul className="delete-user-prompt-to-delete-list">
                            {usersInfo.map((u, i) => (
                                <li key={`${u.fullName}${i}`}>
                                    <p>
                                        <strong>{`${u.fullName} (${u.email})`}</strong>
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <p>
                        {i18n`You are deleting`}&nbsp;
                        <strong>{`${usersInfo[0].fullName} (${usersInfo[0].email})`}</strong>
                    </p>
                )}
            </div>
        ) : null;
    }

    renderLoading() {
        return (
            <div className="text-center text-medium">
                <h1>
                    <i className="far fa-sync fa-lg fa-spin"></i>
                    <p>{i18n`Loading...`}</p>
                </h1>
            </div>
        );
    }

    render() {
        const { allowTransfer } = this.props;
        const { success, error, loading } = this.state;

        if (loading) {
            return this.renderLoading();
        }

        if (success) {
            return this.renderSuccess();
        }

        if (error) {
            return this.renderError();
        }

        return (
            <div className="delete-user-prompt">
                {this.renderUsersToDelete()}
                {allowTransfer
                    ? this.renderOwnerTransferForm()
                    : this.renderUserDeleteForm()}
            </div>
        );
    }
}
