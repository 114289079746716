import PropTypes from 'prop-types';
import React from 'react';
import BankIDActions from '../../actions/BankIDNOServerActions';
import ViewActions from '../../actions/BankIDNOViewActions';
import BankIDStore from '../../stores/BankIDNOStore';
import BankIDWidget from './BankIDWidget';
import StatusPageMessage, {
    ComponentId,
} from 'Common/components/StatusPageMessage';
import CustomerActionCreators from '../../actions/CustomerActionCreators';
import CustomerStore from 'Auth/stores/CustomerStore';
import LoadingData from 'Common/components/Loaders/LoadingData';
import {
    TranslationStore,
    TranslationActions,
    LanguageDropdown,
    i18n,
} from 'Language';
import PostSignStorageOptions from 'Common/components/StorageOptions/PostSignStorageOptions';
import SigningError from 'Common/components/SigningError';

import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import { BANKID_NORWAY_EIDENT } from 'EID/Constants';
import OpenIDActions from '../../actions/OpenIDActions';
import { Intent } from 'OpenID/Constants';
import Constants from 'Constants';

export default class SignContainer extends React.Component {
    static propTypes = {
        location: PropTypes.object,
        params: PropTypes.object,
        redirect: PropTypes.func,
        children: PropTypes.object,
        challengeKey: PropTypes.string.isRequired,
    };

    state = {
        params: null,
        isFetching: null,
        error: null,
        language: TranslationStore.getLanguage(),
        displayAccountStorageOptions: false,
        temporalStorageDefault: null,
        signerArchiveEnabled: true,
        signature: null,
    };

    componentDidMount() {
        if (launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED)) {
            this.timeout = setTimeout(() => {
                OpenIDActions.init(BANKID_NORWAY_EIDENT.type, Intent.SIGN);
            }, 1000);

            return;
        }

        BankIDStore.addChangeListener(this.onChange);
        TranslationStore.addChangeListener(this.onChange);
        CustomerStore.addChangeListener(this.onChange);
        this.loadData();
    }

    loadData = () => {
        const { challengeKey } = this.props;

        CustomerActionCreators.fetchTemporalStorageDefault(challengeKey);
    };

    componentWillUnmount() {
        BankIDStore.removeChangeListener(this.onChange);
        TranslationStore.removeChangeListener(this.onChange);
        CustomerStore.removeChangeListener(this.onChange);
    }

    componentDidUpdate(prevProps, prevState) {
        // Reset the widget if language is changed
        if (prevState.language !== this.state.language) {
            this.retry();
        }
    }

    onChange = () => {
        this.setState({
            params: BankIDStore.getParams(),
            isFetching: BankIDStore.isFetching(),
            error: BankIDStore.getError(),
            language: TranslationStore.getLanguage(),
            temporalStorageDefault: CustomerStore.getTemporalStorageDefault(),
        });
    };

    /*
        Finish signing process after 2-step signing process where user selects a storage option for the documents

        For newly created personal accounts, the signing token needs to be refreshed.
        We get the signature and token in the request to create a new account, and we
        must use this new token to make the signing request complete
    */
    finishSigningProcess = (disposable, storageUserId, newSignature = null) => {
        const { signature } = this.state;

        return BankIDActions.sign(
            newSignature || signature,
            disposable,
            storageUserId
        );
    };

    onSuccess = async (signatureRef) => {
        const { temporalStorageDefault, signerArchiveEnabled } = this.state;
        const signature = await BankIDActions.getSignature(signatureRef);

        // If temporal storage is enabled, display second step of signing
        if (signerArchiveEnabled && temporalStorageDefault) {
            return this.setState({
                signature,
                displayAccountStorageOptions: true,
            });
        }

        // Finish process
        return BankIDActions.sign(signature, false, null);
    };

    init = (languageCode = TranslationStore.getLanguage()) => {
        let { challengeKey } = this.props;

        BankIDActions.initSign(challengeKey, languageCode);
    };

    retry = () => {
        ViewActions.retry('sign');
        this.init();
    };

    open = () => {
        this.setState({
            open: true,
        });
    };

    close = () => {
        this.setState({
            open: false,
        });
    };

    onLanguageChange = (languageCode) => {
        TranslationActions.setLanguage(languageCode, { persist: false });
    };

    renderLanguageSelector = () => {
        let { open } = this.state;

        return (
            <div
                className={'language-dropdown-login' + (open ? ' open' : '')}
                onMouseEnter={this.open}
                onMouseLeave={this.close}>
                <LanguageDropdown
                    closeDropdown={this.close}
                    callback={this.onLanguageChange}
                    persist={false}
                />
            </div>
        );
    };

    restartSignProcess = () => {
        this.setState({
            params: null,
            isFetching: null,
            error: null,
            displayAccountStorageOptions: false,
            temporalStorageDefault: null,
            signerArchiveEnabled: true,
            signature: null,
        });

        this.retry();
    };

    updateError = (error) => {
        this.setState({ error: error });
    };

    render() {
        let {
            params,
            error,
            isFetching,
            displayAccountStorageOptions,
            temporalStorageDefault,
            disposable,
            language,
        } = this.state;
        const isLoaded = temporalStorageDefault !== null;

        if (isLoaded === false) {
            return <LoadingData />;
        }

        if (displayAccountStorageOptions) {
            return (
                <div>
                    {error ? (
                        <SigningError
                            code={error.status}
                            message={error.statusText}
                            retry={this.restartSignProcess}
                        />
                    ) : (
                        <PostSignStorageOptions
                            method="bankidno"
                            hasError={!!error}
                            isSubmitting={isFetching}
                            challengeKey={this.props.challengeKey}
                            signature={this.state.signature}
                            value={disposable}
                            onSubmit={this.finishSigningProcess}
                        />
                    )}
                </div>
            );
        }

        if (launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED)) {
            return (
                <div className="eid-widget-container auth-method-password">
                    <div className="auth-logo">
                        <img
                            height="120"
                            width="120"
                            src={BANKID_NORWAY_EIDENT.logo}
                        />
                    </div>
                    <p>{i18n(`Redirecting...`)}</p>
                </div>
            );
        }

        return (
            <div className="eid-widget-container auth-method-password">
                {this.renderLanguageSelector()}
                <br />
                <StatusPageMessage components={[ComponentId.SIGN_BANKID_NO]} />

                <BankIDWidget
                    params={params}
                    error={error}
                    retry={this.retry}
                    isFetching={isFetching}
                    init={this.init}
                    onSuccess={this.onSuccess}
                    onFailure={this.onFailure}
                    updateError={this.updateError}
                />
                <div className="widget-footer">
                    <a
                        href={Constants.sites.helpLinkForBankIdNorway(language)}
                        target="_blank"
                        rel="noopener noreferrer">
                        {i18n('Problems with BankID?')}
                    </a>
                </div>
            </div>
        );
    }
}
