import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'Store';

import { modal } from 'Common/components/Common/Modal';
import Button from 'Common/components/Button';
import RecipientModal from 'Casefiles/components/casefiles2/RecipientModal';
import { CaseFileEntity } from 'types/CaseFile';
import { SignerRole } from 'types/SignerRole';
import { UserEntity } from 'types/User';
import { i18n } from 'Language';

import RecipientSuggestionModal from './RecipientSuggestionModal';
import { Recipient } from 'AuditingAccounting/types';
import {
    selectedClientSelector,
    selectedRecipientsSelector,
    setAddedRecipientsCount,
    setSelectedRecipients,
} from 'AuditingAccounting/redux/reducer';
import { RecipientType } from '../../../types/Recipient';

type Props = {
    hasRecipients: boolean;
    casefile: CaseFileEntity;
    availableSignerTypes: SignerRole[];
    user: UserEntity;
};

const RecipientSuggestionButton: React.FC<Props> = ({
    casefile,
    availableSignerTypes,
    user,
}) => {
    const [hasRecipients, setHasRecipients] = useState(false);
    const selectedRecipients = useAppSelector(selectedRecipientsSelector);
    const selectedClient = useAppSelector(selectedClientSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        selectedClient?.persons &&
            setHasRecipients(selectedClient?.persons?.length > 0);
    }, [selectedClient]);

    const openRecipientSuggestionModal = () => {
        if (!selectedClient?.persons) {
            return;
        }

        const config = {
            component: (
                <RecipientSuggestionModal
                    client={selectedClient}
                    onSubmit={onRecipientsSelected}
                />
            ),
        };

        modal.show(config);
    };

    const onRecipientsSelected = (selectedRecipients: Recipient[]) => {
        dispatch(setSelectedRecipients(selectedRecipients));
        dispatch(setAddedRecipientsCount(selectedRecipients.length));
    };

    const openRecipientModal = useCallback(
        (recipient: Recipient) => {
            const config = {
                onClose: () => {
                    const [, ...rest] = selectedRecipients;

                    dispatch(setSelectedRecipients(rest));
                },
                component: (
                    <RecipientModal
                        casefile={casefile}
                        index={null}
                        availableSignerTypes={availableSignerTypes}
                        user={user}
                        recipient={{
                            ...recipient,
                            type: RecipientType.Signer,
                            email: recipient.email ?? '',
                            role: [],
                            storeAsContact: true,
                            accessControl: false,
                            enableInsecureSigning: false,
                            reminderInterval: 2,
                        }}
                    />
                ),
                preventClose: true,
            };

            /**
             * The delay for opening the modal is necessary when the modal is beinng re-opened with another recipient,
             * so the opened modal has enough time to fully close, otherwise the content of modal will not refresh.
             * Modal would not re-render with new values as the closing animation of the modal is not completed.
             *
             * This is because the onClose callback executes in the beginning of the closing animation.
             */
            setTimeout(() => modal.show(config), 200);
        },
        [availableSignerTypes, casefile, dispatch, selectedRecipients, user]
    );

    useEffect(() => {
        const [recipient] = selectedRecipients;

        if (!recipient) {
            return;
        }

        openRecipientModal(recipient);
    }, [openRecipientModal, selectedRecipients]);

    if (!(selectedClient && hasRecipients)) {
        return null;
    }

    return (
        <Button
            theme="blue"
            variant="outline"
            className="mr"
            icon="far fa-user-friends"
            onClick={openRecipientSuggestionModal}>
            {i18n`Suggest Recipients`}
        </Button>
    );
};

export default RecipientSuggestionButton;
