import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { i18n } from 'Language';
import Table, { Config } from 'Common/components/BaseTable/Table';
import Button from 'Common/components/Button';
import { Link } from 'react-router';
import {
    fetchEntities,
    entitiesState,
    setSelectedEntity,
} from 'Entities/redux/reducer';

import { useAppDispatch, useAppSelector } from 'Store';

const Clients = () => {
    const state = useAppSelector(entitiesState);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!LaunchDarkly.variation(Flags.CLIENT_ENTITIES_ACCESS)) {
            document.location.replace('/404');
        }

        LaunchDarkly.variation(Flags.CLIENT_ENTITIES_ACCESS) &&
            dispatch(fetchEntities());
    }, [dispatch]);

    const { entities } = state;

    const defaultProps = {
        dataSource: entities ?? [],
        isFetching: !state.fetchedEntities,
        isLoading: !state.fetchedEntities,
        config: {
            vatin: {
                label: 'Company CVR',
            },
            name: {
                label: 'Company name',
                disableSort: true,
            },
            signers: {
                label: 'Signers',
                component: (data) => {
                    return data.signers?.length;
                },
            },
            button: {
                disableSort: true,
                component: (data) => (
                    <Link
                        to={{
                            name: 'client',
                            params: {
                                cvr: data.vatin,
                            },
                        }}>
                        <Button
                            renderAsSpan
                            theme="blue"
                            variant="outline"
                            icon="far fa-plus-circle"
                            onClick={() => {
                                dispatch(setSelectedEntity(data));
                            }}>
                            Client Case
                        </Button>
                    </Link>
                ),
            },
        } as Config,
        page: 1,
        dataCount: entities?.length ?? 0,
        onNext: (offset: number) => null,
        onPrev: (offset: number) => null,
        onLimitChange: (value: number) => null,
        onSortChange: (sort: string) => null,
    };

    return (
        (LaunchDarkly.variation(Flags.CLIENT_ENTITIES_ACCESS) && (
            <div className="archive-container">
                <Helmet>
                    <title>{i18n`Clients List`}</title>
                </Helmet>
                <div className="white-container no-padding-container">
                    <h3 className="title">{i18n`Clients`}</h3>
                    <Table {...defaultProps} />
                </div>
            </div>
        )) ??
        null
    );
};

export default Clients;
