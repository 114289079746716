import { Dispatcher } from 'Core';
import Constants from '../Constants';
import { SigningAPI } from 'Api';

const MessageTemplateActionCreators = {
    async fetchMessageTemplates() {
        SigningAPI.get('/casefile/message/templates')
            .then((templates) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.FETCH_MESSAGE_TEMPLATES_SUCCESS,
                    templates: templates,
                });
            })
            .catch((error) => {
                throw new Error(error);
            });
    },
};

export default MessageTemplateActionCreators;
