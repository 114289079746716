import { SigningMethod } from 'EID';
import { i18n } from 'Language';
import React, { useEffect } from 'react';

import './signing-methods.scss';
import { getUrlId, shouldUseEIDWebcomponent } from 'OpenID/redux/actions';
import { Intent, ServiceIDs } from 'OpenID/Constants';
import { EIDButtonWrapper } from '../EID/components/EIDButtonWrapper';
import { OpenIDMethod } from '../EID/types';

type Props = {
    signingMethods: SigningMethod[];
    handleMethodSelection: (method: SigningMethod) => void;
    onClose: () => void;
    encryptedNIN: string;
};

export const SigningMethods = ({
    signingMethods,
    handleMethodSelection,
    onClose,
    encryptedNIN,
}: Props) => {
    useEffect(() => {
        // handle dropdown closing
        const clickOutside = (e: MouseEvent) => {
            if (
                !document
                    .querySelector('.signing-methods')
                    ?.contains(e.target as Node)
            ) {
                onClose();
            }
        };

        document.addEventListener('click', clickOutside);

        return () => {
            document.removeEventListener('click', clickOutside);
        };
    }, [onClose]);

    return signingMethods?.length ? (
        <div className="signing-methods">
            <ul className="signing-methods-list">
                {signingMethods.map((method) =>
                    shouldUseEIDWebcomponent(
                        getUrlId(
                            method.type as ServiceIDs,
                            Intent.SIGN
                        ) as ServiceIDs
                    ) ? (
                        <EIDButtonWrapper
                            key={method.type}
                            inline={true}
                            method={method as OpenIDMethod}
                            intent={Intent.SIGN}
                            encryptedNIN={encryptedNIN}
                        />
                    ) : (
                        <li key={method.type}>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleMethodSelection(method);
                                }}>
                                <img src={method.newFlag} />
                                {i18n(method?.extendedTitle || method.title)}
                            </a>
                        </li>
                    )
                )}
            </ul>
        </div>
    ) : null;
};
