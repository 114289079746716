import createReducer from 'create-reducer';
import { TemplateType } from 'types/EmailTemplates';
import {
    FETCH_MESSAGE_LIST_TEMPLATES_REQUEST,
    FETCH_MESSAGE_LIST_TEMPLATES_SUCCESS,
    FETCH_MESSAGE_LIST_TEMPLATES_FAILURE,
} from './action-types';

export type EmailTemplatesState = {
    isLoading: boolean;
    templates: {
        default: boolean;
        id: number;
        message: string;
        subject: string;
        title: string;
        type: TemplateType;
    }[];
};

export const initialState: EmailTemplatesState = {
    templates: [],
    isLoading: false,
};

export default createReducer(initialState, {
    [FETCH_MESSAGE_LIST_TEMPLATES_REQUEST]: (state) => ({
        ...state,
        isLoading: true,
    }),
    [FETCH_MESSAGE_LIST_TEMPLATES_FAILURE]: (state) => ({
        ...state,
        isLoading: false,
    }),
    [FETCH_MESSAGE_LIST_TEMPLATES_SUCCESS]: (state, templates) => ({
        ...state,
        templates,
        isLoading: false,
    }),
});
