import React, { useEffect, useState } from 'react';
import {
    SigningRequestSeenBySigner,
    useSigningMethods,
} from '../../Signing/useSigningMethods';
import openIDActions from 'Auth/actions/OpenIDActions';
import { OpenIDMethod, SigningMethod } from '../../EID/types';
import { i18n, TranslationStore } from '../../Language';
import Button from '../../Common/components/Button';
import { CustomerSigningMethod } from '../../types/Customer';
import { ANY_MITID_DENMARK_HIDDEN_NAME_VARIANT } from '../../EID/Constants';
import analytics from '../../Common/Analytics';
import { RadioButton } from '../../Common/components';
import { Intent, ServiceIDs } from 'OpenID/Constants';
import { Languages } from 'Language/Constants';
import { getUrlId, shouldUseEIDWebcomponent } from '../../OpenID/redux/actions';
import { clearOpenIdState } from '../../OpenID/utils/openIdState';

type Props = {
    challengeKey: string;
    customerAllowedSigningMethods: CustomerSigningMethod[];
    signingRequest: SigningRequestSeenBySigner;
    allowSigningWithNap: boolean;
    encryptedNIN?: string;
};

export default function NAPSigningMethods({
    challengeKey,
    customerAllowedSigningMethods,
    signingRequest,
    allowSigningWithNap,
    encryptedNIN,
}: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedMethod, setSelectedMethod] = useState<
        SigningMethod | OpenIDMethod | null
    >();
    /**
     * This method is introduced to allow us to override the signer setting "Allow to upload an image as signature" and
     * NIN validation.
     * This will allow users to use the image upload and other alternative signing methods (Nets IDV, itsme) in the
     * MitID NAP flow, if they're enabled for the customer.
     */
    const getModifiedSigningRequest = (): SigningRequestSeenBySigner => {
        const modifiedSigningRequest: SigningRequestSeenBySigner = {
            ...signingRequest,
            enableInsecureSigning: true,
            isSpecificSignerExpected: false,
        };
        const imageIsEnabledForCompany = customerAllowedSigningMethods.find(
            (method: string) => method === 'image'
        );

        if (imageIsEnabledForCompany) {
            modifiedSigningRequest.insecureSigningMethods?.push('image');
        }

        return modifiedSigningRequest;
    };

    const { signingMethods, handleMethodSelection } = useSigningMethods(
        customerAllowedSigningMethods,
        challengeKey,
        TranslationStore.getLanguage(),
        getModifiedSigningRequest(),
        encryptedNIN
    );

    const handleBackClick = () => {
        window.location.href = `/signing/${challengeKey}`;
    };

    const handleContinueClick = async () => {
        if (!selectedMethod) {
            return;
        }

        setIsLoading(true);

        if (
            selectedMethod?.type === ANY_MITID_DENMARK_HIDDEN_NAME_VARIANT.type
        ) {
            return openIDActions.init(
                ANY_MITID_DENMARK_HIDDEN_NAME_VARIANT.type as ServiceIDs,
                Intent.NAP,
                {},
                Languages.EN,
                encryptedNIN
            );
        }

        if (
            shouldUseEIDWebcomponent(
                getUrlId(
                    selectedMethod.type as ServiceIDs,
                    Intent.SIGN
                ) as ServiceIDs
            )
        ) {
            // Omitted encryptedNIN as it is unnecessary in all other whitelisted OpenID types for now
            return openIDActions.init(
                selectedMethod.credentialType() as ServiceIDs,
                Intent.NAP
            );
        }

        clearOpenIdState();
        handleMethodSelection(selectedMethod as SigningMethod);

        setIsLoading(false);
    };

    const getAvailableSigningMethodsForAnalytics = () => {
        const availableSigningMethods = signingMethods;

        if (allowSigningWithNap) {
            availableSigningMethods.push(
                ANY_MITID_DENMARK_HIDDEN_NAME_VARIANT as SigningMethod
            );
        }

        if (availableSigningMethods.length === 0) {
            return null;
        }

        return availableSigningMethods.map((method) => method.type).join(', ');
    };

    const renderSigningMethodsListTitle = () => {
        // If only MitID is available
        if (signingMethods.length === 0 && allowSigningWithNap) {
            return (
                <p>
                    {i18n`You can still sign with MitID, but your name will not be
                    visible on the signed documents.`}
                </p>
            );
        }

        // If MitID and other methods is available
        if (signingMethods.length > 0 && allowSigningWithNap) {
            return (
                <p>
                    {i18n`You can still sign with MitID, but your name will not be
                    visible on the signed documents.`}{' '}
                    <br />
                    <br />
                    {i18n`Please choose how to sign:`}
                </p>
            );
        }

        // If only method is available and MitID is not
        if (signingMethods.length === 1 && !allowSigningWithNap) {
            return <p>{i18n('Instead, please sign using:')}</p>;
        }

        // If multiple methods is available and MitID is not
        if (signingMethods.length > 1 && !allowSigningWithNap) {
            return <p>{i18n('Please choose how to sign:')}</p>;
        }
    };

    useEffect(() => {
        if (signingMethods.length === 1 && !allowSigningWithNap) {
            setSelectedMethod(signingMethods[0]);
        }

        if (signingMethods.length === 0 && allowSigningWithNap) {
            setSelectedMethod(ANY_MITID_DENMARK_HIDDEN_NAME_VARIANT);
        }
    }, [allowSigningWithNap, signingMethods]);

    useEffect(() => {
        analytics.track('NAP detected', {
            availableSigningMethods: getAvailableSigningMethodsForAnalytics(),
        });
    }, []);

    return (
        <div>
            <p className="mb-6">{i18n`Your name and address are protected in the CPR register. Therefore your name can't be confirmed with MitID.`}</p>
            {!signingMethods.length && !allowSigningWithNap ? (
                <p>{i18n`Please contact the sender to arrange another way to sign.`}</p>
            ) : (
                <>
                    {renderSigningMethodsListTitle()}

                    <div className="signing-methods relative bg-transparent px-0 pt-0 pb-5 shadow-none">
                        <ul className="signing-methods-list">
                            {allowSigningWithNap && (
                                <li
                                    className="mt-0"
                                    key={
                                        ANY_MITID_DENMARK_HIDDEN_NAME_VARIANT.type
                                    }>
                                    <RadioButton
                                        name="selectedMethod"
                                        className="flex gap-2"
                                        checked={
                                            selectedMethod?.type ===
                                            ANY_MITID_DENMARK_HIDDEN_NAME_VARIANT.type
                                        }
                                        onChange={() =>
                                            setSelectedMethod(
                                                ANY_MITID_DENMARK_HIDDEN_NAME_VARIANT
                                            )
                                        }>
                                        <span className="cursor-pointer mt-0.5 text-neutral-900">
                                            <img
                                                src={
                                                    ANY_MITID_DENMARK_HIDDEN_NAME_VARIANT.newFlag
                                                }
                                            />

                                            {i18n(
                                                ANY_MITID_DENMARK_HIDDEN_NAME_VARIANT?.extendedTitle ||
                                                    ANY_MITID_DENMARK_HIDDEN_NAME_VARIANT.title
                                            )}
                                        </span>
                                    </RadioButton>
                                </li>
                            )}

                            {signingMethods.map((method) => (
                                <li className="mt-0" key={method.type}>
                                    <RadioButton
                                        name="selectedMethod"
                                        className="flex gap-2"
                                        checked={
                                            selectedMethod?.type === method.type
                                        }
                                        onChange={() =>
                                            setSelectedMethod(method)
                                        }>
                                        <span className="cursor-pointer mt-0.5 text-neutral-900">
                                            <img src={method.newFlag} />

                                            {i18n(
                                                method?.extendedTitle ||
                                                    method.title
                                            )}
                                        </span>
                                    </RadioButton>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}

            <div className="flex justify-between">
                <Button
                    className="mt-5"
                    theme="blue"
                    variant="outline"
                    disabled={isLoading}
                    onClick={handleBackClick}>
                    {i18n('Back')}
                </Button>

                {signingMethods.length !== 0 || allowSigningWithNap ? (
                    <Button
                        className="mt-5"
                        theme="blue"
                        isLoading={isLoading}
                        disabled={!selectedMethod || isLoading}
                        onClick={handleContinueClick}>
                        {i18n('Continue')}
                    </Button>
                ) : null}
            </div>
        </div>
    );
}
