import { AppThunk } from 'Store';
import { SigningAPI } from 'Api';
import {
    Settings,
    ManagedSettings,
    CustomerSettingKeys,
    ManagedSettingKeys,
    UserSettingKeys,
} from './types';
import { ActionTypes } from './actionTypes';
import { settingsInitialState } from 'Settings/redux/reducer';

// Actions

// GET actions
export const fetchSettings = (): AppThunk => async (dispatch) => {
    dispatch({ type: ActionTypes.SETTINGS_FETCH_REQUEST });

    try {
        const settings: Settings = await SigningAPI.get(`/settings/all`);
        const payload = {
            user: {
                ...settingsInitialState.data.user,
                ...settings.user,
            },
            managed: {
                ...settingsInitialState.data.managed,
                ...settings.managed,
            },
            customer: {
                ...settingsInitialState.data.customer,
                ...settings.customer,
            },
        };

        dispatch({
            type: ActionTypes.SETTINGS_FETCH_SUCCESS,
            payload,
        });

        // Provide backwards compatibility with _settings property in AuthActionCreators.ts
        return settings;
    } catch (error) {
        dispatch({
            type: ActionTypes.SETTINGS_FETCH_FAILURE,
            payload: error,
        });
    }
};

export const fetchManagedSettings = (customerId: number): AppThunk => async (
    dispatch
) => {
    dispatch({ type: ActionTypes.SETTINGS_MANAGED_FETCH_REQUEST });

    try {
        const managedSettings: ManagedSettings = await SigningAPI.get(
            `/settings/managed/${customerId}`
        );
        const payload = {
            ...settingsInitialState.data.managed,
            ...managedSettings,
        };

        dispatch({
            type: ActionTypes.SETTINGS_MANAGED_FETCH_SUCCESS,
            payload,
        });
    } catch (error) {
        dispatch({
            type: ActionTypes.SETTINGS_MANAGED_FETCH_FAILURE,
            payload: error,
        });
    }
};

// PUT actions

export const updateUserSettings = (
    key: UserSettingKeys,
    setting: any
): AppThunk => async (dispatch) => {
    dispatch({ type: ActionTypes.SETTINGS_USER_UPDATE_REQUEST });

    const payload = {
        preferences: {
            ...setting,
        },
    };

    try {
        const { preferences } = await SigningAPI.put(
            `/user/preferences/${key}`,
            payload
        );

        /**
         * If the key is 'whatsNew', don't dispatch the SUCCESS action. This is
         * to be able to show the 'NEW' status on posts the user has not yet
         * seen. */
        if (key === UserSettingKeys.whatsNew) {
            return;
        }

        dispatch({
            type: ActionTypes.SETTINGS_USER_UPDATE_SUCCESS,
            payload: {
                key,
                settings: preferences,
            },
        });
    } catch (error) {
        dispatch({
            type: ActionTypes.SETTINGS_USER_UPDATE_FAILURE,
            payload: error,
        });
    }
};

export const updateManagedSetting = (
    customerId: number,
    key: ManagedSettingKeys,
    diff: any
): AppThunk => async (dispatch, getState) => {
    dispatch({ type: ActionTypes.SETTINGS_MANAGED_UPDATE_REQUEST });

    const settings = getState().settings.data.managed[key];

    const payload = {
        ...settings,
        ...diff,
    };

    try {
        await SigningAPI.put(`/settings/managed/${customerId}/${key}`, {
            value: payload,
        });

        dispatch({
            type: ActionTypes.SETTINGS_MANAGED_UPDATE_SUCCESS,
            payload: {
                key,
                settings: payload,
            },
        });
    } catch (error) {
        dispatch({
            type: ActionTypes.SETTINGS_MANAGED_UPDATE_FAILURE,
            payload: error,
        });
    }
};

export const updateCustomerSettings = (
    key: CustomerSettingKeys,
    setting: any
): AppThunk => async (dispatch, getState) => {
    dispatch({ type: ActionTypes.SETTINGS_CUSTOMER_UPDATE_REQUEST });

    const settings = getState().settings.data.customer[key];

    /**
     * Since the value could be a set of options (object) or an simple number/string/boolean
     * we check if it's an object and generate a payload with the differences
     * or we use the value as-is
     * NOTE: we also use the Array.isArray method, for the setting could be an array
     * and wrongly match the typeof 'object' check
     */
    const payload =
        typeof setting === 'object' && !Array.isArray(setting)
            ? {
                  ...settings,
                  ...setting,
              }
            : setting;

    try {
        await SigningAPI.put(`/customer/options/${key}`, { value: payload });

        dispatch({
            type: ActionTypes.SETTINGS_CUSTOMER_UPDATE_SUCCESS,
            payload: {
                key,
                settings: payload,
            },
        });
    } catch (error) {
        dispatch({
            type: ActionTypes.SETTINGS_CUSTOMER_UPDATE_FAILURE,
            payload: error,
        });
    }
};
