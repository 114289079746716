import produce from 'immer';
import { Dispatcher } from 'Core';
import Constants from '../Constants';
import { WorkflowAPI, PublicAuthAPI } from 'Api';
import FormActions from '../actions/FormActionCreators';

const WorkflowActionCreators = {
    next(id) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.WORKFLOW_RESUMED,
            workflowId: id,
        });
    },

    reject(id) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.WORKFLOW_REJECT,
            workflowId: id,
        });
    },

    fetchById(id) {
        if (!id) {
            return;
        }

        WorkflowAPI.get('/v2/workflows/' + id)
            .then((workflow) => {
                Dispatcher.handleViewAction({
                    type: Constants.ActionTypes.WORKFLOW_FETCH_BY_ID,
                    workflow: workflow,
                });
            })
            .catch((error) => {
                throw new Error(error);
            });
    },

    tag(workflowId, name) {
        if (!name || !workflowId) {
            return;
        }

        WorkflowAPI.patch('/v2/workflows/' + workflowId + '/tag/' + name)
            .then((workflow) => {
                // Dispatcher.handleViewAction({
                //     type: Constants.ActionTypes.WORKFLOW_TAGGED,
                //     workflow: workflow
                // });

                // @fixme: when tag changes, the WorkflowDetailStore is updated
                // This tag functionality should probably be in another actions file
                Dispatcher.handleServerAction({
                    type: 'WORKFLOW_DETAIL_UPDATED',
                    workflow: workflow,
                });
            })
            .catch((error) => {
                throw error;
            });
    },

    untag(workflowId, name) {
        if (!name || !workflowId) {
            return;
        }

        WorkflowAPI.patch('/v2/workflows/' + workflowId + '/untag/' + name)
            .then((workflow) => {
                // Dispatcher.handleViewAction({
                //     type: Constants.ActionTypes.WORKFLOW_UNTAGGED,
                //     workflow: workflow
                // });

                // @fixme: when tag changes, the WorkflowDetailStore is updated
                // This tag functionality should probably be in another actions file
                Dispatcher.handleServerAction({
                    type: 'WORKFLOW_DETAIL_UPDATED',
                    workflow: workflow,
                });
            })
            .catch((error) => {
                throw error;
            });
    },

    instantiate(token, data, attachments = []) {
        if (!token) {
            return;
        }

        WorkflowAPI.post(`/v2/prototype/workflows/${token}/instantiate`, data)
            .then((workflow) => {
                if (!workflow) {
                    throw new Error('Workflow could not be instantiated');
                }

                if (workflow.status === 'failed') {
                    throw new Error(
                        'Workflow instantiation failed at operation ' +
                            workflow.operation
                    );
                }

                if (attachments.length === 0) {
                    return workflow;
                }

                return this._persistPublicAttachments(
                    workflow,
                    attachments
                ).then(() => {
                    return workflow;
                });
            })
            .then((workflowData) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.WORKFLOW_INSTANTIATED,
                    workflow: workflowData,
                });
            })
            .catch((error) => {
                console.log(error);
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.WORKFLOW_INSTANTIATE_FAILED,
                    error: error,
                });
            });
    },

    publish(workflowId) {
        if (!workflowId) {
            return;
        }

        WorkflowAPI.patch('/v2/workflows/' + workflowId + '/publish')
            .then((workflow) => {
                Dispatcher.handleViewAction({
                    type: Constants.ActionTypes.WORKFLOW_PROTOTYPE_PUBLISHED,
                    workflow: workflow,
                });
            })
            .catch((error) => {
                throw new Error(error);
            });
    },

    _convertFieldsArray(fields) {
        let fieldObject = {};

        for (let i = 0; i < fields.length; i++) {
            fieldObject[fields[i].name] = fields[i].value || '-';
        }

        return fieldObject;
    },

    getWorkflow(workflowId) {
        WorkflowAPI.get('/v2/workflows/' + workflowId)
            .then((workflow) => {
                if (!workflow.id) {
                    throw new Error('Workflow Not Found');
                }

                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.WORKFLOW_PROTOTYPE_LOADED,
                    workflow: workflow,
                });
            })
            .catch((error) => {
                throw new Error(error);
            });
    },

    unpublish(workflowId) {
        if (!workflowId) {
            return;
        }

        WorkflowAPI.patch('/v2/workflows/' + workflowId + '/unpublish')
            .then((workflow) => {
                Dispatcher.handleViewAction({
                    type: Constants.ActionTypes.WORKFLOW_PROTOTYPE_UNPUBLISHED,
                    workflow: workflow,
                });
            })
            .catch((error) => {
                throw new Error(error);
            });
    },

    reactivateWorkflow(workflowId) {
        if (!workflowId) {
            return;
        }

        WorkflowAPI.patch('/v2/workflows/' + workflowId + '/reset')
            .then((workflow) => {
                Dispatcher.handleViewAction({
                    type: 'WORKFLOW_REACTIVATE_SUCCESS',
                    workflow: workflow,
                });
            })
            .catch((error) => {
                Dispatcher.handleViewAction({
                    type: 'WORKFLOW_REACTIVATE_FAILURE',
                    error: error,
                });
            });
    },

    getPublicWorkflow(workflowToken) {
        if (!workflowToken) {
            return false;
        }

        let options = {
            silent: true,
        };

        WorkflowAPI.get(
            `/v2/prototype/workflows/${workflowToken}`,
            null,
            options
        )
            .then((workflow) => {
                if (!workflow.id) {
                    throw new Error('Workflow Not Found');
                }

                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.WORKFLOW_PROTOTYPE_LOADED,
                    workflow: workflow,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.WORKFLOW_PROTOTYPE_NOT_FOUND,
                    error: error,
                });
            });
    },

    _removeEmptyKeys(obj) {
        Object.keys(obj).forEach(
            (key) =>
                (obj[key] &&
                    typeof obj[key] === 'object' &&
                    this._removeEmptyKeys(obj[key])) ||
                ((typeof obj[key] === 'undefined' || obj[key] === null) &&
                    delete obj[key])
        );

        return obj;
    },

    saveWorkflowPrototype(workflowPrototype) {
        let payload = produce(workflowPrototype, (draft) => {
            this._removeEmptyKeys(draft);
        });

        WorkflowAPI.post('/v2/workflows', payload).then((response) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.WORKFLOW_PROTOTYPE_CREATED,
                workflow: response,
            });
        });
    },

    updateWorkflowPrototype(workflowId, workflow) {
        // Clean Workflow Keys
        let payload = {
            title: workflow.title,
            userData: workflow.userData,
            stateMachine: workflow.stateMachine,
            prototype: true,
            language: workflow.language,
            settings: workflow.settings,
        };

        WorkflowAPI.put('/v2/workflows/' + workflowId, payload).then(
            (response) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.WORKFLOW_PROTOTYPE_UPDATED,
                    workflow: response,
                });
            }
        );
    },

    async fetchWorkflowPrototype(workflowId) {
        WorkflowAPI.get('/v2/workflows/' + workflowId)
            .then((workflow) => {
                Dispatcher.handleViewAction({
                    type: Constants.ActionTypes.WORKFLOW_PROTOTYPE_LOADED,
                    workflow: workflow,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    },

    /**
     * Dispatches an action to update form attachments.
     * @param  {FileList} files @see https://developer.mozilla.org/en/docs/Web/API/FileList
     */
    updateAttachments(files) {
        let attachments = [];

        for (let i = 0; i < files.length; i++) {
            attachments.push({
                file: files[i],
                name: files[i].name,
            });
        }

        Dispatcher.handleServerAction({
            type: 'UPDATE_FORM_ATTACHMENTS',
            attachments: attachments,
        });
    },

    removeAttachment(index) {
        Dispatcher.handleServerAction({
            type: 'REMOVE_FORM_ATTACHMENT',
            index: index,
        });
    },

    /**
     * Starts attaching document to Workflow and returns Promise to
     * listen on the full process completion after file upload.
     * returns {Promise}
     */
    _persistPublicAttachments(workflow, attachments) {
        let data = {
            token: workflow.metaData.token,
        };

        return PublicAuthAPI.post(`/token/preshared?compatibility=false`, data)
            .then((response) => {
                let promises = attachments.map((attachment) => {
                    return this._persistAttachment(
                        workflow.id,
                        attachment,
                        response.token
                    );
                });

                return Promise.all(promises);
            })
            .then((documents) => {
                return documents;
            });
    },

    /**
     * Starts attaching document to Workflow and returns Promise to
     * listen on the full process completion after file upload.
     * returns {Promise}
     */
    persistAttachments(workflowId, attachments) {
        if (!attachments || attachments.length === 0) {
            return Promise.resolve();
        }

        let promises = attachments.map((attachment) => {
            return this._persistAttachment(workflowId, attachment);
        });

        return Promise.all(promises);
    },

    /**
     * Creates a document attachment in a specific workflow.
     * @param  data {object}  object containing title for document.
     *
     * @return {Promise} contains document resource that was created.
     */
    _persistAttachment(workflowId, attachment, token) {
        let data = {
            title: attachment.name,
        };

        let options = {};

        if (token) {
            options = {
                token: token,
            };
        }

        let endpoint = `/v2/workflows/${workflowId}/documents`;

        return WorkflowAPI.post(endpoint, data, options).then((doc) => {
            let content = {
                file: attachment.file,
            };

            return WorkflowAPI.file(
                `${endpoint}/${doc.id}/content`,
                content,
                options
            ).then(() => {
                return doc;
            });
        });
    },

    updateForm(data) {
        let { fields, workflowId, formId, attachments, coSigner } = data;

        FormActions._updateFormFields(formId, fields)
            .then(() => this.persistAttachments(workflowId, attachments))
            .then(() => this.persistCoSigner(workflowId, coSigner))
            .then(() => {
                Dispatcher.handleServerAction({
                    type: 'WORKFLOW_FORM_SAVED',
                    data: data,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: 'WORKFLOW_FORM_SAVE_ERROR',
                    error: error,
                });
            });
    },

    /**
     * Patches workflow's userData with co-signer data if both name and email are set
     * returns {Promise}
     */
    persistCoSigner(workflowId, coSigner) {
        if (!coSigner.name || !coSigner.email) {
            return Promise.resolve();
        }

        let data = {
            signers: [
                {
                    name: coSigner.name,
                    email: coSigner.email,
                },
            ],
        };

        return WorkflowAPI.patch('/v2/workflows/' + workflowId, data);
    },

    updateFormattedData(data) {
        Dispatcher.handleViewAction({
            type: 'FORMATTED_DATA_LOADED',
            data: data,
        });
    },
};

export default WorkflowActionCreators;
