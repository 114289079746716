import { createComponent } from '@lit/react';
import { Toggle } from '@penneo/vega-ui';
import React from 'react';

const UIToggle = createComponent({
    tagName: 'vega-toggle',
    elementClass: Toggle,
    react: React,
});

export default UIToggle;
