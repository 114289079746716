import React, { ChangeEvent, useRef } from 'react';
import { i18n } from 'Language';

export function DocumentFormAttachment({
    accept = '',
    attachments = [],
    onFileChangeHandler,
    onFileRemoveHandler,
    required,
}: {
    accept: string;
    attachments: File[];
    onFileChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
    onFileRemoveHandler: (index: number) => void;
    required: boolean;
}) {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleFileRemove = (index: number) => {
        // Clear the FileList from the element.
        inputRef.current!.files = new DataTransfer().files;

        onFileRemoveHandler(index);
    };

    return (
        <div className="form-attachment">
            <div className="title">
                {i18n`Add attachments`}
                {required && (
                    <i className="required fa fa-asterisk text-error" />
                )}
            </div>

            <label
                className={
                    'upload-area' + (attachments.length > 0 ? ' has-items' : '')
                }>
                <input
                    ref={inputRef}
                    type="file"
                    name="files[]"
                    accept={accept}
                    className="file"
                    onChange={onFileChangeHandler}
                    multiple={true}
                />
                <span>
                    {i18n`Click here to upload files`}&nbsp;
                    <i className="fa fa-upload" />
                </span>
            </label>

            <div className="file-list">
                <ul>
                    {attachments.map((attachment, index) => (
                        <li key={index} title={attachment.name}>
                            <span className="file-title">
                                <i className="fa fa-paperclip" />
                                &nbsp;&nbsp;
                                {attachment.name}
                            </span>

                            <span
                                className="remove cursor-pointer"
                                onClick={() => handleFileRemove(index)}>
                                <i className="fa fa-times" />
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
