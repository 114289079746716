import { createActions } from 'Core/ActionUtils';

const prefix = 'NEWS';

export let ActionTypes = createActions(prefix, [
    'FETCH_REQUEST',
    'FETCH_SUCCESS',
    'FETCH_FAILURE',
    'TOGGLE_WIDGET',
    'UPDATE_TIMESTAMP_REQUEST',
    'UPDATE_TIMESTAMP_SUCCESS',
]);

const newsContants = {
    NEWS_FEED_URL: 'https://penneo.com/blog/category/product-news/feed/json',
};

export default newsContants;
