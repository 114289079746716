import PropTypes from 'prop-types';
import React from 'react';

export default class DashboardMenuItem extends React.Component {
    static propTypes = {
        children: PropTypes.object,
        branding: PropTypes.object,
        name: PropTypes.string,
        controls: PropTypes.string,
    };

    state = {
        open: false,
    };

    handleClick = () => {
        this.close();
    };

    open = () => {
        this.setState({
            open: true,
        });
    };

    close = () => {
        this.setState({
            open: false,
        });
    };

    toggledClick = () => {
        if (this.state.open) {
            this.close();

            return;
        }

        this.open();
    };

    render() {
        const { controls } = this.props;
        let { open } = this.state;
        let aria = {
            'aria-label': this.props.name ?? 'Menu',
            ...{
                'aria-controls': controls,
                role: 'switch',
                'aria-expanded': open,
                'aria-checked': open,
            },
        };

        return (
            <button
                className={'dashboard-menu-item' + (open ? ' open' : '')}
                onMouseEnter={this.open}
                onMouseLeave={this.close}
                onClick={this.toggledClick}
                {...aria}
                style={{ borderTopColor: this.props.branding.highlightColor }}>
                {React.cloneElement(this.props.children, {
                    closeDropdown: this.handleClick,
                    open: open,
                })}
            </button>
        );
    }
}
