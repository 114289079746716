import React, { ErrorInfo } from 'react';
import Message from 'Common/components/Message';
import Button from 'Common/components/Button';
import './EutlErrorHandler.scss';
import { i18n } from 'Language';
//Redux
import { connect } from 'react-redux';
import { resetState } from 'OpenID/redux/actions';

type EutlErrorBoundaryProps = {
    error?: Error | string | Record<string, string | boolean | number>;
    children?:
        | React.ReactChildren
        | React.ReactChildren[]
        | React.ReactElement
        | React.ReactElement[];
} & { dispatch: (...args: any) => void };

type EutlErrorBoundaryState = {
    hasError: boolean;
    error?: Error | string | null;
};

//TODO: Maybe for future use cases - lets abuse this piece of code from the hot-reload client
//https://github.com/pmmmwh/react-refresh-webpack-plugin/blob/f921063d9a584f29c14b6a76d708a5f29abcae9c/client/utils/errorEventHandlers.js
class EutlErrorHandler extends React.Component<
    EutlErrorBoundaryProps,
    EutlErrorBoundaryState
> {
    constructor(props: EutlErrorBoundaryProps) {
        super(props);

        this.state = {
            hasError: props.error ? true : false,
            error: (props.error as unknown) as any,
        };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    cleanObjectForUndefinedValues = (
        obj: Record<string, string | number | null | undefined>
    ) =>
        Object.entries(obj).reduce(
            (a, [k, v]) => (v ? ((a[k] = v), a) : a),
            {}
        );

    parseError = (): { message: string; code: string | number } | void => {
        if (!this.state.error) return;

        const error = this.cleanObjectForUndefinedValues(
            this.state?.error as Record<string, any>
        );
        const stateError = (this.state.error as unknown) as Record<
            string,
            string | number
        >;

        const returned = {
            ...{
                message:
                    ((!stateError?.toString()?.includes('object') &&
                        stateError?.toString()) ||
                        (stateError?.statusText as string)) ??
                    'Unknown Error',
                code: stateError?.status ?? stateError?.code ?? 0,
            },
            ...error,
        };

        return returned;
    };

    getProdSafeErrorDetails = () => {
        let error = this.state.error;

        if (process.env.NODE_ENV === 'production') {
            if (typeof this.state.error !== 'string') {
                return JSON.stringify(
                    this.cleanObjectForUndefinedValues?.({
                        ...{ message: 'Unknown Error' },
                        code: ((error as unknown) as any)?.code,
                        message:
                            ((error as unknown) as any)?.message ??
                            (error?.toString() !== '[object Object]' &&
                                error?.toString()) ??
                            'Unknown Error',
                        status: ((error as unknown) as any)?.status,
                        details: ((error as unknown) as any)?.details,
                    }),
                    null,
                    2
                );
            }
        }

        return (
            (!this.state.error?.toString()?.includes('object') &&
                this.state.error?.toString()) ||
            JSON.stringify(this.state.error, null, 2)
        );
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        //Perhaps log to sentry?
        console.error('An error occurred', error, { errorInfo });

        return;
    }

    componentDidUpdate(prevProps: EutlErrorBoundaryProps) {
        if (
            prevProps.error !== this.state.error ||
            (!prevProps.error && this.props.error) ||
            (!this.state.error && this.props.error) ||
            (!this.state.hasError && this.props.error)
        ) {
            this.setState({
                error: (this.props.error as unknown) as any,
                hasError: true,
            });
        }
    }

    retry = () => {
        this.props.dispatch(resetState());
        window.location.reload();
    };

    render() {
        if (this.state.hasError) {
            const renderedError = this.parseError();

            return (
                <div data-testid="error-boundary">
                    <h3 className="mt0 mb0">{i18n`Uh oh! Seems like this didn't go as we expected`}</h3>
                    <Message type="error">
                        <>
                            <div>
                                <p>Following error occurred:</p>
                                <pre>
                                    Code: {renderedError?.code + `\n\n`}
                                    Message:
                                    {`\n` + renderedError?.message}
                                </pre>
                            </div>
                        </>
                    </Message>
                    <details className="toggle-error">
                        <summary>
                            Error Details
                            <i className="far fa-chevron-down"></i>
                        </summary>
                        <pre
                            style={{
                                maxWidth: 320,
                                overflowY: 'scroll',
                            }}>
                            {this.getProdSafeErrorDetails()}
                        </pre>
                    </details>
                    <div className="center-button">
                        <Button
                            onClick={this.retry}
                            theme="blue"
                            variant="outline"
                            icon="far fa-sync">
                            {i18n`Try again`}
                        </Button>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default connect(null, (dispatch) => ({ dispatch }))(EutlErrorHandler);
