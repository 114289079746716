import PropTypes from 'prop-types';
import React from 'react';
import Constants from '../../Constants';
import { DragSource } from 'react-dnd';

let fieldSource = {
    beginDrag(props) {
        return {
            type: 'sidebar-field',
            fieldId: props.field.id,
        };
    },
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

export default DragSource(
    Constants.ItemTypes.FIELD,
    fieldSource,
    collect
)(
    class extends React.Component {
        static propTypes = {
            field: PropTypes.object,
            connectDragSource: PropTypes.func.isRequired,
        };

        getType = () => {
            let { field } = this.props;

            let fieldObject = Constants.FieldTypes.filter((type) => {
                return field.metaData.type === type.id;
            });

            return fieldObject[0].name;
        };

        render() {
            let { field, connectDragSource } = this.props;

            return connectDragSource(
                <span key={field.id} className="input-draggable-item">
                    <span className="input-type">{this.getType()}</span>
                    {field.label}
                </span>
            );
        }
    }
);
