import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from 'react';
import { debounce } from 'lodash';
import { useAppDispatch, useAppSelector } from 'Store';

import { i18n } from 'Language';
import { TextInput, ToggleInput } from 'Common/components';
import InputValidation from 'Common/components/InputValidation';
import Tooltip from 'Common/components/Tooltip';
import analytics from 'Common/Analytics';

import { Client, Receiver } from 'AuditingAccounting/types';
import {
    lookupClients,
    fetchClientDetails,
    clientsListSelector,
    selectedClientSelector,
    setSelectedClient,
    clearSelectedClient,
} from 'AuditingAccounting/redux/reducer';

import './client-selector.scss';

type ClientSelectorProps = {
    useClientApi?: boolean;
    onSwitchEntity?: (type: Receiver) => void;
};

const ClientSelector: React.FC<ClientSelectorProps> = ({
    useClientApi,
    onSwitchEntity,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [receiver, setReceiverValue] = useState<Receiver>('company');
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const clients = useAppSelector(clientsListSelector);
    const selectedClient = useAppSelector(selectedClientSelector);

    const DEBOUNCE_LOOKUP_DELAY: number = 200;
    const AUTOCOMPLETE_CLOSING_DELAY: number = 100;

    const dispatch = useAppDispatch();
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (useClientApi) {
            selectedClient && setInputValue(selectedClient.name);
        }
    }, [selectedClient]);

    useEffect(() => {
        !isFocused && setShowAutocomplete(false);
    }, [isFocused]);

    /**
     * When the field is blurred it needs to be deferred,
     * so that if the user selected a new value in the autocomplete list
     * the autocomplete would not close before the onClick event would be triggered.
     */
    const handleOnBlur = (event) => {
        /**
         * Tracks the length of the value and whether it's a VATIN (loose comparison)
         *
         * This is tracking only when the user has inputted something in
         * the client field and moves away from the field
         */
        if (event.target.value) {
            analytics.track('client selector - user input', {
                length: event.target.value.length,
                /**
                 * This is a simple VATIN regex that should match most EU VATINs. It's good enough for the purpose of tracking this feature
                 */
                isVatin: /([A-Z]{2})?[0-9]{8,10}/.test(event.target.value),
            });
        }

        setTimeout(() => {
            // Prevents state update on an unmounted component
            // State update of the unmounted component would occur due to the delay,
            // when the handleOnBlur is called while component is being unmounted (route changed etc.)
            if (!isMounted.current) {
                return;
            }

            setIsFocused(false);
        }, AUTOCOMPLETE_CLOSING_DELAY);
    };

    const handleOnFocus = (): void => {
        setIsFocused(true);
    };

    const handleOnClick = (): void => {
        setShowAutocomplete(!showAutocomplete);
    };

    const isClientNameInvalid = (): boolean => {
        const isFieldBlurred = !isFocused;
        const isThereTextInField = !!inputValue;

        return isFieldBlurred && isThereTextInField && !selectedClient;
    };

    //TODO: Regex Validation as based on: https://www.gov.uk/guidance/vat-eu-country-codes-vat-numbers-and-vat-in-other-languages
    const simpleVatinRegex = /([0-9a-zA-Z])/; // No special chars
    const isClientAVatin = (): boolean => {
        const isFieldBlurred = !isFocused;
        const isThereTextInField = !!inputValue;

        return (
            isFieldBlurred &&
            isThereTextInField &&
            !simpleVatinRegex.test(inputValue)
        );
    };

    const handleQueryChange = (query: string): void => {
        if (selectedClient) {
            dispatch(clearSelectedClient());
        }

        setInputValue(query);

        if (!useClientApi && simpleVatinRegex.test(query)) {
            const client = {
                name: query,
                vatin: query,
                dirtyData: true,
                countryCode: 'null',
                type: receiver,

                persons: [],
            };

            dispatch(setSelectedClient(client));
        }

        //If no client api return early
        if (!useClientApi) return;

        debouncedSearchClients(query);
    };

    const searchClients = useCallback(
        async (query: string): Promise<void> => {
            dispatch(lookupClients(query));
        },
        [dispatch]
    );

    const debouncedSearchClients = useMemo(
        () => debounce(searchClients, DEBOUNCE_LOOKUP_DELAY),
        [searchClients]
    );

    const onSelectedChange = (client: Client): void => {
        dispatch(setSelectedClient(client));
        dispatch(fetchClientDetails(client.vatin));
    };

    const onCaseReceiverTypeChange = (value: Receiver) => {
        setReceiverValue(value);
        onSwitchEntity?.(value);
        analytics.track('Clicked Client toggle', { value });

        //TODO: When we after the fact (in the clients overview) loop through the data
        // we will have to do a "sane lookup" and try to deduct the estimated client based
        // on recipients (THIS IS A BIG TODO)

        const client = {
            type: value,
            countryCode: 'null',
            persons: [],
            dirtyData: true,
        };

        if (value !== 'company') {
            dispatch(
                setSelectedClient({
                    ...client,
                    name: 'null',
                    vatin: 'null',
                })
            );

            return;
        }

        dispatch(
            setSelectedClient({
                ...client,
                name: inputValue,
                vatin: inputValue,
            })
        );
    };

    return (
        <>
            {!useClientApi && (
                <ToggleInput
                    value={receiver}
                    onChange={onCaseReceiverTypeChange}
                    options={[
                        {
                            value: 'company',
                            label: (
                                <span>
                                    {i18n`Company`}
                                    &nbsp;
                                    <i className="far fa-building"></i>
                                </span>
                            ),
                        },
                        {
                            value: 'person',
                            label: (
                                <span>
                                    {i18n`Person`}
                                    &nbsp;
                                    <i className="far fa-user"></i>
                                </span>
                            ),
                        },
                    ]}
                />
            )}

            {receiver === 'company' && (
                <div className="mb">
                    <label>
                        {i18n`Client`}
                        &nbsp;
                        <Tooltip
                            direction="up"
                            showArrow={true}
                            horizontalOffset={40}
                            text={
                                <span className="casefile-document-details-key-tooltip">
                                    {i18n`Add your client's company to get suggestions for signers when public data is available`}
                                </span>
                            }>
                            <i className="far text-blue fa-question-circle" />
                        </Tooltip>
                    </label>
                    <div className="client-selector">
                        <div className="client-selector-input">
                            <InputValidation
                                rules={[
                                    {
                                        error: {
                                            message: i18n`Client not recognized`,
                                        },
                                        test: () =>
                                            (useClientApi &&
                                                isClientNameInvalid?.()) ||
                                            false,
                                    },
                                    {
                                        error: {
                                            message: i18n`Input a valid VATIN`,
                                        },
                                        test: () => {
                                            return (
                                                !useClientApi &&
                                                isClientAVatin()
                                            );
                                        },
                                    },
                                ]}
                                triggers={[inputValue, isFocused]}>
                                <TextInput
                                    className="client-lookup-input"
                                    name="cvrLookupInput"
                                    type="text"
                                    value={inputValue}
                                    placeholder={i18n`Write company name or CVR`}
                                    autoComplete="off"
                                    onChange={(query: string) =>
                                        handleQueryChange(query)
                                    }
                                    autoFocus={true}
                                    onClick={handleOnClick}
                                    onBlur={handleOnBlur}
                                    onFocus={handleOnFocus}
                                />
                            </InputValidation>
                        </div>
                        {showAutocomplete && (
                            <div className="client-selector-autocomplete">
                                {clients.map((client, index) => (
                                    <div
                                        className="autocomplete-client"
                                        onClick={() => onSelectedChange(client)}
                                        key={index}>
                                        <div className="autocomplete-client-name">
                                            {client.name}
                                        </div>
                                        <div className="autocomplete-client-vatin">
                                            <span>{i18n`CVR:`}</span>
                                            &nbsp;
                                            <span
                                                style={{
                                                    fontWeight: 'bold',
                                                }}>
                                                {client.vatin}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ClientSelector;
