import produce from 'immer';
import createReducer from 'create-reducer';
import { EmailTemplate, TemplateType } from 'types/EmailTemplates';
import { RecipientSigner } from 'types/Recipient';
import { EmailMessage } from 'Casefiles/redux/newCaseFile/types';
import {
    CASEFILE_EMAIL_MESSAGE_UPDATED,
    CASEFILE_EMAIL_TEMPLATE_UPDATED,
    EDIT_EMAIL_BODY,
    EDIT_EMAIL_SUBJECT,
    NEW_CUSTOM_EMAIL_MESSAGE,
    REMOVE_CUSTOM_EMAIL_MESSAGE,
    REMOVE_RECIPIENT_FROM_ALL_MESSAGES,
    RESET_CASEFILE_EMAIL_TEMPLATES,
    SET_CUSTOM_EMAIL_RECIPIENTS,
    SET_INITIAL_EMAIL_MESSAGES,
} from './action-types';
import { validateEmailTemplate } from 'Casefiles/utils/casefileValidation';
import { NewCasefileState } from './types';

export const newCasefileInitialState: NewCasefileState = {
    emailMessages: {
        general: {
            messages: {
                [TemplateType.SIGNER]: {
                    default: false,
                    body: { content: '' },
                    subject: { content: '' },
                    title: '',
                },
                [TemplateType.COMPLETED]: {
                    default: false,
                    body: { content: '' },
                    subject: { content: '' },
                    title: '',
                },
                [TemplateType.REMINDER]: {
                    default: false,
                    body: { content: '' },
                    subject: { content: '' },
                    title: '',
                },
            },
            validation: {
                [TemplateType.SIGNER]: { valid: true, errors: {} },
                [TemplateType.COMPLETED]: { valid: true, errors: {} },
                [TemplateType.REMINDER]: { valid: true, errors: {} },
            },
        },
        custom: [],
        isLoaded: false,
    },
    emailTemplates: {
        [TemplateType.SIGNER]: {
            id: null,
            title: null,
            subject: '',
            message: '',
            custom: false,
            default: false,
        },
        [TemplateType.COMPLETED]: {
            id: null,
            title: null,
            subject: '',
            message: '',
            custom: false,
            default: false,
        },
        [TemplateType.REMINDER]: {
            id: null,
            title: null,
            subject: '',
            message: '',
            custom: false,
            default: false,
        },
    },
    validation: {
        emailTemplates: {
            [TemplateType.SIGNER]: { valid: false, errors: {} },
            [TemplateType.COMPLETED]: { valid: false, errors: {} },
            [TemplateType.REMINDER]: { valid: false, errors: {} },
        },
    },
};

const newCasefileReducer = createReducer<NewCasefileState>(
    newCasefileInitialState,
    {
        [CASEFILE_EMAIL_TEMPLATE_UPDATED]: (state, { type, template }) =>
            produce(state, (draftState) => {
                draftState.emailTemplates[type] = template;
                draftState.validation.emailTemplates[
                    type
                ] = validateEmailTemplate(template);
            }),
        [SET_INITIAL_EMAIL_MESSAGES]: (
            state,
            payload: {
                generalMessages: NewCasefileState['emailMessages']['general']['messages'];
                customMessages: {
                    messages: NewCasefileState['emailMessages']['custom'][number]['messages'];
                    recipients: NewCasefileState['emailMessages']['custom'][number]['recipients'];
                }[];
            }
        ) => {
            const { SIGNER, COMPLETED, REMINDER } = TemplateType;
            const { generalMessages, customMessages } = payload;

            const generalMessagesPayload = {
                [SIGNER]: generalMessages![SIGNER],
                [COMPLETED]: generalMessages![COMPLETED],
                [REMINDER]: generalMessages![REMINDER],
            };

            const customMessagesPayload = customMessages.map(
                (customMessage) => ({
                    ...customMessage,
                    validation: {
                        [SIGNER]: { valid: true, errors: {} },
                        [COMPLETED]: { valid: true, errors: {} },
                        [REMINDER]: { valid: true, errors: {} },
                    },
                })
            );

            return produce(state, (draftState) => {
                draftState.emailMessages.isLoaded = true;
                draftState.emailMessages.general.messages = generalMessagesPayload;
                draftState.emailMessages.custom = customMessagesPayload;

                // Validate general messages
                [SIGNER, COMPLETED, REMINDER].forEach((templateType) => {
                    draftState.emailMessages.general.validation[
                        templateType
                    ] = validateEmailTemplate({
                        subject:
                            generalMessagesPayload[templateType].subject
                                .content,
                        message:
                            generalMessagesPayload[templateType].body.content,
                    });
                });

                // Validate all custom messages
                draftState.emailMessages.custom.forEach((emailMessage) => {
                    emailMessage.validation[SIGNER] = validateEmailTemplate({
                        subject: emailMessage.messages[SIGNER].subject.content,
                        message: emailMessage.messages[SIGNER].body.content,
                    });
                });
            });
        },
        [CASEFILE_EMAIL_MESSAGE_UPDATED]: (
            state,
            {
                type,
                template,
                customMessageIndex = -1,
            }: {
                type: TemplateType;
                template: EmailTemplate;
                customMessageIndex?: number;
            }
        ) => {
            const emailMessage = {
                body: { content: template.message },
                default: !!template.default,
                id: template.id,
                subject: { content: template.subject },
                title: template.title,
            };

            if (customMessageIndex >= 0) {
                return produce(state, (draftState) => {
                    draftState.emailMessages.custom[
                        customMessageIndex
                    ].messages[type] = emailMessage;

                    draftState.emailMessages.custom[
                        customMessageIndex
                    ].validation[type] = validateEmailTemplate({
                        message: emailMessage.body.content,
                        subject: emailMessage.subject.content,
                    });
                });
            }

            return produce(state, (draftState) => {
                draftState.emailMessages.general.messages[type] = emailMessage;

                draftState.emailMessages.general.validation[
                    type
                ] = validateEmailTemplate({
                    message: emailMessage.body.content,
                    subject: emailMessage.subject.content,
                });
            });
        },
        [REMOVE_RECIPIENT_FROM_ALL_MESSAGES]: (
            state,
            payload: { tempId: string }
        ) => {
            return produce(state, (draftState) => {
                draftState.emailMessages.custom = draftState.emailMessages.custom.map(
                    (message) => ({
                        ...message,
                        recipients: [
                            ...message.recipients.filter(
                                (recipient) =>
                                    recipient.tempId !== payload.tempId
                            ),
                        ],
                    })
                );
            });
        },
        [RESET_CASEFILE_EMAIL_TEMPLATES]: (state) =>
            produce(state, (draftState) => {
                draftState.emailTemplates =
                    newCasefileInitialState.emailTemplates;
                draftState.validation.emailTemplates =
                    newCasefileInitialState.validation.emailTemplates;
                draftState.emailMessages =
                    newCasefileInitialState.emailMessages;
            }),
        [NEW_CUSTOM_EMAIL_MESSAGE]: (state, message: EmailMessage) => {
            return produce(state, (draftState) => {
                draftState.emailMessages.custom.push({
                    messages: { [TemplateType.SIGNER]: message },
                    recipients: [],
                    validation: {
                        [TemplateType.SIGNER]: {
                            valid: true,
                            errors: {},
                        },
                        [TemplateType.COMPLETED]: {
                            valid: true,
                            errors: {},
                        },
                        [TemplateType.REMINDER]: {
                            valid: true,
                            errors: {},
                        },
                    },
                });
            });
        },
        [REMOVE_CUSTOM_EMAIL_MESSAGE]: (state, { index }: { index: number }) =>
            produce(state, (draftState) => {
                draftState.emailMessages.custom.splice(index, 1);
            }),
        [EDIT_EMAIL_SUBJECT]: (
            state,
            {
                subject,
                type,
                customMessageIndex = -1,
            }: {
                subject: string;
                type: TemplateType;
                customMessageIndex?: number;
            }
        ) =>
            produce(state, (draftState) => {
                const isGeneralMessage = customMessageIndex < 0;

                const messageToModify: EmailMessage = isGeneralMessage
                    ? draftState.emailMessages.general.messages![type]
                    : draftState.emailMessages.custom[customMessageIndex]
                          .messages[type];

                messageToModify.subject = { content: subject };
                messageToModify.title = '';
                messageToModify.default = false;
                delete messageToModify.id;

                if (isGeneralMessage) {
                    draftState.emailMessages.general.validation[
                        type
                    ] = validateEmailTemplate({
                        subject: messageToModify.subject.content,
                        message: messageToModify.body.content,
                    });

                    return;
                }

                draftState.emailMessages.custom[customMessageIndex].validation[
                    type
                ] = validateEmailTemplate({
                    subject: messageToModify.subject.content,
                    message: messageToModify.body.content,
                });
            }),
        [EDIT_EMAIL_BODY]: (
            state,
            {
                body,
                type,
                customMessageIndex = -1,
            }: {
                body: string;
                type: TemplateType;
                customMessageIndex?: number;
            }
        ) =>
            produce(state, (draftState) => {
                const isGeneralMessage = customMessageIndex < 0;

                const messageToModify: EmailMessage = isGeneralMessage
                    ? draftState.emailMessages.general.messages![type]
                    : draftState.emailMessages.custom[customMessageIndex]
                          .messages[type];

                messageToModify.body = { content: body };
                messageToModify.title = '';
                messageToModify.default = false;
                delete messageToModify.id;

                if (isGeneralMessage) {
                    draftState.emailMessages.general.validation[
                        type
                    ] = validateEmailTemplate({
                        subject: messageToModify.subject.content,
                        message: messageToModify.body.content,
                    });

                    return;
                }

                draftState.emailMessages.custom[customMessageIndex].validation[
                    type
                ] = validateEmailTemplate({
                    subject: messageToModify.subject.content,
                    message: messageToModify.body.content,
                });
            }),
        [SET_CUSTOM_EMAIL_RECIPIENTS]: (
            state,
            {
                index,
                recipients,
            }: { index: number; recipients: RecipientSigner[] }
        ) =>
            produce(state, (draftState) => {
                draftState.emailMessages.custom[index].recipients = recipients;
            }),
    }
);

export default newCasefileReducer;
