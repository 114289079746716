import React from 'react';
import { connect } from 'react-redux';
import { AppDispatch } from 'Store';
import Card from 'Common/components/Card';
import { i18n } from 'Language';
import './customer-feature-toggle.scss';
import CustomerStore from '../../stores/CustomerStore';
import CustomerActions from '../../actions/CustomerActionCreators';
import { updateCustomer } from 'Auth/redux/customer/actions';

type Props = {
    customerId: number;
    dispatch: AppDispatch;
    details: any;
    name: string;
    value: boolean;
};

class CustomerPropertyToggle extends React.Component<Props> {
    handleCardToggle = () => {
        const { customerId, dispatch, value, name } = this.props;

        // Need to also update the flux store, until we fully migrate to redux
        CustomerActions.updateCustomer({
            ...CustomerStore.getCustomer(customerId),
            ...{ [name]: !value },
        });

        dispatch(
            updateCustomer(customerId, {
                [name]: !value,
            })
        );
    };

    render() {
        const { details, value } = this.props;

        return (
            <Card
                className="customer-feature-toggle"
                enableHover
                onClick={this.handleCardToggle}
                theme="blue">
                <div className="customer-feature-toggle-content">
                    <div className="feature-icon">
                        <i className={`far fa-lg fa-${details.icon}`} />
                    </div>

                    <div className="feature-description">
                        <h3>{i18n(details.title)}</h3>
                        <p>{i18n(details.subtitle)}</p>
                    </div>

                    {details.type === 'toggle' && (
                        <div className="feature-switch-icon">
                            {value === true ? (
                                <i className="fal fa-toggle-on text-blue"></i>
                            ) : (
                                <i className="fal fa-toggle-on fa-flip-horizontal text-light" />
                            )}
                        </div>
                    )}
                </div>
            </Card>
        );
    }
}

export default connect(() => ({}))(CustomerPropertyToggle);
