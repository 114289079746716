import React from 'react';
import LoadingData from '../Loaders/LoadingData';
import AuthStore from 'Auth/stores/AuthStore';
import moment from 'moment';
import lodash from 'lodash';

export const getDaysToExpire = (expireAt: number) =>
    Math.abs(
        moment()
            .startOf('day')
            .add(1, 'hour')
            .diff(moment.unix(expireAt), 'days')
    );

export const getExpireTimestamp = (item) => {
    if (item.expireAt) {
        return item.expireAt;
    }

    // Derive expire time from creation time + customer expiry policy
    const customer = AuthStore.getCustomer();
    const { caseFileExpiryInterval = 30 } = customer; // defaults to 30

    return moment.unix(item.created).add(caseFileExpiryInterval, 'days').unix();
};

export const getSortedByExpiration = (items) => {
    const withExpiration = items.map((item) => {
        const expireAt = getExpireTimestamp(item);

        return {
            ...item,
            expireAt,
            daysToExpire: getDaysToExpire(expireAt),
        };
    });

    // sort all items by expiry date, with sooner to expire first
    return lodash.sortBy(withExpiration, 'daysToExpire');
};

export const renderLoading = () => (
    <div className="mt">
        <LoadingData />
    </div>
);
