import Button from 'Common/components/Button';
import Message from 'Common/components/Message';
import { i18n } from 'Language/i18n';
import React, { ErrorInfo } from 'react';
import { SMSError } from './types';

type SMSErrorBoundaryProps = {
    error?: SMSError & Error;
    children?: React.ReactChild;
};

type SMSErrorBoundaryState = {
    hasError: boolean;
    error?: SMSError & Error;
};

export default class SMSErrorBoundary extends React.Component<
    SMSErrorBoundaryProps,
    SMSErrorBoundaryState
> {
    constructor(props: SMSErrorBoundaryProps) {
        super(props);

        this.state = {
            hasError: props.error ? true : false,
            error: (props.error as unknown) as any,
        };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('An error occurred', error, { errorInfo });

        return;
    }

    componentDidUpdate(prevProps: Readonly<SMSErrorBoundaryProps>): void {
        if (
            prevProps.error !== this.state.error ||
            (!prevProps.error && this.props.error) ||
            (!this.state.error && this.props.error) ||
            (!this.state.hasError && this.props.error)
        ) {
            this.setState({
                error: (this.props.error as unknown) as any,
                hasError: true,
            });
        }
    }

    handleRetry(retry: (() => void) | undefined) {
        if (retry === undefined) {
            console.error('Missing retry method');

            return;
        }

        retry();
        this.setState({
            hasError: false,
        });
    }

    renderError() {
        const error = this.state.error;

        if (!error) {
            return;
        }

        console.error('An error occurred', error);

        const traceId = error.headers ? error.headers['x-b3-traceid'] : null;
        const requestId = error.headers
            ? error.headers['x-penneo-request-id']
            : null;

        return (
            <div
                className="max-w-[500px] m-auto text-center"
                data-testid="sms-error-boundary">
                <h2 className="text-2xl font-bold">
                    {error.message
                        ? i18n(error.message)
                        : i18n('An error occurred')}
                </h2>

                {error.details && (
                    <Message type="error">
                        <div>
                            {error.details
                                ? i18n(error.details)
                                : i18n(
                                      'Please, try again. If the problem persists, contact support'
                                  )}
                        </div>
                    </Message>
                )}

                {error?.retry && (
                    <Button
                        className="mt-5"
                        theme="blue"
                        icon="far fa-sync"
                        onClick={() => this.handleRetry(error.retry)}>
                        {i18n('Try again')}
                    </Button>
                )}

                <div className="mt-8">
                    {traceId && (
                        <p className="mt text-xs openid-error-details-traceid">
                            {requestId}
                            {traceId && `:${traceId}`}
                        </p>
                    )}
                </div>
            </div>
        );
    }

    render() {
        if (this.state.hasError) {
            return this.renderError();
        }

        return this.props.children;
    }
}
