import React from 'react';
import { TranslationActions } from 'Language';

export default class BootstrapLanguage extends React.Component {
    componentWillMount() {
        TranslationActions.fetchLanguage();
    }

    render() {
        return false;
    }
}
