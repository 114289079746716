import { Dispatcher } from 'Core';
import { SigningAPI } from 'Api';
import ActionTypes from '../ActionTypes';
import cloneDeep from 'lodash/cloneDeep';

function _fetchTeams() {
    return SigningAPI.get(`/teams`);
}

function _fetchTeamMembers(teamId) {
    return SigningAPI.get(`/teams/${teamId}/users`);
}

function _linkTeamMembers(teamId, members) {
    if (members.length > 0) {
        return SigningAPI.post(`/teams/${teamId}/users/${members.join()}`);
    }
}

function _unlinkTeamMembers(teamId, members) {
    return Promise.all(
        members.map((member) => {
            return SigningAPI.delete(`/teams/${teamId}/users/${member}`).then(
                () => {
                    Dispatcher.handleServerAction({
                        type: ActionTypes.MEMBER_REMOVE_SUCCESS,
                    });
                }
            );
        })
    );
}

// Handle all dispatched errors
function _dispatchError(error, action) {
    switch (action) {
        case ActionTypes.FETCH_TEAMS_FAILURE:
        case ActionTypes.FETCH_TEAM_FAILURE:
        case ActionTypes.FETCH_USERS_FAILURE:
        case ActionTypes.CREATE_TEAM_FAILURE:
        case ActionTypes.UPDATE_TEAM_FAILURE:
        case ActionTypes.DELETE_TEAM_FAILURE:
        default:
            Dispatcher.handleServerAction({
                type: action,
                error: error,
            });
            break;
    }
}

const teamActionCreators = {
    fetchTeam(id) {
        Dispatcher.handleServerAction({
            type: ActionTypes.FETCH_TEAM_REQUEST,
        });

        SigningAPI.get(`/teams/${id}`)
            .then((team) => {
                return _fetchTeamMembers(team.id).then((members) => {
                    team.members = members;
                    team.membersCopy = cloneDeep(members);

                    return team;
                });
            })
            .then((team) => {
                Dispatcher.handleServerAction({
                    type: ActionTypes.FETCH_TEAM_SUCCESS,
                    team: team,
                });
            })
            .catch(() => {
                _dispatchError(
                    'Failed to fetch the team',
                    ActionTypes.FETCH_TEAM_FAILURE
                );
            });
    },

    fetchTeams() {
        Dispatcher.handleServerAction({
            type: ActionTypes.FETCH_TEAMS_REQUEST,
        });

        _fetchTeams()
            .then((teams) => {
                return Promise.all(
                    teams.map((team) => {
                        return _fetchTeamMembers(team.id).then((members) => {
                            team.members = members;
                        });
                    })
                ).then(() => teams);
            })
            .then((teams) => {
                Dispatcher.handleServerAction({
                    type: ActionTypes.FETCH_TEAMS_SUCCESS,
                    teams: teams,
                });
            })
            .catch(() => {
                _dispatchError(
                    'Failed to fetch teams',
                    ActionTypes.FETCH_TEAMS_FAILURE
                );
            });
    },

    createTeam(team) {
        Dispatcher.handleServerAction({
            type: ActionTypes.CREATE_TEAM_REQUEST,
        });

        let payload = {
            name: team.name,
            description: team.description,
        };

        if (team.parentId) {
            payload.parentId = team.parentId;
        }

        SigningAPI.post(`/teams`, payload)
            .then((newTeam) => {
                return (
                    team.members &&
                    _linkTeamMembers(
                        newTeam.id,
                        team.members.map((member) => member.id)
                    )
                );
            })
            .then(() => {
                Dispatcher.handleServerAction({
                    type: ActionTypes.CREATE_TEAM_SUCCESS,
                });
            })
            .catch(() => {
                _dispatchError(
                    'Failed to create the team',
                    ActionTypes.CREATE_TEAM_FAILURE
                );
            });
    },

    updateTeam(team) {
        Dispatcher.handleServerAction({
            type: ActionTypes.UPDATE_TEAM_REQUEST,
        });

        let payload = {
            name: team.name,
            description: team.description,
        };

        if (team.parentId) {
            payload.parentId = team.parentId;
        }

        let membersCopy = team.membersCopy.map((member) => member.id);
        let members = team.members.map((member) => member.id);

        let membersToLink = members.filter(
            (member) => membersCopy.indexOf(member) === -1
        );
        let membersToUnlink = membersCopy.filter(
            (member) => members.indexOf(member) === -1
        );

        const _promises = [
            SigningAPI.put(`/teams/${team.id}`, payload),
            _linkTeamMembers(team.id, membersToLink),
            _unlinkTeamMembers(team.id, membersToUnlink),
        ];

        Promise.all(_promises)
            .then(() => {
                Dispatcher.handleServerAction({
                    type: ActionTypes.UPDATE_TEAM_SUCCESS,
                });
            })
            .catch(() => {
                _dispatchError(
                    'Failed to update the team',
                    ActionTypes.UPDATE_TEAM_FAILURE
                );
            });
    },

    deleteTeam(teamId) {
        Dispatcher.handleServerAction({
            type: ActionTypes.DELETE_TEAM_REQUEST,
        });

        SigningAPI.delete(`/teams/${teamId}`)
            .then(() => {
                Dispatcher.handleServerAction({
                    type: ActionTypes.DELETE_TEAM_SUCCESS,
                    teamId: teamId,
                });
            })
            .catch(() => {
                _dispatchError(
                    'Failed to delete the team',
                    ActionTypes.DELETE_TEAM_FAILURE
                );
            });
    },

    fetchAvailableUsers(customerId) {
        Dispatcher.handleServerAction({
            type: ActionTypes.FETCH_USERS_REQUEST,
        });

        SigningAPI.get(`/customers/${customerId}/users`)
            .then((users) => {
                Dispatcher.handleServerAction({
                    type: ActionTypes.FETCH_USERS_SUCCESS,
                    customerId: customerId,
                    users: users || [],
                });
            })
            .catch(() => {
                _dispatchError(
                    'Failed to fetch available users',
                    ActionTypes.FETCH_USERS_FAILURE
                );
            });
    },

    clearStore() {
        Dispatcher.handleViewAction({
            type: ActionTypes.CLEAR_STORE,
        });
    },

    clearErrors() {
        Dispatcher.handleViewAction({
            type: ActionTypes.CLEAR_ERRORS,
        });
    },
};

export default teamActionCreators;
