import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';

export default class Tab extends React.Component {
    static propTypes = {
        activeTab: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        index: PropTypes.number.isRequired,
        width: PropTypes.string,
        icon: PropTypes.string,
    };

    onClick = () => {
        const { index, onClick } = this.props;

        onClick(index);
    };

    render() {
        let { activeTab, label, icon, width, index } = this.props;
        let className = 'tab-list-item';

        if (activeTab === index) {
            className += ' tab-list-active';
        }

        return (
            <li
                style={{ width: width }}
                className={className}
                onClick={this.onClick}>
                <i className={`${icon} tab-list-item-icon`}></i>
                &nbsp;
                <p className="tab-list-item-label">{i18n(label)}</p>
            </li>
        );
    }
}
