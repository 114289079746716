import { OpenIDCertificate, OpenIDObj, OpenIDToken } from 'OpenID/redux/types';
import { OIDs } from '../Constants';

/**
 * E-Ident AMR (Requested authentication method) values.
 *
 * Provided on this page:
 *     https://www.nets.eu/developer/e-ident/overview/Pages/oidc.aspx
 */
export const EIDENT_AMRS = {
    BANKID_SE: ['se_bankid'],
    FTN_FI: ['fi_tupas', 'fi_mobiilivarmenne'],
    EID_BELGIUM_BE: ['be_cardreader'],
    // the last two are not currently used through E-Ident
    BANKID_NO: ['no_bankid', 'no_bidmob', 'no_buypass'],
    MITID_DK: ['mitid'],
};

export function makeEIdentCertProcessor<
    TokenType extends OpenIDToken & { name: string },
    CertificateType extends OpenIDCertificate
>(expectedAmrValues: string[]): OpenIDObj<TokenType, CertificateType> {
    return {
        issuers: [
            'https://www.ident-preprod1.nets.eu/oidc',
            'https://www.ident.nets.eu/oidc',
        ],
        getSubject(token) {
            return {
                [OIDs.OPENID_ISSUER]: token.iss,
                [OIDs.OPENID_ID]: token.sub,
                [OIDs.OPENID_FULL_NAME_V2]: token.name,
            };
        },
        isOwnToken(token): token is TokenType {
            const { iss, amr } = token;
            const isIssuerNets = this.issuers.includes(iss);

            return isIssuerNets && expectedAmrValues.includes(amr);
        },
        isOwnCertificate(certificate): certificate is CertificateType {
            const { subject } = certificate;
            const isIssuerNets = this.issuers.includes(
                subject[OIDs.OPENID_ISSUER]
            );

            if (!isIssuerNets) {
                return false;
            }

            // The OpenID 'sub' claims from ID tokens from E-Ident follow this pattern: {provider}:some-token
            // We can figure out the original EID based on that.
            // It's highly unlikely that E-Ident will change this, as it will mean users get different identifiers. But
            // it is something we might need to keep an eye open for.
            const firstPartOfSubjectId = subject[OIDs.OPENID_ID].split(':')[0];

            return expectedAmrValues.includes(firstPartOfSubjectId);
        },
        getCertificateDisplayInfo(certificate) {
            return {
                title: certificate.subject[OIDs.OPENID_FULL_NAME_V2],
                issuer: certificate.issuer[OIDs.OPENID_ORG_NAME_V2],
            };
        },
    };
}
