import { Dispatcher } from 'Core';
import { PublicAuthAPI } from 'Api';
import { AuthMethodsActions as Actions } from '../ActionTypes';

const authMethodsActions = {
    fetchCredentials() {
        PublicAuthAPI.get('/identity')
            .then((response) => {
                Dispatcher.handleServerAction({
                    type: Actions.FETCH_SUCCESS,
                    credentials: response,
                });
            })
            .catch((error) => {
                this._dispatchError(error, Actions.FETCH_FAILURE);
            });
    },

    delete(id) {
        PublicAuthAPI.delete(`/identity/${id}`)
            .then(() => {
                Dispatcher.handleServerAction({
                    type: Actions.REMOVE_SUCCESS,
                    credentialId: id,
                });
            })
            .catch((error) => {
                this._dispatchError(error, Actions.REMOVE_FAILURE);
            });
    },

    // Handle all dispatched errors
    _dispatchError(error, action) {
        switch (action) {
            case Actions.FETCH_FAILURE:
            case Actions.REMOVE_FAILURE:
            default:
                Dispatcher.handleServerAction({
                    type: action,
                    error: {
                        status: error.status,
                        statusText: error.statusText,
                        data: error.data,
                    },
                });
                break;
        }
    },
};

export default authMethodsActions;
