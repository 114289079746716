import { EmailTemplateGroup, TemplateType } from 'types/EmailTemplates';

type ViewedTutorial = {
    [tourId: string]: {
        count: number;
    };
};

export enum CustomerSettingKeys {
    casefile = 'casefile',
    silverfin = 'silverfin',
    defaultEmailTemplates = 'defaultEmailTemplates',
    allowSigningWithNap = 'allow-signing-with-nap',
}

export enum UserSettingKeys {
    tours = 'tours',
    casefileTemplates = 'casefileTemplates',
    defaultEmailTemplates = 'defaultEmailTemplates',
    storeNewContactsByDefault = 'storeNewContactsByDefault',
    tutorials = 'tutorials',
    whatsNew = 'whatsNew',
    lastUsedFolder = 'lastUsedFolder',
    feedbackPanel = 'feedbackPanel',
}

export enum ManagedSettingKeys {
    features = 'features',
}

export enum V2_STATUS {
    ENABLED = 'enabled',
    PEEK = 'peek',
    DEMO = 'demo',
    DISABLED = 'disabled',
}

export type EmailTemplateSettings = {
    [key in EmailTemplateGroup]: {
        [key in TemplateType]: number | false;
    };
};

export type UserSettings = {
    [UserSettingKeys.casefileTemplates]: {
        [id: number]: any;
    };
    [UserSettingKeys.defaultEmailTemplates]: EmailTemplateSettings;
    [UserSettingKeys.tours]: {
        preventTours: boolean;
        visitedTours: string[];
    };
    [UserSettingKeys.storeNewContactsByDefault]: {
        active: boolean;
    };
    [UserSettingKeys.tutorials]: {
        viewedTutorials: ViewedTutorial[];
    };
    [UserSettingKeys.whatsNew]: {
        lastSeen: string;
    };
    [UserSettingKeys.lastUsedFolder]?: {
        folderId: number;
    };
    [UserSettingKeys.feedbackPanel]?: {
        closedDate: Date;
    };
};

export type ManagedSettings = {
    features: {
        teams: boolean;
        kyc: boolean;
        'client-selector': boolean;
        'nets-id-verifier': boolean;
        'eid.belgium.be': boolean;
    };
    tableauDashboardUsers?: number[];
};

export type CustomerSettings = {
    silverfin: SilverfinSettings;
    casefile: {
        reminderInterval: number;
    };
    kyc: {};
};

export type Settings = {
    user: UserSettings;
    customer: CustomerSettings;
    managed: ManagedSettings;
};

export type SettingsState = {
    data: Settings;
    isLoaded: boolean;
    isFetching: boolean;
    error: any;
};

export type SilverfinSettings = {
    name: string;
    /**
     * Silverfin subdomain defined by the user. Could be “sandbox”, “live”
     * or something custom based on the customers' SSO options on Silverfin.
     */
    subdomain: string | null;
    enabled: boolean;
    enabledAt: Date | null;
    enabledBy: number | null;
    /**
     * Silverfin equivalent to Penneo customer ID. It is provided by the user
     * during the Silverfin authentication flow and passed back to Penneo.
     */
    silverfinFirmId: string | null;
    /**
     * @deprecated Once used to identify towards which
     * Silverfin environment – “sandbox” or “live” – the integration
     * targeted. The subdomain logic replaced this approach, but the parameter
     * is still being fetched from the DB for some legacy customers.
     * To be removed as soon as the last customer is switched to the new logic.
     */
    dev?: any;
};
