import createReducer from 'create-reducer';
import * as Actions from './action-types';
import produce from 'immer';
import { OpenIdReducerState, OpenIdLocalStorageState } from './types';

// Initial state
export const openIdInitialState: OpenIdReducerState = {
    intent: {
        data: {} as OpenIdLocalStorageState,
        isSuccess: false,
        isFetching: false,
        isLoaded: false,
    },
    credentials: {
        credentials: [],
        isLoaded: false,
        isFetching: false,
    },
    sign: {
        data: {} as OpenIdReducerState['sign']['data'],
        displayStorageOptions: false,
        signingError: null,
        isFetching: false,
        isLoaded: false,
    },
    error: null,
};

// Reducer

export const openIdReducer = createReducer(openIdInitialState, {
    [Actions.OPENID_INIT_REQUEST]: (state) =>
        produce(state, (draft) => {
            draft.intent.isFetching = true;
        }),
    [Actions.OPENID_INIT_FAILURE]: (state, error) =>
        produce(state, (draft) => {
            draft.intent.isFetching = false;
            draft.intent.isLoaded = true;
            draft.error = error;
        }),
    [Actions.OPENID_COLLECT_REQUEST]: (state) =>
        produce(state, (draft) => {
            draft.intent.isFetching = true;
        }),
    [Actions.OPENID_COLLECT_SUCCESS]: (state) =>
        produce(state, (draft) => {
            draft.intent.isSuccess = true;
            draft.intent.isFetching = false;
            draft.intent.isLoaded = true;
        }),
    [Actions.OPENID_COLLECT_FAILURE]: (state, error) =>
        produce(state, (draft) => {
            draft.intent.isFetching = false;
            draft.intent.isLoaded = true;
            draft.error = error;
        }),
    [Actions.OPENID_SIGN_INIT_SUCCESS]: (state, payload) => {
        return produce(state, (draft) => {
            draft.sign.isFetching = false;
            draft.sign.isLoaded = true;
            draft.sign.data = {
                ...draft.sign.data,
                signText: payload.signText,
                documents: payload.documents,
                certificate: payload.certificate,
                keyPair: payload.keyPair,
                trustChain: payload.trustChain,
            };
            draft.error = null;
        });
    },
    [Actions.OPENID_SIGN_INIT_FAILURE]: (state, error) => {
        return produce(state, (draft) => {
            draft.sign.isFetching = false;
            draft.sign.isLoaded = true;
            draft.error = error;
        });
    },
    [Actions.OPENID_SIGN_REQUEST]: (state) => {
        return produce(state, (draft) => {
            draft.sign.isFetching = true;
            draft.sign.isLoaded = true;
            draft.error = null;
            draft.sign.signingError = null;
        });
    },
    [Actions.OPENID_SIGN_FAILURE]: (state, error) => {
        return produce(state, (draft) => {
            draft.sign.isFetching = false;
            draft.sign.isLoaded = true;
            draft.sign.signingError = error;
        });
    },
    [Actions.OPENID_LOGIN_REQUEST]: (state, error) =>
        produce(state, (draft) => {
            draft.intent.isFetching = true;
            draft.intent.isLoaded = true;
            draft.error = error;
        }),
    [Actions.OPENID_CACHE_INTENT]: (state, payload) =>
        produce(state, (draft) => {
            draft.intent.data = payload;
        }),
    [Actions.OPENID_RESET_STATE]: () => ({
        ...openIdInitialState,
    }),
    [Actions.OPENID_DISPLAY_STORAGE_OPTIONS]: (state) =>
        produce(state, (draft) => {
            draft.sign.displayStorageOptions = true;
            draft.sign.isFetching = false;
        }),
    [Actions.OPENID_SIGN_DATA_FETCH_SUCCESS]: (state, payload) =>
        produce(state, (draft) => {
            draft.sign.data.signatureData = payload;
        }),
});
