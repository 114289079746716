import { Dispatcher, BaseStore } from 'Core';
import assign from 'object-assign';
import { ActionTypes, DEFAULT_LANGUAGE } from '../Constants';
import { getEnabledLanguages } from '../utils';
import storage from 'local-storage';
import moment from 'moment';

// moment locales
import 'moment/locale/da';
import 'moment/locale/nb';
import 'moment/locale/sv';
import 'moment/locale/fr';
import 'moment/locale/nl';
import 'moment/locale/de';

let _language = null; // data storage for current language code
let _localLanguageChanged = false;

function updateMomentLocale(languageCode) {
    const localeMap = {
        da: 'da',
        no: 'nb',
        sv: 'sv',
        nl: 'nl',
        fr: 'fr',
        de: 'de',
    };

    moment.locale(localeMap?.[languageCode] ?? 'en');
}

function setLanguage(languageCode, settings = {}) {
    const enabledLanguageCodes = Object.keys(getEnabledLanguages());

    /**
     * Only allow switching to a language if the application supports that locale
     *
     * This protects against corrupted localStorage entries where penneo-language can be null, undefined,
     * or a `languageCode` that is not supported or available
     *
     * Falls back to default language and writes and overwrites the corrupted localStorage entry.
     */
    if (enabledLanguageCodes.includes(languageCode)) {
        _language = languageCode;
    } else {
        _language = DEFAULT_LANGUAGE;
    }

    storage('penneo-language', _language);
    updateMomentLocale(_language);

    if (settings.local) {
        _localLanguageChanged = true;
    }
}

function getLocalLanguage() {
    try {
        return storage('penneo-language');
    } catch (e) {
        console.warn('Language not set', e);

        return 'en';
    }
}

const TranslationStore = assign({}, BaseStore, {
    getLanguage(settings = {}) {
        if (settings.local) {
            return getLocalLanguage();
        }

        return _language;
    },

    getLanguages() {
        return getEnabledLanguages();
    },

    getLocalChangeStatus() {
        return _localLanguageChanged;
    },

    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case ActionTypes.LANGUAGE_CHANGE:
                setLanguage(action.languageCode, action.settings);
                break;
            default:
                break;
        }
        TranslationStore.emitChange();
    }),
});

export default TranslationStore;
