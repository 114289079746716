import React from 'react';
import bytes from 'bytes';
import { notify } from 'react-notify-toast';

import TooltipRequired from 'Common/components/TooltipRequired';

import './image-uploader.scss';

import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Dropzone from 'react-dropzone';
import Button from 'Common/components/Button';

export type Props = {
    onChange: Function;
    type: string;
    file: File | null;
    i18n: Function;
    required?: boolean;
    maxSize?: number;
};

export class ImageUploader extends React.Component<Props> {
    // ref
    fileUploadInput;

    // Document upload by browse
    _onButtonHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            this._handleFile(event.target.files[0]);
        }
    };

    // Document upload by dragging and dropping
    _onDropHandler = (files: File[]) => {
        if (files.length === 0) {
            console.log('Error, document not loaded');
        }

        this._handleFile(files[0]);
    };

    _handleFile(file: File) {
        const { maxSize, onChange } = this.props;

        if (maxSize && file.size > maxSize) {
            this.fileUploadInput.value = '';
            this.showErrorToaster();
        } else {
            onChange(file);
        }
    }

    showErrorToaster() {
        const { maxSize, i18n } = this.props;
        const maxSizeEmphasized = <strong>{bytes(maxSize)}</strong>;

        notify.show(
            <span>
                {i18n`You cannot upload files larger than ${maxSizeEmphasized}`}
                &nbsp;
                <i className="fa fa-times" />
            </span>,
            'error',
            3000
        );
    }

    _clear = () => this.props.onChange(null);

    formatType = (type: string) => {
        if (type === 'passport') {
            return {
                name: 'passport',
                icon: 'far fa-passport',
            };
        }

        if (type === 'ssnCard') {
            return {
                name: 'national ID card',
                icon: 'far fa-id-card',
            };
        }

        if (type === 'driverLicense') {
            return {
                name: 'driver license',
                icon: 'far fa-car-side',
            };
        }

        return {
            name: '',
            icon: '',
        };
    };

    formatTitle = (title: string) => {
        const lengthLimit = 30;
        const tolerance = 5;

        if (title.length > lengthLimit) {
            return (
                title.substring(0, lengthLimit - tolerance) +
                '...' +
                title.substring(lengthLimit, title.length)
            );
        }

        return title;
    };

    render() {
        const { file, i18n } = this.props;
        const type = this.formatType(this.props.type);
        const { name: nameOfType } = type;
        const { required } = this.props;

        // The label needs to be interpolated before being passed to the `18n` fn
        // otherwise the interpolated value will be intercepted by the tagged template
        const uploadSectionLabel = `Please upload a copy of your valid ${nameOfType}`;

        return (
            <div className="image-uploader">
                <Dropzone
                    className="image-upload-dropzone"
                    disablePreview={true}
                    onDrop={this._onDropHandler}
                    disableClick={true}
                    accept="image/png,image/jpeg">
                    <input
                        type="file"
                        ref={(ref) => (this.fileUploadInput = ref)}
                        name="fileUploader"
                        className="fileUploader"
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={this._onButtonHandler}
                    />

                    {file && (
                        <div className="image-present">
                            <div className="preview-image">
                                <img
                                    src={URL.createObjectURL(file)}
                                    onClick={() => this.fileUploadInput.click()}
                                />
                            </div>

                            <div className="preview-description">
                                <h4 className="type">
                                    <i className={type.icon} />
                                    &nbsp;
                                    {i18n(type.name)}
                                </h4>
                                <p>{this.formatTitle(file.name)}</p>
                                <p className="file-size">{bytes(file.size)}</p>
                            </div>

                            <div className="actions">
                                <Button
                                    className="replace-button"
                                    theme="gray"
                                    icon="far fa-retweet-alt"
                                    variant="outline"
                                    onClick={() =>
                                        this.fileUploadInput.click()
                                    }>
                                    {i18n('Replace')}
                                </Button>
                                <Button
                                    theme="gray"
                                    icon="far fa-times"
                                    variant="outline"
                                    onClick={this._clear}>
                                    {i18n('Remove')}
                                </Button>
                            </div>
                        </div>
                    )}

                    {!file && (
                        <div className="no-image">
                            <div className="icon">
                                <i className={type.icon} />
                            </div>

                            <div className="description">
                                <h4>
                                    {i18n(uploadSectionLabel)}
                                    {required && <TooltipRequired />}
                                </h4>
                            </div>

                            <div className="actions">
                                <Button
                                    theme="blue"
                                    variant="outline"
                                    icon="far fa-upload"
                                    onClick={() =>
                                        this.fileUploadInput.click()
                                    }>
                                    {i18n('Upload')}
                                </Button>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
        );
    }
}

export default DragDropContext(HTML5Backend)(ImageUploader);
