import { Dispatcher, BaseStore } from 'Core';
import assign from 'object-assign';

let _integrationCredentials = null;
let _activationConfirmed = false;

function updateCredentials(credentials) {
    _integrationCredentials = credentials;
}

function setActivationStatus(status) {
    _activationConfirmed = status;
}

// Flux Store Creation
const IntegrationStore = assign({}, BaseStore, {
    getCredentials() {
        return _integrationCredentials;
    },

    getActivationStatus() {
        return _activationConfirmed;
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case 'CREATE_INTEGRATION_CREDENTIALS_SUCCESS':
            case 'FETCH_INTEGRATION_CREDENTIALS_SUCCESS':
            case 'UPDATE_INTEGRATION_CREDENTIALS_SUCCESS':
                updateCredentials(action.credentials);
                break;
            case 'FETCH_INTEGRATION_CREDENTIALS_ERROR':
                break;
            case 'USER_CONFIRMED_ACTIVATION':
                setActivationStatus(action.activationConfirmed);
                break;
            default:
                return false;
        }

        IntegrationStore.emit(action.type);
        IntegrationStore.emitChange();
    }),
});

export default IntegrationStore;
