import React from 'react';
import { i18n } from 'Language';
import { connect } from 'react-redux';
import { ReduxState } from 'Store';
import { updateEmailTemplate } from 'Casefiles/redux/newCaseFile/actions';
import { CasefileMessageValidation } from 'types/DataValidation';
import PersonalizedMessageEditor, {
    Tab,
} from 'Common/components/PersonalizedMessageEditor';
import {
    EmailTemplate,
    TemplateType,
    EmailTemplates,
    EmailTemplateGroup,
} from 'types/EmailTemplates';

export type Props = {
    dispatch: Function;
    language: string;
    userEmailTemplates: EmailTemplate[];
    casefileEmailTemplates: EmailTemplates;
    emailTemplatesValidation: CasefileMessageValidation;
};

export class CasefileMessages extends React.Component<Props> {
    updateEmailTemplate = (type: TemplateType, template: EmailTemplate) => {
        this.props.dispatch(updateEmailTemplate(type, template));
    };

    render() {
        const {
            userEmailTemplates = [],
            casefileEmailTemplates,
            emailTemplatesValidation,
            language,
        } = this.props;
        const tabs: Tab[] = [
            {
                id: TemplateType.SIGNER,
                valid: emailTemplatesValidation[TemplateType.SIGNER].valid,
                label: i18n`Message to signers`,
                tagline: i18n`This message will be sent out to all signers via email to notify them they have documents to sign`,
            },
            {
                id: TemplateType.COMPLETED,
                valid: emailTemplatesValidation[TemplateType.COMPLETED].valid,
                label: i18n`Case completed`,
                tagline: i18n`Message to signers when casefile is signed by all parties`,
            },
            {
                id: TemplateType.REMINDER,
                valid: emailTemplatesValidation[TemplateType.REMINDER].valid,
                label: i18n`Reminder`,
                tagline: i18n`Reminder messages`,
            },
        ];

        return (
            <PersonalizedMessageEditor
                tabs={tabs}
                group={EmailTemplateGroup.CASEFILE}
                language={language}
                collapsible={true}
                onTemplateChange={this.updateEmailTemplate}
                availableTemplates={userEmailTemplates}
                selectedTemplates={casefileEmailTemplates}
                templatesValidation={emailTemplatesValidation}
            />
        );
    }
}

export default connect((state: ReduxState) => ({
    userEmailTemplates: state.email.isLoading
        ? []
        : state.email.templates || [],
    casefileEmailTemplates: state.newCasefile.emailTemplates,
    emailTemplatesValidation: state.newCasefile.validation.emailTemplates,
}))(CasefileMessages);
