import { createSlice } from '@reduxjs/toolkit';

type SigningState = {
    triggerFinalizing: boolean;
};

export const initialState: SigningState = {
    triggerFinalizing: false,
};

const signingSlice = createSlice({
    name: 'signing',
    initialState,
    reducers: {
        setTriggerFinalizing: (state) => {
            state.triggerFinalizing = true;
        },
        resetTriggerFinalizing: () => initialState,
    },
});

export const {
    setTriggerFinalizing,
    resetTriggerFinalizing,
} = signingSlice.actions;

export default signingSlice.reducer;
