/* eslint max-len:0, camelcase: 0*/
import BankIDNOSign from 'Auth/components/BankID_NO/SignContainer';
import TouchSign from 'Auth/components/Touch/TouchContainer';
import OpenIDSign from 'OpenID/components/OpenIDSign';
import DeleteUserPrompt from 'Auth/components/Admin/DeleteUserPrompt';
import NewsFeed from 'News/components/NewsFeed';
import NewsToggleWidget from 'News/components/NewsToggleWidget';
import TrashCan from 'Casefiles/components/TrashCan/TrashCan';
import Eutl from 'Eutl';
import { EID_METHODS, SIGNING_METHODS } from '../EID';
import ItsmeSigningWrapper from '../ItsmeQES/components/Signing/ItsmeSigningWrapper';
import { getOpenIdState } from '../OpenID/utils/openIdState';
import { Intent } from '../OpenID/Constants';

const nonOpenIDSigningComponents = {
    image: TouchSign,
    bankid_no: BankIDNOSign,
    'qes.itsme.be': ItsmeSigningWrapper,
};

const openIdState = getOpenIdState();

/**
 * We check if Intent is NAP, since we are using signing methods
 * like any.mitid.dk which is not and should not be available in SigningMethods
 */
const methods =
    openIdState?.intent === Intent.NAP
        ? [...SIGNING_METHODS, ...EID_METHODS]
        : SIGNING_METHODS;

const Components = {
    ...methods.reduce((components, method) => {
        components[method.type] =
            nonOpenIDSigningComponents[method.type] ?? OpenIDSign;

        return components;
    }, {}),

    RecycleBin: TrashCan,
    eutl: Eutl,
    DeleteUserPrompt,
    NewsFeed,
    NewsToggleWidget,
};

export default Components;
