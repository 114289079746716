import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Checkbox, TextInput } from 'Common/components';
import { modal } from 'Common/components/Common/Modal';
import Message from 'Common/components/Message';
import Button from 'Common/components/Button';
import DurationInput from 'Common/components/DurationInput';
import { env } from 'Constants';
import { i18n, LanguageDropdown } from 'Language';
import { updateCustomerSettings } from 'Settings/redux/actions';
import {
    CustomerSettingKeys,
    CustomerSettings,
    ManagedSettings,
} from 'Settings/redux/types';
import { AppDispatch, ReduxState } from 'Store';
import { CustomerEntity as Customer } from 'types/Customer';
import { UserEntity as User } from 'types/User';
import { connect } from 'react-redux';
import SigningAndAccessMethods from './SigningAndAccessMethods';

type Props = {
    user: User;
    customer: Customer;
    customerBackup: Customer;
    pathname: string;
    isSaving: boolean;
    updateCustomer: (customer: Customer) => void;
    saveCustomer: () => void;
    dispatch: AppDispatch;
    customerSettings: CustomerSettings;
    managedSetting: ManagedSettings;
};

type State = {
    reminderInterval?: number;
    allowSigningWithNap?: boolean;
};

class CustomerSettingsForm extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    state: State = {};

    handleChange = (
        property: string,
        value: number | string | boolean | null
    ) => {
        const { customer, updateCustomer } = this.props;

        customer[property] = value;

        updateCustomer(customer);
    };

    displayDataDeletionWarning = () => {
        const { caseFileDeletionInterval } = this.props.customer;
        const deletionDate = moment()
            .subtract(caseFileDeletionInterval, 'days')
            .format('DD-MM-YYYY');

        const config = {
            title: i18n`Automatic Data Deletion` as string,
            body: (
                <div>
                    <p className="text-warning">
                        <i className="fa fa-warning"></i>&nbsp;
                        {i18n`Activating automatic data deletion will affect existing
                              case files that have been completed, rejected or expired in the last
                              ${caseFileDeletionInterval} days`}
                        &nbsp;
                        <b>({i18n`Before ${deletionDate}`})</b>
                    </p>

                    <p>
                        {i18n`All case files that match these conditions will be moved to the
                              recycle bin. That means users will have the possibility of
                              restoring the automatically deleted data before it is
                              completely removed`}
                    </p>

                    <p>
                        <b>{i18n`Note:`}</b>{' '}
                        {i18n`The system will not preserve the
                              folder structure after restoring these items`}
                    </p>
                </div>
            ),
            // onClose: this.closeModal,
            buttons: (
                <div>
                    <Button onClick={modal.hide}>{i18n`Cancel`}</Button>
                    <Button
                        theme="blue"
                        icon="fa fa-check"
                        onClick={this.acceptPrompt}>
                        {i18n`Accept & Save`}
                    </Button>
                </div>
            ),
            preventClose: false,
        };

        modal.show(config);
    };

    acceptPrompt = () => {
        modal.hide();
        this.props.saveCustomer();
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const {
            customerBackup,
            customer,
            customerSettings,
            dispatch,
            saveCustomer,
        } = this.props;
        const { reminderInterval, allowSigningWithNap } = this.state;

        const deletionEnabled =
            !customerBackup.caseFileDeletionInterval &&
            customer.caseFileDeletionInterval;

        const deletionDateIncremented =
            customerBackup.caseFileDeletionInterval <
            customer.caseFileDeletionInterval;

        // If deletion is enabled or interval has been made greater,
        // display warning about deleting previous elements.
        if (deletionEnabled || deletionDateIncremented) {
            return this.displayDataDeletionWarning();
        }

        if (
            reminderInterval &&
            customerSettings.casefile.reminderInterval !== reminderInterval
        ) {
            dispatch(
                updateCustomerSettings(CustomerSettingKeys.casefile, {
                    reminderInterval,
                })
            );
        }

        if (
            allowSigningWithNap != null &&
            customerSettings[CustomerSettingKeys.allowSigningWithNap] !==
                allowSigningWithNap
        ) {
            dispatch(
                updateCustomerSettings(
                    CustomerSettingKeys.allowSigningWithNap,
                    allowSigningWithNap
                )
            );
        }

        saveCustomer();
    };

    renderCustomerDetails = () => {
        const {
            address = '',
            city = '',
            zip = '',
            name,
            vatin,
        } = this.props.customer;

        return (
            <div>
                {name}
                {(address.length > 0 || city.length > 0 || zip.length > 0) && (
                    <div>
                        <i className="fa fa-map-marker" />
                        &nbsp;
                        {address}
                        {/* Add comma if any value precedes */}
                        {address.length > 0 && ', '}
                        {city}
                        {/* Add Period if any value precedes */}
                        {(address.length > 0 || city.length > 0) && '. '}
                        {zip}
                    </div>
                )}
                {vatin && (
                    <div>
                        {i18n`VATIN`} {vatin}
                    </div>
                )}
            </div>
        );
    };

    render() {
        const {
            user,
            customer,
            pathname,
            isSaving,
            customerSettings,
        } = this.props;
        const { reminderInterval } = this.state;
        // remove dangling slash. When mounted as a route in the signing-service
        // the initial route is /, causing the links to have double slashes.
        const path = pathname.replace(/\/$/, '');

        return (
            <form className="form ui-v2" onSubmit={this.handleSubmit}>
                <div className="highlight-form-group">
                    <div className="p">
                        {this.renderCustomerDetails()}
                        <br />
                    </div>

                    <Link to={`${path}/general`} className="no-underline">
                        <Button
                            theme="blue"
                            icon="fas fa-pencil"
                            variant="outline"
                            renderIconLeft={true}>
                            {i18n`Edit details`}
                        </Button>
                    </Link>
                </div>

                <h3>{i18n`Default language`}</h3>
                <LanguageDropdown
                    type="standalone"
                    value={customer.language}
                    skipInitCallback={true}
                    callback={(languageCode: string) =>
                        this.handleChange('language', languageCode)
                    }
                />

                <h3>{i18n`Security & privacy`}</h3>

                <Checkbox
                    checked={customer.transferOwnershipOnUserDelete}
                    style={{ display: 'block' }}
                    label={i18n`Allow transferring ownership of data on user deletion`}
                    onChange={(value: boolean) =>
                        this.handleChange(
                            'transferOwnershipOnUserDelete',
                            value
                        )
                    }
                />

                {/*
                    Setting disabled due to security concerns regarding data-sharing
                    @see issue: #213

                    <Checkbox
                        checked={customer.adminAccess}
                        style={{display: 'block'}}
                        onChange={value => this.handleChange('adminAccess', value)}
                        label={i18n`Allow administrators to access other users' folders and
                                    case files via API`}
                    />
                */}

                <Checkbox
                    checked={customer.accessControl}
                    style={{ display: 'block' }}
                    onChange={(value: boolean) =>
                        this.handleChange('accessControl', value)
                    }
                    label={i18n`Enable sensitive data by default for all case files`}
                />

                <Checkbox
                    checked={customer.usersCanStoreContacts}
                    onChange={(value: boolean) =>
                        this.handleChange('usersCanStoreContacts', value)
                    }
                    label={i18n`Allow users to store contacts`}
                />

                <div>
                    <label>
                        {i18n`Case file signing requests expire after`}
                        <DurationInput
                            value={customer.caseFileExpiryInterval}
                            onChange={(value: number) =>
                                this.handleChange(
                                    'caseFileExpiryInterval',
                                    value
                                )
                            }
                        />
                    </label>
                </div>

                <h3>{i18n`Data handling`}</h3>
                <div
                    className={`link-form-input
                        ${customer.caseFileDeletionInterval && 'enabled'}`}>
                    <Checkbox
                        checked={!!customer.caseFileDeletionInterval}
                        label={i18n`Enable automatic data deletion`}
                        onChange={(enabled: boolean) => {
                            this.handleChange(
                                'caseFileDeletionInterval',
                                enabled ? 30 : null
                            );
                        }}
                    />
                </div>

                <div
                    className={
                        customer.caseFileDeletionInterval
                            ? ''
                            : 'input-disabled'
                    }>
                    <label>
                        {i18n`Move items to the recycle bin after ${(
                            <DurationInput
                                value={customer.caseFileDeletionInterval || 30}
                                onChange={(value: number) =>
                                    this.handleChange(
                                        'caseFileDeletionInterval',
                                        value
                                    )
                                }
                            />
                        )} of completion, rejection or expiration`}
                    </label>
                </div>

                <div
                    className={`link-form-input
                        ${
                            customer.caseFileForceDeletionInterval && 'enabled'
                        }`}>
                    <Checkbox
                        checked={!!customer.caseFileForceDeletionInterval}
                        label={i18n`Empty recycle bin automatically`}
                        onChange={(enabled: boolean) => {
                            this.handleChange(
                                'caseFileForceDeletionInterval',
                                enabled ? 30 : null
                            );
                        }}
                    />
                </div>

                <div
                    className={
                        customer.caseFileForceDeletionInterval
                            ? ''
                            : 'input-disabled'
                    }>
                    <label>
                        {i18n`Items in the recycle bin are deleted after`}
                        <DurationInput
                            value={customer.caseFileForceDeletionInterval || 30}
                            onChange={(value: number) =>
                                this.handleChange(
                                    'caseFileForceDeletionInterval',
                                    value
                                )
                            }
                        />
                    </label>
                </div>

                <div className="mb">
                    <h3>{i18n`Case file settings`}</h3>
                    <label>
                        {i18n`Default reminder interval`}:
                        <DurationInput
                            value={
                                reminderInterval ||
                                customerSettings.casefile.reminderInterval
                            }
                            onChange={(value: number) =>
                                this.setState({ reminderInterval: value })
                            }
                        />
                    </label>
                </div>

                {env.legacy === false && (
                    <div>
                        <h3>{i18n`Integrations`}</h3>

                        <Link
                            to={{ name: 'company-integrations' }}
                            className="no-underline">
                            <Button
                                theme="blue"
                                variant="outline"
                                icon="far fa-shapes"
                                renderIconLeft={true}>
                                {i18n`Manage third party integrations`}
                            </Button>
                        </Link>
                        <br />
                    </div>
                )}

                <h3>{i18n`Miscellaneous`}</h3>
                <TextInput
                    label={i18n`Domain`}
                    value={customer.domain}
                    onChange={(value: string) =>
                        this.handleChange('domain', value)
                    }
                />

                <TextInput
                    label={i18n`Microsoft Entra Tenant ID`}
                    value={customer.azureADTenantId}
                    onChange={(value: string) =>
                        this.handleChange('azureADTenantId', value)
                    }
                />

                {user.admin && (
                    <div className="box-gray">
                        <h3 className="mt0">{i18n`Administration`}</h3>
                        <Message type="warning">
                            {i18n`Proceed with caution. These settings can affect customer integrations and billing`}
                        </Message>

                        <label>{i18n`Email settings`}</label>
                        <Checkbox
                            checked={customer.overrideTemplates}
                            label={i18n`Allow email templates to be fully customizable`}
                            onChange={(enabled: boolean) => {
                                this.handleChange('overrideTemplates', enabled);
                            }}
                        />

                        <label>{i18n`Account Type`}</label>
                        <select
                            onChange={(e) =>
                                this.handleChange(
                                    'status',
                                    Number(e.target.value)
                                )
                            }
                            value={customer.status}>
                            <option value="0">{i18n`Demo`}</option>
                            <option value="1">{i18n`Paying`}</option>
                            <option value="2">{i18n`Cancelled`}</option>
                        </select>

                        <TextInput
                            label={i18n`Pipedrive ID`}
                            value={customer.pipedriveId.toString()}
                            onChange={(value: string) =>
                                this.handleChange('pipedriveId', value)
                            }
                        />

                        <div>
                            <Checkbox
                                checked={!customer.active}
                                label={i18n`Disable Customer Account`}
                                onChange={(enabled: boolean) => {
                                    this.handleChange('active', !enabled);
                                }}
                            />

                            {!customer.active && (
                                <div className="text-error">
                                    <i className="fa fa-warning" />{' '}
                                    {i18n`Users in this organization
                                    won't be able to use Penneo while the account is disabled`}
                                </div>
                            )}
                        </div>

                        <label>{i18n`Customer features`}</label>

                        <Link to={`${path}/features`} className="no-underline">
                            <Button
                                theme="blue"
                                variant="outline"
                                icon="far fa-external-link"
                                renderIconLeft={true}>
                                {i18n`Manage customer features`}
                            </Button>
                        </Link>
                    </div>
                )}

                <SigningAndAccessMethods
                    customer={customer}
                    updateCustomer={this.props.updateCustomer}
                    customerSettings={customerSettings}
                    managedSettings={this.props.managedSetting}
                    allowSigningWithNapChanged={(value) =>
                        this.setState({
                            allowSigningWithNap: value,
                        })
                    }
                />

                <br />
                <br />
                <div className="pull-right-flex">
                    <Button theme="blue" disabled={isSaving}>
                        {isSaving ? i18n`Saving...` : i18n`Save changes`}
                    </Button>
                </div>
            </form>
        );
    }
}

export default connect((state: ReduxState) => ({
    customerSettings: state.settings.data.customer,
    managedSetting: state.settings.data.managed,
    isLoaded: state.settings.isLoaded,
    error: state.settings.error,
}))(CustomerSettingsForm);
