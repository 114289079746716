import React from 'react';
import classnames from 'classnames';
import './card.scss';

type Props = {
    className?: string;
    enableHover: boolean;
    enableHoverDecoration: boolean;
    disableHoverMotion: boolean;
    hoverIcon: string;
    theme?: 'blue' | 'darkblue';
    onClick?: () => void;
};

class Card extends React.Component<Props> {
    static defaultProps = {
        enableHover: false,
        disableHoverMotion: false,
        enableHoverDecoration: false,
        hoverIcon: 'fas fa-arrow-right',
    };

    render() {
        const {
            enableHover,
            enableHoverDecoration,
            hoverIcon,
            theme,
        } = this.props;

        const classNames = classnames(
            this.props.className,
            'penneo-card',
            theme,
            {
                'no-motion': this.props.disableHoverMotion,
                hoverable: enableHover,
            }
        );

        return (
            <div className={classNames} onClick={this.props.onClick}>
                <div className="penneo-card-container">
                    {enableHoverDecoration && (
                        <div className="penneo-card-hover-overlay">
                            <div className="penneo-card-hover-overlay-content">
                                <i className={hoverIcon}></i>
                            </div>
                        </div>
                    )}

                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Card;
