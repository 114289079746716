import { i18n } from 'Language';
import React, { useEffect } from 'react';
import { ClientsMainContainer } from './ClientsMainContainer';
import { fetchClientById, resetCurrentClient, clientsState } from './redux';
import { notify } from 'react-notify-toast';
import { useAppDispatch, useAppSelector } from 'Store';
import { UserEntity as User } from 'types/User';
import Loader from 'Common/components/Common/Loader';
import { Client, ClientLinkEntityType } from './types';
import { ReactRouter } from 'types/Router';
import RouterV3Tabs from 'Common/components/RouterV3Tabs';
import { TabbedRoute } from 'types/TabbedRoute';
import { ClientViewCasefiles } from './ClientViewCasefiles';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';

type Props = {
    router: ReactRouter;
    user: User;
    params: {
        clientId: Client['id'];
        entityType: ClientLinkEntityType;
    };
};

export const ClientView = ({
    router,
    user,
    params: { clientId, entityType },
}: Props) => {
    const dispatch = useAppDispatch();
    const { currentClient, loading, error } = useAppSelector(clientsState);

    /**
     * TODO: routing can be improved in 2.0
     */
    const tabs: TabbedRoute[] = [
        {
            name: 'client-view',
            tab: {
                title: 'Casefiles',
            },
            params: {
                clientId,
                entityType: ClientLinkEntityType.casefile,
            },
        },
        {
            name: 'client-view',
            tab: {
                title: 'Contacts',
            },
            params: {
                clientId,
                entityType: ClientLinkEntityType.contact,
            },
        },
    ];

    useEffect(() => {
        // access control
        if (!LaunchDarkly.variation(Flags.CLIENTS)) {
            router.push('/404');
        }
    }, []);

    useEffect(() => {
        dispatch(fetchClientById(clientId));

        /**
         * Clear the curremt client
         * when we unmount the component
         */
        return () => {
            dispatch(resetCurrentClient());
        };
    }, [clientId]);

    useEffect(() => {
        if (error && !loading) {
            notify.show(
                <span>{i18n('An error occurred, please try again')}</span>,
                'error',
                6000
            );
        }
    }, [error, loading]);

    if (!currentClient) {
        return <Loader />;
    }

    const header = <h3 className="title">{currentClient?.name}</h3>;

    return (
        <ClientsMainContainer title="Clients" headerComponent={header}>
            <div className="clients-view">
                <RouterV3Tabs routes={tabs}>
                    {entityType === ClientLinkEntityType.casefile ? (
                        <ClientViewCasefiles clientId={clientId} />
                    ) : (
                        <div className="clients-list-empty">{i18n`The list is empty`}</div>
                    )}
                </RouterV3Tabs>
            </div>
        </ClientsMainContainer>
    );
};
