import React from 'react';
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import { BrandingActionStates, CustomerBranding } from './redux/types';
import { CustomerEntity as Customer } from 'types/Customer';
import { values } from 'lodash';

import { TextInput } from 'Common/components';
import Loading from 'Common/components/Loaders/LoadingData';
import ColorPicker from 'Common/components/ColorPicker';
import Button from 'Common/components/Button';
import { i18n } from 'Language';
import ImagePicker from './ImagePicker';
import './customer-branding.scss';

import {
    resetState,
    fetchBranding,
    updateBranding,
    saveCustomBranding,
    revertDefaultBranding,
} from './redux/actions';

type Props = {
    dispatch: AppDispatch;
    customer: Customer;
    branding: CustomerBranding;
    isFetching: boolean;
    error: boolean;
};

type State = {
    duplicatedColor: string | null;
};
export class Branding extends React.Component<Props, State> {
    state: State = {
        duplicatedColor: null,
    };

    componentDidMount = () => {
        const { dispatch, customer } = this.props;

        dispatch(resetState());
        dispatch(fetchBranding(customer.id));
    };

    componentDidUpdate = (prevProps: Props, prevState: State) => {
        /**
         * We extract all colors props from the branding and
         * check for duplicated colors. If we find one
         * we will show an error on the fields with it
         * and prevent saving the branding changes
         */
        const hexColorRegex = new RegExp(/^#(?:[0-9a-fA-F]{3}){1,2}$/);
        const colors: string[] = values(this.props.branding).filter((color) =>
            hexColorRegex.test(color)
        );
        let duplicatedColor: string | null = null;

        colors.forEach((color) => {
            const isDuplicated = colors.filter(
                (col) => col.toLowerCase() === color.toLowerCase()
            );

            if (isDuplicated.length > 1) {
                duplicatedColor = color;
            }
        });

        if (prevState.duplicatedColor === duplicatedColor) {
            return;
        }

        this.setState({ duplicatedColor });
    };

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(resetState());
    }

    updateBranding = (property: string, value: string) => {
        const { dispatch } = this.props;

        dispatch(updateBranding(property, value));
    };

    handleImageChange = (file: File) => {
        if (!file) {
            return;
        }

        const reader = new FileReader();

        reader.onloadend = () => {
            const result: string = reader.result as string;
            const base64image = result.split(',')[1];

            this.updateBranding('logo', base64image);
            this.updateBranding('imageUrl', URL.createObjectURL(file));
        };

        reader.readAsDataURL(file);
    };

    saveBranding = () => {
        const { dispatch, customer } = this.props;

        dispatch(saveCustomBranding(customer.id));
    };

    revertToDefault = () => {
        const { dispatch, customer } = this.props;

        dispatch(revertDefaultBranding(customer.id));
    };

    render() {
        const { branding, isFetching } = this.props;
        const { duplicatedColor } = this.state;

        if (isFetching) {
            return <Loading />;
        }

        return (
            <>
                <div className="branding-container">
                    <div className="branding-description">
                        {i18n`The changes will not be shown to the organization until you save the changes`}
                    </div>

                    <div className="branding-container-left">
                        <ColorPicker
                            color={branding.backgroundColor}
                            label={i18n`Background color`}
                            onChange={(color: string) =>
                                this.updateBranding('backgroundColor', color)
                            }
                            validation={{
                                message: i18n`All branding colors must be unique`,
                                showError:
                                    duplicatedColor?.toLowerCase() ===
                                    branding.backgroundColor.toLowerCase(),
                            }}
                        />

                        <ColorPicker
                            color={branding.textColor}
                            label={i18n`Text color`}
                            onChange={(color: string) =>
                                this.updateBranding('textColor', color)
                            }
                            validation={{
                                message: i18n`All branding colors must be unique`,
                                showError:
                                    duplicatedColor?.toLowerCase() ===
                                    branding.textColor.toLowerCase(),
                            }}
                        />

                        <TextInput
                            label={i18n`Company page URL`}
                            value={branding.siteUrl}
                            onChange={(siteUrl: string) =>
                                this.updateBranding('siteUrl', siteUrl)
                            }
                        />
                    </div>

                    <div className="branding-container-right">
                        <ImagePicker
                            label={i18n`Company logo`}
                            branding={branding}
                            onChange={(file: File) =>
                                this.handleImageChange(file)
                            }
                        />
                    </div>

                    <div className="branding-footer">
                        <p>{i18n`When changes are saved, they will be visible to everyone else in your organization`}</p>
                    </div>
                </div>
                <div className="pull-right-flex">
                    <Button
                        variant="outline"
                        theme="red"
                        icon="far fa-undo"
                        onClick={this.revertToDefault}
                        renderIconLeft={true}>
                        {i18n`Revert to default`}
                    </Button>

                    <Button
                        className="ml"
                        theme="blue"
                        onClick={this.saveBranding}
                        disabled={!!duplicatedColor}>
                        {i18n`Save changes`}
                    </Button>
                </div>
            </>
        );
    }
}

export default connect((state: ReduxState) => {
    const branding: CustomerBranding = state.customer.branding.data;
    const { isFetching, isLoaded, error } = state.customer.branding
        .__states as BrandingActionStates;

    return {
        branding,
        isFetching,
        isLoaded,
        error,
    };
})(Branding);
