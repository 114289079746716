/* eslint jsx-a11y/accessible-emoji: 0 */

import React from 'react';
import uniqid from 'uniqid';
import { i18n } from 'Language';
import { SSN } from 'types/SSN';
import { SSNs } from 'Constants';
import TooltipRequired from 'Common/components/TooltipRequired';

export type Props = {
    value: string | number | undefined;
    ssnType: string | undefined;
    onChange: Function;
    label?: string;
    placeholder?: string;
    inputClass?: string;
    disabled?: boolean;
    required?: boolean;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

type State = {
    fieldId: string;
};

class SsnInput extends React.Component<Props, State> {
    static defaultProps = {
        ssnType: SSNs.LEGACY_SSN.id,
        disabled: false,
    };

    state: State = {
        fieldId: uniqid(),
    };

    handleSsnTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value, onChange } = this.props;
        const ssnType = e.target.value;

        onChange({ ssnType, value }, e);
    };

    handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { ssnType, onChange } = this.props;
        const value = e.target.value;

        onChange({ ssnType, value }, e);
    };

    renderTypeSelect() {
        const { ssnType, disabled, required } = this.props;

        const { LEGACY_SSN, ...restOfTypes } = SSNs;
        const options: SSN[] = [
            LEGACY_SSN,
            ...Object.values(restOfTypes),
        ].filter((v) => v.isEnabled());

        return (
            <select
                name="ssnType"
                style={{ width: 'auto' }}
                value={ssnType}
                disabled={disabled}
                required={required}
                onChange={this.handleSsnTypeChange}>
                {options.map(({ id, country }) => (
                    <option key={id} value={id}>
                        {id === LEGACY_SSN.id
                            ? `-- ${i18n('Country')} --`
                            : i18n(country?.name)}
                    </option>
                ))}
            </select>
        );
    }

    renderInputField() {
        const {
            value,
            placeholder,
            disabled,
            required,
            inputClass,
            onFocus,
            onBlur,
        } = this.props;
        const { fieldId } = this.state;

        return (
            <input
                type="text"
                name="ssn"
                id={`ssn-${fieldId}`}
                value={value || ''}
                placeholder={placeholder}
                disabled={disabled}
                className={inputClass}
                autoComplete="off"
                required={required}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={this.handleValueChange}
            />
        );
    }

    renderLabel() {
        const { label, required } = this.props;
        const { fieldId } = this.state;

        return (
            <label htmlFor={`ssn-${fieldId}`}>
                {label}
                {required && <TooltipRequired />}
            </label>
        );
    }

    render() {
        const { label } = this.props;

        return (
            <div>
                {label && this.renderLabel()}
                <div
                    className="ssn-input-container"
                    style={{ display: 'flex' }}>
                    {this.renderTypeSelect()}
                    {this.renderInputField()}
                </div>
            </div>
        );
    }
}

export default SsnInput;
