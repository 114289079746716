import { Dispatcher } from 'Core';
import Constants, { defaultBranding } from '../Constants';
import BrandingAPI from 'Branding/utils/BrandingAPI';
import { WorkflowAPI } from 'Api';

const brandingActionCreators = {
    fetchBranding(customerId) {
        BrandingAPI.getBranding(customerId).then((branding) => {
            Dispatcher.handleViewAction({
                type: Constants.ActionTypes.BRANDING_UPDATED,
                branding: branding,
            });
        });
    },

    applyDefaultBranding() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.BRANDING_UPDATED,
            branding: defaultBranding,
        });
    },

    fetchWorkflowBranding(workflowToken) {
        if (!workflowToken) {
            this.applyDefaultBranding();
        }

        WorkflowAPI.get('/v2/prototype/workflows/' + workflowToken)
            .then((workflow) => {
                this.fetchBranding(workflow.customerId);
            })
            .catch(() => {
                this.applyDefaultBranding();
            });
    },
};

export default brandingActionCreators;
