import { API_RESPONSE_HEADERS } from 'Constants';

export type OpenIDClientError = {
    status?: string | number;
    code: string;
    message: string;
    details: string | null;
    data?: any;
    traceId?: string;
    requestId?: string;
};

export const ERROR_OPENID_001 = {
    client: false,
    code: 'ERROR_OPENID_001',
    message: 'There was a problem starting this process',
    details: null,
};

export const ERROR_OPENID_002 = {
    client: false,
    code: 'ERROR_OPENID_002',
    message: 'There was a problem authenticating using OpenID token',
    details: null,
};

// Collect endpoint errors
export const ERROR_OPENID_004 = {
    client: false,
    code: 'ERROR_OPENID_004',
    message: 'There was a problem connecting this ID to your account',
    details: null,
};

export const ERROR_OPENID_005 = {
    client: false,
    code: 'ERROR_OPENID_005',
    message: 'There was a problem logging in with this ID',
    details: null,
};

export const ERROR_OPENID_006 = {
    client: false,
    code: 'ERROR_OPENID_006',
    message: 'There was a problem processing the signing request',
    details: null,
};

// Unknown collect error
export const ERROR_OPENID_007 = {
    client: false,
    code: 'ERROR_OPENID_007',
    message: 'There was a problem processing your request',
    details: null,
};

export const ERROR_OPENID_008 = {
    client: true,
    code: 'ERROR_OPENID_008',
    message: 'There was a problem generating the signing certificate',
    details: "Couldn't create a valid signing certificate request",
};

export const ERROR_OPENID_009 = {
    client: true,
    code: 'ERROR_OPENID_009',
    message: 'There was a problem starting the signature process',
    details: "Couldn't generate the signing data",
};

export const ERROR_OPENID_010 = {
    client: true,
    code: 'ERROR_OPENID_010',
    message: 'There was a problem processing your request',
    details:
        "Cannot process intent. OpenID state couldn't be read from local storage",
};

export const ERROR_OPENID_011 = {
    client: false,
    code: 'ERROR_OPENID_011',
    message: 'There was a problem fetching the CA certificate',
    details: null,
};

export const ERROR_OPENID_012 = {
    client: false,
    code: 'ERROR_OPENID_012',
    message: 'There was a problem initializing the signature process',
    details: null,
};

export const ERROR_OPENID_013 = {
    client: false,
    code: 'ERROR_OPENID_013',
    message:
        "There was a problem reading the signature state from the browser's local storage",
    details: null,
};

export const ERROR_OPENID_014 = {
    client: false,
    code: 'ERROR_OPENID_014',
    message: 'There was a problem finalizing the signature process',
    details: null,
};

export const ERROR_OPENID_015 = {
    client: false,
    code: 'ERROR_OPENID_015',
    message: 'There was a problem validating your identity with this ID',
    details: null,
};

export const ERROR_OPENID_016 = {
    client: false,
    code: 'ERROR_OPENID_016',
    message: 'Your session expired, please validate your identity again',
    details: null,
};

type ServerErrorData = {
    message?: string;
    error?: { message: string } & Record<string, string | number | boolean>;
    cause?: string;
    status?: number;
    title?: string;
    type?: string;
    detail?: string;
    code?: number;
};

type ServerErrorResponse = {
    error?: {};
    message: string;
    data?: ServerErrorData | string;
    status?: number | string;
    statusText?: string;
    headers?: {
        [API_RESPONSE_HEADERS.TRACE_ID]?: string;
        [API_RESPONSE_HEADERS.REQUEST_ID]?: string;
    };
    traceId?: string;
    requestId?: string;
};

const parseServerErrorData = (serverError: ServerErrorResponse) => {
    // Some server errors are returning a string.
    if (
        typeof serverError.data === 'string' &&
        serverError.status &&
        serverError.statusText
    ) {
        return `Server Error: ${serverError.status} ${serverError.statusText}`;
    }

    const serverErrorData = serverError.data as ServerErrorData | undefined;

    // Some server errors have a { data: { error: { message: '...' } } } structure.
    // Some server errors are following the RFC7807 { data: { title: '...' } } structure.
    // Some server errors have a { data: { message: '...' } } structure.
    // Some server errors have a { message: '...' } structure.
    return (
        serverErrorData?.error?.message ??
        serverErrorData?.title ??
        serverErrorData?.message ??
        serverError.message ??
        JSON.stringify(serverErrorData)
    );
};

const parseServerError = (serverError: Error) => {
    let rawError: ServerErrorResponse = serverError;
    const { data, message, traceId, requestId, status, headers } = rawError;

    return {
        data: data ?? null,
        details: data
            ? parseServerErrorData(rawError as ServerErrorResponse)
            : message ?? JSON.stringify(rawError),
        traceId: traceId ?? headers?.[API_RESPONSE_HEADERS.TRACE_ID] ?? null,
        requestId:
            requestId ?? headers?.[API_RESPONSE_HEADERS.REQUEST_ID] ?? null,
        status: status,
        headers: headers,
    };
};

export const createOpenIDError = (
    error: Error,
    baseError?: OpenIDClientError
) => {
    if (!baseError) {
        return {
            ...parseServerError(error),
            handled: true,
        };
    }

    return {
        ...baseError,
        ...parseServerError(error),
        handled: true,
    };
};
