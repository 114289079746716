import PropTypes from 'prop-types';
import React from 'react';
import assign from 'object-assign';

export default class ClickDropdown extends React.Component {
    static propTypes = {
        content: PropTypes.element.isRequired,
        children: PropTypes.element,
        placement: PropTypes.string,
        align: PropTypes.string,
        offset: PropTypes.number,
        width: PropTypes.number,
    };

    static defaultProps = {
        placement: 'bottom left',
    };

    state = {
        show: false,
        content: {
            display: 'none',
        },
        container: {
            display: 'none',
        },
    };

    componentWillUnmount() {
        this.removeEventListeners();
    }

    attachEventListeners = () => {
        window.addEventListener('resize', this.close, true);
        window.addEventListener('keydown', this.handleKeyboard, true);
    };

    removeEventListeners = () => {
        window.removeEventListener('resize', this.close, true);
        window.removeEventListener('keydown', this.handleKeyboard, true);
    };

    handleKeyboard = (event) => {
        if (event.which === 27 || event.keyCode === 27) {
            setTimeout(this.close, 0);
        }
    };

    open = (event) => {
        let rect = event.currentTarget.getBoundingClientRect();

        this.attachEventListeners();

        let align = this.props.align === 'right' ? rect.right : rect.left;

        this.setState({
            content: {
                display: 'block',
                position: 'fixed',
                left: align,
                top: rect.top + 15,
                zIndex: 1002,
            },
            container: {
                display: 'block',
                position: 'fixed',
                zIndex: 1001,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            },
        });
    };

    close = () => {
        this.removeEventListeners();

        this.setState({
            content: { display: 'none' },
            container: { display: 'none' },
        });
    };

    getTooltipStyle = (placement = 'bottom left', offset = '0') => {
        let styles = {
            background: 'white',
            borderRadius: '3px',
            padding: '0.5em',
            border: '1px solid #ddd',
            boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
            position: 'absolute',
            minWidth: (this.props.width || 150) + 'px',
        };

        switch (placement) {
            case 'bottom left':
                assign(styles, { top: '10px', right: offset + 'px' });
                break;
            case 'bottom right':
                assign(styles, { top: '10px', left: offset + 'px' });
                break;
            case 'top left':
                assign(styles, { bottom: '25px', right: offset + 'px' });
                break;
            case 'top right':
            default:
                assign(styles, { bottom: '25px', left: offset + 'px' });
                break;
        }

        return styles;
    };

    getTipStyles = (placement = 'bottom left') => {
        let styles = {
            height: '8px',
            width: '8px',
            position: 'absolute',
            transform: 'rotate(45deg)',
            background: 'white',
            boxShadow: '-1px -1px 1px rgba(0,0,0,0.3)',
        };

        switch (placement) {
            case 'bottom left':
                assign(styles, { top: '-4px', right: '10px' });
                break;
            case 'bottom right':
                assign(styles, { top: '-4px', left: '10px' });
                break;
            case 'top left':
                assign(styles, {
                    bottom: '-5px',
                    right: '8px',
                    boxShadow: '1px 1px 1px rgba(0,0,0,0.3)',
                });
                break;
            case 'top right':
                assign(styles, {
                    bottom: '-5px',
                    left: '8px',
                    boxShadow: '1px 1px 1px rgba(0,0,0,0.3)',
                });
                break;
            default:
                return false;
        }

        return styles;
    };

    render() {
        if (!this.props.content) {
            return false;
        }

        let { offset, placement } = this.props;

        return (
            <span>
                {/* Element */}
                <span onClick={this.open}>{this.props.children}</span>

                {/* Tooltip */}

                {/* Tooltip full-screen container */}
                <div
                    className="tooltip-container"
                    onClick={this.close}
                    style={this.state.container}
                />

                {/* Tooltip element - dynamic placement */}
                <span className="tooltip-content" style={this.state.content}>
                    <div
                        className="content"
                        style={this.getTooltipStyle(placement, offset)}>
                        <div
                            className="tip"
                            style={this.getTipStyles(placement)}
                        />
                        <div onClick={this.close}>{this.props.content}</div>
                    </div>
                </span>
            </span>
        );
    }
}
