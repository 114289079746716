/* eslint max-len: 0 */
import React from 'react';
import { Route, IndexRedirect } from 'react-router';

/* Pages */
import FormRejectedPage from './components/Pages/FormRejectedPage';

/* KYC */
import SubmitKYCPage from 'KYC/views/SubmitKYCPage';

/* Form Document Builder */
import FormBuilderController from './components/Form/FormBuilderController';
import FormMappingEditor from './components/FormTemplate/FormMappingEditor';

import WorkflowHandler from './components/Workflow/RedirectionHandler';

/* Workflow Template */
import WorkflowTemplateList from './components/WorkflowTemplates/WorkflowTemplateList';
import WorkflowTemplateDetails from './components/WorkflowTemplates/WorkflowTemplateDetails';

import SimpleFormDocumentEditor from './controllers/SimpleForm/DocumentEditorController';
import PublicFormDocumentEditor from './controllers/PublicForm/DocumentEditorController';

import Forms from './components/Pages/Forms';
import HeaderContainer from './components/Pages/HeaderContainer';

let userRoutes = (
    <Route>
        <Route component={Forms}>
            <Route name="forms" path="forms">
                <IndexRedirect to="templates/list" />

                <Route
                    name="form-editor"
                    path="template/editor/:formId/:workflowId"
                    component={FormBuilderController}
                />
                <Route
                    name="form-templates"
                    path="templates/list"
                    component={WorkflowTemplateList}
                />
                <Route
                    name="workflow-template-details"
                    path="templates/:workflowId/details"
                    component={WorkflowTemplateDetails}
                />
                <Route
                    name="template-creator"
                    path="template/new"
                    component={FormBuilderController}
                />
                <Route
                    name="template-mapping"
                    path="template/map/:formId/:workflowId"
                    component={FormMappingEditor}
                />
            </Route>
        </Route>
    </Route>
);

let signerRoutes = (
    <Route component={Forms}>
        <Route component={HeaderContainer}>
            <Route
                name="workflow"
                path="workflow/:id/:token"
                component={WorkflowHandler}
            />
            <Route
                name="form"
                path="form/:id"
                component={SimpleFormDocumentEditor}
            />
            <Route
                name="form-rejected"
                path="rejected"
                component={FormRejectedPage}
            />
        </Route>
    </Route>
);

let publicRoutes = (
    <Route component={Forms}>
        <Route component={HeaderContainer}>
            <Route
                name="public-form"
                path="public/form/:formToken/:workflowToken"
                component={PublicFormDocumentEditor}
            />

            <Route
                name="kyc-verification"
                path="kyc/:workflowToken/:kycToken"
                component={SubmitKYCPage}
            />
        </Route>
    </Route>
);

const Routing = { publicRoutes, signerRoutes, userRoutes };

export default Routing;
