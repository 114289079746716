import React from 'react';
import { i18n } from 'Language';
import { PublicAuthAPI } from 'Api';
import { images } from 'Constants';
import Analytics from 'Common/Analytics';
import Message from '../Message';
import './account-setup-error.scss';
import Button from 'Common/components/Button';

export type Props = {
    resendToken: string;
    errorCode: string;
    onBack: Function;
};

export type State = {
    isSending: boolean;
    success: boolean | null;
    error: any | null;
};

class AccountSetupError extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isSending: false,
            success: null,
            error: null,
        };
    }

    handleBackLink = (event) => {
        event.preventDefault();
        this.props.onBack();
    };

    resendRequest = async () => {
        this.setState({
            isSending: true,
        });

        try {
            Analytics.track('request new activation link');

            await PublicAuthAPI.post(
                `/cred/requests/resend/${this.props.resendToken}`
            );
            this.setState({
                success: true,
                isSending: false,
            });
        } catch (error) {
            Analytics.track('error requesting activation link');

            this.setState({
                error: {
                    traceId: error.headers['x-b3-traceid'],
                },
                isSending: false,
            });
        }
    };

    renderSentSuccess() {
        return (
            <div className="account-setup-error sent-success">
                <a
                    className="account-setup-error-back-link"
                    onClick={this.handleBackLink}>
                    <i className="far fa-arrow-left" />
                    &nbsp;{i18n`Back`}
                </a>
                <img
                    src={`${images}/graphics/email_security.png`}
                    height="80"
                />
                <h1>{i18n`We have sent you a new activation link`}</h1>
                <p>
                    {i18n`You will shortly receive a link in your email inbox to finish activating your account.`}
                </p>
                <p>
                    {i18n`Follow the steps in the email and complete the setup to make your account ready to use`}
                </p>
                <br />
                <div className="text-blue text-semibold">
                    {i18n`You can close this page safely now`}
                    &nbsp;<i className="far fa-check"></i>
                </div>
            </div>
        );
    }

    render() {
        const { isSending, success, error } = this.state;
        const { errorCode } = this.props;

        if (success) {
            return this.renderSentSuccess();
        }

        return (
            <div className="account-setup-error">
                <a
                    className="account-setup-error-back-link"
                    onClick={this.handleBackLink}>
                    <i className="far fa-arrow-left" />
                    &nbsp;{i18n`Back`}
                </a>

                <img
                    src={`${images}/graphics/600x600_locked.png`}
                    height="80"
                />
                <h1>{i18n`Please, finish setting up your account`}</h1>
                <p>
                    {i18n`You weren't able to log in because you haven't completed your account activation`}
                </p>
                <p>
                    {i18n`You can request a new link to finish activating your account to be sent to your email by clicking the button below.`}
                </p>
                <br />

                {error ? (
                    <>
                        <Message type="error">
                            {i18n`Could not create a new activation link`}
                        </Message>
                        <br />
                        <Button
                            id="retry-button"
                            theme="green"
                            size="large"
                            onClick={this.resendRequest}
                            icon="far fa-sync">
                            {i18n`Retry`}
                        </Button>
                        <pre className="error-code text-small">
                            {i18n`Trace ID`}: {error.traceId}
                        </pre>
                    </>
                ) : (
                    <>
                        {isSending ? (
                            <Button
                                id="send-button"
                                theme="green"
                                size="large"
                                icon="far fa-sync fa-spin"
                                disabled>
                                {i18n`Sending activation link`}
                            </Button>
                        ) : (
                            <Button
                                id="send-button"
                                theme="green"
                                size="large"
                                onClick={this.resendRequest}
                                icon="far fa-paper-plane">
                                {i18n`Request new activation link`}
                            </Button>
                        )}
                        <pre className="error-code text-small">
                            {i18n`Code`}: {errorCode}
                        </pre>
                    </>
                )}
            </div>
        );
    }
}

export default AccountSetupError;
