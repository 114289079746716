import React from 'react';
import { signingFlowIsRegisteredLetter } from 'RegisteredLetter/utils';
import { CaseFileItem } from './Archive';
import Status from './Status';
import { getCasefileStatus } from 'Casefiles/CasefileDetails/statusCodes';

export type PrefixObject = {
    id: number;
    type: string;
};

export const caseFilesItemIdPrefix = (item: CaseFileItem | PrefixObject) =>
    `${item.type}-${item.id}`;

export const fromPrefixToObject = (prefixedId: string): PrefixObject => {
    const tmp = prefixedId.split('-');

    return {
        id: Number(tmp[1]),
        type: tmp[0] as any,
    };
};

export const orderByTitleAsc = (arr: CaseFileItem[]) =>
    arr.sort((a, b): any =>
        a.title.toUpperCase().localeCompare(b.title.toUpperCase())
    );

export const orderByTitleDesc = (arr: CaseFileItem[]) =>
    arr.sort((a, b): any =>
        b.title.toUpperCase().localeCompare(a.title.toUpperCase())
    );

export const orderByStatusAsc = (arr: CaseFileItem[]) =>
    arr.sort((a, b): any => {
        if (typeof a.status !== 'undefined') {
            return a.status < (b as any).status;
        }

        return 0;
    });

export const orderByStatusDesc = (arr: CaseFileItem[]) =>
    arr.sort((a, b): any => {
        if (typeof a.status !== 'undefined') {
            return a.status > (b as any).status;
        }

        return 0;
    });

export const renderCasefileStatus = (item: CaseFileItem) => {
    const isRegisteredLetter = signingFlowIsRegisteredLetter(
        item.caseFileType?.id
    );

    if (typeof item.status === 'undefined') {
        return '- - -';
    }

    if (isRegisteredLetter) {
        return (
            <Status
                status={{
                    name: 'Sent',
                    details: 'This case has been sent to the recipients',
                    icon: 'check-circle',
                    color: 'gray',
                }}
            />
        );
    }

    return <Status status={getCasefileStatus(item)} />;
};
