import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';

export default class InputErrorMessage extends React.Component {
    static propTypes = {
        message: PropTypes.string,
    };

    render() {
        let { message } = this.props;

        return (
            <div className="input-error-message">
                <div className="error-text-wrapper">
                    <p>{i18n(message)}</p>
                </div>
            </div>
        );
    }
}
