import nbNO from 'rc-calendar/lib/locale/nb_NO';
import nbNO2 from '../time-picker/nb_NO';
import assign from 'object-assign';

let locale = {
    lang: assign(
        {
            placeholder: 'Velg tidspunkt',
            rangePlaceholder: ['Start date', 'End date'],
        },
        nbNO
    ),
    timePickerLocale: assign({}, nbNO2),
};

export default locale;
