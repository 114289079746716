import React from 'react';

import Button from 'Common/components/Button';
import { i18n } from 'Language';
import { CustomerBranding, SigningCasefile } from 'types/SigningProcess';

// Modal
import { modal } from 'Common/components/Common/Modal';
import SigningRejectModal from './SigningRejectModal';

import { trackSigning } from './utils';

type Props = {
    caseFile?: SigningCasefile;
    challengeKey: string;
    branding?: CustomerBranding;
    mobile: boolean;
};

export const SigningRejectButton = ({
    caseFile,
    challengeKey,
    mobile,
}: Props) => {
    const handleOnReject = () => {
        modal.show({
            className: 'signing-reject-modal',
            preventClose: true,
            body: (
                <SigningRejectModal
                    caseFile={caseFile}
                    challengeKey={challengeKey}
                    closeModal={modal.hide}
                />
            ),
        });
        trackSigning('Reject document button click');
    };

    return (
        <Button
            theme="gray"
            className="reject"
            variant="outline"
            size="large"
            onClick={handleOnReject}>
            {i18n(mobile ? `Reject` : `Reject to sign`)}
        </Button>
    );
};
