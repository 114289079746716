import React, { useEffect } from 'react';
import { images } from 'Constants';
import { Languages } from 'Language/Constants';
import { useAppDispatch, useAppSelector } from 'Store';
import { loadState, reset, StateType } from '../../redux/reducer';
import { ItsmeDataToBeSignedComponent } from './ItsmeDataToBeSignedComponent';
import LoadingData from 'Common/components/Loaders/LoadingData';
import { i18n } from 'Language';
import ItsmeError, {
    ItsmeErrorFromRedux,
} from 'ItsmeQES/components/ItsmeError';
import { invalidState } from 'ItsmeQES/errors';
import { resetTriggerFinalizing } from 'Signing/redux/reducer';
import SignatureDestinationSelector from 'ItsmeQES/components/Signing/SignatureDestinationSelector';

export type Props = {
    challengeKey: string;
    languageCode: Languages;
};

const components = {
    [StateType.Uninitialized]: LoadingComponent,
    [StateType.Initialized]: LoadingComponent, // redirect should be in progress
    [StateType.FetchingSignText]: LoadingComponent,
    [StateType.ShowingDataToSign]: ItsmeDataToBeSignedComponent,
    [StateType.SigningConfirmed]: LoadingComponent,
    [StateType.ReadyToSaveSignature]: SignatureDestinationSelector,
    [StateType.Error]: ItsmeErrorFromRedux,
};

function ItsmeSigningWrapper(props: Props) {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.itsmeQESSigning);

    useEffect(() => {
        if (state.type === StateType.Uninitialized) {
            dispatch(loadState(props.challengeKey, props.languageCode));
        }

        return () => {
            dispatch(reset());
            dispatch(resetTriggerFinalizing()); // required because of mounting signing components works.
        };
    }, []);

    const determineComponent = () => {
        const Component = components[state.type];

        if (Component !== undefined) {
            return {
                renderedComponent: <Component />,
                componentName: Component.name,
            };
        }

        const error = invalidState(state.type, 'rendering sub-components');

        return {
            renderedComponent: <ItsmeError error={error}></ItsmeError>,
            componentName: ItsmeError.name,
        };
    };

    const { renderedComponent, componentName } = determineComponent();

    return (
        <div className="openid-sign">
            <div className="flex justify-center">
                <img
                    height="90"
                    className="openid-callback-id-service-logo itsme"
                    src={`${images}/eid/belgium_itsme_logo_512x512.png`}
                    alt="itsme logo"
                />
            </div>

            <div
                className="text-center"
                data-testid={`itsme-signing-wrapper-${componentName}`}>
                {renderedComponent}
            </div>
        </div>
    );
}

function LoadingComponent() {
    return <LoadingData message={i18n('Loading')} />;
}

export default ItsmeSigningWrapper;
