import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const SearchBar = ({ value, onChange, placeholder }) => {
    return (
        <div className="archive-search">
            <MagnifyingGlassIcon
                className="archive-search__icon"
                stroke="currentColor"
                strokeWidth="3"
            />
            <input
                type="text"
                className="archive-search__input"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
        </div>
    );
};

export default SearchBar;
