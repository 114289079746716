import PropTypes from 'prop-types';
import React from 'react';
import PasswordCreateForm from '../Password/PasswordCreateForm';
import PasswordActions from '../../actions/PasswordActionCreators';

export default class PasswordActivation extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    static propTypes = {
        user: PropTypes.object,
        options: PropTypes.array,
        passwordCredentials: PropTypes.object,
        nextLink: PropTypes.object,
    };

    componentDidUpdate() {
        const { passwordCredentials } = this.props;

        if (passwordCredentials) {
            this.context.router.push({
                name: 'invite',
                params: this.props.params,
            });
        }
    }

    savePassword = (username, password) => {
        let credentials = {
            username: username || this.props.user.email,
            password: password,
        };

        PasswordActions.createCredentials(credentials);
    };

    render() {
        return (
            <div className="form activate-user-password">
                <PasswordCreateForm
                    savePassword={this.savePassword}
                    user={this.props.user}
                />
            </div>
        );
    }
}
