import { Client } from '../types';

export type EditableClientProps = Pick<Client, 'name' | 'reference'>;

export const cleanedUpClientData = (
    clientData: EditableClientProps,
    clear = false
) => {
    /**
     * As a basic cleanup,
     * we remove trailing spaces from string values.
     * In order not to pollute the payload with empty values,
     * by selecting 'clear' when the resulting strings are empty,
     * we remove them from the data object.
     */
    const cleanupString = (value: string) =>
        clear ? value.trim() || undefined : value.trim();

    const cleanedUpData: Partial<Client> = {};

    for (const key in clientData) {
        const value = clientData[key];

        cleanedUpData[key] =
            typeof value !== 'string' ? value : cleanupString(value);
    }

    return cleanedUpData as EditableClientProps;
};
