import React, { useState, useRef } from 'react';
import { i18n } from 'Language';
import UILoadingSpinner from 'Common/components/Vega/LoadingSpinner';
import Button from 'Common/components/Button';
import {
    ArrowsPointingOutIcon,
    CheckCircleIcon,
    ExclamationTriangleIcon,
    PencilSquareIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid';
import UITooltip from 'Common/components/Vega/Tooltip';
import { UploadStatus } from '../types';
import { UploadingDocument } from 'types/Document';

import { UploadedDocument } from 'types/CaseFile';
import useUpdateEffect from 'Common/hooks/useUpdateEffect';
import { getDocumentIdentifier } from 'Common/utils/caseFile';

interface Props {
    file: UploadingDocument | UploadedDocument;
    uploadStatus: UploadStatus | undefined;
    preview?: () => void;
    edit?: () => void;
    remove?: () => void;
    isUploadingFile?: boolean;
    showProgressBar?: boolean;
    cancelUpload?: () => void;
}

const completedDocumentIds = new Set<string>();
const DocumentStatusListItem: React.FC<Props> = ({
    file,
    uploadStatus,
    preview,
    edit,
    remove,
    isUploadingFile,
    showProgressBar,
    cancelUpload,
}) => {
    const [showUploadComplete, setShowUploadComplete] = useState(false);
    const timer = useRef<number>();
    const documentId = getDocumentIdentifier(file);

    const markDocumentCompleted = (documentId: string) => {
        completedDocumentIds.add(documentId);
    };

    const isDocumentCompleted = (documentId: string) => {
        return completedDocumentIds.has(documentId);
    };

    useUpdateEffect(() => {
        if (
            uploadStatus === 'completed' &&
            documentId &&
            !isDocumentCompleted(documentId)
        ) {
            markDocumentCompleted(documentId);
            setShowUploadComplete(true);

            timer.current = window.setTimeout(() => {
                setShowUploadComplete(false);
            }, 3000);
        }

        return () => {
            if (timer.current !== undefined) {
                window.clearTimeout(timer.current);
            }
        };
    }, [uploadStatus, documentId]);

    const renderUploadingState = () => (
        <div className="flex flex-row items-center justify-end w-full mt-4 sm:mt-0">
            {!showProgressBar && (
                <div className="flex items-center mr-2 whitespace-nowrap">
                    <span className="mr-4">{i18n`Uploading`}...</span>
                    <div className="p-2">
                        <UILoadingSpinner variant="accent" />
                    </div>
                </div>
            )}
            <div>
                <Button variant="text" onClick={cancelUpload}>
                    <XMarkIcon className="h-6 fill-neutral-700 flex-shrink-0" />
                </Button>
            </div>
            <UITooltip placement="right" offset={7}>
                {i18n`Remove`}
            </UITooltip>
        </div>
    );

    const renderCompletedState = () => (
        <div className="flex flex-row items-center justify-end w-full gap-2 mt-4 sm:mt-0">
            {showUploadComplete && !showProgressBar ? (
                <div className="text-success-500 flex items-center gap-2 whitespace-nowrap">
                    <span>{i18n`uploadStatus.completed`}</span>
                    <CheckCircleIcon className="h-6 flex-shrink-0" />
                    <Button variant="text" disabled={showUploadComplete}>
                        <XMarkIcon className="h-6 flex-shrink-0" />
                    </Button>
                </div>
            ) : (
                <div className="flex flex-row items-center justify-end w-full">
                    {preview && (
                        <>
                            <div>
                                <Button
                                    variant="text"
                                    onClick={() => preview()}>
                                    <ArrowsPointingOutIcon className="h-6 fill-neutral-700 flex-shrink-0" />
                                </Button>
                            </div>
                            <UITooltip placement="bottom" offset={7}>
                                {i18n`Preview`}
                            </UITooltip>
                        </>
                    )}
                    {edit && (
                        <>
                            <div>
                                <Button
                                    className="ml-1"
                                    variant="text"
                                    onClick={() => edit()}>
                                    <PencilSquareIcon className="h-6 fill-neutral-700 flex-shrink-0" />
                                </Button>
                            </div>
                            <UITooltip placement="bottom" offset={7}>
                                {i18n`Edit`}
                            </UITooltip>
                        </>
                    )}
                    {remove && (
                        <>
                            <div>
                                <Button
                                    className="ml-1"
                                    variant="text"
                                    onClick={() => remove()}>
                                    <XMarkIcon className="h-6 fill-neutral-700 flex-shrink-0" />
                                </Button>
                            </div>
                            <UITooltip placement="bottom" offset={7}>
                                {i18n`Remove`}
                            </UITooltip>
                        </>
                    )}
                </div>
            )}
        </div>
    );

    const renderErrorState = () => (
        <div className="flex flex-row items-center justify-end w-full mt-4 sm:mt-0">
            <div className="flex items-center mr-2 whitespace-nowrap">
                <span className="text-red-700">{i18n`uploadStatus.failed`}</span>
                <ExclamationTriangleIcon className="h-6 w-6 mx-2 fill-red-700 flex-shrink-0" />
            </div>
            <div>
                <Button variant="text" onClick={cancelUpload}>
                    <XMarkIcon className="h-6 fill-neutral-700 flex-shrink-0" />
                </Button>
            </div>
            <UITooltip placement="right" offset={7}>
                {i18n`Remove`}
            </UITooltip>
        </div>
    );

    if (!isUploadingFile && (file as UploadedDocument).id) {
        return renderCompletedState();
    } else if (uploadStatus === 'uploading' || uploadStatus === 'delayed') {
        return renderUploadingState();
    } else if (
        typeof uploadStatus === 'object' &&
        uploadStatus.status === 'error'
    ) {
        return renderErrorState();
    }

    return null;
};

export default DocumentStatusListItem;
