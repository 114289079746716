import { Dispatcher, BaseStore } from 'Core';
import assign from 'object-assign';
import lodash from 'lodash';

let _users = [];

function updateList(users) {
    _users = users;
}

function addUser(user) {
    _users.push(user);
}

function removeUser(userId) {
    lodash.remove(_users, { id: userId }); // mutates _users array
}

// Flux Store Creation
const UserManagementStore = assign({}, BaseStore, {
    getUsers() {
        return _users;
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case 'USER_LIST_LOAD_SUCCESS':
                updateList(action.users);
                break;
            case 'USER_CREATION_SUCCESS':
                if (action.user) {
                    addUser(action.user);
                }

                break;
            case 'USER_DELETE_SUCCESS':
                action.userIds.forEach((userId) => removeUser(userId));
                break;
            case 'USER_DELETE_ERROR':
            case 'USER_DELETE_REQUEST':
            case 'USER_CREATION_REQUEST':
            case 'USER_CREATION_FAILURE':
                break;
            default:
                return false;
        }
        UserManagementStore.emit('ACTION_DISPATCHED', action.type, action);
        UserManagementStore.emitChange();
    }),
});

export default UserManagementStore;
