import PropTypes from 'prop-types';
import React from 'react';

export default class Tooltip extends React.Component {
    static propTypes = {
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        horizontalOffset: PropTypes.number,
        style: PropTypes.object,
        direction: PropTypes.string,
        className: PropTypes.string,
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        showArrow: PropTypes.bool,
    };

    static defaultProps = {
        text: null,
        className: '',
        direction: 'up', // up | down
        showArrow: true,
        style: {},
        horizontalOffset: 0,
    };

    render() {
        let {
            text,
            children,
            className,
            showArrow,
            style,
            horizontalOffset,
        } = this.props;

        if (text !== null) {
            return (
                <span
                    className={`tooltip-hover ${
                        this.props.direction
                    } ${className}
                        ${showArrow ? 'show-arrow' : ''}`}
                    style={style}>
                    <div
                        className="tooltip-text"
                        style={{ marginLeft: horizontalOffset + 'px' }}>
                        {/* -6px is always added to the equation to center the triangle properly */}
                        <div
                            className="tooltip-text-triangle"
                            style={{ marginLeft: -horizontalOffset - 6 + 'px' }}
                        />
                        {text}
                    </div>
                    {this.props.children}
                </span>
            );
        }

        return children;
    }
}
