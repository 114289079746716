import React from 'react';
import { SignerRole } from 'types/SignerRole';
import CasefileSignerRolesListItem from './CasefileSignerRolesListItem';

import './casefile-signer-role.scss';

/**
 * Default max number of roles to be listed before trimming the list
 */
export const DEFAULT_TRIM_THRESHOLD = 5;

export type Props = {
    /**
     * The roles to list
     */
    roles: SignerRole[];
    /**
     * The ID of the signer
     */
    signerId?: number;
    /**
     * Whether the activation date should be editable or not
     */
    activationDateEditable?: boolean;
    /**
     * Whether the list should be trimmed after a certain number of roles
     */
    trimList?: boolean;
    /**
     * How many roles should be displayed before the list gets trimmed
     */
    trimThreshold?: number;
};

const CasefileSignerRoleList: React.FunctionComponent<Props> = (props) => {
    const { activationDateEditable = false, roles, trimList, signerId } = props;
    const trimThreshold = props.trimThreshold as number;

    if (roles.length === 0) {
        return null;
    }

    const rolesToDisplay = trimList ? roles.slice(0, trimThreshold) : roles;

    return (
        <ul className="casefile-signer-roles">
            {rolesToDisplay.map((role: any, i: number) => (
                <li key={i}>
                    <CasefileSignerRolesListItem
                        signerId={signerId}
                        role={role}
                        first={i === 0}
                        last={i === rolesToDisplay.length - 1}
                        activationDateEditable={activationDateEditable}
                    />
                </li>
            ))}
            {trimList && roles.length > rolesToDisplay.length && (
                <span className="casefile-signer-role-name">, ...</span>
            )}
        </ul>
    );
};

CasefileSignerRoleList.defaultProps = {
    trimList: true,
    trimThreshold: DEFAULT_TRIM_THRESHOLD,
};

export { CasefileSignerRoleList };
