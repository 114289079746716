import React from 'react';
import { connect } from 'react-redux';
import { AppDispatch } from 'Store';
import Card from 'Common/components/Card';
import Tooltip from 'Common/components/Tooltip';
import classnames from 'classnames';
import { i18n } from 'Language';
import { updateManagedSetting } from 'Settings/redux/actions';
import { ManagedSettingKeys } from 'Settings/redux/types';
import './customer-feature-toggle.scss';

type Props = {
    id: string;
    customerId: number;
    value: boolean | string;
    icon: string;
    type: 'toggle' | 'select';
    dispatch: AppDispatch;
    title: string;
    subtitle: string;
    options?: {
        label: string;
        description: string;
        value: boolean | string;
    }[];
};

class CustomerFeatureToggle extends React.Component<Props> {
    handleChange = (featureName, value) => {
        const { customerId, dispatch } = this.props;
        const payload = {
            [featureName]: value,
        };

        dispatch(
            updateManagedSetting(
                customerId,
                ManagedSettingKeys.features,
                payload
            )
        );
    };

    renderSelectOptions(options: Props['options']) {
        if (!options) {
            return null;
        }

        return (
            <div className="customer-feature-toggle-options">
                {options.map((option, key) => (
                    <div
                        key={key}
                        onClick={() =>
                            this.handleChange(this.props.id, option.value)
                        }
                        className={classnames(
                            'customer-feature-toggle-option',
                            {
                                enabled: option.value === this.props.value,
                            }
                        )}>
                        <Tooltip text={i18n(option.description)}>
                            <span className="customer-feature-toggle-option-button">
                                {i18n(option.label)}
                            </span>
                        </Tooltip>
                    </div>
                ))}
            </div>
        );
    }

    /**
     *  If the element type is not `toggle`, don't apply any change.
     *  multiple selection element changes are handled in the options themselves.
     */
    handleCardToggle = () => {
        const { id, value, type } = this.props;

        if (type !== 'toggle') {
            return;
        }

        this.handleChange(id, !value);
    };

    render() {
        return (
            <Card
                className="customer-feature-toggle"
                enableHover
                onClick={this.handleCardToggle}
                theme="blue">
                <>
                    <div className="customer-feature-toggle-content">
                        <div className="feature-icon">
                            <i className={`far fa-lg fa-${this.props.icon}`} />
                        </div>

                        <div className="feature-description">
                            <h3>{i18n(this.props.title)}</h3>
                            <p>{i18n(this.props.subtitle)}</p>
                        </div>

                        {this.props.type === 'toggle' && (
                            <div className="feature-switch-icon">
                                {this.props.value === true ? (
                                    <i className="fal fa-toggle-on text-blue"></i>
                                ) : (
                                    <i className="fal fa-toggle-on fa-flip-horizontal text-light" />
                                )}
                            </div>
                        )}
                    </div>

                    {this.props.type === 'select' &&
                        this.renderSelectOptions(this.props.options)}
                </>
            </Card>
        );
    }
}

export default connect(() => ({}))(CustomerFeatureToggle);
