import { Dispatcher } from 'Core';
import { SigningAPI, AuthAPI } from 'Api';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';

const userManagementActionCreators = {
    createUser(userDetails, credentials, customerId, role) {
        if (!customerId) {
            throw new Error("Customer ID doesn't exist");
        }

        Dispatcher.handleServerAction({
            type: 'USER_CREATION_REQUEST',
        });

        SigningAPI.post('/customers/' + customerId + '/users', userDetails)
            .then((user) => {
                let credentialsPayload = {
                    allowed: credentials,
                    userId: user.id,
                    customerId: customerId,
                };

                let rolePayload = {
                    role: role,
                    userId: user.id,
                    customerId: customerId,
                };

                let credentialRequest = AuthAPI.post(
                    '/credentials',
                    credentialsPayload
                );

                let roleEndpoint =
                    '/customers/' + customerId + '/users/' + user.id + '/role';
                let roleRequest = SigningAPI.put(roleEndpoint, rolePayload);

                return Promise.all([
                    Promise.resolve(user),
                    credentialRequest,
                    roleRequest,
                ]);
            })
            .then((response) => {
                let user = response[0];

                let payload = {
                    userId: user.id,
                    type: 'initialize',
                };

                // Enforce users to finish the user activation flow before they can log in
                if (LaunchDarkly.variation(Flags.USERS_MUST_COMPLETE_SETUP)) {
                    payload.mustComplete = true;
                }

                return AuthAPI.post('/cred/requests', payload);
            })
            .then(() => {
                Dispatcher.handleServerAction({
                    type: 'USER_CREATION_SUCCESS',
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: 'USER_CREATION_FAILURE',
                    error: error,
                });
            });
    },

    fetchUsers(customerId) {
        SigningAPI.get('/customers/' + customerId + '/users')
            .then((users) => {
                Dispatcher.handleServerAction({
                    type: 'USER_LIST_LOAD_SUCCESS',
                    users: users,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: 'USER_LIST_LOAD_FAILURE',
                    error: error,
                });
            });
    },

    deleteUser(customerId, userIds, newUserId) {
        Dispatcher.handleServerAction({
            type: 'USER_DELETE_REQUEST',
        });

        const payload = !!newUserId ? { newUserId } : undefined;

        let promises = userIds.map((userId) => {
            return SigningAPI.delete(
                `/customers/${customerId}/users/${userId}`,
                payload
            );
        });

        Promise.all(promises)
            .then(() => {
                Dispatcher.handleServerAction({
                    type: 'USER_DELETE_SUCCESS',
                    userIds: userIds,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: 'USER_DELETE_ERROR',
                    error: error,
                });
            });
    },
};

export default userManagementActionCreators;
