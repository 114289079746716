import React, { createContext, useState, ReactNode } from 'react';
import uniqueId from 'lodash/uniqueId';
export type ToastType = 'accent' | 'neutral' | 'danger' | 'warning' | 'success';

export interface Toast {
    id: number;
    message?: string;
    title?: string;
    type: ToastType;
    duration?: number;
    closeButtonLabel?: string;
    action?: {
        label: string;
        tracking?: {
            label: string;
            eventProps?: object;
        };
        url: string;
    };
    testId?: string;
}

interface ToastContextType {
    toasts: Toast[];
    addToast: (
        message?: string,
        title?: string,
        type?: ToastType,
        duration?: number,
        closeButtonLabel?: string,
        action?: Toast['action'],
        testId?: string
    ) => void;
    removeToast: (id: number) => void;
}

export const ToastContext = createContext<ToastContextType | undefined>(
    undefined
);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [toasts, setToasts] = useState<Toast[]>([]);

    const addToast = (
        message?: string,
        title?: string,
        type: ToastType = 'accent',
        duration: number = 5000,
        closeButtonLabel?: string,
        action?: Toast['action'],
        testId: string = 'toast-test'
    ) => {
        const newToast: Toast = {
            id: uniqueId('toast-'),
            message,
            title,
            type,
            duration,
            action,
            closeButtonLabel,
            testId,
        };

        setToasts((prevToasts) => [newToast, ...prevToasts]);
    };

    const removeToast = (id: number) => {
        setToasts((prevToasts) =>
            prevToasts.filter((toast) => toast.id !== id)
        );
    };

    return (
        <ToastContext.Provider value={{ toasts, addToast, removeToast }}>
            {children}
        </ToastContext.Provider>
    );
};
