import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';

export default class StandaloneDropdown extends React.Component {
    static propTypes = {
        languages: PropTypes.object.isRequired,
        selected: PropTypes.string,
        skipInitCallback: PropTypes.bool,
        name: PropTypes.string,
        callback: PropTypes.func,
        className: PropTypes.string,
        value: PropTypes.string,
    };

    state = {
        selected: this.props.selected,
    };

    componentDidMount() {
        this.init();
    }

    init = () => {
        this.setState({ selected: this.props.value || this.props.selected });

        if (!this.props.skipInitCallback) {
            this.props.callback(this.props.value || this.props.selected);
        }
    };

    componentWillReceiveProps(props) {
        if (props.value !== this.props.value) {
            this.setState({ selected: props.value });
        }
    }

    handleChange = (event) => {
        let { value } = event.target;

        this.setState({ selected: value });

        if (this.props.callback) {
            this.props.callback(value, event);
        }
    };

    mapObject = (object, callback) => {
        return Object.keys(object).map((key) => {
            return callback(key, object[key]);
        });
    };

    render() {
        let { languages, name } = this.props;
        let { selected } = this.state;
        const languagesLoading = Object.keys(languages).length === 0;

        return (
            <div className={'simple-language-selector ' + this.props.className}>
                <select
                    name={name}
                    onChange={this.handleChange}
                    value={selected}
                    disabled={languagesLoading}>
                    {languagesLoading ? (
                        <option>{i18n`Loading...`}</option>
                    ) : (
                        this.mapObject(languages, (code, language) => (
                            <option key={code} value={code}>
                                {language.name}
                            </option>
                        ))
                    )}
                </select>
            </div>
        );
    }
}
