import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'Common/components';
import UserStore from 'Auth/stores/UserStore';
import UserPreferenceActions from 'Auth/actions/UserPreferenceActions';

import assign from 'object-assign';
import Tooltip from 'Common/components/Tooltip';
import { notify } from 'react-notify-toast';
import Button from 'Common/components/Button';

export default class UserPreferences extends React.Component {
    static propTypes = {
        user: PropTypes.object,
    };

    state = {
        changed: false,
        preferences: UserStore.getCurrentUser().preferences,
    };

    componentDidMount() {
        UserStore.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        UserStore.removeChangeListener(this.onChange);
    }

    onChange = () => {
        this.setState({
            preferences: UserStore.getCurrentUser().preferences,
        });

        notify.show(
            <span>
                Changes saved <i className="fas fa-check"></i>
            </span>,
            'success',
            1500
        );
    };

    updateOption = (key, value) => {
        let { preferences } = this.state;

        let option = {};

        option[key] = value;

        // Assign is used in favor of replacing the key directly due to the empty preference object
        // being an array because of a serialization problem in the server.

        this.setState({ preferences: assign({}, preferences, option) });
    };

    onSave = () => {
        UserPreferenceActions.savePreferences(this.state.preferences);
    };

    render() {
        let { preferences } = this.state;

        return (
            <div className="form-v2">
                <label>Early Access</label>
                <Checkbox
                    className="v2 checkbox"
                    checked={preferences['casefile-v2-enabled']}
                    style={{ display: 'block' }}
                    label={
                        <span>
                            <b>{`Participate in Case File V2 early access`}</b>
                            &nbsp;&nbsp;
                            <Tooltip
                                text={
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            width: '250px',
                                        }}>
                                        {`This feature is in an early stage of development and
                                        may not yet contain the functionality you
                                        use in a daily basis.`}
                                    </span>
                                }>
                                <span className="text-info text-small">
                                    (
                                    <i className="fas fa-exclamation-triangle" />{' '}
                                    Early stage)
                                </span>
                            </Tooltip>
                        </span>
                    }
                    onChange={(checked) =>
                        this.updateOption('casefile-v2-enabled', checked)
                    }
                />

                <p style={{ marginTop: 0 }}>
                    This feature enables a new step-by-step interface for
                    creating case files.
                    <br />
                    View the changes by pressing the &nbsp;
                    <span className="text-info">
                        Send documents <i className="fas fa-paper-plane" />
                    </span>
                    &nbsp; button in the Dashboard menu.
                </p>

                <Checkbox
                    className="v2 checkbox"
                    checked={preferences['penneo-v2-default']}
                    style={{ display: 'block' }}
                    label={<b>{`Log in to Penneo V2 by default`}</b>}
                    onChange={(checked) =>
                        this.updateOption('penneo-v2-default', checked)
                    }
                />

                <p style={{ marginTop: 0 }}>
                    Skip the archive and log in directly to Penneo V2
                </p>

                <Button theme="green" onClick={this.onSave} icon="fas fa-check">
                    Save Changes
                </Button>
            </div>
        );
    }
}
