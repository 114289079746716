import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FolderItem } from 'Common/redux/Folder/types';
import classnames from 'classnames';

import './folder-tree-item.scss';

type Props = {
    folder: FolderItem;
    folders: FolderItem[];
    onSelect: Function;
    onOpen: Function;
    openItems: number[];
    selectedItem: number | null;
    isChild?: boolean;
};

class FolderTreeItem extends React.Component<Props> {
    static contextTypes = {
        router: PropTypes.object,
    };

    handleClick = (event) => {
        event.stopPropagation();

        const { folder } = this.props;

        this.props.onOpen(folder.id);
        this.props.onSelect(folder.id);
    };

    focusFolder = (ref: any) => {
        const { folder, selectedItem } = this.props;
        const isActive = selectedItem === folder.id;

        if (isActive && ref) {
            const node: any = ReactDOM.findDOMNode(ref);

            node.focus();
        }
    };

    getChildren(parentId) {
        return this.props.folders.filter(
            (folder) => folder.parentId === parentId
        );
    }

    render() {
        const { folder, selectedItem, openItems } = this.props;

        const isOpen = openItems.indexOf(folder.id) !== -1;
        const isActive = selectedItem === folder.id;

        const className = classnames('folder-tree-item', {
            active: isActive,
            open: isOpen,
        });

        const children = this.getChildren(folder.id);
        const hasChildren = children.length > 0;

        const iconClassName = classnames('far', {
            'fa-folder': !hasChildren,
            'fa-folder-open': isOpen && !hasChildren,
            'fa-folder-plus': hasChildren,
        });

        return (
            <li
                className={className}
                key={folder.id}
                onClick={this.handleClick}
                tabIndex={-1}
                ref={this.focusFolder}>
                <div className="folder-tree-item-content">
                    <i className={iconClassName} />
                    &nbsp;
                    {folder.title}
                    {hasChildren && (
                        <span className="pull-right">
                            &nbsp;
                            {isOpen ? (
                                <i className="far fa-chevron-down"></i>
                            ) : (
                                <i className="far fa-ellipsis-h"></i>
                            )}
                        </span>
                    )}
                </div>

                {hasChildren && isOpen && (
                    <ul>
                        {children.map((folder) => (
                            <FolderTreeItem
                                {...this.props}
                                key={folder.id}
                                folder={folder}
                            />
                        ))}
                    </ul>
                )}
            </li>
        );
    }
}

export default FolderTreeItem;
