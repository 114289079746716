import { debug } from 'Core';
import { NewSignerAPI, PublicSignerAPI } from 'Api';
import { V2Validation } from '../../Signing/utils';

export const isTemporaryStorageEnabled = async (
    challengeKey: string
): Promise<boolean> => {
    try {
        const isUsingV2Validation = !!V2Validation.get();
        const api = isUsingV2Validation ? NewSignerAPI : PublicSignerAPI;

        const { temporalStorageDefault, signerArchiveEnabled } = await api.get(
            `/customer/preferences/${challengeKey}`
        );

        return (temporalStorageDefault && signerArchiveEnabled) ?? false;
    } catch (e) {
        debug.log(e);

        return false;
    }
};
