import React from 'react';
import { Link } from 'react-router';
import UIButton from 'Common/components/Vega/Button';
import { i18n } from 'Language';
import analytics from 'Common/Analytics';
import ChevronLeftIcon from '@heroicons/react/20/solid/ChevronLeftIcon';

export type Props = {
    currentRoute: string;
    saveAsDraftButton: Function;
    linkParams: string;
    savingAsDraft: boolean;
    sending: boolean;
    sendCasefile?: Function;
    linkTo?: string;
    isLastStep?: boolean;
    isFormValid?: boolean;
    backLink?: string;
    ariaLabel?: string;
};

const CasefileFooterNavigation = ({
    currentRoute,
    saveAsDraftButton,
    linkTo,
    linkParams,
    isLastStep,
    isFormValid,
    sendCasefile,
    backLink,
    ariaLabel,
    savingAsDraft,
    sending,
}: Props) => {
    const trackAction = (actionLabel: string, destination: string) => {
        const destinationParts = destination.split('-');
        const destinationRoute =
            destinationParts.length > 1 ? destinationParts[1] : destination;

        analytics.track('Casefile footer navigation', {
            destinationRoute: destinationRoute,
            action: actionLabel,
            currentRoute: currentRoute,
        });
    };

    return (
        <div
            className="footer form-v2 w-full max-w-3xl p-8"
            aria-label={ariaLabel}>
            <div className="footer-content right">
                {backLink && (
                    <Link
                        onClick={() => trackAction('back - clicked', backLink)}
                        to={{
                            name: backLink,
                            params: linkParams,
                        }}>
                        <UIButton
                            priority="tertiary"
                            type="neutral"
                            className="pull-left"
                            isDisabled={savingAsDraft || sending}>
                            <ChevronLeftIcon className="h-5 w-5"></ChevronLeftIcon>
                            {i18n`Back`}
                        </UIButton>
                    </Link>
                )}
                {saveAsDraftButton()}

                {!isLastStep && linkTo && (
                    <Link
                        onClick={() =>
                            trackAction('save and continue - clicked', linkTo)
                        }
                        to={{
                            name: linkTo,
                            params: linkParams,
                        }}>
                        <UIButton
                            className="pull-right"
                            priority="primary"
                            type="accent"
                            isDisabled={savingAsDraft || sending}>
                            {i18n`Save & continue`}
                        </UIButton>
                    </Link>
                )}
                {isLastStep && sendCasefile && (
                    <UIButton
                        priority="primary"
                        type="success"
                        className="pull-right"
                        onClick={() => {
                            sendCasefile();
                            trackAction('send documents - clicked', 'submit');
                        }}
                        isDisabled={!isFormValid || savingAsDraft}
                        isLoading={sending}>
                        <span slot="leftIcon">
                            <i className="far fa-paper-plane"></i>
                        </span>
                        {i18n`Send documents`}
                    </UIButton>
                )}
            </div>
        </div>
    );
};

export default CasefileFooterNavigation;
