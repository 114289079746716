import { jwtDecode } from 'jwt-decode';
import { storage } from 'Core';
import { StorageKeys } from 'Constants';

enum JWT_ROLES {
    ROLE_SENDER = 'ROLE_SENDER',
    ROLE_VALIDATOR = 'ROLE_VALIDATOR',
    ROLE_FORMS_USER = 'ROLE_FORMS_USER',
}

type PenneoJWT = {
    type: string;
    u_qty: number;
    uid: number;
    cid: number;
    ver: string;
    roles: JWT_ROLES[];
    exp: number;
    iat: number;
};

export const decodePenneoJWT = (token: string): PenneoJWT => {
    return jwtDecode(token);
};

/**
 * Reads the Penneo token from local storage
 */
export const getLocalPenneoToken = (): string =>
    storage.get(StorageKeys.JWT_TOKEN);

/**
 * Reads and decodes the Penneo token from local storage
 */
export const getDecodedPenneoToken = (): PenneoJWT | null => {
    const token = getLocalPenneoToken();

    if (!token) {
        return null;
    }

    return decodePenneoJWT(getLocalPenneoToken());
};
