import React from 'react';
import TextInput from '../TextInput';
import { isEqual } from 'lodash';
import classNames from 'classnames';

export type Props = {
    data: any[];
    value: number | string | undefined | null;
    onChange: Function;
    onSelect: Function;
    renderItem: Function;
    filterAutocomplete: Function;
    label?: string;
    placeholder?: string;
    required?: boolean;
    className?: string;
    inputStyle?: any;
    type?: string;
    onFocus?: Function;
    extractKey?: Function;
    onSubmit?: Function;
    autoFocus?: boolean;
    inputDisabled?: boolean;
    onBlurHandler?: (inputValue: Props['value']) => void;
};

type State = {
    activeIndex: number;
    suggestion: any[];
    displaySuggestion: boolean;
};

export default class Autocomplete extends React.Component<Props, State> {
    static defaultProps = {
        data: [],
        extractKey: (item) => item.email,
    };

    holderRef;
    inputIsFocused;

    constructor(props) {
        super(props);

        this.inputIsFocused = false;

        this.state = {
            activeIndex: 0,
            suggestion: [],
            displaySuggestion: false,
        };
    }

    componentDidMount() {
        window.addEventListener('keyup', this.handleKeyUp);
        window.addEventListener('click', this.handleCloseSuggestion);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp);
        window.removeEventListener('click', this.handleCloseSuggestion);
    }

    componentDidUpdate(prevProps) {
        const { data, value } = this.props;

        const hasDataChanged = !isEqual(data, prevProps.data);
        const hasValueAndIsFocused = value && this.inputIsFocused;

        /**
         * If the data changed while the input is in focus, update the
         * suggestions
         */
        if (hasDataChanged && hasValueAndIsFocused) {
            this.updateSuggestion(value);
        }
    }

    handleKeyUp = (e) => {
        if (!this.inputIsFocused) {
            return;
        }

        e.preventDefault();
        const key = e.keyCode || e.which;
        const { activeIndex, suggestion } = this.state;

        switch (key) {
            case 13: {
                // key code for [Enter]
                if (!suggestion.length || !suggestion[activeIndex]) {
                    return (
                        this.props.onSubmit &&
                        this.props.onSubmit(this.props.value)
                    );
                }

                this.props.onSelect(suggestion[activeIndex]);
                this.setState({
                    displaySuggestion: false,
                    activeIndex: 0,
                    suggestion: [],
                });
                break;
            }
            case 38: {
                // key code for [Arrow up]
                if (activeIndex === 0) {
                    this.setState({ activeIndex: suggestion.length - 1 });
                } else {
                    this.setState({ activeIndex: activeIndex - 1 });
                }

                break;
            }
            case 40: {
                // key code for [Arrow down]
                if (activeIndex >= 0 && activeIndex + 1 !== suggestion.length) {
                    this.setState({
                        activeIndex: activeIndex + 1,
                    });
                } else if (activeIndex + 1 === suggestion.length) {
                    this.setState({ activeIndex: 0 });
                }

                break;
            }
            default:
                break;
        }
    };

    updateSuggestion(text) {
        const { data, filterAutocomplete } = this.props;
        const suggestion = data.filter(filterAutocomplete(text.toLowerCase()));

        this.setState({
            suggestion,
            displaySuggestion: true,
            activeIndex: 0,
        });
    }

    handleInputOnChange = (text) => {
        this.props.onChange(text);
        this.updateSuggestion(text);
    };

    handleOnClickSuggestion = (index) => () => {
        this.props.onSelect(this.state.suggestion[index]);
    };

    handleOnMouseEnter = (activeIndex) => () => this.setState({ activeIndex });

    handleCloseSuggestion = () => this.setState({ displaySuggestion: false });

    handleOnFocus = () => {
        const { onFocus } = this.props;

        this.inputIsFocused = true;
        onFocus && onFocus(this.inputIsFocused);
    };

    onTabPressed = (e) => {
        const key = e.keyCode || e.which;

        if (key === 9) {
            this.setState({ displaySuggestion: false });
        }

        if (key === 27) {
            e.stopPropagation();
            this.setState({ displaySuggestion: false });
        }
    };

    handleOnBlur = () => {
        const { onFocus, onBlurHandler } = this.props;

        this.inputIsFocused = false;
        onFocus && onFocus(this.inputIsFocused);

        onBlurHandler && onBlurHandler(this.props.value);
    };

    handleHolderRef = (ref) => (this.holderRef = ref);

    render() {
        return (
            <div
                className="autocomplete-list-holder"
                ref={this.handleHolderRef}>
                <TextInput
                    type={this.props.type || 'text'}
                    value={this.props.value}
                    autoComplete={this.props.data.length ? 'false' : 'true'}
                    label={this.props.label}
                    onKeyDown={this.onTabPressed}
                    placeholder={this.props.placeholder}
                    required={this.props.required}
                    className={this.props.className}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBlur}
                    onChange={this.handleInputOnChange}
                    style={this.props.inputStyle}
                    autoFocus={this.props.autoFocus}
                    disabled={this.props.inputDisabled}
                />
                {this.state.displaySuggestion &&
                    !!this.state.suggestion.length &&
                    !!this.props.value && (
                        <ul
                            className="autocomplete-list"
                            style={{ top: `${this.holderRef.offsetHeight}px` }}>
                            {this.state.suggestion.map((item, index) => {
                                const classes = ['info'];

                                if (index === this.state.activeIndex) {
                                    classes.push('active');
                                }

                                if (!!item.onBehalfOf) {
                                    classes.push('onbehalfof');
                                }

                                return (
                                    <li
                                        key={
                                            this.props?.extractKey?.(item) +
                                            `-${index}`
                                        }
                                        className={classNames(...classes)}
                                        onClick={this.handleOnClickSuggestion(
                                            index
                                        )}
                                        onMouseEnter={this.handleOnMouseEnter(
                                            index
                                        )}>
                                        {this.props.renderItem(item)}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
            </div>
        );
    }
}
