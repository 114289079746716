import React, { useState, useEffect } from 'react';
import { i18n } from 'Language';
import UIButton from 'Common/components/Vega/Button';
// redux
import { connect } from 'react-redux';
import {
    restoreItems,
    deleteItems,
    TrashFetchParams,
    deleteAllItems,
    restoreAllItems,
} from './redux/actions';
import { AppDispatch, ReduxState } from 'Store';
import { TrashItem, TrashRow } from './redux/types';
import { TrashIcon, ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import SearchBar from './SearchBar';
import RestoreItemsModal from './RestoreItemsModal';
import DeleteItemsModal from './DeleteItemsModal';
import Analytics from 'Common/Analytics';

export type Props = {
    dispatch: AppDispatch;
    searchValue: string;
    changeFilter: Function;
    currentResults: TrashItem[] | null;
    params: TrashFetchParams;
    itemCount: number;
    selectedRows: TrashRow[];
    isFetching: boolean;
};

export const CustomHeader: React.FC<Props> = ({
    dispatch,
    searchValue,
    changeFilter,
    currentResults,
    params,
    itemCount,
    selectedRows,
    isFetching,
}) => {
    const [localSearchValue, setLocalSearchValue] = useState(searchValue);

    const [showRestoreModal, setShowRestoreModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        setLocalSearchValue(searchValue);
    }, [searchValue]);

    const searchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        setLocalSearchValue(newValue);
        changeFilter(newValue);
    };

    const onRestoreClick = () => {
        setShowRestoreModal(true);
    };

    const onRestoreModalSubmit = () => {
        setShowRestoreModal(false);

        selectedRows.length
            ? actionBuilder(restoreItems)
            : dispatch(restoreAllItems());

        Analytics.track('recycle bin - restore items submitted', {
            itemsCount: getRestoredItemCount(),
        });
    };

    const onRestoreModalHide = () => {
        setShowRestoreModal(false);

        Analytics.track('recycle bin - restore items canceled', {
            itemsCount: getRestoredItemCount(),
        });
    };

    const onDeleteClick = () => {
        setShowDeleteModal(true);
    };

    const onDeleteModalSubmit = () => {
        setShowDeleteModal(false);

        selectedRows.length
            ? actionBuilder(deleteItems)
            : dispatch(deleteAllItems());

        Analytics.track('recycle bin - delete items submitted', {
            itemsCount: getRestoredItemCount(),
        });
    };

    const onDeleteModalHide = () => {
        setShowDeleteModal(false);

        Analytics.track('recycle bin - delete items canceled', {
            itemsCount: getRestoredItemCount(),
        });
    };

    /**
     * Check for selected or current items
     * and restore or delete them accordingly
     * by using the dispatch action as callback
     */

    const actionBuilder = (callback: Function) => {
        const ids = selectedRows.map((row) => row.id);

        dispatch(callback(ids, params));
    };

    const getDeleteButtonText = () => {
        const count = selectedRows.length;

        if (count === 1) {
            return i18n`Delete ${count} item`;
        }

        if (count > 1) {
            return i18n`Delete ${count} items`;
        }

        return i18n`Delete all items`;
    };

    const getRestoreButtonText = () => {
        const count = selectedRows.length;

        if (count === 1) {
            return i18n`Restore ${count} item`;
        }

        if (count > 1) {
            return i18n`Restore ${count} items`;
        }

        return i18n`Restore all`;
    };

    const getRestoredItemCount = () => {
        return selectedRows.length === 0 ? itemCount : selectedRows.length;
    };

    return (
        <>
            <div
                className="archive-header container-header"
                style={{ paddingBottom: 0 }}>
                <div className="archive-header__search-group">
                    <SearchBar
                        value={localSearchValue}
                        onChange={searchChange}
                        placeholder={i18n('Type to filter')}
                    />
                    <span className="archive-header__result-count">
                        {`${itemCount}\u00A0${
                            itemCount > 1 ? i18n('Results') : i18n('Result')
                        }`}
                    </span>
                </div>
                <div className="archive-header__actions">
                    {itemCount > 0 && (
                        <>
                            <UIButton
                                isDisabled={isFetching}
                                priority="secondary"
                                type="accent"
                                onClick={onRestoreClick}>
                                <ArrowUturnLeftIcon className="icon-button" />
                                {getRestoreButtonText()}
                            </UIButton>
                            <UIButton
                                isDisabled={isFetching}
                                priority="secondary"
                                type="danger"
                                onClick={onDeleteClick}>
                                <TrashIcon className="icon-button" />
                                {getDeleteButtonText()}
                            </UIButton>
                        </>
                    )}
                </div>
            </div>

            <div className="text-neutral-900">
                {showRestoreModal && (
                    <RestoreItemsModal
                        handleCloseModal={onRestoreModalHide}
                        submitRestore={onRestoreModalSubmit}
                        selectedItems={getRestoredItemCount()}
                        allSelected={selectedRows.length === 0}
                    />
                )}
                {showDeleteModal && (
                    <DeleteItemsModal
                        handleCloseModal={onDeleteModalHide}
                        submitDelete={onDeleteModalSubmit}
                        selectedItems={getRestoredItemCount()}
                        allSelected={selectedRows.length === 0}
                    />
                )}
            </div>
        </>
    );
};

export default connect((state: ReduxState) => {
    return {
        itemCount: state.trash.itemCount,
        selectedRows: state.trash.selectedRows,
        isFetching: state.trash.isFetching,
    };
})(CustomHeader);
