import React, { useCallback, useState } from 'react';
import { i18n } from 'Language';

import { Moment } from 'moment';
import { CustomerEntity } from 'types/Customer';
import classNames from 'classnames';
import UIButton from 'Common/components/Vega/Button';
import UIDateTimePicker from 'Common/components/Vega/DateTimePicker';
import {
    getDate,
    getDateTime,
    getDateWithTimezoneOffset,
} from 'Common/components/Vega/utils';
import moment from 'moment';

type Props = {
    customer: CustomerEntity;
    sendAtDate: Moment | null;
    expireAtDate: Moment | null;
    enableExpireDate: () => void;
    changeExpireAtDate: (date: any) => void;
    clearExpireDate: () => void;
};

const CasefileRoundsDateConfiguration = ({
    customer,
    sendAtDate,
    expireAtDate,
    changeExpireAtDate,
    clearExpireDate,
}: Props) => {
    const [dateSelector, setDateSelector] = useState<Moment | undefined>();
    const [editingDate, setEditingDate] = useState<boolean>(false);

    const toggleEditingDate = useCallback(() => setEditingDate(!editingDate), [
        editingDate,
    ]);

    const editTempExpiryDate = useCallback(
        (date?: Moment) => setDateSelector(date),
        [dateSelector]
    );

    const persistExpiryDate = useCallback(() => {
        changeExpireAtDate(dateSelector);
    }, [dateSelector]);

    /**
     * If we aren't currently editing a date, the default date to edit
     * will be the existing expireAtDate or, by default, the send date
     * or today's date plus the customer's expiration setting.
     * NOTE: by using moment(currentSendAtDate) we are cloning the date,
     * otherwise we would be modifying the date stored in Flux
     */
    const currentSendAtDate = sendAtDate ?? getDate();

    const defaultExpiryDate = moment(currentSendAtDate).add(
        customer.caseFileExpiryInterval ?? 0,
        'days'
    );

    const tempDate = dateSelector
        ? dateSelector
        : expireAtDate ?? defaultExpiryDate;

    return (
        <div className="casefile-round-options-section casefile-date-configuration">
            <div className="casefile-round-section-header">
                <span className="casefile-round-section-header-text">
                    {i18n`Casefile configuration`}
                </span>
            </div>

            <div className="casefile-round-options-date">
                {(expireAtDate || (!expireAtDate && editingDate)) && (
                    <div className="casefile-round-scheduling">
                        <div
                            className={classNames(
                                'casefile-round-scheduling-selector',
                                {
                                    editing: editingDate,
                                }
                            )}>
                            {!editingDate ? (
                                <>
                                    <div className="casefile-round-date">
                                        <i className="far fa-calendar-exclamation fa-lg pr-2.5" />
                                        {i18n`Expire at`}:
                                        <span className="regular">
                                            {(expireAtDate as Moment).format(
                                                'ddd, MMMM Do YYYY HH:mm'
                                            )}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="casefile-round-date-picker">
                                        <UIDateTimePicker
                                            attachedLabel={i18n`Scheduled for`}
                                            minDate={getDateTime(
                                                currentSendAtDate
                                            )}
                                            value={getDateTime(tempDate)}
                                            onInput={(
                                                event: UIEvent,
                                                values: Pick<
                                                    HTMLInputElement,
                                                    'value' | 'valueAsNumber'
                                                >
                                            ) => {
                                                /**
                                                 * In some browsers, there's a 'clear' button on the date picker.
                                                 * When clicked, it triggers an input event with an empty value,
                                                 * so we need to check we are passing a valid timestamp
                                                 * or default to undefined
                                                 */
                                                let timestamp;

                                                if (values.value) {
                                                    timestamp =
                                                        values.valueAsNumber;
                                                }

                                                editTempExpiryDate(
                                                    getDateWithTimezoneOffset(
                                                        timestamp
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="casefile-round-date-action casefile-round-date-apply">
                                        <UIButton
                                            onClick={() => {
                                                persistExpiryDate();

                                                // close editing mode
                                                toggleEditingDate();
                                            }}
                                            priority="primary"
                                            type="accent"
                                            isDisabled={!dateSelector}>
                                            {i18n`Apply`}
                                        </UIButton>
                                    </div>
                                    <div className="casefile-round-date-action casefile-round-date-cancel">
                                        <UIButton
                                            onClick={() => {
                                                // Reset temp date
                                                editTempExpiryDate();

                                                // close editing mode
                                                toggleEditingDate();
                                            }}
                                            priority="tertiary"
                                            type="accent">
                                            {i18n`Cancel`}
                                        </UIButton>
                                    </div>
                                </>
                            )}
                        </div>
                        {expireAtDate && !editingDate && (
                            <>
                                <div className="casefile-round-date-action casefile-round-date-edit">
                                    <UIButton
                                        onClick={() => toggleEditingDate()}
                                        priority="tertiary"
                                        type="neutral"
                                        size="sm">
                                        {i18n`Edit`}
                                    </UIButton>
                                </div>
                                <div className="casefile-round-date-action casefile-round-date-reset">
                                    <UIButton
                                        onClick={() => clearExpireDate()}
                                        priority="tertiary"
                                        type="neutral"
                                        size="sm">
                                        {i18n`Reset`}
                                    </UIButton>
                                </div>
                            </>
                        )}
                    </div>
                )}

                {!expireAtDate && !editingDate && (
                    <div className="casefile-round-scheduling-toggle">
                        <UIButton
                            onClick={() => toggleEditingDate()}
                            priority="tertiary"
                            type="accent"
                            size="sm">
                            {i18n`Expiry date`}
                            <i
                                slot="leftIcon"
                                className="far fa-calendar-exclamation fa-lg"
                            />
                        </UIButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CasefileRoundsDateConfiguration;
