import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiClient, ClaAPIProxyPrototype } from "Api";

import { AppDispatch, AppThunk, GetState, ReduxState } from "Store";
import { CaseFileEntity } from "types/CaseFile";
export type Entity = {
  id: string;
  name: string;
  vatin: string;
  cases: CaseFileDetailed[] | null;
  persons: CompanyPerson[] | null;
};

type Recipient = {
  email: string;
  name: string;
  role: Role;
};

type Role = {
  id: number;
  role: string;
} & Record<string, string | number>;

export type CompanyPerson = {
  id: string;
  name: string;
  roles: string[];
};

export type CaseFileDetailed = CaseFileEntity & {
  attributes: CaseFileAttribute[];
};

// The below line is totally valid TypeScript typing since TS 4.1
// https://www.typescriptlang.org/docs/handbook/2/template-literal-types.html
// eslint-disable-next-line
type AttributePrefix = `aa.penneo.com/${string}`;

type CaseFileAttribute = {
  id: number;
  name: AttributePrefix;
  value: string;
};

type CompanyData = {
  id: string;
  name: string;
  vatin: string;
  signers: CompanyPerson[];
  cases: CaseFileDetailed[];
};

interface EntitiesState {
  fetchedEntities: boolean;
  entities: Entity[];
  selectedEntity: Entity | null;
  previousRecipients: Recipient[];
  previousCase: CaseFileDetailed | null;
}

export const initialState: EntitiesState = {
  entities: [],
  fetchedEntities: false,
  selectedEntity: null,
  previousRecipients: [],
  previousCase: null,
};

export const entitiesSlice = createSlice({
  name: "entities",
  initialState,
  reducers: {
    setSelectedEntity: (state, action: PayloadAction<Entity>) => {
      state.selectedEntity = action.payload;
    },
    setEntities: (state, action: PayloadAction<Entity[]>) => {
      //TODO: Assumption! If we fetch the list we are should not have a selectedEntity...
      state.selectedEntity = null;
      state.entities = action.payload;
      state.fetchedEntities = true;
    },
  },
});

export const { setSelectedEntity, setEntities } = entitiesSlice.actions;

const fetchCompanyDetails = async (vatin: string) => {
  const params = new URLSearchParams({ vatin });
  const data = await ClaAPIProxyPrototype.get(`/cla/details`, params);

  return data;
};

export const fetchEntities = (): AppThunk =>
  async (
    dispatch: AppDispatch,
    _: GetState,
    { api }: { api: { SigningAPI: ApiClient } },
  ) => {
    try {
      const url = "/v2/casefiles";
      const params = {
        per_page: 100,
        sort: "-created",
        //sort: '-completed',
      };
      const { data: cases }: { data: CaseFileEntity[] } = await api.SigningAPI
        .get(
          url,
          params,
          {
            paginate: true,
          },
        );

      if (cases.length <= 0) return;

      const companies: Entity[] = await Promise.all(
        cases?.map(async ({ id }) => {
          const data: CaseFileDetailed = await api.SigningAPI.get(
            `${url}/${id}/details`,
          );

          return data;
        }),
      )
        .then((cases: any) => {
          let dataIndex = 0;

          const companies: CompanyData[] = cases.reduce(
            (prev: CompanyData[] | [], curr: CaseFileDetailed) => {
              let prevCopy = [...prev];

              if (curr?.attributes.length <= 0) return prevCopy;

              const vatin = curr?.attributes?.find(
                (data: CaseFileAttribute) =>
                  data.name === "aa.penneo.com/audited-company-vatin",
              )?.value;

              const name = curr?.attributes?.find(
                (data: CaseFileAttribute) =>
                  data.name === "aa.penneo.com/audited-company-name",
              )?.value;

              const prevObj = prev?.find((company: CompanyData) => {
                return company?.vatin === vatin;
              }) ?? null;

              if (prevObj) {
                prevCopy = prevCopy.filter(
                  (obj) => obj.vatin !== prevObj.vatin,
                );
              }

              const signers = [
                ...((prevObj && prevObj?.signers) ?? []),
                ...((curr && curr?.signers) ?? []),
              ];
              let obj = {
                id: dataIndex++,
                ...prevObj,
                name,
                vatin,
                signers,
                cases: [...((prevObj && prevObj?.cases) ?? []), curr],
              };

              return [...prevCopy, obj];
            },
            [],
          );

          return companies;
        })
        .then(async (companies: CompanyData[]) => {
          const fullCompanies = await Promise.all(
            companies.map(async (company: CompanyData) => {
              if (company) {
                const { persons }: { persons: CompanyPerson[] } =
                  await fetchCompanyDetails(
                    company.vatin,
                  );

                return { ...company, persons };
              }

              return null;
            }),
          );

          return fullCompanies as Entity[];
        });

      dispatch(
        setEntities([...companies].filter((v) => !!v)),
      );
    } catch (error) {
      console.error(error);
    }
  };

export const entitiesState = (state: ReduxState) => state.entities;

export default entitiesSlice.reducer;
