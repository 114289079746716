import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import Validation from 'Common/components/Common/Validation';
import Constants from 'Constants';

export default class PasswordPrompt extends React.Component {
    static propTypes = {
        password: PropTypes.string,
        repeat: PropTypes.string,
        changeHandler: PropTypes.func,
    };

    changePasswordHandler = (event) => {
        event.preventDefault();

        this.props.changeHandler({
            password: event.target.value,
        });
    };

    componentDidUpdate(prevProps) {
        let passwordChanged = this.props.password !== prevProps.password;
        let repeatChanged = this.props.repeat !== prevProps.repeat;

        if (passwordChanged || repeatChanged) {
            if (this.props.password === this.props.repeat) {
                this.isPasswordMatch(true);
            } else {
                this.isPasswordMatch(false);
            }
        }
    }

    changeRepeatHandler = (event) => {
        event.preventDefault();

        this.props.changeHandler({
            repeat: event.target.value,
        });
    };

    isPasswordValidCallback = (value) => {
        this.props.changeHandler({
            isValid: value,
        });
    };

    isPasswordMatch = (value) => {
        this.props.changeHandler({
            matches: value,
        });
    };

    render() {
        let { password, repeat } = this.props;

        const passwordValidation = Constants.validationRules.password;

        let repeatValidation = [
            {
                rule: /^.+$/,
                message: 'Field cannot be empty',
            },
            {
                condition: repeat && repeat.length > 0 && password === repeat,
                message: 'Passwords must match',
            },
        ];

        return (
            <div>
                <label htmlFor="password">{i18n('Password')}</label>

                <Validation
                    value={password}
                    rules={passwordValidation}
                    callback={this.isPasswordValidCallback}>
                    <input
                        id="password"
                        type="password"
                        autoComplete="off"
                        value={password || ''}
                        onChange={this.changePasswordHandler}
                        placeholder={i18n('Type a password')}
                    />
                </Validation>

                <label htmlFor="repeat-password">
                    {i18n('Repeat password')}
                </label>

                <Validation value={repeat} rules={repeatValidation}>
                    <input
                        id="repeat-password"
                        autoComplete="off"
                        type="password"
                        value={repeat || ''}
                        onChange={this.changeRepeatHandler}
                        placeholder={i18n('Repeat password')}
                    />
                </Validation>
            </div>
        );
    }
}
