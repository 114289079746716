import { createComponent } from '@lit/react';
import { Lozenge } from '@penneo/vega-ui';
import React from 'react';

const UILozenge = createComponent({
    tagName: 'vega-lozenge',
    elementClass: Lozenge,
    react: React,
});

export default UILozenge;
