import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'Language';

// Redux
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';

// Components
import { Checkbox } from 'Common/components';
import {
    KYCRequirements as KYCRequirementsType,
    DataFields,
    FieldTypes,
    KYCRequirementItem,
} from 'KYC/redux/types';
import { updateRequirements } from 'KYC/redux/actions';

import './kyc-requirements.scss';

type Props = {
    dispatch: AppDispatch;
    requirements: KYCRequirementsType;
};

type State = {
    hasError: boolean;
};

class KYCRequirements extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    handleDataChange = (name: DataFields, data: KYCRequirementItem) => {
        const { dispatch } = this.props;

        dispatch(updateRequirements(name, data));
    };

    renderRequirementItem = (field) => {
        return (
            <div className="kyc-requirement-item" key={field.id}>
                <div className="kyc-requirement-item-content">
                    <div className="inline-block align-top">
                        <i
                            className={`kyc-requirement-item-icon ${field.icon}`}
                        />
                        <div className="kyc-requirement-item-title">
                            {field.title}
                        </div>
                    </div>

                    <div className="inline-block align-top">
                        <Checkbox
                            className="kyc-requirement-item-checkbox"
                            label={i18n`Enabled`}
                            checked={field.value.enabled}
                            onChange={(value) =>
                                this.handleDataChange(field.id, {
                                    enabled: value,
                                    type: field.type,
                                })
                            }
                        />

                        <Checkbox
                            className="kyc-requirement-item-checkbox"
                            label={i18n`Required`}
                            checked={field.value.required}
                            onChange={(value) =>
                                this.handleDataChange(field.id, {
                                    required: value,
                                    type: field.type,
                                })
                            }
                        />
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { requirements } = this.props;

        const photoIds = [
            {
                id: DataFields.PASSPORT,
                icon: 'far fa-passport',
                title: i18n`Passport`,
                type: FieldTypes.FILE,
                value: requirements.passport,
            },
            {
                id: DataFields.SSN_CARD,
                icon: 'far fa-id-card-alt',
                title: i18n`National ID card`,
                type: FieldTypes.FILE,
                value: requirements.ssnCard,
            },
            {
                id: DataFields.DRIVER_LICENSE,
                icon: 'far fa-car-side',
                title: i18n`Driver license`,
                type: FieldTypes.FILE,
                value: requirements.driverLicense,
            },
        ];

        const additionalFields = [
            {
                id: DataFields.ADDRESS,
                icon: 'far fa-map-marker-alt',
                title: i18n`Address`,
                type: FieldTypes.TEXT,
                value: requirements.address,
            },
            {
                id: DataFields.PHONE,
                icon: 'far fa-phone',
                title: i18n`Phone number`,
                type: FieldTypes.TEXT,
                value: requirements.phone,
            },
        ];

        return (
            <div>
                <h4 className="section-header mb0 pb0">{i18n`Verification`}</h4>

                <p className="mt0">
                    {i18n`Which verification methods do you want to request your recipients to complete?`}
                </p>

                <label>{i18n`Photo ID`}</label>

                {photoIds.map(this.renderRequirementItem)}

                <label>{i18n`Collect additional information`}</label>

                {additionalFields.map(this.renderRequirementItem)}
            </div>
        );
    }
}

export default connect((state: ReduxState) => ({
    requirements: state.kyc.__new.requirements,
}))(KYCRequirements);
