import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ReduxState, dispatch } from 'Store';
import { i18n } from 'Language';
import { UserEntity } from 'types/User';
import { ProfileItem } from 'Auth/redux/profiles/types';
import { modal } from 'Common/components/Common/Modal';
import Button from 'Common/components/Button';
import ProfilesList from 'Common/components/ProfileSelector/ProfilesList';
import LoadingData from '../Loaders/LoadingData';
import { fetchProfiles } from 'Auth/redux/profiles/actions';

type Props = {
    user: UserEntity;
    profiles: ProfileItem[];
    isLoaded: boolean;
};

export function ProfilesModal(props: Props) {
    const { user, profiles, isLoaded } = props;

    useEffect(() => {
        dispatch(fetchProfiles());
    }, []);

    return (
        <div>
            <div className="casefile-modal-v2">
                <label className="casefile-modal-v2-title">
                    {i18n`Choose an account`}
                </label>

                <div className="mt">
                    {!isLoaded ? (
                        <LoadingData />
                    ) : (
                        <ProfilesList profiles={profiles} currentUser={user} />
                    )}
                </div>

                <div className="text-center mt">
                    <Button
                        theme="gray"
                        onClick={modal.hide}
                        icon="far fa-times">
                        {i18n`Cancel`}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default connect((state: ReduxState) => ({
    profiles: state.profiles.data,
    isLoaded: state.profiles.isLoaded,
}))(ProfilesModal);
