import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'Language';
import { storage } from 'Core';

export default class RememberLoginMethod extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static propTypes = {
        location: PropTypes.object,
    };

    state = {
        remember: !!storage.get('penneo-remember-method'),
    };

    checkHandler = (event) => {
        if (event.target.checked) {
            storage.set('penneo-remember-method', true);
        } else {
            storage.clear('penneo-remember-method');
            storage.clear('penneo-remember-method-active');
        }

        this.setState({
            remember: event.target.checked,
        });
    };

    render() {
        let { remember } = this.state;

        return (
            <div className="remember-login">
                <label className="custom-checkbox">
                    {translate('Remember my login method')}&nbsp;
                    <input
                        type="checkbox"
                        checked={remember}
                        onChange={this.checkHandler}
                    />
                    <span className="check">
                        <i className="fas fa-check" />
                    </span>
                </label>
            </div>
        );
    }
}
