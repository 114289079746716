import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';

// child components
import CasefileInformation from './CasefileInformation.jsx';
import DocumentList from './DocumentList';
import SignerInformation from './SignerInformation.jsx';
// action creators
import CasefileActions from '../../actions/CasefilesActionCreators';
import SignerActions from '../../actions/SignerActionCreators';
import DocumentActions from '../../actions/DocumentActionCreators';
import CasefileStore from '../../stores/CasefileStore';
import CasefileAdvancedOptions from './CasefileAdvancedOptions';
import Button from 'Common/components/Button';

export default class CasefileCreator extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static propTypes = {
        casefile: PropTypes.object,
        folders: PropTypes.array,
        defaultFolder: PropTypes.number,
        casefileTypes: PropTypes.array,
        selectedFolderId: PropTypes.number,
        documents: PropTypes.array,
        signers: PropTypes.array,
        signerRules: PropTypes.object,
        recipients: PropTypes.array,
        selectedDocumentTypes: PropTypes.array,
        availableSignerTypes: PropTypes.array,
        casefileInputErrors: PropTypes.object,
        documentInputErrors: PropTypes.object,
        signerInputErrors: PropTypes.object,
        availableDocumentTypes: PropTypes.array,
        visibilityMode: PropTypes.string,
        error: PropTypes.object,
        action: PropTypes.string,
        resetData: PropTypes.func,
        clearing: PropTypes.bool,
        applyingPayload: PropTypes.bool,
    };

    changeSelectedDocumentTypes = (selectedDocumentTypes) => {
        DocumentActions.setSelectedDocumentTypes(selectedDocumentTypes);
    };

    changeCasefile = (casefile) => {
        CasefileActions.updateCasefile(casefile);
    };

    changeFolder = (selectedFolderId) => {
        CasefileActions.changeFolder(selectedFolderId);
    };

    changeSigners = (signers) => {
        SignerActions.setSigners(signers);
    };

    changeDocuments = (documents) => {
        DocumentActions.setDocuments(documents);
    };

    changeVisibilityMode = (visibilityMode) => {
        CasefileActions.setVisibilityMode(visibilityMode);
    };

    _onNextClick = () => {
        CasefileActions.validateInputs();
        let viewValidation = CasefileStore.validateFirstView();

        if (viewValidation) {
            this._onCasefileValid();
        }
    };

    // *************************************************************************
    // CASEFILE CREATION
    // *************************************************************************

    _onCasefileValid = () => {
        let { router } = this.context;

        router.push({
            name: 'casefile-summary',
        });
    };

    changeCaseTypeId = (typeId) => {
        CasefileActions.setCaseTypeId(typeId, true); // force refresh of document type values

        // Reset roles and document types after changing casefile type via URI.
        SignerActions.resetSignerRoles();
    };

    render() {
        let {
            folders,
            casefileTypes,
            casefile,
            signers,
            signerRules,
            documents,
            selectedFolderId,
            casefileInputErrors,
            signerInputErrors,
            documentInputErrors,
            selectedDocumentTypes,
            availableSignerTypes,
            availableDocumentTypes,
            visibilityMode,
            recipients,
            defaultFolder,
            action,
            error,
            clearing,
            applyingPayload,
        } = this.props;

        return (
            <div className="casefile-creator">
                <div className="casefile-creation-wrapper">
                    <div className="casefile-form-input">
                        <div className="horizontal-layout">
                            <div className="casefile-component-wrapper">
                                <CasefileInformation
                                    action={action}
                                    error={error}
                                    casefile={casefile}
                                    folders={folders}
                                    defaultFolder={defaultFolder}
                                    documents={documents}
                                    changeCasefile={this.changeCasefile}
                                    changeDocuments={this.changeDocuments}
                                    changeCaseType={this.changeCaseType}
                                    changeVisibilityMode={
                                        this.changeVisibilityMode
                                    }
                                    visibilityMode={visibilityMode}
                                    // Recipients
                                    recipients={recipients}
                                    inputErrors={casefileInputErrors}
                                    // Casefile Type
                                    casefileTypes={casefileTypes}
                                    changeCaseTypeId={this.changeCaseTypeId}
                                    // Folders
                                    selectedFolderId={selectedFolderId}
                                    changeFolder={this.changeFolder}
                                    applyingPayload={applyingPayload}
                                    clearing={clearing}
                                />
                            </div>
                            <div className="casefile-component-wrapper">
                                <DocumentList
                                    files={documents}
                                    availableDocumentTypes={
                                        availableDocumentTypes
                                    }
                                    selectedDocumentTypes={
                                        selectedDocumentTypes
                                    }
                                    changeSelectedDocumentTypes={
                                        this.changeSelectedDocumentTypes
                                    }
                                    inputErrors={documentInputErrors}
                                    changeDocuments={this.changeDocuments}
                                />
                            </div>
                        </div>
                        <div className="signer-component-wrapper">
                            <SignerInformation
                                signers={signers}
                                signerRules={signerRules}
                                isCasefileSensitive={casefile.sensitiveData}
                                selectedDocumentTypes={selectedDocumentTypes}
                                availableSignerTypes={availableSignerTypes}
                                inputErrors={signerInputErrors}
                                changeSigners={this.changeSigners}
                            />
                        </div>
                    </div>
                    <div className="horizontal-layout">
                        <div className="pull-right pr"></div>
                        <div className="pull-right pr">
                            <CasefileAdvancedOptions
                                resetData={this.props.resetData}
                            />
                            <Button
                                id="next"
                                theme="blue"
                                onClick={this._onNextClick}>
                                {i18n('Next')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
