import React from 'react';
import { i18n } from 'Language';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import Image from 'Common/components/Image';
import { Link } from 'react-router';

import './modal-create-new.scss';
import Button from 'Common/components/Button';

type Props = {
    id: string;
    title: string;
    link: any | string;
    subtitle: string;
    image: string;
    onClick: Function;
    comingSoon?: boolean;
    inactive?: boolean;
};

type State = {
    active: boolean;
};

export class CreateCTAModal extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    state = {
        active: false,
    };

    handleClick = () => {
        this.props.onClick(this.props.id);
    };

    render() {
        const { active } = this.state;
        const { title, subtitle, link, image } = this.props;

        if (this.props.inactive) {
            return null;
        }

        if (this.props.comingSoon) {
            return (
                <div className="modal-create-new-option pointer-events-none">
                    <div>
                        <Image height="70" src={image} />
                        <h3>{i18n(title)}</h3>
                        <p>{i18n(subtitle)}</p>

                        <i>{i18n`Coming soon`}</i>
                    </div>
                </div>
            );
        }

        return (
            <Link
                to={link}
                onMouseEnter={() => this.setState({ active: true })}
                onMouseLeave={() => this.setState({ active: false })}
                className="modal-create-new-option"
                onClick={this.handleClick}>
                <div className="modal-create-new-option-content">
                    <Image height="70" src={image} />
                    <h3>{i18n(title)}</h3>
                    <p>{i18n(subtitle)}</p>
                </div>
                <Fade when={active} duration={150} distance="15px" bottom>
                    <div className="mx-auto">
                        <Button
                            theme="blue"
                            variant="outline"
                            icon="far fa-plus-circle"
                            renderIconLeft={true}>
                            {i18n`Create`}
                        </Button>
                    </div>
                </Fade>
            </Link>
        );
    }
}

export default CreateCTAModal;
