import React, { useEffect, useRef } from 'react';
import { storage } from 'Core';
import Constants, { Integrations } from '../../../Constants';

/**
 * This unusual React component is responsible for providing Silverfin
 * integration with Penneo JWT. Its needed until we have real OAuth2 in Penneo.
 *
 * Once reached, this component renders invisible <form> with only
 * one hidden field containing access token and almost immediately submits it.
 * This way, JWT is delivered to the integration in the request body.
 * After that its integration responsibiliy to handle proper redirection after
 * this operation.
 * @constructor
 */
const CustomerIntegrationsPenneoAuth: React.FunctionComponent = () => {
    const penneoAccessToken = storage.get(Constants.PENNEO_TOKEN_KEY);
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        formRef.current?.submit();
    });

    if (!penneoAccessToken) {
        return null;
    }

    const { SET_TOKEN_PENNEO_URL } = Integrations.Silverfin.getUrls();
    const submitUrl = `${SET_TOKEN_PENNEO_URL}${window.location.search}`;

    return (
        <form method="POST" action={submitUrl} ref={formRef}>
            <input
                type="hidden"
                name="penneoAccessToken"
                value={penneoAccessToken}
            />
        </form>
    );
};

export default CustomerIntegrationsPenneoAuth;
