import React from 'react';
import { i18n } from 'Language';
import ClickDropdown from 'Common/components/Common/ClickDropdown';
import Button from 'Common/components/Button';
import { KYCListItem } from 'KYC/redux/types';
import { Link } from 'react-router';
import { showKYCDeleteModal } from 'KYC/components/KYCDeleteModal';
import Analytics from 'Common/Analytics';

type Props = {
    kyc: KYCListItem;
    onDelete: Function;
};

const trackNavigation = (item: KYCListItem) => {
    Analytics.track('kyc - view details', {
        id: item.id,
        caseFileId: item.caseFileId,
        createdAt: item.createdAt,
        filledAt: item.filledAt,
    });
};

const KYCListItemActions: React.FC<Props> = (props) => {
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <ClickDropdown
                placement="bottom left"
                offset={-60}
                content={
                    <div className="click-dropdown-list">
                        <ul>
                            <Link
                                to={{
                                    name: 'kyc-item-details',
                                    params: {
                                        id: props.kyc.id,
                                    },
                                }}
                                onClick={trackNavigation}>
                                <li>
                                    &nbsp;
                                    <i className="far fa-arrow-right" />
                                    &nbsp;
                                    {i18n('View Details')}
                                </li>
                            </Link>

                            <li
                                onClick={showKYCDeleteModal.bind(
                                    null,
                                    props.kyc,
                                    props.onDelete,
                                    'KYC list'
                                )}>
                                &nbsp;
                                <i className="far fa-times" />
                                &nbsp;
                                {i18n('Delete KYC')}
                            </li>
                        </ul>
                    </div>
                }>
                <Button
                    style={{
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        paddingRight: '8px',
                        paddingLeft: '8px',
                    }}
                    variant="solid"
                    theme="blue">
                    <i className="fas fa-ellipsis-h"></i>
                </Button>
            </ClickDropdown>
        </div>
    );
};

export default KYCListItemActions;
