import React from 'react';
import { AppDispatch } from 'Store';
import { extendExpiration } from './redux/actions';
import { connect } from 'react-redux';
import DateInput from 'Common/components/DateInput';
import { notify } from 'react-notify-toast';
import { i18n } from 'Language';
import Button from 'Common/components/Button';
import './extend-deadline.scss';

type Props = {
    dispatch: AppDispatch;
    onExtension: (value: any) => Promise<void>;
};

type State = {
    expireAt: number | null;
};

export class ExtendDeadline extends React.Component<Props, State> {
    state = {
        expireAt: null,
    };

    handleOnChange = (expireAt: number) => this.setState({ expireAt });

    extendDeadline = async () => {
        const { dispatch, onExtension } = this.props;
        const { expireAt } = this.state;

        try {
            await dispatch(extendExpiration(expireAt));
            await onExtension(expireAt);
            notify.show(
                <span>
                    {i18n('Case file expiration date updated successfully')}
                    &nbsp;<i className="far fa-check"></i>
                </span>,
                'success',
                5000
            );
        } catch (error) {
            notify.show(
                <span>
                    {i18n(
                        'Could not update the expiration date of this case file'
                    )}
                    &nbsp;<i className="far fa-times"></i>
                </span>,
                'error',
                5000
            );
        }
    };

    render() {
        const { expireAt } = this.state;

        return (
            <div className="extend-deadline">
                <h4 className="casefile-details-section-header">
                    {i18n`Extend expiry date`}
                </h4>

                <DateInput
                    className="extend-deadline-input"
                    label={i18n`Case file will expire in:`}
                    placeholder={i18n`Pick a date`}
                    onChange={this.handleOnChange}
                />

                <Button
                    theme="blue"
                    variant="outline"
                    className="extend-deadline-button ml"
                    disabled={!expireAt}
                    onClick={this.extendDeadline}>
                    {i18n('Save')}
                </Button>
            </div>
        );
    }
}

export default connect()(ExtendDeadline);
