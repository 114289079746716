import React from 'react';
import type { ProfileItem } from 'Auth/redux/profiles/types';
import { i18n } from 'Language';
import './user-avatar.scss';

export type UserProfileWithFullname = {
    fullName: string;
    email?: string;
    customerIds?: number[];
    userIsConnectedToCustomer?: boolean;
} & ProfileItem;

type Props = {
    user: UserProfileWithFullname;
    name?: string | boolean;
    tagline?: string | boolean;
    badge: boolean;
    createdAt?: boolean | number;
};

export default class UserAvatar extends React.Component<Props, {}> {
    getInitials = (fullName) => {
        if (!fullName) {
            return false;
        }

        let names = fullName.split(' ');

        let initials = '';

        if (names.length === 1) {
            initials += names[0][0];
        }

        if (names.length > 1) {
            initials += names[0][0] + names[1][0];
        }

        return initials;
    };

    renderAvatarCircle() {
        const { user, badge } = this.props;

        return (
            <div className="user-avatar" aria-hidden={true}>
                <div className="avatar-circle av-blue">
                    <span className="avatar-circle-text">
                        {this.getInitials(user.fullName)}
                    </span>
                    {badge && this.renderBadge()}
                </div>
            </div>
        );
    }

    renderBadge() {
        const { user } = this.props;

        const isPersonalAccount = !user.userIsConnectedToCustomer;

        if (isPersonalAccount) {
            return (
                <div className="user-avatar-badge grey">
                    <i className="fas fa-user" />
                </div>
            );
        }

        return (
            <div className="user-avatar-badge grey">
                <i className="fas fa-building" />
            </div>
        );
    }

    renderUserAvatar() {
        const { user, name, tagline, createdAt } = this.props;

        return (
            <div className="user-avatar">
                <div className="user-avatar-leading">
                    {this.renderAvatarCircle()}
                </div>

                <div className="user-avatar-details">
                    {name && (
                        <div className="user-avatar-details-name">
                            {name === true ? user.fullName : name}
                        </div>
                    )}
                    {tagline && (
                        <div className="user-avatar-details-email">
                            {tagline === true ? user.email : tagline}
                        </div>
                    )}
                    {createdAt && (
                        <div className="user-avatar-details-date">
                            {i18n`Created ${
                                createdAt === true ? user.createdAt : createdAt
                            }`}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const { name, tagline } = this.props;

        if (!!name || !!tagline) {
            return this.renderUserAvatar();
        }

        return this.renderAvatarCircle();
    }
}
