import createReducer from 'create-reducer';
import { ActionTypes } from './action-types';
import { ContactsState } from './types';
import produce from 'immer';

export const contactsInitialState: ContactsState = {
    data: [],
    pagination: {
        sort: 'name',
        fullSearch: '',
        per_page: 10,
        count: 0,
        page: 1,
    },
    viewState: {
        contactDraft: null,
    },
    isLoaded: false,
    isFetching: false,
    error: null,
};

export const contactsReducer = createReducer<ContactsState>(
    contactsInitialState,
    {
        [ActionTypes.CONTACTS_FETCH_REQUEST]: (state) =>
            produce(state, (draftState) => {
                draftState.isFetching = true;
            }),
        [ActionTypes.CONTACTS_FETCH_SUCCESS]: (state, { items, itemCount }) =>
            produce(state, (draftState) => {
                draftState.data = items;
                draftState.pagination.count = itemCount;
                draftState.isFetching = false;
                draftState.isLoaded = true;
            }),
        [ActionTypes.CONTACTS_FETCH_FAILURE]: (state, error) =>
            produce(state, (draftState) => {
                draftState.isFetching = false;
                draftState.isLoaded = true;
                draftState.error = error;
            }),
        [ActionTypes.CONTACT_LIST_PAGINATION_CHANGE]: (
            state,
            { property, value }
        ) =>
            produce(state, (draftState) => {
                draftState.pagination[property] = value;

                /**
                 * We must reset the page we are in when we perform a search.
                 * Otherwise, we might end up on an scenario that we will show a page
                 * that doesn't exist, meaning we will get an empty list
                 * even if the search is valid
                 */
                if (property === 'fullSearch') {
                    draftState.pagination.page = 1;
                }
            }),
        [ActionTypes.CONTACT_LIST_TOGGLE_ROW_EDIT]: (state, { contactId }) =>
            produce(state, (draftState) => {
                const contact = state.data.find((c) => c.id === contactId);

                draftState.viewState.contactDraft = contact || null;
            }),
        [ActionTypes.CONTACT_LIST_DISABLE_ROW_EDIT]: (state) =>
            produce(state, (draftState) => {
                draftState.viewState.contactDraft = null;
            }),
        [ActionTypes.CONTACT_DRAFT_UPDATED]: (state, { property, value }) =>
            produce(state, (draftState) => {
                const { contactDraft } = draftState.viewState;

                if (contactDraft) {
                    contactDraft[property] = value;
                }
            }),
        [ActionTypes.CONTACT_UPDATE_SUCCESS]: (state, contact) =>
            produce(state, (draftState) => {
                draftState.viewState.contactDraft = null;

                const index = draftState.data.findIndex(
                    (c) => c.id === contact.id
                );

                draftState.data[index] = contact;
            }),
    }
);
