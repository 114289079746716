import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';

export default class WidgetBody extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        return (
            <div className={classnames('widget-body', this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}
