import React from 'react';
import classnames from 'classnames';
import './message.scss';

type Props = {
    children: string | JSX.Element;
    type?: 'error' | 'success' | 'info' | 'warning';
    className?: string;
};

const getIcon = (type: string) => {
    switch (type) {
        case 'error':
            return <i className="penneo-message-icon far fa-times-circle" />;
        case 'success':
            return <i className="penneo-message-icon far fa-check-circle" />;
        case 'info':
            return <i className="penneo-message-icon far fa-info-circle" />;
        case 'warning':
            return (
                <i className="penneo-message-icon far fa-exclamation-circle" />
            );
    }
};

const Message = (props: Props) => (
    <div className={classnames('penneo-message', props.type, props.className)}>
        {getIcon(props.type || 'none')}
        {props.children}
    </div>
);

export default Message;
