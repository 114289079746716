import { Dispatcher, BaseStore } from 'Core';
import lodash from 'lodash';
import assign from 'object-assign';

import CasefileStore from 'Casefiles/stores/CasefileStore';
import DocumentStore from 'Casefiles/stores/DocumentStore';
import SignerStore from 'Casefiles/stores/SignerStore';

// Data Storage
let _store: any = {};

// current route (by name)
_store.active = null;

_store.routes = [
    {
        name: 'casefile2-details',
        icon: 'fas fa-list',
        title: 'Details',
        done: false,
        active: false,
        error: false,
        errorSeen: false,
        validation: [],
        validate: () => [CasefileStore.getValidation()],
    },
    {
        name: 'casefile-documents',
        icon: 'far fa-file-alt',
        title: 'Documents',
        errorSeen: false,
        done: false,
        active: false,
        error: false,
        validation: [],
        validate: () => [
            DocumentStore.getValidation().setup,
            DocumentStore.getValidation().documents,
            DocumentStore.getValidation().documentTypes,
        ],
    },
    {
        name: 'casefile-recipients',
        icon: 'far fa-users',
        title: 'Recipients',
        errorSeen: false,
        done: false,
        active: false,
        error: false,
        validation: [],
        validate: () => [
            SignerStore.getValidation().setup,
            SignerStore.getValidation().signers,
            SignerStore.getValidation().signerTypes,
            SignerStore.getValidation().ccRecipients,
        ],
    },
    {
        name: 'casefile-review',
        icon: 'far fa-paper-plane',
        title: 'Review & send',
        errorSeen: false,
        done: false,
        active: false,
        error: false,
        validation: [],
        validate: () => [],
    },
];

let findRouteIndex = (routeName) => {
    return lodash.findIndex(_store.routes, { name: routeName });
};

let trackNavigation = (routeName) => {
    _store.activeRoute = routeName;
};

let getRouteState = () => {
    let active = findRouteIndex(_store.activeRoute);

    _store.routes.forEach((r, i) => {
        r.active = i === active;
        r.done = i < active;

        // Evaluate validation
        r.validation = r.validate();

        if (!r.validation) {
            // Skip error validation if stores haven't been initialized
            return false;
        }

        r.error = r.validation.filter((v) => v.valid === false).length > 0;

        // Track if an error has been notified for the route.
        // (Avoid reversing state)
        if (r.errorSeen === false) {
            r.errorSeen = r.error && r.done;
        }
    });

    return _store.routes;
};

// Flux Store Creation
const CasefileNavigationStore = assign({}, BaseStore, {
    getRoutes() {
        return getRouteState();
    },

    getCurrentRoute() {
        return _store.activeRoute;
    },

    getRoute(routeName) {
        return lodash.find(_store.routes, routeName);
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case 'CASEFILE_NAVIGATION_TRACK':
            case 'CASEFILE_NAVIGATION_INITIAL_ROUTE':
                trackNavigation(action.routeName);
                break;
            default:
                return false;
        }

        CasefileNavigationStore.emitChange();
    }),
});

export default CasefileNavigationStore;
