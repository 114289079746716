import React, { useContext, useEffect, useRef, useState } from 'react';
import { ToastContext } from '../ToastContext';
import ToastContainer from './ToastContainer';

const ToastList = () => {
    const toastContext = useContext(ToastContext);

    if (!toastContext) {
        throw new Error('ToastList must be used within a ToastProvider');
    }

    const { toasts, removeToast } = toastContext;

    const [positions, setPositions] = useState({});

    const refs = useRef({});

    useEffect(() => {
        const newPositions = {};
        let cumulativeHeight = 0;

        toasts.forEach((toast) => {
            const toastHeight = refs.current[toast.id]?.offsetHeight || 0;

            newPositions[toast.id] = cumulativeHeight;
            cumulativeHeight += toastHeight + 10;
        });

        setPositions(newPositions);
    }, [toasts]);

    return (
        <div className="fixed top-5 right-5 z-[9999]">
            {toasts.map((toast) => (
                <div
                    key={toast.id}
                    ref={(el) => (refs.current[toast.id] = el)}
                    style={{
                        position: 'absolute',
                        top: `${positions[toast.id] || 0}px`,
                        right: '0',
                        transition: 'top 0.3s ease-in-out',
                    }}>
                    <ToastContainer toast={toast} onRemove={removeToast} />
                </div>
            ))}
        </div>
    );
};

export default ToastList;
