import { createComponent } from '@lit/react';
import { InlineMessage } from '@penneo/vega-ui';
import React from 'react';

const UIInlineMessage = createComponent({
    tagName: 'vega-inline-message',
    elementClass: InlineMessage,
    react: React,
});

export default UIInlineMessage;
