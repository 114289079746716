import PropTypes from 'prop-types';
import React from 'react';
import WorkflowDetailStore from '../../stores/WorkflowDetailStore';
import WorkflowDetailActions from '../../actions/WorkflowDetailActionCreators';
import { translate } from 'Language';
import { Link } from 'react-router';
import Button from 'Common/components/Button';

export default class WorkflowTemplateDetails extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static propTypes = {
        params: PropTypes.object,
    };

    state = {
        workflowTemplateDetails: [],
    };

    componentDidMount() {
        WorkflowDetailStore.addChangeListener(this.onChange);
        this.loadData();
    }

    componentWillUnmount() {
        WorkflowDetailStore.removeChangeListener(this.onChange);
    }

    loadData = () => {
        WorkflowDetailActions.loadById(this.props.params.workflowId);
        // // only public_form workflows should be shown in the list.
        // const _stateMachine = "prefilled_form";

        // // @note: this naming pattern doesn't match the backend convention, thus the linter
        // // is disabled for the camelcase rule on the state_machine key.
        // let filter = {
        //     state_machine: _stateMachine /* eslint camelcase: 0 */
        // };

        // WorkflowStore.loadWorkflowPrototypes(filter);
        // FormStore.loadFormPrototypes();
    };

    onChange = () => {
        this.setState({
            workflowTemplateDetails: WorkflowDetailStore.getAll(),
        });
    };

    editFormTemplate = () => {
        let { router } = this.context;
        let { workflowId } = this.props.params;
        let { workflowTemplateDetails } = this.state;
        let workflowDetails = workflowTemplateDetails[workflowId];
        let formId = workflowDetails.workflow.userData.initial.form.prototypeId;

        router.push({
            name: 'form-editor',
            params: {
                formId: formId,
                workflowId: workflowId,
            },
        });
    };

    render() {
        let { workflowTemplateDetails } = this.state;

        let { workflowId } = this.props.params;
        let workflowDetails = workflowTemplateDetails[workflowId];

        if (!workflowDetails) {
            return false;
        }

        let formId = workflowDetails.workflow.userData.initial.form.prototypeId;

        return (
            <div className="white-container">
                <h3 className="title">
                    {translate('Workflow template details')}
                </h3>
                <div className="content">
                    <Link to="form-templates">
                        <i className="fa fa-list"></i>
                        &nbsp;{translate('Go back to the list')}
                    </Link>

                    <div className="panel panel-default">
                        <div className="panel-body">
                            <ul>
                                <li>
                                    <h3>{translate('Title:')}</h3>&nbsp;
                                    {workflowDetails.workflow.title}
                                </li>
                                <li>
                                    <h3>{translate('Type of workflow:')}</h3>
                                    &nbsp;
                                    {workflowDetails.workflow.stateMachine ===
                                    'simple_form'
                                        ? translate('Mass Send')
                                        : translate('Public link')}
                                </li>
                                <li>
                                    <h3>{translate('Form template ID:')}</h3>
                                    &nbsp;{formId}
                                </li>
                                <li>
                                    <h3>{translate('Created at:')}</h3>&nbsp;
                                    {workflowDetails.workflow.createdAt}
                                </li>
                                <li>
                                    <h3>{translate('Last update:')}</h3>&nbsp;
                                    {workflowDetails.workflow.updatedAt}
                                </li>
                            </ul>
                            <br />
                            <br />
                            <div className="pull-right-flex">
                                <Button
                                    theme="green"
                                    onClick={this.editFormTemplate}>
                                    {translate('Edit')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
