import React from 'react';
import DocumentActions from '../../actions/DocumentActionCreators';
import lodash from 'lodash';

import { TextInput, RadioButton } from 'Common/components';
import { modal } from 'Common/components/Common/Modal';
import { i18n } from 'Language';
import Button from 'Common/components/Button';

import { DocumentEntity, DocumentType } from 'types/Document';

type Props = {
    index: number;
    documents: DocumentEntity[];
    availableDocumentTypes: DocumentType[];
};

type State = {
    activeIndex: number;
    promptDelete: boolean;
    document: any;
};

export default class DocumentModalEdit extends React.Component<Props, State> {
    getActiveDocument = () => {
        let { index, documents } = this.props;

        return lodash.cloneDeep(documents[index]);
    };

    onTitleChange = (name) => {
        let doc = this.state.document;

        doc.name = name;
        this.setState({ document: doc });
    };

    onTypeChange = (documentTypeId) => {
        let doc = this.state.document;

        doc.documentTypeId = documentTypeId;
        this.setState({ document: doc });
    };

    onSave = () => {
        DocumentActions.editDocument(this.props.index, this.state.document);
        modal.hide();
    };

    onDelete = () => {
        DocumentActions.removeDocument(
            this.props.documents[this.props.index].id
        );
        modal.hide();
    };

    renderDelete = () => {
        let activeDocument = this.state.document;

        return (
            <div className="document-modal-add casefile-modal-v2 form-v2">
                <h3 className="casefile-modal-v2-title">
                    {i18n`Delete Document`}
                </h3>

                <label className="text-center">{i18n`You are about to delete`}</label>

                <div className="mt box-gray">
                    <i className="far fa-file-alt" />
                    &nbsp;{activeDocument.name}
                </div>

                <label className="text-center mt">{i18n`Are you sure?`}</label>

                <div className="mt">
                    <Button
                        theme="gray"
                        variant="outline"
                        onClick={this.cancelDelete}>
                        {i18n`No, cancel`}
                    </Button>

                    <Button
                        theme="red"
                        className="pull-right"
                        onClick={this.onDelete}
                        icon="far fa-times-circle">
                        {i18n`Yes, delete`}
                    </Button>
                </div>
            </div>
        );
    };

    promptDelete = () => {
        this.setState({ promptDelete: true });
    };

    cancelDelete = () => {
        this.setState({ promptDelete: false });
    };

    state: State = {
        activeIndex: 0,
        promptDelete: false,
        document: lodash.cloneDeep(this.getActiveDocument()),
    };

    renderDocumentTypes = (
        documentTypes: DocumentType[],
        activeDocument: {
            name: string;
            filename: string;
            file: File;
            caseFileId: number | null;
            documentTypeId: number;
            _id?: string | undefined;
        }
    ) => {
        const indexOfFirstAttachment = documentTypes.findIndex(
            (documentType) => !documentType.signerTypes.length
        );

        return documentTypes.map((t, index) => (
            <div key={index}>
                {index === 0 && (
                    <p>
                        <b>{i18n`Signable document`}</b>
                    </p>
                )}
                {index === indexOfFirstAttachment && (
                    <p>
                        <b>{i18n`Attachment`}</b>
                    </p>
                )}
                <RadioButton
                    checked={t.id === activeDocument.documentTypeId}
                    onChange={(value) =>
                        value ? this.onTypeChange(t.id) : null
                    }
                    name="document-type"
                    label={t.name}
                    data-document-type-id={t.id}
                />
            </div>
        ));
    };

    render() {
        let documentTypes = this.props.availableDocumentTypes;
        let activeDocument = this.state.document;

        if (this.state.promptDelete) {
            return this.renderDelete();
        }

        return (
            <div className="document-modal-add casefile-modal-v2 form-v2">
                <h3 className="casefile-modal-v2-title">
                    {i18n`Edit document`}

                    <div className="close" onClick={modal.hide}>
                        <div className="far fa-times"></div>
                    </div>
                </h3>

                <div className="mt">
                    <TextInput
                        label={i18n`Edit title (optional)`}
                        className="match-parent"
                        value={activeDocument.name}
                        onChange={this.onTitleChange}
                    />
                </div>

                <div className="document-type-container">
                    <label className="document-type-header">{i18n`Which type of document is this?`}</label>
                    <div className="document-type-list">
                        {this.renderDocumentTypes(
                            documentTypes,
                            activeDocument
                        )}
                    </div>
                </div>

                <div className="mt">
                    <Button
                        theme="gray"
                        variant="outline"
                        onClick={this.promptDelete}>
                        {i18n`Delete`}
                    </Button>

                    <Button
                        theme="green"
                        className="pull-right"
                        onClick={this.onSave}
                        icon="far fa-check-circle">
                        {i18n`Save changes`}
                    </Button>
                </div>
            </div>
        );
    }
}
