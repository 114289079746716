import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { i18n } from 'Language';

// Redux
import { connect } from 'react-redux';
import { ReduxState } from 'Store';

// Components
import FullScreenSendingError from 'Common/components/FullScreenSendingError';
import Button from 'Common/components/Button';
import FullScreen from 'Common/components/FullScreen';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';

export type Props = {
    onRetry: Function;
    onBack: Function;
    showSuccess?: boolean;
    sent?: boolean;
    error?: any;
};

const KYCSendingState: React.FunctionComponent<Props> = (props, context) => {
    const { error, sent, showSuccess, onRetry, onBack } = props;

    return error ? (
        <FullScreenSendingError
            error={error}
            onRetry={onRetry}
            onBack={onBack}
        />
    ) : (
        <FullScreen>{renderContent()}</FullScreen>
    );

    function renderContent() {
        const isKYCListEnabled = LaunchDarkly.variation(Flags.KYC_LIST_ENABLED);
        //TODO: Change the english translation to: `Now sit back, and enjoy the time you just saved`

        if (sent && showSuccess) {
            return (
                <div>
                    <h1>
                        <i className="far fa-check-circle text-penneo-green"></i>
                        <br />
                        {i18n`All done!`}
                    </h1>

                    <h2>{i18n`Identity validation requests will be delivered by email shortly`}</h2>
                    <p>{i18n`Now sit back, and enjoy the time you saved`}</p>

                    {isKYCListEnabled ? (
                        <Link
                            to={{
                                name: 'kyc-list',
                                params: { status: 'pending' },
                            }}>
                            <Button
                                theme="gray"
                                className="ml"
                                icon="far fa-external-link">
                                {i18n`Manage identity validations`}
                            </Button>
                        </Link>
                    ) : (
                        <Link to={{ name: 'archive' }}>
                            <Button
                                theme="gray"
                                className="ml"
                                icon="far fa-external-link">
                                {i18n`Go to Archive`}
                            </Button>
                        </Link>
                    )}
                </div>
            );
        }

        return (
            <div>
                <h1>
                    <i className="far fa-sync fa-spin"></i>
                    <br />
                    {i18n`Packing things up!`}
                </h1>
            </div>
        );
    }
};

KYCSendingState.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect((state: ReduxState) => ({
    sent: state.kyc.__states.sent,
    error: state.kyc.__states.error,
}))(KYCSendingState);
