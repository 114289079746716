import PropTypes from 'prop-types';
import React from 'react';
import ErrorMessage from '../errors/InputErrorMessage';

export default class RecipientRow extends React.Component {
    static propTypes = {
        recipient: PropTypes.object,
        index: PropTypes.number,
        remove: PropTypes.func,
        update: PropTypes.func,
        inputErrors: PropTypes.object,
    };

    render() {
        let { recipient, index, update, remove, inputErrors } = this.props;

        return (
            <tr>
                <td>
                    <input
                        type="text"
                        name="name"
                        value={recipient.name || ''}
                        className={
                            inputErrors['name' + index] ? 'input-error' : null
                        }
                        onChange={update.bind(null, index)}
                    />
                    {inputErrors['name' + index] ? (
                        <ErrorMessage message={inputErrors['name' + index]} />
                    ) : null}
                </td>
                <td>
                    <input
                        type="text"
                        name="email"
                        value={recipient.email || ''}
                        className={
                            inputErrors['email' + index] ? 'input-error' : null
                        }
                        onChange={update.bind(null, index)}
                    />
                    {inputErrors['email' + index] ? (
                        <ErrorMessage message={inputErrors['email' + index]} />
                    ) : null}
                </td>
                <td>
                    <i
                        className="fa fa-times fa-lg"
                        onClick={remove.bind(null, index)}></i>
                </td>
            </tr>
        );
    }
}
