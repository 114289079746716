import React from 'react';
import { i18n, TranslationStore } from 'Language';
import analytics from 'Common/Analytics';
import { Languages } from 'Language/Constants';
import { EID_METHODS, EIDMethod } from 'EID';
import LoadingData from 'Common/components/Loaders/LoadingData';
import OpenIDCertificate from 'OpenID/components/OpenIDCertificate';
import LibLoader from 'Core/LibLoader';
import Button from 'Common/components/Button';
import { ServiceIDs, Intent } from 'OpenID/Constants';
import { ERROR_OPENID_009, ERROR_OPENID_016 } from 'OpenID/Errors';
import {
    getOpenIdState,
    clearOpenIdState,
    setOpenIdState,
} from 'OpenID/utils/openIdState';
import {
    startSigning,
    finalizeSigning,
    cacheOpenIdIntent,
    initSign,
    init,
    resetState,
} from 'OpenID/redux/actions';
import PostSignStorageOptions from 'Common/components/StorageOptions/PostSignStorageOptions';
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import OpenIDErrorDetails from '../OpenIDError/OpenIDErrorDetails';
import {
    SignText,
    OpenIdLocalStorageState,
    SignatureData,
} from 'OpenID/redux/types';
import OpenIDSignText from '../OpenIDSignText';
import './openid-sign.scss';
import { isMitIDInfoProtected } from '../../services/mitid';
import { MITID_PERSONAL_NO_CPR_STEP_DENMARK } from '../../../EID/Constants';

export type Props = {
    dispatch: AppDispatch;
    challengeKey: string;
    languageCode: Languages;
    signText: SignText;
    serviceId: ServiceIDs;
    openIdState: OpenIdLocalStorageState;
    displayStorageOptions: boolean;
    signatureData: any;
    isLoaded: boolean;
    isFetching: boolean;
    certificate: any;
    signingError: any;
    // Used for displaying title of modal in V1 signing page when signing modal is
    // reopened automatically after oauth redirection
    legacyServiceName: string;
    error: any;
    encryptedNIN?: string;
};

type State = {
    /**
     * Whether the browser can use the crypto library that is needed
     * for signing the documents
     */
    canCrypto: boolean;
};

export class OpenIDSign extends React.Component<Props, State> {
    script = new LibLoader('lib-crypto.js');

    state: State = {
        canCrypto: true,
    };

    componentDidMount() {
        return this.appendCryptoScript();
    }

    // Adds crypto script tag to DOM
    async appendCryptoScript() {
        if (this.isCryptoSupported()) {
            await this.script.load();
            this.loadData();
        } else {
            const { openIdState } = this.props;

            analytics.track('OpenID - unsupported browser', {
                method: openIdState.serviceId,
            });

            this.setState({ canCrypto: false });
        }
    }

    // Remove helper script tag from DOM
    removeCryptoScript() {
        if (!this.script.isLoaded) {
            return false;
        }

        this.script.unload();
    }

    isCryptoSupported() {
        // @ts-ignore
        if (window.crypto?.getRandomValues && window.crypto.subtle) {
            return true;
        }

        return false;
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(resetState());
        this.removeCryptoScript();
    }

    loadData() {
        const { dispatch, challengeKey, languageCode } = this.props;

        const signTextLanguage = TranslationStore.getLanguage() || languageCode;

        const openIdState = getOpenIdState();

        // If OpenID state doesn't exist or the openid request hasn't been processed or
        // there is no openId token, start authentication process with the third party service.
        if (!openIdState || !openIdState.processed) {
            // Reset the OpenID state before starting.
            return this.authenticate();
        }

        // Cache OpenID state in redux state
        dispatch(cacheOpenIdIntent(openIdState));
        // Clear OpenID state from localStorage to avoid triggering the signing
        // flow again if user refreshes the page
        clearOpenIdState();
        dispatch(initSign(challengeKey, signTextLanguage));
    }

    retry = () => {
        const { dispatch, error } = this.props;
        const isValidationSessionExpired =
            error.code === ERROR_OPENID_016.code ||
            error.code === ERROR_OPENID_009.code;

        dispatch(resetState());

        // If the validation expired, we simply reload the page
        // and make the user go through the validation flow again
        if (isValidationSessionExpired) {
            window.location.reload();
        } else {
            this.loadData();
        }
    };

    authenticate = () => {
        const {
            dispatch,
            serviceId,
            legacyServiceName,
            languageCode,
            encryptedNIN,
        } = this.props;

        dispatch(
            init(
                serviceId,
                Intent.SIGN,
                {
                    legacyServiceName,
                },
                languageCode,
                encryptedNIN
            )
        );
    };

    finishSigningProcess = (
        isTemporary: boolean,
        userId: number | null,
        newSignature?: SignatureData
    ) => {
        const { dispatch, challengeKey } = this.props;

        dispatch(
            finalizeSigning(
                challengeKey,
                {
                    disposable: isTemporary,
                    userId,
                },
                newSignature
            )
        );
    };

    handleSign = async () => {
        const { dispatch, challengeKey } = this.props;

        analytics.track('OpenID - Click "Sign Documents" button');

        dispatch(startSigning(challengeKey));
    };

    isSigningInProcess() {
        const { error, isLoaded, isFetching } = this.props;

        return isFetching && isLoaded && !error;
    }

    renderSigningFooterError() {
        const isSigningInProgress = this.isSigningInProcess();

        return (
            <>
                <div>
                    {i18n`Our team has been notified of this issue and we'll look into it.
                        In the meantime you can try again or contact support if this problem keeps on ocurring`}
                </div>
                <br />
                <Button
                    theme="gray"
                    disabled={isSigningInProgress}
                    className="openid-sign-button"
                    variant="outline"
                    icon={
                        isSigningInProgress
                            ? 'far fa-sync fa-spin'
                            : 'far fa-undo'
                    }
                    onClick={this.authenticate}>
                    {i18n`Start over`}
                </Button>
                <span className="ml mr">{i18n`or`}</span>
                <Button
                    theme="blue"
                    disabled={isSigningInProgress}
                    className="openid-sign-button"
                    variant="outline"
                    icon={
                        isSigningInProgress
                            ? 'far fa-sync fa-spin'
                            : 'far fa-sync'
                    }
                    onClick={this.handleSign}>
                    {i18n`Try again`}
                </Button>
            </>
        );
    }

    renderSigningFooterActions() {
        const isSigningInProgress = this.isSigningInProcess();

        return (
            <>
                <br />
                <Button
                    theme="green"
                    disabled={isSigningInProgress}
                    className="openid-sign-button"
                    size="large"
                    icon={
                        isSigningInProgress ? 'far fa-sync fa-spin' : undefined
                    }
                    onClick={this.handleSign}>
                    {i18n`Sign Documents`}
                </Button>
                <div className="mt">
                    <a className="openid-sign-link" onClick={this.authenticate}>
                        {i18n`Or use another account`}
                    </a>
                </div>
            </>
        );
    }

    renderSigning() {
        const { certificate, signText } = this.props;

        return (
            <>
                <OpenIDSignText signText={signText} />
                {certificate && <OpenIDCertificate certificate={certificate} />}
                {this.props.signingError && (
                    <OpenIDErrorDetails error={this.props.signingError} />
                )}
                <div className="openid-sign-container-footer">
                    {this.props.signingError
                        ? this.renderSigningFooterError()
                        : this.renderSigningFooterActions()}
                </div>
            </>
        );
    }

    renderStorageOptions(method: EIDMethod) {
        const isSigningInProgress = this.isSigningInProcess();

        return (
            <>
                <PostSignStorageOptions
                    method={method.type}
                    isSubmitting={isSigningInProgress}
                    hasError={!!this.props.signingError}
                    challengeKey={this.props.challengeKey}
                    signature={this.props.signatureData}
                    onSubmit={this.finishSigningProcess}
                />
                {this.props.signingError && (
                    <OpenIDErrorDetails error={this.props.signingError} />
                )}
            </>
        );
    }

    renderLoading() {
        return (
            <div>
                <br />
                <br />
                <LoadingData />
            </div>
        );
    }

    renderError() {
        const { error } = this.props;
        const isValidationSessionExpired = error.code === ERROR_OPENID_016.code;

        return (
            <div>
                <h3 className="mt0 mb0">{i18n`Uh oh! Seems like this didn't go as we expected`}</h3>
                {/* This message doesn't make sense if the issue is simply that the validation session expired */}
                {!isValidationSessionExpired && (
                    <p className="openid-error-friendly-message">
                        {i18n`Our team has been notified of this issue and we'll look into it.
                        In the meantime you can try again or contact support if this problem keeps on ocurring`}
                    </p>
                )}
                <OpenIDErrorDetails error={error} />
                <Button
                    onClick={this.retry}
                    theme="blue"
                    variant="outline"
                    icon="far fa-sync">
                    {i18n`Try again`}
                </Button>
            </div>
        );
    }

    renderCantCryptoError() {
        return (
            <div>
                <h3 className="mt0 mb">{i18n`Uh oh!`}</h3>
                <p className="openid-error-friendly-message">
                    {i18n`You are using a browser that doesn't have the capabilities of producing a secure signature. Your browser might be outdated.`}
                    <br />
                    <br />
                    {i18n`To sign, please use a different browser. We recommend one of the following: Chrome, Firefox or Safari`}
                    .
                </p>
            </div>
        );
    }

    renderContent(method: EIDMethod) {
        const {
            error,
            isLoaded,
            displayStorageOptions,
            openIdState,
            challengeKey,
            certificate,
        } = this.props;
        const { canCrypto } = this.state;

        if (!canCrypto) {
            return this.renderCantCryptoError();
        }

        if (error) {
            return this.renderError();
        }

        if (!isLoaded) {
            return this.renderLoading();
        }

        if (displayStorageOptions) {
            return this.renderStorageOptions(method);
        }

        if (
            openIdState.serviceId === MITID_PERSONAL_NO_CPR_STEP_DENMARK.type &&
            isMitIDInfoProtected(openIdState, certificate)
        ) {
            /**
             * In the context of NAP we need to retrieve the idValidationToken for the MitID user
             * we will do this in MitIDNAPInterventionScreen.tsx where we will also clear the openIDState from the localstorage
             * when we are done with it
             */
            setOpenIdState(openIdState);

            window.location.href = `/name-address-protection/${challengeKey}`;

            return this.renderLoading();
        }

        return this.renderSigning();
    }

    render() {
        const { serviceId } = this.props;
        const method = EID_METHODS.find((m) => serviceId === m.type);

        if (!method) {
            return null;
        }

        return (
            <div className="openid-sign" data-penneo-service-id={serviceId}>
                <div className="openid-sign-container">
                    <div className="openid-modal-title-bar">
                        <img
                            className="openid-sign-container-logo"
                            src={method.logo}
                            alt={method.title}
                        />
                    </div>
                    {this.renderContent(method)}
                </div>
            </div>
        );
    }
}

export default connect((state: ReduxState) => {
    return {
        signatureData: state.openId.sign.data.signatureData,
        displayStorageOptions: state.openId.sign.displayStorageOptions,
        isFetching: state.openId.sign.isFetching,
        isLoaded: state.openId.sign.isLoaded,
        openIdState: state.openId.intent.data,
        signText: state.openId.sign.data.signText,
        certificate: state.openId.sign.data.certificate,
        signingError: state.openId.sign.signingError,
        error: state.openId.error,
    };
})(OpenIDSign);
