import storage from './Storage';

const debug = {
    // Writes to console.log if debug mode is enabled.
    // Returns console.log function to preserve line number and script source in console
    log: storage.get('penneo-debug') === 'true' ? console.log : () => {},
    info: storage.get('penneo-debug') === 'true' ? console.info : () => {},
    warn: storage.get('penneo-debug') === 'true' ? console.warn : () => {},
    error: storage.get('penneo-debug') === 'true' ? console.error : () => {},
    export: (key, ref) => {
        if (storage.get('penneo-debug') !== 'true') {
            return false;
        }

        if (!window.penneo) {
            window.penneo = {};
        }

        window.penneo[key] = ref;
    },
    isDebugEnabled: storage.get('penneo-debug') === 'true',
};

export default debug;
