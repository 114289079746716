/* eslint react/no-find-dom-node: 0 */
import PropTypes from 'prop-types';
import React from 'react';
import CasefileStore from '../../stores/CasefileStore';
import CasefileActions from '../../actions/CasefilesActionCreators';
import { modal } from 'Common/components/Common/Modal';
import { i18n } from 'Language';
import ReactDOM from 'react-dom';
import Button from 'Common/components/Button';

export default class CasefileFolderSelectorModal extends React.Component {
    static propTypes = {
        folders: PropTypes.array,
        defaultFolder: PropTypes.number,
        active: PropTypes.object,
    };

    static defaultProps = {
        folders: [],
        defaultFolder: null,
    };

    state = {
        open: {},
        selected: this.props.active,
    };

    componentDidMount() {
        this.openParentFolder(this.props.active);
    }

    focusFolder = (el) => {
        if (el) {
            ReactDOM.findDOMNode(el).focus();
        }
    };

    openParentFolder = (folder) => {
        if (!folder.parentId) {
            return false;
        }

        let parent = CasefileStore.getFolder(folder.parentId);

        let { open } = this.state;

        open[parent.id] = true;

        this.setState({ open: open });

        // Perform the same check for deeply nested children.
        this.openParentFolder(parent);
    };

    handleClick = (folder) => {
        let { open } = this.state;

        // Toggle open state for folder.
        // only for items that have children
        if (folder.children) {
            open[folder.id] = !this.isOpen(folder.id); // get open state, reverse it.
        }

        this.setState({ open: open, selected: folder });
    };

    isOpen = (folderId) => {
        let { open } = this.state;

        if (open[folderId]) {
            return open[folderId];
        }

        return false;
    };

    getFolderStateClass = (folder) => {
        if (!folder.children) {
            return '';
        }

        if (this.isOpen(folder.id)) {
            return 'open';
        }

        return 'closed';
    };

    isActive = (folderId) => {
        let { selected } = this.state;

        if (selected && folderId === selected.id) {
            return true;
        }

        return false;
    };

    changeFolder = () => {
        let { selected } = this.state;

        CasefileActions.changeFolder(selected.id);

        modal.hide();
    };

    renderFolderList = (folders, level = 0) => {
        return folders.map((folder) => (
            <li
                key={folder.id}
                tabIndex={-1}
                ref={this.isActive(folder.id) && this.focusFolder}
                className={this.getFolderStateClass(folder)}>
                <span
                    onClick={this.handleClick.bind(null, folder)}
                    className={
                        'folder-title-container ' +
                        (this.isActive(folder.id) ? 'active' : '')
                    }>
                    <span className="folder-title">
                        {folder.children && this.isOpen(folder.id) && (
                            <i>
                                <i className="fa fa-chevron-down"></i>
                                &nbsp;
                            </i>
                        )}
                        {folder.children && !this.isOpen(folder.id) && (
                            <i>
                                <i className="fa fa-chevron-right"></i>
                                &nbsp;
                            </i>
                        )}
                        {this.isOpen(folder.id) ? (
                            <i className="far fa-folder-open"></i>
                        ) : (
                            <i className="far fa-folder"></i>
                        )}
                        &nbsp;&nbsp;{folder.title}
                        {folder.id === this.props.defaultFolder && (
                            <i>
                                &nbsp;&nbsp;<i className="fa fa-star"></i>
                            </i>
                        )}
                    </span>

                    {folder.children && (
                        <span className="children">
                            ({folder.children.length}&nbsp;
                            {folder.children.length === 1 && i18n`folder`}
                            {folder.children.length > 1 && i18n`folders`})
                        </span>
                    )}
                </span>

                {folder.children && this.isOpen(folder.id) && (
                    <ul className={`level-${level}`}>
                        {this.renderFolderList(folder.children, level + 1)}
                    </ul>
                )}
            </li>
        ));
    };

    render() {
        let { folders } = this.props;
        let { selected } = this.state;

        // if folder doesn't have a parent, it's a topmost folder
        let topLevelFolders = folders.filter((folder) => !folder.parent);

        return (
            <div className="casefile-folder-selector">
                <ul className="casefile-folder-container">
                    {this.renderFolderList(topLevelFolders)}
                </ul>
                {selected && (
                    <div className="current-folder">
                        <div>
                            <Button
                                theme="blue"
                                className="pull-right"
                                onClick={this.changeFolder}>
                                {i18n`Select this folder`}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
