import { createComponent } from '@lit/react';
import { Button } from '@penneo/vega-ui';
import React from 'react';

const UIButton = createComponent({
    tagName: 'vega-button',
    elementClass: Button,
    react: React,
    displayName: 'VegaButton',
});

export default UIButton;
