import PropTypes from 'prop-types';
import React from 'react';
import { modal } from 'Common/components/Common/Modal';
import WorkflowCommentActions from '../../actions/WorkflowCommentActionCreators';
import { translate } from 'Language';
import Button from 'Common/components/Button';

export default class RejectForm extends React.Component {
    static propTypes = {
        message: PropTypes.object,
        rejectHandler: PropTypes.func.isRequired,
    };

    state = {
        key: Math.random(),
    };

    closePrompt = (event) => {
        event.preventDefault();
        modal.hide();
    };

    componentWillUnmount() {
        if (!this.state.message || this.state.message.comment.length === 0) {
            return false;
        }

        let comment = {
            type: null,
            comment: '',
        };

        WorkflowCommentActions.updateCurrentComment(comment);
        modal.hide();
    }

    commentChange = (event) => {
        let { value } = event.target;

        const type = 1; // 1 = rejected comment type.

        let comment = {
            type: type,
            comment: value,
        };

        WorkflowCommentActions.updateCurrentComment(comment);
    };

    componentDidUpdate(nextProps) {
        if (nextProps.message !== this.props.message && modal.isVisible()) {
            this.showPrompt();
        }
    }

    showPrompt = (event) => {
        if (event) {
            event.preventDefault();
        }

        let { message } = this.props;

        let config = {
            title: translate('Decline'),
            body: (
                <div>
                    <h4>
                        {translate(
                            'Are you sure you wish to decline to sign this document'
                        )}
                    </h4>
                    <p>
                        {translate(
                            'Please provide a reason for your declining to sign:'
                        )}
                    </p>
                    <textarea
                        style={{ width: '100%', marginBottom: '1em' }}
                        onChange={this.commentChange}
                        rows="8"
                        value={message.comment}
                    />
                </div>
            ),
            buttons: (
                <div>
                    <Button onClick={modal.hide}>{translate('No')}</Button>
                    <Button
                        theme="red"
                        onClick={this.handleClick}
                        disabled={message.comment.trim().length === 0}>
                        {translate('Yes, I wish to decline')}
                    </Button>
                </div>
            ),
        };

        modal.show(config);
    };

    handleClick = (event) => {
        event.preventDefault();
        this.props.rejectHandler();
    };

    render() {
        return (
            <Button
                theme="red"
                fullWidth
                onClick={this.showPrompt}
                icon="fa fa-times">
                {translate('Reject')}
            </Button>
        );
    }
}
