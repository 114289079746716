import React from 'react';
import lodash from 'lodash';
import moment from 'moment';
import { fetchSummary, fetchIncidentIntervalDuration } from './status-page-api';
import StatusComponentId from './component-id';

export type Incident = {
    id: string;
    name: string;
    shortlink: string;
    status: string;
    incident_updates: any[] /* eslint camelcase:0 */;
    components: any;
    updated_at: string /* eslint camelcase:0 */;
};

export const ComponentId = StatusComponentId;

export const IncidentStatus = {
    Investigating: 'investigating',
    Identified: 'identified',
    Monitoring: 'monitoring',
    Resolved: 'resolved',
};

type Props = {
    components: string[];
};

type State = {
    incidents: Incident[];
};

class StatusPageMessage extends React.Component<Props, State> {
    fetchIncidentInterval: any = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            incidents: [],
        };
    }

    componentDidMount() {
        this.fetchIncidentInterval = setInterval(
            () => this.fetchSummaryData(),
            fetchIncidentIntervalDuration
        );

        return this.fetchSummaryData();
    }

    componentWillUnmount() {
        clearInterval(this.fetchIncidentInterval);
    }

    fetchSummaryData = () =>
        fetchSummary().then((incidents) => this.setState({ incidents }));

    getComponentIncidents() {
        return this.state.incidents.filter((incident) => {
            if (
                incident.status === IncidentStatus.Monitoring ||
                incident.status === IncidentStatus.Resolved
            ) {
                return false;
            }

            const affectedComponentIds = incident.components.map((c) => c.id);

            // Return incidents where the IDs requested match the affected component IDs
            return (
                lodash.intersection(this.props.components, affectedComponentIds)
                    .length > 0
            );
        });
    }

    render() {
        const incidents = this.getComponentIncidents();

        if (!incidents.length) {
            return false;
        }

        const [incident] = incidents;
        const update = incident.incident_updates[0];

        return (
            <div className="status-page-message">
                <b>
                    <i className="fas fa-info-circle" />
                    &nbsp;
                    {incident.name}
                </b>
                <p style={{ whiteSpace: 'pre-line' }}>{update.body}</p>
                <a
                    href={incident.shortlink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pull-right">
                    Read more <i className="far fa-external-link" />
                </a>
                <span className="text-small">
                    Updated&nbsp;
                    {moment(incident.updated_at).fromNow()}
                </span>
            </div>
        );
    }
}

export default StatusPageMessage;
