import PropTypes from 'prop-types';
import React from 'react';
import DocumentNavigator from './DocumentNavigator';
import DocumentForm from './DocumentForm';
import DocumentViewer from './DocumentViewer';

import scrollTo from '../../utils/scrollTo';
import FormStore from '../../stores/FormStore';
import FormActions from '../../actions/FormActionCreators';
import { translate } from 'Language';

export default class DocumentEditor extends React.Component {
    static propTypes = {
        form: PropTypes.object,
        fields: PropTypes.array,
        mapping: PropTypes.array,
        formDocument: PropTypes.array,
        pageImages: PropTypes.array,
        dimensions: PropTypes.object,
        workflow: PropTypes.object,
        coSigner: PropTypes.object,
        rejectionMessage: PropTypes.object,
        reject: PropTypes.func,
        saveFormHandler: PropTypes.func,
        previewMode: PropTypes.bool,
        attachments: PropTypes.array,
    };

    static defaultProps = {
        form: {},
        fields: [],
        mapping: [],
        formDocument: {},
        pageImages: [],
        attachmentDocument: {},
        workflow: {},
        coSigner: {},
        rejectionMessage: {},
    };

    /* View State (Internal State - not persisted to store) */
    state = {
        viewportWidth: null,
        currentView: 2,
        dimensions: {},
        zoom: 0,
        scalableStyle: {},
        viewerStyle: {},
        higlightedField: -1,
    };

    componentDidMount() {
        // Prevent body scrolling, for mobile compatibility.
        document.body.classList.add('no-scroll');

        // Attach Event Listeners
        FormStore.addChangeListener(this.onChange);
        window.addEventListener('resize', this.handleResize);

        this._setInitialViewportWidth();
    }

    componentWillUnmount() {
        // Remove Events and classes added on componentDidMount
        FormStore.removeChangeListener(this.onChange);
        document.body.classList.remove('no-scroll');
        window.removeEventListener('resize', this.handleResize);
    }

    onChange = () => {
        this.setState({
            dimensions: FormStore.getDocumentDimensions(),
        });
    };

    /**
     * Sets initial viewportWidth state.
     */
    _setInitialViewportWidth = () => {
        this.setState({ viewportWidth: window.innerWidth });
    };

    /**
     * Listens to Browser Resizing events.
     * Sets viewportWidth state, which tracks the current browser width.
     */
    handleResize = () => {
        this.setState({ viewportWidth: window.innerWidth });

        if (window.innerWidth > 768 && this.state.currentView === 1) {
            this.setState({ currentView: 2 });
        }
    };

    /**
     * Creates an element based on the dropped field type on the documents' page.
     * @param event {event} onDrop event from finalized drag action.
     */
    dropHandler = (event) => {
        event.preventDefault();
        // Get DOM Object Bounds
        let rect = event.target.getBoundingClientRect();

        // Calculate pointer position inside target. (Pointer position - Target Position)
        let posX = event.clientX - rect.left;
        let posY = event.clientY - rect.top;

        let fieldId = parseInt(event.dataTransfer.getData('text/plain'), 10); // Parsed to Integer

        // Render Element in position
        this.createElement(event.target, posX, posY, fieldId);
    };

    /**
     * Updates value on field state and propagates the value to all mapped fields with
     * the same field ID
     * @param field {int}   Field ID
     * @param event {value} Value to set onto field in store
     */
    updateField = (fieldId, value) => {
        FormActions.updateField(fieldId, value);
    };

    scrollToField = (fieldId) => {
        let { dimensions } = this.state;
        let field = document.getElementById('field-' + fieldId);

        // If fields or pages haven't been loaded, skip scrolling to fields.
        if (!dimensions[1] || !field) {
            return false;
        }

        let bounds = field.getBoundingClientRect();
        let viewer = document
            .getElementById('scalable')
            .getBoundingClientRect();

        let pageHeight = dimensions[1].height;
        let distanceY = bounds.top - viewer.top - pageHeight / 4;

        scrollTo('#viewer', distanceY);
    };

    /**
     * Updates state to mark field as highlighted
     * @param field {object} field state object containing field value and id
     * @param event {event} onFocus event, activated by active fields in the form sidebar
     */
    highlightFieldHandler = (field, event) => {
        event.preventDefault();

        this.scrollToField(field.id);
        this.setState({ highlightedField: field.id });
    };

    /**
     * Updates state to remove flag that marks a field as highlighted.
     * @param field {object} field state object containing field value and id
     * @param event {event} onFocus event, activated by active fields in the form sidebar
     */
    removeHighlightHandler = (field, event) => {
        event.preventDefault();

        this.setState({ highlightedField: -1 });
    };

    toggleView = (view) => {
        this.setState({ currentView: view });
    };

    zoomIn = () => {
        let { zoom } = this.state;

        this.scaleContent(zoom + 0.1);
    };

    zoomOut = () => {
        let { zoom } = this.state;

        this.scaleContent(zoom - 0.1);
    };

    scaleContent = (newZoom) => {
        let { scalableStyle, viewerStyle, zoom } = this.state;
        const scalable = document
            .getElementById('page-content')
            .getBoundingClientRect();

        scalableStyle.width = newZoom * scalable.width;
        scalableStyle.height = newZoom * scalable.height;

        this.setState({
            zoom: zoom,
            scalableStyle: scalableStyle,
            viewerStyle: viewerStyle,
        });
    };

    render() {
        // Form Data
        let { form, fields, mapping } = this.props;

        // Documents
        let { formDocument, pageImages, attachments } = this.props;

        // Workflow
        let { workflow, coSigner, rejectionMessage } = this.props;

        // Functions
        let { saveFormHandler, reject } = this.props;

        // View State
        const viewerId = 'viewer';
        let { previewMode } = this.props;

        let {
            currentView,
            zoom,
            scalableStyle,
            viewerStyle,
            dimensions,
        } = this.state;
        let { highlightedField } = this.state;

        return (
            <div
                key={form.id}
                className={
                    'document-forms-wrapper ' +
                    (!previewMode ? 'form-action-bar' : '')
                }>
                <div className="toggle-bar">
                    <span
                        className={
                            'option' + (currentView === 1 ? ' active' : '')
                        }
                        onClick={this.toggleView.bind(this, 1)}>
                        {translate('See documents')}
                        &nbsp;<i className="far fa-file-alt"></i>
                    </span>
                    <span
                        className={
                            'option' + (currentView === 2 ? ' active' : '')
                        }
                        onClick={this.toggleView.bind(this, 2)}>
                        {translate('Fill out the fields')}
                        &nbsp;<i className="fa fa-list-ul"></i>
                    </span>
                </div>

                <div className="document-forms-editor">
                    <DocumentNavigator
                        pages={pageImages}
                        form={form}
                        viewerId={viewerId}
                    />

                    <DocumentViewer
                        doc={formDocument}
                        pages={pageImages}
                        fields={fields}
                        mapping={mapping}
                        highlightedField={highlightedField}
                        highlightField={this.highlightFieldHandler}
                        removeHighlight={this.removeHighlightHandler}
                        disableEvent={this.disableEvent}
                        getCoords={this.getCoords}
                        updateField={this.updateField}
                        scalableStyle={scalableStyle}
                        viewerStyle={viewerStyle}
                        zoom={zoom}
                        dimensions={dimensions}
                    />

                    <div className={currentView === 1 ? 'hide' : ''}>
                        <DocumentForm
                            fields={fields}
                            form={form}
                            workflow={workflow}
                            attachments={attachments}
                            coSigner={coSigner}
                            highlightField={this.highlightFieldHandler}
                            highlightedField={highlightedField}
                            removeHighlight={this.removeHighlightHandler}
                            updateField={this.updateField}
                            previewMode={previewMode}
                            saveFormHandler={saveFormHandler}
                            rejectHandler={reject}
                            rejectionMessage={rejectionMessage}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
