export enum ActionTypes {
    SETTINGS_FETCH_REQUEST = 'SETTINGS_FETCH_REQUEST',
    SETTINGS_FETCH_SUCCESS = 'SETTINGS_FETCH_SUCCESS',
    SETTINGS_FETCH_FAILURE = 'SETTINGS_FETCH_FAILURE',

    SETTINGS_USER_UPDATE_REQUEST = 'SETTINGS_USER_UPDATE_REQUEST',
    SETTINGS_USER_UPDATE_SUCCESS = 'SETTINGS_USER_UPDATE_SUCCESS',
    SETTINGS_USER_UPDATE_FAILURE = 'SETTINGS_USER_UPDATE_FAILURE',

    SETTINGS_CUSTOMER_UPDATE_REQUEST = 'SETTINGS_CUSTOMER_UPDATE_REQUEST',
    SETTINGS_CUSTOMER_UPDATE_SUCCESS = 'SETTINGS_CUSTOMER_UPDATE_SUCCESS',
    SETTINGS_CUSTOMER_UPDATE_FAILURE = 'SETTINGS_CUSTOMER_UPDATE_FAILURE',

    SETTINGS_MANAGED_UPDATE_REQUEST = 'SETTINGS_MANAGED_UPDATE_REQUEST',
    SETTINGS_MANAGED_UPDATE_SUCCESS = 'SETTINGS_MANAGED_UPDATE_SUCCESS',
    SETTINGS_MANAGED_UPDATE_FAILURE = 'SETTINGS_MANAGED_UPDATE_FAILURE',

    SETTINGS_MANAGED_FETCH_REQUEST = 'SETTINGS_MANAGED_FETCH_REQUEST',
    SETTINGS_MANAGED_FETCH_SUCCESS = 'SETTINGS_MANAGED_FETCH_SUCCESS',
    SETTINGS_MANAGED_FETCH_FAILURE = 'SETTINGS_MANAGED_FETCH_FAILURE',
}
