export const loadScript = (
    url: string,
    options?: Record<string, string>
): Promise<void> =>
    new Promise((resolve, reject) => {
        const script = document.createElement('script');

        script.src = url;

        // tracking these events will resolve/reject the promise
        script.onload = () => resolve();
        script.onerror = () => reject(`Couldn't load script`);

        // If there's an options object, add those attributes to the script element
        options &&
            Object.entries(options).forEach(
                ([key, value]) => (script[key] = value)
            );

        document.body.appendChild(script);
    });
