import React from 'react';
import PropTypes from 'prop-types';
import tour from 'Common/utils/tour';
import { TOUR_IDS } from 'Constants';

type Props = {
    tourId: TOUR_IDS;
    children?: React.ReactNode;
};

export class TourWrapper extends React.Component<Props> {
    static propTypes = { tourId: PropTypes.string };

    componentDidMount() {
        const { tourId } = this.props;

        // @note: this timeout is a temporary fix to wait for initial language change before
        // the tours are rendered. This is a workaround because we cannot connect hopscotch
        // to a flux action.
        setTimeout(() => {
            tour.start(tourId, false);
        }, 2000);
    }

    componentWillUnmount() {
        tour.endTour();
    }

    render() {
        const { children } = this.props;

        return <div>{children}</div>;
    }
}
