import { SigningAPI } from 'Api';
import { Dispatcher } from 'Core';
import assign from 'object-assign';

const userPreferenceActions = {
    _fetchPreferences(namespace = 'preferences') {
        return SigningAPI.get(`/user/preferences/${namespace}`)
            .then((response) => response.preferences || {})
            .catch(() => ({}));
    },

    _savePreferences(data, namespace = 'preferences') {
        let payload = {};

        let endpoint = `/user/preferences/${namespace}`;

        // Fetch latest settings
        return (
            this._fetchPreferences(namespace)
                // Merge data with current prefs
                .then((response) => {
                    payload.preferences = assign({}, response, data);
                })

                // POST
                .then(() => SigningAPI.post(endpoint, payload))
                .then((response) => response.preferences)

                // If POST fails, attempt PUT
                .catch(() => ({})) // ignore error

                // PUT
                .then(() => SigningAPI.put(endpoint, payload))
                .then((response) => response.preferences)

                .catch(() => Promise.reject(false))
        );
    },

    saveNewPreferences(data, namespace = 'preferences') {
        let endpoint = `/user/preferences/${namespace}`;

        return SigningAPI.put(endpoint, data)
            .then((response) => response.preferences)
            .catch(() => Promise.reject(false));
    },

    fetchPreferences() {
        return this._fetchPreferences().then((preferences) => {
            Dispatcher.handleServerAction({
                type: 'USER_PREFERENCES_FETCH_SUCCESS',
                preferences: preferences,
            });
        });
    },

    savePreferences(data) {
        return this._savePreferences(data).then((preferences) => {
            Dispatcher.handleServerAction({
                type: 'USER_PREFERENCES_UPDATE_SUCCESS',
                preferences: preferences,
            });
        });
    },
};

export default userPreferenceActions;
