import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'Language';
import analytics from 'Common/Analytics';
import Modal from 'Common/components/Modal';
import CreateNewOption from './CreateNewOption';

import './modal-create-new.scss';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { connect } from 'react-redux';
import { AppDispatch } from 'Store';
import { resetCurrentLinks } from 'Clients/redux';
import { UserEntity } from 'types/User';
import {
    hasCasefileSendAccess,
    hasFormsAccess,
    hasKYCAccess,
} from 'Common/utils/userPermissions';

type Props = {
    dispatch: AppDispatch;
    onClose: Function;
    user: UserEntity;
};

export class CreateCTAModal extends React.Component<Props> {
    static contextTypes = {
        router: PropTypes.object,
    };

    onClick = (itemId) => {
        analytics.track('dialog - create new item', {
            type: itemId,
        });

        /**
         * Clear Client links in Redux.
         * This is to prevent unsaved links
         * from a discarted casefile to stay
         * in the store, even after navigating to
         * other pages.
         */
        if (LaunchDarkly.variation(Flags.CLIENTS)) {
            this.props.dispatch(resetCurrentLinks());
        }

        // Close modal after clicking an element
        this.props.onClose();
    };

    render() {
        const { onClose, user } = this.props;

        return (
            <Modal
                className="modal-create-new"
                fullscreen
                header={
                    <div className="modal-create-new-title">
                        <h1>{i18n`Create new`}</h1>
                        <p>
                            <i>{i18n`Select the type of process you want to start`}</i>
                        </p>
                    </div>
                }
                onClose={onClose}>
                <div className="modal-create-new-content">
                    <div className="modal-create-new-options">
                        {hasCasefileSendAccess(user) && (
                            <CreateNewOption
                                id="casefile"
                                title="Case file"
                                subtitle="Send documents for signature"
                                link={{
                                    name: 'casefile-create',
                                    params: { action: 'new' },
                                }}
                                onClick={this.onClick}
                                image={'/graphics/600x600_documents.png'}
                            />
                        )}

                        {hasFormsAccess(user) && (
                            <CreateNewOption
                                id="form"
                                title="Web Form"
                                subtitle="Create forms to fill and sign via URL"
                                link={'template-creator'}
                                onClick={this.onClick}
                                image={'/graphics/600x600_forms.png'}
                            />
                        )}

                        {hasKYCAccess(user) && (
                            <CreateNewOption
                                id="kyc"
                                inactive={
                                    !LaunchDarkly.variation(Flags.KYC_ACCESS)
                                }
                                title="Identity validation"
                                subtitle="Request identity validation"
                                link={'kyc-create'}
                                onClick={this.onClick}
                                image={'/graphics/600x600_compliance.png'}
                            />
                        )}

                        {hasCasefileSendAccess(user) && (
                            <CreateNewOption
                                id="registered-letter"
                                inactive={
                                    !LaunchDarkly.variation(
                                        Flags.REGISTERED_LETTER_ACCESS
                                    )
                                }
                                title="Registered letter"
                                subtitle="Send a registered letter"
                                link={'registered-letter-create'}
                                onClick={this.onClick}
                                image={'/graphics/600x600_registeredletter.png'}
                            />
                        )}
                    </div>
                </div>
            </Modal>
        );
    }
}

export default connect()(CreateCTAModal);
