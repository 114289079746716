import { Dispatcher, BaseStore } from 'Core';
import Constants from '../Constants';
import assign from 'object-assign';
import { WorkflowAPI } from 'Api';

// Data Storage
let _comments = [];

let _currentComment = {
    type: null,
    comment: '',
};

function getCommentIndex(id) {
    // Find index based on document id
    for (let i = 0; i < _comments.length; i++) {
        if (_comments[i].id === id) {
            return i;
        }
    }
}

function getComments(comments) {
    _comments = comments;
}

/* Private Functions */
function addComment(message) {
    // Add New Message to Collection
    _comments.push(message);
}

function removeMessage(id) {
    let index = getCommentIndex(id);

    // Remove Message from collection
    _comments.splice(index, 1);
}

function updateCurrentComment(comment) {
    let updatedComment = assign({}, _currentComment, comment);

    _currentComment = updatedComment;
}

function saveComment(comment) {
    _comments.push(comment);
}

// Flux Store Creation
const WorkflowCommentStore = assign({}, BaseStore, {
    getCommentById(id, data = this.getMessages()) {
        for (let i = data.length - 1; i >= 0; i--) {
            if (data[i].id === id) {
                return data[i];
            }
        }

        return {};
    },

    getCommentByType() {
        return _comments;
    },

    /* Document operations */
    getComments() {
        return _comments;
    },

    /* Document operations */
    getCurrentComment() {
        return _currentComment;
    },

    // @todo: remove in favor of action.
    // This is a temporary fix to access multiple attachments
    // without keeping a list of attachments with IDs.
    fetchCommentsById(workflowId) {
        return WorkflowAPI.get(
            '/v2/workflows/' + workflowId + '/comments'
        ).then((comments) => {
            return comments;
        });
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.GET_COMMENTS:
                if (action.comments) {
                    getComments(action.comments);
                }

                break;

            case Constants.ActionTypes.MESSAGE_ADDED:
                if (action.message) {
                    addComment(action.message);
                }

                break;

            case Constants.ActionTypes.CURRENT_COMMENT_UPDATED:
                if (action.comment) {
                    updateCurrentComment(action.comment);
                }

                break;

            case Constants.ActionTypes.MESSAGE_REMOVED:
                if (action.id) {
                    removeMessage(action.id);
                }

                break;

            case Constants.ActionTypes.COMMENT_SAVED:
                if (action.comment) {
                    saveComment(action.comment);
                }

                break;

            default:
                return false;
        }
        WorkflowCommentStore.emitChange();
    }),
});

export default WorkflowCommentStore;
