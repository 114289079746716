import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'Language';
// Redux
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import { updateEmailTemplate } from 'KYC/redux/actions';
import { getUserEmailTemplates } from 'EmailTemplates/redux/actions';
import {
    EmailTemplates,
    EmailTemplate,
    EmailTemplateGroup,
    TemplateType,
} from 'types/EmailTemplates';
import { KYCMessageValidation } from 'types/DataValidation';
import PersonalizedMessageEditor, {
    Tab,
} from 'Common/components/PersonalizedMessageEditor';
import { Languages } from 'Language/Constants';

export type Props = {
    dispatch: AppDispatch;
    language: string;
    isLoading: boolean;
    userEmailTemplates: EmailTemplate[];
    kycEmailTemplates: EmailTemplates;
    emailTemplatesValidation: KYCMessageValidation;
};

type State = {
    activeType: any;
    hasError: boolean;
};

export class KYCEmailTemplate extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            activeType: 'initial',
            hasError: false,
        };
    }

    async componentDidMount() {
        const { dispatch } = this.props;

        // Always fetch the tpls in english so the default one can be translated
        // on the fly if the user changes language for the case file during the
        // creation process
        await dispatch(
            getUserEmailTemplates(EmailTemplateGroup.KYC, Languages.EN)
        );
    }

    updateEmailTemplate = (type: TemplateType, template: EmailTemplate) => {
        this.props.dispatch(updateEmailTemplate(type, template));
    };

    render() {
        const {
            userEmailTemplates = [],
            kycEmailTemplates,
            emailTemplatesValidation,
            language,
            isLoading,
        } = this.props;
        const tabs: Tab[] = [
            {
                id: TemplateType.INITIAL,
                valid: emailTemplatesValidation[TemplateType.INITIAL].valid,
                label: 'Initial message to recipients',
                tagline:
                    'This message will be sent out to all recipients via email to notify them they have to fill the validation form',
            },
            {
                id: TemplateType.REMINDER,
                valid: emailTemplatesValidation[TemplateType.REMINDER].valid,
                label: 'Reminder',
                tagline: 'Reminder messages',
            },
        ];

        return (
            <div>
                <h4 className="section-header mb0">
                    {i18n`Personalize email`}
                </h4>

                {!isLoading && (
                    <PersonalizedMessageEditor
                        tabs={tabs}
                        language={language}
                        group={EmailTemplateGroup.KYC}
                        onTemplateChange={this.updateEmailTemplate}
                        availableTemplates={userEmailTemplates}
                        selectedTemplates={kycEmailTemplates}
                        templatesValidation={emailTemplatesValidation}
                        showSignatureNotice={false}
                    />
                )}
            </div>
        );
    }
}

export default connect((state: ReduxState) => ({
    isLoading: state.email.isLoading,
    language: state.kyc.__new.language,
    kycEmailTemplates: state.kyc.__new.emailTemplates,
    emailTemplatesValidation: state.kyc.__new.validation.emailTemplates,
    userEmailTemplates: state.email.templates || [],
}))(KYCEmailTemplate);
