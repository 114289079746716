import React from 'react';
import Tooltip from 'Common/components/Tooltip';
import { i18n } from 'Language';

import './status-badge.scss';

type Props = {
    status: {
        color: string;
        name: string;
        details: JSX.Element | JSX.Element[] | string;
        icon: string;
    };
};

const StatusBadge = ({ status }: Props) => {
    return (
        <div className={`casefile-status-badge ${status.color}`}>
            <Tooltip
                className="wrap"
                showArrow={true}
                text={<span>{i18n(status.details)}</span>}>
                <span className="casefile-status-badge-name no-wrap">
                    {i18n(status.name)}
                    &nbsp;
                    <i className={`far fa-${status.icon}`} />
                </span>
            </Tooltip>
        </div>
    );
};

export default StatusBadge;
