import PropTypes from 'prop-types';
import React from 'react';

export default class ListDropdown extends React.Component {
    static propTypes = {
        languages: PropTypes.object.isRequired,
        selected: PropTypes.string,
        changeLanguage: PropTypes.func.isRequired,
        open: PropTypes.bool,
        close: PropTypes.func.isRequired,
        callback: PropTypes.func,
    };

    handleClick = (languageCode) => {
        this.props.changeLanguage(languageCode);
        this.props.close();

        if (this.props.callback) {
            this.props.callback(languageCode);
        }
    };

    mapObject = (object, callback) => {
        return Object.keys(object).map((key) => {
            return callback(key, object[key]);
        });
    };

    renderLanguageList = () => {
        let { languages } = this.props;

        return this.mapObject(languages, (code, language) => (
            <li
                className="dropdown-element"
                key={code}
                onClick={this.handleClick.bind(this, code)}>
                <a>
                    <span>{language.name}</span>
                </a>
            </li>
        ));
    };

    render() {
        let { selected, languages, open } = this.props;

        return (
            <div className={'language-dropdown' + (open ? ' open' : '')}>
                <div className="dropdown-button">
                    <div>
                        Language: &nbsp;&nbsp;
                        {languages[selected].name}
                        &nbsp;&nbsp;&nbsp;
                        <i
                            className="fa fa-chevron-down"
                            style={{ fontSize: '0.8em' }}></i>
                    </div>
                    <div className={'dropdown-content'}>
                        <ul className="dropdown-list">
                            {this.renderLanguageList()}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
