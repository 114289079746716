import { Dispatcher } from 'Core';
import { AuthAPI } from 'Api';
import { LANGUAGES, DEFAULT_LANGUAGE } from '../Constants';
import { getEnabledLanguages } from '../utils';
import TranslationStore from '../stores/TranslationStore';
import { translations } from 'Language/i18n';
import { AuthStore } from 'Auth';

const actionCreators = {
    async setLanguage(languageCode, settings = { persist: true }) {
        if (!LANGUAGES[languageCode]) {
            return this._setDefaultLanguage();
        }

        await translations.changeLanguage(languageCode, true);

        Dispatcher.handleViewAction({
            type: 'LANGUAGE_CHANGE',
            languageCode: languageCode,
            settings: settings,
        });

        if (settings.persist) {
            this.persistLanguage(languageCode);
        }
    },

    /**
     * This method has to be used when we only want to change
     * the view of the current language (fx. signing page)
     * without persisting it in the user settings
     */
    async changeLanguageView(languageCode) {
        await translations.changeLanguage(languageCode);

        Dispatcher.handleViewAction({
            type: 'LANGUAGE_CHANGE',
            languageCode: languageCode,
            settings: { persist: true },
        });
    },

    persistLanguage(languageCode) {
        let payload = {
            language: languageCode,
        };

        return AuthAPI.patch('/user', payload)
            .then(async (response) => {
                if (!response || !response.language) {
                    throw new Error('Error persisting language');
                }

                await translations.changeLanguage(response.language, true);

                Dispatcher.handleViewAction({
                    type: 'LANGUAGE_CHANGE',
                    languageCode: response.language,
                });
            })
            .catch((error) => {
                console.log(error, 'there was an error persisting language');
            });
    },

    fetchLanguage(settings = {}) {
        // If the API doesn't have a token, don't attempt a request
        // instead, load from local state or default
        if (!AuthStore.getCachedToken()) {
            return this._setDefaultLanguage(settings);
        }

        // third argument skips authentication errors.
        AuthAPI.get('/user?language', null, { silent: true })
            .then(async (response) => {
                if (!getEnabledLanguages()[response.language]) {
                    return this._setDefaultLanguage();
                }

                await translations.changeLanguage(response.language);

                Dispatcher.handleViewAction({
                    type: 'LANGUAGE_CHANGE',
                    languageCode: response.language,
                });
            })
            .catch(() => {
                this._setDefaultLanguage(settings);
            });
    },

    async _setDefaultLanguage() {
        let language =
            TranslationStore.getLanguage({ local: true }) || DEFAULT_LANGUAGE;

        await translations.changeLanguage(language);

        Dispatcher.handleViewAction({
            type: 'LANGUAGE_CHANGE',
            languageCode: language,
        });
    },
};

export default actionCreators;
