import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { i18n } from 'Language';
import { notify } from 'react-notify-toast';

import TeamActions from '../actions/TeamActionCreators';
import TeamStore from '../stores/TeamStore';
import ActionTypes from 'Teams/ActionTypes';

import Loader from 'Common/components/Common/Loader.jsx';
import MemberList from './MemberList';
import Widget from 'Common/components/Widget';
import WidgetHeader from 'Common/components/WidgetHeader';
import WidgetBody from 'Common/components/WidgetBody';
import WidgetFooter from 'Common/components/WidgetFooter';
import ClickDropdown from 'Common/components/Common/ClickDropdown';
import { modal } from 'Common/components/Common/Modal';
import Button from 'Common/components/Button';

export default class TeamEditor extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static propTypes = {
        children: PropTypes.object,
        route: PropTypes.object,
        params: PropTypes.object,
    };

    state = {
        team: {
            name: '',
            description: '',
            parentId: '',
            children: [],
        },
        teams: [],
        availableUsers: [],
        loading: false,
        error: null,
        query: '',
    };

    onChange = (action) => {
        let { teamId } = this.props.params;
        let newState = {
            teams: TeamStore.getTeams(),
            availableUsers: TeamStore.getAvailableUsers(),
            loading: TeamStore.isFetching(),
            error: TeamStore.getError(),
        };

        if (teamId) {
            newState.team = TeamStore.getCurrentTeam();
        }

        this.setState(newState);

        switch (action) {
            case ActionTypes.CREATE_TEAM_SUCCESS:
            case ActionTypes.UPDATE_TEAM_SUCCESS:
            case ActionTypes.DELETE_TEAM_SUCCESS:
            case ActionTypes.FETCH_TEAM_FAILURE:
                return this._redirect();
            case ActionTypes.CREATE_TEAM_FAILURE:
            case ActionTypes.UPDATE_TEAM_FAILURE:
            case ActionTypes.DELETE_TEAM_FAILURE:
            case ActionTypes.FETCH_USERS_FAILURE:
                return TeamActions.clearErrors();
            default:
                break;
        }
    };

    loadData = () => {
        let { customerId, teamId } = this.props.params;

        TeamActions.fetchTeams();
        TeamActions.fetchAvailableUsers(customerId);

        if (teamId) {
            TeamActions.fetchTeam(teamId);
        }
    };

    componentDidMount = () => {
        TeamStore.addChangeListener(this.onChange);

        this.loadData();
    };

    componentWillUnmount = () => {
        TeamActions.clearStore();
        TeamStore.removeChangeListener(this.onChange);
    };

    _redirect = () => {
        let { customerId } = this.props.params;
        let { router } = this.context;

        router.push({
            name: 'company-manage-teams',
            params: {
                customerId: customerId,
            },
        });
    };

    _updateCurrentTeamState = (team) => {
        this.setState({
            team: team,
        });
    };

    _updateTeamDetails = (event) => {
        event.preventDefault();

        let { team } = this.state;
        let fieldName = event.target.name;
        let value = event.target.value;

        if (fieldName === 'parentId') {
            value = Number(value);
        }

        team[fieldName] = value;

        if (value === -1) {
            delete team.parentId;
        }

        this._updateCurrentTeamState(team);
    };

    removeTeamMember = (index, event) => {
        event.preventDefault();
        let { team } = this.state;

        team.members.splice(index, 1);
        this._updateCurrentTeamState(team);
    };

    filterTeamMembers = (query) => {
        this.setState({
            query: query,
        });
    };

    addTeamMembers = (members) => {
        let { team } = this.state;

        if (team.members) {
            team.members = team.members.concat(members);
        } else {
            team.members = members;
        }

        this._updateCurrentTeamState(team);
    };

    getMembers = () => {
        let { query, team } = this.state;

        if (!team.members) {
            return [];
        }

        if (query === '') {
            return team.members;
        }

        query = query.toLowerCase();

        return team.members.filter((member) => {
            return (
                member.fullName.toLowerCase().indexOf(query) > -1 ||
                member.email.toLowerCase().indexOf(query) > -1
            );
        });
    };

    getParentTeams = () => {
        // Get all parent teams except for the one that is being edited
        return this.state.teams.filter((team) => {
            return !team.parentId && team.id !== this.state.team.id;
        });
    };

    createTeam = () => {
        TeamActions.createTeam(this.state.team);
    };

    saveChanges = () => {
        TeamActions.updateTeam(this.state.team);
    };

    renderMoreOptions = () => {
        return (
            <div className="click-dropdown-list">
                <ul>
                    <li onClick={this._promptDelete}>
                        <i className="fa fa-times"></i>
                        {i18n`Delete team`}&nbsp;
                    </li>
                </ul>
            </div>
        );
    };

    _promptDelete = (event) => {
        event.preventDefault();

        let { team } = this.state;
        let config = {
            title: i18n`Are you sure?`,
            body: i18n`This action cannot be undone. If the team has sub-teams they will be deleted as well. Users will not be deleted`,
            buttons: (
                <div>
                    <Button onClick={modal.hide}>{i18n`Back`}</Button>
                    <Button
                        theme="red"
                        onClick={this.handleDelete.bind(this, team.id)}>
                        {i18n`Yes, Remove`}
                    </Button>
                </div>
            ),
            preventClose: true,
        };

        modal.show(config);
    };

    handleDelete = (teamId, event) => {
        event.preventDefault();

        TeamActions.deleteTeam(teamId);
        modal.hide();
    };

    render() {
        let { route, params } = this.props;
        let { query, team, availableUsers, loading, error } = this.state;

        if (error) {
            notify.show(error, 'error');
        }

        return (
            <div className="team-editor">
                {loading ? (
                    <Loader />
                ) : (
                    <Widget>
                        <WidgetHeader>
                            <h1>
                                {team.id
                                    ? i18n`${team.name} Team Details`
                                    : i18n`Create new team`}
                                <span className="dot">.</span>
                            </h1>
                        </WidgetHeader>

                        <WidgetBody>
                            <label>{i18n`Team name`}</label>
                            <input
                                id="team-name"
                                name="name"
                                onChange={this._updateTeamDetails}
                                type="text"
                                value={team.name}
                            />

                            <label>{i18n`Team description`}</label>
                            <input
                                id="team-description"
                                name="description"
                                onChange={this._updateTeamDetails}
                                type="text"
                                value={team.description ? team.description : ''}
                            />

                            {((team.children && team.children.length === 0) ||
                                !team.id) && <label>{i18n`Parent team`}</label>}
                            {((team.children && team.children.length === 0) ||
                                !team.id) && (
                                <select
                                    name="parentId"
                                    onChange={this._updateTeamDetails}
                                    value={team.parentId ? team.parentId : -1}>
                                    <option value={-1}>- {i18n`None`} -</option>
                                    {this.getParentTeams().map(
                                        (team, index) => (
                                            <option key={index} value={team.id}>
                                                {team.name}
                                            </option>
                                        )
                                    )}
                                </select>
                            )}
                        </WidgetBody>

                        <WidgetBody>
                            <MemberList
                                members={this.getMembers()}
                                remove={this.removeTeamMember}
                                add={this.addTeamMembers}
                                filter={this.filterTeamMembers}
                                availableUsers={availableUsers}
                                query={query}
                            />
                        </WidgetBody>

                        <WidgetFooter>
                            <Link
                                to={route.createLink('manage-teams', {
                                    customerId: params.customerId,
                                })}>
                                <Button theme="blue" variant="text">
                                    {i18n`Back`}
                                </Button>
                            </Link>

                            <div className="team-editor-actions pull-right">
                                {team.id && (
                                    <ClickDropdown
                                        placement="top left"
                                        width={180}
                                        align="right"
                                        offset={20}
                                        content={this.renderMoreOptions()}>
                                        <Button
                                            className="mr-2"
                                            theme="gray"
                                            variant="outline"
                                            icon="far fa-ellipsis-h">
                                            {i18n('More')}
                                        </Button>
                                    </ClickDropdown>
                                )}

                                <Button
                                    theme="blue"
                                    onClick={
                                        team.id
                                            ? this.saveChanges
                                            : this.createTeam
                                    }>
                                    {team.id
                                        ? i18n`Save changes`
                                        : i18n`Create new team`}
                                </Button>
                            </div>
                        </WidgetFooter>
                    </Widget>
                )}
            </div>
        );
    }
}
