import createReducer from 'create-reducer';
import produce from 'immer';

// Types
import { CustomerState } from './types';
import { CustomerEntity as Customer } from 'types/Customer';
import { Languages } from 'Language/Constants';

// Action Types
export const CUSTOMER_UPDATED = 'CUSTOMER_UPDATED';
export const CUSTOMER_RESET_STATE = 'CUSTOMER_RESET_STATE';

export const CUSTOMER_FETCH_REQUEST = 'CUSTOMER_FETCH_REQUEST';
export const CUSTOMER_FETCH_SUCCESS = 'CUSTOMER_FETCH_SUCCESS';
export const CUSTOMER_FETCH_FAILURE = 'CUSTOMER_FETCH_FAILURE';

export const CUSTOMER_UPDATE_REQUEST = 'CUSTOMER_UPDATE_REQUEST';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_ERROR = 'CUSTOMER_UPDATE_ERROR';

// Initial state
const customerInitialState: CustomerState = {
    data: {
        accessControl: false,
        active: false,
        address: '',
        adminAccess: false,
        allowedSigningMethods: [],
        allowedSimpleSigningMethods: [],
        azureADTenantId: '',
        caseFileDeletionInterval: 0,
        caseFileExpiryInterval: 0,
        caseFileForceDeletionInterval: 0,
        city: '',
        domain: '',
        id: 0,
        language: Languages.EN,
        name: '',
        overrideTemplates: false,
        pipedriveId: 0,
        status: 0,
        temporalStorageDefault: false,
        transferOwnershipOnUserDelete: false,
        usersCanStoreContacts: false,
        vatin: '',
        zip: '',
    },
    isFetching: false,
    isLoaded: false,
    error: null,
};

// Reducer
export const customerReducer = createReducer<CustomerState>(
    customerInitialState,
    {
        [CUSTOMER_RESET_STATE]: () => ({
            ...customerInitialState,
        }),
        [CUSTOMER_UPDATED]: (state, { property, value }) =>
            produce(state, (draftState) => {
                draftState.data[property] = value;
            }),
        [CUSTOMER_FETCH_REQUEST]: (state) =>
            produce(state, (draftState) => {
                draftState.isFetching = true;
            }),
        [CUSTOMER_FETCH_SUCCESS]: (state, customer: Customer) =>
            produce(state, (draftState) => {
                draftState.data = customer;
                draftState.isFetching = false;
            }),
        [CUSTOMER_FETCH_FAILURE]: (state, { error }) =>
            produce(state, (draftState) => {
                draftState.isFetching = false;
                draftState.error = error;
            }),
        [CUSTOMER_UPDATE_REQUEST]: (state) =>
            produce(state, (draftState) => {
                draftState.isFetching = true;
            }),
        [CUSTOMER_UPDATE_SUCCESS]: (state, customer: Customer) =>
            produce(state, (draftState) => {
                draftState.data = customer;
                draftState.isFetching = false;
            }),
        [CUSTOMER_UPDATE_ERROR]: (state, { error }) =>
            produce(state, (draftState) => {
                draftState.isFetching = false;
                draftState.error = error;
            }),
    }
);
