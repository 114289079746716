import UIButton from 'Common/components/Vega/Button';
import { i18n } from 'Language';
import React from 'react';
import {
    UIModalContainer,
    UIModalFooter,
    UIModalHeader,
    UIModalOverlay,
} from 'Common/components/Vega/Modal';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

type Props = {
    savingAsDraft: boolean;
    handleCloseModal: () => void;
    saveCasefileDraft: () => void;
};

const CasefileUploadInProgressModal = ({
    savingAsDraft,
    handleCloseModal,
    saveCasefileDraft,
}: Props) => (
    <UIModalOverlay onClose={handleCloseModal} zIndex="higher">
        <UIModalContainer>
            <UIModalHeader>
                <div slot="icon">
                    <ExclamationTriangleIcon className="h-6 fill-warning-700" />
                </div>

                <span slot="title">{i18n`modalUploadInProgress.title`}</span>
                <span slot="subtitle">
                    {i18n`modalUploadInProgress.subtitle`}
                </span>
            </UIModalHeader>
            <UIModalFooter>
                <UIButton
                    slot="secondaryButton"
                    onClick={() => handleCloseModal()}
                    priority="tertiary"
                    type="neutral"
                    isDisabled={savingAsDraft}>
                    {i18n`modalUploadInProgress.cancelButton`}
                </UIButton>
                <UIButton
                    slot="primaryButton"
                    onClick={() => saveCasefileDraft()}
                    priority="primary"
                    type="warning"
                    isLoading={savingAsDraft}>
                    {i18n`modalUploadInProgress.continueButton`}
                </UIButton>
            </UIModalFooter>
        </UIModalContainer>
    </UIModalOverlay>
);

export default CasefileUploadInProgressModal;
