import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { i18n, LanguageDropdown } from 'Language';
import { FolderItem, FolderState } from 'Common/redux/Folder/types';
import { ReduxState, AppDispatch } from 'Store';
import FolderSelector from 'Common/components/FolderSelector';
import { Languages } from 'Language/Constants';
import Checkbox from 'Common/components/Checkbox/index';
import { TextInput } from 'Common/components';

import './registered-letter-details.scss';

import {
    folderUpdated,
    titleUpdated,
    sensitiveDataToggled,
} from 'RegisteredLetter/redux/reducer';

export type Props = {
    dispatch: AppDispatch;
    folderId: null | number;
    folders: FolderState;
    language: string;
    onLanguageChange: (language: Languages) => void;
    sensitiveData: boolean;
    title: string;
};

export function RegisteredLetterDetails(props: Props) {
    const {
        folderId,
        folders,
        language,
        onLanguageChange,
        sensitiveData,
        title,
    } = props;

    useEffect(() => {
        if (!folderId && folders.defaultFolder) {
            handleFolderChange(folders.defaultFolder);
        }
    }, [props.folders]);

    const handleFolderChange = (folder: FolderItem) => {
        props.dispatch(folderUpdated(folder.id));
    };

    const handleTitleChange = (title: string) => {
        props.dispatch(titleUpdated(title));
    };

    const handleToggleSensitiveData = () => {
        props.dispatch(sensitiveDataToggled());
    };

    return (
        <div className="registered-letter-details">
            <div className="columns mb">
                <div className="column-2">
                    <TextInput
                        label={i18n`Title`}
                        placeholder={i18n`Enter a title`}
                        required={true}
                        value={title}
                        onChange={handleTitleChange}
                    />
                </div>
                <div className="column-2">
                    <FolderSelector
                        label={i18n`Select a folder`}
                        folders={folders}
                        value={folderId}
                        onChange={handleFolderChange}
                    />
                </div>
            </div>
            <div className="columns">
                <div className="column-2">
                    <label>{i18n('Language')}</label>
                    <LanguageDropdown
                        type="standalone"
                        name="language"
                        value={language}
                        skipInitCallback={true}
                        callback={onLanguageChange}
                    />
                </div>
                <div className="column-2">
                    <label>{i18n`Security`}</label>
                    <Checkbox
                        className="security-checkbox"
                        onChange={handleToggleSensitiveData}
                        label={i18n`This case contains sensitive information and require recipient validation before reading`}
                        name="registered-letter-security"
                        checked={sensitiveData}
                    />
                </div>
            </div>
        </div>
    );
}

export default connect((state: ReduxState) => ({
    folderId: state.registeredLetter.instance.folderId,
    folders: state.folders,
    language: state.registeredLetter.instance.language,
    sensitiveData: state.registeredLetter.instance.sensitiveData,
    title: state.registeredLetter.instance.title,
}))(RegisteredLetterDetails);
