import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import DocumentActions from '../../actions/DocumentActionCreators';

export default class DocumentOptions extends React.Component {
    static propTypes = {
        documentType: PropTypes.object,
    };

    static defaultProps = {
        documentType: {},
    };

    updateDocumentOptions = (event) => {
        let { value } = event.target;
        let { id } = this.props.documentType;

        DocumentActions.updateDocumentOptions(id, value);
    };

    render() {
        let { documentType } = this.props;

        let { opts } = documentType;

        if (!opts) {
            return false;
        }

        // Only use first option.
        let option = opts[0];

        return (
            <div className="casefile-row-form">
                <div className="fieldset">
                    <div className="summary-legend">{i18n('Options')}</div>
                    <div className="casefile-document-options">
                        <h4 style={{ marginTop: 0 }}>
                            {i18n`What is the minimum number of board members
                                that should sign this case file?`}
                        </h4>

                        <div>
                            {i18n`At least ${(
                                <input
                                    style={{ width: '70px' }}
                                    type="number"
                                    value={option.value}
                                    onChange={this.updateDocumentOptions}
                                    min="0"
                                />
                            )} board members`}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
