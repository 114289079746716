import { AppThunk } from 'Store';
import { AuthAPI } from 'Api';

import {
    USER_PROFILES_FETCH_REQUEST,
    USER_PROFILES_FETCH_SUCCESS,
    USER_PROFILES_FETCH_FAILURE,
} from './reducer';

// Actions

export const fetchProfiles = (): AppThunk => async (dispatch) => {
    dispatch({ type: USER_PROFILES_FETCH_REQUEST });

    try {
        const profiles = await AuthAPI.get('/profiles');

        dispatch({
            type: USER_PROFILES_FETCH_SUCCESS,
            payload: profiles,
        });
    } catch (error) {
        dispatch({
            type: USER_PROFILES_FETCH_FAILURE,
            payload: error,
        });
    }
};
