import { StateType } from 'ItsmeQES/redux/reducer';

export const ITSME_INVALID_STATE: ItsmeErrorType = {
    message: 'There was a problem processing your request',
    details:
        "Cannot process intent. Itsme state couldn't be read from local storage",
};

export const ITSME_MISSING_RETURN_PARAMS: ItsmeErrorType = {
    message: 'There was a problem processing your request',
    details: 'Cannot process intent. Missing return parameters',
};

export type ItsmeErrorType = {
    headers?: string;
    message?: string;
    details?: string;
    type?: string;
    showResponse?: boolean;
};

/**
 * We should be protected against invalid state errors by our type system, so we should never see this in practice.
 * It's OK, in this case, if they're not translated.
 */
export const invalidState = (
    found: StateType | undefined,
    whereItHappened: string,
    expected: StateType | null = null
): ItsmeErrorType => {
    const expectedMessage = expected ? ` Expected ${expected}.` : '';

    return {
        type: '/penneo-error/fe-webapp/itsmeQES/invalidState',
        message: 'Invalid internal state',
        details:
            `Invalid state (${found || 'unknown'}) while ${whereItHappened}.` +
            expectedMessage,
    };
};

export const getErrorDetails = (type: string): ItsmeErrorType => {
    switch (type) {
        case 'penneo-error/api-sign/unknown_exception':
            return {
                showResponse: true,
            };

        default:
            return { showResponse: false };
    }
};
