/**
 * Tries to use the native `Element.scroll` method to perform a smooth scroll,
 * otherwise fall backs to just amending the element's `scrollTop` value
 */
export default function scrollTo(elem: string, top: number) {
    const $elem = document.querySelector(elem) as HTMLElement;

    try {
        $elem.scroll({ top, behavior: 'smooth' });
    } catch (e) {
        $elem.scrollTop = top;
    }
}
