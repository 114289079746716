import { isFirefox } from 'Common/utils/browser';
import React from 'react';

type Props = {
    pdfUrl: string;
    iframe?: boolean;
};

/**
 * Generic native PDF embed object.
 * NOTE: we can choose to render the PDF in an iframe, as it fixes
 * an issue where the <object> won't render the PDF in Safari when it's
 * base64 encoded
 */
export const NativePdfEmbed = ({ pdfUrl, iframe }: Props) => {
    const embedData = `${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`;

    /**
     * EDGE CASE:
     * Latest versions of Firefox auto-download PDFs if inside an iframe or a new tab.
     * (Read more:
     * https://connect.mozilla.org/t5/discussions/firefox-now-saves-files-that-i-only-wanted-to-open/m-p/2607)
     * Other modern browsers are okay with this approach.
     */
    return (
        <div className="document-preview-pdf-canvas native">
            {iframe && !isFirefox() ? (
                <iframe
                    className="pdf-iframe"
                    src={embedData}
                    width="100%"
                    height="100%"
                />
            ) : (
                <object
                    type="application/pdf"
                    data={embedData}
                    width="100%"
                    height="100%">
                    <embed
                        width="100%"
                        height="100%"
                        src={embedData}
                        type="application/pdf"
                    />
                </object>
            )}
        </div>
    );
};
