import { Intent } from 'OpenID/Constants';
import type { ManagedSettings } from 'Settings/redux/types';
import { InsecureSigningMethod } from 'types/Signer';

/**
 * Legal or natural person identifier types.
 * Related to signer's "SSN type", from the API.
 */
export enum PersonIdentifierType {
    /** Det centrale personregister */
    DanishCPR = 'dk:cpr',

    /** Personal identity number */
    SwedishPIN = 'se:pin',

    /** National identity number */
    NorwegianNIN = 'no:nin',

    /** Personal identity code */
    FinnishPIC = 'fi:pic',

    /** National register number */
    BelgianNRN = 'be:nrn',

    /**
     * VAT identification number.
     *
     * Note: unfortunately, the API uses this value *specifically* for danish CVR numbers, not just VATINs.
     */
    VATIN = 'vatin',

    /** Phone number **/
    SMS = 'sms',

    /**
     * Special value that used to be the default, but is now a catchall.
     */
    Legacy = 'legacy',
}

/**
 * Use this to provide some nice defaults to your EID
 */
export const eidDefaults = {
    /**
     * Most EIDs have type === credentialType, use this to have that automatically
     */
    credentialType(): string {
        const type = (this as any).type;

        if (type === undefined) {
            // This should not happen because the EIDMethod type has a required type property
            throw new Error('You have to add type on your object');
        }

        return type;
    },
    /**
     * As a default, we assume EIDs can't identify using a NIN, this can be overwritten when necessary.
     */
    canIdentifyBy: (_: PersonIdentifierType) => false,
    canSetupUser(): boolean {
        return (this as EIDMethod).login;
    },
};

/**
 * Convenience function to be used with EIDMethod.canIdentifyBy.
 */
export const identifiesBy = (supportedTypes: PersonIdentifierType[]) => {
    return (expectedType: PersonIdentifierType) => {
        return supportedTypes.includes(expectedType);
    };
};

export type AvailabilityFunction = () => boolean;
export type AvailabilityFunctionWithSettings = (
    managedSettings?: ManagedSettings
) => boolean;
export type AvailabilityValue =
    | AvailabilityFunction
    | AvailabilityFunctionWithSettings
    | 'use-experimental-eid-flag-flag';

export type AvailabilityType =
    | 'configureForLogin'
    | 'configureForSign'
    | 'idValidation'
    | 'login'
    | 'setupUser'
    | 'sign';

export type EIDMethodAvailability = {
    [key in AvailabilityType]?: AvailabilityValue;
};

export type EIDMethod = {
    type: string;
    /**
     * Use this field for when the credential type name is different from the type.
     *
     * For example, the original BankID SE credential type was 'bankid_se'. When moving to an OpenID implementation,
     * the new EID method had a type of 'bankid.se', but an override of 'bankid_se'. This was to make all existing
     * bankid_se credentials work properly with the new EID provider.
     */
    credentialType: () => string;
    /**
     * Returns true if the specific method can identify a legal or physical person by ALL the identifiers specified.
     */
    canIdentifyBy: (idType: PersonIdentifierType) => boolean;
    title: string;
    extendedTitle?: string;
    /**
     * Whether the method can be used for logging in
     */
    login: boolean;
    /**
     * Whether the method can be used for signing documents
     */
    sign: boolean;
    /**
     * Whether the method can be used for setting up new users
     */
    canSetupUser: () => boolean;
    /**
     * @deprecated Meant to be used only for 'bankid_se' and 'bankid_no', as
     * they are sometimes returned as 'bankidse' and 'bankidno'
     */
    typeAlias?: string;
    logo?: string;
    flag?: string;
    newFlag?: string;
    /**
     * Whether the method uses the OpenID Connect protocol
     */
    openid?: boolean;
    paths?: {
        login: string;
        activate?: string;
        validate_id?: string;
        add: string;
    };
    /**
     * Determine if the EID method should be shown or hidden in certain situations or for certain people.
     *
     * Bear in mind that EIDs are used in situations where users are not (yet) logged in, so we may not be able to use
     * LaunchDarkly flags. Use a function in those cases.
     *
     * Not setting these will make the EID always available.
     */
    availability?: EIDMethodAvailability;
};

/**
 * An EID platform that can be used for signing documents.
 */
export type SigningMethod = EIDMethod & {
    sign: true;
    /**
     * This property is to display additional information on this signing method
     * fx. tooltip information when creating a new casefile or custom name for the signing page.
     * (Currently only used in insecure signing methods.)
     */
    extendedInfo?: { title?: string; tooltip?: string };

    configurationInfo?: {
        logo: string;
        title?: string;
        tooltip?: string;
        description?: string;
    };
};

/**
 * An EID platform that can be used for logging in.
 */
export type LoginMethod = EIDMethod & {
    login: true;
    logo: string;
};

/**
 * And EID platform based on the OpenID Connect protocol
 */
export type OpenIDMethod<T extends EIDMethod = EIDMethod> = T & {
    openid: true;
    /**
     * If present, it replaces the `type` property in the OpenID endpoints url
     *
     * @example
     *   {
     *     type: 'itsme.be'
     *     openid: true
     *   }
     *   // results in -> /auth/api/v1/openid/itsme.be/init
     *
     *   {
     *     type: 'itsme.be'
     *     openid: true
     *     openidUrlId: 'itsme.be.v2'
     *     // or
     *     openidUrlId: (intent) => 'itsme.be.v2'
     *   }
     *   // results in -> /auth/api/v1/openid/itsme.be.v2/init
     */
    openidUrlId?: string | ((intent: Intent) => string);
};

/**
 * An EID platform that can be used both for logging in and for signing documents.
 */
export type CompleteEIDMethod = SigningMethod & LoginMethod;

export const isLoginMethod = (method: EIDMethod): method is LoginMethod =>
    method.login === true && typeof method.logo === 'string';

export const isSigningMethod = (method: EIDMethod): method is SigningMethod =>
    method.sign === true;

export const isOpenIDMethod = <T extends EIDMethod>(
    method: T
): method is OpenIDMethod<T> => method.openid === true;

/**
 * List of all insecure signing methods available to choose from by default
 */
export const insecureSigningMethodsDefault: InsecureSigningMethod[] = [
    'text',
    'draw',
    'image',
];
