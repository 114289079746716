import UITooltip from 'Common/components/Vega/Tooltip';
import React from 'react';

export const getFileExtension = (fileName: string) =>
    fileName?.slice(fileName.lastIndexOf('.')) || '';

export const collapseText = (text: string, limit = 35) => {
    const lastDotIndex = text.lastIndexOf('.');
    const hasExtension = lastDotIndex > 0;
    const elipsis = '... ';
    const fileName = hasExtension ? text.slice(0, lastDotIndex) : text;
    const extension = hasExtension ? text.slice(lastDotIndex) : '';

    const base = {
        full: text,
        fileName,
        extension,
        collapsed: '',
    };

    if (text.length <= limit) {
        return hasExtension ? base : { ...base, extension: undefined };
    }

    const collapsedText = hasExtension
        ? fileName.slice(0, limit - elipsis.length - extension.length) +
          elipsis +
          extension
        : text.slice(0, limit - elipsis.length) + elipsis;

    return {
        ...base,
        collapsed: collapsedText,
        extension: hasExtension ? extension : undefined,
    };
};

type RenderFileLabelWithTooltipProps = {
    fileName: string;
    fileNameLimit?: number;
    tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
    tooltipOffset?: number;
};

export const renderFileLabelWithTooltip = ({
    fileName,
    fileNameLimit = 35,
    tooltipPlacement = 'top',
    tooltipOffset = 20,
}: RenderFileLabelWithTooltipProps) => {
    const label = collapseText(fileName, fileNameLimit);

    return (
        <>
            <span>{label.collapsed || label.full}</span>
            {label.collapsed && (
                <UITooltip placement={tooltipPlacement} offset={tooltipOffset}>
                    <div className="max-w-xs">{label.full} </div>
                </UITooltip>
            )}
        </>
    );
};
