import React, { ChangeEventHandler } from 'react';
import { i18n } from 'Language';
import Button from 'Common/components/Button';

export type Props = {
    username: string;
    password: string;
    updateUsername: ChangeEventHandler<HTMLInputElement>;
    updatePassword: ChangeEventHandler<HTMLInputElement>;
    login: Function;
};

export default class PasswordLoginForm extends React.Component<Props> {
    handleSubmit = (event) => {
        event.preventDefault();
        let { username, password, login } = this.props;

        login(username, password);
    };

    render() {
        let { username, password, updateUsername, updatePassword } = this.props;

        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <label htmlFor="email">{i18n('Username')}</label>

                    <div className="icon-text-input">
                        <i className="fa fa-user"></i>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder={i18n('Enter your username')}
                            onChange={updateUsername}
                            value={username}
                            required={true}
                        />
                    </div>

                    <br />

                    <label htmlFor="password">{i18n('Password')}</label>
                    <div className="icon-text-input">
                        <i className="fa fa-lock"></i>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder={i18n('Enter your password')}
                            onChange={updatePassword}
                            value={password}
                            required={true}
                        />
                    </div>

                    <div className="text-center">
                        <br />
                        {/* @note: The ID on this button is used in pingdom test for automated
                         * tests and uptime monitoring */}
                        <Button
                            id="password-login-button"
                            theme="blue"
                            fullWidth>
                            {i18n`Log in to Penneo`}
                        </Button>
                    </div>
                </form>
            </div>
        );
    }
}
