const customerUtils = {
    /**
     * @see: issue #169
     *
     * The data structure for allowedSigningMethods is being returned as an
     * object instead of arrays for some customers. This corrects the data structure
     * for this property when the customers' settings are updated, and prevents
     * the customer management view from failing due to a render problem.
     *
     */
    fixAllowedSigningMethods(
        allowedSigningMethods:
            | {
                  [key: number]: string;
              }
            | string[]
    ) {
        if (!Array.isArray(allowedSigningMethods)) {
            let _keys = Object.keys(allowedSigningMethods);
            let _methods = _keys.map((key) => allowedSigningMethods[key]);

            return _methods;
        }

        return allowedSigningMethods;
    },

    /**
     * @see: https://github.com/Penneo/fe-webapp/issues/228
     *
     * Status needs to be converted to a value accepted by the Signing Service API
     * As it stands right now, the status is returned as an integer and sent as a
     * string representing the current status of the customer's account.
     *
     */
    parseCustomerStatus(statusCode: number) {
        switch (statusCode) {
            case 0:
                return 'demo';
            case 1:
                return 'paying';
            case 2:
                return 'cancelled';
            default:
                return 'unknown';
        }
    },
};

export default customerUtils;
