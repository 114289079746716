import { SigningAPI } from 'Api/ApiClient';
import {
    RegisteredLetterDetailsPayload,
    RegisteredLetterInstance,
    RegisteredLetterRecipient,
} from 'RegisteredLetter/types';
import { UploadedDocument } from 'types/CaseFile';
import { EmailTemplate } from 'types/EmailTemplates';
import { Platforms } from 'Constants';
import { REGISTERED_LETTER_SIGNING_FLOWS } from 'RegisteredLetter/Constants';
import { getEnvironment } from 'utils';
import { PersonIdentifierType } from '../EID/types';

/**
 * This is using a hard coded list of available signing flows to determine if
 * the signing flow is a registered letter or not. This could potentially be
 * improved by determining this dynamically.
 * */
export function signingFlowIsRegisteredLetter(
    signingFlowId: number | undefined
) {
    if (!signingFlowId) {
        return false;
    }

    const environment = getEnvironment();

    return !!Object.values(REGISTERED_LETTER_SIGNING_FLOWS[environment]).find(
        (item) => {
            return signingFlowId === item;
        }
    );
}

export function transformRegLetterDetailsToPayload(
    instance: RegisteredLetterInstance
): RegisteredLetterDetailsPayload {
    const { sensitiveData, title, language } = instance;
    const environment = getEnvironment();
    const caseFileTypeId =
        REGISTERED_LETTER_SIGNING_FLOWS[environment][language];

    return {
        caseFileTypeId,
        language,
        metaData: Platforms.WEB,
        sensitiveData,
        title,
        visibilityMode: 0,
    };
}

export function transformRegLetterRecipientToPayload(
    recipient: RegisteredLetterRecipient
): {
    name: string;
    socialSecurityNumberPlain?: string;
    ssnType: string;
    storeAsContact: boolean;
} {
    const { ssn, ssnType } = recipient;

    return {
        name: recipient.name ?? '',
        storeAsContact: false,
        ssnType: ssnType ?? PersonIdentifierType.Legacy,
        socialSecurityNumberPlain: ssn ? (ssn as string) : undefined,
    };
}

export async function transformRegLetterDocsToPayload(
    caseFileId: number,
    document: UploadedDocument,
    documentOrder: number,
    documentTypeId: number
): Promise<{
    caseFileId: number;
    documentOrder: number;
    documentTypeId: number;
    fileId: number;
    pdfFile: string;
    title: string;
    type: 'attachment';
}> {
    const documentBase64 = await convertFileContentToBase64(
        document.fileContent
    );

    return {
        caseFileId,
        documentOrder,
        documentTypeId,
        fileId: document.id,
        pdfFile: documentBase64,
        title: document.name,
        type: 'attachment',
    };
}

export async function createCaseFile(
    casefileDetails: RegisteredLetterDetailsPayload
): Promise<number> {
    const { id } = await SigningAPI.post('/casefiles', casefileDetails);

    return id;
}

export async function fetchDocumentType(casefileId: number): Promise<number> {
    const response = await SigningAPI.get(
        `/casefiles/${casefileId}/documenttypes`
    );

    return response[0]?.id;
}

export async function addRecipients(
    casefileId: number,
    emailTemplate: EmailTemplate,
    recipients: RegisteredLetterRecipient[],
    sensitiveData: RegisteredLetterInstance['sensitiveData']
) {
    const promises = recipients.map(async (recipient) => {
        const payload = transformRegLetterRecipientToPayload(recipient);

        const { id: signerId } = await SigningAPI.post(
            `/casefiles/${casefileId}/signers`,
            payload
        );

        const signingRequestResponse = await SigningAPI.get(
            `/casefiles/${casefileId}/signers/${signerId}/signingrequests`
        );

        const signingRequestId = signingRequestResponse[0].id;

        const signingRequestPayload = {
            email: recipient.email,
            emailSubject: emailTemplate.subject,
            emailText: emailTemplate.message,
            accessControl: sensitiveData,
        };

        await SigningAPI.put(
            `/signingrequests/${signingRequestId}`,
            signingRequestPayload
        );
    });

    await Promise.all(promises);
}

export async function addDocuments(
    casefileId: number,
    documentTypeId: number,
    documents: UploadedDocument[]
) {
    const promises = documents.map(async (document, index) => {
        const payload = await transformRegLetterDocsToPayload(
            casefileId,
            document,
            index,
            documentTypeId
        );

        return await SigningAPI.post('/document-from-file', payload);
    });

    return Promise.all(promises);
}

export const convertFileContentToBase64 = (
    fileContent: Blob | File
): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(fileContent);
        reader.onload = () =>
            resolve(reader.result?.toString().split(',')[1] || '');
        reader.onerror = (error) => reject(error);
    });
};
