import React from 'react';
import { i18n } from 'Language';
import './status-banner.scss';
import { Status } from './statusCodes';

type Props = {
    status: Status;
    className?: string;
};

// class StatusBanner extends React.Component<Props> {
const StatusBanner = ({ status, className = '' }: Props) => {
    return (
        <div className={`casefile-status-banner ${className} ${status.color}`}>
            <span className="casefile-status-banner-name">
                {i18n(status.name)}&nbsp;
                <i className={`far fa-${status.icon}`} />
            </span>
            <span className="casefile-status-banner-details">
                {i18n(status.details)}
            </span>
        </div>
    );
};

export default StatusBanner;
