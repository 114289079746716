import PropTypes from 'prop-types';
import React from 'react';
import { TranslationActions } from 'Language';

export default class Auth extends React.Component {
    static propTypes = {
        children: PropTypes.object,
        location: PropTypes.object,
        user: PropTypes.object,
        language: PropTypes.string,
    };

    componentWillMount() {
        let { location } = this.props;

        if (!location.query) {
            return false;
        }

        if (location.query.lang) {
            TranslationActions.setLanguage(location.query.lang, {
                persist: false,
                local: false,
            });
        }
    }

    render() {
        return (
            <div className="penneo-auth">
                {React.cloneElement(this.props.children, {
                    user: this.props.user,
                    language: this.props.language,
                })}
            </div>
        );
    }
}
