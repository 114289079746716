import React from 'react';
import { SignText } from 'OpenID/redux/types';
import './openid-sign-text.scss';

type Props = {
    signText: SignText;
};

export default class OpenIDSignText extends React.Component<Props> {
    render() {
        const { signText } = this.props;

        return (
            <div className="openid-sign-text" data-testid="openid-sign-text">
                <div dangerouslySetInnerHTML={{ __html: signText.html }} />
            </div>
        );
    }
}
