/* eslint max-len:0 */
import hopscotch from 'hopscotch';
import { i18n } from 'Language';
import { TOUR_IDS, VIEWPORT_SIZES } from 'Constants';
import { TourConfig } from 'types/Tour';
import { Flags } from 'Common/LaunchDarkly';

// We need to call a function to return the tourconfigs to have the strings
// translated correctly.
const generateTourConfigs = (): TourConfig[] => [
    {
        id: TOUR_IDS.DASHBOARD,
        public: false,
        featureFlag: Flags.DASHBOARD_TOUR_ENABLED,
        [VIEWPORT_SIZES.DESKTOP]: {
            id: TOUR_IDS.DASHBOARD,
            showPrevButton: true,
            i18n: {
                nextBtn: i18n`next`,
                prevBtn: i18n`back`,
                doneBtn: i18n`done`,
            },
            steps: [
                {
                    title: i18n`Create New Case File`,
                    content: i18n`You can send documents for signature, create forms, and request identity validation`,
                    target: '#create-new-btn',
                    placement: 'right',
                    yOffset: -12,
                },
                {
                    title: i18n`Create New Case Files`,
                    content: i18n`Send documents for signing`,
                    target: '.start-work-widget-content',
                    placement: 'top',
                },
                {
                    title: i18n`Manage`,
                    content: i18n`Here you will find your folders and files`,
                    target: '#manage',
                    placement: 'right',
                    yOffset: -9,
                },
            ],
        },
    },
    {
        id: TOUR_IDS.FORM,
        public: true,
        featureFlag: Flags.FILL_FORM_TOUR_ENABLED,
        [VIEWPORT_SIZES.DESKTOP]: {
            id: TOUR_IDS.FORM,
            showPrevButton: true,
            i18n: {
                nextBtn: i18n`next`,
                prevBtn: i18n`back`,
                doneBtn: i18n`done`,
            },
            steps: [
                {
                    title: i18n`Navigate documents easily`,
                    content:
                        i18n`The pages of your document will be in the left sidebar.` +
                        ' ' +
                        i18n`You can switch between pages by clicking the pages.`,
                    target: '.document-navigator',
                    placement: 'right',
                    yOffset: 20,
                },
                {
                    title: i18n`Fill out the form in just one place`,
                    content:
                        i18n`All the information you have to fill is at hand.` +
                        ' ' +
                        i18n`Just type in this form and it will automatically fill in the document.`,
                    target: '.sidebar-content',
                    placement: 'left',
                },
                {
                    title: i18n`Document editor`,
                    content: i18n`As you type the information in the sidebar, you can watch it
                    appear in the document. You can also type the information right in the document,
                    everything is connected!`,
                    target: '.page-field-overlay',
                    placement: 'top',
                    xOffset: 150,
                    width: 300,
                    yOffset: 180,
                },
                {
                    title: i18n`Help is always close`,
                    content: i18n`You can find this guide again by clicking on "Show guide" at the top of this page`,
                    target: '#start-tour-handler',
                    placement: 'bottom',
                    xOffset: -250,
                    arrowOffset: 250,
                },
            ],
        },
        [VIEWPORT_SIZES.MOBILE]: {
            id: TOUR_IDS.FORM,
            showPrevButton: true,
            i18n: {
                nextBtn: i18n`next`,
                prevBtn: i18n`back`,
                doneBtn: i18n`done`,
            },
            steps: [
                {
                    title: i18n`Toggle Between Views`,
                    content:
                        i18n`You can switch at any time between the document and form.` +
                        ' ' +
                        i18n`The information you input will be visible in both views.`,
                    placement: 'bottom',
                    target: '.toggle-bar',
                    arrowOffset: 'center',
                    xOffset: 'center',
                    // @hack: auto scroll doesn't work properly when a nested div has its own scroll
                    onNext: function () {
                        let div = document.getElementsByClassName(
                            'form-sidebar'
                        )[0];

                        div.scrollTop = div.scrollHeight;
                    },
                },
                {
                    title: i18n`Fill The Form`,
                    content: i18n`You can fill out the information in the form and
                    preview the document at any time`,
                    placement: 'top',
                    target: '.success',
                },
                {
                    title: i18n`Help is always close`,
                    content: i18n`You can find this guide again by clicking on "Show guide" at the top of this page`,
                    target: '#start-tour-handler',
                    placement: 'bottom',
                    xOffset: -250,
                    arrowOffset: 250,
                },
            ],
        },
    },
];

function getTourConfig(tourId: TOUR_IDS) {
    const tourConfigs = generateTourConfigs();

    return tourConfigs.find((item) => item.id === tourId);
}

function startTour(tour: TourDefinition) {
    hopscotch.startTour(tour);
}

function endTour() {
    hopscotch.endTour(true);
}

const tourConfig = {
    start: startTour,
    getTourConfig: getTourConfig,
    endTour: endTour,
};

export default tourConfig;
