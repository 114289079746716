import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { Helmet } from 'react-helmet';
import WorkflowStore from '../../stores/WorkflowStore';
import FormStore from '../../stores/FormStore';
import WorkflowActions from '../../actions/WorkflowActionCreators';
import FormActions from '../../actions/FormActionCreators';
import { i18n } from 'Language';
import { Stores as AuthStores } from 'Auth';
import classnames from 'classnames';
import FormsUpsell from 'Marketing/FormsUpsell';
import Button from 'Common/components/Button';

const sortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
};

const tableHeaders = [
    {
        title: 'ID',
        name: 'id',
        width: 50,
        sortable: true,
    },
    {
        title: 'Template Name',
        name: 'title',
        width: '30%',
        sortable: true,
    },
    {
        title: 'Actions',
        name: 'actions',
        width: 50,
    },
    {
        title: 'Public link',
        name: 'link',
        width: 200,
    },
];

export default class WorkflowTemplateList extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    state = {
        workflows: [],
        forms: [],
        data: [],
        filtered: [],
        filter: '',
        sort: {
            column: 'id',
            type: sortTypes.DESC,
        },
        statuses: [],
    };

    componentDidMount() {
        WorkflowStore.addChangeListener(this.onChange);
        FormStore.addChangeListener(this.onChange);
        this.loadData();
    }

    componentWillUnmount() {
        WorkflowStore.removeChangeListener(this.onChange);
        FormStore.removeChangeListener(this.onChange);
    }

    loadData = () => {
        // // only public_form workflows should be shown in the list.
        // const _stateMachine = "prefilled_form";

        // // @note: this naming pattern doesn't match the backend convention, thus the linter
        // // is disabled for the camelcase rule on the state_machine key.
        // let filter = {
        //     state_machine: _stateMachine /* eslint camelcase: 0 */
        // };

        // WorkflowStore.loadWorkflowPrototypes(filter);
        WorkflowStore.loadWorkflowPrototypes();
        FormStore.loadFormPrototypes();
    };

    onChange = () => {
        this.setState({
            workflows: WorkflowStore.getWorkflowPrototypes(),
            forms: FormStore.getFormPrototypes(),
        });

        let { workflows } = this.state;

        if (workflows.length === 0) {
            return;
        }

        let data = this.getData(workflows);

        this.setState({
            data: data,
            filtered: this._filterData(this.state.filter, data),
        });
    };

    getData = (data) => {
        let results = [];

        for (let i = 0; i < data.length; i++) {
            let workflow = data[i];
            let row = {
                id: workflow.id,
                title: workflow.title,
                token: workflow.token,
                formId: workflow.userData.initial.form.prototypeId,
            };

            results.push(row);
        }

        return results;
    };

    _filterData = (filter, data) => {
        if (filter === '') {
            return [];
        }

        let term = filter.toLowerCase();

        let filteredData = [];

        for (let i = 0; i < data.length; i++) {
            for (let column in data[i]) {
                if (!data[i][column]) {
                    continue;
                }

                if (
                    data[i][column].toString().toLowerCase().indexOf(term) !==
                    -1
                ) {
                    filteredData.push(data[i]);
                    break;
                }
            }
        }

        return filteredData;
    };

    _onFilterChange = (e) => {
        let filterBy = e.target.value.toLowerCase();

        if (filterBy.length === 0) {
            return this.setState({
                filter: '',
                filtered: [],
            });
        }

        let filteredData = this._filterData(filterBy, this.state.data);

        this.setState({
            filter: filterBy,
            filtered: filteredData,
        });
    };

    sortTable = (rows, sort) => {
        // console.log(sort);
        let sortArray = rows.slice();

        let key = sort.column;
        let dir = sort.type;

        sortArray.sort((indexA, indexB) => {
            let valueA = indexA[key];
            let valueB = indexB[key];
            let sortVal = 0;

            if (valueA < valueB) {
                sortVal = 1;
            }

            if (valueA > valueB) {
                sortVal = -1;
            }

            if (sortVal !== 0 && dir === sortTypes.ASC) {
                sortVal *= -1;
            }

            return sortVal;
        });

        return sortArray;
    };

    changeSortHandler = (header) => {
        let { sort } = this.state;

        if (sort.column === header.name) {
            if (sort.type === sortTypes.ASC) {
                sort.type = sortTypes.DESC;
            } else {
                sort.type = sortTypes.ASC;
            }

            return this.setState({ sort: sort });
        }

        this.setState({
            sort: {
                column: header.name,
                type: sortTypes.ASC,
            },
        });
    };

    /**
     * Creates a link to the workflow instance redirection, with the token to allow
     * an instance of the workflow to be created.
     * @return {string} URL to workflow instance redirection view.
     */
    createLink = (formId, workflowToken) => {
        let formToken = this.getFormToken(formId);

        if (!workflowToken || !formToken) {
            return '';
        }

        const route = 'public-form';

        // @todo: Make Dynamic
        const groupName = 'forms';

        let { router } = this.context;

        let path = router.createHref({
            name: route,
            params: {
                workflowToken: workflowToken,
                formToken: formToken,
            },
            query: {
                group: groupName,
            },
        });

        let { protocol, host } = window.location;

        return protocol + '//' + host + path;
    };

    getFormToken = (formId) => {
        let form = FormStore.getFormById(formId);

        if (form) {
            return form.token;
        }

        return false;
    };

    handlePublish = (workflowId, formId, event) => {
        event.preventDefault();

        WorkflowActions.publish(workflowId);
        FormActions.publish(formId);
    };

    handleUnpublish = (workflowId, event) => {
        event.preventDefault();
        WorkflowActions.unpublish(workflowId);
    };

    render() {
        const { customer } = this.props;
        let { data, filter, filtered, sort } = this.state;

        let tableData = filtered.length === 0 ? data : filtered;

        if (filter.length > 0) {
            tableData = filtered;
        }

        let rows = this.sortTable(tableData, sort);
        let user = AuthStores.UserStore.getCurrentUser();

        const { active: isCustomerActive } = customer;

        // If user doesn't have access to forms.
        if (user.rights && user.rights.indexOf('mass-send') === -1) {
            return <FormsUpsell />;
        }

        return (
            <div className="white-container workflow-templates-list ui-v2">
                <Helmet>
                    <title>{i18n`Form templates`}</title>
                </Helmet>
                <h4 className="title">{i18n('Form templates')}</h4>
                <div className="content">
                    <div className="container-header pb0">
                        <div className={'inline-block'}>
                            <span className="search-bar">
                                <input
                                    type="text"
                                    style={{ width: 200 }}
                                    onChange={this._onFilterChange}
                                    placeholder={i18n('Type to filter')}
                                />
                            </span>
                            <span className="text-bold pl">
                                {rows.length === 1 ? (
                                    <span>
                                        {rows.length} {i18n('template')}
                                    </span>
                                ) : (
                                    <span>
                                        {rows.length} {i18n('templates')}
                                    </span>
                                )}
                            </span>
                        </div>
                        <Link className="pull-left" to="template-creator">
                            <Button
                                icon="far fa-plus"
                                theme="blue"
                                variant="outline"
                                disabled={!isCustomerActive}
                                renderIconLeft={true}>
                                {i18n`Create new template`}
                            </Button>
                        </Link>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                {tableHeaders.map((header, index) => (
                                    <th
                                        key={index}
                                        className={classnames({
                                            sortable: header.sortable,
                                            'sort-ascending':
                                                header.name === sort.column &&
                                                sort.type === 'ASC',
                                            'sort-descending':
                                                header.name === sort.column &&
                                                sort.type === 'DESC',
                                        })}
                                        width={header.width}
                                        onClick={
                                            header.sortable
                                                ? this.changeSortHandler.bind(
                                                      this,
                                                      header
                                                  )
                                                : null
                                        }>
                                        <span>
                                            {/* {header.name === sort.column ?
                                                <i className={sort.type === "ASC" ?
                                                    "sort-ascending" :
                                                    "sort-descending"}>
                                                </i>
                                                :
                                                header.sortable &&
                                                    <i className="fa fa-sort"></i>
                                            } */}
                                            {/* &nbsp;&nbsp; */}
                                            {i18n(header.title)}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row) => (
                                <tr key={row.id}>
                                    <td>{row.id}</td>
                                    <td>
                                        <span>
                                            <Link
                                                to={{
                                                    name:
                                                        'workflow-template-details',
                                                    params: {
                                                        workflowId: row.id,
                                                    },
                                                }}>
                                                <i className="fa fa-cog fa-lg"></i>
                                            </Link>
                                            &nbsp;&nbsp;&nbsp;
                                        </span>

                                        {i18n(row.title)}
                                    </td>
                                    <td>
                                        {!row.token ? (
                                            <Button
                                                theme="blue"
                                                variant="text"
                                                onClick={this.handlePublish.bind(
                                                    this,
                                                    row.id,
                                                    row.formId
                                                )}>
                                                {i18n('Make public')}
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={this.handleUnpublish.bind(
                                                    this,
                                                    row.id
                                                )}
                                                theme="gray"
                                                variant="text">
                                                {i18n('Revoke access')}
                                            </Button>
                                        )}
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="match-parent"
                                            value={this.createLink(
                                                row.formId,
                                                row.token
                                            )}
                                            readOnly={true}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
