import { Dispatcher } from 'Core';
import { SigningAPI } from 'Api';

const integrationActionCreators = {
    fetchCredentials() {
        SigningAPI.get('/user/credentials/api')
            .then((credentials) => {
                Dispatcher.handleServerAction({
                    type: 'FETCH_INTEGRATION_CREDENTIALS_SUCCESS',
                    credentials: credentials,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: 'FETCH_INTEGRATION_CREDENTIALS_ERROR',
                    error: error,
                });
            });
    },

    updateCredentials() {
        SigningAPI.patch('/user/credentials/api')
            .then((credentials) => {
                Dispatcher.handleServerAction({
                    type: 'UPDATE_INTEGRATION_CREDENTIALS_SUCCESS',
                    credentials: credentials,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: 'UPDATE_INTEGRATION_CREDENTIALS_ERROR',
                    error: error,
                });
            });
    },

    createCredentials() {
        SigningAPI.post('/user/credentials/api')
            .then((credentials) => {
                Dispatcher.handleServerAction({
                    type: 'CREATE_INTEGRATION_CREDENTIALS_SUCCESS',
                    credentials: credentials,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: 'CREATE_INTEGRATION_CREDENTIALS_ERROR',
                    error: error,
                });
            });
    },

    generateCredentials(credentials) {
        let credentialsRequest = this.createCredentials;

        if (credentials) {
            credentialsRequest = this.updateCredentials;
        }

        // Reset credentials activation status when a new set of credentials is requested.
        this.setActivationStatus(false).then(() => {
            credentialsRequest();
        });
    },

    setActivationStatus(status = true) {
        let payload = {
            preferences: {
                apiCredentialsConfirmed: status,
            },
        };

        return SigningAPI.get('/user/preferences/activation')
            .then(() => {
                return SigningAPI.put(
                    '/user/preferences/activation',
                    payload
                ).then(() => {
                    Dispatcher.handleServerAction({
                        type: 'USER_CONFIRMED_ACTIVATION',
                        activationConfirmed: status,
                    });
                });
            })
            .catch(() => {
                return SigningAPI.post(
                    '/user/preferences/activation',
                    payload
                ).then(() => {
                    Dispatcher.handleServerAction({
                        type: 'USER_CONFIRMED_ACTIVATION',
                        activationConfirmed: status,
                    });
                });
            });
    },

    fetchActivationStatus() {
        SigningAPI.get('/user/preferences/activation')
            .then((response) => {
                Dispatcher.handleServerAction({
                    type: 'USER_CONFIRMED_ACTIVATION',
                    activationConfirmed:
                        response.preferences.apiCredentialsConfirmed,
                });
            })
            .catch(() => {
                Dispatcher.handleServerAction({
                    type: 'USER_CONFIRMED_ACTIVATION',
                    activationConfirmed: false,
                });
            });
    },
};

export default integrationActionCreators;
