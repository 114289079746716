import React from 'react';
import AuthStore from '../../stores/AuthStore';

import PasswordStore from '../../stores/PasswordStore';
import PasswordActions from '../../actions/PasswordActionCreators';

import PasswordLoginForm from './PasswordLoginForm';
import { translate, i18n } from 'Language';

export default class PasswordLoginWidget extends React.Component {
    state = {
        username: '',
        password: '',
    };

    componentWillMount() {
        AuthStore.clearState();
    }

    componentDidMount() {
        AuthStore.addChangeListener(this.onChange);
        PasswordStore.addChangeListener(this.onChange);
        this.mounted = true;
    }

    componentWillUnmount() {
        AuthStore.removeChangeListener(this.onChange);
        PasswordStore.removeChangeListener(this.onChange);
        this.mounted = false;
    }

    onChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            isFetching: AuthStore.isFetching(),
            isAuthenticated: AuthStore.isAuthenticated(),
            authError: AuthStore.getAuthError(),
            username: PasswordStore.getCredentials().username || '',
            password: PasswordStore.getCredentials().password || '',
        });
    };

    updatePassword = (event) => {
        let { value } = event.target;

        PasswordActions.updatePassword(value);
    };

    updateUsername = (event) => {
        let { value } = event.target;

        PasswordActions.updateUsername(value);
    };

    login = (username, password) => {
        PasswordActions.login(username, password);
    };

    render() {
        let {
            username,
            password,
            isFetching,
            isAuthenticated,
            authError,
        } = this.state;

        return (
            <div className="password-login-widget">
                <h4>{i18n`Log in with your Penneo account details`}</h4>
                {authError && (
                    <div className="error-message">
                        <div className="message-content">
                            <p style={{ fontWeight: 'bold' }}>
                                {translate(
                                    'There was an error logging in, please try again'
                                )}
                            </p>
                            <p>
                                {!!authError.data &&
                                    !!authError.data.error &&
                                    authError.data.error.message}
                            </p>
                        </div>
                    </div>
                )}

                {(isFetching || isAuthenticated) && (
                    <div>
                        <br />
                        <br />
                        <div className="spinner">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </div>
                )}

                {!isFetching && !isAuthenticated && (
                    <PasswordLoginForm
                        username={username}
                        password={password}
                        login={this.login}
                        updateUsername={this.updateUsername}
                        updatePassword={this.updatePassword}
                    />
                )}
            </div>
        );
    }
}
