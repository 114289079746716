import PropTypes from 'prop-types';
import React from 'react';
import assign from 'object-assign';
import lodash from 'lodash';
import uniqid from 'uniqid';
import produce from 'immer';

import Constants from 'Forms/Constants';

import { AuthStore } from 'Auth';

// Components
import FormBuilder from './FormBuilder';

// Forms
import FormStore from '../../stores/FormStore';
import FormActions from '../../actions/FormActionCreators';

// Workflows
import WorkflowActions from '../../actions/WorkflowActionCreators';
import WorkflowStore from '../../stores/WorkflowStore';

// Message Templates
import MessageTemplateStore from '../../stores/MessageTemplateStore';
import MessageTemplateActions from '../../actions/MessageTemplateActionCreators';
// Common
import Analytics from 'Common/Analytics';
// Redux
import { connect } from 'react-redux';
import { fetchFolders } from 'Common/redux/Folder/actions';
import { requestUserList } from 'Casefiles/redux/contacts/actions';

const stateMachines = [
    {
        id: 'prefilled_form',
        title: 'Public Form',
    },
    {
        id: 'simple_form',
        title: 'CSV Form',
    },
];

let getFormTemplate = () => {
    return { title: null };
};

let getWorkflowTemplate = (user) => {
    return {
        title: null,
        prototype: true,
        stateMachine: stateMachines[0].id, // @todo: make dynamic
        language: 'en',
        userData: {
            initial: {
                form: {
                    fields: {
                        placeholder: 'placeholder',
                    },
                    prototypeId: null,
                },
                caseFile: {
                    title: null,
                    folderId: null,
                    document: {
                        title: null,
                    },
                    sensitiveData: false,
                    enableInsecureSigning: false,
                    signers: [
                        {
                            name: 'placeholder',
                            email: 'placeholder@penneo.com',
                        },
                    ],
                },
                emails: {
                    templateId: null,
                    coSignerTemplateId: null,
                    sender: {
                        name: user.fullName,
                        email: user.email,
                    },
                },
            },
        },
        settings: {
            allowCoSigner: false,
            requireCoSigner: false,
            allowAttachment: false,
            requireAttachment: false,
        },
    };
};

let getFieldsTemplate = () => {
    return Constants.PROTECTED_FIELDS.slice().map((field) => {
        field._id = uniqid();

        return field;
    });
};

export class FormBuilderController extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static propTypes = {
        user: PropTypes.object,
        folders: PropTypes.object,
        params: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        const { user } = props;
        const { workflowId, formId } = props.params;
        const isEdit = workflowId && formId;

        this.state = {
            edit: isEdit,
            workflow: null,
            form: null,
            fields: null,
            deletedFields: [],
            formDocument: {},
            file: null,
            messageTemplates: [],
            showFolderWarning: false,
            ownerId: !isEdit ? user.id : undefined,
        };
    }

    componentWillMount() {
        // Clear previous state
        WorkflowStore.clearStore();
        FormStore.clearStore();
        MessageTemplateStore.clearStore();
    }

    async componentDidMount() {
        const {
            ActionTypes: { WORKFLOW_PROTOTYPE_LOADED },
        } = Constants;

        FormStore.addChangeListener(this.onFormChange);
        WorkflowStore.addEventListener(
            WORKFLOW_PROTOTYPE_LOADED,
            this.onWorkflowChange
        );
        MessageTemplateStore.addChangeListener(this.onTemplateChange);

        const { edit } = this.state;
        const { dispatch } = this.props;

        // If component is in editing mode, fetch server data. else, load default data.
        if (edit) {
            let { workflowId, formId } = this.props.params;

            //  Attach Listeners on Update
            FormStore.addEventListener(
                'FORM_PROTOTYPE_UPDATED',
                this.onFormSaved
            );
            FormStore.addEventListener(
                'FORM_PROTOTYPE_CREATION_FAILURE',
                this.onFormSaveError
            );
            WorkflowStore.addEventListener(
                'WORKFLOW_PROTOTYPE_UPDATED',
                this.onWorkflowSaved
            );

            // Load users for the owner lookup dropdown
            const customer = AuthStore.getCustomer();

            await dispatch(requestUserList({ customerId: customer.id }));

            await this.loadData(formId, workflowId);
        } else {
            //  Attach Listeners on Create
            FormStore.addEventListener(
                'FORM_PROTOTYPE_CREATED',
                this.onFormSaved
            );
            FormStore.addEventListener(
                'FORM_PROTOTYPE_CREATION_FAILURE',
                this.onFormSaveError
            );
            WorkflowStore.addEventListener(
                'WORKFLOW_PROTOTYPE_CREATED',
                this.onWorkflowSaved
            );
            this.loadDefaultData();
        }

        // Load Folders
        await dispatch(fetchFolders());

        this.setDefaultFolder();
    }

    componentWillUnmount() {
        const {
            ActionTypes: { WORKFLOW_PROTOTYPE_LOADED },
        } = Constants;

        FormStore.removeChangeListener(this.onFormChange);
        WorkflowStore.removeEventListener(
            WORKFLOW_PROTOTYPE_LOADED,
            this.onWorkflowChange
        );
        MessageTemplateStore.removeChangeListener(this.onTemplateChange);

        let { edit } = this.state;

        if (edit) {
            //  Remove Listeners on Update
            FormStore.removeEventListener(
                'FORM_PROTOTYPE_UPDATED',
                this.onFormSaved
            );
            FormStore.removeEventListener(
                'FORM_PROTOTYPE_CREATION_FAILURE',
                this.onFormSaveError
            );
            WorkflowStore.removeEventListener(
                'WORKFLOW_PROTOTYPE_UPDATED',
                this.onWorkflowSaved
            );
        } else {
            //  Remove Listeners on Create
            FormStore.removeEventListener(
                'FORM_PROTOTYPE_CREATED',
                this.onFormSaved
            );
            FormStore.removeEventListener(
                'FORM_PROTOTYPE_CREATION_FAILURE',
                this.onFormSaveError
            );
            WorkflowStore.removeEventListener(
                'WORKFLOW_PROTOTYPE_CREATED',
                this.onWorkflowSaved
            );
            this.loadDefaultData();
        }
    }

    loadData = async (formId, workflowId) => {
        // Load Workflow Data
        await WorkflowActions.fetchWorkflowPrototype(workflowId);

        // Load Form Data
        await FormActions.fetchFormPrototype(formId);

        // Load Templates
        await MessageTemplateActions.fetchMessageTemplates();
    };

    loadDefaultData = () => {
        this.setState(
            produce((draft) => {
                draft.workflow = getWorkflowTemplate(this.props.user);
                draft.form = getFormTemplate();
                draft.fields = getFieldsTemplate();
            })
        );
    };

    setDefaultFolder = () => {
        const { workflow, edit } = this.state;
        const { folders, user } = this.props;

        if (!user || !workflow || !folders.isLoaded) {
            return;
        }

        if (workflow.userId !== user.id && edit) {
            return;
        }

        const { folderId } = workflow.userData.initial.caseFile;

        if (folderId && folders.defaultFolder && folders.items) {
            if (!this.doesCurrentFolderBelongToUser()) {
                this.updateFolder(folders.defaultFolder.id);
                this.setState(
                    produce((draft) => {
                        draft.showFolderWarning = edit;
                    })
                );
            }
        }

        if (!folderId && folders.defaultFolder) {
            this.updateFolder(folders.defaultFolder.id);
            this.setState(
                produce((draft) => {
                    draft.showFolderWarning = edit;
                })
            );
        }
    };

    doesCurrentFolderBelongToUser() {
        const { folders } = this.props;
        const { workflow } = this.state;
        const { folderId } = workflow.userData.initial.caseFile;

        return folders.items.some((folder) => folder.id === folderId);
    }

    // Store Change Listeners

    onTemplateChange = () => {
        this.setState(
            produce((draft) => {
                draft.messageTemplates = MessageTemplateStore.getMessageTemplates();
            })
        );
    };

    onWorkflowChange = () => {
        const workflow = WorkflowStore.getCurrentWorkflowPrototype();

        this.setState(
            produce((draft) => {
                draft.workflow = workflow;
                draft.ownerId = workflow.userId;
            })
        );
    };

    onFormChange = () => {
        let formPrototype = FormStore.getFormPrototype();
        let { form, formFields, formDocument } = formPrototype;

        let filteredFields = formFields.filter((f) => !f.deleted);

        this.setState(
            produce((draft) => {
                draft.form = form;
                draft.fields = lodash.orderBy(filteredFields, 'order');
                draft.formDocument = formDocument;
            })
        );
    };

    // State Update Functions

    // Updates Workflow and Form title
    // @todo: Make Form title editable
    updateTitle = (event) => {
        const newTitle = event.target.value;

        this.setState(
            produce(({ workflow, form }) => {
                workflow.title = newTitle;
                form.title = newTitle;
            })
        );
    };

    updateCaseFileTitle = (title) => {
        this.setState(
            produce(({ workflow }) => {
                workflow.userData.initial.caseFile.title = title;
            })
        );
    };

    updateDocumentTitle = (title) => {
        this.setState(
            produce(({ workflow }) => {
                workflow.userData.initial.caseFile.document.title = title;
            })
        );
    };

    // Updates selected email template
    updateMessageTemplate = (event) => {
        let templateId = Number(event.target.value);
        let { workflow } = this.state;
        let { emails } = workflow.userData.initial;

        if (!emails) {
            emails = {};
        }

        emails.templateId = templateId;

        this.setState(
            produce((draft) => {
                draft.workflow = workflow;
            })
        );
    };

    // Updates Selected Form Document
    updateFile = (selectedFile) => {
        this.setState(
            produce((draft) => {
                draft.formDocument.title =
                    selectedFile.name || draft.formDocument.title;
                draft.file = selectedFile;
            })
        );
    };

    // Updates sender information
    updateSender = (event) => {
        const data = {};

        data[event.target.name] = event.target.value;

        this.setState(
            produce(({ workflow }) => {
                const { emails } = workflow.userData.initial;

                // Handle cases where legacy workflows didn't have sender data.
                if (!emails) {
                    workflow.userData.initial.emails = {
                        sender: data,
                    };
                } else {
                    // Update existing sender data if it exists.
                    emails.sender = assign({}, emails.sender, data);
                }
            })
        );
    };

    updateFields = (fields) => {
        this.setState(
            produce((draft) => {
                draft.fields = fields.slice();
            })
        );
    };

    /**
     * Before updating the fields in the component's state, it checks the
     * "is duplicate" status on each of them, eventually skipping the one
     * field given as argument
     *
     * The bulk check is necessary in case a field (the one to be skipped
     * given as argument, usually) has had its name updated or got deleted,
     * and thus the other fields previously marked as duplicate need to be
     * checked again in case they are not a duplicate anymore
     *
     * @param {array} fields
     * @param {object} fieldToSkip
     */
    updateFieldsAfterDuplicationCheck = (fields, fieldToSkip) => {
        fields = produce(fields, (draft) => {
            draft.forEach((field, i) => {
                const skipCheck = !field.isDuplicate || field === fieldToSkip;

                draft[i] = skipCheck
                    ? draft[i]
                    : {
                          ...field,
                          isDuplicate: this.isFieldDuplicate(field, draft),
                      };
            });
        });

        this.updateFields(fields);
    };

    updateDeletedFields = (deletedField) => {
        this.setState(
            produce(({ deletedFields }) => {
                deletedFields.push(deletedField);
            })
        );
    };

    updateField = (data, index) => {
        const { fields } = this.state;
        const old = fields[index];
        const updated = assign({}, old, data);
        const hasNameChanged = updated.name !== old.name;

        // If the name has changed, then checks if it has become
        // a duplicate of some other field
        if (hasNameChanged) {
            updated.isDuplicate = this.isFieldDuplicate(updated, fields);
        }

        const newFields = produce(fields, (draft) => {
            draft[index] = updated;
        });

        hasNameChanged
            ? this.updateFieldsAfterDuplicationCheck(newFields, updated)
            : this.updateFields(newFields);
    };

    /**
     * Checks whether the field is a duplicate, meaning that it has
     * the same name as some other field in the list
     *
     * The check is performed only against fields that
     *   1. are not the given field
     *   2. are not duplicate themselves, otherwise the "original" field
     *      could be marked as duplicate when its value changes
     *
     * @param {object} field
     * @param {object} fields The list of fields where to look for duplicates
     * @return {boolean}
     */
    isFieldDuplicate = (field, fields) => {
        const id = field.id || field._id;

        return fields
            .filter((f) => f !== id && !f.isDuplicate)
            .some((f) => f.name.toLowerCase() === field.name.toLowerCase());
    };

    removeField = (index) => {
        const fields = this.state.fields.slice(0);
        const [deletedField] = fields.splice(index, 1);

        if (deletedField.id) {
            this.updateDeletedFields(deletedField);
        }

        deletedField.name
            ? this.updateFieldsAfterDuplicationCheck(fields, deletedField)
            : this.updateFields(fields);
    };

    updateSetting = (setting, value) => {
        this.setState(
            produce(({ workflow }) => {
                workflow.settings[setting] = value;
            })
        );
    };

    updateLanguage = (languageCode) => {
        this.setState(
            produce(({ workflow }) => {
                workflow.language = languageCode;
            })
        );
    };

    updateFolder = (folderId) => {
        this.setState(
            produce(({ workflow }) => {
                workflow.userData.initial.caseFile.folderId = folderId;
            })
        );
    };

    updateStateMachine = (stateMachine) => {
        let { workflow } = this.state;

        workflow.stateMachine = stateMachine;
        this.setState({ workflow: workflow });
    };

    updateSensitiveData = (enabled) => {
        this.setState(
            produce(({ workflow }) => {
                workflow.userData.initial.caseFile.sensitiveData = enabled;

                if (enabled) {
                    workflow.userData.initial.caseFile.enableInsecureSigning = false;
                }
            })
        );
    };

    // Event Listeners

    /**
     * Sends the error to analytic services
     * @param {object} error
     */
    onFormSaveError = (error) => {
        const statusCode = error.data.error
            ? error.data.error.code
            : error.status;

        Analytics.track('form template - creation error', { statusCode });
    };

    onFormSaved = (form) => {
        const { fields, workflow } = this.state;

        this.saveWorkflowPrototype(form.id);

        Analytics.track('form template - created', {
            language: workflow.language,
            fieldsCount: fields.length,
            enableTouchSignature:
                workflow.userData.initial.caseFile.enableInsecureSigning,
            sensitiveInformation:
                workflow.userData.initial.caseFile.sensitiveData,
            ...workflow.settings,
        });
    };

    onWorkflowSaved = async (workflow) => {
        const { edit } = this.state;

        if (edit && this.hasOwnerChanged()) {
            const { form, ownerId } = this.state;

            await FormActions.transferOwnership(form.id, ownerId);
        }

        const workflowId = workflow.id;
        const formId = workflow.userData.initial.form.prototypeId;

        this.onTemplateSavedSuccess(workflowId, formId);
    };

    onTemplateSavedSuccess = (workflowId, formId) => {
        let { router } = this.context;

        router.push({
            name: 'template-mapping',
            params: {
                formId: formId,
                workflowId: workflowId,
            },
        });
    };

    saveHandler = () => {
        this.saveFormPrototype();
    };

    saveFormPrototype = () => {
        let {
            form,
            file,
            fields,
            deletedFields,
            formDocument,
            edit,
        } = this.state;

        let formPrototype = {
            form: form,
            // Remove field properties we cannot send to the API endpoint
            formFields: fields.map((field) =>
                lodash.omit(field, ['_id', 'isDuplicate'])
            ),
            deletedFields: deletedFields,
            formDocument: formDocument,
            file: file,
        };

        if (edit) {
            FormActions.updateFormPrototype(formPrototype);

            return;
        }

        FormActions.saveFormPrototype(formPrototype);
    };

    /**
     * Sends action to persist workflow prototype.
     * This function is run after the form has been successfully persisted,
     * as the form prototype id is required to save
     * @param  {int} formId - Form Prototype ID to set in workflow.
     * @return {void}
     */
    saveWorkflowPrototype = (formId) => {
        const { edit, workflow } = this.state;

        const amendedWorkflow = produce(workflow, (draft) => {
            const { caseFile, emails, form } = draft.userData.initial;

            // Set formId as form prototype id
            form.prototypeId = formId;

            if (!caseFile.title) {
                caseFile.title = 'placeholder';
            }

            if (!caseFile.document.title) {
                caseFile.document.title = 'placeholder';
            }

            if (emails.templateId === -1) {
                delete emails.templateId; // If standard template is selected, remove from payload.
            }

            if (edit && this.hasOwnerChanged()) {
                this.amendCurrentFolderAfterOwnerChange(draft);
            }
        });

        if (edit) {
            const { workflowId } = this.props.params;

            WorkflowActions.updateWorkflowPrototype(
                workflowId,
                amendedWorkflow
            );
        } else {
            WorkflowActions.saveWorkflowPrototype(amendedWorkflow);
        }
    };

    amendCurrentFolderAfterOwnerChange(workflow) {
        const { user } = this.props;
        const { ownerId } = this.state;
        const isNewOwnerTheCurrentUser = ownerId === user.id;

        // Unsetting the folder makes it so that the default folder of
        // the new owner is selected automatically
        let unsetFolder = true;

        // The folder is not unset if the new owner is the current user, and
        // the selected folder belongs to them
        if (isNewOwnerTheCurrentUser) {
            unsetFolder = !this.doesCurrentFolderBelongToUser();
        }

        if (unsetFolder) {
            delete workflow.userData.initial.caseFile.folderId;
        }
    }

    updateInsecureSigning = (enabled) => {
        this.setState(
            produce(({ workflow }) => {
                workflow.userData.initial.caseFile.enableInsecureSigning = enabled;

                if (enabled) {
                    workflow.userData.initial.caseFile.sensitiveData = false;
                }
            })
        );
    };

    updateOwner = (ownerId) => {
        this.setState(
            produce((draft) => {
                draft.ownerId = ownerId;
            })
        );
    };

    hasOwnerChanged() {
        const { ownerId, workflow } = this.state;

        return ownerId !== workflow.userId;
    }

    render() {
        // Data Objects
        const { folders } = this.props;
        const {
            form,
            fields,
            formDocument,
            workflow,
            ownerId,
            messageTemplates,
            showFolderWarning,
            edit,
        } = this.state;

        if (!workflow || !form || !folders.isLoaded) {
            return false;
        }

        return (
            <FormBuilder
                // Data
                fields={fields}
                formDocument={formDocument}
                form={form}
                workflow={workflow}
                folders={folders}
                ownerId={ownerId}
                messageTemplates={messageTemplates}
                stateMachines={stateMachines}
                showFolderWarning={showFolderWarning}
                isEdit={edit}
                // Functions
                updateLanguage={this.updateLanguage}
                updateFolder={this.updateFolder}
                updateMessageTemplate={this.updateMessageTemplate}
                updateTitle={this.updateTitle}
                updateStateMachine={this.updateStateMachine}
                updateSetting={this.updateSetting}
                updateSensitiveData={this.updateSensitiveData}
                updateFile={this.updateFile}
                updateSender={this.updateSender}
                updateField={this.updateField}
                updateFields={this.updateFields}
                updateDocumentTitle={this.updateDocumentTitle}
                updateCaseFileTitle={this.updateCaseFileTitle}
                updateInsecureSigning={this.updateInsecureSigning}
                updateOwner={this.updateOwner}
                removeField={this.removeField}
                // Handlers
                saveHandler={this.saveHandler}
            />
        );
    }
}

export default connect((state) => {
    return {
        folders: state.folders,
    };
})(FormBuilderController);
