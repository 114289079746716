import PropTypes from 'prop-types';
import React from 'react';
import Constants from '../../Constants';

import FormStore from '../../stores/FormStore';

import WorkflowStore from '../../stores/WorkflowStore';
import WorkflowCommentStore from '../../stores/WorkflowCommentStore';

import WorkflowActions from '../../actions/WorkflowActionCreators';
import WorkflowCommentActions from '../../actions/WorkflowCommentActionCreators';

import DocumentEditor from '../../components/Form/DocumentEditor';
import { modal } from 'Common/components/Common/Modal';
import { translate, TranslationActions } from 'Language';

import FormRejectedPage from '../../components/Pages/FormRejectedPage';
import Button from 'Common/components/Button';

export default class DocumentEditorController extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static propTypes = {
        params: PropTypes.object.isRequired,
        location: PropTypes.object,
    };

    state = {
        formDocument: [],
        pages: [],
        fields: [],
        mapping: [],
        attachments: [],
        coSigner: {},
        form: {},
        dimensions: {},
        rejectionMessage: WorkflowCommentStore.getCurrentComment(),
        workflow: null,
    };

    /**
     * React Lifecycle event for component that undergoes through a prop change.
     * Reloads server data when there's a prop change without remounting the component.
     *
     * @param  nextProps {object} collection of newly received props.
     */
    componentWillReceiveProps(nextProps) {
        if (this.props.params.id !== nextProps.params.id) {
            this.loadData(nextProps.params.id);
        }
    }

    componentDidMount() {
        // Change Listeners
        FormStore.addChangeListener(this.onChange);
        WorkflowStore.addChangeListener(this.onChange);
        WorkflowCommentStore.addChangeListener(this.onChange);

        // Custom Event Listeners
        WorkflowStore.addEventListener('rejected', this.onRejected);

        WorkflowStore.addEventListener(
            'WORKFLOW_FORM_SAVED',
            this.onFormUpdated
        );
        WorkflowStore.addEventListener(
            'WORKFLOW_FORM_SAVE_ERROR',
            this.onResumeError
        );

        WorkflowStore.addEventListener('resume-error', this.onResumeError);

        // Load Initial set of data.
        this.loadData();
    }

    componentWillUnmount() {
        // Change Listeners
        FormStore.removeChangeListener(this.onChange);
        WorkflowStore.removeChangeListener(this.onChange);
        WorkflowCommentStore.removeChangeListener(this.onChange);

        // Custom Event Listeners
        WorkflowStore.removeEventListener(
            'WORKFLOW_FORM_SAVED',
            this.onFormUpdated
        );
        WorkflowStore.removeEventListener(
            'WORKFLOW_FORM_SAVE_ERROR',
            this.onResumeError
        );

        WorkflowStore.removeEventListener('rejected', this.onRejected);
        WorkflowStore.removeEventListener('resume-error', this.onResumeError);
        WorkflowStore.removeEventListener('resume-error', this.onResumeError);
    }

    /**
     * Listens to Store Change event emisions, and updates all
     * states acccording to the latest store contents.
     */
    onChange = () => {
        this.setState({
            formDocument: FormStore.getFormDocument(),
            pageImages: FormStore.getFormDocumentImages(),
            fields: FormStore.getFormFields(),
            mapping: FormStore.getFormFieldsMapping(),
            attachments: WorkflowStore.getAttachments(),
            coSigner: WorkflowStore.getCoSigner(),
            form: FormStore.getCurrentForm(),
            dimensions: FormStore.getDocumentDimensions(),
            rejectionMessage: WorkflowCommentStore.getCurrentComment(),
            workflow: WorkflowStore.getCurrentWorkflow(),
        });
    };

    /**
     * Sets the language based on the workflow when the workflow loads
     */
    componentWillUpdate(nextProps, nextState) {
        let { workflow } = nextState;

        if (this.state.workflow && !this.state.workflow.id && workflow.id) {
            TranslationActions.setLanguage(workflow.language, {
                persist: false,
            });
        }
    }

    /**
     * Loads all the data from store after a prop change. (applies on first load too)
     * @param id {int} form ID prop, references a form ID in the server.
     *
     * Sets initial viewportWidth state.
     */
    loadData = (id) => {
        let formId = this.props.params.id;

        if (id) {
            formId = id;
        }

        let { workflow } = this.props.location.query;

        WorkflowActions.fetchById(workflow);
        WorkflowCommentActions.getComments(workflow);

        FormStore.loadFormDocument(formId);
        FormStore.loadFormFields(formId);
        FormStore.loadFormFieldsMapping(formId);
        FormStore.loadFormById(formId);
    };

    /**
     * Creates an element based on the dropped field type on the documents' page.
     * @param event {event} onDrop event from finalized drag action.
     */
    dropHandler = (event) => {
        event.preventDefault();
        // Get DOM Object Bounds
        let rect = event.target.getBoundingClientRect();

        // Calculate pointer position inside target. (Pointer position - Target Position)
        let posX = event.clientX - rect.left;
        let posY = event.clientY - rect.top;

        let fieldId = parseInt(event.dataTransfer.getData('text/plain'), 10); // Parsed to Integer

        // Render Element in position
        this.createElement(event.target, posX, posY, fieldId);
    };

    saveFormHandler = () => {
        let formId = this.props.params.id;
        let workflowId = this.props.location.query.workflow;

        let { fields, attachments, coSigner } = this.state;

        // Clean fields
        for (let field in fields) {
            if (fields.hasOwnProperty(field)) {
                delete fields[field].isHighlighted;
            }
        }

        let data = {
            formId: formId,
            workflowId: workflowId,
            fields: fields,
            attachments: attachments,
            coSigner: coSigner,
        };

        WorkflowActions.updateForm(data);
    };

    // Go to next step in workflow
    onFormUpdated = () => {
        let workflowId = this.props.location.query.workflow;

        WorkflowActions.next(workflowId);
    };

    onResumeError = () => {
        let config = {
            title: translate('Der opstod en fejl'),
            body: (
                <span>
                    {translate(
                        'Der opstod en fejl i kommunikationen med serveren.'
                    )}
                    <br />
                    {translate('Prøv venligst igen, eller kontakt support.')}
                </span>
            ),
            buttons: (
                <div>
                    <a
                        href={Constants.SUPPORT_URL}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Button theme="blue">
                            {translate('Contact Support')}
                        </Button>
                    </a>
                    <Button onClick={modal.hide}>{translate('Close')}</Button>
                </div>
            ),
        };

        modal.show(config);
    };

    /**
     * Calls WorkflowStore to reject a workflow
     * @param event {onClick} event
     */
    rejectWorkflowHandler = () => {
        let config = {
            title: 'Afvis at underskrive',
            body: 'Er du sikker på, at du vil afvise at underskrive?',
            buttons: (
                <div>
                    <Button theme="red" onClick={this.rejectWorkflow}>
                        {translate('Reject to Sign')}
                    </Button>
                    <Button onClick={modal.hide}>{translate('Cancel')}</Button>
                </div>
            ),
        };

        modal.show(config);
    };

    rejectWorkflow = () => {
        let workflowId = this.props.location.query.workflow;
        let { rejectionMessage } = this.state;

        WorkflowActions.reject(workflowId);
        WorkflowCommentActions.save(workflowId, rejectionMessage);
    };

    onRejected = () => {
        modal.hide();
        let workflowId = this.props.location.query.workflow;

        this.context.router.replace({
            name: 'form-rejected',
            params: {
                id: workflowId,
            },
        });
    };

    render() {
        // Form Data
        let { form, fields, mapping, dimensions } = this.state;

        // Documents
        let { formDocument, pageImages, attachments } = this.state;

        // Workflow
        let { workflow, coSigner, rejectionMessage } = this.state;

        // Functions
        let { rejectWorkflow } = this;

        // View State
        let { preview } = this.props.location.query;

        if (!workflow) {
            return false;
        }

        if (workflow.status === 'rejected') {
            return <FormRejectedPage />;
        }

        return (
            <DocumentEditor
                form={form}
                fields={fields}
                mapping={mapping}
                formDocument={formDocument}
                pageImages={pageImages}
                attachments={attachments}
                workflow={workflow}
                coSigner={coSigner}
                reject={rejectWorkflow}
                rejectionMessage={rejectionMessage}
                dimensions={dimensions}
                saveFormHandler={this.saveFormHandler}
                previewMode={!!preview}
            />
        );
    }
}
