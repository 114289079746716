import { Dispatcher, BaseStore } from 'Core';
import assign from 'object-assign';
import ActionTypes from '../ActionTypes';

let _default = {
    teams: [],
    team: {
        name: '',
        description: '',
        parentId: '',
    },
    availableUsers: [],
    fetching: false,
    error: null,
};

// Initialize Store data
let _store = {};

resetState();

function updateState(state) {
    _store = assign(_store, state, { fetching: false });
}

function resetState() {
    _store = assign(_store, _default);
}

function updateTeamsList(teams) {
    updateState({ teams: teams });
}

function updateCurrentTeam(team) {
    updateState({ team: team });
}

function updateCustomerAvailableUsers(availableUsers) {
    _store.availableUsers = availableUsers;
}

function updateError(error) {
    updateState({ error: error });
}

// Flux Store Creation
const TeamStore = assign({}, BaseStore, {
    isFetching() {
        return _store.fetching;
    },

    getTeams() {
        return _store.teams;
    },

    getCurrentTeam() {
        return _store.team;
    },

    getAvailableUsers() {
        return _store.availableUsers;
    },

    getError() {
        return _store.error;
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register((payload) => {
        let action = payload.action;

        switch (action.type) {
            // Request Actions
            case ActionTypes.FETCH_TEAMS_REQUEST:
            case ActionTypes.FETCH_TEAM_REQUEST:
            case ActionTypes.UPDATE_TEAM_REQUEST:
            case ActionTypes.CREATE_TEAM_REQUEST:
            case ActionTypes.DELETE_TEAM_REQUEST:
            case ActionTypes.FETCH_USERS_REQUEST:
                _store.fetching = true;
                break;

            // Success Actions
            case ActionTypes.CREATE_TEAM_SUCCESS:
            case ActionTypes.UPDATE_TEAM_SUCCESS:
            case ActionTypes.DELETE_TEAM_SUCCESS:
                resetState();
                break;
            case ActionTypes.FETCH_TEAMS_SUCCESS:
                updateTeamsList(action.teams);
                break;
            case ActionTypes.FETCH_TEAM_SUCCESS:
                updateCurrentTeam(action.team);
                break;
            case ActionTypes.FETCH_USERS_SUCCESS:
                updateCustomerAvailableUsers(action.users);
                break;

            // Failure Actions
            case ActionTypes.FETCH_TEAMS_FAILURE:
            case ActionTypes.FETCH_TEAM_FAILURE:
            case ActionTypes.FETCH_USERS_FAILURE:
            case ActionTypes.CREATE_TEAM_FAILURE:
            case ActionTypes.UPDATE_TEAM_FAILURE:
            case ActionTypes.DELETE_TEAM_FAILURE:
                updateError(action.error);
                break;

            // View Actions
            case ActionTypes.CLEAR_STORE:
                resetState();
                break;
            case ActionTypes.CLEAR_ERRORS:
                updateError(null);
                break;
            default:
                return false;
        }
        TeamStore.emitChange(action.type);

        return false;
    }),
});

export default TeamStore;
