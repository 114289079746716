/**
 *  We use Tableau Server for some reports that *some* customers have access to.
 *
 *  We need to authenticate the users with Tableau Server first, and we do this via a
 *  one-time-use token we get from the API.
 *
 *  It was decided that so few customers have access to this, not having it translated is OK.
 *
 *  If you want to edit the dashboard itself, it can only be done from Tableau Server, and
 *  only our Analytics department can do that.
 *
 * NOTE: There's an error triggered by the embed code, but this shouldn't break our app
 * nor the functionality of Tableau itself.
 * Read more: https://community.tableau.com/s/question/0D58b00009vZlKeCAK/error-reports-only-refused-to-evaluate-a-string-as-javascript-because-unsafeeval
 */

import React, { useEffect, useState } from 'react';
import { SigningAPI } from 'Api';
import Loading from 'Common/components/Loading';
import EmbedWrapper from 'Common/components//EmbedWrapper';
import { loadScript } from './utils';
import { i18n } from 'Language';

/**
 * We declare a global interface to enable typing for 'tableau-viz' element in React
 * NOTE: if in the future we need to use more web components, this should be done
 * generic, in a separate file
 */
declare global {
    namespace JSX {
        interface IntrinsicElements {
            'tableau-viz': React.AllHTMLAttributes<HTMLElement> &
                Record<string, string>;
        }
    }
}

const TableauEmbed = () => {
    const [loadingState, setLoadingState] = useState<
        'init' | 'loading' | 'error' | 'clicked' | 'loaded'
    >('init');
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [accessToken, setAccessToken] = useState<null | string>(null);

    useEffect(() => {
        loadScript(
            'https://analytics.penneo.cloud/javascripts/api/tableau.embedding.3.latest.min.js',
            { type: 'module' }
        )
            .then(() => setScriptLoaded(true))
            .catch((error) => console.error(error));
    }, []);

    useEffect(() => {
        if (!scriptLoaded) {
            return;
        }

        if (loadingState === 'init') {
            SigningAPI.post('/data-dashboard/tableau/token', {})
                .then(({ token }) => {
                    setLoadingState('loaded');
                    setAccessToken(token);
                })
                .catch((error) => {
                    console.error(error);
                    setAccessToken(error.request.response);
                    setLoadingState('error');
                });

            setLoadingState('loading');
        }
    }, [scriptLoaded, loadingState]);

    if (loadingState === 'init' || loadingState === 'loading') {
        return (
            <EmbedWrapper>
                <div>
                    {i18n`Loading...`} <Loading size="small" theme="inline" />
                </div>
            </EmbedWrapper>
        );
    }

    if (loadingState === 'error') {
        return (
            <EmbedWrapper>
                <p>{i18n`There was an error while processing your request.`}</p>
                <p>{accessToken}</p>
            </EmbedWrapper>
        );
    }

    return (
        <>
            {accessToken && (
                <tableau-viz
                    id="tableauViz"
                    src="https://analytics.penneo.cloud/#/views/EmbeddedAnalyticsBETA/UsageOverview"
                    toolbar="hidden"
                    token={accessToken}
                    device="desktop"></tableau-viz>
            )}
        </>
    );
};

/**
 * An external data dashboard available to some customers.
 */
const ExternalCustomerDataDashboard = () => <TableauEmbed />;

export default ExternalCustomerDataDashboard;
