export const CASEFILE_EMAIL_MESSAGE_UPDATED = 'CASEFILE_EMAIL_MESSAGE_UPDATED';
export const CASEFILE_EMAIL_TEMPLATE_UPDATED =
    'CASEFILE_EMAIL_TEMPLATE_UPDATED';
export const EDIT_EMAIL_BODY = 'EDIT_EMAIL_BODY';
export const EDIT_EMAIL_SUBJECT = 'EDIT_EMAIL_SUBJECT';
export const NEW_CUSTOM_EMAIL_MESSAGE = 'NEW_CUSTOM_EMAIL_MESSAGE';
export const REMOVE_CUSTOM_EMAIL_MESSAGE = 'REMOVE_CUSTOM_EMAIL_MESSAGE';
export const REMOVE_RECIPIENT_FROM_ALL_MESSAGES =
    'REMOVE_RECIPIENT_FROM_ALL_MESSAGES';
export const RESET_CASEFILE_EMAIL_TEMPLATES = 'RESET_CASEFILE_EMAIL_TEMPLATES';
export const SET_CUSTOM_EMAIL_RECIPIENTS = 'SET_CUSTOM_EMAIL_RECIPIENTS';
export const SET_INITIAL_EMAIL_MESSAGES = 'SET_INITIAL_EMAIL_MESSAGES';
