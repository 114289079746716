import Constants from 'Constants';
import { createOpenIDError, ERROR_OPENID_011 } from 'OpenID/Errors';
import { ApiClient } from 'Api';

type CAResponse = {
    certificate: any;
    trustChain: any;
};

/**
 * Fetches Certificate from the CA service
 * @param  {String} csr PKCS#10 Certificate signing request in PEM format
 * @return {Object}     Response consisting of an issued certificate
 *                      and trust chain certificates
 */
export const fetchCACertificate = async (csr: string): Promise<CAResponse> => {
    const API = new ApiClient({
        baseURL: Constants.PENNEO_ORIGIN,
        isPublic: false,
        defaultVersion: null,
    });

    try {
        return await API.post(Constants.PENNEO_CA_ENDPOINT, { csr });
    } catch (error) {
        throw createOpenIDError(error, ERROR_OPENID_011);
    }
};
