import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';

export default class CustomNoTableData extends React.Component {
    static propTypes = {
        icon: PropTypes.string,
        message: PropTypes.string,
        subText: PropTypes.string,
    };

    render() {
        let { icon, message, subText } = this.props;

        return (
            <div className="text-center">
                <h1>
                    <i className={'fa fa-lg ' + icon} />
                </h1>
                <h1 className="header-text">{i18n(message)}</h1>
                {subText ? <p className="sub-text">* {i18n(subText)}</p> : null}
            </div>
        );
    }
}
