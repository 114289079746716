import Constants from 'Constants';
import { PublicAuthAPI } from 'Api/ApiClient';
import { storage } from 'Core';
import { Actions } from 'Auth';

const getRefreshToken = () => storage.get(Constants.PENNEO_REFRESH_KEY);
const setToken = (token) => storage.set(Constants.PENNEO_TOKEN_KEY, token);

export const AuthClient = {
    async reauthenticate() {
        const data = {
            token: getRefreshToken(),
        };

        const options = {
            propagateErrors: true,
        };

        const { token } = await PublicAuthAPI.post(
            `/token/refresh`,
            data,
            options
        );

        setToken(token);

        return token;
    },

    logout() {
        return Actions.AuthActions.handleUnauthorized();
    },
};
