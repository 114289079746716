import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { LanguageDropdown, TranslationActions, translate } from 'Language';

export default class LoginContainerHeader extends React.Component {
    static propTypes = {
        back: PropTypes.string,
        title: PropTypes.string,
        hideLanguageSelector: PropTypes.bool,
    };

    state = {
        open: false,
    };

    open = () => {
        this.setState({
            open: true,
        });
    };

    close = () => {
        this.setState({
            open: false,
        });
    };

    onLanguageChange = (languageCode) => {
        TranslationActions.setLanguage(languageCode, {
            local: true,
            persist: false,
        });
    };

    render() {
        let { title, back, hideLanguageSelector } = this.props;
        let { open } = this.state;

        return (
            <div className="login-container-header">
                {back && (
                    <Link className="back" to={back}>
                        <span className="fa fa-chevron-left" />
                    </Link>
                )}

                {!hideLanguageSelector && (
                    <div
                        className={
                            'language-dropdown-login' + (open ? ' open' : '')
                        }
                        onMouseEnter={this.open}
                        onMouseLeave={this.close}>
                        <LanguageDropdown
                            closeDropdown={this.close}
                            callback={this.onLanguageChange}
                            persist={false}
                        />
                    </div>
                )}

                <h1 className="title">
                    {translate(title) || translate('Log in to Penneo')}
                </h1>
            </div>
        );
    }
}
