import React from 'react';
import { Route, IndexRoute } from 'react-router';

import CasefileDocumentDetails from 'Casefiles/CasefileDetails/CasefileDocumentDetails';

import ArchiveFolderShare from 'Casefiles/Archive/Share';

import { dispatch } from 'Store';
import { deleteBreadcrumbs } from 'Casefiles/Archive/redux/reducer';

import CasefileDetails from 'Casefiles/CasefileDetails';
import Archive from 'Casefiles/Archive';

const resetBreadcrumbState = () => {
    dispatch(deleteBreadcrumbs());
};

const clearFilters = () => {
    window?.sessionStorage?.removeItem('archive-search');
    window?.sessionStorage?.removeItem('archive-last-page');
};

const userRoutes = (
    <Route
        name="personal-archive"
        path="personal-archive"
        onLeave={() => {
            resetBreadcrumbState();
            clearFilters();
        }}>
        <IndexRoute name="personal-archive-all" component={Archive} />
        {/* Folders */}
        <Route
            name="personal-archive-folder"
            path="folder/:folderId"
            component={Archive}>
            <Route
                name="personal-archive-folder-share"
                path="share"
                components={{
                    modal: ArchiveFolderShare,
                }}
            />
        </Route>
        <Route
            name="personal-archive-search"
            path="search"
            component={Archive}
        />
        <Route
            name="personal-archive-virtual"
            path=":tab"
            component={Archive}
        />
        {/* Casefile details */}
        <Route
            name="personal-archive-casefile-details"
            path="view/:casefileId/details"
            component={CasefileDetails}>
            <Route
                name="personal-archive-document-details"
                path="document/:documentId"
                components={{ modal: CasefileDocumentDetails }}
            />
        </Route>
    </Route>
);

const routing = { userRoutes };

export default routing;
