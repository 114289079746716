import React from 'react';
import { i18n } from 'Language';
import NumberInput from 'Common/components/NumberInput';
import './duration-input.scss';

export type Props = {
    onChange?: Function;
    label: string;
    value: number;
    disabled?: boolean;
    required?: boolean;
    showIntervalOptions?: boolean;
    readOnly?: boolean;
};

export type State = {
    value: number;
    selectedInterval: IntervalOption;
};

type IntervalOption = {
    id: string;
    multiplier: number;
};

export default class DurationInput extends React.Component<Props, State> {
    static defaultProps: Partial<Props> = {
        showIntervalOptions: true,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            value: 1,
            selectedInterval: this.intervals[0],
        };
    }

    intervals: IntervalOption[] = [
        {
            id: 'days',
            multiplier: 1,
        },
        {
            id: 'months',
            multiplier: 30,
        },
    ];

    componentDidMount() {
        this.selectInitialInterval(this.props.value);
    }

    selectInitialInterval(value: number) {
        //
        // Reversing the iteration we go by the "largest" value last
        // That way we are ensuring that the "lowest" denominator is not chosen
        // (that being days)
        // ...
        // This is however favorable for situations where (showIntervalOptions) are not true
        // or where the field is only displaying data (readOnly)
        //
        const intervals =
            ((this.props.showIntervalOptions || this.props.readOnly) &&
                [...this.intervals].reverse()) ||
            this.intervals;
        const selectedInterval =
            intervals.find((curr: IntervalOption) => {
                return value % curr.multiplier === 0;
            }) ?? this.intervals[0];

        return this.setState({
            selectedInterval,
            value: value / selectedInterval.multiplier,
        });
    }

    getRealValue(value: number) {
        const interval = this.state.selectedInterval;

        return value * interval.multiplier;
    }

    handleChange(value: number) {
        this.setState({ value });

        const realValue = this.getRealValue(value);

        this.props.onChange && this.props.onChange(realValue);
    }

    handleChangeInterval(selectedOption: string) {
        const selectedInterval =
            this.intervals.find((i) => i.id === selectedOption) ??
            this.intervals[0];

        this.setState({ selectedInterval }, () => {
            const realValue = this.getRealValue(this.state.value);

            this.props.onChange && this.props.onChange(realValue);
        });
    }

    render() {
        const { value, selectedInterval } = this.state;
        const intervalLabel = (value: number, id: IntervalOption['id']) =>
            i18n(value <= 1 ? id.slice(0, -1) : id);

        return (
            <span className="duration-input">
                {this.props.readOnly ? (
                    `${value} ${intervalLabel(value, selectedInterval.id)}`
                ) : (
                    <>
                        <span className="duration-input-number-input">
                            <NumberInput
                                min={1}
                                max={365}
                                onChange={(value: number) =>
                                    this.handleChange(value)
                                }
                                value={this.state.value}
                            />
                        </span>

                        {this.props.showIntervalOptions ? (
                            <select
                                className="duration-input-select"
                                onChange={(e) =>
                                    this.handleChangeInterval(e.target.value)
                                }
                                value={selectedInterval.id}>
                                {this.intervals.map(
                                    (interval: IntervalOption) => (
                                        <option
                                            key={interval.id}
                                            value={interval.id}>
                                            {intervalLabel(value, interval.id)}
                                        </option>
                                    )
                                )}
                            </select>
                        ) : (
                            intervalLabel(value, selectedInterval.id)
                        )}
                    </>
                )}
            </span>
        );
    }
}
