import React from 'react';

import { i18n } from 'Language';

type RecipientListNoDataProps = {
    openRecipientModal: () => void;
};

const RecipientListNoData = ({
    openRecipientModal,
}: RecipientListNoDataProps) => (
    <div className="signer-list-v2-empty">
        <div className="signer-list-empty-graphic">
            <i className="far fa-users" />
            <i className="far fa-plus" />
        </div>
        <h2>{i18n`You still haven't added any recipients`}</h2>
        <span className="underline-link" onClick={() => openRecipientModal()}>
            <i className="far fa-plus-circle" />
            &nbsp;
            {i18n('Add a recipient')}
        </span>
    </div>
);

export default RecipientListNoData;
