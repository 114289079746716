import PropTypes from 'prop-types';
import React from 'react';
import { TourWrapper } from 'Common/components/TourWrapper';
import HeaderLink from './HeaderLink';
import { defaultBranding } from 'Branding/Constants';
import { TOUR_IDS } from 'Constants';
import { i18n } from 'Language';
import tour from 'Common/utils/tour';

export default class Header extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static propTypes = {
        route: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        branding: PropTypes.object,
        appLoaded: PropTypes.bool,
    };

    static defaultProps = {
        branding: defaultBranding,
    };

    insertLogo = () => {
        const { branding } = this.props;

        return (
            <span>
                <a
                    href={branding.siteUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img className="logo" src={branding.imageUrl} />
                </a>
            </span>
        );
    };

    startTourHandler = (event) => {
        event.preventDefault();

        tour.start(TOUR_IDS.FORM, true);
    };

    render() {
        const {
            textColor,
            backgroundColor,
            highlightColor,
        } = this.props.branding;
        const isContrastTooLow = checkIfContrastTooLow(
            textColor,
            backgroundColor
        );
        const linkColor =
            isContrastTooLow && backgroundColor === defaultBranding.textColor
                ? defaultBranding.backgroundColor
                : textColor;

        return (
            <div
                className="header"
                style={{ backgroundColor: backgroundColor }}>
                <div className="header-content">
                    <div className="branding">
                        {this.insertLogo()}
                        <span className="pull-right header-text header-options">
                            <HeaderLink
                                color={linkColor}
                                highlight={highlightColor}>
                                <TourWrapper tourId={TOUR_IDS.FORM}>
                                    <a
                                        id="start-tour-handler"
                                        className="help"
                                        onClick={this.startTourHandler}>
                                        {i18n`Show guide`}&nbsp;
                                        <i className="fa fa-support"></i>
                                    </a>
                                </TourWrapper>
                            </HeaderLink>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * @param {string} color1
 * @param {string} color2
 * @return {boolean}
 */
function checkIfContrastTooLow(color1, color2) {
    return isLightOrDark(color1) === isLightOrDark(color2);
}

/**
 * Using the HSP (Highly Sensitive Poo) equation value, determine whether
 * the given color is light or dark
 *
 * @see http://alienryderflex.com/hsp.html
 * @param {string} color
 * @param {int} [treshold]
 * @return {boolean}
 */
function isLightOrDark(color, treshold = 120) {
    // Variables for red, green, blue values
    let r, g, b;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        [, r, g, b] = color.match(
            /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );
    } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +(
            '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
        );

        r = color >> 16;
        g = (color >> 8) & 255; /* eslint no-mixed-operators:0 */
        b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    return hsp > treshold ? 'light' : 'dark';
}
