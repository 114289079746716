import React from 'react';
import { ServiceIDs } from 'OpenID/Constants';
import Widget from '../Widget';
import WidgetBody from '../WidgetBody';
import WidgetHeader from '../WidgetHeader';
import OpenIDSign from 'OpenID/components/OpenIDSign';
import TouchContainer from 'Auth/components/Touch/TouchContainer';
import EutlSign from 'Eutl/EutlSign';
import { Languages } from 'Language/Constants';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import { SIGNING_METHODS } from 'EID';
type Props = {
    params: {
        serviceId: ServiceIDs;
        challengeKey: string;
    };
};

export default class TestSign extends React.Component<Props> {
    renderedComponent = (method: string, challengeKey: string) => {
        return {
            touch: (
                <TouchContainer
                    challengeKey={challengeKey}
                    languageCode={Languages.EN}
                />
            ),
        }?.[method] ?? launchDarkly.variation(Flags.QRSS_SIGNATURE) ? (
            <EutlSign
                method={
                    SIGNING_METHODS.find(
                        (signingMethod) => signingMethod.type === method
                    ) ?? SIGNING_METHODS[0]
                }
                challengeKey={challengeKey}
                languageCode={Languages.EN}
                serviceId={method as ServiceIDs}
            />
        ) : (
            <OpenIDSign
                challengeKey={challengeKey}
                languageCode={Languages.EN}
                serviceId={method as ServiceIDs}
                legacyServiceName={method}
            />
        );
    };

    render() {
        const { challengeKey, serviceId } = this.props.params;

        return (
            <div>
                <Widget>
                    <WidgetHeader>
                        <h1>Test {serviceId} signing</h1>
                        <p>Key: {challengeKey}</p>
                    </WidgetHeader>
                    <WidgetBody>
                        {this.renderedComponent(serviceId, challengeKey)}
                    </WidgetBody>
                </Widget>
            </div>
        );
    }
}
