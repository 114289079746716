import { flatten } from 'lodash';
import { CaseFileEntity } from 'types/CaseFile';
import { SignatureLineEntity } from 'types/Document';

/**
 * Extracts all signature lines from a casefile for a certain signer that has
 * been modified.
 */
export const getModifiedSigLinesFromCasefile = (
    signerId: number,
    casefile: CaseFileEntity
): SignatureLineEntity[] => {
    const modifiedSignatureLines = casefile.documents.map((document) => {
        return document.signatureLines
            ?.filter((signatureLine) => {
                return signatureLine.signerId === signerId;
            })
            .filter((signatureLineForSigner) => {
                return signatureLineForSigner.hasChanged === true;
            });
    });

    const flattenedSignatureLines = flatten(modifiedSignatureLines);

    return flattenedSignatureLines as SignatureLineEntity[];
};

export const populateSignLinesWithDocumentId = (
    signatureLines: SignatureLineEntity[],
    casefile: CaseFileEntity
): SignatureLineEntity[] => {
    const signatureLinesWithDocId = signatureLines.map((signatureLine) => {
        const documentContainingSignatureLine = casefile.documents.find(
            (document) => {
                const documentContainsSigLine = document.signatureLines?.find(
                    (sigLineInDocument) => {
                        return sigLineInDocument.id === signatureLine.id;
                    }
                );

                if (documentContainsSigLine) return true;
            }
        );

        if (!documentContainingSignatureLine) {
            throw new Error(
                'Found no documents containing specified signature line'
            );
        }

        return {
            ...signatureLine,
            documentId: documentContainingSignatureLine.id,
        };
    });

    return signatureLinesWithDocId;
};
