import createReducer from 'create-reducer';
import { ContactPickerState } from '../../State';
import {
    CONTACT_PICKER_FAILURE,
    CONTACT_PICKER_REQUEST,
    CONTACT_PICKER_SUCCESS,
} from './action-types';

export const contactPickerInitialState: ContactPickerState = {
    byIds: {},
    isFetching: false,
    error: null,
};

const contactPickerReducer = createReducer<ContactPickerState>(
    contactPickerInitialState,
    {
        [CONTACT_PICKER_REQUEST]: (state) => ({
            ...state,
            isFetching: true,
            error: null,
        }),
        [CONTACT_PICKER_SUCCESS]: (state, { byIds, queryMeta }) => ({
            ...state,
            byIds,
            queryMeta,
            isFetching: false,
            error: null,
        }),
        [CONTACT_PICKER_FAILURE]: (state, error) => ({
            ...state,
            isFetching: false,
            error,
        }),
    }
);

export default contactPickerReducer;
