import { getCrypto } from './crypto';

/**
 * Format string in order to have each line with length equal to 63
 * @param  {string} pemString String to format
 * @return {string}           Formatted string
 */
function _formatPEM(pemString: string) {
    const stringLength = pemString.length;
    let resultString = '';

    for (let i = 0, count = 0; i < stringLength; i++, count++) {
        if (count > 63) {
            resultString = `${resultString}\n`;
            count = 0;
        }

        resultString = `${resultString}${pemString[i]}`;
    }

    return resultString;
}

function exportPEM(pkcs10: ArrayBuffer) {
    const { toBase64, arrayBufferToString } = getCrypto();
    const formattedPEM = _formatPEM(toBase64(arrayBufferToString(pkcs10)));

    const delimiters = {
        start: `-----BEGIN CERTIFICATE REQUEST-----`,
        end: `-----END CERTIFICATE REQUEST-----`,
    };

    return `${delimiters.start}\n${formattedPEM}\n${delimiters.end}`;
}

export { exportPEM };
