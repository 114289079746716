type InMemory = {
    [x: string]: {
        value: any;
        expiry?: number;
        _?: number;
    };
};

let inMemory: InMemory = {};

export const cacheExpirationMap = (value: string | number) => {
    if (typeof value === 'number') {
        return value;
    }

    const str = value.toString();

    if (/^\d+$/.test(str)) {
        return Number(value);
    }

    const timeValue = str[0];

    switch (str[1]) {
        case 's':
            return Number(timeValue) * 1000;
        case 'm':
            return Number(timeValue) * 1000 * 60;
        case 'h':
            return Number(timeValue) * 1000 * 60 * 60;
        default:
            throw new Error(
                `function cacheExpirationMap(${value}), @param value can only be (Number)(m|s) exec: '2m', '3s' or '1h'`
            );
    }
};

export const add = (key: string, value: any, expiry?: number | string) => {
    inMemory[key] = {
        value,
    };

    if (expiry) {
        inMemory[key].expiry =
            typeof expiry === 'number' ? expiry : cacheExpirationMap(expiry);
        inMemory[key]._ = Date.now();
    }
};

export const get = <T>(key: string): T | null => {
    const expiration = inMemory[key] && inMemory[key].expiry;

    if (expiration) {
        if (Date.now() - (inMemory[key] as any)._ < expiration) {
            return inMemory[key].value;
        }

        return null;
    }

    return inMemory[key] && inMemory[key].value;
};

export const clear = () => {
    inMemory = {};
};
