import PropTypes from 'prop-types';
import React from 'react';

export default class SimpleDropdown extends React.Component {
    static propTypes = {
        languages: PropTypes.object.isRequired,
        selected: PropTypes.string,
        changeLanguage: PropTypes.func.isRequired,
        name: PropTypes.string,
        callback: PropTypes.func,
    };

    handleChange = (event) => {
        let { value } = event.target;

        this.props.changeLanguage(value);

        if (this.props.callback) {
            this.props.callback(event);
        }
    };

    mapObject = (object, callback) => {
        return Object.keys(object).map((key) => {
            return callback(key, object[key]);
        });
    };

    render() {
        let { languages, name, selected } = this.props;

        return (
            <div className="simple-language-selector">
                <select
                    name={name}
                    onChange={this.handleChange}
                    value={selected}>
                    {this.mapObject(languages, (code, language) => (
                        <option key={code} value={code}>
                            {language.name}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}
