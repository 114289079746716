import axios from 'axios';
import { debug } from 'Core';
import { parameters } from 'Constants';
import { getCrypto } from './crypto';

let crls: any[] = []; // Array of CRLs for all certificates (trusted + intermediate)
const trustedCert = parameters.penneoCARootCertificate; // root cert

const _extractCertificateInformation = (certificateInformation) => {
    let { typesAndValues } = certificateInformation;
    let values = {};

    typesAndValues.forEach((item) => {
        // Add type property and value to object.
        values[item.type] = item.value.valueBlock.value;
    });

    return values;
};

const _getPublicKeySize = (publicKeyInfo) => {
    const { fromBER, RSAPublicKey } = getCrypto();

    if (publicKeyInfo.algorithm.algorithmId.indexOf('1.2.840.113549') !== -1) {
        const asn1PublicKey = fromBER(
            publicKeyInfo.subjectPublicKey.valueBlock.valueHex
        );
        const rsaPublicKey = new RSAPublicKey({ schema: asn1PublicKey.result });

        const modulusView = new Uint8Array(
            rsaPublicKey.modulus.valueBlock.valueHex
        );
        let modulusBitLength = 0;

        if (modulusView[0] === 0x00) {
            modulusBitLength =
                (rsaPublicKey.modulus.valueBlock.valueHex.byteLength - 1) * 8;
        } else {
            modulusBitLength =
                rsaPublicKey.modulus.valueBlock.valueHex.byteLength * 8;
        }

        return modulusBitLength.toString();
    }

    return '< unknown >';
};

const _parsePEMString = (pemCertificate: string) => {
    const delimiterRegex = /---.+---/g;

    try {
        return pemCertificate.replace(delimiterRegex, '').split('\n').join('');
    } catch (e) {
        console.log('FAILED pemCertificate parse', pemCertificate, e);
    }
};

const _fetchCRL = async (extensions) => {
    // Extract CRL Distribution Points URL (id-ce-CRLDistributionPoints)
    // @see: https://www.alvestrand.no/objectid/2.5.29.31.html
    const ID_CE_CRL_DISTRIBUTION_POINTS = '2.5.29.31';
    const extensionCRL = extensions.find(
        (extn) => extn.extnID === ID_CE_CRL_DISTRIBUTION_POINTS
    );

    if (!extensionCRL) {
        return Promise.resolve(); // @todo: Figure out if we should fail the process if none of the extension IDs match '2.5.29.31'.
    }

    // Get CRL URL
    const crlURL = extensionCRL.parsedValue.distributionPoints[0].distributionPoint[0].value.replace(
        'http:',
        'https:'
    ); // convert URL schema to https.

    try {
        const response = await axios({
            url: crlURL,
            method: 'GET',
            responseType: 'blob',
        });

        return _handleCRLsFile(response.data);
    } catch (error) {
        debug.error(error);
    }
};

// Decode X.509 certificate
const _decodeCertificate = (pemCertificate) => {
    let { fromBER, stringToArrayBuffer, fromBase64, Certificate } = getCrypto();

    const pemString = _parsePEMString(pemCertificate);
    const asn1 = fromBER(stringToArrayBuffer(fromBase64(pemString)));

    return new Certificate({ schema: asn1.result });
};

const _handleCRLsFile = (file: File) => {
    let { fromBER, CertificateRevocationList } = getCrypto();
    const reader = new FileReader();

    reader.onload = (event) => {
        try {
            const asn1 = fromBER((event.target as FileReader).result);
            const crl = new CertificateRevocationList({ schema: asn1.result });

            crls.push(crl);
        } catch (error) {
            console.log(error);
        }
    };

    reader.readAsArrayBuffer(file);
};

const createVerificationError = (verification) => {
    return {
        status: verification.resultCode,
        message: verification.resultMessage,
    };
};

const verifyCertificate = async (
    pemCertificate,
    pemIntermediateCertificates
) => {
    const { CertificateChainValidationEngine } = getCrypto();

    const issuedCertificate = _decodeCertificate(pemCertificate);

    const intermediateCertificates: any[] = [];

    pemIntermediateCertificates.forEach((interCertificate) => {
        intermediateCertificates.push(_decodeCertificate(interCertificate));
    });

    await _fetchCRL(issuedCertificate.extensions);

    // Create certificate's array (end-user certificate + intermediate certificates)
    const certificates = [issuedCertificate, ...intermediateCertificates];

    const trustedCerts = [_decodeCertificate(trustedCert)];

    // Create new X.509 certificate chain object
    const certificateChainValidationEngine = new CertificateChainValidationEngine(
        {
            trustedCerts,
            certs: certificates,
            // crls // @todo: Add crls for each certificate
        }
    );

    const verification = await certificateChainValidationEngine.verify();

    if (verification.result === false) {
        throw createVerificationError(verification);
    }

    return verification.result;
};

const parseCertificate = (pemCertificate) => {
    const {
        fromBER,
        stringToArrayBuffer,
        fromBase64,
        Certificate,
        bufferToHexCodes,
    } = getCrypto();
    const pemString = _parsePEMString(pemCertificate);

    // Decode existing X.509 certificate
    const asn1 = fromBER(stringToArrayBuffer(fromBase64(pemString)));
    const certificate = new Certificate({ schema: asn1.result });

    // Extract sginature algorithm ID
    const { algorithmId } = certificate.signatureAlgorithm;

    // Extract values from certificate
    let values: any = {
        issuer: _extractCertificateInformation(certificate.issuer),
        subject: _extractCertificateInformation(certificate.subject),
        serialNumber: bufferToHexCodes(
            certificate.serialNumber.valueBlock.valueHex
        ),
        notBefore: certificate.notBefore.value.toString(),
        notAfter: certificate.notAfter.value.toString(),
        keySize: _getPublicKeySize(certificate.subjectPublicKeyInfo),
        algorithm: algorithmId,
    };

    // Extract information about certificate extensions if any
    if (certificate.extensions) {
        values.extensions = certificate.extensions;
    }

    return values;
};

/**
 * Removes PEM delimiters and creates a single line PEM string
 * @param  {String} pem Certificate in PEM format
 * @return {String}     Certificate without PEM delimiters
 */
export const stripPEMDelimiters = (pemCertificate) => {
    return pemCertificate
        .replace(/-----(BEGIN|END)[\w\d\s]+-----/g, '')
        .replace(/[\r\n]/g, '');
};

export const getX509CertificateList = (certificate, trustChain) => {
    return [
        stripPEMDelimiters(certificate),
        ...trustChain.map((cert) => stripPEMDelimiters(cert)),
    ];
};

export { parseCertificate, verifyCertificate };
