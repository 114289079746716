import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import ErrorMessage from '../errors/InputErrorMessage';
import TimeStamp from './TimeStamp';
import moment from 'moment';
import { CUSTOM_EMAIL_TEMPLATE_ID } from 'Casefiles/Constants';
import MessageTemplateActions from '../../actions/MessageTemplateActionCreators';
import CasefilesActions from '../../actions/CasefilesActionCreators';

export default class MessageSetup extends React.Component {
    static propTypes = {
        changeSendAtDate: PropTypes.func,
        changeExpireAtDate: PropTypes.func,
        clearExpireDate: PropTypes.func,
        clearSendDate: PropTypes.func,
        sendAtDate: PropTypes.object,
        sendLater: PropTypes.bool,
        sendLaterToggle: PropTypes.func,
        expireAtDate: PropTypes.object,
        expireEnable: PropTypes.bool,
        expireEnableToggle: PropTypes.func,
        messageTemplates: PropTypes.array,
        messageTemplate: PropTypes.object,
        inputErrors: PropTypes.object,
        dateFormat: PropTypes.func,
        casefile: PropTypes.object,
    };

    static defaultProps = {
        messageTemplates: [],
    };

    _changeSelectedTemplate = (event) => {
        let templateId = parseInt(event.target.value, 10);

        MessageTemplateActions.updateSelectedTemplateId(templateId);
    };

    _updateTemplate = (event) => {
        let payload = {};
        let { name, value } = event.target;

        payload[name] = value;

        MessageTemplateActions.updateMessageTemplate(payload);
    };

    _enableExpireDate = () => {
        let {
            changeExpireAtDate,
            expireAtDate,
            sendAtDate,
            expireEnable,
        } = this.props;

        this.props.clearExpireDate();
        this.props.expireEnableToggle();

        if (!expireEnable && !expireAtDate && sendAtDate) {
            let expireDefaultDate = moment(
                sendAtDate.format('DD-MM-YYYY') + '12:00',
                'DD-MM-YYYY HH:mm'
            ).add(7, 'days');

            changeExpireAtDate(expireDefaultDate);
        } else if (!expireEnable && !expireAtDate) {
            changeExpireAtDate(moment('12:00', 'HH:mm').add(7, 'days'));
        }
    };

    _clearExpireDate = () => {
        this.props.clearExpireDate();
        this.props.expireEnableToggle(false);
    };

    _enableSendLaterDate = () => {
        let { changeSendAtDate, sendLater } = this.props;

        this._clearSendDate();

        if (!sendLater) {
            let timeNow = new Date();
            let hours = timeNow.getHours();
            let minutes = timeNow.getMinutes();

            let defaultSendAtDate = moment(hours + ':' + minutes, 'HH:mm');

            changeSendAtDate(defaultSendAtDate);
        }
    };

    _clearSendDate = () => {
        let { clearSendDate, sendLaterToggle } = this.props;

        clearSendDate();

        sendLaterToggle();
    };

    _updateReference = (event) => {
        let { value } = event.target;
        let { casefile } = this.props;

        casefile.reference = value;

        CasefilesActions.updateCasefile(casefile);
    };

    render() {
        const {
            sendAtDate,
            expireAtDate,
            messageTemplates,
            messageTemplate,
            inputErrors,
            sendLater,
            expireEnable,
            changeSendAtDate,
            changeExpireAtDate,
            casefile,
        } = this.props;
        const customTemplateOption = {
            id: CUSTOM_EMAIL_TEMPLATE_ID,
            title: '----',
        };
        const templateOptions =
            messageTemplate.id === CUSTOM_EMAIL_TEMPLATE_ID
                ? [customTemplateOption].concat(messageTemplates)
                : messageTemplates;

        return (
            <div className="casefile-message-setup">
                <div className="casefile-message-form">
                    <div className="casefile-row-form">
                        <div className="fieldset">
                            <div className="summary-legend">
                                {i18n(`Case file options`)}
                            </div>

                            <div className="casefile-options-container">
                                <div className="inline-block align-top mr">
                                    <label className="mr">
                                        {i18n(`Send At:`)}
                                    </label>

                                    <label className="custom-checkbox">
                                        <input
                                            type="checkbox"
                                            name="checkboxSendLaterDate"
                                            checked={sendLater}
                                            disabled={false}
                                            onChange={this._enableSendLaterDate}
                                        />
                                        <span className="check">
                                            <i className="fas fa-check" />
                                        </span>
                                        <span className="label">
                                            {i18n(`Send later`)}
                                        </span>
                                    </label>

                                    <TimeStamp
                                        type="sendAt"
                                        sendAtDate={sendAtDate}
                                        expireAtDate={expireAtDate}
                                        onChange={changeSendAtDate}
                                        clearExpireDate={this._clearExpireDate}
                                        enabled={sendLater}
                                    />
                                </div>
                                <div className="inline-block align-top">
                                    <label className="mr">
                                        {i18n(`Expire At:`)}
                                    </label>
                                    <label className="custom-checkbox">
                                        <input
                                            type="checkbox"
                                            name="checkboxEnableExpireDate"
                                            checked={expireEnable}
                                            disabled={false}
                                            onChange={this._enableExpireDate}
                                        />
                                        <span className="check">
                                            <i className="fas fa-check" />
                                        </span>
                                        <span className="label">
                                            {i18n(`Enable`)}
                                        </span>
                                    </label>
                                    <TimeStamp
                                        type="expireAt"
                                        sendAtDate={sendAtDate}
                                        expireAtDate={expireAtDate}
                                        onChange={changeExpireAtDate}
                                        enabled={expireEnable}
                                        clearExpireDate={this._clearExpireDate}
                                    />
                                </div>

                                <div className="mt">
                                    <label>{i18n(`Reference`)}</label>
                                    <div className="casefile-input">
                                        <input
                                            type="text"
                                            name="reference"
                                            className={inputErrors.reference}
                                            value={casefile.reference}
                                            placeholder={i18n('Reference')}
                                            onChange={this._updateReference}
                                        />
                                        {inputErrors.reference && (
                                            <ErrorMessage
                                                message={inputErrors.reference}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <form action="" name="messageForSigners">
                        <div className="casefile-row-form">
                            <div className="fieldset">
                                <div className="summary-legend">
                                    {i18n(`Message for signers`)}
                                </div>
                                <div className="message-section-content">
                                    <div className="message-form-input">
                                        <div className="left-block">
                                            <label
                                                htmlFor="id"
                                                className="first">
                                                {i18n(`Choose Mail Template:`)}
                                            </label>
                                        </div>
                                        <div className="right-block">
                                            <select
                                                name="id"
                                                value={messageTemplate.id}
                                                onChange={
                                                    this._changeSelectedTemplate
                                                }>
                                                {templateOptions.map(
                                                    (template, index) => (
                                                        <option
                                                            key={index}
                                                            value={template.id}>
                                                            {template.title}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="message-form-input">
                                        <div className="left-block">
                                            <label htmlFor="title">
                                                {i18n(`Subject:`)}
                                            </label>
                                        </div>
                                        <div className="right-block">
                                            <input
                                                type="text"
                                                name="subject"
                                                value={messageTemplate.subject}
                                                className={
                                                    inputErrors.subject
                                                        ? 'input-error'
                                                        : null
                                                }
                                                onChange={this._updateTemplate}
                                            />
                                            {inputErrors.subject ? (
                                                <ErrorMessage
                                                    message={
                                                        inputErrors.subject
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="message-form-input">
                                        <div className="left-block">
                                            <label htmlFor="message">
                                                {i18n(`Message`)}
                                            </label>
                                        </div>
                                        <textarea
                                            className={
                                                inputErrors.message
                                                    ? 'input-error'
                                                    : null
                                            }
                                            name="message"
                                            rows="8"
                                            onChange={this._updateTemplate}
                                            value={messageTemplate.message}
                                        />
                                        {inputErrors.message ? (
                                            <ErrorMessage
                                                message={inputErrors.message}
                                            />
                                        ) : null}
                                        <h4
                                            style={{
                                                marginBottom: 0,
                                                maginTop: '0.5em',
                                            }}>
                                            <i className="fa fa-info-circle"></i>
                                            &nbsp;
                                            {i18n`Your auto signature from Penneo
                                                     will be automatically appended.`}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
