import React from 'react';
import { TranslationStore, i18n } from 'Language';
import { LoginFooterLink as LoginFooterLinkType } from './types';

type Props = {
    link: LoginFooterLinkType;
};

const LoginFooterLink: React.FC<Props> = (props) => {
    const {
        link: { url, title },
    } = props;

    const lang = TranslationStore.getLanguage();
    const href = typeof url === 'function' ? url(lang) : url;

    return (
        <li>
            <a href={href} target="_blank" rel="noopener noreferrer">
                {i18n(title)}
            </a>
        </li>
    );
};

export default LoginFooterLink;
