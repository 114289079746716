import React from 'react';
import { Route, IndexRedirect } from 'react-router';
import Clients from './components/Clients';
import Client from './components/Client';
const entitiesRoutes = (
    <>
        <Route name="entities" path="entities">
            <IndexRedirect to="clients" />

            <Route name="clients" path="clients">
                <IndexRedirect to="list" />

                <Route name="clients-list" path="list" component={Clients} />
                <Route name="client" path=":cvr" component={Client} />
            </Route>
        </Route>
    </>
);

const routing = { entitiesRoutes };

export default routing;
