import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { i18n } from 'Language';
import { notify } from 'react-notify-toast';

// State
import {
    getLatestCases,
    getPendingSignatures,
} from 'Casefiles/Archive/redux/reducer';

// Components
import DashboardWidgetContainer, {
    DashboardWidget,
} from './DashboardWidgetContainer';
import { getSortedByExpiration, renderLoading } from './common';

import './my-pending-signatures-widget.scss';
import { CaseFileEntity } from 'types/CaseFile';
import { trackDashboardInteraction } from './utils';
import { newSigningUrl } from 'Signing/utils';
import { clearOpenIdState } from 'OpenID/utils/openIdState';
import classNames from 'classnames';

type CaseFileExtended = CaseFileEntity & {
    daysToExpire: number;
};

type CasefilesData = {
    count: number;
    data: CaseFileExtended[];
};

type CasefileToSign = {
    caseFileExpiresAt?: number;
    caseFileTitle: string;
    signingUri: string;
};

type CasefileToSignResponse = {
    items: CasefileToSign[];
};

export const MyPendingSignaturesWidget = () => {
    const [casefiles, setCasefiles] = useState<CasefilesData | null>(null);
    const [myCases, setMyCases] = useState<CasefileToSign[]>([]);
    const [allCases, setAllCases] = useState<CaseFileExtended[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const handleError = () => {
        notify.show(
            <span>
                {i18n`An unexpected error occurred`}{' '}
                <i className="fa fa-times" />
            </span>,
            'error',
            3000
        );
    };

    useEffect(() => {
        let mounted = true; // var to prevent updating state if unmounted

        (async () => {
            try {
                const casefilesToSign = await getPendingSignatures();
                const casefilesData = await getLatestCases();

                if (mounted) {
                    setMyCases(
                        (casefilesToSign as CasefileToSignResponse).items.slice(
                            0,
                            10
                        )
                    );
                    setCasefiles(casefilesData);
                }
            } catch (error) {
                handleError();
                mounted && setCasefiles(null);
            } finally {
                mounted && setIsLoaded(true);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        let items: CaseFileExtended[] = [];

        // prevents errors if there's no data
        if (casefiles?.data) {
            // ensure all items have an expiry date
            items = getSortedByExpiration(casefiles.data);
            setAllCases(items.slice(0, 5));
        }
    }, [casefiles]);

    const renderEmpty = (own: boolean = true) => (
        <div className="widget-empty">
            <p>
                <i className="fal fa-tasks" aria-hidden={true} />
                {own
                    ? i18n`You have no pending signatures`
                    : i18n`There are no pending signatures`}
            </p>
        </div>
    );

    const renderMySignatures = () => (
        <DashboardWidget
            key={`MySignatures`}
            title={i18n`My pending signatures`}>
            {isLoaded === false ? (
                renderLoading()
            ) : (
                <>
                    {myCases.length === 0 ? (
                        renderEmpty()
                    ) : (
                        <ul>
                            {myCases.map((item) => {
                                const hasExpiration = !!item.caseFileExpiresAt;
                                const humanDuration = moment
                                    .duration(
                                        moment
                                            .unix(
                                                item.caseFileExpiresAt as number
                                            )
                                            .diff(moment())
                                    )
                                    .humanize();

                                return (
                                    <li
                                        key={item.signingUri}
                                        className="widget-entry"
                                        data-testid={`my`}>
                                        <p className="widget-entry-title">
                                            {item.caseFileTitle}
                                        </p>
                                        <p className="widget-entry-details">
                                            <span
                                                className={classNames(
                                                    'widget-entry-date',
                                                    {
                                                        'doesnt-expire': !hasExpiration,
                                                    }
                                                )}
                                                aria-label={
                                                    hasExpiration
                                                        ? `${i18n`Expiration date`}: ${moment
                                                              .unix(
                                                                  item.caseFileExpiresAt as number
                                                              )
                                                              .format(
                                                                  'Do MMM YYYY'
                                                              )}`
                                                        : ''
                                                }
                                                aria-hidden={!hasExpiration}>
                                                <span aria-hidden={true}>
                                                    {hasExpiration
                                                        ? moment
                                                              .unix(
                                                                  item.caseFileExpiresAt as number
                                                              )
                                                              .format(
                                                                  'DD/MM/YYYY'
                                                              )
                                                        : '00/00/0000'}
                                                </span>
                                            </span>
                                            <a
                                                href={newSigningUrl(
                                                    item.signingUri
                                                )}
                                                className="widget-entry-action"
                                                onClick={() => {
                                                    clearOpenIdState();
                                                    trackDashboardInteraction(
                                                        'My Pending Signatures widget - sign link clicked',
                                                        {
                                                            title:
                                                                item.caseFileTitle,
                                                        }
                                                    );
                                                }}
                                                aria-label={`${i18n`Sign`} ${
                                                    item.caseFileTitle
                                                }, ${i18n`Expires`}: ${humanDuration}`}>
                                                {i18n`Sign`}
                                            </a>
                                        </p>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </>
            )}
        </DashboardWidget>
    );

    const renderAllSignatures = () => (
        <DashboardWidget key={`AllSignatures`} title={i18n`Pending signatures`}>
            {isLoaded === false ? (
                renderLoading()
            ) : (
                <>
                    {allCases.length === 0 ? (
                        renderEmpty(false)
                    ) : (
                        <>
                            <ul>
                                {allCases.map((item) => (
                                    <li
                                        key={item.id}
                                        className="widget-entry"
                                        data-testid={`all`}>
                                        <p className="widget-entry-title">
                                            {item.title}
                                        </p>
                                        <p
                                            className="widget-entry-details"
                                            aria-label={`${i18n`Expiration date`}: ${moment
                                                .unix(item.expireAt)
                                                .format('Do MMM YYYY')}`}>
                                            <span
                                                className="widget-entry-date"
                                                aria-hidden={true}>
                                                {moment
                                                    .unix(item.expireAt)
                                                    .format('DD/MM/YYYY')}
                                            </span>

                                            <Link
                                                className="widget-entry-action"
                                                aria-label={`${i18n`View more details on`}: ${
                                                    item.title
                                                }`}
                                                to={{
                                                    name:
                                                        'archive-casefile-details',
                                                    params: {
                                                        casefileId: item.id,
                                                    },
                                                }}
                                                onClick={() =>
                                                    trackDashboardInteraction(
                                                        `Pending Signatures widget - casefile clicked`,
                                                        {
                                                            id: item.id,
                                                        }
                                                    )
                                                }>
                                                {i18n`More`}
                                            </Link>
                                        </p>
                                    </li>
                                ))}
                            </ul>
                            <div className="mt pull-right-flex">
                                <Link
                                    to={{
                                        name: 'archive-virtual',
                                        params: {
                                            tab: 'pending',
                                        },
                                    }}
                                    className="bold-link"
                                    onClick={() =>
                                        trackDashboardInteraction(
                                            'navigation',
                                            {
                                                source:
                                                    'Pending Signatures widget',
                                                to: 'pending',
                                            }
                                        )
                                    }
                                    aria-label={`${i18n`View all`} ${`${i18n`Pending signatures`}`.toLowerCase()}`}>
                                    {i18n`View all`}
                                </Link>
                            </div>
                        </>
                    )}
                </>
            )}
        </DashboardWidget>
    );

    return (
        <DashboardWidgetContainer
            title={`${i18n`My pending signatures`} & ${i18n`Pending signatures`}`}
            className="my-pending-signatures-widget"
            content={[renderMySignatures(), renderAllSignatures()]}
        />
    );
};

export default MyPendingSignaturesWidget;
