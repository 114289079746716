import React from 'react';
import {
    CustomerEntity as Customer,
    CustomerSigningMethod,
} from 'types/Customer';
import { i18n, TranslationStore } from 'Language';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import { INSECURE_SIGNING_METHODS, SIGNING_METHODS } from 'EID/Constants';
import { InsecureSigningMethod } from 'types/Signer';
import { cloneDeep, uniqBy } from 'lodash';
import MethodCheckbox from './MethodCheckbox';
import { insecureSigningMethodsDefault, SigningMethod } from 'EID/types';
import { isMethodAvailableToConfigureForSign } from 'EID';
import {
    CustomerSettingKeys,
    CustomerSettings,
    ManagedSettings,
} from 'Settings/redux/types';
import { Checkbox } from 'Common/components';
import Message from '../../../../Common/components/Message';
import './settings.scss';

type Props = {
    customer: Customer;
    updateCustomer: (customer: Customer) => void;
    customerSettings: CustomerSettings;
    managedSettings: ManagedSettings;
    allowSigningWithNapChanged: (newValue: boolean) => void;
};

type State = {
    allowSigningWithNap?: boolean;
};

export default class Settings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    private updateInsecureSigningMethod(
        method: InsecureSigningMethod,
        enabled: boolean
    ) {
        const { customer, updateCustomer } = this.props;
        const insecureMethods = cloneDeep(customer.allowedSimpleSigningMethods);

        if (enabled) {
            insecureMethods.push(method);
        } else {
            const index = insecureMethods.indexOf(method);

            insecureMethods.splice(index, 1);
        }

        /**
         * We check if we should toggle the 'image' (touch) signing method
         */
        const insecureEnabled = customer.allowedSigningMethods.includes(
            CustomerSigningMethod.IMAGE
        );

        /**
         * We make sure that to toggle 'touch' in the allowedSigningMethods array
         * depending on its current value and the insecureMethods enabled
         */
        let signingMethods = customer.allowedSigningMethods;

        if (insecureEnabled && !insecureMethods.length) {
            signingMethods = signingMethods.filter(
                (method) => method !== CustomerSigningMethod.IMAGE
            );
        } else if (!insecureEnabled && insecureMethods.length) {
            signingMethods.push(CustomerSigningMethod.IMAGE);
        }

        /**
         * At the very end we update the whole Customer store
         */
        updateCustomer({
            ...customer,
            allowedSigningMethods: signingMethods,
            allowedSimpleSigningMethods: insecureMethods,
        });
    }

    private updateSigningMethod(
        method: CustomerSigningMethod,
        enabled: boolean
    ) {
        const { customer, updateCustomer } = this.props;
        const index = customer.allowedSigningMethods.indexOf(method);

        if (enabled) {
            customer.allowedSigningMethods.push(method);
        } else {
            customer.allowedSigningMethods.splice(index, 1);
        }

        /**
         * While the new SES flag is disabled, we need to make sure
         * to fill/empty the SES array in the DB depending on toggling Touch.
         * Once the flag is on, this won't be necessary, as the customer
         * will be able to toggle methods individually which will, in turn,
         * toggle 'Touch' in the background
         */
        if (
            !launchDarkly.variation(Flags.SPLIT_INSECURE_SIGNING_METHODS) &&
            method === CustomerSigningMethod.IMAGE
        ) {
            let insecureMethods: InsecureSigningMethod[] = [];

            if (enabled) {
                insecureMethods = insecureSigningMethodsDefault;
            }

            return updateCustomer({
                ...customer,
                allowedSimpleSigningMethods: insecureMethods,
            });
        }

        updateCustomer(customer);
    }

    getSigningMethods(): SigningMethod[] {
        const { managedSettings } = this.props;

        const allSigningMethods = SIGNING_METHODS.filter((method) =>
            isMethodAvailableToConfigureForSign(method, managedSettings)
        );

        let deduplicated = uniqBy(allSigningMethods, (method) =>
            method.credentialType()
        );

        // we remove the 'image' option, for it's covered by the new insecure methods logic
        if (launchDarkly.variation(Flags.SPLIT_INSECURE_SIGNING_METHODS)) {
            deduplicated = deduplicated.filter(
                (method) =>
                    (method.credentialType() as CustomerSigningMethod) !==
                    CustomerSigningMethod.IMAGE
            );
        }

        // we'll remove the 'SMS' verification option, if the feature flag is off.
        if (!launchDarkly.variation(Flags.SMS_VERIFICATION)) {
            deduplicated = deduplicated.filter(
                (method) =>
                    (method.credentialType() as CustomerSigningMethod) !==
                    CustomerSigningMethod.SMS
            );
        }

        return deduplicated;
    }

    private isInsecureMethod(method: SigningMethod): boolean {
        return INSECURE_SIGNING_METHODS.includes(method);
    }

    private isEnabled(method: SigningMethod) {
        const { customer } = this.props;

        if (this.isInsecureMethod(method)) {
            return customer.allowedSimpleSigningMethods?.includes(
                method.credentialType() as InsecureSigningMethod
            );
        } else {
            return customer.allowedSigningMethods?.includes(
                method.credentialType() as CustomerSigningMethod
            );
        }
    }

    private updateMethod(method: SigningMethod, newValue: boolean) {
        if (this.isInsecureMethod(method)) {
            this.updateInsecureSigningMethod(
                method.credentialType() as InsecureSigningMethod,
                newValue
            );
        } else {
            this.updateSigningMethod(
                method.credentialType() as CustomerSigningMethod,
                newValue
            );
        }
    }

    private makeCheckBox(method: SigningMethod) {
        return (
            <div key={method.credentialType()}>
                <MethodCheckbox
                    checked={this.isEnabled(method)}
                    method={method}
                    onChange={this.updateMethod.bind(this, method)}
                    usedForAccessControl={
                        !this.isInsecureMethod(method) && method.login
                    }
                    usedForSigning={method.credentialType() !== 'sms'}
                />
            </div>
        );
    }

    private makeMitIdNAPCheckbox() {
        const { customer, customerSettings } = this.props;
        const { allowSigningWithNap } = this.state;

        const hasNAPFlag = launchDarkly.variation(
            Flags.ENABLE_NAP_INTERVENTION_SCREEN
        );
        const isMitIdEnabled =
            customer.allowedSigningMethods?.includes(
                CustomerSigningMethod.MITID
            ) ?? false;

        if (!hasNAPFlag || !isMitIdEnabled) {
            return;
        }

        const getHelpLink = () => {
            const userLanguage = TranslationStore.getLanguage();

            if (userLanguage === 'da') {
                return 'https://www.support.penneo.com/hc/da/articles/20505278550941-Min-underskriver-kan-ikke-underskrive-med-MitID-fordi-navn-og-adresse-er-beskyttet-i-CPR-registret';
            }

            return 'https://www.support.penneo.com/hc/en-gb/articles/20505278550941-My-signer-can-t-sign-with-MitID-because-name-and-address-are-protected-in-the-CPR-register';
        };

        const isChecked =
            allowSigningWithNap ??
            customerSettings[CustomerSettingKeys.allowSigningWithNap];

        return (
            <div className="method sub-method">
                <div className="method-setting">
                    <Checkbox
                        checked={isChecked}
                        onChange={(value: boolean) => {
                            this.setState({
                                allowSigningWithNap: value,
                            });
                            this.props.allowSigningWithNapChanged(value);
                        }}
                        label={i18n`Allow signing with protected name`}
                    />
                </div>

                {isChecked && (
                    <Message type="info">
                        <div>
                            <strong>
                                {i18n`The signer's name cannot be confirmed`}
                            </strong>
                            <br />
                            {i18n`When signing with protected name is enabled, people with name and address protection in the Danish CPR register can sign with MitID. Their names will not be included in the signed documents.`}
                            &nbsp;
                            <a
                                href={getHelpLink()}
                                target="_blank"
                                rel="noreferrer">{i18n`Learn more`}</a>
                        </div>
                    </Message>
                )}
            </div>
        );
    }

    public render() {
        const signingMethods = this.getSigningMethods();
        const mitId =
            signingMethods.filter(
                (m) => m.credentialType() === CustomerSigningMethod.MITID
            )?.[0] ?? null;

        const otherMethods = signingMethods.filter((m) => m !== mitId);

        const insecureMethodsEnabled = launchDarkly.variation(
            Flags.SPLIT_INSECURE_SIGNING_METHODS
        );

        return (
            <div className="signature-settings">
                <h3>{i18n`Signature Settings`}</h3>
                <h4>{i18n`Choose the methods for access restrictions and signatures`}</h4>
                <div className="checkbox-group access-method-table">
                    {mitId !== null && this.makeCheckBox(mitId)}
                    {mitId !== null && this.makeMitIdNAPCheckbox()}
                    {otherMethods.map(this.makeCheckBox.bind(this))}
                </div>
                {insecureMethodsEnabled && (
                    <div>
                        <h4>{i18n`Allow Simple Electronic Signatures`}</h4>

                        <div className="checkbox-group insecure-signing-method-table">
                            {INSECURE_SIGNING_METHODS.map(
                                this.makeCheckBox.bind(this)
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
