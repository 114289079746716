import React from 'react';
import classnames from 'classnames';
import { i18n } from 'Language';
import TooltipRequired from 'Common/components/TooltipRequired';
import FolderSelectorModal from './FolderSelectorModal';
import { FolderItem, FolderState } from 'Common/redux/Folder/types';

import './folder-selector.scss';
import Button from 'Common/components/Button';

type Props = {
    className?: string;
    folders: FolderState;
    onChange: Function;
    label?: JSX.Element | string;
    name?: string;
    title?: string;
    value: number | null;
    inline?: boolean;
    required?: boolean;
};

type State = {
    open: boolean;
};

export default class FolderSelector extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    handleChange = (folder: FolderItem) => {
        this.props.onChange(folder);
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    openModal = () => {
        if (!this.props.folders.isLoaded) {
            return false;
        }

        this.setState({ open: true });
    };

    getSelectedFolder = (folderId) => {
        const { folders } = this.props;

        if (folderId === null) {
            return this.props.folders.defaultFolder;
        }

        return folders.items.filter((f) => f.id === folderId)[0];
    };

    render() {
        // HTML Properties
        let { required, inline } = this.props;

        // Data
        let { label, folders, value } = this.props;

        const className = classnames('folder-selector', this.props.className, {
            'inline-block': inline,
        });

        const folder = this.getSelectedFolder(value);

        return (
            <div className={className}>
                {this.state.open && (
                    <FolderSelectorModal
                        selectedItem={folder.id || null}
                        folders={folders.items}
                        onClose={this.closeModal}
                        onChange={this.props.onChange}
                    />
                )}
                {label && (
                    <label>
                        {label}
                        {required && <TooltipRequired />}
                    </label>
                )}
                <div
                    className={'folder-selector-input'}
                    onClick={this.openModal}>
                    <span className="folders-selector-input-title">
                        {!folders.isLoaded ? (
                            <span className="text-medium">
                                <i className="far fa-sync fa-spin"></i>&nbsp;
                                <span>{`Loading...`}</span>
                            </span>
                        ) : (
                            <span>
                                <i className="far fa-folder"></i>&nbsp;
                                <span>{folder.title}</span>
                            </span>
                        )}

                        <Button
                            theme="blue"
                            variant="outline"
                            className="pull-right"
                            disabled={folders.isFetching}>
                            {i18n`Change`}
                        </Button>
                    </span>
                </div>
            </div>
        );
    }
}
