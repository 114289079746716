import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { i18n } from 'Language';
import { DateTime } from 'luxon';
import xss from 'xss';
import { connect } from 'react-redux';
import { UserSettingKeys, UserSettings } from 'Settings/redux/types';
import { AppDispatch, ReduxState } from 'Store';
import { WordPressMedia, WordPressPost } from './types';
import { decode } from 'html-entities';
import axios from 'axios';
import { WHATS_NEW_WORDPRESS_MEDIA_URL } from 'Constants';

type Props = {
    active: boolean;
    dispatch: AppDispatch;
    toggleWhatsNew: () => void;
    wordPressData: WordPressPost[];
    whatsNewUserSetting: UserSettings[UserSettingKeys.whatsNew];
};

const createMarkup = (content: string) => {
    return { __html: xss(content) };
};

const lastSeenDateIsOlderThanPostDate = (
    lastSeenDate: string | undefined,
    postDate: string
) => {
    if (!lastSeenDate) return;

    const postDateLuxon = DateTime.fromISO(postDate).setZone('UTC', {
        keepLocalTime: true,
    });

    const lastSeenDateLuxon = DateTime.fromISO(lastSeenDate);
    const dateDiff = postDateLuxon.diff(lastSeenDateLuxon).toObject();

    // Fallback to true
    if (!dateDiff?.milliseconds) {
        return true;
    }

    return dateDiff.milliseconds > 0;
};

interface ImageItemProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    href: string;
    imageId: string;
    tabIndex?: number;
}

const ImageItem: React.FC<ImageItemProps> = ({ href, imageId, tabIndex }) => {
    const [mediaPath, setMediaPath] = useState<string | undefined>(undefined);

    useEffect(() => {
        const fetchMediaUrl = async () => {
            try {
                const response = await axios.get(
                    `${WHATS_NEW_WORDPRESS_MEDIA_URL}${imageId}`
                );
                const mediaData: WordPressMedia = response.data;

                if (mediaData.source_url && mediaData.source_url !== '') {
                    setMediaPath(mediaData.source_url);
                }
            } catch (_error) {}
        };

        fetchMediaUrl();
    }, []);

    if (mediaPath === undefined) {
        return null;
    }

    return (
        <a href={href} target="_blank" rel="noreferrer" tabIndex={tabIndex}>
            <img className="post-image" src={mediaPath} alt="" loading="lazy" />
        </a>
    );
};

export const WhatsNew = (props: Props) => {
    const { active, toggleWhatsNew, wordPressData } = props;
    const sidebarClassNames = classNames('whats-new-sidebar', { active });

    const tabIndex = (!active && { tabIndex: -1 }) ?? {};
    const renderSinglePost = (post: WordPressPost, index: number) => {
        const { whatsNewUserSetting } = props;
        const shouldShowNewStatus = lastSeenDateIsOlderThanPostDate(
            whatsNewUserSetting?.lastSeen,
            post.date
        );

        return (
            <div key={index} className="whats-new-post">
                {/* Status: NEW */}
                {shouldShowNewStatus && (
                    <span className="status-new">{i18n`NEW`}</span>
                )}
                {/* Date */}
                <span
                    className="post-date"
                    aria-label={DateTime.fromISO(post.date).toFormat(
                        'dd LLLL yyyy'
                    )}>
                    {DateTime.fromISO(post.date).toFormat('dd LLL yyyy')}
                </span>
                {/* Title */}
                <a
                    href={post.link}
                    target="_blank"
                    rel="noreferrer"
                    {...tabIndex}>
                    <h3 className="post-title">
                        {decode(post.title.rendered)}
                    </h3>
                </a>
                {/* Excerpt */}
                <div
                    className="post-excerpt"
                    dangerouslySetInnerHTML={createMarkup(
                        post.excerpt.rendered
                    )}
                />
                {/* Image */}
                {post.featured_media && (
                    <ImageItem
                        href={post.link}
                        imageId={post.featured_media}
                        {...tabIndex}
                    />
                )}
            </div>
        );
    };

    const renderPosts = () => {
        const renderHelpCenter = () => (
            <a
                href="https://www.support.penneo.com/"
                target="_blank"
                rel="noreferrer">{i18n`help center`}</a>
        );

        if (wordPressData.length <= 0) {
            return (
                <div className="whats-new-no-posts">
                    <h3>{i18n('We have nothing new :(')}</h3>
                    <p>
                        {i18n`
                        But in the meantime, don't forget that we have some great articles in our ${renderHelpCenter()}`}
                    </p>
                </div>
            );
        }

        return wordPressData.map((post, index) => {
            return renderSinglePost(post, index);
        });
    };

    const dashOverlayClassList = classNames('dashboard-overlay', { active });
    const topOverlayClassList = classNames('top-overlay', { active });

    return (
        <>
            <aside
                className={sidebarClassNames}
                aria-hidden={!active}
                title={i18n`What is new`}
                id="whats-new-sidebar">
                <div className="pull-right-flex whats-new-close-icon-container">
                    <button
                        className="whats-new-close-button"
                        onClick={toggleWhatsNew}
                        aria-label="Close what's new"
                        aria-hidden={!active}
                        {...tabIndex}>
                        <i className="whats-new-close-icon cursor-pointer fal fa-times-circle" />
                    </button>
                </div>
                {active && (
                    <div className="whats-new-posts-container">
                        {renderPosts()}
                    </div>
                )}
            </aside>
            <div className={dashOverlayClassList} onClick={toggleWhatsNew} />
            <div className={topOverlayClassList} onClick={toggleWhatsNew} />
        </>
    );
};

export default connect((state: ReduxState) => ({
    whatsNewUserSetting: state.settings.data.user.whatsNew,
}))(WhatsNew);
