import React from 'react';
import TransitionGroup from 'react-addons-css-transition-group';
import { translate } from 'Language';

export default class NotFound extends React.Component {
    render() {
        return (
            <div className="error-page">
                <div className="content">
                    <TransitionGroup
                        transitionName="redirectionWidget"
                        transitionAppear={true}
                        transitionEnterTimeout={500}
                        transitionAppearTimeout={500}
                        transitionLeaveTimeout={300}>
                        <div key="error-container" className="error-container">
                            <div className="bar info"></div>
                            <div className="document-stack-icon">
                                <div className="document-image">
                                    <i className="fa fa-unlink"></i>
                                </div>
                                <div className="lines"></div>

                                <div className="page"></div>
                                <div className="page page-2"></div>
                                <div className="page page-3"></div>
                            </div>
                            <h1 style={{ fontSize: '20pt' }}>404</h1>
                            <TransitionGroup
                                transitionName="redirectionWidget"
                                transitionAppear={true}
                                transitionEnterTimeout={500}
                                transitionAppearTimeout={500}
                                transitionLeaveTimeout={300}>
                                <div key="0">
                                    <h2 className="title">
                                        {translate(`The page you're trying to
                                                        visit is not available`)}
                                    </h2>
                                    <p className="subtitle">
                                        {translate(
                                            'If you think this may be an error'
                                        )}{' '}
                                        <br />
                                        {translate('please contact support.')}
                                    </p>
                                </div>
                            </TransitionGroup>
                        </div>
                    </TransitionGroup>
                </div>
            </div>
        );
    }
}
