import {
    ApiError,
    checkPDFSupport,
    PdfDocument,
    PdfPreviewError,
} from 'Common/components/PdfPreviewer';
import analytics from 'Common/Analytics';
import { fetchDocumentFilePreview } from 'OpenID/utils/documents';
import React, { useCallback, useEffect, useState } from 'react';
import { SigningDocument } from 'types/SigningProcess';

import './signing-casefile-document-preview.scss';

export type Props = {
    id: number;
    challengeKey: string;
    uploadedDocuments: SigningDocument[];
};

const SigningCasefileDocumentPreview = ({
    id,
    challengeKey,
    uploadedDocuments,
}: Props) => {
    const pdfSupport = checkPDFSupport();
    const [index, setIndex] = useState<number>(0);
    const [currentDocument, setCurrentDocument] = useState<SigningDocument>();
    const [pdfDataUrl, setPdfDataUrl] = useState<string>();
    const [pdfData, setPdfData] = useState<Blob>();
    const [error, setError] = useState<ApiError>();

    const getDocumentContent = useCallback(async (): Promise<Blob | null> => {
        if (!currentDocument?.id) {
            return null;
        }

        return await fetchDocumentFilePreview(challengeKey, currentDocument.id);
    }, [currentDocument]);

    const getDocumentPreview = useCallback(async () => {
        // remove 'cached' document from state
        pdfData && setPdfData(undefined);

        try {
            const documentContent = await getDocumentContent();

            if (!documentContent) {
                throw Error('Unable to get the document content');
            }

            /**
             * If the browser supports PDF natively, we use the blob and make it into an object url
             * Otherwise, we maintain the old method of Base64 + PDFjs
             */
            if (pdfSupport) {
                const objectUrl = URL.createObjectURL(documentContent);

                return setPdfDataUrl(objectUrl);
            }

            setPdfData(documentContent);
        } catch (error) {
            triggerError(error);
        }
    }, [getDocumentContent]);

    const triggerError = (error) => {
        analytics.track('PDF render error', {
            documentId: currentDocument?.id,
        });

        setError(error);
    };

    useEffect(() => {
        setIndex(uploadedDocuments.findIndex((doc) => doc.id === id));
        setPdfDataUrl(undefined);
    }, [id, uploadedDocuments]);

    useEffect(() => {
        setCurrentDocument(uploadedDocuments[index]);
    }, [index, uploadedDocuments]);

    useEffect(() => {
        if (currentDocument) {
            getDocumentPreview();
        }
    }, [currentDocument, getDocumentPreview]);

    useEffect(() => {
        return () => {
            // cleanup browser memory after displaying the PDF natively
            if (typeof pdfDataUrl === 'string') {
                URL.revokeObjectURL(pdfDataUrl);
            }
        };
    }, [pdfDataUrl]);

    return (
        <div className="document-preview">
            {error ? (
                <PdfPreviewError error={error} />
            ) : (
                <PdfDocument
                    isNativePDFSupported={pdfSupport}
                    pdfData={pdfDataUrl ?? pdfData}
                />
            )}
        </div>
    );
};

export default SigningCasefileDocumentPreview;
