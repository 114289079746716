import PropTypes from 'prop-types';
import React from 'react';
import PasswordPrompt from '../Common/PasswordPrompt';
import assign from 'object-assign';
import { i18n } from 'Language';
import Validation from 'Common/components/Common/Validation';
import Constants from 'Constants';
import PasswordStore from '../../stores/PasswordStore';
import Button from 'Common/components/Button';
import { notify } from 'react-notify-toast';

let statusTimeout;

export default class PasswordCreateForm extends React.Component {
    static propTypes = {
        isFetching: PropTypes.bool,
        user: PropTypes.object,
        savePassword: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isFetching: false,
    };

    state = {
        name: null,
        password: '',
        repeat: null,
        isValid: false,
        isUsernameValid: true,
        matches: false,
        status: null,
    };

    componentDidMount() {
        PasswordStore.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        clearTimeout(statusTimeout);
        PasswordStore.removeChangeListener(this.onChange);
    }

    onChange = () => {
        this.setState(
            {
                status: PasswordStore.getPasswordChangeStatus(),
            },
            () => this.renderMessage()
        );
    };

    clearStatus = (timeout) => {
        statusTimeout = setTimeout(() => {
            this.setState({ status: null });
        }, timeout);
    };

    renderFailure = (timeout) =>
        notify.show(
            i18n(`Could not save classic credentials`),
            'error',
            timeout
        );

    // @todo: use a generic error function instead of custom message.
    renderUsernameTaken = (timeout) =>
        notify.show(
            <>
                {i18n(`The selected username is already taken`)}
                <br />
                {i18n(`Try a different username`)}
            </>,
            'error',
            timeout
        );

    renderMessage = () => {
        let { status } = this.state;
        const timeout = 5000;

        switch (status) {
            case 'failure':
                this.clearStatus(timeout);

                return this.renderFailure(timeout);
            case 'username-taken':
                this.clearStatus(timeout);

                return this.renderUsernameTaken(timeout);
            default:
                return;
        }
    };

    savePasswordHandler = (event) => {
        event.preventDefault();
        this.props.savePassword(this.state.name, this.state.password);
    };

    changeNameHandler = (event) => {
        event.preventDefault();

        this.setState({
            name: event.target.value || '',
        });
    };

    promptChangeHandler = (passwordChanges) => {
        let passwordState = assign(this.state, passwordChanges);

        this.setState(passwordState);
    };

    getUsername = () => {
        let { user } = this.props;
        let { name } = this.state;

        // If name is set, or empty
        if (name || name === '') {
            return name;
        }

        return user.email;
    };

    isFormValid = () => {
        let { matches, isValid, isUsernameValid } = this.state;

        if (matches && isValid && isUsernameValid) {
            return true;
        }

        return false;
    };

    isUsernameValid = (value) => {
        this.setState({
            isUsernameValid: value,
        });
    };

    render() {
        let { isFetching } = this.props;
        let { password, repeat, matches, isValid } = this.state;

        const usernameValidation = Constants.validationRules.username;
        let isFormValid = this.isFormValid();

        return (
            <div className="password-reset-form">
                <label htmlFor="name">{i18n('User Name')}</label>

                <Validation
                    value={this.getUsername()}
                    rules={usernameValidation}
                    callback={this.isUsernameValid}>
                    <input
                        id="name"
                        type="text"
                        value={this.getUsername()}
                        onChange={this.changeNameHandler}
                        placeholder={i18n('Enter your username')}
                    />
                </Validation>

                <PasswordPrompt
                    password={password}
                    repeat={repeat}
                    isValid={isValid}
                    matches={matches}
                    changeHandler={this.promptChangeHandler}
                />

                <br />
                <Button
                    theme="blue"
                    disabled={!isFormValid || isFetching}
                    onClick={isFormValid ? this.savePasswordHandler : null}>
                    {isFetching ? i18n('Saving') : i18n('Save Password')}
                </Button>
            </div>
        );
    }
}
