import React from 'react';
import { i18n } from 'Language';
import { Link } from 'react-router';
import { Config } from 'Common/components/BaseTable/Table';
import moment from 'moment';
import { getKYCCasefileStatus } from 'Casefiles/CasefileDetails/statusCodes';
import Status from 'Casefiles/Archive/Status';
import { KYCListItem } from 'KYC/redux/types';
import Analytics from 'Common/Analytics';
import KYCListItemActions from 'KYC/components/KYCListItemActions';

export const getTableConfig = (
    kycListTab: 'pending' | 'filled',
    refreshData: Function
): Config => {
    if (kycListTab === 'pending') {
        return {
            title: {
                label: i18n('Title'),
                tdClassName: 'no-padding',
                component: (item: KYCListItem) => (
                    <Link
                        className="table-row-link"
                        onClick={() => trackItemClick(item)}
                        to={{
                            name: 'kyc-item-details',
                            params: {
                                id: item.id,
                            },
                        }}>
                        <div className="td-padding">{item.title}</div>
                    </Link>
                ),
            },
            recipient: {
                disableSort: true,
                label: i18n('Recipient'),
                tdClassName: 'no-padding',
                component: (item: KYCListItem) => (
                    <Link
                        onClick={() => trackItemClick(item)}
                        to={{
                            name: 'kyc-item-details',
                            params: {
                                id: item.id,
                            },
                        }}>
                        <div className="td-padding">
                            {item.recipient.name}&nbsp;
                            <span className="text-blue text-small">
                                ({item.recipient.email})
                            </span>
                        </div>
                    </Link>
                ),
            },
            status: {
                thClassName: 'column-type',
                tdClassName: 'column-type',
                label: i18n('Status'),
                component: (item: KYCListItem) => (
                    <Status
                        status={{
                            name: 'Pending',
                            color: 'yellow',
                            icon: 'far fa-paper-plane',
                            details: `Sent ${moment
                                .unix(item.createdAt)
                                .fromNow()}`,
                        }}
                    />
                ),
            },
            createdAt: {
                label: i18n('Created At'),
                component: (item: KYCListItem) => (
                    <div>{moment.unix(item.createdAt).fromNow()}</div>
                ),
            },
            actions: {
                label: '',
                disableSort: true,
                disableAnimation: true,
                component: (item: KYCListItem) => (
                    <KYCListItemActions kyc={item} onDelete={refreshData} />
                ),
                tdClassName: 'column-minimize column-show-on-row-hover',
            },
        };
    }

    return {
        title: {
            label: i18n('Title'),
            tdClassName: 'no-padding',
            component: (item: KYCListItem) => (
                <Link
                    className="table-row-link"
                    onClick={() => trackItemClick(item)}
                    to={{
                        name: 'kyc-item-details',
                        params: {
                            id: item.id,
                        },
                    }}>
                    <div className="td-padding">{item.title}</div>
                </Link>
            ),
        },
        recipient: {
            disableSort: true,
            label: i18n('Recipient'),
            tdClassName: 'no-padding',
            component: (item: KYCListItem) => (
                <Link
                    className="table-row-link"
                    onClick={() => trackItemClick(item)}
                    to={{
                        name: 'kyc-item-details',
                        params: {
                            id: item.id,
                        },
                    }}>
                    <div className="td-padding">
                        {item.recipient.name}&nbsp;
                        <span className="text-blue text-small">
                            ({item.recipient.email})
                        </span>
                    </div>
                </Link>
            ),
        },
        status: {
            disableSort: true,
            thClassName: 'column-type',
            tdClassName: 'column-type',
            label: i18n('Status'),
            component: (item) => renderFilledKYCStatus(item),
        },
        createdAt: {
            label: i18n('Created At'),
            tdClassName: 'column-title',
            component: (item: KYCListItem) => (
                <div>{moment.unix(item.createdAt).fromNow()}</div>
            ),
        },
        filledAt: {
            label: i18n('Filled At'),
            tdClassName: 'column-title',
            component: (item: KYCListItem) => (
                <div>
                    {item.filledAt
                        ? moment.unix(item.filledAt).fromNow()
                        : '- - -'}
                </div>
            ),
        },
    };
};

const renderFilledKYCStatus = (kycItem: KYCListItem) => {
    let status;

    // The filled kyc items have a status property in string format. We have a collection of
    // statuses using the casefile status number format. In the switch statement below, we match
    // the string status names to the numeric status to be able to look them up in our status list.
    switch (kycItem.status) {
        case 'completed':
            status = getKYCCasefileStatus(5);
            break;
        case 'rejected':
            status = getKYCCasefileStatus(2);
            break;
        case 'pending':
        default:
            status = getKYCCasefileStatus(1);
            break;
    }

    return <Status status={status} />;
};

const trackItemClick = (item: KYCListItem) => {
    Analytics.track('kyc management - row click', {
        id: item.id,
        caseFileId: item.caseFileId,
        createdAt: item.createdAt,
        filledAt: item.filledAt,
    });
};
