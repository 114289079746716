import PropTypes from 'prop-types';
import React from 'react';
import { TranslationStore } from 'Language';
import { AuthStore } from 'Auth';
import Loader from '../Common/Loader';
import { BrandingStore } from 'Branding';

import BootstrapLanguage from './BootstrapLanguage';
import BootstrapBranding from './BootstrapBranding';
import { Components as AuthComponents } from 'Auth';

import { Link, browserHistory } from 'react-router';
import TransitionGroup from 'react-addons-css-transition-group';

import PresharedErrorPage from '../Common/PresharedErrorPage';
import SessionErrorPage from '../Common/SessionErrorPage';
import { ERRORS } from 'Core/Errors';

let BootstrapAuthentication = AuthComponents.AuthenticatedComponent;

const BootstrappingContainer = (Component, parameters) => {
    return class BootstrappingContainer extends React.Component {
        static propTypes = {
            children: PropTypes.object,
        };

        state = {
            user: AuthStore.getUser(),
            customer: AuthStore.getCustomer(),
            settings: AuthStore.getSettings(),
            language: TranslationStore.getLanguage(),
            branding: BrandingStore.getBranding(),
            error: AuthStore.getAuthError(),
        };

        componentWillMount() {
            if (parameters.requireAuth) {
                this.attachAuthListeners();
            }

            if (parameters.requireLanguage) {
                this.attachTranslationListeners();
            }

            if (parameters.requireBranding) {
                this.attachBrandingListeners();
            }
        }

        componentWillUnmount() {
            AuthStore.removeChangeListener(this.onChange);
            TranslationStore.removeChangeListener(this.onChange);
            BrandingStore.removeChangeListener(this.onChange);
        }

        attachAuthListeners = () => {
            AuthStore.addChangeListener(this.onChange);
        };

        attachBrandingListeners = () => {
            BrandingStore.addChangeListener(this.onChange);
        };

        attachTranslationListeners = () => {
            TranslationStore.addChangeListener(this.onChange);
        };

        onChange = () => {
            this.setState({
                user: AuthStore.getUser(),
                customer: AuthStore.getCustomer(),
                settings: AuthStore.getSettings(),
                language: TranslationStore.getLanguage(),
                branding: BrandingStore.getBranding(),
                error: AuthStore.getAuthError(),
            });
        };

        isApplicationLoaded = () => {
            let isLoaded = {};
            let { requireAuth, requireLanguage, requireBranding } = parameters;
            let { error } = this.state;
            let authenticationError = parameters.requireAuth && error;

            if (authenticationError) {
                return false;
            }

            if (requireAuth) {
                isLoaded.authLoaded = !!this.state.user;
            }

            if (requireLanguage) {
                isLoaded.languageLoaded = !!this.state.language;
            }

            if (requireBranding) {
                isLoaded.brandingLoaded = !!this.state.branding;
            }

            let { authLoaded, languageLoaded, brandingLoaded } = isLoaded;

            let isAuthLoaded = !!requireAuth === !!authLoaded;
            let isLanguageLoaded = !!requireLanguage === !!languageLoaded;
            let isBrandingLoaded = !!requireBranding === !!brandingLoaded;

            if (isAuthLoaded && isLanguageLoaded && isBrandingLoaded) {
                return true;
            }

            return false;
        };

        handleAuthenticationError = (error) => {
            switch (error.code) {
                case ERRORS.AUTH_SESSION_EXPIRED:
                    return <SessionErrorPage />;
                case ERRORS.AUTH_PRESHARED_TOKEN_INVALID:
                    return <PresharedErrorPage />;
                default:
                    return <SessionErrorPage />;
            }
        };

        attachBootstrappingComponents = () => {
            let { requireAuth, requireLanguage, requireBranding } = parameters;

            return (
                <div>
                    {requireAuth && <BootstrapAuthentication {...this.props} />}

                    {/*
                        When the application requires authentication, the language will
                        be initialized as a consequence of the authentication of the user.

                        This means <BootstrapLanguage/> is only needed in public pages (like the login page)
                    */}
                    {requireLanguage && !requireAuth && <BootstrapLanguage />}

                    {requireBranding && requireAuth && this.state.user && (
                        <BootstrapBranding
                            user={this.state.user}
                            {...this.props}
                        />
                    )}

                    {requireBranding && !requireAuth && (
                        <BootstrapBranding {...this.props} />
                    )}
                </div>
            );
        };

        renderClonedElement = (component) => {
            return React.cloneElement(component, this.state);
        };

        skipLink = (id) => {
            const currentLocation = browserHistory.getCurrentLocation();

            return `${currentLocation?.pathname ?? '/'}#${id}`;
        };

        render() {
            let { error } = this.state;
            let applicationLoaded = this.isApplicationLoaded();
            let authenticationError = parameters.requireAuth && error;
            const currentLocation = browserHistory.getCurrentLocation();
            const navLink = this.skipLink('nav');
            const contentLink = this.skipLink('content');

            return (
                <>
                    <p
                        id={'route-announcer'}
                        className="sr-only"
                        aria-live={'polite'}>
                        {`Navigating to ${
                            currentLocation?.pathname ??
                            window.location.pathname
                        }`.replace('/', ' ')}
                    </p>
                    {applicationLoaded && (
                        <>
                            <Link
                                href={contentLink}
                                className={'sr-only skip-link'}>
                                Skip to content
                            </Link>
                            <Link
                                href={navLink}
                                className={'sr-only skip-link'}>
                                Skip to navigation
                            </Link>
                        </>
                    )}
                    <div>
                        {!applicationLoaded && !authenticationError && (
                            <Loader />
                        )}

                        {authenticationError &&
                            this.handleAuthenticationError(error)}

                        {applicationLoaded && !authenticationError && (
                            <TransitionGroup
                                transitionName="fadeIn"
                                transitionAppear={true}
                                transitionEnterTimeout={500}
                                transitionAppearTimeout={500}
                                transitionLeaveTimeout={300}>
                                {Component ? (
                                    <Component
                                        {...this.props}
                                        {...this.state}
                                    />
                                ) : (
                                    this.renderClonedElement(
                                        this.props.children
                                    )
                                )}
                            </TransitionGroup>
                        )}

                        {this.attachBootstrappingComponents()}
                    </div>
                </>
            );
        }
    };
};

export default BootstrappingContainer;
