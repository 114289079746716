import { Dispatcher, BaseStore } from 'Core';
import { PasswordActions as Actions } from '../ActionTypes';
import assign from 'object-assign';

let _passwordCredentials = null;

let _credentials = {
    username: '',
    password: '',
};

let _passwordChangeState = null;
let _error = null;

function updateUsername(username) {
    _credentials.username = username;
}

function updatePassword(password) {
    _credentials.password = password;
}

function updatePasswordCredentials(credentials) {
    _passwordCredentials = credentials;
}

function updateError(error) {
    _error = error;
}

function resetStore() {
    _passwordCredentials = null;
    _passwordChangeState = null;
    _credentials = {
        username: null,
        password: null,
    };
    _error = null;
}

function resetState() {
    _passwordChangeState = null;
}

// Flux Store Creation
const PasswordStore = assign({}, BaseStore, {
    getCredentials() {
        return _credentials;
    },

    getPasswordChangeStatus() {
        return _passwordChangeState;
    },

    getPasswordCredentials() {
        return _passwordCredentials;
    },

    getError() {
        return _error;
    },

    resetStore() {
        resetStore();
        PasswordStore.emitChange();
    },

    resetState() {
        resetState();
        PasswordStore.emitChange();
    },

    resetPasswordStatus() {
        _passwordChangeState = null;
        PasswordStore.emitChange();
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Actions.PASSWORD_UPDATE_USERNAME:
                updateUsername(action.username);
                break;
            case Actions.PASSWORD_UPDATE_PASSWORD:
                updatePassword(action.password);
                break;
            case Actions.PASSWORD_CHANGE_REQUEST:
                _passwordChangeState = 'request';
                break;
            case Actions.PASSWORD_CHANGE_SUCCESS:
                _passwordChangeState = 'success';
                updatePasswordCredentials(action.credentials);
                break;
            case Actions.PASSWORD_CHANGE_ERROR:
                updateError(action.error);
                _passwordChangeState = 'failure';
                break;
            case Actions.PASSWORD_FETCH_SUCCESS:
                updatePasswordCredentials(action.credentials);
                PasswordStore.emit(action.type, action.credentials);
                break;
            case Actions.PASSWORD_FETCH_ERROR:
                // updatePasswordCredentials({});
                PasswordStore.emit(action.type);
                break;
            case Actions.PASSWORD_CREATE_SUCCESS:
                _passwordChangeState = 'success';
                updatePasswordCredentials(action.credentials);
                break;
            case Actions.PASSWORD_CREATE_ERROR:
                if (action.code) {
                    _passwordChangeState = action.code;
                    break;
                }

                _passwordChangeState = 'failure';
                updateError(action.error);
                break;
            default:
                return false;
        }

        PasswordStore.emitChange();
    }),
});

export default PasswordStore;
