import React from 'react';
import { i18n } from 'Language';
import Tooltip from 'Common/components/Tooltip';

import './tooltip-required.scss';

export type Props = {
    /**
     * Overrides the default tooltip message
     */
    text?: string;
};

/**
 * A simple default for a required marker ("*") with a tooltip
 *
 * It is usually used next or inside a `<label>` element
 *
 * @example
 *   <label htmlFor="the-field">
 *       {label}
 *       {required && <TooltipRequired />}
 *   </label>
 */
const TooltipRequired: React.FunctionComponent<Props> = ({
    text = i18n`This field is required`,
}) => {
    return (
        <Tooltip className="tooltip-required" showArrow={true} text={text}>
            <i className="text-error fas fa-asterisk"></i>
        </Tooltip>
    );
};

export default TooltipRequired;
