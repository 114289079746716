import React, { Children, cloneElement } from 'react';
import classnames from 'classnames';
import './dashboard-widget-container.scss';

type WidgetProps = {
    title?: string | JSX.Element;
    children: JSX.Element;
    footer?: JSX.Element;
    header?: JSX.Element;
};

type Props = {
    className?: string;
    content: JSX.Element | JSX.Element[];
    title?: string;
};

export const DashboardWidget = ({
    title,
    children,
    header,
    footer,
}: WidgetProps) => (
    <section
        className="dashboard-widget-content"
        aria-label={`${title} - widget content`}>
        <header className="dashboard-widget-header">
            {title && <h2 className="dashboard-widget-title">{title}</h2>}
            {header}
        </header>
        {children}
        {footer && <footer className="dashboard-widget-link">{footer}</footer>}
    </section>
);

const DashboardWidgetContainer = ({ className, content, title }: Props) => (
    <article
        aria-label={`${title ?? className} - widget`}
        className={classnames('dashboard-widget', className)}>
        <div className={'dashboard-widget-container'}>
            {Children.map(content, (child, i) => {
                return cloneElement(child, { key: i });
            })}
        </div>
    </article>
);

export default DashboardWidgetContainer;
