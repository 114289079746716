import axios from 'axios';

export default class LibLoader {
    constructor(libName) {
        this.manifest = '/manifest.json';
        this.libName = libName;

        this.script = null;
        this.isLoaded = false;
    }

    isLoaded() {
        return this.isLoaded;
    }

    load() {
        const baseUrl = `${window.location.protocol}//${window.location.host}`;

        return axios
            .get(this.manifest)
            .then(({ data }) => {
                return data[this.libName];
            })
            .then((libUrl) => {
                // Create script tag.
                this.script = document.createElement('script');
                this.script.src = `${baseUrl}/${libUrl}`;

                // Mount script on DOM and assign element reference
                // to local script object
                var head =
                    document.head || document.getElementsByTagName('head')[0];

                head.appendChild(this.script);

                // Bind the clientId to script loaded callback.
                // @todo: needs onreadystate to support IE.
                return new Promise((resolve) => {
                    this.script.onload = () => {
                        this.isLoaded = true;

                        return resolve(this.script);
                    };
                });
            });
    }

    unload() {
        document.head.removeChild(this.script);

        // Clear element reference
        this.isLoaded = false;
        this.script = null;
    }
}
