export type PenneoCrypto = {
    Attribute;
    AttributeTypeAndValue;
    CertificateRevocationList;
    CertificateChainValidationEngine;
    CertificationRequest;
    Certificate;
    Extension;
    Extensions;
    RelativeDistinguishedNames;
    RSAPublicKey;
    getCrypto;
    fromBER;
    Utf8String;
    OctetString;
    BitString;
    stringToArrayBuffer;
    fromBase64;
    bufferToHexCodes;
    arrayBufferToString;
    toBase64;
    SignedXml;
    Parse;
};

export type WindowWithCrypto = Window &
    typeof globalThis & {
        penneo: {
            crypto: PenneoCrypto;
        };
    };

export const getCrypto = () => (window as WindowWithCrypto).penneo.crypto;
