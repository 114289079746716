export const FETCH_MESSAGE_LIST_TEMPLATES_REQUEST =
    'FETCH_MESSAGE_LIST_TEMPLATES_REQUEST';
export const FETCH_MESSAGE_LIST_TEMPLATES_SUCCESS =
    'FETCH_MESSAGE_LIST_TEMPLATES_SUCCESS';
export const FETCH_MESSAGE_LIST_TEMPLATES_FAILURE =
    'FETCH_MESSAGE_LIST_TEMPLATES_FAILURE';

export const FETCH_UPDATE_MESSAGE_TEMPLATE_REQUEST =
    'FETCH_UPDATE_MESSAGE_TEMPLATE_REQUEST';
export const FETCH_UPDATE_MESSAGE_TEMPLATE_SUCCESS =
    'FETCH_UPDATE_MESSAGE_TEMPLATE_SUCCESS';
export const FETCH_UPDATE_MESSAGE_TEMPLATE_FAILURE =
    'FETCH_UPDATE_MESSAGE_TEMPLATE_FAILURE';

export const FETCH_DELETE_MESSAGE_TEMPLATE_REQUEST =
    'FETCH_DELETE_MESSAGE_TEMPLATE_REQUEST';
export const FETCH_DELETE_MESSAGE_TEMPLATE_SUCCESS =
    'FETCH_DELETE_MESSAGE_TEMPLATE_SUCCESS';
export const FETCH_DELETE_MESSAGE_TEMPLATE_FAILURE =
    'FETCH_DELETE_MESSAGE_TEMPLATE_FAILURE';

export const FETCH_CREATE_MESSAGE_TEMPLATE_REQUEST =
    'FETCH_CREATE_MESSAGE_TEMPLATE_REQUEST';
export const FETCH_CREATE_MESSAGE_TEMPLATE_SUCCESS =
    'FETCH_CREATE_MESSAGE_TEMPLATE_SUCCESS';
export const FETCH_CREATE_MESSAGE_TEMPLATE_FAILURE =
    'FETCH_CREATE_MESSAGE_TEMPLATE_FAILURE';
