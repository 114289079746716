import PropTypes from 'prop-types';
import React from 'react';
import { DocumentFormAttachment } from './DocumentFormAttachment';
import FormCoSigner from './DocumentFormCoSigner';
import HoverTooltip from '../Common/HoverTooltip';
import { modal } from 'Common/components/Common/Modal';
import RejectForm from './RejectForm';
import { i18n } from 'Language';
import tour from 'Common/utils/tour';

import moment from 'moment';
import DatePicker from 'react-datepicker';

import { aggregateSSN, splitAggregatedSSN } from 'utils';
import Validation from 'Common/components/Common/Validation';
import { validationRules } from 'Constants';
import Button from 'Common/components/Button';
import SsnInput from 'Common/components/SsnInput';
import WorkflowActions from 'Forms/actions/WorkflowActionCreators';

export default class DocumentForm extends React.Component {
    static propTypes = {
        document: PropTypes.object,
        fields: PropTypes.array,
        updateField: PropTypes.func.isRequired,
        highlightField: PropTypes.func,
        saveFormHandler: PropTypes.func,
        rejectHandler: PropTypes.func,
        removeHighlight: PropTypes.func,
        coSigner: PropTypes.object,
        validationErrors: PropTypes.array,
        form: PropTypes.object,
        rejectionMessage: PropTypes.object,
        workflow: PropTypes.object,
        previewMode: PropTypes.bool,
        attachments: PropTypes.array,
        highlightedField: PropTypes.number,
    };

    static defaultProps = {
        validationErrors: [
            'Please, complete all required fields before saving',
            'You need to fill both name and email for the co-signer',
            'Please upload at least one attachment',
        ],
    };

    state = {
        isValidated: false,
        validationText: 0,
        validation: {},
    };

    validateFields = (props) => {
        const { fields, coSigner, attachments, form, workflow } = props;
        let coSignerNameSet = coSigner.name && coSigner.name.length > 0;
        let coSignerEmailSet = coSigner.email && coSigner.email.length > 0;
        let { validation } = this.state;

        for (let i = 0; i < fields.length; i++) {
            if (validation[fields[i].id] === false) {
                this.setState({ isValidated: false, validationText: 0 });

                return;
            }
        }

        if (!workflow.settings) {
            this.setState({ isValidated: false });

            return;
        }

        if (workflow.settings.allowAttachment) {
            if (
                workflow.settings.requireAttachment &&
                !(attachments.length > 0)
            ) {
                this.setState({ isValidated: false, validationText: 2 });

                return;
            }
        } else {
            this.setState({ isValidated: true });
        }

        let requireCoSigner =
            form.coSigner || workflow.settings.requireCoSigner;

        if (requireCoSigner && (!coSignerNameSet || !coSignerEmailSet)) {
            this.setState({ validationText: 1 });
            this.setState({ isValidated: false });

            if (!workflow.settings.requireCoSigner) {
                this.setState({ isValidated: true });
            }
        } else {
            this.setState({ isValidated: true });
        }
    };

    componentWillReceiveProps(props) {
        this.validateFields(props);
    }

    preventDefault = (event) => {
        event.preventDefault();
    };

    /**
     * Updates value on field state and propagates the value to all mapped fields with
     * the same field ID
     * @param field {object} field state object containing field value and id
     * @param event {event} onChange event, activated by text change on mapped field.
     * @param value {string} The new value of the field, in case it can't be
     *      extracted from the event object directly
     */
    updateFieldHandler = (field, event, value = undefined) => {
        if (this.props.previewMode) {
            return false;
        }

        event.preventDefault();

        let fieldValue = value || event.target.value;

        if (field.type === 'textarea') {
            fieldValue = fieldValue.substr(0, 2001); //Limit input to max 2000 characters (exclusive)
        }

        this.props.updateField(field.id, fieldValue || event.target.value);
    };

    updateDateHandler = (field, event) => {
        if (!event) {
            return this.props.updateField(field.id, null);
        }

        if (event._isAMomentObject) {
            return this.props.updateField(field.id, event.format('DD/MM/YYYY'));
        }

        // Update field with value
        return this.props.updateField(field.id, event.target.value);
    };

    updateValidations = (field, valid) => {
        let { validation } = this.state;

        validation[field.id] = valid;
        this.setState({ validation: validation });

        // Trigger a validation
        this.validateFields(this.props);
    };

    onBlur = (field, event) => {
        this.props.removeHighlight(field, event);
        let date = moment(event.target.value, 'DD/MM/YYYY');

        if (!date._isValid) {
            return this.props.updateField(field.id, '');
        }

        return this.props.updateField(field.id, date.format('DD/MM/YYYY'));
    };

    createField = (field, index) => {
        let fieldJSX;
        let { highlightField, highlightedField, removeHighlight } = this.props;

        let isFocused = highlightedField === field.id;
        let isModified = !(
            field.value === null || typeof field.value === 'undefined'
        );

        let displayHint = isFocused || isModified;

        let { updateFieldHandler } = this;

        // @todo: legacy forms don't have a type, and RECIPIENT_EMAIL and COSIGNER_EMAIL
        // were hardcoded fields used for email types. This will make them render appropiately.
        const legacyEmailFields = ['RECIPIENT_EMAIL', 'COSIGNER_EMAIL'];

        if (legacyEmailFields.indexOf(field.name) !== -1) {
            field.metaData.type = 'email';
        }

        let rules = field.required ? validationRules.required : [];

        switch (field.metaData.type) {
            case 'email':
                rules = rules.concat(validationRules.email); // append email validation

                fieldJSX = (
                    <input
                        type="email"
                        onFocus={highlightField.bind(null, field)}
                        onBlur={removeHighlight.bind(null, field)}
                        value={field.value || ''}
                        onChange={updateFieldHandler.bind(null, field)}
                        required={field.required}
                    />
                );
                break;
            case 'textarea':
                fieldJSX = (
                    <textarea
                        key={index}
                        id={'field-' + field.id}
                        type={field.metaData.type}
                        // placeholder={field.label}
                        value={field.value || ''}
                        onFocus={highlightField.bind(null, field)}
                        onBlur={removeHighlight.bind(null, field)}
                        maxLength={2000}
                        onChange={updateFieldHandler.bind(
                            null,
                            field
                        )}></textarea>
                );
                break;
            case 'date':
                fieldJSX = (
                    <div className="match-parent">
                        <DatePicker
                            key={index}
                            placeholderText="dd/mm/yyyy"
                            disabledKeyboardNavigation
                            dateFormat="DD/MM/YYYY"
                            onFocus={highlightField.bind(null, field)}
                            onBlur={this.onBlur.bind(null, field)}
                            value={field.value || ''}
                            selected={
                                field.value
                                    ? moment(field.value, 'DD/MM/YYYY')
                                    : ''
                            }
                            onChangeRaw={this.updateDateHandler.bind(
                                null,
                                field
                            )}
                            onChange={this.updateDateHandler.bind(null, field)}
                            required={field.required}
                        />
                    </div>
                );
                break;
            case 'ssn':
                rules = rules.concat(validationRules.ssn); // append ssn validation

                const splitValue = splitAggregatedSSN(field.value);

                fieldJSX = (
                    <SsnInput
                        value={splitValue?.ssn}
                        ssnType={splitValue?.typeId}
                        onFocus={highlightField.bind(null, field)}
                        onBlur={removeHighlight.bind(null, field)}
                        required={field.required}
                        placeholder={i18n`Type SSN`}
                        onChange={({ ssnType, value }, event) => {
                            const newValue = aggregateSSN(value, ssnType);

                            updateFieldHandler(field, event, newValue);
                        }}
                    />
                );
                break;
            case 'vatin':
                rules = rules.concat(validationRules.vatin); // append vatin validation

                fieldJSX = (
                    <input
                        type="text"
                        onFocus={highlightField.bind(null, field)}
                        onBlur={removeHighlight.bind(null, field)}
                        value={field.value || ''}
                        onChange={updateFieldHandler.bind(null, field)}
                        required={field.required}
                    />
                );
                break;
            case 'select':
                fieldJSX = (
                    <select
                        onFocus={highlightField.bind(null, field)}
                        onBlur={removeHighlight.bind(null, field)}
                        value={field.value || '-1'}
                        onChange={updateFieldHandler.bind(null, field)}
                        required={field.required}>
                        <option
                            value="-1"
                            disabled>{i18n`Select an option`}</option>
                        {field.metaData.options.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                );
                break;
            case 'text':
            default:
                fieldJSX = (
                    <input
                        type="text"
                        onFocus={highlightField.bind(null, field)}
                        onBlur={removeHighlight.bind(null, field)}
                        value={field.value || ''}
                        onChange={updateFieldHandler.bind(null, field)}
                        required={field.required}
                    />
                );
                break;
        }

        // If field is optional and empty, remove validation rules
        if (
            !field.required &&
            (!field.value || (field.value && field.value.length === 0))
        ) {
            rules = [];
        }

        return (
            <div key={index}>
                <p className="field-label">
                    {field.label}&nbsp;
                    {field.required && (
                        <i className="required fa fa-asterisk text-error" />
                    )}
                </p>
                <Validation
                    rules={rules}
                    value={field.value}
                    callback={this.updateValidations.bind(null, field)}
                    displayError={displayHint}>
                    {fieldJSX}
                </Validation>
            </div>
        );
    };

    handleFormSave = () => {
        let modalContent = {
            title: null,
            body: (
                <div>
                    <br />
                    <div className="text-center text-medium">
                        <h1>
                            <i className="far fa-sync fa-lg fa-spin"></i>
                            <p>{i18n`Loading...`}</p>
                        </h1>
                    </div>
                    <br />
                </div>
            ),
            preventClose: true,
        };

        modal.show(modalContent);

        this.props.saveFormHandler();
    };

    promptFormSubmit = (event) => {
        event.preventDefault();
        tour.endTour();

        // Don't prompt if preview mode is enabled.
        if (this.props.previewMode) {
            return false;
        }

        let config = {
            title: i18n`Are you sure?`,
            body: i18n`Are you sure you’ve filled out the form correctly?
                   You won’t be able to change your answers later.`,
            buttons: (
                <div>
                    <Button onClick={modal.hide}>{i18n`Back`}</Button>
                    <Button theme="green" onClick={this.handleFormSave}>
                        {i18n`Yes, save and continue`}
                    </Button>
                </div>
            ),
            preventClose: true,
        };

        modal.show(config);
    };

    onFileChangeHandler = (event) => {
        let { files } = event.target;

        WorkflowActions.updateAttachments(files);
    };

    onFileRemoveHandler = (index) => {
        WorkflowActions.removeAttachment(index);
    };

    render() {
        let { fields, attachments, validationErrors, form } = this.props;
        let {
            rejectionMessage,
            rejectHandler,
            workflow,
            previewMode,
        } = this.props;

        let { isValidated, validationText } = this.state;

        let tooltipText = validationErrors[validationText];

        let editableFields = fields.filter((field) => field.editable);

        return (
            <div className="form-sidebar">
                <div className="sidebar-content">
                    <form
                        action=""
                        onSubmit={
                            isValidated
                                ? this.promptFormSubmit
                                : this.preventDefault
                        }>
                        {/* Forms with submit action and several buttons require a dummy button
                            to avoid triggering the onClick event on the first button that appears
                            in the markup (in this case, the reject button), which is the
                            incorrect default action for this form.
                            @see: http://stackoverflow.com/a/14463642/781779 */}
                        <button
                            aria-hidden="true"
                            tabIndex="-1"
                            style={{
                                width: '0px',
                                height: '0px',
                                margin: '0px',
                                padding: '0px',
                                outline: 'none',
                                border: '0px',
                                position: 'absolute',
                            }}></button>

                        {editableFields.length > 0 ? (
                            <div>
                                <h4>
                                    {i18n`Fill the form to complete the
                                          information on the document`}
                                </h4>
                                <p className="note">
                                    <i className="fa fa-info-circle text-medium"></i>
                                    &nbsp;
                                    {i18n`Fields marked with
                                        ${(
                                            <i className="required fa fa-asterisk text-error" />
                                        )}
                                        are required`}
                                </p>
                            </div>
                        ) : (
                            <h4>
                                {i18n`This form has no fields to fill. Please read the document and
                                    click on "Save and continue" below`}
                            </h4>
                        )}

                        {workflow.id && !workflow.settings && (
                            <div>
                                {!previewMode && (
                                    <DocumentFormAttachment
                                        attachments={attachments}
                                        onFileChangeHandler={
                                            this.onFileChangeHandler
                                        }
                                        onFileRemoveHandler={
                                            this.onFileRemoveHandler
                                        }
                                    />
                                )}
                            </div>
                        )}

                        {workflow.settings &&
                            workflow.settings.allowAttachment &&
                            !previewMode && (
                                <DocumentFormAttachment
                                    attachments={attachments}
                                    required={
                                        workflow.settings.requireAttachment
                                    }
                                    onFileChangeHandler={
                                        this.onFileChangeHandler
                                    }
                                    onFileRemoveHandler={
                                        this.onFileRemoveHandler
                                    }
                                />
                            )}

                        <div className="inputs">
                            {editableFields.map((field, index) =>
                                this.createField(field, index)
                            )}
                        </div>

                        <br />
                        {workflow.id && !workflow.settings && (
                            <div>
                                <FormCoSigner coSigner={form.coSigner} />
                            </div>
                        )}

                        {workflow.settings &&
                            workflow.settings.allowCoSigner && (
                                <FormCoSigner
                                    coSigner={workflow.settings.allowCoSigner}
                                />
                            )}
                        {!previewMode && (
                            <div className="form-actions">
                                <ul className="progress">
                                    <li className="active">
                                        {i18n`1. Fill the form fields`}
                                    </li>
                                    <li>{i18n`2. Sign document`}</li>
                                </ul>
                                <div className="buttons">
                                    {rejectHandler && (
                                        <div className="button-wrapper">
                                            <RejectForm
                                                rejectHandler={rejectHandler}
                                                message={rejectionMessage}
                                            />
                                        </div>
                                    )}
                                    <div className="button-wrapper">
                                        <HoverTooltip
                                            text={
                                                !isValidated
                                                    ? i18n(tooltipText)
                                                    : null
                                            }>
                                            <Button
                                                theme="green"
                                                fullWidth
                                                icon="far fa-check"
                                                disabled={!isValidated}>
                                                {i18n`Save and continue`}
                                            </Button>
                                        </HoverTooltip>
                                    </div>
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        );
    }
}
