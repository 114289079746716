import React from 'react';
import { i18n } from 'Language';
import StartWorkWidgetCTA from './StartWorkWidgetCTA';
import DashboardWidgetContainer, {
    DashboardWidget,
} from './DashboardWidgetContainer';
import './start-work-widget.scss';
import classnames from 'classnames';
import { trackDashboardInteraction } from './utils';
import {
    hasCasefileSendAccess,
    hasFormsAccess,
} from 'Common/utils/userPermissions';
import { UserEntity } from 'types/User';

export type Props = {
    user: UserEntity;
    disabled?: boolean;
};

const StartWorkWidget = ({ user, disabled }: Props) => {
    const renderContent = () => (
        <DashboardWidget title={i18n`Let's get to work`}>
            <div
                className="start-work-widget-cta-container"
                data-testid={disabled && `start-working-disabled`}>
                <StartWorkWidgetCTA
                    onClick={() => {
                        trackDashboardInteraction('Case File - create new', {
                            source: 'dashboard widget',
                        });
                    }}
                    link={{
                        name: 'casefile-create',
                        params: {
                            action: 'new',
                        },
                    }}
                    icon="briefcase"
                    buttonLabel="Create new"
                    buttonIcon="plus-circle"
                    title="Case file"
                    subtitle="Send documents for signature"
                    disabled={!hasCasefileSendAccess(user)}
                />

                <StartWorkWidgetCTA
                    onClick={() => {
                        trackDashboardInteraction('Web Form - create new', {
                            source: 'dashboard widget',
                        });
                    }}
                    link="template-creator"
                    icon="file-alt"
                    buttonLabel="Create new"
                    buttonIcon="plus-circle"
                    title="Web form"
                    subtitle="Create forms to fill and sign via URL"
                    disabled={!hasFormsAccess(user)}
                />
            </div>
        </DashboardWidget>
    );

    return (
        <DashboardWidgetContainer
            title={i18n`Let's get to work`}
            className={classnames('new-start-work-widget', {
                'is-disabled': disabled,
            })}
            content={[renderContent()]}
        />
    );
};

export default StartWorkWidget;
