import PropTypes from 'prop-types';
import React from 'react';
import NewsActions from 'News/actions/NewsActions';
import NewsStore from 'News/stores/NewsStore';
import NewsFeedItem from './NewsFeedItem';
import Loading from 'Common/components/Loaders/LoadingData';
import moment from 'moment';
import TransitionGroup from 'react-addons-css-transition-group';
import { i18n } from 'Language';

export default class NewsFeed extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
    };

    state = NewsStore.getState();

    componentDidMount() {
        if (this.state.news.length > 0) {
            this.updateReadTimestamp();
        }

        NewsStore.addChangeListener(this.onChange);
    }

    componentWillUpdate(nextProps, nextState) {
        // If news articles were not loaded before opening the news feed
        // the last read timestamp has to be updated when the news are fetched.
        if (this.state.news.length === 0 && nextState.news.length > 0) {
            this.updateReadTimestamp();
        }
    }

    componentWillUnmount() {
        NewsStore.removeChangeListener(this.onChange);
    }

    onChange = () => {
        this.setState(NewsStore.getState());
    };

    updateReadTimestamp = () => {
        let [latestPost] = this.state.news;
        let latestTimestamp = moment(latestPost.date_published).unix();

        NewsActions.saveReadTimestamp(latestTimestamp);
    };

    close = () => {
        NewsActions.toggleNews();
    };

    render() {
        let { news, fetching, open } = this.state;

        return (
            <div className={'news-feed'}>
                <TransitionGroup
                    transitionName="news-feed-backdrop-transition"
                    transitionAppear={true}
                    transitionEnter={false}
                    transitionLeave={true}
                    transitionEnterTimeout={300}
                    transitionAppearTimeout={300}
                    transitionLeaveTimeout={300}>
                    {open && <div key={0} className="news-feed-backdrop" />}
                </TransitionGroup>
                <TransitionGroup
                    transitionName="news-feed-sidebar-transition"
                    transitionAppear={true}
                    transitionEnter={false}
                    transitionLeave={true}
                    transitionEnterTimeout={300}
                    transitionAppearTimeout={300}
                    transitionLeaveTimeout={300}>
                    {open && (
                        <div key={0} className="news-feed-container">
                            <div className="news-feed-header">
                                <h1>{i18n`What's new in Penneo`}</h1>
                                <div
                                    className="close-icon"
                                    onClick={this.close}>
                                    <i className="fa fa-times" />
                                </div>
                            </div>

                            {fetching && <Loading />}

                            {news && !fetching && (
                                <div className="news-feed-content">
                                    {news.map((post, i) => (
                                        <NewsFeedItem key={i} post={post} />
                                    ))}

                                    {news.length === 0 && (
                                        <div className="news-feed-empty">
                                            <i className="far fa-newspaper fa-lg" />
                                            <br />
                                            <p>
                                                {i18n`We don't have anything to show yet, but stay tuned,
                                              we're always working hard to bring new things to you`}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </TransitionGroup>
            </div>
        );
    }
}
