import React, { useState } from 'react';
import { notify } from 'react-notify-toast';
import { i18n } from 'Language';
import Message from 'Common/components/Message';
import Modal from 'Common/components/Modal';
import { TextInput } from 'Common/components';
import { ObfuscatedTextField } from 'Common/components/ObfuscatedTextField';
import Button from 'Common/components/Button';
import LoadingData from 'Common/components/Loaders/LoadingData';
import { IntegrationCredentials } from 'Auth/components/CredentialsManagement/ManageIntegrationCredentials';
import IntegrationActions from '../../actions/IntegrationActionCreators';
import analytics from 'Common/Analytics';
import './api-key-management-form.scss';

type Props = {
    credentials: IntegrationCredentials | null;
};

const copyToClipboard = (text: string) => {
    const clipboardTextArea = document.createElement('textarea');

    clipboardTextArea.innerText = text;
    document.body.appendChild(clipboardTextArea);
    clipboardTextArea.select();
    document.execCommand('copy');
    clipboardTextArea.remove();
};

const IntegrationFormContainer: React.FC<Props> = (props) => {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const handleResetKeys = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        IntegrationActions.updateCredentials();

        setShowConfirmation(false);

        analytics.track('API keys reset');

        notify.show(
            <span>
                {i18n`API keys have been reset`}&nbsp;
                <i className="fa fa-check" />
            </span>,
            'success',
            1500
        );
    };

    const handleCopy = (text: string) => {
        copyToClipboard(text);

        notify.show(
            <span>
                {i18n`Copied to clipboard`}&nbsp;
                <i className="fa fa-check" />
            </span>,
            'success',
            1500
        );
    };

    const { credentials } = props;

    if (!credentials) {
        return <LoadingData />;
    }

    const { key, secret } = credentials;

    return (
        // Added `ui-v2` class for compatibility with V1, since this component is also displayed in embedded mode in V1
        <div className="api-key-management-form ui-v2">
            <p className="mt0">
                {i18n`API keys are used allow other applications to access your
                    Penneo account.`}
                &nbsp;
                <a
                    href="https://app.penneo.com/api/docs/"
                    target="_blank"
                    rel="noopener noreferrer">
                    {i18n`You can find our API reference here`}
                </a>
            </p>
            <div className="api-key-management-form-input">
                <TextInput
                    className="api-key-management-form-textinput"
                    label={i18n`API key`}
                    value={key}
                    readOnly
                />
                <Button
                    variant="outline"
                    onClick={() => handleCopy(key)}>{i18n`Copy`}</Button>
            </div>
            <div className="api-key-management-form-input">
                <ObfuscatedTextField
                    label={i18n`API secret`}
                    value={secret}
                    obfuscationLength={20}
                />
                <Button
                    variant="outline"
                    onClick={() => handleCopy(secret)}>{i18n`Copy`}</Button>
            </div>
            <Message type="warning">
                {i18n`Resetting API keys will issue new credentials and will invalidate your previous keys and you won't be able to use them anymore to connect to Penneo. You should reset your keys if they are compromised`}
            </Message>
            <br />
            <Button
                onClick={() => setShowConfirmation(true)}
                theme="red"
                variant="outline"
                icon="far fa-sync">
                {i18n`Reset keys`}
            </Button>
            {showConfirmation && (
                <Modal
                    header={<h3>{i18n`Are you sure?`}</h3>}
                    onClose={() => setShowConfirmation(false)}>
                    <div>
                        <p>{i18n`Resetting your API credentials will`}:</p>
                        <ul>
                            <li>- {i18n`Create new API keys`}</li>
                            <li>- {i18n`Invalidate your previous API keys`}</li>
                            <li>
                                -&nbsp;
                                {i18n`Cause any Penneo integration that uses the previous API keys to stop working`}
                            </li>
                        </ul>
                        <Message type="warning">{i18n`If you are unsure about what this does or what to
                            do, please contact your administrator`}</Message>
                        <br />
                        <div className="text-right">
                            <Button
                                variant="outline"
                                onClick={() => setShowConfirmation(false)}>
                                {i18n`Cancel`}
                            </Button>

                            <Button
                                className="ml"
                                onClick={handleResetKeys}
                                theme="red"
                                variant="solid"
                                icon="far fa-sync">
                                {i18n`Yes, reset keys`}
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default IntegrationFormContainer;
