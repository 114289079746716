import UIButton from 'Common/components/Vega/Button';
import { i18n } from 'Language';
import React from 'react';
import { ReactRouter, Route } from 'types/Router';
import {
    UIModalContainer,
    UIModalFooter,
    UIModalHeader,
    UIModalOverlay,
} from 'Common/components/Vega/Modal';

type Props = {
    handleCloseModal: () => void;
    router: ReactRouter;
    nextRoute?: Route;
    saveCasefileDraft: () => void;
    savingAsDraft: boolean;
};

const CasefileExitModal = ({
    handleCloseModal,
    router,
    nextRoute,
    saveCasefileDraft,
    savingAsDraft,
}: Props) => (
    <UIModalOverlay onClose={handleCloseModal} zIndex="higher">
        <UIModalContainer>
            <UIModalHeader>
                <i
                    slot="icon"
                    className="fas fa-info-circle text-[20px] text-neutral-700"
                />
                <span slot="title">{i18n`modalExit.title`}</span>
                <span slot="subtitle">{i18n`modalExit.subtitle`}</span>
            </UIModalHeader>
            <UIModalFooter>
                <UIButton
                    slot="tertiaryButton"
                    onClick={() => nextRoute && router.push(nextRoute)}
                    priority="tertiary"
                    type="neutral"
                    isDisabled={savingAsDraft}>
                    {i18n`modalExit.discardButton`}
                </UIButton>
                <UIButton
                    slot="secondaryButton"
                    onClick={() => handleCloseModal()}
                    priority="secondary"
                    type="neutral"
                    isDisabled={savingAsDraft}>
                    {i18n`modalExit.backToEditingButton`}
                </UIButton>
                <UIButton
                    slot="primaryButton"
                    onClick={() => saveCasefileDraft()}
                    priority="primary"
                    type="neutral"
                    isLoading={savingAsDraft}>
                    {i18n`modalExit.saveAsDraftButton`}
                </UIButton>
            </UIModalFooter>
        </UIModalContainer>
    </UIModalOverlay>
);

export default CasefileExitModal;
