import { jwtDecode } from 'jwt-decode';
import {
    OpenIDToken,
    OpenIDSubjectIdentifiers,
    OpenIDCertificateDisplayInfo,
    OpenIDCertificate,
} from 'OpenID/redux/types';
import * as OpenId from 'OpenID/services';

export const getOpenIDSubject = (idToken: string): OpenIDSubjectIdentifiers => {
    const parsedToken: OpenIDToken = jwtDecode(idToken);

    const service = OpenId.services.find((service) =>
        service.isOwnToken(parsedToken)
    );

    if (!service) throw new Error('The token could not be identified');

    return service.getSubject(parsedToken);
};

export const getCertificateDisplayInfo = (
    certificate: OpenIDCertificate
): OpenIDCertificateDisplayInfo | null => {
    const service = OpenId.services.find((service) =>
        service.isOwnCertificate(certificate)
    );

    if (!service) return null;

    return service.getCertificateDisplayInfo(certificate);
};
