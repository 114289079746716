import PropTypes from 'prop-types';
import React from 'react';

export default class Forms extends React.Component {
    static propTypes = {
        children: PropTypes.object,
        branding: PropTypes.object,
        location: PropTypes.object,
        user: PropTypes.object,
        customer: PropTypes.object,
    };

    render() {
        let childProps = {
            location: this.props.location,
            branding: this.props.branding,
            user: this.props.user,
            customer: this.props.customer,
        };

        return (
            <div className="penneo-forms ui-v2">
                {React.cloneElement(this.props.children, childProps)}
            </div>
        );
    }
}
