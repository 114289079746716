import createReducer from 'create-reducer';
import produce from 'immer';

// Types
import { CustomerBrandingState, CustomerBranding } from './types';

// Default Penneo Branding
import { defaultBranding } from 'Branding/Constants';

// Action Types
export const BRANDING_UPDATED = 'BRANDING_UPDATED';
export const BRANDING_RESET_STATE = 'BRANDING_RESET_STATE';

export const BRANDING_FETCH_REQUEST = 'BRANDING_FETCH_REQUEST';
export const BRANDING_FETCH_SUCCESS = 'BRANDING_FETCH_SUCCESS';
export const BRANDING_FETCH_FAILURE = 'BRANDING_FETCH_FAILURE';

export const BRANDING_PATCH_REQUEST = 'BRANDING_PATCH_REQUEST';
export const BRANDING_PATCH_SUCCESS = 'BRANDING_PATCH_SUCCESS';
export const BRANDING_PATCH_FAILURE = 'BRANDING_PATCH_FAILURE';

// Initial state
const brandingInitialState: CustomerBrandingState = {
    data: defaultBranding,
    __states: {
        isFetching: false,
        isLoaded: false,
        error: null,
    },
};

// Reducer
export const brandingReducer = createReducer<CustomerBrandingState>(
    brandingInitialState,
    {
        [BRANDING_RESET_STATE]: () => ({
            ...brandingInitialState,
        }),
        [BRANDING_UPDATED]: (state, { property, value }) =>
            produce(state, (draftState) => {
                draftState.data[property] = value;
            }),
        [BRANDING_FETCH_REQUEST]: (state) =>
            produce(state, (draftState) => {
                draftState.__states.isFetching = true;
            }),
        [BRANDING_FETCH_SUCCESS]: (state, branding: CustomerBranding) =>
            produce(state, (draftState) => {
                draftState.data = branding;
                draftState.__states.isFetching = false;
            }),
        [BRANDING_FETCH_FAILURE]: (state, { error }) =>
            produce(state, (draftState) => {
                draftState.__states.isFetching = false;
                draftState.__states.error = error;
            }),
        [BRANDING_PATCH_REQUEST]: (state) =>
            produce(state, (draftState) => {
                draftState.__states.isFetching = true;
            }),
        [BRANDING_PATCH_SUCCESS]: (state, branding: CustomerBranding) =>
            produce(state, (draftState) => {
                draftState.data = branding;
                draftState.__states.isFetching = false;
            }),
        [BRANDING_PATCH_FAILURE]: (state, { error }) =>
            produce(state, (draftState) => {
                draftState.__states.isFetching = false;
                draftState.__states.error = error;
            }),
    }
);
