import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import CredentialManagementTabs from '../CredentialsManagement/CredentialManagementTabs';
import UserStore from '../../stores/UserStore';
import CredentialStore from '../../stores/CredentialStore';
import CredentialActions from '../../actions/CredentialActionCreators';
import Tooltip from 'Common/components/Tooltip';
import { i18n } from 'Language';
import Loader from 'Common/components/Common/Loader';
import launchDarkly from 'Common/LaunchDarkly';
import { UserEntity as User, Credentials } from 'types/User';
import { TabbedRoute } from 'types/TabbedRoute';

type Props = {
    userOptions: TabbedRoute[];
    children: JSX.Element;
    params: {
        [key: string]: string;
    };
};

type State = {
    user: User | null;
    credentials: Credentials | null;
};

export default class UserProfile extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static defaultProps: Partial<Props> = {
        userOptions: [
            {
                path: 'account-user-details',
                tab: {
                    title: 'User details',
                },
            },
            {
                path: 'account-auth-methods',
                tab: {
                    title: 'Manage credentials',
                },
            },
            {
                type: 'api',
                path: 'account-user-integration',
                tab: {
                    title: 'API keys',
                },
            },
        ],
    };

    /**
     * @todo
     * After migrating to Redux, app state will have default values for those objects
     * and they will always have the explicit type instead of (like now) being
     * for example `User | null` and then passing them down to functions
     * like `(user as User).xyz` will not be necessary.
     */
    state: State = {
        user: null,
        credentials: null,
    };

    getOptions = (credentials: Credentials) => {
        const { userOptions } = this.props;
        const options: TabbedRoute[] = [];

        userOptions
            // Exclude options that are disabled in routes.
            .filter((option) => {
                if (!option.flag) {
                    return true;
                }

                return launchDarkly.variation(option.flag) === true;
            })

            // Return options that are allowed for the user
            .forEach((option) => {
                if (!option.type) {
                    options.push(option);
                } else if (credentials.allowed.indexOf(option.type) !== -1) {
                    options.push(option);
                }
            });

        return options;
    };

    componentWillMount() {
        UserStore.addChangeListener(this.onChange);
        CredentialStore.addChangeListener(this.onChange);
        this.loadData();
    }

    componentWillUnmount() {
        UserStore.removeChangeListener(this.onChange);
        CredentialStore.removeChangeListener(this.onChange);
    }

    loadData = () => {
        CredentialActions.fetchCurrentUserCredentials();
    };

    onChange = () => {
        this.setState({
            user: UserStore.getCurrentUser() as User,
            credentials: CredentialStore.getCurrentUserCredentials(),
        });
    };

    renderContainer = (Component: JSX.Element) => {
        const { user, credentials } = this.state;

        const props = {
            user: user,
            credentials: credentials,
            customerId: this.getCustomerId(user as User),
            params: this.props.params,
        };

        return React.cloneElement(Component, props);
    };

    getCustomerId = (user: User) => {
        if (!user?.customerIds?.length) {
            return null;
        }

        return user.customerIds[0];
    };

    render() {
        const { user, credentials } = this.state;
        const isLoaded = !!(user && credentials);

        return (
            <div className="penneo-auth">
                <Helmet>
                    <title>{i18n`Edit user details`}</title>
                </Helmet>

                <div className="white-container">
                    <h3 className="title">
                        {i18n`Edit user details`}
                        &nbsp;
                        <Tooltip
                            direction="down"
                            showArrow={true}
                            horizontalOffset={40}
                            text={
                                <span className="casefile-document-details-key-tooltip">
                                    {i18n`Find your user details, credentials and preferences`}
                                </span>
                            }>
                            <i className="far text-blue fa-question-circle" />
                        </Tooltip>
                    </h3>
                    {isLoaded ? (
                        <CredentialManagementTabs
                            options={this.getOptions(
                                credentials as Credentials
                            )}
                            userId={(user as User).id}
                            customerId={this.getCustomerId(user as User)}>
                            {this.renderContainer(this.props.children)}
                        </CredentialManagementTabs>
                    ) : (
                        <Loader type="dots" />
                    )}
                </div>
            </div>
        );
    }
}
