import React from 'react';
import { notify } from 'react-notify-toast';
import { i18n } from 'Language';
import { SigningAPI } from 'Api';
import Message from 'Common/components/Message';
import Button from 'Common/components/Button';
import Loader from 'Common/components/Loaders/LoadingData';
import HTMLEditor from 'Common/components/HTMLEditor';
import { CustomerEntity as Customer } from 'types/Customer';

export type Signature = {
    id: number | null;
    emailSignature: string;
};

export type Props = {
    customer: Customer;
};

type State = {
    signature: Signature;
    isLoading: boolean;
    isSaving: boolean;
};

export default class CustomerEmailSignatures extends React.Component<
    Props,
    State
> {
    state = {
        isLoading: true,
        isSaving: false,
        signature: {
            id: null,
            emailSignature: '',
        },
    };

    async componentDidMount() {
        const { customer } = this.props;
        const signature = await fetchCompanySignature(customer);

        this.setState({
            isLoading: false,
            signature: signature || this.state.signature,
        });
    }

    handleChange = ({ editor }) => {
        let { signature } = this.state;

        signature = { ...signature, emailSignature: editor.getData() };

        this.setState({ signature });
    };

    handleSubmit = async () => {
        this.setState({ isSaving: true });

        try {
            await this.persistSignature();
            this.showNotificationSuccess();
        } catch (e) {
            this.showNotificationError();
        }

        this.setState({ isSaving: false });
    };

    showNotificationSuccess = () => {
        this.showNotification(
            <span>
                {i18n`Changes successfully saved`}{' '}
                <i className="far fa-check" />
            </span>,
            'success'
        );
    };

    showNotificationError = () => {
        this.showNotification(
            <span>
                {i18n`An error occurred. Please try again`}{' '}
                <i className="far fa-check" />
            </span>,
            'error'
        );
    };

    showNotification = (message: JSX.Element, type: string) => {
        notify.show(message, type, 3000);
    };

    persistSignature = async (): Promise<JSON> => {
        const { customer } = this.props;
        const { signature } = this.state;

        const endpoint = `/customers/${customer.id}/email-signatures`;
        const payload = {
            emailSignature: signature.emailSignature,
        };

        if (signature.id) {
            return await SigningAPI.patch(
                `${endpoint}/${signature.id}`,
                payload
            );
        }

        return await SigningAPI.post(endpoint, payload);
    };

    render() {
        const { signature, isLoading, isSaving } = this.state;

        return (
            <div style={{ maxWidth: 1170 }}>
                <h3>
                    {i18n`Set up an email signature for everybody in your company:`}
                </h3>
                <p>
                    {i18n`As an administrator you can set up email signatures for all users in your company. All user signatures will automatically be updated when you change the signature. The users will not be able to change the signature themselves. That way you always have control of how the signatures will look in the emails that are sent out.`}
                </p>
                <div className="mb">
                    <Message type="info">
                        <div>
                            <strong>{i18n`Note:`}</strong>{' '}
                            {i18n`If you want to use the name and/or email address of the sender in the email signature, please use this code: {{sender.name}} and {{sender.email}}`}
                            <br />
                            {i18n`The system will automatically replace the code with either name or email address of the sender.`}
                        </div>
                    </Message>
                </div>
                <div className="mb">
                    {!isLoading ? (
                        <HTMLEditor
                            data={signature.emailSignature}
                            onChange={this.handleChange}
                        />
                    ) : (
                        <Loader />
                    )}
                </div>
                <div className="pull-right-flex">
                    <Button
                        theme="blue"
                        disabled={isLoading || isSaving}
                        onClick={this.handleSubmit}>
                        {i18n`Save changes`}
                    </Button>
                </div>
            </div>
        );
    }
}

/**
 * Gets the company-wide email signature
 *
 * @note For now the /customer/{customerID}/email-signatures returns a list of
 * only one signature, but in the future it might return multiple (company signature,
 * team signature, etc))
 *
 * @param {Customer} customer
 * @returns {Promise} Either the signature or null if the company has no signature
 */
async function fetchCompanySignature(customer: Customer): Promise<Signature> {
    const allSignatures = await SigningAPI.get(
        `/customers/${customer.id}/email-signatures`
    );
    const companySignature = allSignatures[0];

    return companySignature || null;
}
