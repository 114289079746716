import { Dispatcher } from 'Core';
import { TouchActions } from '../ActionTypes';
import { PublicSessionAPI } from 'Api';
import { TranslationStore } from 'Language';
import { signingRedirectUrl } from 'Signing/utils';
import { fetchSignText } from 'OpenID/utils/signText';
import { signingStages } from '../../types/SigningProcess';
import { PublicSigningAPIWithAuthHeader } from '../../Api/ApiClient';

const touchActions = {
    init(challengeKey, language = 'en') {
        Dispatcher.handleServerAction({
            type: TouchActions.PARAMS_REQUEST,
        });

        PublicSessionAPI.get(`/casefile/signature/properties/${challengeKey}`)
            .then(async function (params) {
                const signTextLanguage =
                    TranslationStore.getLanguage() || language;

                const signText = await fetchSignText(
                    challengeKey,
                    signTextLanguage,
                    true
                );

                return {
                    ...params,
                    signText: signText.html,
                };
            })
            .then(({ signText, allowedInsecureSigningMethods }) => {
                Dispatcher.handleServerAction({
                    type: TouchActions.PARAMS_SUCCESS,
                    signText,
                    allowedInsecureSigningMethods,
                });
            })
            .catch((error) => {
                this._dispatchError(error, TouchActions.PARAMS_FAILURE);
            });
    },

    sign(challengeKey, signature) {
        Dispatcher.handleServerAction({
            type: TouchActions.SIGN_REQUEST,
        });

        let payload = {
            challengeKey: challengeKey,
            signature: signature,
        };

        PublicSigningAPIWithAuthHeader.post('/sign/image/signatures', payload)
            .then(async (response) => {
                Dispatcher.handleServerAction({
                    type: TouchActions.SIGN_SUCCESS,
                    response: response,
                });

                const url = await signingRedirectUrl();

                window.location.href = response.successUrl || url;
            })
            .catch((error) => {
                this._dispatchError(error, TouchActions.SIGN_FAILURE);
            });
    },

    /**
     * We introduced this method called signWithNap to allow us to sign with the name and address protection image signature endpoint
     */
    signWithNAP(challengeKey, signature, disposable, userId) {
        Dispatcher.handleServerAction({
            type: TouchActions.SIGN_REQUEST,
        });

        let payload = {
            challengeKey: challengeKey,
            signature: signature,
            disposable: disposable,
            userId: userId,
        };

        PublicSigningAPIWithAuthHeader.post(
            '/sign/name-address-protection/image/signatures',
            payload
        )
            .then(async (response) => {
                Dispatcher.handleServerAction({
                    type: TouchActions.SIGN_SUCCESS,
                    response: response,
                    withNap: true,
                });

                const url = await signingRedirectUrl(
                    signingStages.signed,
                    challengeKey
                );

                window.location.href = response.successUrl || url;
            })
            .catch((error) => {
                this._dispatchError(error, TouchActions.SIGN_FAILURE);
            });
    },

    clearStore() {
        Dispatcher.handleViewAction({
            type: TouchActions.CLEAR_STORE,
        });
    },

    retry() {
        Dispatcher.handleViewAction({
            type: TouchActions.RETRY,
        });
    },

    loadWidget(signingMethod) {
        Dispatcher.handleViewAction({
            type: TouchActions.WIDGET_LOADED,
            method: signingMethod,
        });
    },

    updateSignatureImage(base64image) {
        Dispatcher.handleViewAction({
            type: TouchActions.SIGNATURE_IMAGE_UPDATED,
            base64image: base64image,
        });
    },

    // Handle all dispatched errors
    _dispatchError(error, action) {
        switch (action) {
            case TouchActions.PARAMS_FAILURE:
            case TouchActions.SIGN_FAILURE:
            default:
                Dispatcher.handleServerAction({
                    type: action,
                    error: {
                        status: error.status,
                        statusText: error.statusText,
                        data: error.data,
                    },
                });
                break;
        }
    },
};

export default touchActions;
