import PropTypes from 'prop-types';
import React from 'react';
import PasswordPrompt from '../Common/PasswordPrompt';
import PasswordStore from '../../stores/PasswordStore';
import PasswordActions from '../../actions/PasswordActionCreators';
import { i18n } from 'Language';
import Loader from 'Common/components/Common/Loader';
import assign from 'object-assign';
import Button from 'Common/components/Button';
import Validation from 'Common/components/Common/Validation';
import { notify } from 'react-notify-toast';
import { validationRules, MIN_USERNAME_LENGTH } from 'Constants';

export default class PasswordResetForm extends React.Component {
    static propTypes = {
        credentials: PropTypes.object,
        isFetching: PropTypes.bool,
        onSuccess: PropTypes.func,
    };

    state = {
        username: '',
        password: '',
        repeat: null,
        isValid: false,
        matches: false,
        credentials: null,
        status: null,
        error: null,
    };

    componentDidMount() {
        PasswordStore.addChangeListener(this.onChange);
        PasswordStore.addEventListener(
            'PASSWORD_FETCH_ERROR',
            this.onFetchError
        );
        this.loadData();
    }

    componentWillUnmount() {
        PasswordStore.removeChangeListener(this.onChange);
        PasswordStore.removeEventListener(
            'PASSWORD_FETCH_ERROR',
            this.onFetchError
        );
        PasswordStore.resetState();
    }

    loadData = () => {
        PasswordActions.fetchPasswordCredentials();
    };

    componentWillUpdate(nextProps, nextState) {
        if (this.state.status !== 'success' && nextState.status === 'success') {
            this.setState({
                password: null,
                repeat: null,
            });
        }
    }

    onChange = () => {
        let credentials = PasswordStore.getPasswordCredentials() || {};

        this.setState(
            {
                credentials: credentials,
                username: credentials.username || '',
                status: PasswordStore.getPasswordChangeStatus(),
                error: PasswordStore.getError(),
            },
            () => this.renderMessage()
        );
    };

    onFetchError = () => {
        this.setState({
            credentials: {},
        });
    };

    updateCredentialsHandler = () => {
        let { username, password } = this.state;

        if (!username) {
            username = this.state.credentials.username;
        }

        PasswordActions.updateCredentials(username, password);
    };

    createCredentialsHandler = () => {
        let { username, password } = this.state;

        let credentials = {
            username: username,
            password: password,
        };

        PasswordActions.createCredentials(credentials);
    };

    changeUsernameHandler = (event) => {
        event.preventDefault();

        let { value } = event.target;

        this.setState({
            username: value,
        });
    };

    promptChangeHandler = (passwordChanges) => {
        let passwordState = assign(this.state, passwordChanges);

        this.setState(passwordState);
    };

    handleSave = (event) => {
        event.preventDefault();
        let { isValid, matches, status, credentials } = this.state;

        if (!isValid || !matches || status === 'request') {
            return false;
        }

        if (credentials) {
            return this.updateCredentialsHandler();
        }

        return this.createCredentialsHandler();
    };

    renderDefault = () => {
        let {
            credentials,
            password,
            repeat,
            matches,
            isValid,
            status,
            username,
        } = this.state;

        let allowSubmit =
            isValid &&
            matches &&
            status !== 'request' &&
            username.length >= MIN_USERNAME_LENGTH;

        return (
            <div>
                <label htmlFor="username">
                    {i18n('User name (used for log in)')}
                </label>

                <Validation value={username} rules={validationRules.username}>
                    <input
                        id="username"
                        type="text"
                        autoComplete="off"
                        onChange={this.changeUsernameHandler}
                        value={this.state.username}
                        placeholder={i18n('Enter your username')}
                    />
                </Validation>

                <PasswordPrompt
                    password={password || ''}
                    repeat={repeat || ''}
                    isValid={isValid}
                    matches={matches}
                    changeHandler={this.promptChangeHandler}
                />

                <Button
                    theme="blue"
                    className="mt"
                    disabled={!allowSubmit || status === 'request'}
                    onClick={this.handleSave}
                    icon={
                        status === 'request'
                            ? 'fas circle-notch fa-spin'
                            : 'far fa-check'
                    }
                    renderIconLeft={true}>
                    {status === 'request'
                        ? i18n('Saving')
                        : i18n(
                              credentials
                                  ? 'Update classic credentials'
                                  : 'Save classic credentials'
                          )}
                </Button>
            </div>
        );
    };

    renderSuccess = () =>
        notify.show(
            i18n`The Classic Credentials were updated successfully`,
            'success',
            5000
        );

    renderFailure = () => {
        let { error } = this.state;

        if (error?.data?.reason === 'Username taken') {
            notify.show(
                i18n`The selected username is already taken`,
                'error',
                5000
            );

            return;
        }

        if (error && error.data) {
            notify.show(
                i18n`The password is ${error.data.reason}`,
                'error',
                5000
            );

            return;
        }

        notify.show(i18n`This user name is already taken`, 'error', 5000);
    };

    renderMessage = () => {
        let { status } = this.state;

        switch (status) {
            case 'success':
                return this.renderSuccess();
            case 'failure':
                return this.renderFailure();
            default:
                return;
        }
    };

    render() {
        let { credentials } = this.state;

        if (!credentials) {
            return <Loader type="dots" />;
        }

        return (
            <div>
                <div className="react-modal-header-title">
                    {i18n('Configure classic credentials')}
                </div>
                <br />

                <div className="form password-reset-form">
                    {this.renderDefault()}
                </div>
            </div>
        );
    }
}
