import React from 'react';
import Constants from 'Constants';
import { LoginFooterLink as LoginFooterLinkType } from './types';
import LoginFooterLink from './LoginFooterLink';
import './login-footer.scss';

const LoginFooterLinks: LoginFooterLinkType[] = [
    {
        title: 'Contact Support',
        url: Constants.sites.support,
    },
    {
        title: 'Service Status',
        url: Constants.sites.statusPage,
    },
    {
        title: 'About Penneo',
        url: (lang = 'en') => Constants.sites.aboutPenneo[lang],
    },
];

const LoginFooter: React.FC = () => {
    return (
        <div className="login-footer">
            <ul>
                {LoginFooterLinks.map((link, index) => (
                    <LoginFooterLink link={link} key={index} />
                ))}
            </ul>
        </div>
    );
};

export default LoginFooter;
