import {
    parsePhoneNumber as libParsePhoneNumber,
    PhoneNumber,
} from 'utils/countries';

/**
 * This function is used to parse a specified phone number
 * and separate the country code from the phone number itself.
 *
 * @param phoneNumber The phone number that will be parsed
 * @returns A object with the phone number and country code seperated.
 */
export const parsePhoneNumber = (
    phoneNumber: string | undefined
): PhoneNumber | null => {
    if (!phoneNumber) {
        return null;
    }

    try {
        const parsed = libParsePhoneNumber(phoneNumber);

        if (parsed?.isValid()) {
            return parsed;
        }
    } catch (e) {}

    return null;
};
