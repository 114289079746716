import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'Common/components/Modal';
import { i18n } from 'Language';

import { connect } from 'react-redux';
import { ReduxState } from 'Store';

import './customer-integrations-modal.scss';
import CustomerFeatureToggle from './CustomerFeatureToggle';
import CustomerPropertyToggle from './CustomerPropertyToggle';
import LoadingData from 'Common/components/Loaders/LoadingData';
import { ManagedSettings } from 'Settings/redux/types';
import { CustomerEntity as Customer } from 'types/Customer';

type Props = {
    features: ManagedSettings['features'];
    customer: Customer;
    isLoaded: boolean;
    customerId: number;
    onClose: Function;
};

type State = {
    showConfirmation: boolean;
};

class CustomerFeaturesModal extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    renderFeatureList() {
        const { features, customer } = this.props;
        const list: {
            icon: string;
            id: string;
            subtitle: string;
            title: string;
            type: 'toggle' | 'select';
            customerProperty?: string;
            options?: {
                description: string;
                label: string;
                value: boolean | string;
            }[];
        }[] = [
            {
                id: 'teams',
                type: 'toggle',
                icon: 'users-class',
                title: 'Teams',
                subtitle: 'Enable access to teams',
            },
            {
                id: 'kyc',
                type: 'toggle',
                icon: 'id-card',
                title: 'Identity validation',
                subtitle: 'Enable access to identity validation',
            },
            {
                id: 'client-selector',
                type: 'toggle',
                icon: 'money-check-edit',
                title: 'CVR lookup (beta)',
                subtitle: 'Enable client selector (only for danish customers)',
            },
            {
                id: 'temporary-case-file-storage-enabled',
                customerProperty: 'temporalStorageDefault',
                type: 'toggle',
                icon: 'box-heart',
                title: 'Temporary case file storage',
                subtitle: 'Enable temporary case file storage',
            },
        ];

        return list.map((feat) => (
            <div className="mb" key={feat.id}>
                {feat.customerProperty ? (
                    <CustomerPropertyToggle
                        customerId={this.props.customerId}
                        name={feat.customerProperty}
                        value={customer[feat.customerProperty]}
                        details={feat}
                    />
                ) : (
                    <CustomerFeatureToggle
                        id={feat.id}
                        type={feat.type as 'toggle' | 'select'}
                        icon={feat.icon}
                        title={feat.title}
                        subtitle={feat.subtitle}
                        value={(features && features[feat.id]) || false}
                        options={feat.options}
                        customerId={this.props.customerId}
                    />
                )}
            </div>
        ));
    }

    render() {
        const { isLoaded, onClose } = this.props;

        return (
            <Modal
                large
                disableFocusTrap={true}
                onClose={onClose}
                header={
                    <h3 style={{ marginTop: 0 }}>{i18n`Manage features`}</h3>
                }>
                <div className="user-integrations">
                    {!isLoaded ? (
                        <LoadingData />
                    ) : (
                        <div className="pt">{this.renderFeatureList()}</div>
                    )}
                </div>
            </Modal>
        );
    }
}

export default connect((state: ReduxState) => ({
    features: state.settings.data.managed.features,
    customer: state.customer.details.data,
    isLoaded: state.settings.isLoaded,
    error: state.settings.error,
}))(CustomerFeaturesModal);
