import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from 'Language';
import { Link } from 'react-router';

// Redux
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import { KYCRecipient } from 'KYC/redux/types';

import './kyc-recipient-list.scss';
import Card from 'Common/components/Card';
import Button from 'Common/components/Button';
import TooltipRequired from 'Common/components/TooltipRequired';

type Props = {
    dispatch: AppDispatch;
    recipients: KYCRecipient[];
};

type State = {
    hasError: boolean;
};

class KYCRecipientList extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    renderEmptyList() {
        return (
            <h4 className="text-center">
                {i18n`Add at least one recipient to send a identity validation`}
                <br />

                <Link to="kyc-recipient-add" className={`inline-block mt`}>
                    <span className="underline-link">
                        {i18n`Add recipient`}
                        &nbsp;
                        <i className="far fa-plus-circle" />
                    </span>
                </Link>
            </h4>
        );
    }

    renderRecipientItem = (recipient: KYCRecipient, index: number) => {
        return (
            <li className="kyc-recipient-list-item" key={index}>
                <Link to={{ name: 'kyc-recipient-edit', params: { index } }}>
                    <Card
                        enableHover
                        enableHoverDecoration
                        hoverIcon="fas fa-pen"
                        theme="darkblue">
                        <h4 className="kyc-recipient-list-item-name">
                            {recipient.name}
                        </h4>
                        <div className="kyc-recipient-list-item-email">
                            <i className="far fa-envelope"></i>
                            &nbsp;
                            {recipient.email}
                        </div>
                    </Card>
                </Link>
            </li>
        );
    };

    render() {
        const { recipients } = this.props;

        return (
            <div>
                <h4 className="section-header mb0">
                    {i18n`Recipients`}
                    <TooltipRequired
                        text={i18n`At least one recipient is required`}
                    />

                    <Link to="kyc-recipient-add" className="no-underline">
                        <Button
                            theme="blue"
                            variant="text"
                            className="ml"
                            icon="far fa-plus-circle"
                            renderIconLeft={true}>
                            {i18n`Add new recipient`}
                        </Button>
                    </Link>
                </h4>

                <div className="kyc-recipient-list">
                    <div className="box-gray">
                        <ul className="kyc-recipient-list-container">
                            {recipients.map(this.renderRecipientItem)}
                        </ul>
                        {recipients.length === 0 && this.renderEmptyList()}
                    </div>

                    <p className="text-yellow">
                        <i className="far fa-exclamation-circle"></i>&nbsp;
                        {i18n`You can create multiple independent validations at once by adding multiple recipients`}
                    </p>
                </div>
            </div>
        );
    }
}

export default connect((state: ReduxState) => ({
    recipients: state.kyc.__new.recipients,
}))(KYCRecipientList);
