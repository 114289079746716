export const getServiceRedirectUrl = () => {
    return `${window.location.origin}/sign/csc/qes.itsme.be/service-callback`;
};

export const getCredentialRedirectUrl = () => {
    return `${window.location.origin}/sign/csc/qes.itsme.be/credential-callback`;
};

export const getURLSearchParameter = (parameter: string): string | null => {
    return new URL(window.location.href).searchParams.get(parameter);
};
