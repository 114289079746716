import React from 'react';
import DatePicker from 'antd/lib/date-picker';
import LocaleProvider from 'antd/lib/locale-provider';

// dictionaries from antd dependency, since they support English and Swedish
import enUS from 'antd/lib/locale-provider/en_US';
import svSE from 'antd/lib/locale-provider/sv_SE';
import nl_BE from 'antd/lib/locale-provider/nl_BE';
import fr_FR from 'antd/lib/locale-provider/fr_FR';

// antd doesn't support Norwegian and Danish so those are made locally, based on antd implementation
import nbNO from '../../vendor/antd/nb_NO.js';
import daDK from '../../vendor/antd/da_DK.js';

import { i18n, TranslationStore } from 'Language';
import moment from 'moment';

function range(start, end) {
    const result: number[] = [];

    for (let i = start; i < end; i++) {
        result.push(i);
    }

    return result;
}

function clickSelectTimeBtn() {
    const elementBtn = document.getElementsByClassName(
        'ant-calendar-time-picker-btn'
    ) as HTMLCollectionOf<HTMLElement>;

    // When the string will be added to the POEditor, those translated strings should be
    // translated with a single i18n() function on the default English version of the string
    if (
        elementBtn[0].innerText === 'Select time' ||
        elementBtn[0].innerText === 'Vælg tidspunkt' ||
        elementBtn[0].innerText === 'Velg tidspunkt' ||
        elementBtn[0].innerText === 'Välj tidpunkt'
    ) {
        elementBtn[0].click();
    }
}

type Props = {
    type: string;
    sendAtDate: any;
    expireAtDate: any;
    sendAtPlaceholder: string;
    expireAtPlaceholder: string;
    onChange: Function;
    enabled: boolean;
    clearExpireDate: Function;
};

type State = {
    endOpen: boolean;
};

export default class TimeStamp extends React.Component<Props, State> {
    static defaultProps = {
        sendAtDate: null,
        expireAtDate: null,
        sendAtPlaceholder: 'Send case file now',
        expireAtPlaceholder: 'Optional',
    };

    state: State = {
        endOpen: false,
    };

    // sets language of week days and months and the strings in the calendar
    getCalendarLanguage = () => {
        const languageCode = TranslationStore.getLanguage();
        let language;

        switch (languageCode) {
            case 'da':
                language = daDK;
                break;
            case 'no':
                language = nbNO;
                break;
            case 'sv':
                language = svSE;
                break;
            case 'fr':
                language = fr_FR;
                break;
            case 'nl':
                language = nl_BE;
                break;
            default:
                language = enUS;
        }

        return language;
    };

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({
                endOpen: true,
            });
        }
    };

    handleEndOpenChange = (open) => {
        this.setState({
            endOpen: open,
        });
    };

    // Disables days from the past
    // Returns `true` if the `current` day needs to be disabled, `false` to keep it enabled.
    disabledStartDate = (current) => {
        // If the `current` day is today or is after today - keep it enabled
        if (moment(current).isSameOrAfter(moment(), 'day')) {
            return false;
        }

        // Any other days - disable
        return true;
    };

    // Disables days from `sendAtDate` and before - so the casefile can't expire before being send
    // Returns `true` if the `current` day needs to be disabled, `false` to keep it enabled.
    disabledEndDate = (current) => {
        let { sendAtDate } = this.props;

        if (
            sendAtDate &&
            moment(current).isBetween(sendAtDate, moment(), 'day')
        ) {
            return true;
        }

        // If the `sendAtDate` is chosen keep all days after that date enabled
        if (sendAtDate && moment(current).isAfter(sendAtDate, 'day')) {
            return false;
            // If there is no `sendAtDate` selected then keep all the days after today enabled
        } else if (!sendAtDate && moment(current).isAfter(moment(), 'day')) {
            return false;
        }

        // Any other days - disable
        return true;
    };

    // Returns an object with array/s of hours/minutes to be disabled
    disabledDateTime = () => {
        let { sendAtDate, expireAtDate, type } = this.props;
        let date = sendAtDate;

        if (type === 'expireAt') {
            date = expireAtDate;
        }

        // If selecting sending a casefile time happens on today's date
        if (
            (type === 'sendAt' && sendAtDate.isSame(moment(), 'day')) ||
            (type === 'expireAt' && expireAtDate.isSame(moment(), 'day'))
        ) {
            const timeNow = new Date();
            const hours = timeNow.getHours();
            const minutes = timeNow.getMinutes();

            if (date.isBefore(moment(), 'hour')) {
                return {
                    disabledHours: () => range(0, 25),
                    disabledMinutes: () => range(0, 60),
                };
                // If the casefile is going to be send in the same hour which is right now, then disable
                // all the previous hours except current, and all the previous minutes except current
            } else if (date.isSame(moment(), 'hour')) {
                return {
                    disabledHours: () => range(0, hours),
                    disabledMinutes: () => range(0, minutes),
                };
                // If the casefile is going to be send after current hour, then enable all the minutes,
                // disable only hours up to the current one
            } else if (date.isAfter(moment(), 'hour')) {
                return {
                    disabledHours: () => range(0, hours),
                };
            }
        }

        return {};
    };

    onDateChange = (date) => {
        const {
            enabled,
            sendAtDate,
            expireAtDate,
            onChange,
            type,
            clearExpireDate,
        } = this.props;
        let newDate = date;

        if (sendAtDate && type === 'sendAt') {
            // If the new sendAt date (newDate) is the same as or after the current expire date
            // then clear the expire date
            if (expireAtDate && newDate.isSameOrAfter(expireAtDate, 'day')) {
                clearExpireDate();
            }

            // if the new date is today date, and its time is before current - set current time
            if (
                newDate.isSame(moment(), 'day') &&
                newDate.isBefore(moment(), 'minute')
            ) {
                const timeNow = new Date();
                const hours = timeNow.getHours();
                const minutes = timeNow.getMinutes();

                newDate = moment(hours + ':' + minutes, 'HH:mm');
            }
        }

        // set the new date
        onChange(newDate);

        // if the send later date or the expire date was selected, move to the time selector
        if ((enabled && sendAtDate) || (enabled && expireAtDate)) {
            clickSelectTimeBtn();
        }
    };

    render() {
        const { endOpen } = this.state;
        const {
            sendAtDate,
            expireAtDate,
            type,
            enabled,
            sendAtPlaceholder,
            expireAtPlaceholder,
        } = this.props;

        const timePickerOptions = {
            format: 'HH:mm',
            hideDisabledOptions: false,
        };

        return (
            <div className="time-stamp horizontal-stamp">
                <div className="time-stamp-date-picker">
                    <LocaleProvider locale={this.getCalendarLanguage()}>
                        {type === 'sendAt' ? (
                            <DatePicker
                                placeholder={i18n(sendAtPlaceholder)}
                                format="DD-MM-YYYY HH:mm"
                                value={sendAtDate}
                                onChange={this.onDateChange}
                                disabled={!enabled}
                                onOpenChange={this.handleStartOpenChange}
                                disabledDate={this.disabledStartDate}
                                disabledTime={this.disabledDateTime}
                                showTime={timePickerOptions}
                                allowClear={false}
                                showToday={false}
                            />
                        ) : (
                            <DatePicker
                                placeholder={i18n(expireAtPlaceholder)}
                                format="DD-MM-YYYY HH:mm"
                                value={expireAtDate}
                                onChange={this.onDateChange}
                                disabled={!enabled}
                                open={endOpen}
                                onOpenChange={this.handleEndOpenChange}
                                disabledDate={this.disabledEndDate}
                                disabledTime={this.disabledDateTime}
                                showTime={timePickerOptions}
                                allowClear={false}
                                showToday={false}
                            />
                        )}
                    </LocaleProvider>
                </div>
            </div>
        );
    }
}
