import { Dispatcher, BaseStore } from 'Core';
import assign from 'object-assign';
import lodash from 'lodash';
import { CustomerEntity } from 'types/Customer';

let _customers: CustomerEntity[] = [];
let _users = {};
let _error = null;
let _temporalStorageDefault = null;
let _signerArchiveEnabled = true;

let _customerBackup = {};

// Object with customer ids as keys to track unsaved changes
let _customersChangesUnsaved: Record<CustomerEntity['id'], boolean> = {};

function saveCustomerBackup(customer: CustomerEntity) {
    let copy = lodash.cloneDeep(customer);

    _customerBackup[copy.id] = copy;
}

function customerSetUnsavedChanges(customerId: CustomerEntity['id']) {
    _customersChangesUnsaved[customerId] = true;
}

function updateCustomer(customer: CustomerEntity, hasChanged = false) {
    /**
     * We keep track of changes being performed on a specific customer,
     * unless the customer changes have just been
     * persisted in the backend or have been fetched for the first time.
     */
    if (hasChanged) {
        customerSetUnsavedChanges(customer.id);
    }

    // If customer exists, update it
    let index = lodash.findIndex(_customers, { id: customer.id });

    if (index === -1) {
        _customers.push(customer);

        return;
    }

    _customers[index] = { ...customer };
}

function customerResetUnsavedChanges(
    customerId: CustomerEntity['id'],
    restoreBackup = false
) {
    if (!_customersChangesUnsaved[customerId]) {
        return;
    }

    if (restoreBackup && _customerBackup[customerId]) {
        updateCustomer(_customerBackup[customerId]);
    }

    delete _customersChangesUnsaved[customerId];
}

function updateCustomers(customers) {
    _customers = customers;
}

// Flux Store Creation
const CustomerStore = assign({}, BaseStore, {
    getUsers(customerId) {
        return _users[customerId];
    },

    getError() {
        return _error;
    },

    getCustomers() {
        return _customers;
    },

    getCustomerCopy(customerId) {
        return _customerBackup[customerId];
    },

    getCustomerUnsavedChanges(customerId: CustomerEntity['id']) {
        return _customersChangesUnsaved[customerId];
    },

    getCustomer(customerId) {
        return _customers.filter(
            (customer) => customer.id === Number(customerId)
        )[0];
    },

    getTemporalStorageDefault() {
        return _temporalStorageDefault;
    },

    isSignerArchiveEnabled(): boolean {
        return _signerArchiveEnabled;
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case 'CUSTOMER_UPDATED':
                updateCustomer(action.customer, true);
                break;
            case 'CUSTOMER_RESET_CHANGES':
                customerResetUnsavedChanges(action.customerId, true);
                break;
            case 'CUSTOMER_UPDATE_SUCCESS':
            case 'CUSTOMER_FETCH_SUCCESS':
                updateCustomer(action.customer);
                saveCustomerBackup(action.customer);
                customerResetUnsavedChanges(action.customer.id);
                break;
            case 'CUSTOMERS_FETCH_SUCCESS':
                updateCustomers(action.customers);
                break;
            case 'CUSTOMER_USERS_FETCH_SUCCESS':
                _users[action.customerId] = action.users;
                break;
            case 'CUSTOMER_STORAGE_DEFAULT_FETCH_SUCCESS':
            case 'CUSTOMER_STORAGE_DEFAULT_FETCH_FAILURE':
                _temporalStorageDefault = action.temporalStorageDefault;
                _signerArchiveEnabled = action.signerArchiveEnabled;
                break;
            case 'CUSTOMER_UPDATE_ERROR':
            case 'CUSTOMER_UPDATE_REQUEST':
                break;
            case 'CUSTOMER_RESET_ERROR':
                _error = null;
                break;
            default:
                return false;
        }
        CustomerStore.emitChange(action.type);
    }),
});

export default CustomerStore;
