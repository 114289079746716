import React from 'react';
import { SigningAPI } from 'Api';
import { i18n } from 'Language';
import DonutChart from 'Common/components/DonutChart';
import Loading from 'Common/components/Loaders/LoadingData';
import { CustomerEntity as Customer } from 'types/Customer';

import './customer-stats.scss';

export type Props = {
    customer: Customer;
};

export type State = {
    slPurchaseBalance: number;
    slUsageBalance: number;
    slAvailableBalance: number;
    usersActiveQuantity: number;
    usersInactiveQuantity: number;
    usersTotalQuantity: number;
    fetching: boolean;
    error: any;
};

export default class CustomerStats extends React.Component<Props, State> {
    state = {
        slPurchaseBalance: 0,
        slUsageBalance: 0,
        slAvailableBalance: 0,
        usersActiveQuantity: 0,
        usersInactiveQuantity: 0,
        usersTotalQuantity: 0,
        fetching: false,
        error: null,
    };

    componentDidMount = () => {
        this.loadData();
    };

    loadData = async () => {
        const { customer } = this.props;

        this.setState({
            fetching: true,
            error: null,
        });

        try {
            const response = await fetchCustomerStats(customer.id);
            const {
                slPurchaseBalance,
                slUsageBalance,
                slAvailableBalance,
                usersActiveQuantity,
                usersInactiveQuantity,
                usersTotalQuantity,
            } = response;

            this.setState({
                slPurchaseBalance,
                slUsageBalance,
                slAvailableBalance,
                usersActiveQuantity,
                usersInactiveQuantity,
                usersTotalQuantity,
                fetching: false,
            });
        } catch (error) {
            this.setState({
                error: error.data,
                fetching: false,
            });
        }
    };

    getIndicatorColor = (value: number) => {
        const good: string = '#9DE0AD';
        const moderate: string = '#E9D758';
        const bad: string = '#EE6C4D';
        let indicatorColor: string = good;

        if (value >= 75 && value < 90 && moderate) {
            indicatorColor = moderate;
        }

        if (value >= 90 && bad) {
            indicatorColor = bad;
        }

        return indicatorColor;
    };

    getSLBalance = () => {
        const { slPurchaseBalance, slUsageBalance } = this.state;

        if (slPurchaseBalance < slUsageBalance) {
            return 100;
        }

        if (slPurchaseBalance === 0 && slUsageBalance === 0) {
            return 0;
        }

        return (slUsageBalance / slPurchaseBalance) * 100;
    };

    render() {
        const {
            slPurchaseBalance,
            slUsageBalance,
            slAvailableBalance,
            usersActiveQuantity,
            usersInactiveQuantity,
            usersTotalQuantity,
            fetching,
            error,
        } = this.state;
        // If the purchased lines are 0 or less display 100%
        const slBalance: number = this.getSLBalance();
        const slUsedColor = this.getIndicatorColor(slBalance);
        const slAvailableColor: string = '#D7D8D9';

        if (fetching) {
            return <Loading />;
        }

        if (error) {
            return (
                <span>{i18n`There was a problem loading this data. Check back shortly`}</span>
            );
        }

        return (
            <div className="customer-stats">
                <div className="customer-stats-left">
                    <h3>{i18n`Signature lines balance`}</h3>
                    <div className="customer-stats-sl-balance-content">
                        <div className="sl-donut-chart">
                            <DonutChart
                                value={slBalance}
                                label={i18n`used`}
                                size={150}
                                indicatorWidth={10}
                                trackColor={slAvailableColor}
                                indicatorColor={slUsedColor}
                                labelColor={'#888888'}
                            />
                        </div>
                        <div className="sl-detailed-balance">
                            <div className="sl-purchased">
                                {i18n`Purchased signature lines ${slPurchaseBalance}`}
                            </div>
                            <div className="sl-donut-chart-legend">
                                <div className="sl-used">
                                    <div
                                        className="preview"
                                        style={{ backgroundColor: slUsedColor }}
                                    />
                                    <div className="description">
                                        {i18n`${slUsageBalance} signature lines used`}
                                    </div>
                                </div>
                                <div className="sl-available">
                                    <div
                                        className="preview"
                                        style={{
                                            backgroundColor: slAvailableColor,
                                        }}
                                    />
                                    <div className="description">
                                        {i18n`${slAvailableBalance} signature lines remaining`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="customer-stats-right">
                    <h3>{i18n`Users balance`}</h3>
                    <div className="customer-stats-users-balance-content">
                        <div className="general-balance">
                            {`${usersActiveQuantity}/${usersTotalQuantity}`}
                        </div>
                        <div className="detailed-balance">
                            <span className="active">
                                <i className="fal fa-running" />
                                {i18n`${usersActiveQuantity} active`}
                            </span>
                            <span className="inactive">
                                <i className="fal fa-male" />
                                {i18n`${usersInactiveQuantity} inactive`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const fetchCustomerStats = async (customerId: number) => {
    return await SigningAPI.get(`/customers/${customerId}/stats`);
};
