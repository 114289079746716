import React from 'react';
import classNames from 'classnames';
import RecipientListItem from './RecipientListItem';
import './recipient-list.scss';

import { RecipientSigner, RecipientCopy } from 'types/Recipient';

import { RegisteredLetterRecipient } from 'RegisteredLetter/types';
export type Props = {
    /**
     * The list of recipients
     */
    recipients: (RegisteredLetterRecipient | RecipientSigner | RecipientCopy)[];
    /**
     * Any CSS class to apply to the list
     */
    className?: string;
    /**
     * Click handler on each individual item
     */
    onItemClick?: (
        index: number | null | undefined,
        recipient: RegisteredLetterRecipient | RecipientSigner | RecipientCopy
    ) => void;
    /**
     * JSX to display if the list is empty
     */
    noData?: JSX.Element;
    /**
     * Specific styling for Registered Letters
     */
    registeredLetter?: boolean;
    /**
     * The language of the casefile
     */
    casefileLanguage?: string;
};

/**
 * Shows a list of recipients in cards
 *
 * @example
 *   <RecipientList
 *     recipients={recipients}
 *     onItemClick={clickHandler}
 *     noData={<div>No recipients to show!</div>}
 *   />
 */
const RecipientList: React.FunctionComponent<Props> = ({
    recipients,
    onItemClick,
    className,
    noData,
    registeredLetter,
    casefileLanguage,
}) => {
    return recipients.length ? (
        <ul {...(className && { className })}>
            {recipients.map(
                (
                    recipient:
                        | RegisteredLetterRecipient
                        | RecipientSigner
                        | RecipientCopy,
                    index: number
                ) => (
                    <li
                        key={index}
                        className={classNames('recipient-list-item', {
                            mb: index < recipients.length - 1,
                        })}>
                        <RecipientListItem
                            key={index}
                            index={index}
                            recipient={recipient}
                            edit={onItemClick}
                            registeredLetter={registeredLetter}
                            casefileLanguage={casefileLanguage}
                        />
                    </li>
                )
            )}
        </ul>
    ) : (
        noData ?? null
    );
};

export default RecipientList;
