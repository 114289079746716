import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import { SilverfinActions } from 'Auth/components/CustomerIntegrations/redux/actions';
import { fetchSettings } from 'Settings/redux/actions';

type Props = {
    dispatch: AppDispatch;
    location: any;
};

class CallbackContainer extends React.Component<Props> {
    static contextTypes = {
        router: PropTypes.object,
    };

    componentDidMount = async () => {
        const { location, dispatch } = this.props;
        const { code, authorized_firm_id, firm_id } = location.query;
        const silverfinFirmId = authorized_firm_id ?? firm_id ?? null;

        await dispatch(fetchSettings());

        if (code) {
            await dispatch(SilverfinActions.collect(code, silverfinFirmId));
        }

        this.context.router.push({ name: 'company-integrations' });
    };

    render = () => <div className="app-loader" />;
}

export default connect((state: ReduxState) => ({}))(CallbackContainer);
