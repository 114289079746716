import PropTypes from 'prop-types';
import React from 'react';
import UserAvatar from 'Common/components/UserAvatar';
import { Checkbox } from 'Common/components';
import { i18n } from 'Language';

import Widget from 'Common/components/Widget';

export default class UserPicker extends React.Component {
    static propTypes = {
        users: PropTypes.array,
        onChange: PropTypes.func,
        toggleSelection: PropTypes.func,
    };

    static defaultProps = {
        users: [],
    };

    state = {
        query: '',
    };

    toggleSelection = (user) => (e) => {
        e.preventDefault();
        this.props.toggleSelection(user);
    };

    filterList = (event) => {
        this.setState({
            query: event.target.value,
        });
    };

    getUsers = () => {
        let { users } = this.props;
        let { query } = this.state;

        if (query === '') {
            return users;
        }

        query = query.trim().toLowerCase();

        return users.filter((user) => {
            return (
                user.fullName.toLowerCase().indexOf(query) > -1 ||
                user.email.toLowerCase().indexOf(query) > -1
            );
        });
    };

    render() {
        let { query } = this.state;
        let users = this.getUsers();

        return (
            <Widget>
                <div className="user-picker">
                    <div className="user-picker-filter">
                        <span className="search-bar">
                            <input
                                type="text"
                                onChange={this.filterList}
                                placeholder={i18n('Type to filter')}
                            />
                        </span>
                    </div>

                    <div className="user-picker-list">
                        {users.length > 0 &&
                            users.map((user, index) => (
                                <div
                                    className="user-picker-list-item"
                                    key={index}
                                    onClick={this.toggleSelection(user)}>
                                    <div className="user-picker-list-item-avatar">
                                        <UserAvatar user={user} />
                                    </div>

                                    <div className="user-picker-list-item-details">
                                        <div className="user-picker-list-item-details-name">
                                            {user.fullName}
                                        </div>
                                        <div>{user.email}</div>
                                    </div>

                                    <Checkbox
                                        className="v2 checkbox pull-right"
                                        checked={user.selected}
                                        onChange={() => {}}
                                    />
                                </div>
                            ))}

                        {query !== '' && users.length === 0 && (
                            <div className="user-picker-list-empty">
                                <h2>{i18n`Could not find any results for "${query}"`}</h2>
                            </div>
                        )}

                        {query === '' && users.length === 0 && (
                            <div className="user-picker-list-empty">
                                <h2>{i18n`There are no available users`}</h2>
                            </div>
                        )}
                    </div>
                </div>
            </Widget>
        );
    }
}
