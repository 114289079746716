import PropTypes from 'prop-types';
import React from 'react';
import TranslationStore from '../stores/TranslationStore';
import TranslationActions from '../actions/TranslationActionCreators';
import { DEFAULT_LANGUAGE } from '../Constants';
import { getEnabledLanguages } from '../utils';
import SimpleDropdown from './SimpleDropdown';
import StandaloneDropdown from './StandaloneDropdown';
import ListDropdown from './ListDropdown';
import FlagDropdown from './FlagDropdown';

export default class LanguageDropdown extends React.Component {
    static propTypes = {
        type: PropTypes.string,
        closeDropdown: PropTypes.func,
        skipInitCallback: PropTypes.bool,
        persist: PropTypes.bool,
        name: PropTypes.string,
        callback: PropTypes.func,
        open: PropTypes.bool,
        value: PropTypes.string,
    };

    static defaultProps = {
        open: false,
        persist: true,
        local: false,
    };

    state = {
        selected: TranslationStore.getLanguage() || DEFAULT_LANGUAGE,
    };

    componentDidMount() {
        TranslationStore.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        TranslationStore.removeChangeListener(this.onChange);
    }

    onChange = () => {
        this.setState({
            selected: TranslationStore.getLanguage(),
        });
    };

    changeLanguage = (languageCode) => {
        TranslationActions.setLanguage(languageCode, {
            persist: this.props.persist,
            local: this.props.local,
        });
    };

    render() {
        let { selected } = this.state;
        let {
            value,
            type,
            name,
            skipInitCallback,
            closeDropdown,
            callback,
            open,
        } = this.props;

        const languages = getEnabledLanguages();

        switch (type) {
            case 'li':
                return (
                    <ListDropdown
                        languages={languages}
                        selected={selected}
                        callback={callback}
                        changeLanguage={this.changeLanguage}
                        open={open}
                        close={closeDropdown}
                    />
                );
            case 'simple':
                return (
                    <SimpleDropdown
                        languages={languages}
                        selected={selected}
                        name={name}
                        callback={callback}
                        changeLanguage={this.changeLanguage}
                    />
                );
            case 'standalone':
                return (
                    <StandaloneDropdown
                        languages={languages}
                        selected={selected}
                        name={name}
                        callback={callback}
                        value={value}
                        skipInitCallback={skipInitCallback}
                        {...this.props}
                    />
                );
            case 'flag':
            default:
                return (
                    <FlagDropdown
                        languages={languages}
                        selected={selected}
                        callback={callback}
                        changeLanguage={this.changeLanguage}
                        close={closeDropdown}
                    />
                );
        }
    }
}
