import React from 'react';
import './Box.scss';

import classnames from 'classnames';
const Box = ({ children, className, ...rest }) => (
    <div className={classnames('box', className)} {...rest}>
        {children}
    </div>
);

export default Box;
