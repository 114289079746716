import React from 'react';
import { images } from 'Constants';
import UITooltip from 'Common/components/Vega/Tooltip';
import UILoadingSpinner from 'Common/components/Vega/LoadingSpinner';
import Button from 'Common/components/Button';
import {
    XMarkIcon,
    CheckCircleIcon,
    ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import UIInlineMessage from 'Common/components/Vega/InlineMessage';
import { UtilityState } from './index';
import { Variant } from '@penneo/vega-ui/dist/components/_common/types';

const resolveInlineDescription = (utilityState: UtilityState) => {
    switch (utilityState) {
        case 'notPenneoDocument':
            return 'This file does not contain Penneo signatures or has invalid signatures. Please try a different document';
        case 'validationError':
            return 'File format not supported. Please verify the format and try again';
        default:
            return 'Formatting failed. Please try again';
    }
};
const resolveProperties = (utilityState: UtilityState) => {
    let description: string | undefined = undefined;
    let hasError = false;
    let variant: Variant = 'accent';
    let icon = 'pdf.svg',
        inlineDescription = '';
    let showInlineMessage = false;

    if (utilityState === 'uploading') {
        description = 'Formatting in progress';
    } else if (
        utilityState === 'uploadError' ||
        utilityState === 'notPenneoDocument' ||
        utilityState === 'validationError'
    ) {
        hasError = true;
        description = 'Formatting failed';
        variant = 'danger';
        icon = 'pdf-error.svg';
        inlineDescription = resolveInlineDescription(utilityState);
        showInlineMessage = true;
    } else if (utilityState === 'uploadSuccess') {
        variant = 'success';
        inlineDescription = 'Document formatted successfully';
        showInlineMessage = false;
        description = 'Formatting complete';
    } else if (utilityState === 'noActionNeeded') {
        inlineDescription =
            'No issue detected. The document has correct formatting';
        showInlineMessage = true;
    }

    return {
        description,
        inlineDescription,
        variant,
        icon,
        showInlineMessage,
        hasError,
    };
};

type Props = {
    documentState: UtilityState;
    fileName: string;
    donwloadUrl?: string;
    onReset?: () => void;
};

const DocumentFile = ({ fileName, documentState, onReset }: Props) => {
    const {
        description,
        inlineDescription,
        variant,
        icon,
        showInlineMessage,
        hasError,
    } = resolveProperties(documentState);

    return (
        <>
            <div
                className={`flex items-center bg-white rounded-[3px] border-solid border-[1px] p-4 h-[72px] ${
                    hasError
                        ? 'border-[#b91c1c]'
                        : documentState === 'uploadSuccess'
                        ? 'border-[#008658]'
                        : 'border-[#D1D5DB]'
                }`}>
                <div className="inline-flex items-center">
                    <img
                        className="inline-block align-middle w-[27.5px] h-[35px] flex-shrink-0"
                        src={`${images}/file-component/${icon}`}
                        alt="icon"
                    />
                    <div className="inline-block align-middle ml-2 text-[14px] text-[#111827] font-semibold">
                        {fileName}
                    </div>
                </div>
                <div className="flex items-center ml-auto">
                    <div className="inline-flex items-center justify-between ml-8">
                        {description && (
                            <span
                                className={`${
                                    hasError
                                        ? 'ml-8 mt-1 text-[#b91c1c]'
                                        : documentState === 'uploadSuccess'
                                        ? 'ml-8 mt-1 text-[#008658]'
                                        : 'ml-8 mt-1'
                                }`}>
                                {description}
                                {documentState === 'uploadSuccess' && (
                                    <CheckCircleIcon className="h-5 fill-[#008658] inline-block align-middle ml-1 mt-[-2px]" />
                                )}
                                {hasError && (
                                    <ExclamationTriangleIcon className="h-5 fill-[#b91c1c] inline-block align-middle ml-1 mt-[-2px]" />
                                )}
                            </span>
                        )}
                        <div className="p-2">
                            {documentState === 'uploading' && (
                                <UILoadingSpinner variant="accent" />
                            )}
                            {onReset && (
                                <>
                                    <Button variant="text" onClick={onReset}>
                                        <XMarkIcon className="h-6 fill-neutral-700" />
                                    </Button>
                                    <UITooltip placement="right" offset={7}>
                                        Close
                                    </UITooltip>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showInlineMessage && (
                <div className="mt-1">
                    <UIInlineMessage
                        variant={variant}
                        description={inlineDescription}
                        hideCloseButton
                    />
                </div>
            )}
        </>
    );
};

export default DocumentFile;
