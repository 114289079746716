import { Dispatcher, BaseStore } from 'Core';
import Constants from '../Constants';
import assign from 'object-assign';
import validations from '../components/validation/inputValidation.jsx';
import lodash from 'lodash';
import { utils } from '../utils';

import { validateCasefileDetails } from 'Casefiles/utils/casefileValidation';
import { CaseFileType } from 'types/CaseFile';

// Stores
import MessageTemplateStore from './MessageTemplateStore';
import SignerStore from './SignerStore';
import DocumentStore from './DocumentStore';
import CustomerStore from 'Auth/stores/CustomerStore';
import AuthStore from 'Auth/stores/AuthStore';

// Actions
import CasefileActions from '../actions/CasefilesActionCreators';
import DocumentActions from '../actions/DocumentActionCreators';
import { produce } from 'immer';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { signingFlowIsRegisteredLetter } from 'RegisteredLetter/utils';

// Process state
let _isFetching = false;
let _error = null;

// View State
let _states = {
    sending: false,
    sent: false,
    savingAsDraft: false,
    savedAsDraft: false,
};

// Casefile
let _casefile: any = {};
let _fetchedCasefileData: any = {};
let _cachedCasefileTypeId = null; // used to save casefile type ID before casefile types are loaded asynchronously.

// Archive Folder
let _folders: any[] = [];
let _cachedFolderId = null; // used to save folderID before folders are loaded asynchronously.
let _defaultFolder = null;
let _selectedFolderId = null;

// Casefile Types
let _caseTypes: CaseFileType[] = [];

// Casefile Log
let _casefileLog = [];

// CC Recipients
let _recipients: any[] = [];
// Casefile Dates - (Send date / Expiry date)
// Both by default are null
let _sendAtDate = null;
let _expireAtDate = null;
let _sendLater = false;
let _expireEnable = false;

let _conditionalSigningOptions = {
    available: false,
    enabled: false,
    count: 1,
    role: '',
};

/**
 * Source of the data in the store.
 * Value is null if the data was created manually.
 */
let _dataSource = null;

// Validation Errors
let _validationErrors = {
    casefile: {},
    documents: {},
    signers: {},
    recipients: {},
    message: {},
};

let _validation = validateCasefileDetails(_casefile);

// VALIDATION START
function casefileValidation() {
    _validationErrors.casefile = validations.casefileFormValidation(_casefile);
}

function documentsValidation() {
    let documents = DocumentStore.getDocuments();

    _validationErrors.documents = validations._documentFormValidation(
        documents,
        _casefile,
        _caseTypes
    );
}

function signersValidation() {
    let signers = SignerStore.getSigners();

    _validationErrors.signers = validations._signerArrayFormValidation(
        signers,
        _casefile
    );
}

function recipientValidation() {
    _validationErrors.recipients = validations._recipientArrayFormValidation(
        _recipients
    );
}

function messageValidation() {
    let messageTemplate = MessageTemplateStore.getMessageTemplate();

    _validationErrors.message = validations._messageFormValidation(
        messageTemplate
    );
}

function validateAllFormInputs() {
    let isValid = true;

    for (let key in _validationErrors) {
        if (Object.keys(_validationErrors[key]).length !== 0) {
            isValid = false;
        }
    }

    return isValid;
}

function validateFirstView() {
    let isValid = true;
    let objectsToValidate = ['casefile', 'documents', 'signers'];

    for (let i = 0; i < objectsToValidate.length; i++) {
        let key = objectsToValidate[i];

        if (Object.keys(_validationErrors[key]).length !== 0) {
            isValid = false;
        }
    }

    return isValid;
}
// VALIDATION END

// DATE AND TIME START
function updateSendAtDate(date) {
    if (date._isValid) {
        enableSendLater();
        _sendAtDate = date;
    }
}

function updateExpireAtDate(date) {
    if (date._isValid) {
        enableExpireDate();
        _expireAtDate = date;
    } else {
        _expireAtDate = null;
    }
}

function clearSendDate() {
    _sendAtDate = null;
}

function clearExpireDate() {
    _expireAtDate = null;
}

function sendLaterToggle(isSendLater) {
    if (typeof isSendLater === 'undefined') {
        _sendLater = !_sendLater;
    } else if (isSendLater) {
        enableSendLater();
    } else if (!isSendLater) {
        disableSendLater();
    }
}

function expireEnableToggle(isEnabled) {
    if (typeof isEnabled === 'undefined') {
        _expireEnable = !_expireEnable;
    } else if (isEnabled) {
        enableExpireDate();
    } else if (!isEnabled) {
        disableExpireDate();
    }
}

function enableSendLater() {
    _sendLater = true;
}

function enableExpireDate() {
    _expireEnable = true;
}

function disableSendLater() {
    _sendLater = false;
}

function disableExpireDate() {
    _expireEnable = false;
}
// DATE AND TIME END

function updateSelectedCaseTypeId(caseTypeId, resetDefaults = true) {
    if (!caseTypeId) {
        return false;
    }

    if (_casefile.caseFileTypeId === caseTypeId) {
        return false;
    }

    // If casetypes have been loaded at this point, trigger refresh document types
    if (_caseTypes.length === 0 && caseTypeId) {
        // Cache the selected casefile type if the collection of casefile types haven't been fetched yet.
        _cachedCasefileTypeId = caseTypeId;
        initializeCasefileType(caseTypeId, resetDefaults);

        return false;
    }

    const match = CasefileStore.getCaseTypeById(_getCaseTypeId(caseTypeId));

    // If there is no match and casefile type is not set, return the first available type
    if (!match && !_casefile.caseFileTypeId) {
        return initializeCasefileType(_caseTypes[0].id, resetDefaults);
    }

    // If there is no match, and casefile type is currently set, return it
    if (!match) {
        return initializeCasefileType(_casefile.caseFileTypeId, resetDefaults);
    }

    initializeCasefileType(caseTypeId, resetDefaults);
}

function initializeCasefileType(caseTypeId, resetDefaults = true) {
    let id = _getCaseTypeId(caseTypeId);

    // Update resolved ID on casefile
    _casefile.caseFileTypeId = id;

    // If the casefile type should refresh the link between the documents and signers, discard the cached role.
    if (resetDefaults) {
        _cachedCasefileTypeId = null;
    }

    refreshDocumentTypes(id, resetDefaults);
}

function _getCaseTypeId(caseTypeId) {
    // If casetype is not a number, match by name.
    if (isNaN(caseTypeId)) {
        return findCaseTypeByName(caseTypeId);
    }

    return Number(caseTypeId);
}

const getDefaultConditionalSigningOptions = () => {
    return {
        available: false,
        enabled: false,
        count: 1,
        role: '',
    };
};

function updateConditionalSigningOptions(options?: {
    available: boolean;
    enabled: boolean;
    count: number;
    role: string;
}) {
    // If feature flag is disabled, keep default values.
    if (!LaunchDarkly.variation(Flags.CONDITIONAL_SIGNING_ENABLED)) {
        _conditionalSigningOptions = getDefaultConditionalSigningOptions();

        return;
    }

    if (options) {
        _conditionalSigningOptions = {
            available: options.available,
            enabled: options.enabled,
            count: options.count,
            role: options.role,
        };

        return;
    }

    // Check availability from Document Types
    const documentType = DocumentStore.getDocumentTypeWithBoardSignOptions();

    if (!documentType || documentType.length === 0) {
        _conditionalSigningOptions = getDefaultConditionalSigningOptions();

        return;
    }

    // If the document type does have a document with board sign options, we make the feature available.
    _conditionalSigningOptions.available = true;

    // If there are documents that have options enabled.
    const boardSignEnabled = DocumentStore.getBoardSignEnabled();
    const count = DocumentStore.getBoardSignCountFromDocuments();

    _conditionalSigningOptions.role = DocumentStore.getRelevantSignerTypeRoleFromDocument();

    if (boardSignEnabled) {
        _conditionalSigningOptions.enabled = true;
        _conditionalSigningOptions.count = count || 1;
    } else {
        _conditionalSigningOptions.enabled = false;
    }

    return;
}

function refreshDocumentTypes(caseTypeId, resetDefaults = true) {
    // Trigger document type update
    let documentTypes = extractDocumentTypes(caseTypeId);

    if (documentTypes) {
        DocumentActions.updateAvailableDocumentTypes(
            documentTypes,
            resetDefaults
        );
    }
}

function extractDocumentTypes(caseTypeId): DocumentType[] | false {
    let caseType = CasefileStore.getCaseTypeById(caseTypeId);

    if (!caseType) {
        return false;
    }

    return caseType.documentTypes;
}

function updateCasefileTypes(casefileTypes: CaseFileType[]) {
    if (!casefileTypes) {
        return;
    }

    utils.sortArrayByKey(casefileTypes, 'name');
    _caseTypes = casefileTypes.filter(
        (caseFileType) => !signingFlowIsRegisteredLetter(caseFileType.id)
    );

    if (_cachedCasefileTypeId) {
        const caseFileTypeId = _getCaseTypeId(_cachedCasefileTypeId);

        initializeCasefileType(caseFileTypeId);

        return;
    }

    // Skip default selection if there's a casefile already selected.
    if (_casefile.caseFileTypeId) {
        // If there's a casefile already selected, trigger a document types refresh
        initializeCasefileType(_casefile.caseFileTypeId);

        return;
    }

    // Set first casefile type as selected.
    CasefileActions.setCaseTypeId(casefileTypes[0].id);
}

function updateCasefileLog(casefileLog) {
    _casefileLog = casefileLog;
}

function updateRecipients(recipients) {
    _recipients = _recipients.concat(recipients);
}

// Sets any change to the casefile
function updateCasefile(casefile) {
    _casefile = assign({}, _casefile, casefile);

    let customer = CustomerStore.getCustomer(AuthStore.getAuthDetails().cid);

    if (customer && !_casefile.hasOwnProperty('sensitiveData')) {
        return updateCasefile({ sensitiveData: customer.accessControl });
    }
}

function updateFolders(folders) {
    _folders = folders;

    let _folderIndex = {}; // reset the index

    for (let i = 0; i < folders.length; i++) {
        _folderIndex[folders[i].id] = folders[i];
    }

    let parentIndex = {};

    for (let i = 0; i < folders.length; i++) {
        if (folders[i].parentId) {
            parentIndex[folders[i].parentId] =
                _folderIndex[folders[i].parentId];
            parentIndex[folders[i].parentId].children = [];
        }
    }

    folders.forEach((folder) => {
        if (folder.parentId) {
            parentIndex[folder.parentId].children.push(folder);
            utils.sortArrayByKey(
                parentIndex[folder.parentId].children,
                'title'
            );
        }
    });

    utils.sortArrayByKey(_folders, 'title');

    // For URI Data, Folder ID can be selected before the folder data is fetched.
    // try to update folder if cached folder exists. If it doesn't, select the _default folder.
    let folderUpdated = updateFolder(_cachedFolderId);

    if (!folderUpdated) {
        _selectedFolderId = null;
    }
}

function updateDefaultFolder(folderId) {
    _defaultFolder = folderId;
}

/**
 * Updates selected folder, only if the folder is available within the collection
 * @param  {int|string} folderId - Folder ID
 */
function updateFolder(folderId) {
    if (!folderId && _selectedFolderId) {
        _cachedFolderId = _selectedFolderId;
        _selectedFolderId = _selectedFolderId;

        return true;
    }

    if (!folderId) {
        return false;
    }

    // Allow setting folders before the data is fetched.
    if (!_cachedFolderId && _folders.length === 0) {
        _cachedFolderId = folderId; // save value

        return false;
    }

    // If ID is not a number, match by title.
    let match = typeof folderId === 'number' ? 'id' : 'title';

    // Match existing folder
    let folder = lodash.find(_folders, [match, folderId]);

    if (folder) {
        _selectedFolderId = folder.id;
        _cachedFolderId = null; // reset cached folder.

        return true;
    }

    return false;
}

function setDataSource(source) {
    _dataSource = source;
}

function clearStore() {
    _casefile = {};
    _conditionalSigningOptions = getDefaultConditionalSigningOptions();
    _fetchedCasefileData = {};
    _sendAtDate = null;
    _sendLater = false;
    _expireAtDate = null;
    _expireEnable = false;
    _selectedFolderId = null;
    _recipients = [];
    _error = null;
    _states = {
        sending: false,
        sent: false,
        savingAsDraft: false,
        savedAsDraft: false,
    };
}

function findCaseTypeByName(caseTypeName) {
    let caseType = _caseTypes.filter((type) => type.name === caseTypeName)[0];

    if (caseType) {
        return Number(caseType.id);
    }

    return false;
}

// finds a signer role from documents
function findSignerRole(docs, signerRoleId) {
    let roleId = Number(signerRoleId);
    let signers;
    let signer;

    for (let i = docs.length - 1; i >= 0; i--) {
        signers = docs[i].signerTypes;
        for (let j = signers.length - 1; j >= 0; j--) {
            signer = signers[j];

            if (signer.id === roleId) {
                return signer.role;
            }
        }
    }
}

// finds a signer role from documents
function findDocument(docs, documentId) {
    let docId = Number(documentId);
    let doc;

    for (let i = docs.length - 1; i >= 0; i--) {
        doc = docs[i];

        if (doc.id === docId) {
            return doc.name;
        }
    }
}

function setFetchedCasefileData(casefileData) {
    _fetchedCasefileData = casefileData;
}

/* Flux Store Creation */
const CasefileStore = assign({}, BaseStore, {
    getSignerRole(casefileId, signerRoleId) {
        let casefile = this.getCaseTypeById(casefileId);

        if (casefile) {
            return findSignerRole(casefile.documentTypes, signerRoleId);
        }
    },

    getDocumentType(casefileId, documentId) {
        let casefile = this.getCaseTypeById(casefileId);

        if (casefile) {
            return findDocument(casefile.documentTypes, documentId);
        }
    },

    getCachedFolder() {
        return _cachedFolderId;
    },

    getFolders() {
        return _folders;
    },

    getFolder(folderId) {
        if (!folderId) {
            return false;
        }

        // If ID is not a number, match by title.
        let match = typeof folderId === 'number' ? 'id' : 'title';

        // Match existing folder
        let folder = lodash.find(_folders, [match, folderId]);

        if (!folder && _defaultFolder) {
            folder = lodash.find(_folders, [match, _defaultFolder]);
        }

        if (!folder) {
            folder = lodash.sortBy(_folders, 'id')?.[0];
        }

        return folder;
    },

    getCurrentFolder() {
        let folderId = _selectedFolderId;

        return this.getFolder(folderId);
    },

    updateFolderForce(folderId) {
        const folder = this.getFolder(folderId);

        updateFolder(folder.id);

        return folder;
    },

    getDefaultFolder() {
        return _defaultFolder;
    },

    getCasefileTypes(): CaseFileType[] {
        return _caseTypes;
    },

    getCasefileLog() {
        return _casefileLog;
    },

    getCaseTypeById(caseTypeId): CaseFileType {
        return _caseTypes.filter((type) => type.id === caseTypeId)[0];
    },

    getCasefile() {
        return _casefile;
    },

    updateCasefile(casefile) {
        updateCasefile(casefile);
    },

    getFetchedCasefileData() {
        return _fetchedCasefileData;
    },

    getRecipients() {
        return _recipients;
    },

    getSelectedFolder() {
        if (_selectedFolderId) {
            return _selectedFolderId;
        }

        if (_cachedFolderId) {
            return _cachedFolderId;
        }

        // Not found
        return null;
    },

    getConditionalSigningOptions() {
        return _conditionalSigningOptions;
    },

    getSelectedFolderId() {
        return _selectedFolderId;
    },

    getSendAtDate() {
        return _sendAtDate;
    },

    getSendLater() {
        return _sendLater;
    },

    getExpireAtDate() {
        return _expireAtDate;
    },

    getExpireEnable() {
        return _expireEnable;
    },

    getSensitiveDataFlag() {
        return _casefile.sensitiveData;
    },

    // @todo: Those 2 validate functions need to be removed from here.
    // Public functions should not call the private functions like this.
    // Make them private and crate actions for them.
    validateAllFormInputs() {
        return validateAllFormInputs();
    },

    validateFirstView() {
        return validateFirstView();
    },

    getValidationErrors() {
        return _validationErrors;
    },

    getValidation() {
        return _validation;
    },

    isFetching() {
        return _isFetching;
    },

    getError() {
        return _error;
    },

    getStates() {
        return _states;
    },

    getDataSource() {
        return _dataSource;
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.CREATE_CASEFILE_REQUEST:
                _states.sending = true;
                _error = null;
                _states.sent = false;
                break;
            case Constants.ActionTypes.SAVE_DRAFT_REQUEST:
                _states.savingAsDraft = true;
                _states.savedAsDraft = false;
                _error = null;
                break;
            case Constants.ActionTypes.CREATE_CASEFILE_SUCCESS:
                if (action.casefile) {
                    _states.sent = true;
                    _states.sending = false;
                    _casefile = action.casefile;
                    CasefileStore.emit(
                        Constants.ActionTypes.CREATE_CASEFILE_SUCCESS,
                        action.casefile
                    );
                }

                break;
            case Constants.ActionTypes.SAVE_DRAFT_SUCCESS:
                _casefile.id = action.id;
                _states.savingAsDraft = false;
                _states.savedAsDraft = true;
                CasefileStore.emit(Constants.ActionTypes.SAVE_DRAFT_SUCCESS);
                break;
            case Constants.ActionTypes.CREATE_CASEFILE_FAILURE:
                _states.sending = false;
                _error = action.error;
                break;
            case Constants.ActionTypes.SAVE_DRAFT_FAILURE:
                _states.savingAsDraft = false;
                _error = action.error;
                break;
            case Constants.ActionTypes.CLEAR_ERROR:
                _error = null;
                _isFetching = false;
                break;
            case Constants.ActionTypes.FETCH_CASEFILE_TYPES_SUCCESS:
                if (action.casefileTypes) {
                    updateCasefileTypes(action.casefileTypes);
                }

                break;
            case Constants.ActionTypes.FETCH_CASEFILE_LOG_SUCCESS:
                updateCasefileLog(action.casefileLog);
                break;
            case Constants.ActionTypes.FETCH_FOLDERS_SUCCESS:
                if (action.folders) {
                    updateFolders(action.folders);
                }

                break;
            case Constants.ActionTypes.FETCH_DEFAULT_FOLDER_SUCCESS:
                updateDefaultFolder(action.folderId);
                break;
            case Constants.ActionTypes.CASEFILE_TYPE_CHANGED:
                updateSelectedCaseTypeId(
                    action.caseTypeId,
                    action.resetDefaults
                );
                updateConditionalSigningOptions();
                break;
            case Constants.ActionTypes.SEND_AT_DATE_CHANGED:
                if (action.date) {
                    updateSendAtDate(action.date);
                }

                break;
            case Constants.ActionTypes.SEND_DATE_CLEARED:
                clearSendDate();
                break;
            case Constants.ActionTypes.SEND_LATER_TOGGLE:
                sendLaterToggle(action.isSendLater);
                break;
            case Constants.ActionTypes.EXPIRE_AT_DATE_CHANGED:
                if (action.date) {
                    updateExpireAtDate(action.date);
                }

                break;
            case Constants.ActionTypes.EXPIRE_DATE_CLEARED:
                clearExpireDate();
                break;
            case Constants.ActionTypes.ENABLE_EXPIRE_TOGGLE:
                expireEnableToggle(action.isEnabled);
                break;
            case Constants.ActionTypes.RECIPIENTS_CHANGED:
                if (action.recipients) {
                    updateRecipients(action.recipients);
                }

                break;
            case Constants.ActionTypes.CASEFILE_CHANGED:
                if (action.casefile) {
                    updateCasefile(action.casefile);
                }

                break;
            case Constants.ActionTypes.FOLDER_CHANGED:
                if (action.selectedFolderId) {
                    updateFolder(action.selectedFolderId);
                }

                break;
            case Constants.ActionTypes.VISIBILITY_MODE_CHANGED:
                updateCasefile({ visibilityMode: action.visibilityMode });
                break;
            case Constants.ActionTypes.VALIDATE_INPUTS:
                signersValidation();
                recipientValidation();
                messageValidation();
                casefileValidation();
                documentsValidation();
                break;
            case Constants.ActionTypes.RECIPIENT_ADDED:
                _recipients.push(action.recipient);
                break;
            case Constants.ActionTypes.RECIPIENT_REMOVED:
                _recipients.splice(action.index, 1);
                break;
            case Constants.ActionTypes.RECIPIENT_UPDATED:
                _recipients[action.index] = assign(
                    _recipients[action.index],
                    action.recipient
                );
                break;
            case Constants.ActionTypes.SENSITIVE_DATA_CHANGED:
                updateCasefile({ sensitiveData: action.sensitiveData });
                break;
            case Constants.ActionTypes.RECIPIENTS_REMOVED:
                _recipients = [];
                break;
            case Constants.ActionTypes.CLEAR_CASEFILE_STORE:
                clearStore();
                break;
            case Constants.ActionTypes.SIGN_AT_MEETING:
                updateCasefile({ signOnMeeting: action.data });
                break;
            case Constants.ActionTypes.SET_DATA_SOURCE:
                setDataSource(action.data);
                break;
            case Constants.ActionTypes.CREATE_FOLDER_REQUEST:
                _error = null;
                break;
            case Constants.ActionTypes.CREATE_FOLDER_SUCCESS:
                _error = null;
                break;
            case Constants.ActionTypes.CREATE_FOLDER_FAILURE:
                _error = action.error;
                break;
            case Constants.ActionTypes.FETCH_CASEFILE_DRAFT_REQUEST:
            case Constants.ActionTypes.FETCH_CASEFILE_TYPES_REQUEST:
                _isFetching = true;
                break;
            case Constants.ActionTypes.DOCUMENT_DELETE_SUCCESS:
                // When deleting a file via the API, it's necessary to also remove it from the initially fetched data
                // This is to avoid the retry function from attempting to delete a document that was already deleted.
                // (Documents get deleted when submitting a casefile and only if they exist in _fetchedCasefilesData
                // after they have been removed from the _documents property from DocumentStore.js)
                _fetchedCasefileData = produce(
                    _fetchedCasefileData,
                    (draft) => {
                        const deleteIndex = draft.documents.findIndex(
                            (doc) => doc.id === action.documentId
                        );

                        draft.documents.splice(deleteIndex, 1);
                    }
                );
                break;
            case Constants.ActionTypes.FETCH_CASEFILE_DRAFT_SUCCESS:
                setFetchedCasefileData(action.fetchedCasefileData);
                _isFetching = false;
                break;
            case Constants.ActionTypes.FETCH_CASEFILE_DRAFT_FAILURE:
                action.error.type = action.type; // Enhance the error object to be able to react to specific error in the view (CasefileContainer)
                _error = action.error;
                _isFetching = false;
                break;
            /**
             * Append documents added to the fetched data, as they are uploaded immediately when uploading from the UI
             * When deleting documents from a draft, the comparison happens between IDs in the server and the list of documents in local state
             * Adding the documents to the list of fetched casefile data fixes a problem in which documents that were deleted from the UI weren't deleted from the server
             */
            case Constants.ActionTypes.DRAFT_DOCUMENT_ADDED_SUCCESS:
                _fetchedCasefileData.documents.push(action.newDocument);
                break;
            case Constants.ActionTypes.CLEAR_FOLDERS:
                _folders = [];
                _cachedFolderId = null;
                _selectedFolderId = null;
                break;
            case Constants.ActionTypes.CASEFILE_CLEAR_ERROR:
                _error = null;
                _states.sending = false;
                _states.sent = false;
                break;
            case Constants.ActionTypes.CONDITIONAL_SIGNING_OPTIONS_UPDATED:
                updateConditionalSigningOptions(action.options);
                break;
            default:
                return true;
        }

        // Validate casefile on every action processed
        _validation = validateCasefileDetails(_casefile);

        // If action was responded to, emit change event
        CasefileStore.emitChange(action.type);

        return true;
    }),
});

export default CasefileStore;

export let API = {
    signersValidation: signersValidation,
};
