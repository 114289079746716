import { createComponent } from '@lit/react';
import { Banner } from '@penneo/vega-ui';
import React from 'react';

const UIBanner = createComponent({
    tagName: 'vega-banner',
    elementClass: Banner,
    react: React,
});

export default UIBanner;
