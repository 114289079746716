import React from 'react';
import './donut-chart.scss';

export type Props = {
    /**
     * The percentage of the chart to color.
     * The chart will show this vale, using its indicator but also text in the middle
     */
    value: number;
    /**
     * Short description of the show value, shown inside of the chart
     */
    label: string;
    /**
     * Diameter of the chart
     */
    size: number;
    /**
     * Width of chart indicator
     */
    indicatorWidth: number;
    /**
     * Color of an empty part of the chart
     */
    trackColor: string;
    /**
     * Color of the indicator depicting the chart value
     */
    indicatorColor: string;
    /**
     * Color of the chart label
     */
    labelColor: string;
};

export default class DonutChart extends React.Component<Props> {
    render() {
        const {
            size,
            indicatorWidth,
            value,
            label,
            trackColor,
            indicatorColor,
            labelColor,
        } = this.props;

        // Variable useful for simplifying other calculations below
        const halfSize = size * 0.5;
        // Chart circle radius - distance from the center to circle perimeter
        const radius = halfSize - indicatorWidth * 0.5;
        // Enclosing boundary - length of the circle; distance of the line that forms the circle
        const circumference = 2 * Math.PI * radius;

        const strokeValue = (value * circumference) / 100;
        const dashValue = strokeValue + ' ' + circumference;

        // Empty part of the chart
        const trackStyle = {
            stroke: trackColor,
            strokeWidth: indicatorWidth,
        };
        // Filled part of the chart
        const indicatorStyle = {
            stroke: indicatorColor,
            strokeWidth: indicatorWidth,
            strokeDasharray: dashValue,
        };

        const rotationValue = 'rotate(-90 ' + halfSize + ',' + halfSize + ')';
        const renderedValue = value.toString().includes('.')
            ? value.toFixed(2)
            : value;

        return (
            <svg width={size} height={size} className="donut-chart">
                <circle
                    r={radius}
                    cx={halfSize}
                    cy={halfSize}
                    transform={rotationValue}
                    style={trackStyle}
                    className="donut-chart-track"
                />
                <circle
                    r={radius}
                    cx={halfSize}
                    cy={halfSize}
                    transform={rotationValue}
                    style={indicatorStyle}
                    className="donut-chart-indicator"
                />
                <text
                    className="donut-chart-center"
                    x={halfSize}
                    y={halfSize}
                    style={{ textAnchor: 'middle' }}>
                    <tspan className="donut-chart-value" fill={labelColor}>
                        {renderedValue}%
                    </tspan>
                    <tspan
                        className="donut-chart-label"
                        fill={labelColor}
                        x={halfSize}
                        y={halfSize + 20}>
                        {label}
                    </tspan>
                </text>
            </svg>
        );
    }
}
