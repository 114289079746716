import axios, { AxiosInstance } from 'axios';
import React from 'react';
import Constants from 'Constants';
import { Languages } from './Constants';

type TranslateConstructor = {
    options?: {
        preserveSpaces: boolean;
        dictionary?: any;
        languageCode?: string;
    };
};

type LanguageObject = Record<string, string>;
type Dictionaries = Record<string, LanguageObject>;

export class Translate {
    private languageCode?: Languages = Languages.EN;
    private dictionary?: LanguageObject = {};
    private options: TranslateConstructor['options'];
    private client: AxiosInstance;
    private dictionaries: Dictionaries = {};

    constructor({ options }: TranslateConstructor) {
        this.options = options;
        this.client = axios.create({
            baseURL: Constants.PENNEO_LANGUAGE_ENDPOINT,
        });

        this.translateTerm = this.translateTerm.bind(this);
        this.changeLanguage(this.languageCode as Languages, true); // Load the initial language dictionary
    }

    private async fetchDictionary(languageCode = Languages.EN) {
        const { data } = await this.client.get(`/${languageCode}.json`);

        return data;
    }

    private async loadDictionary(languageCode: Languages) {
        if (!this.dictionaries[languageCode]) {
            this.dictionaries[languageCode] = await this.fetchDictionary(
                languageCode
            ).catch((e) => {
                console.error(
                    `Failed to fetch dictionary for language: ${languageCode}`,
                    e
                );

                return {};
            });
        }

        return this.dictionaries[languageCode];
    }

    async getDictionary(
        language: Languages = Languages.EN,
        changeLanguage: boolean = true
    ) {
        if (!this.dictionaries[language]) {
            await this.loadDictionary(language);
        }

        if (this.dictionaries[language] && changeLanguage) {
            this.dictionary = this.dictionaries[language];
        }

        return this.dictionaries[language];
    }

    async changeLanguage(
        languageCode: Languages = Languages.EN,
        setDictionary: boolean = true
    ) {
        this.languageCode = languageCode;

        const htmlElm = document.querySelector('html');

        htmlElm?.setAttribute('lang', languageCode);

        await this.getDictionary(languageCode, setDictionary);

        // Ensure English dictionary is always loaded for fallback!
        if (languageCode !== Languages.EN) {
            await this.getDictionary(Languages.EN, false);
        }
    }

    i18n = (strings: any, ...values: any) => {
        let key;

        if (!strings) {
            return false;
        }

        try {
            // Build Lookup Key
            if (Array.isArray(strings)) {
                key = this._buildKey(strings);
            } else {
                key = strings;
            }

            let term = key;

            // Remove duplicate trailing spaces, leading spaces and newlines before lookup
            if (!this.options?.preserveSpaces) {
                term = key.replace(/\s\s+/g, ' ');
            }

            return this.translateTerm(term, ...values);
        } catch (error) {
            console.error('Translation error:', error);
        }
    };

    getTranslatedString = (term: string, values: any[]) => {
        return term.replace(/{(\d)}/g, (_, index) => values[Number(index)]);
    };

    getTranslatedComponent(term, values) {
        const result = term.split(' ').map((value, index) => {
            const matches = value.match(/{(\d)}/);

            if (matches) {
                return (
                    <span key={index}>
                        {index !== 0 ? ' ' : null}
                        {values[Number(matches[1])]}
                    </span>
                );
            }

            return (
                <span key={index}>
                    {index !== 0 ? ' ' : null}
                    {value}
                </span>
            );
        });

        return <span>{result}</span>;
    }

    // Replaces keys with ES6 Template Literal values
    translateTerm(key, ...values) {
        let term = key;

        // Use term from dictionary if available
        if (this.dictionary && this.dictionary[key]) {
            term = this.dictionary[key];
        } else if (
            this.dictionaries[Languages.EN] &&
            this.dictionaries[Languages.EN][key]
        ) {
            // Fallback to English dictionary
            // If translation for the term doesn't exists in current dictionary - use the one from English dictionary
            term = this.dictionaries[Languages.EN][key];
        }

        // If text doesn't contain replacements, return term as is.
        if (!values || values.length === 0) {
            return term;
        }

        let hasComponents =
            values.filter((value) => React.isValidElement(value)).length > 0;

        if (hasComponents) {
            return this.getTranslatedComponent(term, values);
        }

        return this.getTranslatedString(term, values);
    }

    // Converts ES6 Template Literal String array into key for lookup.
    _buildKey(strings: string[]) {
        let key = ``;

        strings.forEach((string, index) => {
            key += `${string}${
                index === strings.length - 1 ? '' : `{${index}}`
            }`;
        });

        return key;
    }
}

const translations = new Translate({
    options: { preserveSpaces: false },
});

const translationsPreservedSpaces = new Translate({
    options: { preserveSpaces: true },
});

const dictionaries = {};

export const i18n = (strings, ...rest) => translations.i18n(strings, ...rest);
const language = i18n;

export { dictionaries, language, translations, translationsPreservedSpaces };
