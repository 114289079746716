/**
 * The current browser user agent
 */
export const UA = navigator.userAgent;

export const isIOS = () => /iPad|iPhone/.test(UA);

/**
 * Method to detect if the current browser is Firefox,
 * and optionally if it's the Android mobile version of it
 * @param mobile (optional) boolean
 * @returns A boolean
 */
export const isFirefox = (mobile = false) => {
    const isFirefox = /Firefox/.test(UA);

    if (!mobile) {
        return isFirefox;
    }

    return isFirefox && /Android/.test(UA);
};
