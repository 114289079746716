import React from 'react';
import { i18n } from 'Language';
import EmailTemplateList, {
    Props,
} from 'EmailTemplates/components/EmailTemplateList';

const EmailTemplatesPage: React.FunctionComponent<Props> = (props) => {
    return (
        <div className="white-container">
            <h3 className="title">{i18n`Email templates`}</h3>
            <EmailTemplateList {...props} />
        </div>
    );
};

export default EmailTemplatesPage;
