import PropTypes from 'prop-types';
import React from 'react';

export default class Loader extends React.Component {
    static propTypes = {
        type: PropTypes.string,
        inline: PropTypes.bool,
        loadingText: PropTypes.string,
    };
    render() {
        let { type, inline, loadingText } = this.props;

        switch (type) {
            case 'dots':
                return (
                    <>
                        <div
                            className={'spinner' + (inline ? ' inline' : '')}
                            data-testid="Common/components/Common/Loader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                        <p
                            className="sr-only"
                            aria-live="polite"
                            aria-busy="true">
                            Loading
                        </p>
                    </>
                );
            case 'spinner':
            default:
                return (
                    <>
                        <div
                            className="app-loader"
                            data-testid="Common/components/Common/Loader"></div>
                        {loadingText && (
                            <p
                                className="mt"
                                aria-live="polite"
                                aria-busy="true">
                                {loadingText}
                            </p>
                        )}
                        {!loadingText && (
                            <p
                                className="sr-only"
                                aria-live="polite"
                                aria-busy="true">
                                Loading Penneo
                            </p>
                        )}
                    </>
                );
        }
    }
}
