import React from 'react';
import './embed-wrapper.scss';

type Props = {
    children: React.ReactNode;
};

const EmbedWrapper = ({ children }: Props) => (
    <div className="embed-wrapper">
        <div className="white-container">
            <div className="content">{children}</div>
        </div>
    </div>
);

export default EmbedWrapper;
