import React, { useCallback, useEffect, useState } from 'react';
import { i18n } from 'Language';
import { SigningAPI } from 'Api';

export type Props = {
    casefileId: number;
    emailLogId: number;
    subject: string;
};

const trimEmailSubject = (subject: string): string => {
    return subject.length > 50
        ? subject.substring(0, 49).concat('...')
        : subject;
};

/**
 * Properly formatted link to a casefile email, with its subject as label
 */
const EmailSubjectLink = ({ casefileId, emailLogId, subject }: Props) => {
    const [loading, setLoading] = useState(false);
    const [emailPreviewUrl, setEmailPreviewUrl] = useState<string>();

    const fetchEmailPreview = useCallback(async () => {
        // We toggle 'loading' to prevent this function being triggered multiple times
        setLoading(true);

        try {
            // Fetch raw HTML of email preview
            const emailPreviewContentRaw: string = await SigningAPI.get(
                `/casefiles/${casefileId}/emails/${emailLogId}`
            );

            // Filter out broken signing request links
            const emailPreviewContentFiltered = emailPreviewContentRaw.replace(
                /href="([^"]*\[challenge_key:(.*))"/g,
                ''
            );

            // Convert to blob
            const emailPreviewContent = new Blob(
                [emailPreviewContentFiltered],
                {
                    type: 'text/html',
                }
            );

            /**
             * Once we have fetched the email HTML code,
             * we create a temporary url to view its content
             * and then open a new tab with it.
             * NOTE: we store the generated url so we can
             * clean the memory afterwards.
             * This is done in a separate method,
             * to allow users to reload this new page while this component
             * is still active.
             */
            const emailPreview = URL.createObjectURL(emailPreviewContent);

            window.open(emailPreview, '_blank');

            setEmailPreviewUrl(emailPreview);
        } catch (error) {
            throw new Error(`Failed to fetch email data - [ID: ${emailLogId}`);
        } finally {
            setLoading(false);
        }
    }, [casefileId, emailLogId]);

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();

            if (loading) {
                return;
            }

            fetchEmailPreview();
        },
        [loading]
    );

    useEffect(() => {
        return () => {
            if (!emailPreviewUrl) {
                return;
            }

            // cleanup Url from browser memory on unmount
            URL.revokeObjectURL(emailPreviewUrl);
        };
    }, [emailPreviewUrl]);

    return (
        <span>
            <span>|&nbsp;</span>
            <a href="#" onClick={handleClick}>
                <span className="email-subject-link">
                    <i className="far fa-envelope-open-text" />
                    &nbsp;
                    {trimEmailSubject(i18n(subject))}
                </span>
            </a>
        </span>
    );
};

export default EmailSubjectLink;
