import React from 'react';
import PropTypes from 'prop-types';
import analytics from 'Common/Analytics';
import FullScreenModal from 'Common/components/FullScreenModal';
import Button from 'Common/components/Button';
import { i18n } from 'Language';

import './casefile-document-preview.scss';
import {
    ApiError,
    checkPDFSupport,
    PdfDocument,
    PdfPreviewError,
} from 'Common/components/PdfPreviewer';
import { fetchDocumentContent } from './utils';

export type Props = {
    id: number;
    uploadedDocuments: DocumentObject[];
    onOpen: (documentId: number) => void;
    onClose: () => void;
};

type State = {
    index: number;
    currentDocument: DocumentObject;
    previewType: PreviewType;
    isNativePDFSupported: boolean;
    error?: ApiError;
    pdfData?: Blob;
};

export type DocumentObject = {
    id: number;
    name: string;
    fileContent?: Blob;
    caseFileId?: number;
    documentTypeId?: number;
    filename?: string;
    localPath?: string;
    order?: number;
};

enum PreviewType {
    /**
     * PDF file that is already connected with the case file and has case file id
     * Used in case of case file drafts.
     */
    CASEFILE_DOCUMENT = 'document',
    /* New PDF file that is NOT connected with the case file yet */
    NEW_DOCUMENT = 'file',
}
class CasefileDocumentPreviewModal extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props: Props) {
        super(props);

        const { id, uploadedDocuments } = this.props;
        const index = uploadedDocuments.findIndex((doc) => doc.id === id);

        this.state = {
            index,
            currentDocument: uploadedDocuments[index],
            previewType: uploadedDocuments[index].caseFileId
                ? PreviewType.CASEFILE_DOCUMENT
                : PreviewType.NEW_DOCUMENT,
            isNativePDFSupported: checkPDFSupport(),
        };
    }

    componentDidMount = () => {
        const { isNativePDFSupported } = this.state;

        if (!isNativePDFSupported) {
            this.getDocumentPreview();
        }
    };

    /**
     *
     * @param {object} prevProps
     */
    componentDidUpdate = async (prevProps: Props) => {
        const { id } = this.props;
        const { id: prevId } = prevProps;

        if (id !== prevId) {
            this.updateState();
        }
    };

    updateState = () => {
        const { id, uploadedDocuments } = this.props;
        const index = uploadedDocuments.findIndex((doc) => doc.id === id);

        this.setState(
            {
                index,
                currentDocument: uploadedDocuments[index],
                previewType: uploadedDocuments[index].caseFileId
                    ? PreviewType.CASEFILE_DOCUMENT
                    : PreviewType.NEW_DOCUMENT,
            },
            () => this.getDocumentPreview()
        );
    };

    /**
     * Gets the content of the document, either by returning the `fileContent`
     * property (if it is a newly uploaded document), or by fetching the content
     * via the API (if it is a document that was uploaded previously in the past)
     */
    async getDocumentContent(): Promise<Blob | null> {
        const { currentDocument } = this.state;

        if (!currentDocument) {
            return null;
        }

        if (currentDocument.fileContent) {
            return currentDocument.fileContent;
        }

        if (currentDocument.id) {
            return await fetchDocumentContent(currentDocument.id);
        }

        return null;
    }

    async getDocumentPreview() {
        // cleanup previous document before fetching new one
        this.setState({ pdfData: undefined });

        try {
            const documentContent = await this.getDocumentContent();

            if (!documentContent) {
                throw Error('Unable to get the document content');
            }

            this.setState({ pdfData: documentContent });
        } catch (error) {
            this.triggerError(error);
        }
    }

    triggerError(error) {
        const { currentDocument } = this.state;

        analytics.track('PDF render error', {
            documentId: currentDocument.id,
        });

        this.setState({ error });
    }

    renderHeader = () => {
        const { id } = this.props;
        const { currentDocument, previewType } = this.state;

        return (
            <div className="document-preview-header">
                <div className="document-preview-actions pull-right mb">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/${previewType}/pdf/${id}`}>
                        <Button
                            theme="blue"
                            className="mr"
                            variant="outline"
                            icon="far fa-expand-arrows-alt">
                            {i18n`View PDF`}
                        </Button>
                    </a>
                    <a
                        download=""
                        rel="noopener noreferrer"
                        href={`/${previewType}/pdf/${id}/attachment`}>
                        <Button
                            theme="blue"
                            variant="outline"
                            icon="far fa-arrow-alt-to-bottom">
                            {i18n`Download PDF`}
                        </Button>
                    </a>
                </div>
                <h1 className="casefile-document-details-title">
                    {currentDocument.name}
                </h1>
            </div>
        );
    };

    renderDocumentPreview() {
        const { id } = this.props;
        const {
            previewType,
            error,
            pdfData,
            isNativePDFSupported,
        } = this.state;

        return error ? (
            <PdfPreviewError error={error} />
        ) : (
            <PdfDocument
                isNativePDFSupported={isNativePDFSupported}
                pdfData={
                    isNativePDFSupported
                        ? `/${previewType}/pdf/${id}/inline`
                        : pdfData
                }
            />
        );
    }

    renderDocumentPager = () => {
        const { index } = this.state;
        const { id, uploadedDocuments } = this.props;
        const isFirst = index === 0;
        const isLast = index === uploadedDocuments.length - 1;

        if (uploadedDocuments.length <= 1) {
            return;
        }

        return (
            <div className="document-preview-pagination">
                <span>
                    <Button
                        className="prev"
                        theme="blue"
                        variant="text"
                        onClick={this.handleOnPrev}
                        icon="far fa-arrow-left"
                        disabled={isFirst}></Button>
                    <span>
                        {i18n`Document ${(
                            <select
                                onChange={this.handleOnDocumentChange}
                                className="current-document"
                                value={id}>
                                {uploadedDocuments.map((doc, index: number) => (
                                    <option key={index} value={doc.id}>
                                        {index + 1}
                                    </option>
                                ))}
                            </select>
                        )} of ${uploadedDocuments.length}`}
                    </span>
                    <Button
                        className="next"
                        theme="blue"
                        variant="text"
                        onClick={this.handleOnNext}
                        icon="far fa-arrow-right"
                        disabled={isLast}></Button>
                </span>
            </div>
        );
    };

    handleOnPrev = () => {
        const { index } = this.state;
        const { uploadedDocuments } = this.props;

        this.changeToDifferentDocument(uploadedDocuments[index - 1].id);
    };

    handleOnNext = () => {
        const { index } = this.state;
        const { uploadedDocuments } = this.props;

        this.changeToDifferentDocument(uploadedDocuments[index + 1].id);
    };

    handleOnDocumentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.changeToDifferentDocument(Number(e.target.value));
    };

    changeToDifferentDocument = (documentId: number) => {
        this.props.onOpen(documentId);
    };

    render() {
        return (
            <div className="document-preview">
                <FullScreenModal
                    displayModal={true}
                    onCancel={this.props.onClose}
                    header={this.renderHeader()}
                    footer={this.renderDocumentPager()}>
                    {this.renderDocumentPreview()}
                </FullScreenModal>
            </div>
        );
    }
}

export default CasefileDocumentPreviewModal;
