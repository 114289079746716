import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import SignerCreator from './SignerCreator.jsx';
import Tooltip from 'Common/components/Tooltip';
import SignerActions from '../../actions/SignerActionCreators';
import Button from 'Common/components/Button';
import { AuthStore } from 'Auth';
import { CustomerSigningMethod } from 'types/Customer';
import { connect } from 'react-redux';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';

export class SignerInformation extends React.Component {
    static propTypes = {
        signers: PropTypes.array,
        signerRules: PropTypes.object,
        availableSignerTypes: PropTypes.array,
        selectedDocumentTypes: PropTypes.array,
        isCasefileSensitive: PropTypes.bool,
        inputErrors: PropTypes.object,
        changeSigners: PropTypes.func,
        customerSettings: PropTypes.object,
    };

    state = {
        customer: {},
        showInsecureMethods: undefined,
    };

    removeSignerHandler = (index) => {
        SignerActions.removeSigner(index);
    };

    updateSignerHandler = (index, data) => {
        SignerActions.updateSigner(index, data);
    };

    updateSignerRoleHandler = (index, role, checked) => {
        SignerActions.updateSignerRole(index, role, checked);
    };

    addSigner = (event) => {
        event.preventDefault();
        SignerActions.addSigner();
    };

    componentDidMount() {
        const { signers } = this.props;

        if (signers.length === 0) {
            // We always want a "blank" signer to be there, ready for the user to fill in.
            // Blank signers will be ignored when creating signers on the server.
            SignerActions.addSigner();
        }

        /**
         * We will use some information from the current customer,
         * so we pull it from Flux and add it to our state.
         * NOTE: since the Desktop app doesn't have access to editing company's settings
         * it is safe to use the AuthStore rather than the CustomerStore
         * to retrieve the "latest" allowedSigningMethods list
         */
        this.setState({ customer: AuthStore.getCustomer() });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.customer.allowedSigningMethods == null ||
            prevState.showInsecureMethods != null
        ) {
            return;
        }

        /**
         * Regardless of the flag on/off, we only check for the 'image' method to be enabled.
         * This is to make it backwards compatible with clients who haven't
         * updated the list of insecure methods
         */
        const showInsecureMethods = this.state.customer.allowedSigningMethods.includes(
            CustomerSigningMethod.IMAGE
        );

        this.setState({
            showInsecureMethods,
        });
    }

    render() {
        // values
        let {
            signers,
            inputErrors,
            availableSignerTypes,
            isCasefileSensitive,
            signerRules,
        } = this.props;

        const { showInsecureMethods } = this.state;

        return (
            <div className="casefile-row-form">
                <div className="fieldset">
                    <div className="legend">
                        <span className="fa-stack">
                            <i className="fa fa-circle fa-stack-2x"></i>
                            <strong className="fa-stack-1x fa-stack-text fa-inverse">
                                3
                            </strong>
                        </span>
                        &nbsp;{i18n('Add Signers')}
                    </div>

                    <div className="signer-information-button">
                        <Button
                            onClick={this.addSigner}
                            theme="blue"
                            variant="outline">
                            {i18n('Add new signer')}
                        </Button>
                    </div>
                    <div className="signer-table-wrapper">
                        <table className="signer-table">
                            <thead>
                                <tr>
                                    <th>{i18n`Name`}</th>
                                    <th>{i18n`Email`}</th>
                                    <th>{i18n`On Behalf Of`}</th>
                                    <th>{i18n`Role`}</th>
                                    <th>{i18n`SSN`}</th>
                                    <th>
                                        <div>
                                            {i18n`VAT Id`}&nbsp;
                                            <Tooltip
                                                text={i18n`This feature is only supported for
                                                danish CVR numbers`}>
                                                <i className="fa fa-info-circle"></i>
                                            </Tooltip>
                                        </div>
                                    </th>
                                    <th>{i18n`Private`}</th>
                                    {showInsecureMethods && (
                                        <th>
                                            {launchDarkly.variation(
                                                Flags.SPLIT_INSECURE_SIGNING_METHODS
                                            )
                                                ? i18n`Simple Electronic Signatures`
                                                : i18n`Touch`}
                                        </th>
                                    )}
                                    <th>{i18n`Reminder Interval (days)`}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {signers.map((signer, index) => (
                                    <SignerCreator
                                        key={index}
                                        signer={signer}
                                        index={index}
                                        signerRules={signerRules}
                                        availableSignerTypes={
                                            availableSignerTypes
                                        }
                                        isCasefileSensitive={
                                            isCasefileSensitive
                                        }
                                        inputErrors={inputErrors}
                                        remove={this.removeSignerHandler}
                                        updateSigner={this.updateSignerHandler}
                                        updateSignerRole={
                                            this.updateSignerRoleHandler
                                        }
                                        showInsecureMethods={
                                            showInsecureMethods
                                        }
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <br />
                </div>
            </div>
        );
    }
}

export default connect((state) => ({
    customerSettings: state.settings.data.customer,
}))(SignerInformation);
