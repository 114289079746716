import { createComponent } from '@lit/react';
import { Container } from '@penneo/vega-ui';
import React from 'react';

const UIModalContainer = createComponent({
    tagName: 'vega-modal-container',
    elementClass: Container,
    react: React,
});

export default UIModalContainer;
