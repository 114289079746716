import PropTypes from 'prop-types';
import React from 'react';
import assign from 'object-assign';
import { i18n, LanguageDropdown } from 'Language';
import ErrorMessage from '../errors/InputErrorMessage';
import CasefileDetailsTable from './CasefileDetailsTable';
import { modal } from 'Common/components/Common/Modal';
import CasefileStore from '../../stores/CasefileStore';
import CasefileActions from '../../actions/CasefilesActionCreators';
import CasefileFolderSelector from './CasefileFolderSelector';
import Button from 'Common/components/Button';
import { connect } from 'react-redux';
import { UserSettingKeys } from 'Settings/redux/types';
import { updateUserSettings } from 'Settings/redux/actions';

class CasefileInformation extends React.Component {
    static propTypes = {
        action: PropTypes.string,
        error: PropTypes.object,
        casefile: PropTypes.object,
        folders: PropTypes.array,
        defaultFolder: PropTypes.number,
        casefileTypes: PropTypes.array,
        changeFolder: PropTypes.func,
        changeDocuments: PropTypes.func,
        changeCasefile: PropTypes.func,
        caseType: PropTypes.object,
        documents: PropTypes.array,
        changeCaseType: PropTypes.func,
        selectedFolderId: PropTypes.number,
        inputErrors: PropTypes.object,
        changeCaseTypeId: PropTypes.func,
        changeVisibilityMode: PropTypes.func,
        recipients: PropTypes.array,
        dispatch: PropTypes.func,
        userSettings: PropTypes.object,
        clearing: PropTypes.bool,
        applyingPayload: PropTypes.bool,
    };

    state = {
        setLastUsedFolder: false,
        currentLastUsedFolder: undefined,
    };

    componentDidMount() {
        const { userSettings } = this.props;

        /**
         * We must check if there's a lastUsedFolder stored in the user settings
         * If not, we will use the defaultFolder prop to start with
         * and set that as lastUsedFolder for the future
         */
        if (userSettings.lastUsedFolder && !this.state.setLastUsedFolder) {
            this.setState({
                currentLastUsedFolder: userSettings.lastUsedFolder.folderId,
            });

            return;
        }

        this.setState({ setLastUsedFolder: true });
    }

    componentDidUpdate(prevProps) {
        const { selectedFolderId: prevSelectedFolderId } = prevProps;
        const {
            defaultFolder,
            selectedFolderId,
            folders,
            clearing,
            applyingPayload,
        } = this.props;
        const { setLastUsedFolder, currentLastUsedFolder } = this.state;
        const _cachedFolderId = CasefileStore.getCachedFolder();

        if (prevSelectedFolderId && !selectedFolderId) {
            return;
        }

        if (folders.length <= 0) {
            return;
        }

        if (
            selectedFolderId &&
            setLastUsedFolder &&
            selectedFolderId !== currentLastUsedFolder
        ) {
            this.updateLastUsedFolder(selectedFolderId);

            return;
        }

        if (!clearing && !prevSelectedFolderId && selectedFolderId === null) {
            const folderSelection =
                _cachedFolderId ??
                selectedFolderId ??
                currentLastUsedFolder ??
                defaultFolder;

            CasefileActions.changeFolder(folderSelection);

            return;
        }

        /**
         * We make sure there are folders ids selected before making any comparison and changes
         * in the user preferences endpoint (these values start as null)
         */
        if (
            !applyingPayload &&
            prevSelectedFolderId &&
            selectedFolderId &&
            prevSelectedFolderId !== selectedFolderId
        ) {
            this.updateLastUsedFolder(selectedFolderId);

            return;
        }

        /**
         * If there is no setting for lastUserFolder in user preferences
         * (which will happen to all users the first time)
         * setLastUsedFolder boolean will be true, so
         * we use the default folder id and set is as lastUserFolder.
         * We must check first that the defaultFolder prop is loaded
         */
        if (
            !setLastUsedFolder ||
            (setLastUsedFolder && !defaultFolder) ||
            currentLastUsedFolder
        ) {
            return;
        }

        if (!applyingPayload) {
            this.setState({ setLastUsedFolder: true });

            return;
        }

        this.updateLastUsedFolder(defaultFolder);
    }

    // Stores lastUsedFolder in User settings
    updateLastUsedFolder = (folderId) => {
        const { dispatch, applyingPayload } = this.props;
        const lastUsedFolder = folderId && !applyingPayload;

        /**
         * If this is a new casefile & the user hasn't yet a lastUsedFolder
         * in their preferences endpoint, we MUST set also the Flux selected folder here
         */
        if (lastUsedFolder) {
            dispatch(
                updateUserSettings(UserSettingKeys.lastUsedFolder, {
                    folderId: folderId,
                })
            );
        }

        CasefileActions.changeFolder(folderId);

        this.setState({
            setLastUsedFolder: lastUsedFolder,
            currentLastUsedFolder: folderId,
        });
    };

    _updateCasefileHandler = (event) => {
        let { casefile, changeCasefile } = this.props;
        let fieldName = event.target.name;
        let value = event.target.value;

        casefile[fieldName] = value;
        assign({}, casefile);

        changeCasefile(casefile);
    };

    displayCopyRecipientError = () => {
        let config = {
            className: 'large',
            title: i18n`Activate Sensitive Information`,
            body: (
                <div>
                    <p>{i18n`You cannot activate sensitive data for this case file if
                             you have added copy recipients.`}</p>

                    <p>
                        {i18n`You can automatically remove copy recipients and activate this
                             feature, or cancel and continue without sensitive data`}
                    </p>
                </div>
            ),
            buttons: (
                <div>
                    <Button
                        theme="green"
                        onClick={() => {
                            CasefileActions.removeCopyRecipients();
                            CasefileActions.setSensitiveData(true);
                            modal.hide();
                        }}>
                        {i18n`Remove Copy Recipients and Activate`}
                    </Button>
                    <Button onClick={modal.hide}>{i18n`Cancel`}</Button>
                </div>
            ),
        };

        modal.show(config);
    };

    _toggleSensitiveData = (event) => {
        event.stopPropagation();

        let { recipients } = this.props;
        let { checked } = event.target;

        if (checked && recipients.length > 0) {
            return this.displayCopyRecipientError();
        }

        CasefileActions.setSensitiveData(checked);
    };

    _toggleSignOnMeeting = (event) => {
        event.stopPropagation();
        let { checked } = event.target;

        CasefileActions.setSignOnMeeting(checked);
    };

    _updateCasefileLanguage = (language) => {
        let { casefile, changeCasefile } = this.props;

        casefile.language = language;

        changeCasefile(casefile);
    };

    _updateFolderId = (event) => {
        this.props.changeFolder(event.target.value);
    };

    _updateCasefileTypeId = (event) => {
        this.props.changeCaseTypeId(event.target.value);
    };

    _updateVisibilityMode = (event) => {
        this.props.changeVisibilityMode(event.target.value);
    };

    displayCasefileDetails = (event) => {
        event.preventDefault();

        let casefile = this.getCasefile();

        let config = {
            className: 'full-screen penneo-casefiles',
            title: i18n`Case file details`,
            body: <CasefileDetailsTable casefile={casefile} />,
            buttons: (
                <Button onClick={modal.hide} theme="blue">
                    {i18n`Close`}
                </Button>
            ),
        };

        modal.show(config);
    };

    getCasefile = () => {
        let { casefile } = this.props;
        let casefileInfo;

        if (casefile.caseFileTypeId) {
            casefileInfo = CasefileStore.getCaseTypeById(
                casefile.caseFileTypeId
            );

            return casefileInfo;
        }
    };

    render() {
        let { casefileTypes, casefile, action, inputErrors } = this.props;

        // Folders
        let { folders, selectedFolderId } = this.props;
        const { currentLastUsedFolder } = this.state;

        return (
            <div className="casefile-row-form">
                <div className="casefile-recipient-form">
                    <div className="fieldset">
                        <div className="legend">
                            <span className="fa-stack">
                                <i className="fa fa-circle fa-stack-2x"></i>
                                <strong className="fa-stack-1x fa-stack-text fa-inverse">
                                    1
                                </strong>
                            </span>
                            &nbsp;{i18n('Case File')}
                        </div>

                        <table className="casefile-information-table">
                            <tbody>
                                {/* Case file Name */}
                                <tr>
                                    <td className="label">
                                        {i18n('Case File Name')}
                                    </td>
                                    <td className="casefile-input">
                                        <input
                                            type="text"
                                            name="title"
                                            className={
                                                inputErrors.title
                                                    ? 'input-error'
                                                    : ''
                                            }
                                            value={casefile.title || ''}
                                            placeholder={i18n('Case file name')}
                                            onChange={
                                                this._updateCasefileHandler
                                            }
                                        />

                                        {inputErrors.title && (
                                            <ErrorMessage
                                                message={inputErrors.title}
                                            />
                                        )}
                                    </td>
                                </tr>

                                {/* Case file Type */}
                                <tr>
                                    <td className="label">
                                        {i18n('Case File Type')}&nbsp;
                                        {casefile.caseFileTypeId && (
                                            <a
                                                onClick={
                                                    this.displayCasefileDetails
                                                }
                                                href="#">
                                                <i className="fa fa-question-circle"></i>
                                            </a>
                                        )}
                                    </td>
                                    <td>
                                        <select
                                            onChange={
                                                this._updateCasefileTypeId
                                            }
                                            value={
                                                casefile.caseFileTypeId || -1
                                            }
                                            className={
                                                (inputErrors.caseFileTypeId
                                                    ? 'input-error'
                                                    : '') +
                                                (casefileTypes.length === 0
                                                    ? ' text-medium'
                                                    : '')
                                            }>
                                            <option value={-1} disabled>
                                                {casefileTypes.length === 0
                                                    ? i18n('Loading...')
                                                    : i18n(
                                                          '- Select a Case File Type -'
                                                      )}
                                            </option>
                                            {casefileTypes.map(
                                                (type, index) => (
                                                    <option
                                                        key={index}
                                                        value={type.id}>
                                                        {type.name}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                        {inputErrors.caseFileTypeId && (
                                            <ErrorMessage
                                                message={
                                                    inputErrors.caseFileTypeId
                                                }
                                            />
                                        )}
                                    </td>
                                </tr>

                                {/* Signer visibility */}
                                <tr>
                                    <td className="label">
                                        {i18n('Signer can see')}
                                    </td>
                                    <td>
                                        <select
                                            name="documentsSignerSee"
                                            value={casefile.visibilityMode}
                                            onChange={
                                                this._updateVisibilityMode
                                            }>
                                            <option value={0}>
                                                {i18n('All documents')}
                                            </option>
                                            <option value={1}>
                                                {i18n(
                                                    'Documents for signature'
                                                )}
                                            </option>
                                        </select>
                                    </td>
                                </tr>

                                {/* Casefile Language */}
                                <tr>
                                    <td className="label">
                                        {i18n('Language')}
                                    </td>
                                    <td>
                                        <LanguageDropdown
                                            type="standalone"
                                            name="language"
                                            value={casefile.language}
                                            callback={
                                                this._updateCasefileLanguage
                                            }
                                        />
                                    </td>
                                </tr>

                                {/* Select Folder */}
                                <tr>
                                    <td className="label">
                                        {i18n('Store in folder')}
                                    </td>
                                    <td>
                                        <CasefileFolderSelector
                                            folders={folders}
                                            action={action}
                                            lastUsedFolder={
                                                currentLastUsedFolder
                                            }
                                            selectedFolder={selectedFolderId}
                                            forceUpdate={
                                                this.updateLastUsedFolder
                                            }
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <label className="custom-checkbox">
                                <span className="label">
                                    {i18n('Sensitive')}
                                </span>
                                <input
                                    type="checkbox"
                                    checked={casefile.sensitiveData || false}
                                    onChange={this._toggleSensitiveData}
                                />
                                <span className="check">
                                    <i className="fas fa-check" />
                                </span>
                            </label>
                            <label className="custom-checkbox pl">
                                <span className="label">
                                    {i18n('Sign at the meeting')}
                                </span>
                                <input
                                    type="checkbox"
                                    checked={casefile.signOnMeeting || false}
                                    onChange={this._toggleSignOnMeeting}
                                />
                                <span className="check">
                                    <i className="fas fa-check" />
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state) => ({
    userSettings: state.settings.data.user,
}))(CasefileInformation);
