import LanguageDropdown from 'Language/components/LanguageDropdown';
import React from 'react';
import './login-language-selector.scss';

type Props = {
    mobile: boolean;
};

type State = {
    open: boolean;
};

class LoginLanguageSelector extends React.Component<Props, State> {
    state = {
        open: false,
    };

    open = () => {
        this.setState({
            open: true,
        });
    };

    close = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const { open } = this.state;
        const { mobile } = this.props;

        return (
            <div className="login-language-selector">
                {mobile ? (
                    <div className="language-selector-mobile">
                        <LanguageDropdown
                            type="simple"
                            persist={false}
                            local={true}
                        />
                    </div>
                ) : (
                    <div
                        className="login-language-selector-wrapper"
                        onMouseEnter={this.open}
                        onMouseLeave={this.close}>
                        <LanguageDropdown
                            type="li"
                            open={open}
                            persist={false}
                            local={true}
                            closeDropdown={this.close}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default LoginLanguageSelector;
