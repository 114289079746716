import classNames from 'classnames';
import React from 'react';

export enum AnimationType {
    beat = 'fa-beat',
    shake = 'fa-shake',
}

type AnimationOptions = {
    type: AnimationType;
    delay?: number;
    duration?: number;
    times?: number;
    easing?: string;
};

type Props = {
    tag: string;
    className?: string;
    options: AnimationOptions;
};

export const AnimatedElement = ({ tag, className, options }: Props) => {
    const style = {
        '--fa-animation-duration': options.duration
            ? `${options.duration}s`
            : undefined,
        '--fa-animation-delay': options.delay ? `${options.delay}s` : undefined,
        '--fa-animation-iteration-count': options.times,
        '--fa-animation-timing': options.easing,
    } as React.CSSProperties;

    return React.createElement(tag, {
        className: classNames(className, options.type),
        style,
    });
};
