import React from 'react';
import TransitionGroup from 'react-addons-css-transition-group';
import Constants from 'Constants';
import { translate } from 'Language';
import Button from 'Common/components/Button';

export default class UnderMaintenance extends React.Component {
    render() {
        return (
            <div className="error-page">
                <div className="content">
                    <TransitionGroup
                        transitionName="redirectionWidget"
                        transitionAppear={true}
                        transitionEnterTimeout={500}
                        transitionAppearTimeout={500}
                        transitionLeaveTimeout={300}>
                        <div key="error-container" className="error-container">
                            <div className="bar info"></div>
                            <TransitionGroup
                                transitionName="redirectionWidget"
                                transitionAppear={true}
                                transitionEnterTimeout={500}
                                transitionAppearTimeout={500}
                                transitionLeaveTimeout={300}>
                                <div key="0" style={{ padding: '0 2em' }}>
                                    <h2 className="title">
                                        <p></p>
                                        {translate(
                                            `The service is currently unavailable`
                                        )}
                                    </h2>
                                    <h3 className="subtitle">
                                        {translate(
                                            `An unexpected error occurrend and`
                                        )}
                                        <br />
                                        {translate(`we will be back shortly`)}
                                        <br />
                                        <br />
                                        {translate(`Check our status page
                                                for more information`)}
                                        <br />
                                    </h3>

                                    <a
                                        href={Constants.sites.statusPage}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <Button theme="blue">
                                            {translate('Visit Status Page')}
                                        </Button>
                                    </a>
                                </div>
                            </TransitionGroup>
                        </div>
                    </TransitionGroup>
                </div>
            </div>
        );
    }
}
