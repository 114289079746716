import React, { useCallback, useEffect, useState } from 'react';
import { MultiValue } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { i18n } from 'Language';
import {
    clientsState,
    fetchClientsByEntity,
    fetchClientsQuery,
    setCurrentLinks,
} from 'Clients/redux';
import { Client, ClientLinkEntity, ClientLinks } from './types/Clients';
import { notify } from 'react-notify-toast';
import { debounce } from 'lodash';
import { DEBOUNCE_SEARCH_DELAY } from './ClientsList';
import { useAppDispatch, useAppSelector } from 'Store';
import { UserEntity as User } from 'types/User';
import {
    isCustomerAdministrator,
    isPenneoAdministrator,
} from 'Common/utils/userPermissions';

export type AutocompleteEntry = {
    label: string;
    value: Client;
};

type Props = {
    linkEntity: ClientLinkEntity;
    onCreate?: (newClient: string) => void;
    user: User;
};

export function AutocompleteClients({ linkEntity, onCreate, user }: Props) {
    const dispatch = useAppDispatch();
    const isAdmin =
        isPenneoAdministrator(user) || isCustomerAdministrator(user);
    const { currentLinks } = useAppSelector(clientsState);
    const [value, setValue] = useState<MultiValue<AutocompleteEntry>>();
    const [refreshKey, setRefreshKey] = useState<number>();

    const buildOptions = (clientList: Client[]): AutocompleteEntry[] =>
        clientList.map((client) => ({
            label: client.name,
            value: client,
        }));

    const onChange = useCallback(
        (newValue: MultiValue<AutocompleteEntry>) => {
            dispatch(
                setCurrentLinks({
                    ...(currentLinks as ClientLinks),
                    new: newValue.map((entry) => entry.value),
                    dirty: true,
                })
            );

            setValue(newValue);
        },
        [currentLinks]
    );

    useEffect(() => {
        /**
         * RESET:
         * If you go from a draft to a new casefile,
         * this component is not mounted again. With this,
         * we clear any leftover client list
         */
        if (
            !currentLinks ||
            (!linkEntity.entityId && currentLinks?.linkEntity?.entityId) ||
            linkEntity.entityId !== currentLinks?.linkEntity?.entityId
        ) {
            setValue(undefined);

            /**
             * Note that we have to introduce a "random"
             * id generator, to use as the key
             * that'll force the rerender of the
             * react-select component.
             * Without this key, even if the value
             * passed to the component is undefined,
             * the modified list is persisted locally
             */
            setRefreshKey(Date.now());

            dispatch(fetchClientsByEntity(linkEntity));

            return;
        }

        /**
         * If there's a saved list of clients
         * on this entity, we will use that
         * to set the initial options selected.
         * Subsequent updates of Redux will follow
         * only the new, unsaved list.
         */
        setValue(
            buildOptions(
                currentLinks.dirty ? currentLinks.new : currentLinks.saved
            )
        );
    }, [linkEntity, currentLinks]);

    const fetchClientList = useCallback(
        async (searchTerm: string) => {
            try {
                const { clients } = await fetchClientsQuery({
                    per_page: 10,
                    page: 1,
                    ...(searchTerm && { name: searchTerm }),
                });

                /**
                 * We must remove already selected values from
                 * the list of fetched options
                 */
                const filteredOptions = clients.filter(
                    (client) =>
                        !value?.find(
                            (selected) => selected.value.id === client.id
                        )
                );

                return buildOptions(filteredOptions);
            } catch (error) {
                notify.show(
                    <span>
                        {i18n(
                            'An error occurred trying to get the list of clients'
                        )}
                    </span>,
                    'error',
                    6000
                );

                return [];
            }
        },
        [value]
    );

    const debouncedSearchClients = useCallback(
        debounce(
            (
                searchTerm: string,
                callback: (options: AutocompleteEntry[]) => void
            ) => {
                fetchClientList(searchTerm).then((options) =>
                    callback(options)
                );
            },
            DEBOUNCE_SEARCH_DELAY
        ),
        [fetchClientList]
    );

    /**
     * Depending on the user role,
     * we have to use a different react-select component.
     * Only admins can be promted to create new clients,
     * so if the user is an admin, the 'creatable' component
     * and relevant props are rendered
     */
    const Component = isAdmin ? AsyncCreatableSelect : AsyncSelect;
    const creatableProps = {
        ...(isAdmin && {
            onCreateOption: onCreate,
        }),
    };

    return (
        <>
            <p
                id={`autocomplete-clients-input`}
                className="sr-only"
                aria-hidden="true">
                {i18n`Select clients`}
            </p>
            <Component
                key={linkEntity.entityId ?? refreshKey}
                value={value}
                isMulti
                name="autocomplete-clients"
                noOptionsMessage={() => i18n('No options')}
                onChange={onChange}
                placeholder={`${i18n('Search')}...`}
                aria-labelledby={`autocomplete-clients-input`}
                classNamePrefix={'autocomplete-tags'}
                unstyled
                defaultOptions={false}
                loadOptions={debouncedSearchClients}
                cacheOptions={true}
                {...creatableProps}
            />
        </>
    );
}
