import React from 'react';
import { images } from 'Constants';

type Props = {
    src: string;
    height?: string;
    width?: string;
    alt?: string;
    style?: any;
    className?: string;
};

class Image extends React.Component<Props> {
    render() {
        return <img {...this.props} src={`${images}${this.props.src}`} />;
    }
}

export default Image;
