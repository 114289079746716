import UIButton from 'Common/components/Vega/Button';
import { i18n } from 'Language';
import React from 'react';
import {
    UIModalContainer,
    UIModalFooter,
    UIModalHeader,
    UIModalOverlay,
} from 'Common/components/Vega/Modal';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

type Props = {
    handleCloseModal: () => void;
    submitDelete: () => void;
    selectedItems: number;
    allSelected: boolean;
};

const DeleteItemsModal = ({
    handleCloseModal,
    submitDelete,
    selectedItems,
    allSelected,
}: Props) => {
    const getSubmitButtonMessage = () => {
        if (selectedItems === 1) {
            return i18n`deleteItemsModal.submitButton.singular`;
        }

        return i18n`deleteItemsModal.submitButton.plural`;
    };

    const getTitleMessage = () => {
        if (selectedItems === 1) {
            return i18n`deleteItemsModal.title.singular`;
        }

        if (allSelected) {
            return i18n`deleteItemsModal.title.all`;
        }

        return i18n`${selectedItems} deleteItemsModal.title.plural`;
    };

    return (
        <UIModalOverlay onClose={handleCloseModal} zIndex="higher">
            <UIModalContainer>
                <UIModalHeader>
                    <div slot="icon">
                        <ExclamationTriangleIcon className="h-6 fill-danger-700" />
                    </div>
                    <span slot="title">{getTitleMessage()}</span>
                    <div slot="subtitle">
                        <span className="font-semibold mr-1">{i18n`Please note:`}</span>
                        <span>{i18n`deleteItemsModal.subtitle.note`}</span>
                    </div>
                </UIModalHeader>
                <UIModalFooter>
                    <UIButton
                        slot="secondaryButton"
                        onClick={handleCloseModal}
                        priority="tertiary"
                        type="neutral">
                        {i18n`Cancel`}
                    </UIButton>
                    <UIButton
                        slot="primaryButton"
                        onClick={submitDelete}
                        priority="primary"
                        type="danger">
                        {getSubmitButtonMessage()}
                    </UIButton>
                </UIModalFooter>
            </UIModalContainer>
        </UIModalOverlay>
    );
};

export default DeleteItemsModal;
