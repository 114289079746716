import React from 'react';
import { i18n } from 'Language';
import './empty-table-body.scss';
import Button from 'Common/components/Button';

type Props = {
    itemCount: number;
    search: string;
    clearSearch: () => void;
    message?: string;
};

const EmptyTableBody = ({ itemCount, search, clearSearch, message }: Props) => {
    if (search && search.length > 1 && itemCount === 0) {
        return (
            <div className="empty-table-body">
                <div>
                    <div className="sad-search">
                        <div className="sad-search-eye-left"></div>
                        <div className="sad-search-eye-right"></div>
                        <div className="sad-search-mouth"></div>
                        <i className="fal fa-search" />
                        <div className="sad-search-shadow"></div>
                    </div>
                </div>
                <div className="empty-table-body-message">
                    {i18n`We couldn't find any results matching ${(
                        <span className="search-term">"{search}"</span>
                    )}`}
                </div>

                <Button
                    theme="blue"
                    variant="outline"
                    className="mt"
                    onClick={clearSearch}
                    icon="far fa-times">
                    {i18n`Clear search terms`}
                </Button>
            </div>
        );
    }

    return (
        <div className="empty-table-body">
            <div>
                <div className="sad-folder">
                    <div className="sad-folder-eye-left"></div>
                    <div className="sad-folder-eye-right"></div>
                    <div className="sad-folder-mouth"></div>
                    <i className="fal fa-folder" />
                    <div className="sad-folder-shadow"></div>
                </div>
            </div>
            <div className="empty-table-body-message">
                {message || i18n`This folder doesn't have any contents`}
            </div>
        </div>
    );
};

export default EmptyTableBody;
