import React from 'react';
import { i18n } from 'Language';

type Props = {
    message?: string;
};

const LoadingData: React.FC<Props> = ({ message }) => (
    <div className="text-center text-medium">
        <h1>
            <i className="far fa-sync fa-lg fa-spin"></i>
            <p className="mt">{message || i18n`Loading...`}</p>
        </h1>
    </div>
);

export default LoadingData;
