/* eslint camelcase: 0 */
import { OIDs } from 'OpenID/Constants';
import {
    OpenIDObj,
    OpenIDCertificate,
    OpenIDToken,
    OpenIDSubjectIdentifiers,
} from 'OpenID/redux/types';

export type MitIDErhvervToken = OpenIDToken & {
    iss: string;
    acr: string;
    nbf: number;
    amr: string[];
    loa: string;
    identity_type: string;
    idp: 'mitid_erhverv';
    auth_time: string;
    transaction_id: string;
    'nemlogin.persistent_professional_id': string;
    'nemlogin.name': string;
    'nemlogin.org_name': string;
    'nemlogin.cvr': string;
    'nemlogin.given_name': string;
    'nemlogin.family_name': string;
};

export type MitIDErhvervSubjectIdentifiers = OpenIDSubjectIdentifiers & {
    [OIDs.OPENID_ORG_NAME_V2]: string;
    [OIDs.OPENID_ORG_IDENTIFIER_TYPE]: 'CVR';
    [OIDs.OPENID_ORG_IDENTIFIER]: string;
    [OIDs.OPENID_FULL_NAME_V2]: string;
};

export type MitIDErhvervCertificate = OpenIDCertificate<
    MitIDErhvervSubjectIdentifiers
>;

/**
 * MitID Erhverv is an alternative form of MitID that also includes data about the organization the user belongs to,
 * and their role there.
 */
export const mitIDErhverv: OpenIDObj<
    MitIDErhvervToken,
    MitIDErhvervCertificate
> = {
    issuers: ['https://netseidbroker.dk/op', 'https://pp.netseidbroker.dk/op'],
    getSubject(token) {
        return {
            [OIDs.OPENID_ISSUER]: token.iss,
            [OIDs.OPENID_ID]: token['nemlogin.persistent_professional_id'],
            [OIDs.OPENID_ORG_NAME_V2]: token['nemlogin.org_name'],
            [OIDs.OPENID_ORG_IDENTIFIER_TYPE]: 'CVR',
            [OIDs.OPENID_ORG_IDENTIFIER]: token['nemlogin.cvr'],
            [OIDs.OPENID_FULL_NAME_V2]: token['nemlogin.name'],
        };
    },
    isOwnToken(token): token is MitIDErhvervToken {
        return (
            this.issuers.includes(token.iss) && token.idp === 'mitid_erhverv'
        );
    },
    isOwnCertificate(certificate): certificate is MitIDErhvervCertificate {
        const { subject } = certificate;

        return (
            this.issuers.includes(subject[OIDs.OPENID_ISSUER]) &&
            !!subject[OIDs.OPENID_FULL_NAME_V2] &&
            subject[OIDs.OPENID_ORG_IDENTIFIER_TYPE] === 'CVR'
        );
    },
    getCertificateDisplayInfo({ subject, issuer }) {
        return {
            title: subject[OIDs.OPENID_FULL_NAME_V2],
            issuer: issuer[OIDs.OPENID_ORG_NAME_V2],
            organization: {
                idType: subject[OIDs.OPENID_ORG_IDENTIFIER_TYPE],
                id: subject[OIDs.OPENID_ORG_IDENTIFIER],
                name: subject[OIDs.OPENID_ORG_NAME_V2],
            },
        };
    },
};
