import PropTypes from 'prop-types';
import React from 'react';
import Button from 'Common/components/Button';
import { i18n } from 'Language';

export default class CustomTitleTemplate extends React.Component {
    static propTypes = {
        options: PropTypes.array,
        title: PropTypes.string,
        text: PropTypes.string,
        onChange: PropTypes.func,
    };

    state = {
        caretPosition: null,
        currentOption: null,
    };

    updateText = (text, clean = false) => {
        if (clean) {
            let cleanText = this.cleanText(text);

            return this.props.onChange(cleanText);
        }

        this.props.onChange(text);
    };

    onFocusChange = (event) => {
        let { target } = event;

        this.setState({
            caretPosition: target.selectionStart,
        });

        let { text } = this.props;

        this.updateText(this.cleanText(text));
    };

    appendVariable = (event) => {
        event.preventDefault();

        let { caretPosition, currentOption } = this.state;
        let { text } = this.props;

        if (!currentOption) {
            let { options } = this.props;

            currentOption = options[0];
        }

        // Surround text to insert with template
        let insertText = `{{${currentOption}}}`;

        // Append directly
        if (!text || text === 'placeholder' || text.length === 0) {
            return this.updateText(insertText, true);
        }

        if (!caretPosition) {
            this.setState({
                caretPosition: null,
            });

            let appendedText = text + ' ' + insertText + ' ';

            return this.updateText(appendedText, true);
        }

        // split string on every character
        let stringArray = text.split('');

        // Merge strings at last caret position
        stringArray.splice(caretPosition, 0, ' ' + insertText + ' ');

        // Join array after strings are merged
        let result = stringArray.join('');

        this.setState({
            caretPosition: null,
        });

        return this.updateText(result, true);
    };

    componentWillUpdate(nextProps) {
        if (
            this.props.options !== nextProps.options &&
            nextProps.options.length > 0
        ) {
            this.setState({
                currentOption: nextProps.options[0],
            });
        }

        /*
        if (this.state.text !== nextState.text) {
            this.renderMarkup(nextState.text);
        }*/
    }

    cleanText = (string) => {
        if (!string) {
            return null;
        }

        return string.trim().replace(/\s\s+/g, ' ');
    };

    updateOption = (event) => {
        let { options } = this.props;

        this.setState({
            currentOption: event.target.value || options[0],
        });
    };

    onChange = (event) => {
        let { target } = event;

        this.setState({
            caretPosition: target.selectionStart,
        });

        return this.updateText(target.value);
    };

    render() {
        let { options, title } = this.props;
        let { text } = this.props;

        return (
            <div className="form custom-title-template">
                <label>{i18n(title)}</label>
                {/* Custom Template Input*/}
                <input
                    type="text"
                    className="x2 inline-block align-top"
                    value={(text !== 'placeholder' ? text : '') || ''}
                    placeholder={i18n`Enter the title template (or select variables with the add field button)`}
                    onBlur={this.onFocusChange}
                    onFocus={this.onFocusChange}
                    onChange={this.onChange}
                    onClick={this.onClick}></input>

                <select
                    className="inline-block align-top ml"
                    onChange={this.updateOption}>
                    {options.map((option, i) => (
                        <option key={i} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <Button
                    theme="blue"
                    variant="text"
                    className="ml"
                    icon="fa fa-plus"
                    onClick={this.appendVariable}
                    renderIconLeft={true}>
                    {i18n`Add field`}
                </Button>
            </div>
        );
    }
}
