import type * as Amplitude from '../Common/Amplitude';

const fakeFn = () => Promise.resolve();

const initUser: (
    user: Amplitude.UserEntity,
    customers: Amplitude.CustomerEntity[],
    settings: Amplitude.Settings
) => Promise<void[]> = fakeFn as any;

const mockAmplitude = {
    init: fakeFn,
    track: fakeFn,
    setUserId: fakeFn,
    setUserProperties: fakeFn,
    userProperties: fakeFn,
    identify: fakeFn,
    incrementUserProperty: fakeFn,
    appendUserProperty: fakeFn,
    initUser,
};

export default mockAmplitude;
