import React from 'react';
import { SignerEntity } from 'types/Signer';
import CasefileAttachment from './CasefileAttachment';

export type Props = {
    signer: SignerEntity;
    files: any[];
};

const CasefileAttachmentList: React.FunctionComponent<Props> = ({
    signer,
    files,
}): JSX.Element => (
    <div className="signer-details-files">
        {files.map((file: any, key: number) => (
            <CasefileAttachment signer={signer} file={file} key={key} />
        ))}
    </div>
);

export default CasefileAttachmentList;
