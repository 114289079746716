import React from 'react';
import { i18n } from 'Language';
import './casefile-eventlog.scss';
import { CaseFileEntity } from 'types/CaseFile';
import { modal } from 'Common/components/Common/Modal';
import Button from 'Common/components/Button';

type Props = {
    casefile: CaseFileEntity;
};

class CasefilePrivacy extends React.Component<Props> {
    displaySensitiveDataInfo = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const config = {
            component: (
                <div className="casefile-modal-v2">
                    <div className="casefile-modal-v2-title">
                        {i18n`Sensitive Information`}

                        <div className="close" onClick={modal.hide}>
                            <div className="far fa-times"></div>
                        </div>
                    </div>

                    <p>
                        {i18n`Normally, Penneo sends the signed documents as attachments in emails. However, certain information is not suitable for sending via email and requires strict access control.`}
                    </p>

                    <p>
                        {i18n`If you are sending documents containing such information that you don't want to send via email, you should enable this feature, which acts as an extra security and compliance layer.`}
                    </p>

                    <h4 className="tag">{i18n`Please note`}</h4>
                    <ul className="list">
                        <li>
                            {i18n`All recipients will be asked to authenticate with an Electronic ID, before they can read and sign the documents`}
                        </li>
                        <li>
                            {i18n`You will not be able to use the "Copy recipient" functionality`}
                        </li>
                        <li>
                            {i18n`You will be asked to provide a social security number for every recipient`}
                        </li>
                        <li>
                            {i18n`The signed documents will not be sent out by email as attachments, but must be downloaded from Penneo.com`}
                        </li>
                    </ul>
                    <br />
                    <div className="text-center">
                        <Button
                            theme="green"
                            onClick={modal.hide}
                            icon="far fa-check">
                            {i18n`I understand`}
                        </Button>
                    </div>
                </div>
            ),
        };

        modal.show(config);
    };

    render() {
        const { casefile } = this.props;

        return (
            <div>
                <h4 className="casefile-details-section-header">
                    {i18n`Security and Privacy`}
                </h4>
                <div className="casefile-privacy">
                    <div className="inline-block mr">
                        <label>
                            {i18n`Sensitive Data Protection`}
                            &nbsp;
                            <i
                                className="far text-blue fa-question-circle"
                                onClick={this.displaySensitiveDataInfo}
                            />
                        </label>
                        <div>
                            {casefile.sensitiveData ? (
                                <span>
                                    <i className="far fa-lock-alt" />
                                    &nbsp;
                                    {i18n`Access control is enabled`}
                                </span>
                            ) : (
                                <span>
                                    <i className="far fa-lock-open-alt" />
                                    &nbsp;
                                    {i18n`No access control`}
                                </span>
                            )}
                        </div>
                    </div>

                    <div
                        className="inline-block ml"
                        style={{ verticalAlign: 'top' }}>
                        <label style={{ marginTop: 0 }}>
                            {i18n`Document Visibility`}
                        </label>
                        <div>
                            {casefile.visibilityMode === 0 ? (
                                <span>
                                    <i className="far fa-eye" />
                                    &nbsp;
                                    {i18n`Recipients can see all documents when signing`}
                                </span>
                            ) : (
                                <span>
                                    <i className="far fa-eye-slash" />
                                    &nbsp;
                                    {i18n`Recipients can only see documents they have to sign`}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CasefilePrivacy;
