import { createComponent } from '@lit/react';
import { Tooltip } from '@penneo/vega-ui';
import React from 'react';

const UITooltip = createComponent({
    tagName: 'vega-tooltip',
    elementClass: Tooltip,
    react: React,
});

export default UITooltip;
