import React from 'react';
import { Helmet } from 'react-helmet';
import { i18n } from 'Language';

// Flux
import AuthStore from 'Auth/stores/AuthStore';

// Redux
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import {
    createKYC,
    clearError,
    clearSendingState,
    resetState,
    updateLanguage,
} from 'KYC/redux/actions';
import { KYCRecipient } from 'KYC/redux/types';
import { KYCMessageValidation } from 'types/DataValidation';

// Components
import KYCDetails from 'KYC/components/KYCDetails';
import KYCRecipientList from 'KYC/components/KYCRecipientList';
import KYCRequirements from 'KYC/components/KYCRequirements';
import KYCEmailTemplate from 'KYC/components/KYCEmailTemplate';
import KYCSendingState from 'KYC/components/KYCSendingState';
import KYCAdvancedOptions from 'KYC/components/KYCAdvancedOptions';
import Button from 'Common/components/Button';
import { UserEntity, UserRights } from 'types/User';
import { ReactRouter } from 'types/Router';

export type Props = {
    dispatch: AppDispatch;
    modal: JSX.Element;
    isSending: boolean;
    sent: boolean;
    title: string;
    recipients: KYCRecipient[];
    emailTemplatesValidation: KYCMessageValidation;
    user: UserEntity;
    router: ReactRouter;
};

export class NewKYCPage extends React.Component<Props> {
    componentDidMount() {
        const { dispatch, user, router } = this.props;

        /**
         * Even if the user doesn't have access to KYC, they can still
         * access this route. Here we will then redirect them to an error page
         * if that is the case
         */
        if (!user.rights.includes(UserRights.KYC)) {
            router.push('/404');

            return;
        }

        const { language } = AuthStore.getCustomer();

        dispatch(updateLanguage(language));
    }

    componentWillMount() {
        const { dispatch } = this.props;

        dispatch(resetState());
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(resetState());
    }

    handleSendKYC = () => {
        const { dispatch } = this.props;

        dispatch(createKYC());
    };

    retry = () => {
        const { dispatch } = this.props;

        dispatch(createKYC());
        dispatch(clearError());
    };

    back = () => {
        const { dispatch } = this.props;

        dispatch(clearError());
        dispatch(clearSendingState());
    };

    getBasicValidation = () => {
        const { title, recipients, emailTemplatesValidation } = this.props;
        let isValid: boolean = true;

        if (title === '') {
            isValid = false;
        }

        if (!recipients.length) {
            isValid = false;
        }

        Object.keys(emailTemplatesValidation).forEach((key) => {
            if (!emailTemplatesValidation[key].valid) {
                isValid = false;
            }
        });

        return isValid;
    };

    onLanguageChange(language: string) {
        const { dispatch } = this.props;

        dispatch(updateLanguage(language));
    }

    render() {
        const { isSending, modal } = this.props;
        const isValid = this.getBasicValidation();

        if (isSending) {
            return (
                <KYCSendingState
                    onRetry={this.retry}
                    onBack={this.back}
                    showSuccess={true}
                />
            );
        }

        return (
            <div className="create-kyc-page">
                <Helmet>
                    <title>{i18n`Create new identity validation`}</title>
                </Helmet>

                {modal && <div className="casefile-details-modal">{modal}</div>}

                <div className="white-container">
                    <h3 className="title">
                        {i18n`Create new identity validation`}
                    </h3>

                    <div className="content">
                        <p className="mt0">
                            {i18n`Collect information about your customers and validate their identity to minimize risk and save time in a secure, fast and compliant way`}
                        </p>

                        <KYCDetails
                            onLanguageChange={this.onLanguageChange.bind(this)}
                        />
                        <KYCRecipientList />
                        <KYCRequirements />
                        <KYCEmailTemplate />
                        <KYCAdvancedOptions />

                        <div className="text-center mt">
                            <Button
                                theme="green"
                                onClick={this.handleSendKYC}
                                icon="far fa-paper-plane"
                                disabled={!isValid}
                                renderIconLeft={true}>
                                {i18n`Send`}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ReduxState) => ({
    isSending: state.kyc.__states.isSending,
    title: state.kyc.__new.title,
    recipients: state.kyc.__new.recipients,
    emailTemplatesValidation: state.kyc.__new.validation.emailTemplates,
}))(NewKYCPage);
