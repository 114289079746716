import React from 'react';
import { modal } from 'Common/components/Common/Modal';
import { i18n } from 'Language';
import Loading from 'Common/components/Loaders/LoadingData';
import { notify } from 'react-notify-toast';

import URIActions from '../../actions/URIActionCreators';
import URIStore from '../../stores/URIStore';
import Button from 'Common/components/Button';

export default class CasefileTemplateCreatorModal extends React.Component {
    state = {
        open: false,
        templateName: '',
        templateData: null,
        fetching: false,
    };

    componentDidMount() {
        URIStore.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        URIStore.removeChangeListener(this.onChange);
    }

    onChange = () => {
        let { fetching } = URIStore.getCasefileTemplateState();

        this.setState(
            {
                fetching: fetching,
                templateData: URIStore.getIntegrationCode(),
            },
            () => {
                this.renderModal();
            }
        );
    };

    componentDidUpdate(prevProps, prevState) {
        // onSuccess
        if (prevState.fetching && !this.state.fetching && !this.state.error) {
            this.handleSuccess();
        }

        // onError
        if (prevState.fetching && !this.state.fetching && this.state.error) {
            this.handleError();
        }
    }

    handleSuccess = () => {
        if (!this.state.open) {
            return false;
        }

        this.setState(
            {
                open: false,
            },
            () => {
                modal.hide();

                notify.show(
                    <span>
                        {i18n`Template saved`} <i className="fa fa-check" />
                    </span>,
                    'success',
                    2000
                );
            }
        );
    };

    onNameChange = (event) => {
        let { value } = event.target;

        this.setState({ templateName: value });
    };

    modalBody = () => {
        let { fetching } = this.state;

        if (fetching) {
            return <Loading />;
        }

        return (
            <div>
                <form onSubmit={this.createTemplate}>
                    <div className="sub-header">
                        {i18n`By saving this information as a template you will be able to create
                              new case files with pre-defined data.`}
                    </div>
                    <div className="template-name-container">
                        <br />
                        <label style={{ display: 'inline-block' }}>
                            {i18n`Template name`}&nbsp;
                        </label>
                        <input
                            style={{ display: 'inline-block' }}
                            onChange={this.onNameChange}
                            type="text"
                            className="template-name-field"
                        />
                    </div>
                    <br />
                    <Button theme="green">{i18n('Create template')}</Button>
                </form>
            </div>
        );
    };

    createTemplate = (event) => {
        event.preventDefault();

        let { templateName, templateData } = this.state;

        URIActions.saveCasefileTemplate(templateName, templateData);
    };

    renderModal = () => {
        if (!this.state.open) {
            return false;
        }

        let config = {
            className: 'large penneo-casefiles', // needed for scss namespacing
            title: i18n`Save case file as template`,
            body: this.modalBody(),
            onClose: () => this.setState({ open: false }),
        };

        modal.show(config);
    };

    openModal = () => {
        // Update integration code.
        URIActions.generateTemplateData();

        this.setState(
            {
                open: true,
            },
            () => {
                this.renderModal();
            }
        );
    };

    render() {
        return (
            <li className="advanced-option" onClick={this.openModal}>
                <i className="fa fa-bookmark" />
                {i18n('Save as template')}
            </li>
        );
    }
}
