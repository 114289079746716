import { storage } from 'Core';
import lodash from 'lodash';
import { StorageKeys } from 'Constants';
import {
    EmailTemplate,
    EmailTemplateGroup,
    TemplateType,
} from 'types/EmailTemplates';

type Signature = (
    templates: EmailTemplate[],
    group: EmailTemplateGroup,
    type: TemplateType,
    defaultTemplates
) => EmailTemplate | null;

/**
 * Returns the custom default template (if any) of the current user
 * for the given type
 */
export const getCustomDefaultTemplate: Signature = (
    templates,
    group,
    type,
    defaultTemplates
) => {
    const customDefaultIdFromStorage = storage.get(
        getCustomEmailTemplateStorageKey(group, type)
    );

    const customDefaultId = lodash.get(defaultTemplates, `${group}.${type}`);

    // For backwards compatibility. Keep checking for IDs stored in localStorage
    const templateId = customDefaultId || customDefaultIdFromStorage;

    return templateId
        ? templates.find((tpl) => tpl.id === templateId) || null
        : null;
};

/**
 * Builds the local storage key used to persist and retrieve the template
 * the user chose as their custom default
 */
export const getCustomEmailTemplateStorageKey = (
    group: EmailTemplateGroup,
    type: string
): string =>
    StorageKeys.CUSTOM_DEFAULT_EMAIL_TPL.replace('{group}', group).replace(
        '{type}',
        type
    );
