import amplitude from './Amplitude';
import * as Amplitude from './Amplitude';

const analytics = {
    identify(
        user: Amplitude.UserEntity,
        customers: Amplitude.CustomerEntity[],
        settings: Amplitude.Settings
    ): Promise<[void[]]> {
        return Promise.all(<[Promise<void[]>]>[
            amplitude.initUser(user, customers, settings),
        ]);
    },

    track(
        eventType: string,
        eventProperties: object | null = null,
        userProperties: object | null = null
    ): Promise<[void]> {
        return Promise.all(<[Promise<void>]>[
            amplitude.track(eventType, eventProperties, userProperties),
        ]);
    },

    // Reference to individual tracking mechanisms
    amplitude: amplitude,
};

export default analytics;
