import { Dispatcher } from 'Core';
import Constants from 'Branding/Constants';

import { AppThunk } from 'Store';
import { SigningAPI } from 'Api';

import { CustomerBranding } from './types';

import {
    BRANDING_RESET_STATE,
    BRANDING_UPDATED,
    BRANDING_FETCH_REQUEST,
    BRANDING_FETCH_SUCCESS,
    BRANDING_FETCH_FAILURE,
    BRANDING_PATCH_REQUEST,
    BRANDING_PATCH_SUCCESS,
    BRANDING_PATCH_FAILURE,
} from './reducer';

import { defaultBranding } from 'Branding/Constants';

export const resetState = (): AppThunk => (dispatch) => {
    dispatch({ type: BRANDING_RESET_STATE });
};

export const updateBranding = (property: string, value: string): AppThunk => (
    dispatch
) => {
    dispatch({
        type: BRANDING_UPDATED,
        payload: {
            property,
            value,
        },
    });
};

export const fetchBranding = (customerId: number): AppThunk => async (
    dispatch
) => {
    dispatch({
        type: BRANDING_FETCH_REQUEST,
    });

    try {
        // Fetch customer branding
        const response = await SigningAPI.get(
            `/customers/${customerId}/branding`
        );

        if (response) {
            const logo =
                response.imageId &&
                (await fetchLogo(customerId, response.imageId));

            const { backgroundColor, textColor, siteUrl } = response;

            const customBranding = {
                ...defaultBranding,
                backgroundColor,
                textColor,
                siteUrl,
            } as CustomerBranding;

            if (logo && logo.url) {
                customBranding.imageUrl = logo.url;
            }

            dispatch({
                type: BRANDING_FETCH_SUCCESS,
                payload: customBranding,
            });
        } else {
            dispatch({
                type: BRANDING_FETCH_SUCCESS,
                payload: defaultBranding,
            });
        }
    } catch (error) {
        dispatch({
            type: BRANDING_FETCH_FAILURE,
            payload: {
                error,
            },
        });
    }
};

export const fetchLogo = async (customerId: number, imageId: number) => {
    return await SigningAPI.get(`/customers/${customerId}/images/${imageId}`);
};

export const saveCustomBranding = (customerId: number): AppThunk => async (
    dispatch,
    getState
) => {
    const reduxState = getState();
    const branding: CustomerBranding = reduxState.customer.branding.data;

    dispatch({
        type: BRANDING_PATCH_REQUEST,
    });

    try {
        await SigningAPI.patch(`/customers/${customerId}/branding`, branding);

        dispatch({
            type: BRANDING_PATCH_SUCCESS,
            payload: branding,
        });

        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.BRANDING_UPDATED,
            branding: branding,
        });
    } catch (error) {
        dispatch({
            type: BRANDING_PATCH_FAILURE,
            payload: {
                error,
            },
        });
    }
};

export const revertDefaultBranding = (customerId: number): AppThunk => async (
    dispatch
) => {
    dispatch({
        type: BRANDING_PATCH_REQUEST,
    });

    try {
        await SigningAPI.post(`/customers/${customerId}/branding/reset`);

        dispatch({
            type: BRANDING_PATCH_SUCCESS,
            payload: defaultBranding,
        });
    } catch (error) {
        dispatch({
            type: BRANDING_PATCH_FAILURE,
            payload: {
                error,
            },
        });
    }
};
