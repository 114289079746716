import React from 'react';
import { i18n } from 'Language';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modal';
import FolderTree from './FolderTree';
import { Folders } from 'Common/redux/Folder/types';
import Button from 'Common/components/Button';

type Props = {
    folders: Folders;
    onClose: Function;
    onChange: Function;
    selectedItem: number | null;
    modalHeaderLabel?: JSX.Element | string;
    modalButtonLabel?: JSX.Element | string;
};

type State = {
    selectedItem: number | null;
    openItems: number[];
};

class FolderSelectorModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { selectedItem } = this.props;
        const openItems = this.getOpenParentIds(selectedItem, true);

        this.state = {
            selectedItem,
            openItems,
        };
    }

    static contextTypes = {
        router: PropTypes.object,
    };

    handleSelectItem = (selectedItem: number) => {
        if (selectedItem === this.state.selectedItem) {
            return false;
        }

        this.setState({ selectedItem });
    };

    handleSubmitSelection = () => {
        const activeFolder = this.getActiveFolder();

        if (activeFolder) {
            this.props.onChange(activeFolder);
        }

        this.props.onClose();
    };

    findParentId = (folderId) => {
        const item = this.props.folders.find((f) => f.id === folderId);

        if (item) {
            return item.parentId;
        }
    };

    findParents = (id) => {
        let res: number[] = [];
        let iteratorId = id;
        let parentsFound = false;

        while (parentsFound === false) {
            const parentId = this.findParentId(iteratorId);

            if (parentId) {
                res.push(parentId);
                iteratorId = parentId;
            } else {
                parentsFound = true;
            }
        }

        return res;
    };

    getActiveFolder() {
        return (
            this.props.folders.filter(
                (f) => f.id === this.state.selectedItem
            )[0] || null
        );
    }

    getOpenParentIds = (folderId, skipOpen: boolean = false) => {
        const { folders } = this.props;
        const parentIds = this.findParents(folderId);

        let isOpen = false;

        if (skipOpen === false) {
            isOpen = this.state.openItems.indexOf(folderId) !== -1;
        }

        const hasChildren =
            folders.filter((f) => f.parentId === folderId).length > 0;

        if (isOpen || !hasChildren || skipOpen) {
            return parentIds;
        }

        return [...parentIds, folderId];
    };

    handleOpenItem = (folderId: number) => {
        const openItems = this.getOpenParentIds(folderId, false);

        return this.setState({
            openItems,
        });
    };

    render() {
        const { folders, modalHeaderLabel, modalButtonLabel } = this.props;
        const { selectedItem, openItems } = this.state;

        return (
            <Modal
                onClose={this.props.onClose}
                title={modalHeaderLabel || i18n`Select a folder`}>
                <FolderTree
                    folders={folders}
                    onOpen={this.handleOpenItem}
                    onSelect={this.handleSelectItem}
                    selectedItem={selectedItem}
                    openItems={openItems}
                />

                <div className="text-center mt">
                    <Button
                        theme="blue"
                        icon="far fa-check"
                        onClick={this.handleSubmitSelection}
                        renderIconLeft={true}>
                        {modalButtonLabel || <span>{i18n`Select folder`}</span>}
                    </Button>
                </div>
            </Modal>
        );
    }
}

export default FolderSelectorModal;
