import { signingFlowIsRegisteredLetter } from 'RegisteredLetter/utils';
import { CaseFileEntity } from 'types/CaseFile';
import { CaseFileItem } from '../Archive';
import { EventLogState } from './redux/types';
import { EventLogSignerEvent } from 'types/EventLog';

export type Status = {
    name: string;
    details: string;
    icon: string;
    color: 'red' | 'green' | 'yellow' | 'gray' | 'cyan';
};

export const getKYCCasefileStatus = (status: number): Status => {
    switch (status) {
        case 1:
            return {
                name: 'Filled',
                details: 'This KYC is still waiting for signatures',
                icon: 'clock',
                color: 'cyan',
            };
        case 2:
            return {
                name: 'Rejected',
                details: 'Recipient rejected to sign',
                icon: 'ban',
                color: 'red',
            };
        case 3:
            return {
                name: 'Deleted',
                details: 'This case was deleted',
                icon: 'times',
                color: 'red',
            };
        case 4:
            return {
                name: 'Signed',
                details: 'All signatures have been recorded',
                icon: 'check-circle',
                color: 'green',
            };
        case 5:
            return {
                name: 'Completed',
                details:
                    'This KYC has been processed by Penneo and is locked for editing',
                icon: 'check-circle',
                color: 'green',
            };
        case 6:
            return {
                name: 'Failed',
                details: 'There was a problem processing this KYC',
                icon: 'crosshairs',
                color: 'red',
            };
        case 7:
            return {
                name: 'Expired',
                details: 'This KYC expired before all signatures were recorded',
                icon: 'times',
                color: 'red',
            };
        default:
            return {
                name: 'Unknown',
                details: 'There was a problem reading the status of this KYC',
                icon: 'question-circle',
                color: 'gray',
            };
    }
};

export const getCasefileStatus = (
    item?: CaseFileEntity | CaseFileItem,
    eventLog?: EventLogState
): Status => {
    const isRegisteredLetter = item
        ? signingFlowIsRegisteredLetter(item.caseFileType?.id)
        : false;

    const everyoneHasOpenedTheCasefile = eventLog
        ? eventLog?.data?.signers?.reduce(
              (
                  prevValue: boolean,
                  signer: { name: string; events: EventLogSignerEvent[] }
              ) => {
                  if (!prevValue) {
                      return false;
                  }

                  const signerHasOpenedCasefile = signer.events.find(
                      (event) => {
                          return event.eventType === 2;
                      }
                  );

                  return !!signerHasOpenedCasefile;
              },
              true
          )
        : false;

    switch (item?.status) {
        case 0:
            return (
                (item?.sendAt && {
                    name: 'Scheduled delivery',
                    details: 'This case will be sent at a later date',
                    icon: 'paper-plane',
                    color: 'cyan',
                }) || {
                    name: 'Draft',
                    details: "You haven't finished creating this case",
                    icon: 'edit',
                    color: 'cyan',
                }
            );
        case 1:
            return {
                name: 'Pending',
                details: 'This case is pending signatures',
                icon: 'clock',
                color: 'yellow',
            };
        case 2:
            return {
                name: 'Rejected',
                details: 'A signer rejected to sign this case',
                icon: 'ban',
                color: 'red',
            };
        case 3:
            return {
                name: 'Deleted',
                details: 'This case was deleted',
                icon: 'times',
                color: 'red',
            };
        case 4:
            return {
                name: 'Signed',
                details: 'All signatures have been recorded',
                icon: 'check-circle',
                color: 'green',
            };
        case 5:
            if (isRegisteredLetter) {
                if (everyoneHasOpenedTheCasefile) {
                    return {
                        name: 'Completed',
                        details: 'Everyone has opened the case',
                        icon: 'check-circle',
                        color: 'green',
                    };
                } else {
                    return {
                        name: 'Pending',
                        details: 'Some recipients has not opened the case',
                        icon: 'clock',
                        color: 'yellow',
                    };
                }
            } else {
                return {
                    name: 'Completed',
                    details:
                        'This case has been processed by Penneo and is locked for editing',
                    icon: 'check-circle',
                    color: 'green',
                };
            }
        case 6:
            return {
                name: 'Failed',
                details: 'There was a problem processing this case',
                icon: 'crosshairs',
                color: 'red',
            };
        case 7:
            return {
                name: 'Expired',
                details:
                    'This case expired before all signatures were recorded',
                icon: 'times',
                color: 'red',
            };
        default:
            return {
                name: 'Unknown',
                details: 'There was a problem reading the status of this case',
                icon: 'question-circle',
                color: 'gray',
            };
    }
};

export const getDocumentStatus = (statusId: number): Status => {
    switch (statusId) {
        case 0:
            return {
                name: 'New',
                details:
                    "The document hasn't been delivered to the signer(s) yet",
                icon: 'star',
                color: 'cyan',
            };
        case 1:
            return {
                name: 'Pending',
                details: 'The document is pending signatures',
                icon: 'clock',
                color: 'yellow',
            };
        case 2:
            return {
                name: 'Rejected',
                details:
                    'The document is void. The signer(s) have rejected to sign',
                icon: 'ban',
                color: 'red',
            };
        case 3:
            return {
                name: 'Deleted',
                details: 'The document is void. It has been deleted',
                icon: 'times',
                color: 'red',
            };
        case 4:
            return {
                name: 'Signed',
                details:
                    'The document is being processed by Penneo. All signatures have been collected',
                icon: 'check-circle',
                color: 'green',
            };
        case 5:
            return {
                name: 'Completed',
                details:
                    'The document has been finalized and is locked for editing',
                icon: 'check-circle',
                color: 'green',
            };
        case 6:
            return {
                name: 'Quarantined',
                details: 'There was a problem processing this document',
                icon: 'crosshairs',
                color: 'red',
            };
        case 7:
            return {
                name: 'Failed',
                details: 'There was a problem processing this document',
                icon: 'times',
                color: 'red',
            };
        default:
            return {
                name: 'Unknown',
                details:
                    'There was a problem reading the status of this document. Check back shortly or contact support',
                icon: 'question-circle',
                color: 'gray',
            };
    }
};

export const getSigningRequestStatus = (statusId: number): Status => {
    switch (statusId) {
        case 0:
            return {
                name: 'New',
                details: "This signer hasn't received any documents yet",
                icon: 'star',
                color: 'cyan',
            };
        case 1:
            return {
                name: 'Pending',
                details: 'This signer still has documents to sign',
                icon: 'clock',
                color: 'yellow',
            };
        case 2:
            return {
                name: 'Rejected',
                details: 'This signer has rejected to sign this case',
                icon: 'ban',
                color: 'red',
            };
        case 3:
            return {
                name: 'Deleted',
                details: 'This signer was deleted',
                icon: 'times',
                color: 'red',
            };
        case 4:
            return {
                name: 'Signed',
                details:
                    'We have recorded all signatures for this signer. No more actions needed',
                icon: 'check-circle',
                color: 'green',
            };
        case 5:
            return {
                name: 'Undeliverable',
                details: 'We could not deliver the documents to this signer',
                icon: 'times',
                color: 'red',
            };
        default:
            return {
                name: 'Unknown',
                details:
                    'There was a problem reading the status. Check back shortly or contact support',
                icon: 'question-circle',
                color: 'gray',
            };
    }
};
