import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import Button from 'Common/components/Button';
import { i18n } from 'Language';
import {
    CustomerBranding,
    SigningCasefile,
    SigningDocument,
} from 'types/SigningProcess';
import { SigningMethods } from './SigningMethods';

import { CustomerSigningMethod } from 'types/Customer';
import './signing-footer.scss';
import { SigningRejectButton } from './SigningRejectButton';
import {
    SigningRequestSeenBySigner,
    useSigningMethods,
} from './useSigningMethods';

type Props = {
    currentDocId: number;
    caseFile: SigningCasefile;
    challengeKey: string;
    branding?: CustomerBranding;
    uploadedDocuments: SigningDocument[];
    allowedSigningMethods: CustomerSigningMethod[];
    isRegisteredLetter: boolean;
    signingRequest: SigningRequestSeenBySigner;
    changeDocument: (id: number) => void;
    encryptedNIN: string;
    mobile: boolean;
};

//TODO: Rewrite this into a modular footer that can be used for both signing, and browsing documents / registered letters...
// Do not put everything into ONE file, make it modular so a user can choose themselves what parts they want or need to use...
// Everything else becomes complicated and does not adhere to the rule of "composition" in React
export const SigningFooter = ({
    currentDocId,
    caseFile,
    challengeKey,
    branding,
    uploadedDocuments,
    allowedSigningMethods,
    isRegisteredLetter,
    signingRequest,
    changeDocument,
    encryptedNIN,
    mobile,
}: Props) => {
    const findDocIndex = useCallback(
        () => uploadedDocuments.findIndex((doc) => doc.id === currentDocId),
        [currentDocId, uploadedDocuments]
    );

    const [index, setIndex] = useState<number>(findDocIndex());
    const [openSigningDropdown, setOpenSigningDropdown] = useState<boolean>(
        false
    );

    const { signingMethods, handleMethodSelection } = useSigningMethods(
        allowedSigningMethods,
        challengeKey,
        caseFile.language,
        signingRequest,
        encryptedNIN
    );

    useEffect(() => {
        setIndex(findDocIndex());
    }, [currentDocId, findDocIndex]);

    const handleOnPrev = () => {
        changeDocument(uploadedDocuments[index - 1].id);
    };

    const handleOnNext = () => {
        changeDocument(uploadedDocuments[index + 1].id);
    };

    const handleOnContinue = () => {
        /**
         * If there's only one Signing Method available, select it right away.
         * Otherwise, open the dropdown with all methods
         */

        if (signingMethods.length > 1) {
            setOpenSigningDropdown(true);
        } else {
            handleMethodSelection(signingMethods[0]);
        }
    };

    const handleOnCloseMethods = () => {
        setOpenSigningDropdown(false);
    };

    const isFirst = index === 0;
    const isLast = index === uploadedDocuments.length - 1;

    /**
     * Translate 'next' and 'sign' buttons depending on user
     * being on mobile or not
     */
    const nextButtonLabel: string = mobile
        ? i18n`Next (${index + 1} of ${uploadedDocuments.length})`
        : i18n`Next document (${index + 1} of ${uploadedDocuments.length})`;

    const signButtonLabel: string = mobile
        ? i18n`Sign`
        : i18n(
              uploadedDocuments.length > 1 ? `Sign documents` : `Sign document`
          );

    const hasMultipleDocuments = uploadedDocuments.length > 1;

    return (
        <footer className="signing-footer">
            <div
                className={classnames('signing-footer-content', {
                    'has-multiple-documents': hasMultipleDocuments,
                })}
                data-testid="signing-buttons">
                {hasMultipleDocuments && (
                    <Button
                        className="prev"
                        variant="text"
                        theme="gray"
                        disabled={isFirst}
                        onClick={handleOnPrev}>{i18n`Back`}</Button>
                )}
                <div>
                    {!isRegisteredLetter && (
                        <SigningRejectButton
                            caseFile={caseFile}
                            branding={branding}
                            challengeKey={challengeKey}
                            mobile={mobile}
                        />
                    )}
                    {uploadedDocuments.length > 1 && !isLast ? (
                        <Button
                            variant="solid"
                            theme="green"
                            onClick={handleOnNext}>
                            {nextButtonLabel}
                        </Button>
                    ) : (
                        !isRegisteredLetter && (
                            <Button
                                variant="solid"
                                theme="green"
                                onClick={handleOnContinue}
                                data-testid="signing-button">
                                {signButtonLabel}
                            </Button>
                        )
                    )}

                    {openSigningDropdown && (
                        <SigningMethods
                            signingMethods={signingMethods}
                            handleMethodSelection={handleMethodSelection}
                            onClose={handleOnCloseMethods}
                            encryptedNIN={encryptedNIN}
                        />
                    )}
                </div>
            </div>
        </footer>
    );
};
