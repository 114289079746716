import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';

import Loader from 'Common/components/Common/Loader.jsx';
import Griddle from 'griddle-react';

import TeamStore from '../stores/TeamStore';
import TeamActions from '../actions/TeamActionCreators';

export default class TeamList extends React.Component {
    static propTypes = {
        children: PropTypes.object,
    };

    state = {
        teams: [],
        loading: false,
    };

    componentDidMount() {
        TeamStore.addChangeListener(this.onChange);

        this.loadData();
    }

    componentWillUnmount() {
        TeamStore.removeChangeListener(this.onChange);
    }

    loadData = () => {
        TeamActions.fetchTeams();
    };

    onChange = () => {
        this.setState({
            teams: TeamStore.getTeams(),
            loading: TeamStore.isFetching(),
        });
    };

    // @todo: This is only evaluated on first render.
    // On language change, column names are not updated.
    getColumnMetaData = () => {
        return [
            {
                columnName: 'Name',
                displayName: i18n`Name`,
            },
            {
                columnName: 'Description',
                displayName: i18n`Description`,
            },
            {
                columnName: 'Members',
                displayName: i18n`Members`,
            },
        ];
    };

    getTeams = () => {
        let { teams } = this.state;

        return teams.map((team) => {
            return {
                Name: team.name,
                Description: team.description,
                Members: team.members
                    .map((member) => member.fullName)
                    .join(', '),
            };
        });
    };

    render = () => {
        let { loading } = this.state;
        let data = this.getTeams();

        if (loading) {
            return <Loader />;
        }

        return (
            <div>
                {data.length > 0 ? (
                    <div className="penneo-auth">
                        <div className="content">
                            <Griddle
                                tableClassName="griddle-table"
                                results={data}
                                resultsPerPage={10}
                                showFilter={true}
                                noDataMessage={''}
                                useCustomFilterComponent={true}
                                initialSort={'Name'}
                                initialSortAscending={false}
                                customFilterComponent={CustomHeader()}
                                useGriddleStyles={false}
                                nextText={i18n`Next`}
                                previousText={i18n`Previous`}
                                columnMetadata={this.getColumnMetaData()}
                            />
                        </div>
                    </div>
                ) : (
                    <h2>There are no teams.</h2>
                )}
            </div>
        );
    };
}

let CustomHeader = () =>
    class extends React.Component {
        static propTypes = {
            changeFilter: PropTypes.func,
            currentResults: PropTypes.array,
        };

        searchChange = (event) => {
            this.props.changeFilter(event.target.value);
        };

        render() {
            let { currentResults } = this.props;

            return (
                <div className="container-header" style={{ paddingBottom: 0 }}>
                    <span className="search-bar">
                        <input
                            type="text"
                            style={{ width: 200 }}
                            onChange={this.searchChange}
                            placeholder={i18n`Type to filter`}
                        />
                    </span>

                    <span className="text-bold pl">
                        {`${currentResults.length} ` + i18n`Teams`}
                    </span>
                </div>
            );
        }
    };
