import PropTypes from 'prop-types';
import React from 'react';
import WorkflowStore from '../../stores/WorkflowStore';
import { i18n } from 'Language';

export default class DocumentFormCoSigner extends React.Component {
    static propTypes = {
        coSigner: PropTypes.bool,
    };

    static defaultProps = {
        coSigner: false,
    };

    updateCoSignerName = (event) => {
        let name = event.target.value;

        WorkflowStore.setCoSignerName(name);
    };

    updateCoSignerEmail = (event) => {
        let email = event.target.value;

        WorkflowStore.setCoSignerEmail(email);
    };

    render() {
        let { coSigner } = this.props;

        if (!coSigner) {
            return false;
        }

        return (
            <div className="form-cosigner">
                <div>
                    {/* <h4>Add another person as a co-signer</h4> */}
                    {/* <p>Co-signer's Name</p> */}
                    <h4>{i18n`Add an additional signatory`}</h4>
                    <label>{i18n`Name of additional signatory`}</label>
                    <input
                        type="text"
                        name="name"
                        className="match-parent"
                        // placeholder="Co-signer's Name"
                        placeholder={i18n`Co-signer's Name`}
                        onChange={this.updateCoSignerName}
                    />
                    {/* <p>Co-signer's Email</p> */}
                    <label className="mt">
                        {i18n`Email address of additional signatory`}
                    </label>
                    <input
                        type="email"
                        name="email"
                        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9_-]{2,63})+$"
                        title="Invalid Email Address"
                        className="match-parent"
                        // placeholder="Co-signer's Email"
                        placeholder={i18n`Email address of additional signatory`}
                        onChange={this.updateCoSignerEmail}
                    />
                </div>
            </div>
        );
    }
}
