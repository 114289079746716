import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';

export default class Validation extends React.Component {
    static propTypes = {
        rules: PropTypes.array,
        children: PropTypes.object.isRequired,
        value: PropTypes.string,
        validate: PropTypes.func,
        callback: PropTypes.func,
        displayError: PropTypes.bool,
        showHint: PropTypes.bool,
    };

    static defaultProps = {
        rules: [],
    };

    componentDidMount() {
        this.validate();
    }

    componentDidUpdate(newProps) {
        if (newProps.value !== this.props.value) {
            this.validate();
        }
    }

    getValidationText = () => {
        let { rules, value } = this.props;

        for (let i = 0; i < rules.length; i++) {
            let { rule, func, message, condition } = rules[i];

            if (condition === false) {
                return message;
            }

            if (func && !func(value)) {
                return message;
            }

            if (typeof value === 'undefined' || value === null) {
                return message;
            }

            if (rule && !rule.test(value)) {
                return message;
            }
        }

        return false;
    };

    validate = () => {
        let { rules, value, callback } = this.props;

        for (let i = 0; i < rules.length; i++) {
            let { rule, func, condition } = rules[i];

            if (condition === false) {
                return callback ? callback(false) : false;
            }

            // If the validation is a function, execute the function.
            // @todo: support multiple parameters for value comparison
            if (func && !func(value)) {
                return callback ? callback(false) : false;
            }

            // If value is undefined, skip RegExp validation, validating undefined variables
            // evaluates to true
            if (typeof value === 'undefined' || value === null) {
                return callback ? callback(false) : false;
            }

            // Execute RegExp test
            if (rule && !rule.test(value)) {
                return callback ? callback(false) : false;
            }
        }

        return callback ? callback(true) : true;
    };

    render() {
        let text = this.getValidationText();
        let hideError = this.props.displayError === false;
        let display = text && !hideError;

        return (
            <span className={display ? 'validation' : ''}>
                <span style={{ position: 'inherit', zIndex: 1 }}>
                    {this.props.children}
                </span>
                {display && (
                    <p
                        className="validation-message"
                        style={{ position: 'inherit', zIndex: 0 }}>
                        {i18n(text)}
                    </p>
                )}
            </span>
        );
    }
}
