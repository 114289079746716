import React from 'react';
import { i18n } from 'Language';
import TextInput from 'Common/components/TextInput';
import DateInput from 'Common/components/DateInput';
import { CaseFileEntity } from 'types/CaseFile';
import { CaseFileStatus } from '../Constants';
import HelpTooltip from 'Common/components/HelpTooltip';

import './casefile-eventlog.scss';

type Props = {
    casefile: CaseFileEntity;
    updateCasefile: Function;
};

class CasefileConfiguration extends React.Component<Props> {
    render() {
        const { casefile, updateCasefile } = this.props;

        return (
            <div>
                <h4 className="casefile-details-section-header small-margin-bottom">
                    {i18n`Configuration`}
                    <p className="casefile-details-section-subtitle">
                        {i18n`Most of these settings cannot be changed after the case file has been sent`}
                    </p>
                </h4>
                <div className="casefile-configuration">
                    <TextInput
                        className="casefile-details-input"
                        placeholder={i18n`Add a reference text`}
                        onChange={(value) =>
                            updateCasefile({ reference: value })
                        }
                        value={casefile.reference}
                        label={
                            <span>
                                {i18n`Casefile Reference`}
                                &nbsp;
                                <HelpTooltip
                                    width={300}
                                    text={
                                        i18n(
                                            `The reference can be used to identify the case file with an external ` +
                                                `piece of data that can later be used to cross-reference a case file ` +
                                                `with data from another system or to integrate to a process in your ` +
                                                `organization.`
                                        ) +
                                        i18n(
                                            `This doesn't affect the case file process and isn't shown in the ` +
                                                `documents or to the signers.`
                                        )
                                    }
                                />
                            </span>
                        }
                    />

                    <DateInput
                        className="casefile-details-input"
                        placeholder={i18n`Date to send casefile`}
                        onChange={(value) => updateCasefile({ sendAt: value })}
                        disabled={casefile.status > 0}
                        value={casefile.sendAt}
                        label={
                            <span>
                                <i className="far fa-calendar-check"></i>
                                &nbsp;
                                {i18n`Send at`}
                            </span>
                        }
                    />

                    <DateInput
                        className="casefile-details-input"
                        placeholder={i18n`Date of expiry`}
                        disabled={casefile.status !== CaseFileStatus.PENDING}
                        onChange={(value) =>
                            updateCasefile({ expireAt: value })
                        }
                        value={casefile.expireAt}
                        label={
                            <span>
                                <i className="far fa-calendar-times"></i>
                                &nbsp;
                                {i18n`Expire at`}
                            </span>
                        }
                    />
                </div>
            </div>
        );
    }
}

export default CasefileConfiguration;
