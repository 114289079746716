import React from 'react';
import { i18n } from 'Language';

import { connect } from 'react-redux';
import { ReduxState } from 'Store';

import DocumentStore from 'Casefiles/stores/DocumentStore';
import SignerStore from 'Casefiles/stores/SignerStore';
import {
    getSignerValidationErrorMessage,
    getDocumentValidationErrorMessage,
    parseEmailMessageValidationErrors,
} from 'Casefiles/utils/casefileValidation';
import {
    TypeError,
    CasefileValidation,
    DocumentValidation,
    SignerValidation,
    CasefileMessageValidation,
} from 'types/DataValidation';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { NewCasefileState } from 'Casefiles/redux/newCaseFile/types';

enum VALIDATION_TYPE {
    SIGNER = 'signerValidation',
    DOCUMENT = 'documentValidation',
}

type Props = {
    casefileValidation: CasefileValidation;
    documentValidation: DocumentValidation;
    signerValidation: SignerValidation;
    emailTemplatesValidation: CasefileMessageValidation;
    emailMessages: NewCasefileState['emailMessages'];
};

class CasefileErrorList extends React.Component<Props> {
    getDetailErrorMessages = () => {
        const { casefileValidation } = this.props;

        return Object.keys(casefileValidation.errors).map(
            (key: string) => casefileValidation.errors[key].message
        );
    };

    // TODO Remove once LD flag 'signer-specific-emails' is removed
    getMessageTemplatesErrorMessages = () => {
        const { emailTemplatesValidation } = this.props;
        let errorMessages: string[] = [];

        Object.keys(emailTemplatesValidation).forEach((templateType) => {
            const templateErrors =
                emailTemplatesValidation[templateType].errors;
            const templateErrorTypes = Object.keys(templateErrors);

            templateErrorTypes.forEach((errorType) => {
                if (
                    errorMessages.indexOf(templateErrors[errorType].message) ===
                    -1
                ) {
                    errorMessages.push(templateErrors[errorType].message);
                }
            });
        });

        return errorMessages;
    };

    getEmailMessagesValidationErrors = () => {
        const errorMessages = parseEmailMessageValidationErrors(
            this.props.emailMessages
        );

        return errorMessages;
    };

    getDocumentErrorMessages = () => {
        const { documentValidation } = this.props;

        return this.parseMessages(documentValidation);
    };

    getSignerErrorMessages = () => {
        const { signerValidation } = this.props;

        return this.parseMessages(signerValidation);
    };

    parseMessages = (
        validationObject: DocumentValidation | SignerValidation
    ) => {
        let errorMessages: string[] = [];

        Object.keys(validationObject).forEach((key: string) => {
            const validationParameter = validationObject[key];
            const isSetupKey = key === 'setup' ? true : false;
            const validationObjectType =
                key === 'signerTypes'
                    ? VALIDATION_TYPE.SIGNER
                    : VALIDATION_TYPE.DOCUMENT;

            if (validationParameter.valid) {
                return;
            }

            if (isSetupKey && validationParameter.errors) {
                const setupErrors = validationParameter.errors;

                if (setupErrors.count) {
                    errorMessages.push(setupErrors.count.message);
                }

                if (setupErrors.missingRoles) {
                    errorMessages.push(setupErrors.missingRoles.message);
                }
            }

            if (!isSetupKey && validationParameter.items) {
                Object.keys(validationParameter.items).forEach(
                    (key: string) => {
                        const itemId = key;
                        const item = validationParameter.items[key];

                        if (item.valid) {
                            return;
                        }

                        if (item.errors) {
                            Object.keys(item.errors).forEach((key: string) => {
                                // If error has a message use that message
                                if (
                                    item.errors[key] &&
                                    item.errors[key].message
                                ) {
                                    errorMessages.push(
                                        item.errors[key].message
                                    );
                                }

                                // If error has no message get the error code and map it to the right message
                                if (
                                    item.errors[key] &&
                                    !item.errors[key].message
                                ) {
                                    errorMessages.push(
                                        this.getDetailedTypeErrorMessage(
                                            validationObjectType,
                                            itemId,
                                            item.errors[key]
                                        )
                                    );
                                }
                            });
                        }
                    }
                );
            }
        });

        return errorMessages;
    };

    getDetailedTypeErrorMessage = (
        validationType: VALIDATION_TYPE.DOCUMENT | VALIDATION_TYPE.SIGNER,
        itemId: string,
        { code, count, required }: TypeError
    ) => {
        if (validationType === VALIDATION_TYPE.SIGNER) {
            const type = SignerStore.getSignerRole(Number(itemId));

            return getSignerValidationErrorMessage(required, count, type, code);
        }

        if (validationType === VALIDATION_TYPE.DOCUMENT) {
            const type = DocumentStore.getDocumentType(itemId);

            return getDocumentValidationErrorMessage(
                required,
                count,
                type,
                code
            );
        }
    };

    getErrorMessages = () => [
        ...this.getDetailErrorMessages(),
        ...this.getDocumentErrorMessages(),
        ...this.getSignerErrorMessages(),
        ...(LaunchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS)
            ? this.getEmailMessagesValidationErrors()
            : this.getMessageTemplatesErrorMessages()),
    ];

    renderMessage(message: string | JSX.Element): string | JSX.Element {
        return typeof message === 'string' ? i18n`${message}` : message;
    }

    render() {
        return (
            <ul className="validation-errors-list">
                {this.getErrorMessages()
                    .filter((message) => !!message)
                    .map((message: string, key: number) => (
                        <li className="validation-errors-list-item" key={key}>
                            <i className="fas fa-exclamation-triangle" />
                            <span>{this.renderMessage(message)}</span>
                        </li>
                    ))}
            </ul>
        );
    }
}

export default connect((state: ReduxState) => ({
    emailMessages: state.newCasefile.emailMessages,
    emailTemplatesValidation: state.newCasefile.validation.emailTemplates,
}))(CasefileErrorList);
