import React from 'react';
import Select, { ActionMeta, MultiValue } from 'react-select';
import { i18n } from 'Language';
import './autocomplete-tags.scss';

export type AutocompleteEntry = {
    label: string;
    value: string;
};

type Props = {
    ariaDescription?: string;
    className?: string;
    id: string;
    name: string;
    onChange: (action: ActionMeta<AutocompleteEntry>) => void;
    suggestions: { value: string; label: string }[];
    value: { value: string; label: string }[];
};

//TODO: This is really a good component but super tied up to the RecipientEmails per signer - so perhaps we can do a "new one" where these special cases are handled
export function AutocompleteTags({
    className,
    id,
    name,
    onChange,
    suggestions,
    value,
    ariaDescription,
}: Props) {
    const onChangeHandler = (
        _: MultiValue<AutocompleteEntry>,
        action: ActionMeta<AutocompleteEntry>
    ) => {
        onChange(action);
    };

    return (
        <>
            {ariaDescription && (
                <p
                    id={`id-recipient-text-${id}`}
                    className="sr-only"
                    aria-hidden="true">
                    {i18n`Select custom message recipients`}
                </p>
            )}
            <Select
                closeMenuOnSelect={false}
                inputId={id}
                isMulti
                name={name}
                noOptionsMessage={() => i18n('No options')}
                onChange={onChangeHandler}
                options={suggestions}
                placeholder={`${i18n('Select')}...`}
                value={value}
                className={className}
                {...(ariaDescription && {
                    'aria-labelledby': `id-recipient-text-${id}`,
                })}
                classNamePrefix={'autocomplete-tags'}
                unstyled
            />
        </>
    );
}
