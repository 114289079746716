import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

export default class NewsFeedItem extends React.Component {
    static propTypes = {
        post: PropTypes.object.isRequired,
    };

    render() {
        let { post } = this.props;

        return (
            <div className="news-feed-item">
                <h3>
                    {post.new && <span className="new-tag">New</span>}
                    {post.title}
                </h3>
                {post.thumbnail && <img src={post.thumbnail} />}

                <p>{post.excerpt}</p>

                <div className="news-feed-item-footer">
                    <a
                        href={post.url}
                        target="_blank"
                        rel="noopener noreferrer">
                        Read more
                        <i className="fa fa-external-link" />
                    </a>

                    <div className="post-date">
                        <i className="far fa-clock"></i>&nbsp;
                        {moment.utc(post.date_published).local().fromNow()}
                        {/* moment.unix(post.date_published).utc().fromNow()*/}
                    </div>
                </div>
            </div>
        );
    }
}
