import React from 'react';
import Dropzone from 'react-dropzone';
import { notify } from 'react-notify-toast';
import { i18n } from 'Language';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchActions from 'Auth/actions/TouchActions';
import Button from 'Common/components/Button';
import downsize from './downsizer';

type Props = {
    onChange: (image: string | null) => void;
};

type State = {
    file?: File;
    imageUrl?: string;
};

export default DragDropContext(HTML5Backend)(
    class ImageUpload extends React.Component<Props, State> {
        state: State = {};
        fileUpload: HTMLInputElement | null = null;

        static MAX_UPLOAD_SIZE_MB = 10;
        static MIN_IMAGE_SIZE_FOR_DOWNSIZING_BYTES = 250 * 1024;

        componentDidMount() {
            TouchActions.loadWidget('image');

            this.props.onChange(null);
        }

        _onButtonHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.target.files) {
                throw new Error('No file found!');
            }

            this._loadFile(event.target.files[0]);
        };

        // Uploading a document by dragging and dropping
        _onDropHandler = (files: Array<File>) => {
            if (!files.length) {
                console.log('Error, not a valid image input');

                notify.show(
                    i18n`An error occurred while loading the image`,
                    'error',
                    5000
                );

                return;
            }

            this._loadFile(files?.[0]);
        };

        _loadFile = (file: File) => {
            if (!file) {
                return;
            }

            if (file.size > ImageUpload.MAX_UPLOAD_SIZE_MB * 1024 * 1024) {
                notify.show(
                    i18n`Please use an image smaller than` +
                        ` ${ImageUpload.MAX_UPLOAD_SIZE_MB} MB`,
                    'error',
                    5000
                );

                return;
            }

            if (file.size > ImageUpload.MIN_IMAGE_SIZE_FOR_DOWNSIZING_BYTES) {
                this.loadDownsizedImage(file).catch(() => this.loadImage(file));
            } else {
                this.loadImage(file);
            }
        };

        imageChanged(base64image: string): void {
            if (base64image.startsWith('data:image')) {
                // ensure that the image does not contain a prefix like
                // `data:image/jpeg;base64,` because `onChange` expects a plain
                // base64 encoded string without any prefixes.
                base64image = base64image.split(',')[1];
            }

            this.props.onChange(base64image);

            if (this.state.imageUrl) {
                URL.revokeObjectURL(this.state.imageUrl);
            }
        }

        loadImage(file: File): void {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (!reader.result) {
                    throw new Error('Could not read file!');
                }

                this.imageChanged(reader.result.toString());
                this.setState({
                    imageUrl: URL.createObjectURL(file),
                    file,
                });
            };

            reader.readAsDataURL(file);
        }

        async loadDownsizedImage(file: File): Promise<void> {
            const base64Image = await downsize(file);
            const blob = await this.convertImageToBlob(base64Image);

            this.imageChanged(base64Image);
            this.setState({
                imageUrl: URL.createObjectURL(blob),
                file,
            });
        }

        convertImageToBlob = async (base64image: string): Promise<Blob> => {
            const res = await fetch(base64image);

            return res.blob();
        };

        _clear = (): void => {
            if (this.state.imageUrl) {
                URL.revokeObjectURL(this.state.imageUrl);
            }

            this.setState({
                file: undefined,
                imageUrl: undefined,
            });

            this.props.onChange(null);
        };

        render(): JSX.Element {
            const { file, imageUrl } = this.state;

            return (
                <div
                    className="image-upload form-v2"
                    data-testid="components/VisualSignature/ImageUpload">
                    <label>{i18n`Upload an image of your handwritten signature`}</label>
                    <label>{i18n`For the best result please crop your image before uploading`}</label>

                    <Dropzone
                        className="image-upload-dropzone"
                        disablePreview={true}
                        onDrop={this._onDropHandler}
                        disableClick={true}
                        accept="image/png,image/jpeg">
                        <input
                            type="file"
                            ref={(ref) => (this.fileUpload = ref)}
                            name="file"
                            className="file"
                            data-testid="components/VisualSignature/ImageUpload/input"
                            style={{ display: 'none' }}
                            accept="image/x-png,image/jpeg"
                            onChange={this._onButtonHandler}
                        />

                        {imageUrl && file && (
                            <div className="image-upload-image">
                                <div className="signature-image">
                                    <img
                                        /* Make the image non-draggable in a dropzone */
                                        style={{ pointerEvents: 'none' }}
                                        src={imageUrl}
                                        alt={'signature'}
                                    />
                                    <p className="text-small">{file?.name}</p>
                                </div>

                                <Button
                                    theme="gray"
                                    onClick={() => this.fileUpload?.click()}
                                    icon="far fa-retweet-alt">
                                    {i18n`Replace`}
                                </Button>
                                <Button
                                    theme="gray"
                                    onClick={this._clear}
                                    icon="far fa-times-circle">
                                    {i18n`Remove`}
                                </Button>
                            </div>
                        )}

                        {!file && (
                            <div className="image-upload-no-image">
                                <p>{i18n`Drag and drop image here`}</p>
                                <p>- {i18n`or`} -</p>
                                <span
                                    className="underline-link"
                                    onClick={() => this.fileUpload?.click()}>
                                    <i className="far fa-folder-open" />
                                    &nbsp;
                                    {i18n`Select image of your signature`}
                                </span>

                                <div className="text-small">
                                    <br />
                                    <br />
                                    <i className="fa fa-question-circle"></i>
                                    &nbsp;
                                    <span></span>
                                    {`${i18n`.png, .jpg are supported`} - (${i18n`Max image upload size:`} ${
                                        ImageUpload.MAX_UPLOAD_SIZE_MB
                                    } MB)`}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>
            );
        }
    }
);
