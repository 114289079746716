import { Dispatcher, BaseStore } from 'Core';
import assign from 'object-assign';

let _credentials = {};
let _currentUserCredentials = null;

function storeUserCredentials(userId, credentials) {
    _credentials[userId] = credentials;
}

function storeCurrentUserCredentials(credentials) {
    _currentUserCredentials = credentials;
}

// Flux Store Creation
const CredentialStore = assign({}, BaseStore, {
    getUserCredentials(userId) {
        return _credentials[userId];
    },

    getCurrentUserCredentials() {
        return _currentUserCredentials || { allowed: [] };
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case 'USER_CREDENTIALS_LOADED':
                storeUserCredentials(action.userId, action.credentials);
                break;
            case 'USER_CREDENTIALS_UPDATED':
                break;
            case 'CURRENT_USER_CREDENTIALS_LOADED':
                storeCurrentUserCredentials(action.credentials);
                break;
            default:
                return false;
        }
        CredentialStore.emit(action.type);
        CredentialStore.emitChange();
    }),
});

export default CredentialStore;
