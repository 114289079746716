import React from 'react';
import PropTypes from 'prop-types';
import Constants from 'Constants';
import { notify } from 'react-notify-toast';
import { i18n } from 'Language';
import Loading from 'Common/components/Loaders/LoadingData';
import { modal } from 'Common/components/Common/Modal';
import { Link } from 'react-router';
import moment from 'moment';
import { Helmet } from 'react-helmet';

// Redux
import { connect } from 'react-redux';
import {
    fetchCasefile,
    fetchCasefileEventLog,
    updateCasefile,
    persistCasefile,
    reactivateSigner,
    fetchCasefileAttachments,
    fetchCasefileFolder,
    resetCasefileFolder,
} from './redux/actions';
import {
    CasefileState,
    SignerState,
    EventLogState,
    CasefileAttachmentsState,
    FolderState,
} from './redux/types';

// Components
import CasefileRecipients from './CasefileRecipients';
import CasefileDocuments from './CasefileDocuments';
import CasefileAttachmentsSection from './CasefileAttachmentsSection';
import CasefileEventLog from './CasefileEventLog';
import CasefileConfiguration from './CasefileConfiguration';
import CasefilePrivacy from './CasefilePrivacy';
import TitleModal from './TitleModal';
import ExtendDeadline from './ExtendDeadline';

import {
    archiveInitialBreadcrumb,
    BreadcrumbsBase,
    getFolderPredecessors,
    updateBreadcrumbsAction,
    addBreadcrumbsAction,
    deleteBreadcrumbs,
} from '../Archive/redux/reducer';

import './casefile-details.scss';
import { ReduxState } from 'Store';
import { images } from 'Constants';
import StatusBanner from './StatusBanner';
import { getCasefileStatus } from './statusCodes';
import Button from 'Common/components/Button';
import { AuthStore } from 'Auth';
import { fetchCustomer } from 'Auth/redux/customer/actions';
import CustomerStore from 'Auth/stores/CustomerStore';
import { CustomerEntity } from 'types/Customer';
import { ReactRouter } from 'types/Router';
import { sentry } from 'Common/SentryLogger';

type Props = {
    casefileId: number;
    params: {
        casefileId: string;
        documentId?: string;
        signerId?: string;
    };
    router: ReactRouter;
    modal: JSX.Element;
    caseFile: CasefileState;
    eventLog: EventLogState;
    signers: SignerState;
    folder: FolderState;
    attachments: CasefileAttachmentsState;
    dispatch: any;
    breadcrumbs: BreadcrumbsBase[];
};

type StateDef = {
    hasBreadcrumbs: boolean;
    loadedBreadcrumbs: boolean;
    hasError: boolean;
};

class CasefileDetails extends React.Component<Props, StateDef> {
    user = AuthStore.getUser();

    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            hasBreadcrumbs: false,
            loadedBreadcrumbs: false,
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        error.name = '[componentDidCatch] CasefileDetails';
        sentry.track(error, { ...errorInfo, ...this.props });
    }

    componentDidMount() {
        const { dispatch, breadcrumbs } = this.props;
        const hasBreadcrumbs = breadcrumbs?.length;

        this.setState({ hasBreadcrumbs: !!hasBreadcrumbs }, () => {
            // This scenario only will happen when we don't come from the Archive
            !hasBreadcrumbs &&
                dispatch(addBreadcrumbsAction(archiveInitialBreadcrumb()));
            this.loadData();
        });
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(deleteBreadcrumbs());
        dispatch(resetCasefileFolder());
    }

    componentDidUpdate() {
        const { loadedBreadcrumbs, hasBreadcrumbs } = this.state;
        const {
            dispatch,
            caseFile,
            casefileId,
            breadcrumbs,
            folder,
            router,
            params,
        } = this.props;
        const { isOwner } = caseFile;

        /**
         * Once all casefile info is available in Redux
         * we prevent the Recipient info modal to appear
         * if the user doesn't own the casefile.
         * We also redirect to the original path
         * to keep the routing consistent
         */
        if (caseFile.isLoaded && !isOwner && params.signerId) {
            router.push({
                name: 'archive-casefile-details',
                params: {
                    casefileId,
                },
            });

            return;
        }

        /**
         * We make sure we have the casefile info and that we haven't set up breadcrumbs yet.
         * We must check as well that the casefile in Redux matches the current casefile
         */
        if (
            !loadedBreadcrumbs &&
            caseFile.isLoaded &&
            caseFile.data.id === casefileId
        ) {
            const breadcrumb = {
                title: caseFile.data.title,
                route: {
                    name: 'archive-casefile-details',
                    params: {
                        casefileId: caseFile.data.id,
                    },
                },
            };

            /**
             * We need to do this check to include the route to our current casefile if not present
             * (whether when coming from the Archive or when directly loading the case details url)
             */
            if (
                !breadcrumbs.some(
                    (crumb) =>
                        crumb.route?.params?.casefileId ===
                        breadcrumb.route.params.casefileId
                )
            ) {
                dispatch(addBreadcrumbsAction(breadcrumb));
            }

            // If we don't come from the Archive (direct link), we need to fetch the breadcrumbs from an endpoint
            if (!hasBreadcrumbs) {
                /**
                 * If it's a shared casefile (was created by a different user but it's contained in a folder shared with us),
                 * we do not have access to get the containing folder predecessors.
                 * In that case, we can only preppend the 'Shared' tab to the casefile route
                 */
                const isSharedCasefile = caseFile.data.userId !== this.user.id;

                if (isSharedCasefile) {
                    this.setState({ loadedBreadcrumbs: true }, () =>
                        dispatch(
                            updateBreadcrumbsAction([
                                archiveInitialBreadcrumb(true),
                                breadcrumb,
                            ])
                        )
                    );

                    return;
                }

                /**
                 * If the endpoint to get the containing folder info is not fetched yet
                 * or if it failed, we just keep the current breadcrumbs
                 */
                if (!folder?.data?.id) {
                    return;
                }

                this.setState({ loadedBreadcrumbs: true }, async () => {
                    const previousBreadcrumbs = await this.getFolderRoute(
                        folder.data.id
                    );

                    const isSharedFolder = folder.data.userId !== this.user.id;

                    dispatch(
                        updateBreadcrumbsAction([
                            archiveInitialBreadcrumb(isSharedFolder),
                            ...previousBreadcrumbs,
                            breadcrumb,
                        ])
                    );
                });

                return;
            }

            this.setState({ loadedBreadcrumbs: true });
        }
    }

    loadData = async () => {
        const { dispatch, casefileId } = this.props;
        const { hasBreadcrumbs } = this.state;

        const customer = CustomerStore.getCustomer(
            AuthStore.getAuthDetails().cid
        ) as CustomerEntity;

        const promises = [
            dispatch(fetchCasefile(casefileId)),
            dispatch(fetchCasefileEventLog(casefileId)),
            // Fetch customer data (on Redux)
            dispatch(fetchCustomer(customer.id)),
        ];

        // When we aren't coming from the Archive, we need to fetch the casefile folder info
        if (!hasBreadcrumbs) {
            promises.push(dispatch(fetchCasefileFolder(casefileId)));
        }

        await Promise.all(promises);
        await dispatch(fetchCasefileAttachments(this.props.caseFile.data));
    };

    getFolderRoute = async (folderId: number) => {
        const { dispatch } = this.props;
        let previousBreadcrumbs = [];

        try {
            const predecessors = await dispatch(
                getFolderPredecessors(folderId)
            );

            if (!predecessors?.length) {
                return previousBreadcrumbs;
            }

            previousBreadcrumbs = predecessors.map((folder) => ({
                title: folder.title,
                route: {
                    name: 'archive-folder',
                    params: {
                        folderId: folder.id,
                    },
                },
            }));
        } catch (error) {
            console.error(error);
        }

        return previousBreadcrumbs;
    };

    formatDate(date: any) {
        return moment.unix(date).format('DD/MM/YYYY - HH:mm');
    }

    updateCasefile = (data: any) => {
        const { dispatch } = this.props;

        dispatch(updateCasefile(data));
    };

    persistChanges = async () => {
        const { dispatch } = this.props;

        try {
            await dispatch(persistCasefile());
            notify.show(
                <span>
                    {i18n('Case updated successfully')}&nbsp;
                    <i className="far fa-check"></i>
                </span>,
                'success',
                5000
            );
        } catch (e) {
            notify.show(
                <span>
                    {i18n('Could not update case file')}&nbsp;
                    <i className="far fa-times"></i>
                </span>,
                'error',
                5000
            );
        }
    };

    handleOnEditItemTitle = () => {
        modal.show({
            title: i18n('Edit title'),
            body: (
                <TitleModal
                    inputLabel={i18n('Edit title')}
                    closeModal={modal.hide}
                    item={this.props.caseFile.data}
                    saveChange={(data: any) => {
                        this.updateCasefile(data);
                        this.persistChanges();
                    }}
                />
            ),
        });
    };

    renderError(error) {
        if (error.status === 403) {
            return (
                <div className="casefile-details ui-v2">
                    <div className="casefile-error text-center">
                        <h2 className="code">[Code: 403]</h2>
                        <img
                            src={`${images}/graphics/security.png`}
                            height="300"
                            alt=""
                        />
                        <h1>{i18n`You don't have access to this case`}</h1>

                        <Button
                            theme="blue"
                            onClick={() => this.props.router.goBack()}>
                            {i18n`Back to archive`}
                        </Button>
                    </div>
                </div>
            );
        }

        return (
            <div className="casefile-details ui-v2">
                <div className="casefile-error text-center">
                    <img
                        src={`${images}/graphics/automated.png`}
                        height="300"
                        alt=""
                    />
                    <h1>{i18n`An unexpected error occurred`}</h1>

                    <Button
                        theme="blue"
                        onClick={() => window.location.reload()}>
                        {i18n`Reload page`}
                    </Button>
                </div>
            </div>
        );
    }

    handleBackButton = () => {
        const { breadcrumbs } = this.props;
        const path = breadcrumbs[breadcrumbs.length - 2];

        this.props.router.push(path.route);
    };

    isExpired = (status) => {
        const { expireAt } = this.props.caseFile.data;

        if (status.name === 'Expired') {
            return true;
        }

        return moment.unix(expireAt).isBefore(moment());
    };

    onExtension = async () => {
        await this.reactivateSigners();
        await this.loadData();
    };

    reactivateSigners = async () => {
        const { caseFile, dispatch, casefileId } = this.props;
        const { signers } = caseFile.data;
        const promises = signers
            .filter((signer) => signer.signingRequest.status === 2)
            .map(
                async (signer) =>
                    await dispatch(reactivateSigner(casefileId, signer.id))
            );

        return Promise.all(promises);
    };

    renderAttachmentsSection = () => {
        const { caseFile, attachments } = this.props;

        if (attachments.data.length) {
            const [firstSigner] = caseFile.data.signers;
            const files = attachments.data;

            return (
                <CasefileAttachmentsSection
                    signer={firstSigner}
                    files={files}
                />
            );
        }
    };

    render() {
        const {
            eventLog,
            caseFile,
            modal,
            attachments,
            casefileId,
            params,
        } = this.props;
        const { isOwner } = caseFile;

        if (this.state.hasError || caseFile.error) {
            return this.renderError(caseFile.error || {});
        }

        if (!caseFile.isLoaded || !attachments.isLoaded) {
            return (
                <div className="casefile-details ui-v2">
                    <Loading />
                </div>
            );
        }

        const status = getCasefileStatus(caseFile.data, eventLog);
        const isExpired = this.isExpired(status);
        const isRejected = status.name === 'Rejected';

        return (
            <div className="casefile-details ui-v2">
                <Helmet>
                    <title>{caseFile.data.title}</title>
                </Helmet>

                <Link className="back-link" onClick={this.handleBackButton}>
                    <i className="fas fa-arrow-left" />
                    <div className="back-button-text">&nbsp;{i18n`Back`}</div>
                </Link>

                <a
                    className={`pull-right no-underline`}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${Constants.PENNEO_ORIGIN}/casefile/${casefileId}/downloadFiles`}>
                    <Button
                        theme="blue"
                        variant="outline"
                        icon="far fa-arrow-alt-to-bottom"
                        renderIconLeft={true}>
                        {i18n`Download`}
                    </Button>
                </a>
                {modal && (isOwner || !params.signerId) && (
                    <div className="casefile-details-modal">{modal}</div>
                )}

                {caseFile.data.caseFileType && (
                    <div className="casefile-signing-flow text-blue">
                        <i className="far fa-tag"></i>&nbsp;
                        {caseFile.data.caseFileType.name}
                    </div>
                )}

                <h1 className="casefile-details-title">
                    {caseFile.data.title}&nbsp;
                    {isOwner && (
                        <span
                            className="item-inline-action"
                            onClick={this.handleOnEditItemTitle}>
                            <i className="far fa-pencil-alt"></i>
                        </span>
                    )}
                </h1>

                <StatusBanner status={status} />

                <span className="casefile-details-subtitle">
                    {caseFile.data.created &&
                        i18n`Created on ${this.formatDate(
                            caseFile.data.created
                        )}`}
                    {caseFile.data.completed && (
                        <span>
                            &nbsp;|&nbsp;
                            {i18n`Completed on ${this.formatDate(
                                caseFile.data.completed
                            )}`}
                        </span>
                    )}{' '}
                    | ID: {caseFile.data.id}
                </span>

                {isOwner && (
                    <>
                        {isExpired && isRejected && (
                            <>
                                <p className="casefile-details-info-note">
                                    <i className="fas fa-exclamation-triangle" />
                                    &nbsp;
                                    {i18n`The status of the rejected case file can be reverted by extending the deadline in section below`}
                                </p>

                                <ExtendDeadline
                                    onExtension={this.onExtension}
                                />
                            </>
                        )}
                        <CasefileRecipients
                            casefile={caseFile.data}
                            casefileId={casefileId}
                            eventLog={eventLog}
                        />
                    </>
                )}

                <CasefileDocuments
                    casefile={caseFile.data}
                    documents={caseFile.data.documents}
                />

                {isOwner && (
                    <>
                        {this.renderAttachmentsSection()}
                        <CasefileEventLog
                            events={eventLog}
                            casefileId={casefileId}
                        />
                        <CasefileConfiguration
                            casefile={caseFile.data}
                            updateCasefile={this.updateCasefile}
                        />
                        <CasefilePrivacy casefile={caseFile.data} />

                        <br />
                        <br />

                        <div className="pull-right-flex">
                            <Button
                                theme="blue"
                                onClick={this.persistChanges}
                                icon="far fa-check"
                                disabled={
                                    !caseFile.hasChanged || caseFile.isFetching
                                }
                                renderIconLeft={true}>
                                {i18n`Save changes`}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default connect((state: ReduxState, props: Props) => ({
    caseFile: state.caseFileDetails.casefile,
    eventLog: state.caseFileDetails.eventLog,
    signers: state.caseFileDetails.signers,
    folder: state.caseFileDetails.folder,
    attachments: state.caseFileDetails.attachments,
    breadcrumbs: state.archive.breadcrumbs,
    casefileId: Number(props.params.casefileId),
}))(CasefileDetails);
