import React, { useEffect, useReducer } from 'react';
import uniqid from 'uniqid';
import { i18n } from 'Language';
import Select, { components, GroupProps, SingleValue } from 'react-select';
import { parsePhoneNumber } from './utils';
import { storage } from 'Core';
import countries, {
    CountryCode,
    PhoneNumber,
    getCountryByCode,
    getCountryCallingCode,
} from '../../../utils/countries';
import { Country } from '../../../types/Country';

export type Props = {
    value: string | undefined;
    onChange: Function;
    label?: string;
    placeholder?: string;
    inputClass?: string;
    disabled?: boolean;
    required?: boolean;
};

const reducer = (state: any, action: { type: any; payload: any }) => {
    switch (action.type) {
        case 'setPhoneNumber':
            return { ...state, phoneNumber: action.payload };
        case 'setCountryCode':
            return { ...state, countryCode: action.payload };
        case 'setIsValid':
            return { ...state, isValid: action.payload };
        case 'setSelectedCountryCode':
            return { ...state, selectedCountryCode: action.payload };
        default:
            return state;
    }
};

export default function SmsInput({
    value,
    onChange,
    placeholder,
    disabled,
    inputClass,
    required,
}: Props): JSX.Element {
    const fieldId = uniqid();

    const initialState = {
        phoneNumber: parsePhoneNumber(value)?.nationalNumber,
        countryCode: parsePhoneNumber(value)?.country,
        isValid: true,
        selectedCountryCode: null,
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handlePhoneNumberChange = async ({
        target,
    }: React.ChangeEvent<HTMLInputElement>) => {
        if (!state.countryCode) {
            dispatch({
                type: 'setIsValid',
                payload: false,
            });

            return;
        }

        const callingCode = getCountryCallingCode(state.countryCode);
        const number = `+${callingCode}${target.value}`;

        const validated = validatePhoneNumber(number);

        dispatch({
            type: 'setPhoneNumber',
            payload: validated?.nationalNumber ?? target.value,
        });

        if (validated) {
            target.value = validated.nationalNumber;
        }

        onChange(validated?.number);
    };

    const handleCountryCodeChange = (
        newValue: SingleValue<CountryOption>
    ): void => {
        if (!newValue) {
            return;
        }

        dispatch({ type: 'setCountryCode', payload: newValue.value });
        dispatch({ type: 'setSelectedCountryCode', payload: newValue.value });
        updateRecentlyUsedCountries(newValue);

        const callingCode = getCountryCallingCode(newValue.value);
        const number = `+${callingCode}${state.phoneNumber}`;

        onChange(validatePhoneNumber(number)?.number);
    };

    const validatePhoneNumber = (input: string): PhoneNumber | undefined => {
        const parsed = parsePhoneNumber(input);

        dispatch({
            type: 'setIsValid',
            payload: parsed !== null,
        });

        return parsed ?? undefined;
    };

    type CountryOption = {
        readonly value: CountryCode;
        readonly label: string;
    };

    const countryOptions: CountryOption[] = countries.map(
        ({ code, flag, name }: Country) => ({
            value: code,
            label: `${flag} ${name} (+${getCountryCallingCode(code)})`,
        })
    );

    const updateRecentlyUsedCountries = (
        selected: SingleValue<CountryOption>
    ): void => {
        if (selected) {
            const updatedRecentlyUsedCountries = [
                selected.value,
                ...getRecentlyUsedCountryOptions()
                    .map((co) => co.value)
                    .filter((c) => c !== selected.value),
            ].slice(0, 5);

            storage.set(
                'recentlyUsedCountriesForPhoneNumbers',
                JSON.stringify(updatedRecentlyUsedCountries)
            );
        }
    };

    const getRecentlyUsedCountryOptions = (): CountryOption[] => {
        const stored = JSON.parse(
            storage.get('recentlyUsedCountriesForPhoneNumbers')
        );

        if (!Array.isArray(stored)) {
            return [];
        }

        return stored
            .map((x) => getCountryOptionForCode(x))
            .filter((x): x is CountryOption => typeof x !== undefined);
    };

    const groupedOptions = [
        {
            label: i18n`Recently used`,
            options: getRecentlyUsedCountryOptions(),
        },
        {
            label: i18n`All`,
            options: countryOptions,
        },
    ];

    function getCountryOptionForCode(
        countryCode: CountryCode
    ): CountryOption | undefined {
        if (!countryCode) {
            return undefined;
        }

        try {
            const country = getCountryByCode(countryCode);
            const callingCode = getCountryCallingCode(countryCode);

            return {
                value: countryCode,
                label: `${country?.flag} ${country?.name} (+${callingCode})`,
            };
        } catch (error) {
            console.error(error.message);

            return undefined;
        }
    }

    useEffect(() => {
        dispatch({
            type: 'setSelectedCountryCode',
            payload: state.countryCode,
        });
    }, [state.countryCode]);

    const Group = (props: GroupProps<CountryOption, false>): JSX.Element => (
        <components.Group {...props} />
    );

    const { isValid, phoneNumber, countryCode } = state;

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <Select<CountryOption>
                    data-testid="sms-country-select"
                    styles={{
                        container: (styles) => ({
                            ...styles,
                            width: '100%',
                            maxWidth: '200px',
                            paddingRight: '0.5rem',
                        }),
                    }}
                    placeholder={`-- ${i18n('Country')} --`}
                    name="country_code"
                    id={`country-code-${fieldId}`}
                    onChange={handleCountryCodeChange}
                    isDisabled={disabled}
                    components={{ Group }}
                    options={groupedOptions}
                    value={getCountryOptionForCode(state.selectedCountryCode)}
                />

                <input
                    data-testid="sms-phone-number-input"
                    type="text"
                    id={`sms-${fieldId}`}
                    value={state.phoneNumber}
                    placeholder={placeholder}
                    disabled={disabled}
                    className={inputClass}
                    style={{ width: '200px' }}
                    autoComplete="off"
                    required={required}
                    onChange={handlePhoneNumberChange}
                />
            </div>

            {!isValid && (!!phoneNumber?.length || !countryCode?.length) && (
                <div className="input-validation">
                    <span className="input-validation-message">
                        {i18n`Phone number is not valid`}
                    </span>
                </div>
            )}
        </div>
    );
}
