import { Dispatcher, BaseStore } from 'Core';
import Constants from '../Constants';
import assign from 'object-assign';
import lodash from 'lodash';

import URIActions from '../actions/URIActionCreators';

import CasefileStore from './CasefileStore';
import DocumentStore from './DocumentStore';
import SignerStore from './SignerStore';
import MessageStore from './MessageTemplateStore';
import { SSNs } from 'Constants';

// This is used to compare integration codes so that duplicates are not added, if the same
// integration code is added more than once.
let _integrationCode = {};
// This integration code is used for actual population of the system.
let _JSONIntegrationCode = {};

let _casefileTemplates = {
    templates: null,
    fetching: false,
    success: false,
    deleting: false,
};

function setCasefileTemplates(casefileTemplates) {
    _casefileTemplates.templates = casefileTemplates;
}

function formatTemplate(templateName, template) {
    return { name: templateName, data: template };
}

function createNewTemplate(templateName) {
    generateIntegrationCode();

    return formatTemplate(templateName, _integrationCode);
}

function deleteCasefileTemplate(templateId) {
    delete _casefileTemplates[templateId];
}

function generateIntegrationCode() {
    let casefile = CasefileStore.getCasefile();
    let docs = DocumentStore.getDocuments();
    let documents = lodash.cloneDeep(docs);
    let signers = SignerStore.getSigners();
    let selectedFolderId = CasefileStore.getSelectedFolderId();
    let recipients = CasefileStore.getRecipients();
    let messageTemplate = MessageStore.getMessageTemplate();
    let sendAtDate = CasefileStore.getSendAtDate();
    let expireAtDate = CasefileStore.getExpireAtDate();
    let sensitiveData = CasefileStore.getSensitiveDataFlag();

    let uriDocuments = [];
    let uriSigners = [];
    let uriCopyRecipients = [];

    let jsonObject = {};

    if (documents) {
        documents.forEach((doc) => {
            uriDocuments.push({
                name: doc.name, // string,
                order: doc.order, // int
                typeId: doc.documentTypeId, // int
                localPath: doc.localPath, // string
            });
            delete doc.file;
            delete doc.base64File;
        });
    }

    if (recipients) {
        recipients.forEach((recipient) => {
            if (!lodash.isEmpty(recipient)) {
                uriCopyRecipients.push({
                    name: recipient.name, // string
                    email: recipient.email, // string
                });
            }
        });
    }

    if (signers) {
        uriSigners = signers
            .map((signer) => {
                return _clean({
                    name: signer.name,
                    email: signer.email,
                    onBehalfOf: signer.onBehalfOf,
                    isPrivate: signer.accessControl,
                    enableTouch: signer.enableInsecureSigning || null,
                    roles: signer.role
                        .filter((role) => role.enabled)
                        .map((role) => role.id),
                    ssn: signer.ssn,
                    ssnType: signer.ssnType ?? SSNs.LEGACY_SSN.id,
                    vatid: signer.vatin,
                    reminderInterval: Number(signer.reminderInterval),
                });
            })
            .filter((signer) => {
                if (Object.keys(signer).length === 0) {
                    return false;
                }

                // Ignore signer if it only has the reminder interval property
                if (
                    Object.keys(signer).length === 1 &&
                    signer.reminderInterval >= 0
                ) {
                    return false;
                }

                return true;
            });
    }

    // Check if template has been modified.
    let { isDefaultTemplate } = MessageStore;
    let defaultTemplate = isDefaultTemplate(messageTemplate, casefile.language);

    jsonObject = _clean({
        templateId: casefile.caseFileTypeId, // int
        name: casefile.title, // string
        language: casefile.language,
        folderId: selectedFolderId, // int
        signOnMeeting: casefile.signOnMeeting, // bool
        sensitiveData: sensitiveData, // bool
        visibilityMode: Constants.VISIBILITY_MODES[casefile.visibilityMode], // string
        messageTemplate: !defaultTemplate.title ? messageTemplate.title : null, // string
        messageSubject: !defaultTemplate.subject
            ? messageTemplate.subject
            : null, // string
        messageText: !defaultTemplate.message ? messageTemplate.message : null, // string
        documents: uriDocuments, // arr
        signers: uriSigners, // arr
        copyRecipients: uriCopyRecipients, // arr
        submitWhenReady: null, // bool
        sendAt: sendAtDate ? sendAtDate.format() : null,
        expireAt: expireAtDate ? expireAtDate.format() : null,
        reference: casefile.reference,
        schemaVersion: '2.0.0', // string,
    });

    let JSONString = JSON.stringify(jsonObject);

    _JSONIntegrationCode = JSONString.split(' ').join('%20');

    _integrationCode = jsonObject;
}

function _clean(obj) {
    for (let key in obj) {
        if (
            obj[key] === null || // null
            obj[key] === '' || // empty
            // obj[key] === 0 || // is a numeric 0
            typeof obj[key] === 'undefined' || // undefined
            (Array.isArray(obj[key]) && !obj[key][0])
        ) {
            // array with no elements
            delete obj[key];
        }
    }

    return obj;
}

function updateIntegrationCode(jsonObject) {
    _integrationCode = jsonObject;
}

/* Flux Store */
const URIStore = assign({}, BaseStore, {
    getIntegrationCode() {
        return _integrationCode;
    },

    getJSONIntegrationCode() {
        return _JSONIntegrationCode;
    },

    getCasefileTemplateState() {
        return _casefileTemplates;
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case 'GENERATE_INTEGRATION_CODE':
            case 'GENERATE_CASEFILE_TEMPLATE_DATA':
                generateIntegrationCode();
                break;
            case Constants.ActionTypes.INTEGRATION_CODE_CHANGED:
                if (action.data) {
                    updateIntegrationCode(action.data);
                }

                break;
            case 'GENERATE_CASEFILE_TEMPLATE':
                if (action.name) {
                    let template = createNewTemplate(action.name);

                    // TODO: When the state is stored as a URI fix THIS!!!!
                    URIActions.addCasefileTemplate(template);
                }

                break;
            case 'CASEFILE_TEMPLATES_FETCHED':
                if (action.data) {
                    setCasefileTemplates(action.data);
                }

                break;
            case 'CASEFILE_TEMPLATE_DELETED':
                if (action.key) {
                    deleteCasefileTemplate(action.key);
                }

                break;
            case 'CASEFILE_TEMPLATE_POSTED':
                URIActions.fetchCasefileTemplates();
                break;
            case 'CASEFILE_TEMPLATE_PUT':
                URIActions.fetchCasefileTemplates();
                break;
            case 'DOCUMENT_EXTRACT_URI_SUCCESS':
            case 'DOCUMENT_EXTRACT_URI_FAILURE':
                URIStore.emit(action.type, action.data); // Forward data to listeners, skip store

                return;
            case 'CASEFILE_TEMPLATE_CREATE_REQUEST':
            case 'CASEFILE_TEMPLATES_FETCH_REQUEST':
                _casefileTemplates.fetching = true;
                break;
            case 'CASEFILE_TEMPLATE_CREATE_SUCCESS':
            case 'CASEFILE_TEMPLATES_FETCH_SUCCESS':
                _casefileTemplates.templates = action.templates;
                _casefileTemplates.fetching = false;
                break;
            case 'CASEFILE_TEMPLATE_CREATE_FAILURE':
            case 'CASEFILE_TEMPLATES_FETCH_FAILURE':
                _casefileTemplates.fetching = false;
                break;
            case 'CASEFILE_TEMPLATES_RESET':
                _casefileTemplates = {
                    templates: null,
                    fetching: false,
                    success: false,
                };
                break;
            case 'CASEFILE_TEMPLATE_LOAD_SUCCESS':
                _casefileTemplates = assign(_casefileTemplates, {
                    success: true,
                });
                break;
            case 'CASEFILE_TEMPLATE_DELETE_REQUEST':
                _casefileTemplates = assign(_casefileTemplates, {
                    deleting: true,
                });
                break;
            case 'CASEFILE_TEMPLATE_DELETE_SUCCESS':
                _casefileTemplates = assign(_casefileTemplates, {
                    templates: action.templates,
                    deleting: false,
                });
                break;
            default:
                return;
        }
        // If action was responded to, emit change event
        URIStore.emitChange(action.type);
    }),
});

export default URIStore;
