import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import { AvailabilityType, EIDMethod } from 'EID/types';
import parameters from 'Constants';
import { ManagedSettings } from 'Settings/redux/types';

/**
 * Checks if an EID method is available for use for the given intent,
 * based on the presence and the value of its LaunchDarkly flag
 * @param method The EID method to check
 * @param intent The availability type to check
 * @param managedSettings Optional settings required for some availability checks
 * @returns boolean indicating if the method is available
 */
export const isMethodAvailable = (
    method: EIDMethod,
    intent: AvailabilityType,
    managedSettings?: ManagedSettings
): boolean => {
    const intentAvailability = method.availability?.[intent];

    if (intentAvailability === undefined) {
        return true;
    }

    if (typeof intentAvailability === 'function') {
        // The "availability" function's length is the number of parameters it accepts
        if (intentAvailability.length > 0 && !managedSettings) {
            throw new Error(
                `Availability check for ${intent} on method ${method.type} requires managedSettings but none were provided`
            );
        }

        return intentAvailability(managedSettings);
    }

    const enabledExperimentalEIDs = launchDarkly.variation(
        Flags.EXPERIMENTAL_EIDS_ENABLED
    );

    if (!Array.isArray(enabledExperimentalEIDs)) {
        return false;
    }

    if (
        parameters.EXPERIMENTAL_EID_WILDCARD_ENABLED &&
        enabledExperimentalEIDs.includes('*')
    ) {
        return true;
    }

    return (
        enabledExperimentalEIDs.includes(method.type) ||
        enabledExperimentalEIDs.includes(`${method.type}:${intent}`)
    );
};

const createAvailabilityCheck = (intent: AvailabilityType) => (
    method: EIDMethod,
    managedSettings?: ManagedSettings
) => isMethodAvailable(method, intent, managedSettings);

export type AvailabilityCheckFn = ReturnType<typeof createAvailabilityCheck>;

export const isMethodAvailableToLogIn = createAvailabilityCheck('login');
export const isMethodAvailableToSign = createAvailabilityCheck('sign');
export const isMethodAvailableToConfigureForSign = createAvailabilityCheck(
    'configureForSign'
);
export const isMethodAvailableToConfigureForLogin = createAvailabilityCheck(
    'configureForLogin'
);
export const isMethodAvailableToSetupUser = createAvailabilityCheck(
    'setupUser'
);
export const isMethodAvailableToValidateId = createAvailabilityCheck(
    'idValidation'
);

/**
 * Given a type, it finds the matching method by either its `type` or `typeAlias`
 */
export const findByType = (methods: EIDMethod[], type: string) =>
    methods.find((method) =>
        [method.type, method.typeAlias, method.credentialType()].includes(
            type.toLowerCase()
        )
    );
