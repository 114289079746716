import React from 'react';
import { connect } from 'react-redux';
import { i18n } from 'Language';
import lodash from 'lodash';
import { ReduxState, AppDispatch } from 'Store';
import ClickDropdown from 'Common/components/Common/ClickDropdown';
import { updateUserSettings } from 'Settings/redux/actions';
import { UserSettingKeys, UserSettings } from 'Settings/redux/types';
import { EmailTemplateGroup, TemplateType } from 'types/EmailTemplates';
import Button from 'Common/components/Button';
import { notify } from 'react-notify-toast';

export type Props = {
    dispatch: AppDispatch;
    defaultEmailTemplates: UserSettings['defaultEmailTemplates'];
    template: {
        id: number;
    };
};

type TemplateItem = {
    group: EmailTemplateGroup;
    type: TemplateType;
    label: string;
};
export class DefaultTemplateSelector extends React.Component<Props> {
    updateDefaultTemplate(item: TemplateItem, isDefault: boolean) {
        const { dispatch, template, defaultEmailTemplates } = this.props;
        const updated = lodash.set(
            lodash.cloneDeep(defaultEmailTemplates),
            `${item.group}.${item.type}`,
            isDefault ? false : template.id
        );

        dispatch(
            updateUserSettings(UserSettingKeys.defaultEmailTemplates, updated)
        );

        if (isDefault) {
            notify.show(
                <span>
                    {item.label} {i18n`was reset to defaults`}&nbsp;
                    <i className="fa fa-check" />
                </span>,
                'success',
                3000
            );
        } else {
            notify.show(
                <span>
                    {item.label} {i18n`was set as default`}&nbsp;
                    <i className="fa fa-check" />
                </span>,
                'success',
                3000
            );
        }
    }

    isTemplateDefault(group: EmailTemplateGroup, type: TemplateType) {
        const defaultTemplateId = lodash.get(
            this.props.defaultEmailTemplates,
            `${group}.${type}`,
            false
        );

        return defaultTemplateId === this.props.template.id;
    }

    renderOption(option) {
        const isDefault = this.isTemplateDefault(option.group, option.type);

        return (
            <li
                key={`${option.group}.${option.type}`}
                onClick={() => this.updateDefaultTemplate(option, isDefault)}>
                {option.label}
                {this.isTemplateDefault(option.group, option.type) && (
                    <span>
                        &nbsp;
                        <i className="fas fa-check text-blue"></i>
                    </span>
                )}
            </li>
        );
    }

    render() {
        const casefileTemplates: TemplateItem[] = [
            {
                label: i18n`Message to signers`,
                group: EmailTemplateGroup.CASEFILE,
                type: TemplateType.SIGNER,
            },
            {
                label: i18n`Reminder message`,
                group: EmailTemplateGroup.CASEFILE,
                type: TemplateType.REMINDER,
            },
            {
                label: i18n`Case completed message`,
                group: EmailTemplateGroup.CASEFILE,
                type: TemplateType.COMPLETED,
            },
        ];

        const kycTemplates: TemplateItem[] = [
            {
                label: i18n`Message to recipient`,
                group: EmailTemplateGroup.KYC,
                type: TemplateType.INITIAL,
            },
            {
                label: i18n`Reminder message`,
                group: EmailTemplateGroup.KYC,
                type: TemplateType.REMINDER,
            },
        ];

        // Count how many defaults are set as for this template
        const defaultCount = casefileTemplates
            .concat(kycTemplates)
            .reduce((prev: number, current) => {
                if (this.isTemplateDefault(current.group, current.type)) {
                    prev++;

                    return prev;
                }

                return prev;
            }, 0);

        return (
            <div className="pull-right">
                <ClickDropdown
                    placement="top left"
                    width={240}
                    align="right"
                    offset={0}
                    content={
                        <div className="default-template-selector click-dropdown-list">
                            <ul>
                                <h4 className="mt0  text-small mb0">{i18n`Case files`}</h4>
                                {casefileTemplates.map((option) =>
                                    this.renderOption(option)
                                )}

                                <h4 className="mb0 text-small ">{i18n`Identity validation`}</h4>
                                {kycTemplates.map((option) =>
                                    this.renderOption(option)
                                )}
                            </ul>
                        </div>
                    }>
                    <Button
                        className="ml"
                        theme="blue"
                        variant="outline"
                        type="button"
                        icon="far fa-ellipsis-h">
                        <span>
                            {i18n('Set as default')}
                            {defaultCount > 0 && (
                                <span>&nbsp;({defaultCount})</span>
                            )}
                        </span>
                    </Button>
                </ClickDropdown>
            </div>
        );
    }
}

export default connect((state: ReduxState) => ({
    defaultEmailTemplates: state.settings.data.user.defaultEmailTemplates,
}))(DefaultTemplateSelector);
