import React from 'react';
import classnames from 'classnames';

type Props = {
    onChange: Function;
    checked: boolean;
    name?: string;
    className?: string;
    label?: string;
    disabled?: boolean;
    required?: boolean;
    children?: JSX.Element;
    style?: any;
};

const RadioButton: React.FunctionComponent<Props> = (props) => {
    const {
        checked,
        className,
        style,
        name,
        disabled,
        required,
        label,
        onChange,
        children,
        ...restProps
    } = props;

    const handleChange = (event) => {
        const { checked } = event.target;

        return onChange(checked, event);
    };

    return (
        <label
            className={classnames('radio', className, { checked: checked })}
            style={style}>
            <input
                type="radio"
                className="invisible" // Add invisible to the element because it interfered with cursor classes
                name={name}
                disabled={disabled}
                checked={checked}
                required={required}
                onChange={handleChange}
                {...restProps}
            />

            <span className="radio-input"></span>
            <span className="radio-label">{label}</span>

            {children}
        </label>
    );
};

export default RadioButton;
