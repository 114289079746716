import Button from 'Common/components/Button';
import { i18n } from 'Language';
import { dispatch, ReduxState } from 'Store';
import React, { useEffect, useState } from 'react';
import { sendSMS, setError, setShowConfirmModal } from './redux/reducer';
import { SMSError } from './types';
import { useSelector } from 'react-redux';
import analytics from '../../../Common/Analytics';
import { Checkbox } from '../../../Common/components';
import { EULA_URL, PRIVACY_POLICY_URL } from '../../../Constants';

type SendAuthenticationCodeProps = {
    maskedPhoneNumber: string;
};

export default function SendAuthenticationCode({
    maskedPhoneNumber,
}: SendAuthenticationCodeProps) {
    const { challengeKey } = useSelector(
        (state: ReduxState) => state?.smsValidation
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [didUserConsent, setDidUserConsent] = useState<boolean>(false);

    const handleSendCode = () => {
        setIsLoading(true);

        analytics.track('SMS - Click on "Send me the code"');

        if (!challengeKey) {
            return dispatch(
                setError({ message: 'Invalid challengeKey' } as SMSError &
                    Error)
            );
        }

        dispatch(sendSMS(challengeKey));
        setIsLoading(false);
    };

    const handleShowRejectModal = () => {
        analytics.track('SMS - Click on "This is not my number"');
        dispatch(setShowConfirmModal(true));
    };

    useEffect(() => {
        analytics.track('SMS - Flow started');
    }, []);

    return (
        <div data-testid="send-authentication-code">
            <p className="text-neutral-900 mb-6">{i18n`A one-time code will be sent via SMS to the number below:`}</p>

            <div className="text-center">
                <h2 className="font-semibold text-neutral-700">
                    {maskedPhoneNumber}
                </h2>

                <a
                    className="link text-secondary-600 block mb-6"
                    onClick={handleShowRejectModal}>
                    {i18n`This is not my number`}
                </a>

                <div className="inline-flex accent-neutral-900 mb-6 text-left">
                    <Checkbox
                        name="consent"
                        label={
                            <div className="inline">
                                {i18n`I agree to receive a one-time code via SMS message from Penneo. Normal message and data rates apply.`}{' '}
                                <a
                                    href={EULA_URL}
                                    target="_blank"
                                    rel="noreferrer">{i18n`Terms and Conditions`}</a>
                                &nbsp;&amp;&nbsp;
                                <a
                                    href={PRIVACY_POLICY_URL}
                                    target="_blank"
                                    rel="noreferrer">{i18n`Privacy Policy`}</a>
                            </div>
                        }
                        checked={didUserConsent}
                        onChange={(value: boolean) => setDidUserConsent(value)}
                    />
                </div>

                <Button
                    theme="blue"
                    isLoading={isLoading}
                    disabled={isLoading || !didUserConsent}
                    onClick={handleSendCode}>{i18n`Send me the code`}</Button>
            </div>
        </div>
    );
}
