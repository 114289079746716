import React, { useEffect, useState } from 'react';
import ItsmeError from '../ItsmeError';
import {
    getErrorDetails,
    ITSME_INVALID_STATE,
    ITSME_MISSING_RETURN_PARAMS,
    ItsmeErrorType,
} from '../../errors';
import { getURLSearchParameter } from '../../utils';
import { PublicSigningAPI } from 'Api';
import { images } from 'Constants';
import LoadingData from 'Common/components/Loaders/LoadingData';
import { i18n } from 'Language';
import WidgetHeader from '../../../Common/components/WidgetHeader';
import WidgetBody from '../../../Common/components/WidgetBody';
import Widget from '../../../Common/components/Widget';
import {
    loadStoredItsmeState,
    StoredStateType,
    storeItsmeState,
} from 'ItsmeQES/storedState';

type State = {
    error: ItsmeErrorType | null;
};
function ItsmeCredentialCallback() {
    const itsmeState = loadStoredItsmeState();
    // We do not clear the state here because it could be relevant for the user to try to refresh, for example when the
    // API call below fails because of network errors.
    // If the situation really is unrecoverable, the user will be able to go back to the main signing page, where the
    // state WILL be cleared.

    const [state, setState] = useState({
        error: null,
    } as State);

    const collectSignatures = async () => {
        setState({ error: null });

        const code = getURLSearchParameter('code');
        const state = getURLSearchParameter('state');

        if (
            itsmeState?.type !== StoredStateType.SecondDanceInit ||
            !itsmeState?.successUrl
        ) {
            return setState({ error: ITSME_INVALID_STATE });
        }

        if (code === null || state === null) {
            return setState({
                error: ITSME_MISSING_RETURN_PARAMS,
            });
        }

        try {
            const response = await PublicSigningAPI.post(
                '/v3/sign/itsme.be/collect-signatures',
                {
                    challengeKey: itsmeState.challengeKey,
                    code,
                    state,
                }
            );

            storeItsmeState({
                type: StoredStateType.SecondDanceResponse,
                signature: response.signedXAdES,
            });

            window.location.href = itsmeState.successUrl;
        } catch (error) {
            console.error('Error trying to collect itsme.be signature', error);

            const { data, headers } = error;
            const errorDetails = getErrorDetails(data.type);

            setState({
                error: {
                    headers,
                    ...data,
                    ...errorDetails,
                },
            });
        }
    };

    useEffect(() => {
        collectSignatures();
    }, []); // Using an empty dependencies array to ensure collectSignatures only runs on initial mount, avoiding triggers from itsmeState or other changes.

    return (
        <Widget className="openid-callback">
            <WidgetHeader>
                <>
                    <br />
                    <img
                        height="18"
                        src={`${images}/penneo-blue.png`}
                        alt="Penneo logo"
                    />
                </>
            </WidgetHeader>
            <WidgetBody>
                {state.error && (
                    <ItsmeError
                        error={state.error}
                        redirectUrl={
                            itsmeState?.type === StoredStateType.SecondDanceInit
                                ? itsmeState?.successUrl
                                : null
                        }
                    />
                )}

                {!state.error && (
                    <>
                        <img
                            height="90"
                            className="openid-callback-id-service-logo"
                            src={`${images}/eid/belgium_itsme_logo_512x512.png`}
                            alt="itsme logo"
                        />

                        <LoadingData
                            message={i18n`Redirecting back to Penneo...`}
                        />
                    </>
                )}
            </WidgetBody>
        </Widget>
    );
}

export default ItsmeCredentialCallback;
