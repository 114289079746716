//import { PublicSignerAPI } from 'Api';
import { i18n, TranslationActions } from 'Language';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

/* new signing components */
import { SigningHeader } from './SigningHeader';
import {
    trackSigning,
    signingData,
    signingRedirectUrl,
    signingBranding,
    setSignerLanguage,
} from './utils';
import { Languages } from 'Language/Constants';

import Loader from 'Common/components/Common/Loader';
import './signing-done.scss';
import { images } from 'Constants';
import { signingStages } from 'types/SigningProcess';

const SignFail = ({ challengeKey }: { challengeKey: string }) => {
    const [determined, setDetermined] = useState(false);
    // This is only set to force a re-render after the user selects a language.
    const [_, setUserSelectedLanguage] = useState(''); // eslint-disable-line

    useEffect(() => {
        (async () => {
            // makes sure to cleanup after signing
            const localData = signingData.get(challengeKey);
            const outcome = localData?.outcome;

            if (outcome) {
                const signRedirectUrl = await signingRedirectUrl(
                    outcome as signingStages,
                    challengeKey
                );

                setDetermined(true);
                window.location.replace(signRedirectUrl);
            }
        })();
    }, [challengeKey]);

    async function changeLanguage(languageCode: Languages) {
        await TranslationActions.changeLanguageView(languageCode);

        setSignerLanguage(languageCode);

        setUserSelectedLanguage(languageCode);
    }

    const linkTracking = (name: string, url: string) =>
        trackSigning(`Fail sign page - Link clicked`, { name, url });

    const linkLocalization = (language: Languages = Languages.EN) =>
        language === Languages.EN ? '' : `${language}/`;

    const links = [
        {
            name: 'Legality of digital signatures',
            url: `blog/digital-signatures/`,
        },
        {
            name: 'Validity of the signed document',
            url: `trust-center`,
        },
        {
            name: 'EU & international regulations',
            url: `blog/eidas-regulation/`,
        },
        {
            name: 'Penneo for businesses',
            url: `free-trial-businesses/`,
        },
    ];

    if (determined) {
        return (
            <>
                <br />
                <br />
                <Loader />
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>{i18n`Something went wrong`}</title>
            </Helmet>

            <SigningHeader
                branding={signingBranding()}
                changeLanguage={changeLanguage}
            />
            <div className="signing-container-done">
                <div className="signing-done">
                    <div className="signing-content-done">
                        <>
                            <h1 className={'error'}>
                                {i18n`Casefile does not exist`}
                                <img
                                    src={`${images}/sign/signing-rejected.svg`}
                                />
                            </h1>
                            <p>{i18n`This could be due to you already having signed or rejected the document.`}</p>
                            <p>{i18n`If you have not viewed, rejected or signed a document, contact the sender of the document in question.`}</p>
                        </>

                        <p>
                            {i18n`Need to know what signing a document digitally with Penneo means? Learn more about:`}
                        </p>
                        <ul className="signing-links-list">
                            {links.map((link) => {
                                const href = `https://penneo.com/${linkLocalization()}${
                                    link.url
                                }`;

                                return (
                                    <li key={link.name}>
                                        <a
                                            href={href}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={() =>
                                                linkTracking(link.name, href)
                                            }>
                                            {i18n(link.name)}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="signing-footer"></div>
        </>
    );
};

export default SignFail;
