import { CaseFileType } from 'types/CaseFile';
import { DocumentType } from 'types/Document';
import { SignerTypeEntity } from 'types/Signer';
import {
    BetterCaseFileType,
    BetterDocumentType,
    BetterRound,
    BetterSignerType,
} from '../types';

const extractDocumentTypes = (
    casefileType: CaseFileType
): BetterDocumentType[] => {
    return casefileType.documentTypes.map((dt) => {
        return {
            id: dt.id,
            name: dt.name,
            lowerLimit: dt.lowerLimit,
            upperLimit: dt.upperLimit,
            signable: dt.signerTypes.length > 0,
        };
    });
};

const extractSignerTypes = (casefileType: CaseFileType): BetterSignerType[] => {
    const signerTypes = new Map<number, BetterSignerType>();

    casefileType.documentTypes.forEach((dt) => {
        dt.signerTypes.forEach((st) => {
            signerTypes.set(st.id, {
                id: st.id,
                role: st.role,
                lowerLimit: st.lowerLimit,
                upperLimit: st.upperLimit,
            });
        });
    });

    // Extract values from Map
    const iterator = signerTypes.values();

    return Array.from(iterator);
};

export const getSigningRoundsCount = (casefileType: CaseFileType): number => {
    const rounds = new Set<number>();

    // Iterate over all document types and signer types to find the highest number of signing rounds.
    // Use a Set so values are unique.
    casefileType.documentTypes.forEach((dt) => {
        dt.signerTypes.forEach((st) => {
            rounds.add(st.signOrder);
        });
    });

    // Convert Set to Array and get max value.
    const roundsArray = Array.from(rounds);

    return Math.max(...roundsArray);
};

const getDocumentsFromSigningRound = (
    casefileType: CaseFileType,
    signingRound: number
): CaseFileType['documentTypes'] => {
    const docs = casefileType.documentTypes.filter((dt) => {
        return (
            dt.signerTypes.filter((st) => st.signOrder === signingRound)
                .length > 0
        );
    });

    return docs;
};

const getSignersFromDocumentByRound = (
    documentType: DocumentType,
    signingRound: number
): SignerTypeEntity[] => {
    const signers = documentType.signerTypes.filter((st) => {
        return st.signOrder === signingRound;
    });

    return signers;
};

const extractSigningRounds = (casefileType: CaseFileType): BetterRound[][] => {
    const rounds = [] as BetterRound[][];

    const roundsCount = getSigningRoundsCount(casefileType);

    // Create an array filled with a value so we can iterate over it.
    const roundsIterator = new Array(roundsCount + 1).fill(0);

    roundsIterator.forEach((_, roundIndex) => {
        const docs = getDocumentsFromSigningRound(casefileType, roundIndex);

        const roundMapping = docs.map((doc) => {
            const signers = getSignersFromDocumentByRound(doc, roundIndex);

            return {
                documentTypeId: doc.id,
                signerTypeIds: signers.map((s) => s.id),
            };
        });

        rounds.push(roundMapping);
    });

    return rounds;
};

/**
 * This utility is used to convert the current casefile type entity that the API returns from api/v1/casefiletypes
 * into a more manageable format that allows to create better UIs for visualizing the signing flow
 *
 * See:
 * - Example of API Casefile Type: /src/__mocks__/casefiletype-original.json
 * - Example of new Casefile Type: /src/__mocks__/better-casefiletype.json
 *
 * @param casefileType - Casefile Type from API
 */
export const convert = (casefileType: CaseFileType): BetterCaseFileType => {
    return {
        id: casefileType.id,
        name: casefileType.name,
        documentTypes: extractDocumentTypes(casefileType),
        signerTypes: extractSignerTypes(casefileType),
        rounds: extractSigningRounds(casefileType),
    };
};
