import React from 'react';
import CasefileStore from '../../stores/CasefileStore';
import { modal } from 'Common/components/Common/Modal';
import FolderSelectorModal from './FolderSelectorModal';
import CasefileActions from 'Casefiles/actions/CasefilesActionCreators';
import Loading from 'Common/components/Loaders/LoadingData';
import { notify } from 'react-notify-toast';
import { i18n } from 'Language';
import Button from 'Common/components/Button';
import Analytics from 'Common/Analytics';
import { SimpleFolderEntity } from 'types/Folder';

type Props = {
    folders: SimpleFolderEntity[];
    selectedFolder: number;
    action: string;
    handleFolderChange: (folderId: number) => void;
};

type State = {
    title: null | string;
};

export default class CasefileFolderSelector extends React.Component<
    Props,
    State
> {
    static defaultProps = {
        folders: [],
    };

    state: State = {
        title: null,
    };

    getActiveFolder = () => {
        let { selectedFolder } = this.props;

        return CasefileStore.getFolder(selectedFolder);
    };

    handleCreateFolder = () => {
        let { title } = this.state;

        if (!title) {
            return false;
        }

        CasefileActions.createFolder(title);
    };

    handleChange = (event) => {
        let { value } = event.target;

        this.setState({ title: value });
    };

    componentDidUpdate(props) {
        this.handleNewFolderActions(props.action);
    }

    handleNewFolderActions = (action) => {
        switch (action) {
            case 'CREATE_FOLDER_REQUEST':
                break;
            case 'CREATE_FOLDER_SUCCESS':
                setTimeout(() => modal.hide(), 0);
                break;
            case 'CREATE_FOLDER_FAILURE':
                break;
            default:
                return false;
        }

        this.showNewFolderModal(action);
    };

    showNewFolderModal = (action) => {
        // notify of error, if any
        if (action === 'CREATE_FOLDER_FAILURE') {
            notify.show(
                i18n`An error occurred. Please try again`,
                'error',
                5000
            );
        }

        let body = (
            <div>
                <div className="label">
                    <input
                        type="text"
                        className="match-parent"
                        placeholder={i18n('Folder name')}
                        onChange={this.handleChange}
                    />
                </div>

                <Button theme="green" onClick={this.handleCreateFolder}>
                    {i18n`Create`}
                </Button>
            </div>
        );

        if (action === 'CREATE_FOLDER_REQUEST') {
            body = <Loading />;
        }

        if (action === 'CREATE_FOLDER_SUCCESS') {
            body = notify.show(
                <span>
                    {i18n`Success`} <i className="fa fa-check" />
                </span>,
                'success',
                3000
            );
        }

        let config = {
            className: 'medium penneo-casefiles',
            title: i18n`Create new folder`,
            body: body,
        };

        modal.show(config);
    };

    openFolderSelector = () => {
        let config = {
            component: (
                <FolderSelectorModal
                    folders={this.props.folders}
                    handleFolderChange={this.props.handleFolderChange}
                    active={this.getActiveFolder()}
                />
            ),
            preventClose: false,
        };

        modal.show(config);

        Analytics.track('casefile - open folder selection modal');
    };

    render() {
        let activeFolder = this.getActiveFolder();
        let loading = this.props.folders.length === 0 || !activeFolder;

        return (
            <div
                onClick={this.openFolderSelector}
                className="casefile-folder-selector-bar">
                <span className="folder-title">
                    {loading ? (
                        <span className="text-medium">
                            <i className="far fa-sync fa-spin"></i>&nbsp;
                            <span>{i18n`Loading...`}</span>
                        </span>
                    ) : (
                        <span>
                            <i className="far fa-folder"></i>&nbsp;
                            <span>{activeFolder.title}</span>
                        </span>
                    )}
                </span>

                <Button
                    theme="blue"
                    variant="outline"
                    className="pull-right"
                    disabled={loading}>
                    {i18n`Change`}
                </Button>
            </div>
        );
    }
}
