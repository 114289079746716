import { Dispatcher } from 'Core';
import { OpenIDActions as Actions } from '../ActionTypes';
import { Intent, ServiceIDs } from 'OpenID/Constants';
import { storeOpenIdIntentState } from 'OpenID/utils/openIdState';
import { PublicAuthAPI } from 'Api';
import { getUrlId } from 'OpenID/redux/actions';

const OpenIDActions = {
    initSuccess(
        intent: Intent,
        serviceId: string | ((intent: Intent) => string),
        response = {
            uri: undefined,
            csrfToken: '',
        },
        encryptedNIN?: string
    ) {
        if (!response.uri) {
            return;
        }

        Dispatcher.handleServerAction({
            type: Actions.INIT_SUCCESS,
            uri: response.uri,
        });

        // Save data about user's intent
        storeOpenIdIntentState({
            serviceId: serviceId as ServiceIDs,
            intent,
            csrfToken: response.csrfToken,
            encryptedNIN,
        });

        // Redirect to OpenID service
        window.location.href = response.uri;
    },

    init(
        serviceId: ServiceIDs,
        intent: Intent,
        metadata = {},
        language = 'en',
        encryptedNIN?: string
    ) {
        Dispatcher.handleServerAction({
            type: Actions.INIT_REQUEST,
        });

        const payload = {
            language: language,
            redirectUri: `${window.location.origin}/openid/${serviceId}/callback`,
        };

        PublicAuthAPI.post(
            `/openid/${getUrlId(serviceId as any, Intent.ACTIVATION)}/init`,
            payload
        )
            .then((response) => {
                Dispatcher.handleServerAction({
                    type: Actions.INIT_SUCCESS,
                    uri: response.uri,
                });

                // Save data about user's intent
                storeOpenIdIntentState(
                    {
                        serviceId: serviceId,
                        intent: intent,
                        csrfToken: response.csrfToken,
                        encryptedNIN,
                    },
                    metadata
                );

                // Redirect to OpenID service
                window.location.href = response.uri;
            })
            .catch((error) => {
                this._dispatchError(error, Actions.INIT_FAILURE);
            });
    },

    // Handle all dispatched errors
    _dispatchError(error, action) {
        switch (action) {
            case Actions.INIT_FAILURE:
            case Actions.COLLECT_FAILURE:
            case Actions.LOGIN_FAILURE:
            case Actions.SIGN_FAILURE:
            case Actions.ADD_FAILURE:
            case Actions.FETCH_FAILURE:
            case Actions.REMOVE_FAILURE:
            case Actions.RESET_FAILURE:
            default:
                Dispatcher.handleServerAction({
                    type: action,
                    error: {
                        headers: error.headers,
                        status: error.status,
                        statusText: error.statusText,
                        data: error.data,
                    },
                });
                break;
        }
    },
};

export default OpenIDActions;
