import PropTypes from 'prop-types';
import React from 'react';
import NumericInput from 'react-numeric-input';

export default class NumberInput extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        label: PropTypes.string,
        name: PropTypes.string,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
    };

    render() {
        // HTML Properties
        // let {checked, name, disabled, required, inline} = this.props;

        // Data
        // let {label, labelBefore} = this.props;

        // Functions
        // let {onChange} = this.props;

        return (
            // @see: https://www.npmjs.com/package/react-numeric-input
            // for documentation
            <NumericInput
                strict={true}
                size={8}
                precision={0}
                mobile={true}
                className="number-input"
                data-lpignore={true} // prevent lastpass from rendering autofill helpers
                autoComplete={'false'}
                {...this.props}
            />
        );
    }
}
