import classnames from 'classnames';
import { AutocompleteTags } from 'Common/components/AutocompleteTags';
import { i18n, translationsPreservedSpaces } from 'Language';
import { Languages } from 'Language/Constants';
import { i18nCustomLanguage } from 'Language/utils';
import React, {
    ChangeEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';
import { ActionMeta } from 'react-select';
import { ValidationError } from 'types/DataValidation';

export type Props = {
    collapsible: boolean;
    heading: string | ReactElement;
    message: {
        body: string;
        isDefaultTemplate: boolean;
        subject: string;
        id?: number;
    };
    messageValidity: {
        valid: boolean;
        errors: { subject?: ValidationError; content?: ValidationError };
    };
    index?: number;
    onChangeBody: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    onChangeSubject: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeTemplate: (selectedValue: string) => void;
    showSignatureNotice: boolean;
    translationLanguage: Languages;
    children?: ReactElement | ReactElement[];
    recipients?: {
        availableRecipients: { value: string; label: string }[];
        currentRecipients: { value: string; label: string }[];
        onPickSuggestion: (
            actionMeta: ActionMeta<{ label: string; value: string }>
        ) => void;
    };
    recipientsDescription?: string;
    selectOptions?: { label: string; value: number }[];
};

export function EmailMessageEditor(props: Props) {
    const {
        children,
        collapsible,
        heading,
        message,
        messageValidity,
        onChangeBody,
        onChangeSubject,
        onChangeTemplate,
        index = false,
        recipients,
        selectOptions,
        showSignatureNotice,
        translationLanguage,
        recipientsDescription,
    } = props;

    const [isCollapsed, setIsCollapsed] = useState(false);
    const lang = useRef<string | undefined>(undefined);
    const [subject, setSubject] = useState<string>(
        (message?.subject?.length > 0 && message.subject) || ''
    );
    const [body, setBody] = useState<string>(
        (message?.body?.length > 0 && message.body) || ''
    );

    const selectRef = useRef<number | undefined>(message.id);

    useEffect(() => {
        selectRef.current = message.id || -2;
    }, [message.id]);

    function handleOnChangeSubject(event: ChangeEvent<HTMLInputElement>) {
        setSubject(event.target.value);
        selectRef.current = -2;
        onChangeSubject(event);
    }

    function handleOnChangeBody(event: ChangeEvent<HTMLTextAreaElement>) {
        setBody(event.target.value);
        selectRef.current = -2;
        onChangeBody(event);
    }

    function handleOnChangeTemplate(event: ChangeEvent<HTMLSelectElement>) {
        selectRef.current = Number(event.target.value);
        onChangeTemplate(event.target.value);
    }

    function handleOnPickSuggestion(
        actionMeta: ActionMeta<{
            label: string;
            value: string;
        }>
    ) {
        if (!recipients) return;

        recipients.onPickSuggestion(actionMeta);
    }

    useEffect(() => {
        const { body, id, isDefaultTemplate, subject } = message;

        if (id == undefined) {
            // so we are in editing mode... there is no id...
            if (!message.subject) {
                setSubject(subject);
            }

            if (!message.body) {
                setBody(body);
            }

            return;
        }

        if (id && id > 0) {
            setBody(body);
            setSubject(subject);

            return;
        }

        if (isDefaultTemplate || lang.current !== translationLanguage) {
            (async () => {
                await translationsPreservedSpaces.changeLanguage(
                    translationLanguage
                );

                const translatedBody = await i18nCustomLanguage(
                    body,
                    translationLanguage
                );

                const translatedSubject = await i18nCustomLanguage(
                    subject,
                    translationLanguage
                );

                setBody(translatedBody);
                setSubject(translatedSubject);
                lang.current = translationLanguage;
            })();
        }
    }, [translationLanguage, message]);

    const id = (type: string): string =>
        index !== false && index >= 0 ? `${type}_${index}` : `${type}`;

    return (
        <div
            className={classnames('message-editor-container collapsing-box', {
                collapsed: collapsible && isCollapsed,
            })}>
            <div
                data-testid="message-header"
                className={classnames(
                    'collapsing-box-header text-blue text-semibold',
                    { 'cursor-pointer': collapsible }
                )}
                onClick={() => collapsible && setIsCollapsed(!isCollapsed)}>
                <span className={'collapsing-box-header-title'}>
                    {!messageValidity.valid && (
                        <span data-testid="validation-warning">
                            <i className="fas fa-exclamation-triangle text-warning" />
                            &nbsp;
                        </span>
                    )}
                    {heading}
                    &nbsp;
                    <i className="far fa-envelope"></i>
                </span>

                {collapsible && (
                    <div className="pull-right">
                        {isCollapsed ? (
                            <span>
                                {i18n`Expand`}
                                &nbsp;
                                <i className="fas fa-chevron-down"></i>
                            </span>
                        ) : (
                            <span>
                                {i18n`Collapse`}
                                &nbsp;
                                <i className="fas fa-chevron-up"></i>
                            </span>
                        )}
                    </div>
                )}
            </div>

            <div className="collapsing-box-content">
                <div
                    className={classnames('casefile-messages', {
                        invalid: !messageValidity.valid,
                    })}>
                    {selectOptions && (
                        <div className={'casefiles-messages-templates'}>
                            <label htmlFor={id('templates')}>
                                {i18n('Select template')}
                            </label>
                            <select
                                id={id('templates')}
                                name={id('templates')}
                                data-testid="templates-select"
                                className="cursor-pointer mb"
                                value={selectRef.current || -1}
                                onChange={handleOnChangeTemplate}>
                                {selectRef.current === -2 && (
                                    <option key={-2} value={-2} disabled>
                                        {`${i18n('Custom template')}..`}
                                    </option>
                                )}

                                {selectOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                        {option.value === -1
                                            ? i18n(option.label)
                                            : option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {recipients && (
                        <div className="casefile-messages-recipients">
                            <label
                                htmlFor={id('recipients')}
                                aria-labelledby={id('recipients-description')}>
                                {i18n('Recipient')}
                            </label>
                            {recipientsDescription && (
                                <p
                                    className="sr-only"
                                    id={id('recipients-description')}
                                    aria-hidden={true}>
                                    {recipientsDescription}
                                </p>
                            )}
                            <AutocompleteTags
                                id={id('recipients')}
                                name={id('recipients')}
                                ariaDescription={`${i18n`Recipients of custom message`} ${
                                    ((index as number) ?? 0) + 1
                                }`}
                                suggestions={recipients.availableRecipients}
                                onChange={(action) =>
                                    handleOnPickSuggestion(action)
                                }
                                value={recipients.currentRecipients}
                                className="recipient-autocomplete-tags"
                            />
                        </div>
                    )}
                    {!recipients && recipientsDescription && (
                        <div className="casefile-messages-recipients recipients-general">
                            <label htmlFor={'recipients-field'}>
                                {i18n('Recipient')}
                            </label>
                            <input
                                className="recipients-non-editable"
                                type="text"
                                id={'recipients-field'}
                                name={'recipients-field'}
                                readOnly
                                placeholder={recipientsDescription}
                            />
                        </div>
                    )}

                    <div className="casefile-messages-subject">
                        <label htmlFor={id('subject')}>{i18n`Subject`}</label>
                        <input
                            type="text"
                            id={id('subject')}
                            name={id('subject')}
                            aria-label="subject"
                            onChange={handleOnChangeSubject}
                            value={subject ?? message.subject}
                        />
                    </div>

                    <div
                        className={classnames('casefile-messages-message', {
                            'with-footer-notice': showSignatureNotice,
                        })}>
                        <label htmlFor={id('message')}>{i18n`Message`}</label>
                        <textarea
                            name={id('message')}
                            id={id('message')}
                            aria-label="message"
                            rows={8}
                            className="match-parent"
                            onChange={handleOnChangeBody}
                            value={body ?? message.body}
                        />

                        {showSignatureNotice && (
                            <p className="casefile-message-signature">
                                <i className="far fa-info-circle"></i>&nbsp;
                                {i18n`Your email signature will be added automatically`}
                            </p>
                        )}
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
}
