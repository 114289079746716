import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';

export default class SignerRoleRow extends React.Component {
    static propTypes = {
        id: PropTypes.number,
        title: PropTypes.string,
        toggle: PropTypes.func,
        changeTitle: PropTypes.func,
        enabled: PropTypes.bool,
    };

    state = {
        editable: false,
    };

    componentWillUnmount() {
        if (this.props.title === '') {
            this.props.changeTitle(null);
        }
    }

    toggleEditable = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ editable: !this.state.editable });
    };

    handleChecked = (event) => {
        let { checked } = event.target;

        this.props.toggle(checked);
    };

    handleChange = (event) => {
        let { value } = event.target;

        this.props.changeTitle(value);
    };

    handleOnEnter = (event) => {
        let { target, key } = event;

        if (key !== 'Enter') {
            return false;
        }

        this.toggleEditable(event);

        // If the string is empty
        if (target.value === '') {
            this.props.changeTitle(null);
        }
    };

    render() {
        let { title, enabled, id } = this.props;
        let { editable } = this.state;

        return (
            <div>
                <label className="custom-checkbox">
                    <input
                        type="checkbox"
                        value={id}
                        checked={enabled}
                        onChange={this.handleChecked}
                    />
                    <span className="check">
                        <i className="fas fa-check" />
                    </span>

                    {!editable ? (
                        <span className="label">{title}</span>
                    ) : (
                        <input
                            type="text"
                            value={title}
                            onChange={this.handleChange}
                            onKeyPress={this.handleOnEnter}
                        />
                    )}
                    <a className="pull-right" onClick={this.toggleEditable}>
                        {i18n`Edit`}&nbsp;<i className="fa fa-pencil"></i>
                    </a>
                </label>
            </div>
        );
    }
}
