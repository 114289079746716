import { TemplateType } from 'types/EmailTemplates';

export const MAX_ATTACHMENT_SIZE = '20Mb';

export const KYCDefaultEmailTemplates = {
    [TemplateType.INITIAL]: {
        subject: `Identity validation: {{kyc.title}}`,
        message: `Dear {{recipient.name}},

As agreed, here is a link to use for identification purposes.
When you click on the link below, you will be given the opportunity to authenticate yourself using your Electronic ID

{{kyc.link}}`,
    },
    [TemplateType.REMINDER]: {
        subject: `Reminder regarding identity validation: {{kyc.title}}`,
        message: `Dear {{recipient.name}},

This is to remind you that you haven't validated your identity yet.

Please click the link below to validate it now.`,
    },
};

export const KYCDefaultDescription =
    'Hello {{recipient.name}}, {{sender.name}} at {{sender.company}} has requested you to validate your identity. Please follow the steps below.';

export const KYCPreferencesKey = 'kyc-preferences';
