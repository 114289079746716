import React from 'react';
import { TextInput } from 'Common/components';
import { i18n } from 'Language';
import Loading from 'Common/components/Loaders/LoadingData';
import CustomerActions from 'Auth/actions/CustomerActionCreators';
import lodash from 'lodash';
import Button from 'Common/components/Button';
import { CustomerEntity as Customer } from 'types/Customer';
import { UserEntity as User } from 'types/User';
import { notify } from 'react-notify-toast';

type Props = {
    action: string;
    user: User;
    customer: Customer;
    close: Function;
};

type State = {
    data: Customer;
};

export default class CustomerDetails extends React.Component<Props, State> {
    state: State = {
        data: lodash.clone(this.props.customer),
    };

    componentDidUpdate(prevProps: Props) {
        const { action, close } = this.props;
        const { action: prevAction } = prevProps;

        if (
            action === 'CUSTOMER_UPDATE_SUCCESS' &&
            prevAction !== 'CUSTOMER_UPDATE_SUCCESS'
        ) {
            close();
        } else {
            this.renderMessage(action);
        }
    }

    handleChange = (
        value: string,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name } = event.target;
        const { data } = this.state;

        data[name] = value;

        this.setState({ data: data });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        CustomerActions.persistCustomer(
            this.props.customer.id,
            this.state.data
        );
    };

    renderMessage = (action: string) => {
        switch (action) {
            case 'CUSTOMER_UPDATE_ERROR':
                notify.show(
                    i18n`There was an error updating the details`,
                    'error',
                    4000
                );
                CustomerActions.resetError();

                return;
            default:
                return;
        }
    };

    render() {
        const { data } = this.state;
        const { user, action } = this.props;

        if (action === 'CUSTOMER_UPDATE_SUCCESS') {
            return (
                <div className="text-center text-medium">
                    <h1>
                        <i className="fa fa-lg fa-check" />
                    </h1>
                </div>
            );
        }

        if (action === 'CUSTOMER_UPDATE_REQUEST') {
            return <Loading />;
        }

        return (
            <div className="customer-details pr pl ui-v2">
                <form className="form" onSubmit={this.handleSubmit}>
                    <h3 className="text-center">{i18n`Edit company details`}</h3>
                    <TextInput
                        label={i18n`Company name`}
                        name="name"
                        value={data.name}
                        onChange={this.handleChange}
                    />

                    <TextInput
                        label={i18n`Address`}
                        name="address"
                        value={data.address}
                        onChange={this.handleChange}
                    />

                    <TextInput
                        label={i18n`City`}
                        name="city"
                        value={data.city}
                        onChange={this.handleChange}
                    />

                    <TextInput
                        label={i18n`Zip code`}
                        name="zip"
                        value={data.zip}
                        onChange={this.handleChange}
                    />

                    <TextInput
                        label={i18n`VATIN`}
                        disabled={!user.admin}
                        name="vatin"
                        value={data.vatin}
                        onChange={this.handleChange}
                    />
                    <div className="text-center mt">
                        <Button
                            theme="blue"
                            icon="far fa-check"
                            onClick={this.handleSubmit}
                            renderIconLeft={true}>
                            {i18n`Save changes`}
                        </Button>
                    </div>
                </form>
            </div>
        );
    }
}
