import { Languages } from 'Language/Constants';

export enum UserRights {
    /**
     * User can send and manage case files
     */
    SEND = 'send',
    /**
     * User can send and manage forms
     */
    FORMS = 'mass-send',
    /**
     * User can send and manage KYCs
     */
    KYC = 'kyc',
    /**
     * @deprecated
     * User can send and manage KYC/AML validations. The legacy version of KYCs.
     */
    __DEPRECATED__VALIDATION = 'validation',
}

/**
 * User roles determine the level of permissions a user account has as a part of an organization.
 *
 * These roles don’t include the “Penneo super admin” role, which is determined by the admin flag property on UserEntity
 */
export enum UserRoles {
    /**
     * User can perform administrative actions on behalf of the organization
     */
    CUSTOMER_ADMINISTRATOR = 'administrator',
    /**
     * User is part of an organization
     */
    USER = 'user',
    /**
     * User is part of an organization and can use the API, but not the Web UI
     * (This is used only for integrations)
     */
    API_USER = 'api_user',
    /**
     * User is part of an organization and can use the API, and can perform administrative actions
     * (This is used only for integrations)
     */
    API_SUPER_USER = 'api_super_user',
}

/**
 * The representation of the user object for the web application (fe-webapp)
 *
 * @note: This structure is constructed from multiple sources and the properties are grouped into a single
 * entity during the bootstrapping process of the application for ease of use.
 */
export type UserEntity = {
    /**
     * Set to true if user has finished the activation/onboarding process
     */
    active: boolean;
    /**
     * Determines if the user is a Penneo super administrator. Only set to `true` for certain Penneo employees
     */
    admin?: true;
    /**
     * List of IDs of the customer accounts that the user belongs to
     */
    customerIds: number[];
    /**
     * Email address used for communication purposes
     */
    email: string;
    /**
     * Name set on user profile
     */
    fullName: string;
    /**
     * User identifier
     */
    id: number;
    /**
     * Language in which the UI will be displayed. Defaults to the company's language until the user selects a different one
     */
    language: Languages;
    /**
     * Timestamp of the users' last login
     */
    lastLogin: number;
    /**
     * Role of the user in the customer that he's logged in for
     * - This property is fetched from a separate endpoint than `/user` and is added during the bootstrapping process of the web application
     */
    role: UserRoles;
    /**
     * Role of the user in the customer that he's logged in for
     * - This property is fetched from a separate endpoint than `/user` and is added during the bootstrapping process of the web application
     */
    rights: UserRights[];
    /**
     * List of IDs of teams that the user belongs to
     */
    teamIds: number[];
};

/**
 * Entity for a user that is not connected to an organization
 */
export type PrivateUserEntity = Omit<UserEntity, 'customerIds' | 'admin'>;

/**
 * Type Guard to check if a user is a regular user or a user that is not connected to an organization
 */
export function isNotPrivateUserEntity(
    user: UserEntity | PrivateUserEntity
): user is UserEntity {
    return 'customerIds' in user;
}

export type Credentials = {
    allowed: string[];
    customerId: number;
    id: number;
    userId: number;
};
