import { Dispatcher, BaseStore } from 'Core';
import Constants from '../Constants';
import { defaultBranding } from 'Branding/Constants';
import assign from 'object-assign';

function _getCustomerId() {
    let id = localStorage.getItem('branding-customer-id');

    if (id) {
        return parseInt(id, 10);
    }

    return null;
}

// Data Storage
let _branding = {};

function updateBranding(branding) {
    assign(_branding, branding);
}

function fetchBranding(branding) {
    _branding = branding;
}

function applyDefaultBranding() {
    _branding = assign({}, defaultBranding);
}

// Flux Store Creation
const BrandingStore = assign({}, BaseStore, {
    getBranding() {
        return _branding;
    },

    getLocalCustomerId() {
        return _getCustomerId();
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.BRANDING_UPDATED:
                if (action.branding) {
                    updateBranding(action.branding);
                }

                break;
            case Constants.ActionTypes.FETCH_BRANDING:
                if (action.branding && action.customerId) {
                    fetchBranding(action.branding, action.customerId);
                }

                break;
            case Constants.ActionTypes.APPLY_DEFAULT_BRANDING:
                applyDefaultBranding();
                break;
            default:
                return false;
        }

        BrandingStore.emitChange();
    }),
});

export default BrandingStore;
