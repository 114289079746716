import { createComponent } from '@lit/react';
import { Body } from '@penneo/vega-ui';
import React from 'react';

const UIModalBody = createComponent({
    tagName: 'vega-modal-body',
    elementClass: Body,
    react: React,
});

export default UIModalBody;
