import React from 'react';
import { convert } from '../utils/convert-casefile-type';
import { BetterFlowOverview } from '../components/BetterFlowOverview';
import { CaseFileType } from 'types/CaseFile';

type Props = {
    onClose: () => void;
    casefileType?: CaseFileType;
};

type State = {
    error: any;
};

export class BetterFlowPage extends React.Component<Props, State> {
    state: State = {
        error: null,
    };

    render() {
        if (!this.props.casefileType) {
            return null;
        }

        return (
            <div className="better-flow-page">
                <BetterFlowOverview
                    onClose={this.props.onClose}
                    casefileType={convert(this.props.casefileType)}
                />
            </div>
        );
    }
}
