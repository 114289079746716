import { AppThunk } from 'Store';
import { ActionTypes } from './action-types';
import { ApiResponse } from 'Api/ApiClient';
import { TrashRow } from './types';

export const resetTrash = (): AppThunk => (dispatch) => {
    dispatch({ type: ActionTypes.TRASH_RESET });
};

export type TrashFetchParams = {
    per_page: number;
    page: number;
    search_phrase?: string;
};

export const fetchTrash = (
    params: TrashFetchParams
): AppThunk<Promise<ApiResponse>> => async (dispatch, _, { api }) => {
    dispatch({ type: ActionTypes.TRASH_FETCH_REQUEST });
    dispatch({ type: ActionTypes.TRASH_API_REQUEST });

    try {
        const { data, count } = await api.SigningAPI.get('/trash', params, {
            paginate: true,
        });

        dispatch({
            type: ActionTypes.TRASH_FETCH_SUCCESS,
            payload: {
                trashList: data,
                itemCount: count,
                selectedRows: [],
            },
        });
    } catch (error) {
        dispatch({ type: ActionTypes.TRASH_FETCH_FAILURE });
        dispatch({
            type: ActionTypes.TRASH_API_FAILURE,
            payload: error,
        });
    }
};

export const restoreItems = (
    itemIds: number[] = [],
    params: TrashFetchParams
): AppThunk<Promise<ApiResponse>> => async (dispatch, _, { api }) => {
    dispatch({ type: ActionTypes.TRASH_RESTORE_REQUEST });
    dispatch({ type: ActionTypes.TRASH_API_REQUEST });

    try {
        let payload = itemIds.join(','); // Comma separated list.

        await api.SigningAPI.post(`/trash/casefiles/${payload}/restore`);

        dispatch({
            type: ActionTypes.TRASH_RESTORE_SUCCESS,
        });

        dispatch(fetchTrash(params));
    } catch (error) {
        dispatch({ type: ActionTypes.TRASH_RESTORE_FAILURE });
        dispatch({
            type: ActionTypes.TRASH_API_FAILURE,
            payload: error,
        });
    }
};

export const restoreAllItems = (): AppThunk<Promise<ApiResponse>> => async (
    dispatch,
    _,
    { api }
) => {
    dispatch({ type: ActionTypes.TRASH_RESTORE_REQUEST });
    dispatch({ type: ActionTypes.TRASH_API_REQUEST });

    try {
        await api.SigningAPI.post(`/trash/restoreall`);

        dispatch({
            type: ActionTypes.TRASH_RESTORE_ALL_SUCCESS,
        });
    } catch (error) {
        dispatch({ type: ActionTypes.TRASH_RESTORE_FAILURE });
        dispatch({
            type: ActionTypes.TRASH_API_FAILURE,
            payload: error,
        });
    }
};

export const deleteItems = (
    itemIds: number[] = [],
    params: TrashFetchParams
): AppThunk<Promise<ApiResponse>> => async (dispatch, _, { api }) => {
    dispatch({ type: ActionTypes.TRASH_DELETE_REQUEST });
    dispatch({ type: ActionTypes.TRASH_API_REQUEST });

    try {
        let payload = itemIds.join(','); // Comma separated list.

        await api.SigningAPI.post(`/trash/casefiles/${payload}/forcedelete`);

        dispatch({
            type: ActionTypes.TRASH_DELETE_SUCCESS,
        });

        dispatch(fetchTrash(params));
    } catch (error) {
        dispatch({ type: ActionTypes.TRASH_DELETE_FAILURE });
        dispatch({
            type: ActionTypes.TRASH_API_FAILURE,
            payload: error,
        });
    }
};

export const deleteAllItems = (): AppThunk<Promise<ApiResponse>> => async (
    dispatch,
    _,
    { api }
) => {
    dispatch({ type: ActionTypes.TRASH_DELETE_REQUEST });
    dispatch({ type: ActionTypes.TRASH_API_REQUEST });

    try {
        await api.SigningAPI.delete(`/trash/empty`);

        dispatch({
            type: ActionTypes.TRASH_DELETE_ALL_SUCCESS,
        });
    } catch (error) {
        dispatch({ type: ActionTypes.TRASH_DELETE_FAILURE });
        dispatch({
            type: ActionTypes.TRASH_API_FAILURE,
            payload: error,
        });
    }
};

export const manageSelectedRow = (row: TrashRow): AppThunk => (
    dispatch,
    getState
) => {
    const reduxState = getState();
    const selectedRows: TrashRow[] = Object.assign(
        [],
        reduxState.trash.selectedRows
    );

    let matchIndex = -1;

    for (let i = selectedRows.length - 1; i >= 0; i--) {
        if (selectedRows[i].id === row.id) {
            matchIndex = i;
        }
    }

    if (matchIndex === -1) {
        selectedRows.push(row);
    } else {
        selectedRows.splice(matchIndex, 1);
    }

    dispatch({
        type: ActionTypes.TRASH_SELECTED_ROW,
        payload: {
            selectedRows,
        },
    });
};
