import { SigningAPI } from 'Api';

const customerPreferenceActions = {
    fetchPreferences: async (tag = 'preferences') => {
        try {
            const response = await SigningAPI.get(`/customer/options/${tag}`);

            return response || {};
        } catch (e) {
            return {};
        }
    },

    saveNewPreferences: async (data, tag = 'preferences') => {
        try {
            await SigningAPI.put(`/customer/options/${tag}`, data);
        } catch (e) {
            return await Promise.reject(false);
        }
    },
};

export default customerPreferenceActions;
