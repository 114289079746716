import TranslationStore from './stores/TranslationStore';
import { LANGUAGES } from './Constants';

// Gets the dictionary for a set language.
function getDictionary(languageCode) {
    return LANGUAGES[languageCode].dictionary;
}

function translate(term) {
    if (!term) {
        return false;
    }

    let language = TranslationStore.getLanguage();

    // If language is not set, return original string.
    if (!language) {
        return term;
    }

    // If language doesn't exist in available languages, return original string.
    if (!LANGUAGES[language]) {
        return term;
    }

    // Get Dictionary from Language
    let dictionary = getDictionary(language);

    // If language doesn't have a dictionary, return original string.
    if (!dictionary) {
        return term;
    }

    // Remove duplicate trailing spaces, leading spaces and newlines before lookup
    let trimmedTerm = term.replace(/\s\s+/g, ' ');

    // get term from dictionary, if it doesn't exist, return original string.
    return dictionary[trimmedTerm] || term;
}

export default translate;
