import React, { MutableRefObject } from 'react';
import classnames from 'classnames';
import './button.scss';
import Spinner from '../Loaders/Spinner';

type CommonProps = {
    /**
     * Determines the color of the button
     *  - Defaults to gray
     */
    theme?: 'blue' | 'green' | 'red' | 'gray' | 'white';
    /**
     * Determines the size of the button
     *  - If `undefined`. It renders the button with the default size
     */
    size?: 'small' | 'large';
    /**
     * Full className of the icons to render. Renders inside <i> HTML element
     * @example: icon="far fa-check"
     */
    icon?: string;
    /**
     * Match the parent's width. (100% width)
     */
    fullWidth?: boolean;
    /**
     * Render the button as a <span> element
     * Useful for cases where nesting a <button> element is invalid DOM
     * @example Rendering a button inside a file input.
     */
    renderAsSpan?: boolean;
    renderIconLeft?: boolean;
    /**
     * Remove padding (useful for text buttons)
     */
    noPadding?: boolean;

    /**
     * Loading state to display loading ui in button
     */
    isLoading?: boolean;
    loadingText?: string;
} & Partial<React.ButtonHTMLAttributes<HTMLButtonElement>>;

type TruncateProps =
    | {
          /**
           * Determines the type of button to render
           *  - 'solid' renders a button with background color (default)
           *  - 'outline' renders a bordered button
           *  - 'text' renders a button without border
           */
          variant?: 'solid' | 'outline' | 'text';
          disabled?: boolean;
      }
    | { variant: 'text' };

export type Props = CommonProps & TruncateProps;

const Button: React.FC<Props> = React.forwardRef(
    (
        props: Props,
        ref: React.Ref<HTMLButtonElement> | React.Ref<HTMLSpanElement>
    ) => {
        const {
            icon,
            size,
            fullWidth,
            renderIconLeft,
            renderAsSpan = false,
            variant = 'solid',
            theme = 'gray',
            noPadding,
            className,
            children,
            disabled,
            onClick,
            isLoading = false,
            loadingText = 'Loading...',
            ...jsxProps // Forward all the standard JSX props to the element
        } = props;

        const classes = classnames(
            'button',
            `button-${theme}`,
            `button-${variant}`,
            className,
            {
                'button-small': size === 'small',
                'button-large': size === 'large',
                'button-full-width': fullWidth,
                'render-icon-left': renderIconLeft,
                'no-padding': noPadding,
            }
        );

        const params = {
            'aria-disabled': disabled,
            onClick: !disabled ? onClick : (e: any) => e?.preventDefault(),
        };

        const renderButtonContent = (): React.ReactNode => {
            return (
                <>
                    {children}
                    {icon && <i className={'button-icon ' + icon} />}
                </>
            );
        };

        if (renderAsSpan) {
            return (
                <span
                    role="button"
                    className={classes}
                    {...params}
                    {...jsxProps}
                    ref={ref as MutableRefObject<HTMLSpanElement>}>
                    {isLoading ? <Spinner /> : renderButtonContent()}
                </span>
            );
        }

        return (
            <button
                className={classes}
                {...params}
                {...jsxProps}
                ref={ref as MutableRefObject<HTMLButtonElement>}>
                {isLoading ? (
                    <Spinner loadingText={loadingText} />
                ) : (
                    renderButtonContent()
                )}
            </button>
        );
    }
);

export default Button;
