import PropTypes from 'prop-types';
import React from 'react';

export default class HeaderLink extends React.Component {
    static propTypes = {
        highlight: PropTypes.string,
        children: PropTypes.object,
        color: PropTypes.string,
    };

    state = {
        color: this.props.color,
    };

    componentWillReceiveProps(props) {
        this.setState({ color: props.color });
    }

    onMouseEnter = () => {
        this.setState({ color: this.props.highlight });
    };

    onMouseLeave = () => {
        this.setState({ color: this.props.color });
    };

    render() {
        let { children } = this.props;
        let { color } = this.state;

        return (
            <span
                className="option"
                style={{ color: color }}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}>
                {children}
            </span>
        );
    }
}
