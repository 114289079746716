import React from 'react';
import { i18n } from 'Language';
import { Link } from 'react-router';
import Button from 'Common/components/Button';
import { Route } from 'types/Router';

import './start-work-widget.scss';
import classnames from 'classnames';

type Props = {
    link: string | Route;
    icon: string;
    title: string;
    subtitle: string;
    buttonLabel: string;
    buttonIcon: string;
    onClick: Function;
    disabled: boolean;
};

const StartWorkWidgetCTA = ({
    link,
    icon,
    title,
    subtitle,
    buttonLabel,
    buttonIcon,
    onClick,
    disabled,
}: Props) => (
    <div
        className={classnames('start-work-widget-cta', {
            'is-disabled': disabled,
        })}
        data-testid={`start-cta`}>
        <Link
            to={!disabled ? link : undefined}
            onClick={!disabled ? onClick : undefined}
            data-testid={title}
            aria-label={`${i18n`Create new`} ${i18n(title)} - ${i18n(
                subtitle
            )}`}>
            <div className="start-work-widget-cta-image">
                <i className={`fal fa-${icon}`}></i>
            </div>
            <h3 className="start-work-widget-cta-title">{i18n(title)}</h3>
            <p
                id={`desc-action-${title.toLowerCase().replace(/\s/g, '')}`}
                className="start-work-widget-cta-subtitle">
                {i18n(subtitle)}
            </p>
            <div className="start-work-widget-cta-button">
                <Button
                    title={`${i18n`Create new`} ${`${i18n(
                        title
                    )}`.toLowerCase()}`}
                    theme="blue"
                    icon={`far fa-${buttonIcon}`}
                    variant="text"
                    renderIconLeft={true}
                    disabled={disabled}>
                    <span>{i18n(buttonLabel)}</span>
                </Button>
            </div>
        </Link>
    </div>
);

export default StartWorkWidgetCTA;
