import storage from './Storage';

const storageKey = 'penneo-ui-state';

const uiState = {
    set: (key, value) => {
        let data = JSON.parse(storage.get(storageKey));

        if (data === null || typeof data === undefined) {
            data = {};
        }

        data[key] = value;

        return storage.set(storageKey, JSON.stringify(data));
    },

    get: (key: string, defaultValue: any = null) => {
        const data = JSON.parse(storage.get(storageKey));

        if (data === null || typeof data === undefined) {
            return defaultValue;
        }

        if (data[key] === null || typeof data[key] === undefined) {
            return defaultValue;
        }

        return data[key];
    },
};

export default uiState;
