import PropTypes from 'prop-types';
import React from 'react';
import { BrandingActions } from 'Branding';

export default class BootstrapBranding extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        params: PropTypes.object,
    };

    componentWillMount() {
        let { params } = this.props;

        if (params.workflowToken) {
            return this.loadWorkflowBranding(params.workflowToken);
        }

        this.loadBranding();
    }

    loadBranding = () => {
        let { user } = this.props;

        // Load User Customers' Branding
        if (!!user?.customerIds?.length) {
            let customerId = user.customerIds[0]; // @todo: make customerID dynamic

            return BrandingActions.fetchBranding(customerId);
        }

        // Get Default Branding
        BrandingActions.applyDefaultBranding();
    };

    loadWorkflowBranding = (workflowToken) => {
        BrandingActions.fetchWorkflowBranding(workflowToken);
    };

    render() {
        return false;
    }
}
