import { find } from 'lodash';
import { SSN } from 'types/SSN';
import { SSNs } from 'Constants';

export const SEPARATOR = ';';

export function isSSNValid(
    ssn: string,
    typeId: SSN['id'] = SSNs.LEGACY_SSN.id
): boolean {
    typeId = typeId || SSNs.LEGACY_SSN.id;

    if (typeId === 'sms') {
        return true; // If we use sms for access control the phone number will be validated in SmsInput.tsx
    }

    return getSsnByType(typeId).isValid(ssn);
}

export function formatSSN(
    ssn: string | number,
    typeId: SSN['id'] = SSNs.LEGACY_SSN.id
): string {
    typeId = typeId || SSNs.LEGACY_SSN.id;

    if (typeId === 'sms') {
        return ssn as string;
    }

    return getSsnByType(typeId).format(ssn.toString());
}

export function aggregateSSN(
    ssn: string | number = '',
    typeId: SSN['id'] = SSNs.LEGACY_SSN.id
): string {
    typeId = typeId || SSNs.LEGACY_SSN.id;

    return `${typeId}${SEPARATOR}${ssn}`;
}

export type SsnEntity = { typeId: SSN['id']; ssn: string };

export function splitAggregatedSSN(
    aggregatedSsn: string
): SsnEntity | undefined {
    if (!aggregatedSsn) {
        return;
    }

    const [typeId, ssn] = aggregatedSsn.split(SEPARATOR);

    return {
        typeId: typeId || SSNs.LEGACY_SSN.id,
        ssn,
    };
}

/**
 * This function removes the dash from an SSN (to prevent issues when validating)
 * NOTE: Finnish SSNs MUST keet the dash
 */
export const cleanAggregatedSSN = (aggregatedSsn: string) => {
    const { typeId, ssn } = splitAggregatedSSN(aggregatedSsn) as SsnEntity;
    const cleanSSN = typeId === SSNs.FI_PIC.id ? ssn : ssn.replace('-', '');

    return aggregateSSN(cleanSSN, typeId);
};

export function isLegacySSN(typeId: SSN['id']) {
    return typeId === SSNs.LEGACY_SSN.id;
}

export function getSsnByType(typeId: string): SSN {
    return find(SSNs, (ssn: SSN) => ssn.id === typeId);
}
