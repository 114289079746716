import PropTypes from 'prop-types';
import React from 'react';
import Tab from './Tab';

export default class Tabs extends React.Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array),
    };

    state = {
        activeTab: 0,
    };

    onClickTabItem = (tabIndex) => {
        this.setState({ activeTab: tabIndex });
    };

    render() {
        const { activeTab } = this.state;
        const { children } = this.props;

        const tabs = children?.filter((children) => !!children);
        const tabWidth = 100 / tabs?.length + '%' ?? `100%`;

        return (
            (tabs && (
                <div className="tabs">
                    <ul className="tabs-list">
                        {tabs.map((tab, index) => {
                            return (
                                <Tab
                                    activeTab={activeTab}
                                    width={tabWidth}
                                    key={index}
                                    index={index}
                                    label={tab.props.label}
                                    icon={tab.props.icon}
                                    onClick={this.onClickTabItem}
                                />
                            );
                        })}
                    </ul>

                    <div className="tab-content">
                        {tabs.map((tab, index) => {
                            if (index !== activeTab) {
                                return null;
                            }

                            return tab.props.children;
                        })}
                    </div>
                </div>
            )) ??
            null
        );
    }
}
