/* eslint camelcase:0 */
import { Dispatcher } from 'Core';

const casefileNavigationActions = {
    setInitialRoute(routeName) {
        Dispatcher.handleViewAction({
            type: 'CASEFILE_NAVIGATION_INITIAL_ROUTE',
            routeName: routeName,
        });
    },

    trackNavigation(routeName) {
        Dispatcher.handleViewAction({
            type: 'CASEFILE_NAVIGATION_TRACK',
            routeName: routeName,
        });
    },
};

export default casefileNavigationActions;
