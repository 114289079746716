import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { i18n } from 'Language';
import {
    Breadcrumbs as BreadcrumbsState,
    removeBreadcrumbsByIndexAction,
} from '../redux/reducer';
import './breadcrumbs.scss';

export type Props = {
    breadcrumbs: BreadcrumbsState;
    dispatch: any;
};

export const Breadcrumbs = ({ breadcrumbs, dispatch }: Props) => {
    const handleOnClick = (index: number) => () =>
        dispatch(removeBreadcrumbsByIndexAction(index));

    return (
        <div className="breadcrumbs">
            {breadcrumbs.map((breadcrumb, i) => (
                <span
                    className="breadcrumb-item"
                    key={`${breadcrumb.title}-${i}`}>
                    <Link
                        className="breadcrumb-item-title"
                        to={breadcrumb.route}
                        onClick={handleOnClick(i)}>
                        {i === 0 && (
                            <span>
                                <i className="fas fa-home" />
                                &nbsp;
                            </span>
                        )}
                        {i18n(breadcrumb.title)}
                    </Link>

                    <span className="breadcrumb-item-arrow">
                        <i className="fas fa-caret-right" />
                    </span>
                </span>
            ))}
        </div>
    );
};

export default connect()(Breadcrumbs);
