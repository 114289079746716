import { EmailTemplates } from 'types/EmailTemplates';
import { KYCMessageValidation } from 'types/DataValidation';
import { Languages } from 'Language/Constants';

export type KYCRecipient = {
    name: string;
    email: string;
};

export type KYCSender = {
    name?: string;
    email?: string;
    company?: string;
};

export type KYCOptions = {
    requireAnyPhotoId: boolean;
};

export enum DataFields {
    PASSPORT = 'passport',
    SSN_CARD = 'ssnCard',
    DRIVER_LICENSE = 'driverLicense',
    SSN = 'ssn',
    ADDRESS = 'address',
    PHONE = 'phone',
}

export enum FieldTypes {
    TEXT = 'text',
    FILE = 'file',
}

export type KYCDataItem = {
    value: string;
    isValid?: boolean;
};

export type KYCAttachment = {
    value: File | null;
};

export type KYCRequirementItem = {
    type?: FieldTypes;
    enabled?: boolean;
    required?: boolean;
};

export type KYCRequirements = {
    [key in DataFields]: KYCRequirementItem;
};

export type KYCData = {
    [key in DataFields]: KYCDataItem;
};

export type KYCAttachments = {
    [key in DataFields]: KYCAttachment;
};

export type NewKYC = {
    title: string;
    description: string;
    language: string;
    folderId: number | null;
    recipients: KYCRecipient[];
    requirements: KYCRequirements;
    emailTemplates: EmailTemplates;
    reminderInterval: number;
    validation: {
        emailTemplates: KYCMessageValidation;
    };
};

export type KYCInstance = {
    id?: number;
    title: string;
    description: string;
    language: string;
    recipient: KYCRecipient;
    requirements: KYCRequirements;
    data: KYCData;
    attachments: KYCAttachments;
    sender: KYCSender;
    filledAt?: number;
};

export type KYCActionStates = {
    isLoaded: boolean;
    isFetching: boolean;
    isSending: boolean;
    sent: boolean;
    error?: any;
};

export type KYCState = {
    __new: NewKYC;
    __states: KYCActionStates;
    instance: KYCInstance;
    items: KYCList;
};

export type KYCListItemField = {
    id: number;
    editable: boolean;
    label: string;
    name: string;
    order: number;
    required: boolean;
    type: string;
};

export type KYCListItem = {
    id: number;
    createdAt: number;
    title: string;
    creatorCustomerId: number;
    creatorUserId: number;
    description: string;
    fields: KYCListItemField[];
    language: Languages;
    recipient: {
        name: string;
        email: string;
    };
    sender: {
        name: string;
        email: string;
    };
    caseFileId?: number;
    filledAt?: number;
    status?: string;
};

export type KYCList = {
    data: KYCListItem[];
    count: number;
    isFetching: boolean;
    isLoaded: boolean;
    error: any;
};
