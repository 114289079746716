import React from 'react';
import { i18n } from 'Language';
import { SignerEntity } from 'types/Signer';
import CasefileAttachmentList from '../CasefileAttachmentList';

export type Props = {
    title?: string;
    signer: SignerEntity;
    files: any[];
};

const CasefileAttachmentsSection: React.FunctionComponent<Props> = ({
    title,
    signer,
    files,
}) => {
    return (
        <div>
            <h4 className="casefile-details-section-header">
                {title || i18n`Signers’ attachments`}

                <span className="casefile-details-section-count">
                    {files.length}
                </span>
            </h4>
            <div className="casefile-documents">
                <div className="casefile-documents-container">
                    <CasefileAttachmentList signer={signer} files={files} />
                </div>
            </div>
        </div>
    );
};

export default CasefileAttachmentsSection;
