/* eslint camelcase: 0 */

// @see: https://w3c.github.io/webcrypto/#examples-signing
export const algorithm = {
    name: 'RSASSA-PKCS1-v1_5',
    hash: 'SHA-256',
    modulusLength: 4096,
    publicExponent: new Uint8Array([1, 0, 1]),
};

export enum OIDs {
    OPENID_ISSUER = '2.25.190190',
    OPENID_ID = '2.25.190191',
    OPENID_ID_TOKEN = '2.25.190193',
    OPENID_FULL_NAME_V2 = '2.5.4.3',
    OPENID_EMAIL = '2.25.190195',
    OPENID_ORG_NAME_V2 = '2.5.4.10',

    /**
     * Should be an alphanumeric code that uniquely identifies an organization in a certain context.
     */
    OPENID_ORG_IDENTIFIER = '2.25.190200',

    /**
     * The type of OPENID_ORG_IDENTIFIER. For example VATIN, CVR.
     */
    OPENID_ORG_IDENTIFIER_TYPE = '2.25.190201',

    /**
     * The country code in ISO 3166-1 alpha-3 format. For example DNK, SWE.
     */
    PENNEO_OPENID_COUNTRY_NAME_ISO_3166_1_ALPHA_3 = '2.25.190202',
    /**
     * The type of document. For example:
     * C: Crew member
     * I: Identity card
     * P: Passport
     * V: Visa
     */
    PENNEO_OPENID_DOCUMENT_TYPE = '2.25.190203',
    PENNEO_OPENID_DOCUMENT_NUMBER = '2.25.190204',

    PKCS10_EXTENSIONS = '1.2.840.113549.1.9.14',

    X509_V3_BASIC_CONSTRAINTS = '2.5.29.19',
    X509_V3_SUBJECT_KEY_IDENTIFIER = '2.5.29.14',
    X509_V3_KEY_USAGE = '2.5.29.15',
}

export enum SignTextFormats {
    XML = 'xml',
    HTML = 'html',
}

export enum Intent {
    SIGN = 'sign',
    LOGIN = 'login',
    VALIDATE_ID = 'validate_id',
    ADD = 'add',
    ACTIVATION = 'activation',

    /**
     * This intent will have most of the same capabilities as Intent.SIGN,
     * we want to avoid having to change the current implementation where Intent.SIGN is used
     * and therefore we're introducing a new intent for NAP-specific situations.
     */
    NAP = 'nap',
}

export enum ServiceIDs {
    GOOGLE = 'google',
    MICROSOFT = 'microsoft',
    MITID = 'mitid.dk',
    ITSME = 'itsme.be',
    FTN = 'ftn.fi',
}
