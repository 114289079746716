export enum ActionTypes {
    TRASH_RESET = 'TRASH_RESET',
    TRASH_API_REQUEST = 'TRASH_API_REQUEST',
    TRASH_API_FAILURE = 'TRASH_API_FAILURE',
    TRASH_FETCH_REQUEST = 'TRASH_FETCH_REQUEST',
    TRASH_FETCH_SUCCESS = 'TRASH_FETCH_SUCCESS',
    TRASH_FETCH_FAILURE = 'TRASH_FETCH_FAILURE',
    TRASH_RESTORE_REQUEST = 'TRASH_RESTORE_REQUEST',
    TRASH_RESTORE_SUCCESS = 'TRASH_RESTORE_SUCCESS',
    TRASH_RESTORE_FAILURE = 'TRASH_RESTORE_FAILURE',
    TRASH_DELETE_REQUEST = 'TRASH_DELETE_REQUEST',
    TRASH_DELETE_SUCCESS = 'TRASH_DELETE_SUCCESS',
    TRASH_DELETE_FAILURE = 'TRASH_DELETE_FAILURE',
    TRASH_DELETE_ALL_SUCCESS = 'TRASH_DELETE_ALL_SUCCESS',
    TRASH_RESTORE_ALL_SUCCESS = 'TRASH_RESTORE_ALL_SUCCESS',
    TRASH_SELECTED_ROW = 'TRASH_SELECTED_ROW',
}
