import { Dispatcher } from 'Core';
import { SigningAPI } from 'Api';
import Constants from '../Constants';
import { debug } from 'Core';
import lodash from 'lodash';
import { parseURIString, loadCasefileObject } from '../utils/uri';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';

const decodeOctal = (octal) => {
    const [, ...arr] = octal.split('\\');

    return arr
        .map((char) => {
            return String.fromCharCode(parseInt(char, 8));
        })
        .join('');
};

/**
 * In some cases, the printer outputs a PS file that when converted by the URI processing endpoint
 * (/document/extract/integrationcode) results in the application receiving a filename that cannot be JSON parsed
 * by the desktop application. This function is an attempt to fix the filenames that break the printing process.
 *
 * In some cases, the application receives escaped parentheses which break the JSON parsing. This function converts them
 * into the unescaped version of the character.
 *
 * This function also fixes a problem where the filename will sometimes have an extra parenthesis added at the end (When
 * printing files that contain danish/norwegian/swedish characters). This is fixed by checking whether the parenthesis is
 * the last character and checking whether it matches an opening parenthesis.
 *
 * @param {string} str | document title incoming from URI
 */
const removeEscapedParens = (str) => {
    // Fix escaped parenthesis problem.
    str = str.replace(/\\\(/g, '(');
    str = str.replace(/\\\)/g, ')');

    const lastCharacterIsParenthesis =
        str.substring(str.length - 1, str.length) === ')';

    if (lastCharacterIsParenthesis) {
        // Count opening parenthesis and remove last parenthesis if it doesn't have an opening one.
        const openingParenthesisCount = (str.match(/\(/g) || []).length;
        const closingParenthesisCount = (str.match(/\)/g) || []).length;

        // Remove last parenthesis if there aren't a matching number of parenthesis
        if (closingParenthesisCount !== openingParenthesisCount) {
            str = str.substring(0, str.length - 1);
        }
    }

    return str;
};

const replaceOctalChars = (str) => {
    const isLatin1 = isLatin1Encoded(str);
    let regex = /\\\d\d\d/g;

    // If latin-1, the octal strings are represented in octal pairs. (i.e. \200\245)
    if (isLatin1) {
        regex = /\\\d\d\d\\\d\d\d/g;
    }

    // No octals in string.
    if (!regex.test(str)) {
        return str;
    }

    const decodedChars = str.replace(regex, (a) => decodeOctal(a));

    // If latin-1. convert to UTF-8 before returning.
    if (isLatin1) {
        return decodeURIComponent(escape(decodedChars));
    }

    // If UTF-8, return as-is
    return decodedChars;
};

// Detect latin-1 encoding based on known characters in the charset
// @note: as the server isn't able to tell the client a charset with certainty, we are
// assuming it based on matches of specific characters in the supported languages.
// This isn't a perfect check for all safe filenames with special characters but without this,
// any scandiavian special character would be completely unusable
const isLatin1Encoded = (str) => {
    const patterns = [
        // Danish / Norwegian
        /\\303\\246/, //  - æ
        /\\303\\270/, //  - ø
        /\\303\\245/, //  - å
        /\\303\\206/, //  - Æ
        /\\303\\230/, //  - Ø
        /\\303\\205/, //  - Å

        // Norwegian
        /\\303\\204/, // - Ä
        /\\303\\244/, // - ä
        /\\303\\226/, // - Ö
        /\\303\\266/, // - ö
    ];

    for (let i = 0; i < patterns.length; i++) {
        if (patterns[i].test(str)) {
            return true;
        }
    }

    return false;
};

const uriActionCreators = {
    setNewIntegrationCode(data) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.INTEGRATION_CODE_CHANGED,
            data: data,
        });
    },

    selectTemplate(templateId) {
        Dispatcher.handleViewAction({
            type: 'TEMPLATE_SELECTED',
            data: templateId,
        });
    },

    loadTemplate(templateId) {
        Dispatcher.handleViewAction({
            type: 'TEMPLATE_SET',
            data: templateId,
        });
    },

    // Fixes bug that created initial user preferences case file template without id.

    // Some users stored a preferences object with the following format.
    //  {
    //     data: {},
    //     name: "name",
    //     NaN: {
    //        data: {},
    //        name: "name"
    //     }
    //  }
    //
    //  Which made the template functionality break. This function will fix this format
    //  into a keyed object with numerical order.
    _fixCasefileTemplates(data) {
        // Due to PHP serialization, if the preferences are empty,
        // the backend is returning an array instead of an object.
        let preferences = Array.isArray(data) && data.length === 0 ? {} : data;

        let result = {};

        // If preferences are in correct format.
        if (!preferences.data || !preferences.name || !preferences[NaN]) {
            return Promise.resolve(preferences);
        }

        // Extract correct entries
        Object.keys(preferences).forEach((key, index) => {
            if (!isNaN(key)) {
                result[index + 1] = preferences[key];
            }
        });

        // Add data + name to next entry
        if (preferences.data && preferences.name) {
            result[Object.keys(result).length + 1] = {
                data: preferences.data,
                name: preferences.name,
            };
        }

        // Convert NaN object.
        if (preferences[NaN]) {
            result[Object.keys(result).length + 1] = preferences[NaN];
        }

        return Promise.resolve(result);
    },

    fetchCasefileTemplates() {
        Dispatcher.handleServerAction({
            type: 'CASEFILE_TEMPLATES_FETCH_REQUEST',
        });

        return SigningAPI.get('/user/preferences/casefileTemplates')
            .then((data) => this._fixCasefileTemplates(data.preferences))
            .then((templates) => {
                Dispatcher.handleServerAction({
                    type: 'CASEFILE_TEMPLATES_FETCH_SUCCESS',
                    templates: templates,
                });
            })
            .catch((error) => {
                debug.log(error);

                if (error.status === 404) {
                    return Dispatcher.handleServerAction({
                        type: 'CASEFILE_TEMPLATES_FETCH_SUCCESS',
                        templates: {},
                    });
                }

                Dispatcher.handleServerAction({
                    type: 'CASEFILE_TEMPLATES_FETCH_FAILURE',
                    error: error,
                });
            });
    },

    // ****************************************
    // Templates are stored in user preferences
    // ****************************************

    // Templates are fetched just before being pushed back with new template data thus ID has to be
    // created right after the fetch and before the post. This keeps data consistent with the
    // backend getting rid of using the frontend for data consistency.

    // Template ID creator in actions due to the database state being managed in the frontend
    _createTemplateId(templates) {
        // If the preferences object is empty. Start IDs from 1
        if (Object.keys(templates).length === 0) {
            return 1;
        }

        // Convert object keys to numeric indices, to calculate maximum value
        let templateIds = Object.keys(templates).map((id) => Number(id));

        // Create an ID by 1 int greater than largest key
        return lodash.max(templateIds) + 1;
    },

    resetCasefileTemplates() {
        Dispatcher.handleViewAction({
            type: 'CASEFILE_TEMPLATES_RESET',
        });
    },

    saveCasefileTemplate(templateName, templateData) {
        let endpoint = '/user/preferences/casefileTemplates';

        Dispatcher.handleViewAction({
            type: 'CASEFILE_TEMPLATE_CREATE_REQUEST',
        });

        SigningAPI.get(endpoint)
            .then((data) => {
                return this._fixCasefileTemplates(data.preferences);
            })
            .catch((error) => {
                // If casefile templates preference is not initialized
                if (error.status === 404) {
                    return false;
                }

                throw error;
            })
            .then((templates) => {
                let payload = {
                    preferences: {},
                };

                if (!templates) {
                    // Start with index 1.

                    payload.preferences[1] = {
                        name: templateName,
                        data: templateData,
                    };

                    // Initialize Preferences
                    return SigningAPI.post(endpoint, payload).then(
                        () => payload.preferences
                    );
                }

                // Update current templates object.
                let templateId = this._createTemplateId(templates);

                // Update object in new index.
                templates[templateId] = {
                    name: templateName,
                    data: templateData,
                };

                // Replace default preferences with updated templates
                payload.preferences = templates;

                return SigningAPI.put(endpoint, payload).then(
                    () => payload.preferences
                );
            })
            .then((templates) => {
                Dispatcher.handleServerAction({
                    type: 'CASEFILE_TEMPLATE_CREATE_SUCCESS',
                    templates: templates,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: 'CASEFILE_TEMPLATE_CREATE_FAILURE',
                    error: error,
                });
            });
    },

    loadCasefileTemplate(templateData) {
        let uriData = parseURIString(templateData);

        loadCasefileObject(uriData);

        Dispatcher.handleViewAction({
            type: 'CASEFILE_TEMPLATE_LOAD_SUCCESS',
            data: uriData,
        });
    },

    deleteCasefileTemplate(templates, templateId) {
        Dispatcher.handleViewAction({
            type: 'CASEFILE_TEMPLATE_DELETE_REQUEST',
        });

        // Copy current collection
        let _templatesCopy = lodash.cloneDeep(templates);

        // Remove item
        let _templates = lodash.omit(_templatesCopy, [templateId]); // Exclude element by ID

        let payload = {
            preferences: _templates,
        };

        SigningAPI.post('/user/preferences/casefileTemplates', payload).then(
            () => {
                Dispatcher.handleServerAction({
                    type: 'CASEFILE_TEMPLATE_DELETE_SUCCESS',
                    templates: _templates,
                });
            }
        );
    },

    convertPrinterDocument(data) {
        let payload = {
            document: data.base64,
        };

        SigningAPI.post('/document/extract/integrationcode', payload)
            .then((response) => {
                let title = response.title || data.title || 'Document';

                if (LaunchDarkly.variation(Flags.URI_CLEAN_PARENS)) {
                    title = removeEscapedParens(title);
                }

                title = replaceOctalChars(title);

                Dispatcher.handleServerAction({
                    type: 'DOCUMENT_EXTRACT_URI_SUCCESS',
                    data: {
                        // Print job details
                        jobId: data.jobId,

                        // Response
                        title: title,
                        base64: response.document,
                        uri: response.integrationCode,
                    },
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: 'DOCUMENT_EXTRACT_URI_FAILURE',
                    data: {
                        // Print job details
                        jobId: data.jobId,
                        title: data.title || 'Document',

                        // Response
                        error: error,
                    },
                });
            });
    },

    generateIntegrationCode() {
        Dispatcher.handleViewAction({
            type: 'GENERATE_INTEGRATION_CODE',
        });
    },

    generateTemplateData() {
        Dispatcher.handleViewAction({
            type: 'GENERATE_CASEFILE_TEMPLATE_DATA',
        });
    },
};

export default uriActionCreators;
