import React from 'react';
import PropTypes from 'prop-types';
import { Folders } from 'Common/redux/Folder/types';
import FolderTreeItem from './FolderTreeItem';
import './folder-tree.scss';

type Props = {
    folders: Folders;
    isChild?: boolean;
    onSelect: Function;
    onOpen: Function;
    selectedItem: number | null;
    openItems: number[];
};

class FolderTree extends React.Component<Props> {
    static contextTypes = {
        router: PropTypes.object,
    };

    render() {
        const { folders, openItems, selectedItem } = this.props;
        const topLevel = folders.filter((folder) => !folder.parentId);

        return (
            <ul className="folder-tree">
                {topLevel.map((folder) => (
                    <FolderTreeItem
                        key={folder.id}
                        folder={folder}
                        folders={folders}
                        onOpen={this.props.onOpen}
                        onSelect={this.props.onSelect}
                        openItems={openItems}
                        selectedItem={selectedItem}
                    />
                ))}
            </ul>
        );
    }
}

export default FolderTree;
