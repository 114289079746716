import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { CaseFileType } from 'types/CaseFile';
import { CustomerEntity } from 'types/Customer';
import {
    CasefileMessageValidation,
    CasefileValidation,
    DocumentValidation,
    SignerValidation,
} from 'types/DataValidation';
import { ClientSideDocument, DocumentEntity } from 'types/Document';
import { EmailTemplate } from 'types/EmailTemplates';
import { SignerRole } from 'types/SignerRole';
import { UserEntity } from 'types/User';
import { DEFAULT_LANGUAGE, LANGUAGES } from 'Language/Constants';
import { i18n } from 'Language';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';

import { AppDispatch, ReduxState } from 'Store';
import CasefileActions from 'Casefiles/actions/CasefilesActionCreators';
import CasefileStore from 'Casefiles/stores/CasefileStore';
import DocumentStore from 'Casefiles/stores/DocumentStore';

import Button from 'Common/components/Button';
import { modal } from 'Common/components/Common/Modal';
import FeatureFlag from 'Common/components/FeatureFlag';
import Message from 'Common/components/Message';
import { SidePanel } from 'Common/components/SidePanel';
import { BetterFlowPage } from 'Labs/BetterFlowOverview/views/BetterFlowPage';
import TimeStamp from '../casefiles/TimeStamp';
import CasefileErrorList from './CasefileErrorList';
import CasefileMessages from './CasefileMessages';
import RecipientList from 'Common/components/Recipients/RecipientList';
import RecipientModal, { Recipient } from './RecipientModal';
import { RecipientCopy, RecipientSigner } from 'types/Recipient';
import { NewCasefileState } from 'Casefiles/redux/newCaseFile/types';
import { getColorByIndex } from './utils';
import LaunchDarkly from '../../../Common/LaunchDarkly';
import RecipientModalExtended from './RecipientModalExtended';
import {
    CasefileRoundsDateConfiguration,
    CasefileRoundsFlowActions,
    CasefileRoundsInfo,
    CasefileRoundsTimeline,
} from './CasefileRounds';
import { getSigningRoundsCount } from 'Labs/BetterFlowOverview/utils/convert-casefile-type';
import classNames from 'classnames';
import { CaseFileFromStore } from './types';
import { ReactRouter } from 'types/Router';
import Flag from 'Common/components/Flag';
import CasefileFooterNavigation from './CasefileFooterNavigation';
import { images } from 'Constants';

type Props = {
    sendCasefile: Function;
    sendAtDate: any;
    sendLater: boolean;
    expireAtDate: any;
    expireEnable: boolean;
    messageTitle: string;
    casefile: CaseFileFromStore;
    messageTemplates: EmailTemplate[];
    messageTemplate: EmailTemplate;
    documents: DocumentEntity[];
    uploadedDocuments: DocumentEntity[];
    recipients: Recipient[];
    options: any;
    casefileSendValidation: Function;
    casefileValidation: CasefileValidation;
    documentValidation: DocumentValidation;
    signerValidation: SignerValidation;
    emailTemplatesValidation: CasefileMessageValidation;
    casefileTypes: CaseFileType[];
    linkParams: any;
    saveAsDraftButton: Function;
    saveCasefileDraft: () => void;
    showCasefileDetails: () => void;
    openDocumentPreview: Function;
    user: UserEntity;
    availableSignerTypes: SignerRole[];
    dispatch: AppDispatch;
    customer: CustomerEntity;
    emailMessages: NewCasefileState['emailMessages'];
    router: ReactRouter;
    savingAsDraft: boolean;
    sending: boolean;
};

type State = {
    showFlowDetails: boolean;
};

class CasefileSummaryStep extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    state = {
        showFlowDetails: false,
    };

    getDocumentTypeColor = (id: number) => {
        const types = DocumentStore.getAvailableDocumentTypes();
        const index = types.findIndex((type) => type.id === id);

        return getColorByIndex(index);
    };

    renderDocumentTypeLegend = () => {
        let selectedTypeIds = DocumentStore.getDocumentTypes();

        let types = DocumentStore.getAvailableDocumentTypes().filter(
            (dt) => selectedTypeIds.indexOf(dt.id) !== -1
        );

        return (
            <ul className="document-type-legend">
                {types.map((t) => (
                    <li
                        key={t.id}
                        style={{
                            borderColor: this.getDocumentTypeColor(t.id),
                        }}>
                        <span
                            className="tag-color"
                            style={{
                                backgroundColor: this.getDocumentTypeColor(
                                    t.id
                                ),
                            }}
                        />
                        {t.name}
                    </li>
                ))}
            </ul>
        );
    };

    renderDocumentRow = (doc) => {
        const type = DocumentStore.getDocumentType(doc.documentTypeId);
        const isPdf =
            doc.format === 'pdf' || doc.fileType === 'application/pdf';

        const fileIcon = `${isPdf ? 'pdf' : 'xbrl'}.svg`;

        if (!doc || !type) {
            return false;
        }

        return (
            <div
                key={type.id}
                className="casefile-summary-document-content items-center">
                {type.signerTypes.length > 0 ? (
                    <img
                        height="24"
                        src={`${images}/file-component/${fileIcon}`}
                    />
                ) : (
                    <i className="far fa-paperclip text-dark"></i>
                )}
                &nbsp;
                <span className="casefile-summary-document-name">
                    {doc.name}
                </span>
                &nbsp;
                {type && (
                    <span>
                        <div
                            className="tag-color"
                            style={{
                                backgroundColor: this.getDocumentTypeColor(
                                    type.id
                                ),
                            }}></div>
                    </span>
                )}
            </div>
        );
    };

    getLanguage = (code = DEFAULT_LANGUAGE) => {
        return LANGUAGES[code];
    };

    changeSendAtDate = (date) => {
        CasefileActions.setSendAtDate(date);
    };

    changeExpireAtDate = (date) => {
        CasefileActions.setExpireAtDate(date);
    };

    clearExpireDate = () => {
        CasefileActions.clearExpireDate();
        CasefileActions.expireEnableToggle(false);
    };

    isCasefileValid = () => {
        const {
            casefileValidation,
            documentValidation,
            signerValidation,
            emailTemplatesValidation,
            emailMessages,
        } = this.props;
        let isValid = true;

        if (!casefileValidation.valid) {
            isValid = false;
        }

        Object.keys(documentValidation).forEach((key) => {
            if (!documentValidation[key].valid) {
                isValid = false;
            }
        });

        Object.keys(signerValidation).forEach((key) => {
            if (!signerValidation[key].valid) {
                isValid = false;
            }
        });

        // TODO: This should be revised and potentially removed once the flag
        // SIGNER_SPECIFIC_EMAILS is permanently turned on for all users
        Object.keys(emailTemplatesValidation).forEach((key) => {
            if (
                !emailTemplatesValidation[key].valid &&
                !launchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS)
            ) {
                isValid = false;
            }
        });

        Object.keys(emailMessages.general.validation).forEach((key) => {
            if (
                !emailMessages.general.validation[key].valid &&
                launchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS)
            ) {
                isValid = false;
            }
        });

        emailMessages.custom.forEach((customMessage) => {
            Object.keys(customMessage.validation).forEach((key) => {
                if (!customMessage.validation[key].valid) {
                    isValid = false;
                }
            });
        });

        return isValid;
    };

    enableSendLaterDate = () => {
        let { sendLater } = this.props;

        CasefileActions.clearSendDate();
        CasefileActions.sendLaterToggle();

        if (!sendLater) {
            let timeNow = new Date();
            let hours = timeNow.getHours();
            let minutes = timeNow.getMinutes();

            let defaultSendAtDate = moment(hours + ':' + minutes, 'HH:mm');

            this.changeSendAtDate(defaultSendAtDate);
        }
    };

    enableExpireDate = () => {
        let { expireAtDate, sendAtDate, expireEnable } = this.props;

        CasefileActions.clearExpireDate();
        CasefileActions.expireEnableToggle();

        if (!expireEnable && !expireAtDate && sendAtDate) {
            let expireDefaultDate = moment(
                sendAtDate.format('DD-MM-YYYY') + '12:00',
                'DD-MM-YYYY HH:mm'
            ).add(7, 'days');

            this.changeExpireAtDate(expireDefaultDate);
        } else if (!expireEnable && !expireAtDate) {
            this.changeExpireAtDate(moment('12:00', 'HH:mm').add(7, 'days'));
        }
    };

    onDocumentPreview = () => {
        const { openDocumentPreview, uploadedDocuments } = this.props;
        // Preview first available uploaded document
        const documentId = uploadedDocuments[0].id;

        openDocumentPreview(documentId);
    };

    openRecipientModal = (
        index?: number | null | undefined,
        recipient?: any
    ) => {
        const { casefile, availableSignerTypes } = this.props;

        const RecipientComponent = LaunchDarkly.variation(
            Flags.IMPROVED_RECIPIENT_SETTINGS
        )
            ? RecipientModalExtended
            : RecipientModal;

        const config = {
            component: (
                <RecipientComponent
                    casefile={casefile}
                    availableSignerTypes={availableSignerTypes}
                    index={index}
                    user={this.props.user}
                    recipient={recipient}
                />
            ),
            preventClose: false,
        };

        modal.show(config);
    };

    handleShowFlowDetails = () => {
        if (launchDarkly.variation(Flags.NEW_FLOW_OVERVIEW)) {
            return this.setState({
                showFlowDetails: !this.state.showFlowDetails,
            });
        }

        this.props.showCasefileDetails();
    };

    render() {
        const {
            casefile,
            documents,
            recipients,
            linkParams,
            sendCasefile,
            uploadedDocuments,
            customer,
            savingAsDraft,
            sending,
        } = this.props;
        // Dates
        const {
            sendLater,
            sendAtDate,
            expireEnable,
            expireAtDate,
        } = this.props;
        // Casefile validation
        const {
            casefileValidation,
            documentValidation,
            signerValidation,
        } = this.props;

        const type = CasefileStore.getCaseTypeById(casefile.caseFileTypeId);
        const folder = CasefileStore.getCurrentFolder();
        const language = this.getLanguage(casefile.language);
        const isValid = this.isCasefileValid();
        const hasCopyRecipients = recipients.some(
            (r) => r.type === 'copyrecipient'
        );

        const showRoundsOverview =
            LaunchDarkly.variation(Flags.ACTIVATION_DATE_PER_ROUND) &&
            type &&
            getSigningRoundsCount(type) >= 1;

        return (
            <div
                className={classNames('casefile-process-v2 medium', {
                    'has-rounds': showRoundsOverview,
                })}>
                <FeatureFlag flag={Flags.NEW_FLOW_OVERVIEW}>
                    {this.state.showFlowDetails && (
                        <SidePanel onClose={this.handleShowFlowDetails}>
                            <BetterFlowPage
                                casefileType={type}
                                onClose={this.handleShowFlowDetails}
                            />
                        </SidePanel>
                    )}
                </FeatureFlag>

                <div className="header">
                    <h1>
                        {i18n`We're almost done`}
                        <span className="dot">.</span>
                    </h1>
                    <p>{i18n`Review that all the information is correct before sending your case file`}</p>
                </div>

                <div className="body">
                    {/**
                     * Only show rounds activation editor if this casefile type has multiple rounds.
                     * NOTE: this can/should be restricted by a feature flag if we need to restrict
                     * specific casefile types only
                     */}
                    {showRoundsOverview && (
                        <>
                            <CasefileRoundsInfo
                                casefile={casefile}
                                casefileType={type}
                                folder={folder}
                                language={language}
                                handleShowFlowDetails={
                                    this.handleShowFlowDetails
                                }
                            />

                            <CasefileRoundsTimeline
                                signers={recipients}
                                documents={
                                    (this.props
                                        .documents as unknown) as ClientSideDocument[]
                                }
                                casefileType={type}
                                sendAtDate={sendAtDate}
                                sendLater={sendLater}
                                expireAtDate={expireAtDate}
                                clearExpireDate={this.clearExpireDate}
                            />

                            <CasefileRoundsDateConfiguration
                                customer={customer}
                                sendAtDate={sendAtDate}
                                expireAtDate={expireAtDate}
                                enableExpireDate={this.enableExpireDate}
                                changeExpireAtDate={this.changeExpireAtDate}
                                clearExpireDate={this.clearExpireDate}
                            />
                        </>
                    )}

                    {!showRoundsOverview && (
                        <>
                            <h4 className="casefile-details-section-header mt0">
                                {i18n`Details`}
                            </h4>

                            <div className="columns">
                                <div className="column-2">
                                    <label>{i18n`Title`}</label>
                                    {casefile.title || (
                                        <span className="text-warning">
                                            <i className="fas fa-exclamation-triangle" />
                                            &nbsp;{i18n`You must add a title`}
                                        </span>
                                    )}

                                    <label>
                                        {i18n`Signing flow`}&nbsp;
                                        {type && (
                                            <i
                                                className="far fa-question-circle text-blue cursor-pointer"
                                                onClick={
                                                    this.handleShowFlowDetails
                                                }
                                            />
                                        )}
                                    </label>
                                    <span className="text-blue">
                                        <i className="far fa-tag"></i>
                                        &nbsp;
                                        {type ? type.name : '--'}
                                    </span>

                                    {casefile.reference && (
                                        <div className="mt">
                                            <label>{i18n`Reference`}</label>
                                            {casefile.reference}
                                        </div>
                                    )}
                                </div>

                                <div className="column-2">
                                    <label>{i18n`Folder`}</label>
                                    <div>
                                        <i className="far fa-folder" />
                                        &nbsp;
                                        {folder ? folder.title : '--'}
                                    </div>

                                    {casefile.language && (
                                        <>
                                            <label>{i18n`Language`}</label>
                                            <Flag
                                                src={
                                                    LANGUAGES[
                                                        casefile.language as string
                                                    ].svgFlag
                                                }
                                                label={
                                                    LANGUAGES[casefile.language]
                                                        .name
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            </div>

                            <h4 className="casefile-details-section-header">
                                {i18n`Documents`}

                                <span className="casefile-details-section-count">
                                    {documents.length}
                                </span>

                                {documents.length > 0 && (
                                    <Button
                                        className="pull-right"
                                        theme="blue"
                                        variant="text"
                                        icon="far fa-eye"
                                        style={{
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        }}
                                        disabled={uploadedDocuments.length <= 0}
                                        onClick={this.onDocumentPreview}>
                                        {i18n`Preview your documents`}
                                    </Button>
                                )}
                            </h4>

                            {documents.length ? (
                                <>
                                    <div className="box-gray casefile-summary-documents">
                                        {documents.map((d, i) => (
                                            <div
                                                key={i}
                                                className="casefile-summary-document">
                                                {this.renderDocumentRow(d)}
                                            </div>
                                        ))}
                                    </div>
                                    {this.renderDocumentTypeLegend()}
                                </>
                            ) : (
                                <h4 className="box-gray text-center">
                                    {i18n`You still haven't added any documents`}
                                </h4>
                            )}

                            <h4 className="casefile-details-section-header">
                                {i18n`Recipients`}

                                <span className="casefile-details-section-count">
                                    {recipients.length}
                                </span>
                            </h4>
                            <RecipientList
                                recipients={
                                    (recipients as unknown) as
                                        | RecipientSigner[]
                                        | RecipientCopy[]
                                }
                                className="box-gray"
                                onItemClick={this.openRecipientModal}
                                noData={
                                    <h4 className="box-gray text-center">
                                        {i18n`You still haven't added any recipients`}
                                    </h4>
                                }
                                casefileLanguage={casefile.language}
                            />

                            {hasCopyRecipients &&
                                DocumentStore.isDocsSizeOverCopyEmailThreshold() && (
                                    <Message type="warning">
                                        <span>{i18n`Please note! The total size of documents exceeds 7Mb which means we can’t send a copy of the finalized document in the email`}</span>
                                    </Message>
                                )}

                            {!launchDarkly.variation(
                                Flags.SIGNER_SPECIFIC_EMAILS
                            ) && (
                                <CasefileMessages
                                    language={this.props.casefile.language}
                                />
                            )}

                            <h4 className="casefile-details-section-header">
                                {i18n`Security & privacy`}
                            </h4>

                            <div className="inline-block align-top mr">
                                <label className="mt0">
                                    {i18n`Sensitive data protection`}
                                </label>
                                <div>
                                    {casefile.sensitiveData ? (
                                        <span>
                                            <i className="far fa-lock-alt" />
                                            &nbsp;
                                            {i18n`Enabled`}
                                        </span>
                                    ) : (
                                        <span>
                                            <i className="far fa-lock-open-alt" />
                                            &nbsp;
                                            {i18n`Disabled`}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="inline-block align-top ml">
                                <label style={{ marginTop: 0 }}>
                                    {i18n`Document visibility`}
                                </label>
                                <div>
                                    {casefile.visibilityMode === 0 ? (
                                        <span>
                                            <i className="far fa-eye" />
                                            &nbsp;
                                            {i18n`Recipients can see all documents when signing`}
                                        </span>
                                    ) : (
                                        <span>
                                            <i className="far fa-eye-slash" />
                                            &nbsp;
                                            {i18n`Recipients can only see documents they have to sign`}
                                        </span>
                                    )}
                                </div>
                            </div>

                            {casefile.signOnMeeting && (
                                <div className="mt">
                                    <label>{i18n`Sign at a meeting`}</label>
                                    <i className="fas fa-check text-cf-blue" />
                                    &nbsp;
                                    {i18n`You can guide the signing process as a host`}
                                </div>
                            )}

                            <h4 className="casefile-details-section-header">
                                {i18n`Configuration`}
                            </h4>

                            <div className="date-selector">
                                {!sendLater && (
                                    <span
                                        className="switch-on"
                                        onClick={this.enableSendLaterDate}>
                                        {i18n('Send later')}&nbsp;
                                        <i className="far fa-plus" />
                                    </span>
                                )}

                                {sendLater && (
                                    <div>
                                        <label>{i18n`Send date`}</label>
                                        <div className="inline-block align-top">
                                            <TimeStamp
                                                type="sendAt"
                                                sendAtDate={sendAtDate}
                                                expireAtDate={expireAtDate}
                                                sendAtPlaceholder="Date to send case file"
                                                onChange={this.changeSendAtDate}
                                                clearExpireDate={
                                                    this.clearExpireDate
                                                }
                                                enabled={true}
                                            />
                                        </div>
                                        &nbsp;
                                        <div
                                            className="switch-off inline-block align-top"
                                            onClick={this.enableSendLaterDate}>
                                            {i18n`Remove`}&nbsp;
                                            <i className="far fa-times" />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="date-selector">
                                {!expireEnable && (
                                    <span
                                        className="switch-on"
                                        onClick={this.enableExpireDate}>
                                        {i18n('Expire at')}
                                        &nbsp;
                                        {customer.caseFileExpiryInterval ? (
                                            <>
                                                {moment
                                                    .unix(
                                                        casefile.createdAt ||
                                                            moment().unix()
                                                    )
                                                    .add(
                                                        customer.caseFileExpiryInterval,
                                                        'days'
                                                    )
                                                    .format('DD-MM-YYYY HH:mm')}
                                                &nbsp;
                                                <i className="far fa-pencil" />
                                            </>
                                        ) : (
                                            <>
                                                <i className="far fa-plus" />
                                            </>
                                        )}
                                    </span>
                                )}

                                {expireEnable && (
                                    <div>
                                        <label>{i18n`Expiry date`}</label>
                                        <div className="inline-block align-top">
                                            <TimeStamp
                                                type="expireAt"
                                                sendAtDate={sendAtDate}
                                                expireAtDate={expireAtDate}
                                                sendAtPlaceholder="Date to send case file"
                                                onChange={
                                                    this.changeExpireAtDate
                                                }
                                                clearExpireDate={
                                                    this.clearExpireDate
                                                }
                                                enabled={true}
                                            />
                                        </div>
                                        &nbsp;
                                        <div
                                            className="switch-off inline-block align-top"
                                            onClick={this.enableExpireDate}>
                                            {i18n`Remove`}&nbsp;
                                            <i className="far fa-times" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>

                {!isValid && (
                    <div className="body">
                        <h4 className="casefile-details-section-header mt0">
                            {i18n`You need to resolve the following issues before sending your documents`}
                        </h4>

                        <CasefileErrorList
                            casefileValidation={casefileValidation}
                            documentValidation={documentValidation}
                            signerValidation={signerValidation}
                        />
                    </div>
                )}

                {showRoundsOverview && (
                    <CasefileRoundsFlowActions
                        saveCasefileDraft={this.props.saveCasefileDraft}
                        sendCasefile={sendCasefile as () => void}
                        router={this.props.router}
                        backRoute={{
                            name: 'casefile-recipients',
                            params: linkParams,
                        }}
                        isValid={isValid}
                    />
                )}

                {!showRoundsOverview && (
                    <CasefileFooterNavigation
                        currentRoute="summary"
                        saveAsDraftButton={this.props.saveAsDraftButton}
                        linkParams={linkParams}
                        backLink="casefile-recipients"
                        isLastStep={true}
                        isFormValid={isValid}
                        sendCasefile={sendCasefile}
                        savingAsDraft={savingAsDraft}
                        sending={sending}
                    />
                )}
            </div>
        );
    }
}

export default connect((state: ReduxState) => ({
    emailMessages: state.newCasefile.emailMessages,
    emailTemplatesValidation: state.newCasefile.validation.emailTemplates,
    customer: state.customer.details.data,
}))(CasefileSummaryStep);
