// Actions
import CasefileActions from '../actions/CasefilesActionCreators';
import SignerActions from '../actions/SignerActionCreators';
import DocumentActions from '../actions/DocumentActionCreators';
import MessageTemplateActions from '../actions/MessageTemplateActionCreators';
import schemaV3 from '../schemas/integration_v3.0.0.json';
import lodash from 'lodash';
import Ajv from 'ajv';
import produce from 'immer';
import { URISchemaV3 } from 'types/URISchema';
import { SSNs } from 'Constants';

async function loadCasefileObject(data) {
    const _promises = [
        // Update general information about the casefile - it's type, name and so on
        CasefileActions.URIUpdateCasefileStore(data),
        // Add documents that arrive via URI
        DocumentActions.URIUpdateDocumentStore(data.documents),
        // Add casefile signers
        SignerActions.URIUpdateSignerStore(data.signers),
        // Extract message template
        MessageTemplateActions.populateStore(data),
    ];

    return Promise.all(_promises);
}

function getValidationErrors(data: URISchemaV3, schema = schemaV3) {
    const validator = new Ajv({ allErrors: true });
    const validate = validator.compile(schema);
    const isValid = validate(data);

    if (isValid) {
        return false;
    }

    return validate.errors;
}

/**
 * Data paths from AJV are prepended by a dot (.) and to be able to access data
 * from the payload using lodash unset / get, we need to remove the leading dot (.)
 *
 * Removing the leading dot also makes the console messages cleaner.
 *
 * @param dataPath string - path to property in AJV validations.
 */
function extractDataPath(dataPath) {
    return dataPath.substring(1, dataPath.length);
}

function cleanInvalidProperties(data, validationErrors: Ajv.ErrorObject[]) {
    return produce(data, (draft) => {
        validationErrors.forEach((error) => {
            lodash.unset(draft, extractDataPath(error.dataPath));
        });
    });
}

function outputValidationWarnings(validationErrors: Ajv.ErrorObject[]) {
    console.warn('URI was loaded with the following warnings:');
    validationErrors.forEach((error) => {
        const path = extractDataPath(error.dataPath);

        // If property is an enum, append the allowed values for a better error message
        if (error.keyword === 'enum') {
            error.message += ` (${(error.params as any).allowedValues})`;
        }

        console.warn(path, error.message);
    });

    console.warn('(All invalid fields were skipped when loading the data)');
}

/**
 * Parses the data so it can be added to app's stores
 * Data being extracted in accordance to JSON schema version that has been used to create the data set.
 */
function parseURIString(data) {
    switch (data.schemaVersion) {
        /**
         * Version 3.0.0 of the URI schema is not validated in the desktop application
         * Therefore, we are performing the validation in the web application, in a way that
         * the whole JSON payload is not rejected entirely. We validate the payload against the
         * schema, and only discard the invalid properties before loading the data.
         *
         * The validation messages will also be printed to console to aid developers building
         * integrations
         */
        case '3.0.0':
            const validationErrors = getValidationErrors(data, schemaV3);

            if (validationErrors) {
                outputValidationWarnings(validationErrors);
                data = cleanInvalidProperties(data, validationErrors);
            }

            return extractV3Data(data);
        case '2.0.0':
            return extractV2Data(data);
        default:
            return extractV1Data(data);
    }
}

function _lowerCaseKeys(data) {
    let result = {};

    for (let key in data) {
        if (!data.hasOwnProperty(key)) {
            continue;
        }

        let value = data[key];

        // @note: This makes the keys of objects nested in arrays lowercase, recursively.
        // This is required to make the documents and signers array lowercase.
        if (Array.isArray(data[key])) {
            value = data[key].map((k) =>
                _lowerCaseKeys(k)
            ); /* eslint no-loop-func:0 */
        }

        result[key.toLowerCase()] = value;
    }

    return result;
}

function extractV1Data(uriData: any) {
    let signers;
    let documents;
    let copyRecipients;

    // Convert all keys to lowercase recurisvely to support case insensitivity in JSON schema,
    let data: any = _lowerCaseKeys(uriData);

    if (data.documents) {
        documents = data.documents
            .map((doc) => {
                return {
                    name: doc.name,
                    typeId: doc.type,
                    localPath: doc.localpath,
                    metaData: doc.metadata,
                };
            })
            .filter((doc) => {
                return !!doc.localPath; // Exclude documents that don't have a path
            });
    }

    if (data.signers) {
        signers = data.signers.map((signer) => {
            return {
                name: signer.name,
                email: signer.email,
                onBehalfOf: signer.onbehalfof,
                ssn: signer.ssn,
                ssnType: signer.ssnType ?? SSNs.LEGACY_SSN.id,
                vatin: signer.vatid,
                isPrivate: signer.private,
                reminderInterval: signer.reminderinterval,
                roles: signer.roles,
            };
        });
    }

    if (data.copyrecipients) {
        copyRecipients = data.copyrecipients.map((recipient) => {
            return {
                name: recipient.name,
                email: recipient.email,
            };
        });
    }

    return {
        templateId: data.template,
        name: data.name,
        folderId: data.folder,
        visibilityMode: data.visibilitymode,
        documents: documents,
        signers: signers,
        copyRecipients: copyRecipients,
        messageTemplate: data.messagetemplate,
        messageSubject: data.messagesubject,
        messageText: data.messagetext,
        clearData: data.cleardata,
        metaData: data.metadata,
    };
}

function extractV3Data(data: URISchemaV3) {
    const { caseFile } = data;

    if (!caseFile) {
        return;
    }

    const {
        documents = [],
        signers = [],
        copyRecipients = [],
        messages = {},
    } = caseFile;

    return {
        templateId: caseFile.caseFileTypeId,
        name: caseFile.title,
        folderId: caseFile.folderId,
        selectedFolderId: caseFile.folderId,
        visibilityMode: caseFile.visibilityMode,
        documents: documents
            .filter((doc) => !!doc.path || !!doc.base64)
            .map((doc) => ({
                name: doc.name,
                documentTypeId: doc.typeId,
                order: doc.order,
                localPath: doc.path,
                base64File: doc.base64,
                metaData: doc.metaData,
            })),
        signers: signers.map((signer) => ({
            name: signer.name,
            email: signer.email,
            onBehalfOf: signer.onBehalfOf,
            ssn: signer.ssn,
            ssnType: signer.ssnType ?? SSNs.LEGACY_SSN.id,
            vatin: signer.vatin,
            enableInsecureSigning: !!signer.enableTouch,
            isPrivate: !!signer.private,
            reminderInterval: signer.reminderInterval,
            roles: signer.roles,
        })),
        copyRecipients: copyRecipients.map((cc) => ({
            name: cc.name,
            email: cc.email,
        })),
        messageTemplate: messages.request ? messages.request.templateId : false,
        messageSubject: messages.request ? messages.request.subject : false,
        messageText: messages.request ? messages.request.body : false,
        sensitiveData: !!caseFile.sensitiveData,
        language: caseFile.language,
        signOnMeeting: !!caseFile.signOnMeeting,
        sendAt: caseFile.sendAt,
        expireAt: caseFile.expireAt,
        metaData: caseFile.metaData,
        clearData: data.clearData,
        reference: caseFile.reference,
        source: data.integrationId,
    };
}

function extractV2Data(data) {
    let signers;
    let documents;
    let copyRecipients;

    if (data.documents) {
        documents = data.documents
            .map((doc) => {
                return {
                    name: doc.name,
                    order: doc.order,
                    typeId: doc.typeId,
                    localPath: doc.localPath,
                    metaData: doc.metaData,
                };
            })
            .filter((doc) => {
                return !!doc.localPath; // Exclude documents that don't have a path
            });
    }

    if (data.signers) {
        signers = data.signers.map((signer) => {
            return {
                name: signer.name,
                email: signer.email,
                onBehalfOf: signer.onBehalfOf,
                ssn: signer.ssn,
                ssnType: signer.ssnType ?? SSNs.LEGACY_SSN.id,
                vatin: signer.vatid,
                enableInsecureSigning: signer.enableTouch,
                isPrivate: signer.isPrivate,
                reminderInterval: signer.reminderInterval,
                roles: signer.roles,
            };
        });
    }

    if (data.copyRecipients) {
        copyRecipients = data.copyRecipients.map((recipient) => {
            return {
                name: recipient.name,
                email: recipient.email,
            };
        });
    }

    return {
        templateId: data.templateId,
        name: data.name,
        sensitiveData: data.sensitiveData,
        language: data.language,
        folderId: data.folderId,
        selectedFolderId: data.folderId,
        visibilityMode: data.visibilityMode,
        documents: documents,
        signers: signers,
        copyRecipients: copyRecipients,
        messageTemplate: data.messageTemplate,
        messageSubject: data.messageSubject,
        messageText: data.messageText,
        signOnMeeting: data.signOnMeeting,
        sendAt: data.sendAt,
        expireAt: data.expireAt,
        metaData: data.metaData,
        clearData: data.clearData,
        reference: data.reference,
        source: data.source,
    };
}

export { parseURIString, loadCasefileObject };
