import React, { useEffect, useState } from 'react';
import ToastComponent from './Toast';

const ToastContainer = ({ toast, onRemove }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [removalTriggered, setRemovalTriggered] = useState(false);

    useEffect(() => {
        const showTimer = setTimeout(() => setIsVisible(true), 10);

        const removeTimer = setTimeout(() => {
            triggerRemove();
        }, toast.duration);

        return () => {
            clearTimeout(showTimer);
            clearTimeout(removeTimer);
        };
    }, [toast.duration]);

    const triggerRemove = () => {
        if (!removalTriggered) {
            setRemovalTriggered(true);
            setIsVisible(false);
            setTimeout(() => onRemove(toast.id), 300);
        }
    };

    return (
        <div
            className={`transition duration-300 ease-in-out 
                ${
                    isVisible
                        ? 'opacity-100 translate-y-0'
                        : 'opacity-0 translate-y-[-20px]'
                }`}>
            <ToastComponent toast={toast} removeToast={triggerRemove} />
        </div>
    );
};

export default ToastContainer;
