import React from 'react';
import classnames from 'classnames';
import { i18n } from 'Language';
import TextInput from 'Common/components/TextInput';

type Props = {
    dataCount: number;
    searchValue: string;
    onSearchInputChange: (value: string, e: React.ChangeEvent) => void;
    clearSearch: () => void;
};

const TopAction: React.FunctionComponent<Props> = ({
    searchValue,
    clearSearch,
    onSearchInputChange,
    dataCount,
}) => (
    <div className="top-actions">
        <span className="top-actions-search">
            <div
                className={classnames('archive-search-bar-container', {
                    active: searchValue !== '',
                })}>
                <div className="archive-search-bar">
                    <div className="archive-search-bar-icon">
                        <i className="fas fa-search" />
                    </div>
                    <TextInput
                        className="archive-search-bar-input"
                        placeholder={i18n('Filter results')}
                        value={searchValue}
                        onChange={onSearchInputChange}
                    />
                    {searchValue !== '' && (
                        <div className="archive-search-bar-clear-button">
                            <span onClick={clearSearch}>
                                <i className="far fa-times" />
                                &nbsp;
                                <span className="archive-search-bar-clear-button-label">
                                    {i18n`clear`}
                                </span>
                            </span>
                        </div>
                    )}
                </div>
                <span className="archive-search-bar-label">
                    {dataCount === 0 && i18n`No results`}
                    {dataCount === 1 && i18n`1 item`}
                    {dataCount > 1 && i18n`${dataCount} items`}
                </span>
            </div>
        </span>
    </div>
);

export default TopAction;
