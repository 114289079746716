import { createComponent } from '@lit/react';
import { DateTimePicker } from '@penneo/vega-ui';
import React from 'react';

const UIDateTimePicker = createComponent({
    tagName: 'vega-date-time-picker',
    elementClass: DateTimePicker,
    react: React,
});

export default UIDateTimePicker;
