import React from 'react';
import { NativePdfEmbed } from './NativePdfEmbed';
import Loader from 'Common/components/Common/Loader';
type Props = {
    isNativePDFSupported: boolean;
    pdfData: string | Blob | undefined;
    renderInIframe?: boolean;
};

const PdfPreviewer = React.lazy(() => import('./PdfPreviewer'));

export const PdfDocument = ({
    isNativePDFSupported,
    pdfData,
    renderInIframe,
}: Props) => {
    if (!pdfData) {
        return null;
    }

    return (
        <div className="document-preview-pdf-container">
            {isNativePDFSupported ? (
                <NativePdfEmbed
                    pdfUrl={pdfData as string}
                    iframe={renderInIframe}
                />
            ) : (
                <React.Suspense fallback={<Loader />}>
                    <PdfPreviewer pdfData={pdfData as Blob} />
                </React.Suspense>
            )}
        </div>
    );
};
