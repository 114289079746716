import { i18n } from 'Language';
import React, { useCallback, useEffect, useState } from 'react';
import { clientsState, fetchClientLinkedEntities } from './redux';
import { ClientLinkEntityType } from './types';
import { useAppDispatch, useAppSelector } from 'Store';
import Table, { Config } from 'Common/components/BaseTable';
import { Link } from 'react-router';
import { CaseFileItem } from 'Casefiles/Archive';
import { CaseFileStatus } from 'Casefiles/Constants';
import moment from 'moment';
import { renderCasefileStatus } from 'Casefiles/Archive/utils';

type Props = {
    clientId: number;
};

export const ClientViewCasefiles = ({ clientId }: Props) => {
    const dispatch = useAppDispatch();
    const { loading, currentClient } = useAppSelector(clientsState);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [sorting, setSorting] = useState('');
    const [querySorting, setQuerySorting] = useState<'ASC' | 'DESC'>('ASC');

    const getItemRoute = (item: Partial<CaseFileItem>) => {
        if (item.status === CaseFileStatus.DRAFT) {
            return {
                name: 'casefile-create',
                params: {
                    action: 'draft',
                    casefileId: item.id,
                },
            };
        }

        return {
            name: 'archive-casefile-details',
            params: {
                casefileId: item.id,
            },
        };
    };

    const tableConfig: Config = {
        title: {
            label: i18n('Name'),
            tdClassName: 'column-title',
            component: (item: Partial<CaseFileItem>) => (
                <Link to={getItemRoute(item)}>
                    <span>
                        <i className="far fa-file-alt" />
                        &nbsp; {item.title}
                    </span>
                </Link>
            ),
            disableSort: true,
        },
        type: {
            thClassName: 'column-type',
            tdClassName: 'column-type',
            label: i18n('Type'),
            component: (item: Partial<CaseFileItem>) =>
                !item.caseFileType ? (
                    <span>- - -</span>
                ) : (
                    <span className="no-wrap">
                        <i
                            className="far fa-tag"
                            style={{ marginRight: '0.3em' }}
                        />
                        {item.caseFileType.name}
                    </span>
                ),
            disableSort: true,
        },
        status: {
            thClassName: 'column-status',
            tdClassName: 'column-status',
            label: i18n('Status'),
            component: (item: Partial<CaseFileItem>) =>
                renderCasefileStatus(item as CaseFileItem),
            disableSort: true,
        },
        created: {
            thClassName: 'column-created',
            tdClassName: 'column-created',
            label: i18n('Created'),
            component: (item: Partial<CaseFileItem>) => (
                <>
                    {item.created
                        ? moment.unix(item.created).format('YYYY-MM-DD')
                        : '- - -'}
                </>
            ),
        },
    };

    /**
     * We have to do some logic when the limit changes,
     * as it can conflict with the current page number
     */
    const handleLimitChange = useCallback(
        (newLimit: number) => {
            if (currentClient?.entities) {
                if (currentClient.entities.total <= newLimit) {
                    setPage(1);
                }
            }

            setLimit(newLimit);
        },
        [currentClient]
    );

    /**
     * 'sorting' keeps track of the Table component selected sorting,
     * and 'querySorting' is to sort the Client list
     * to be fetched
     */
    const handleSortingChange = (newSorting: string) => {
        setSorting(newSorting);
        setQuerySorting(!newSorting.includes('-') ? 'ASC' : 'DESC');
    };

    useEffect(() => {
        dispatch(
            fetchClientLinkedEntities(clientId, ClientLinkEntityType.casefile, {
                per_page: limit,
                page,
                sort: querySorting,
            })
        );
    }, [clientId, limit, page, querySorting]);

    return (
        <Table
            limit={limit}
            page={page}
            sort={sorting}
            config={tableConfig}
            dataSource={currentClient?.entities?.list ?? []}
            dataCount={currentClient?.entities?.total ?? 0}
            isLoading={loading}
            emptyTableComponent={
                <div className="clients-list-empty">{i18n`The list is empty`}</div>
            }
            onSortChange={handleSortingChange}
            onLimitChange={handleLimitChange}
            onNext={setPage}
            onPrev={setPage}
            onPageChange={setPage}
        />
    );
};
