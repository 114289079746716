import React, { useEffect } from 'react';
import PostSignStorageOptions from 'Common/components/StorageOptions/PostSignStorageOptions';
import { useAppDispatch, useAppSelector } from 'Store';
import {
    saveSignature,
    showError,
    SignatureData,
    StateType,
} from 'ItsmeQES/redux/reducer';
import { ITSME_BELGIUM_QES } from 'EID/Constants';
import { SignatureData as OpenIDSignatureData } from 'OpenID/redux/types';
import { invalidState } from 'ItsmeQES/errors';
import LoadingData from 'Common/components/Loaders/LoadingData';

export default function SignatureDestinationSelector() {
    const state = useAppSelector((state) => state.itsmeQESSigning);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (state.type === StateType.ReadyToSaveSignature) {
            return;
        }

        dispatch(
            showError({
                error: invalidState(
                    state.type,
                    'showing signature destination selector',
                    StateType.ReadyToSaveSignature
                ),
            })
        );
    }, []);

    if (state.type !== StateType.ReadyToSaveSignature) {
        console.log('Unexpected state in SignatureDestinationSelector', state);

        return <LoadingData />;
    }

    const { challengeKey } = state.base;
    const { signToken, signature } = state;
    const originalSignData = {
        signToken: signToken,
        signature: signature,
    };

    const handleOnSubmit = (
        disposable: boolean,
        userId: number | null,
        newSignature: OpenIDSignatureData | undefined = undefined
    ) => {
        dispatch(
            saveSignature({
                signData: toSignData(newSignature) || originalSignData,
                disposable,
                userId,
                challengeKey,
            })
        );
    };

    return (
        <div>
            <PostSignStorageOptions
                challengeKey={challengeKey}
                signature={{
                    token: signToken,
                    signature: [signature, ''],
                }}
                isSubmitting={false}
                hasError={false}
                method={ITSME_BELGIUM_QES.type}
                onSubmit={handleOnSubmit}
            />
        </div>
    );
}

const toSignData = (
    input: OpenIDSignatureData | undefined
): SignatureData | undefined => {
    if (input === undefined) {
        return undefined;
    }

    return {
        signature: input.signature[0],
        signToken: input.token,
    };
};
