import Pagination from 'rc-pagination/lib/locale/en_US';
import DatePicker from './date-picker/da_DK';
import TimePicker from './time-picker/da_DK';
import Calendar from 'antd/lib/calendar/locale/en_US';

const daDK = {
    locale: 'da',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    Table: {
        filterTitle: 'Filter MenuTEST',
        filterConfirm: 'OKTEST',
        filterReset: 'ResetTEST',
        emptyText: 'No DataTEST',
        selectAll: 'Select Current PageTEST',
        selectInvert: 'Select InvertTEST',
    },
    Modal: {
        okText: 'OKTEST',
        cancelText: 'CancelTEST',
        justOkText: 'OKTEST',
    },
    Popconfirm: {
        okText: 'OKTEST',
        cancelText: 'CancelTEST',
    },
    Transfer: {
        notFoundContent: 'Not FoundTEST',
        searchPlaceholder: 'Search hereTEST',
        itemUnit: 'itemTEST',
        itemsUnit: 'itemsTEST',
    },
    Select: {
        notFoundContent: 'Not FoundTEST',
    },
    Upload: {
        uploading: 'Uploading...TEST',
        removeFile: 'Remove fileTEST',
        uploadError: 'Upload errorTEST',
        previewFile: 'Preview fileTEST',
    },
};

export default daDK;
