import axios from 'axios';
import { SigningAPI } from 'Api';
import { Dispatcher } from 'Core';
import Constants, { ActionTypes } from '../Constants';

const newsActions = {
    saveReadTimestamp(timestamp) {
        let payload = {
            preferences: {
                lastReadTimestamp: timestamp,
            },
        };

        // Update UI optimistically
        Dispatcher.handleViewAction({
            type: ActionTypes.UPDATE_TIMESTAMP_REQUEST,
            timestamp: timestamp,
        });

        this.fetchNewsPreferences()
            .then((preferences) => {
                if (preferences.lastReadTimestamp === 0) {
                    return SigningAPI.post('/user/preferences/news', payload);
                }

                return SigningAPI.put('/user/preferences/news', payload);
            })
            .then(() => {
                Dispatcher.handleServerAction({
                    type: ActionTypes.UPDATE_TIMESTAMP_SUCCESS,
                    timestamp: timestamp,
                });
            });
    },

    fetchNewsPreferences() {
        return SigningAPI.get('/user/preferences/news')
            .then((response) => {
                return response.preferences;
            })
            .catch((error) => {
                if (error.status === 404) {
                    return { lastReadTimestamp: 0 };
                }
            });
    },

    fetchNews() {
        Dispatcher.handleViewAction({
            type: ActionTypes.FETCH_REQUEST,
        });

        Promise.all([
            this.fetchNewsPreferences(),
            axios.get(Constants.NEWS_FEED_URL),
        ])
            .then((response) => {
                let [preferences, news] = response;

                Dispatcher.handleServerAction({
                    type: ActionTypes.FETCH_SUCCESS,
                    lastRead: preferences.lastReadTimestamp,
                    news: news.data.items,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: ActionTypes.FETCH_FAILURE,
                    error: error,
                });
            });
    },

    toggleNews() {
        Dispatcher.handleViewAction({
            type: ActionTypes.TOGGLE_WIDGET,
        });
    },
};

export default newsActions;
