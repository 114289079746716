// Created based on the example provided by the xadesjs:

import { getXMLSignatureDocument } from './signText';
import { getX509CertificateList, stripPEMDelimiters } from './certificate';
import { algorithm } from '../Constants';
import { getCrypto } from './crypto';
import {
    SignText,
    KeyPair,
    OpenIDCertificate,
    OpenIDCertificateTrustChain,
} from 'OpenID/redux/types';

// https://github.com/PeculiarVentures/xadesjs/blob/master/examples/html/src/main.js
export const createSignature = (
    certificate: OpenIDCertificate,
    keyPair: KeyPair,
    trustChain: OpenIDCertificateTrustChain,
    signText: SignText
) => {
    const { SignedXml } = getCrypto();
    const { privateKey, publicKey } = keyPair;

    const xmlDoc = getXMLSignatureDocument(signText.xml);
    const x509 = getX509CertificateList(certificate, trustChain);
    const signature = new SignedXml();

    return signature.Sign(
        algorithm, // Signing Algorithm
        privateKey, // Signing Key
        xmlDoc, // XML document to be signed
        {
            // Additional options
            // Public key for KeyInfo block
            keyValue: publicKey,
            // List of Reference
            // Default is Reference with hash alg SHA-256 and exc-c14n transform
            references: [{ hash: algorithm.hash, transforms: ['c14n'] }],
            // List of X509 Certificates
            x509: x509,
            signingCertificate: stripPEMDelimiters(certificate),
        }
    );
};
