import { storage } from 'Core';
import launchDarkly from 'Common/LaunchDarkly';
import { Flags } from 'types/LaunchDarkly';
import { Country } from 'types/Country';
import { SSN } from 'types/SSN';
import { UserEntity } from 'types/User';
import { CustomerEntity } from 'types/Customer';
import { isLegacySSN, isSSNValid, splitAggregatedSSN } from 'utils';
import {
    hasKYCAccess,
    isCustomerAdministrator,
    isPenneoAdministrator,
    hasDataDashboardAccess,
    hasFormsAccess,
} from 'Common/utils/userPermissions';
import { Settings } from './Settings/redux/types';
import { getCountryByCode } from './utils/countries';

// Default empty parameters object.
let params: any = { auth: {}, signing: {}, workflow: {}, form: {}, signer: {} };
let configAvailable = true;

// Validate that the external penneo configuration is available at application start.
try {
    if ((window as any).PENNEO_CONFIG) {
        /* eslint no-undef: 0 */
        params = (window as any).PENNEO_CONFIG; /* eslint no-undef: 0 */
    }
} catch (e) {
    configAvailable = false;
}

export const parameters = params;

export const images = '/assets/images';

export type AuthMethod = {
    type: string;
    credentialType: () => string;
    title: string;
    paths: {
        activate: string;
    };
};

export const AUTH_METHODS: AuthMethod[] = [
    {
        type: 'classic',
        credentialType: () => 'classic',
        title: 'Classic Credentials',
        paths: {
            activate: 'activate-classic-credentials',
        },
    },
    {
        type: 'api',
        credentialType: () => 'api',
        title: 'API Keys',
        paths: {
            activate: 'activate-integration',
        },
    },
];

let APP_ORIGIN = 'https://app.penneo.com';

if (window.location.origin.includes('sandbox.penneo.com')) {
    APP_ORIGIN = 'https://sandbox.penneo.com';
}

if (window.location.origin.includes('staging.penneo.com')) {
    APP_ORIGIN = 'https://staging.penneo.com';
}

if (process.env.NODE_ENV !== 'production') {
    APP_ORIGIN = window.location.origin;
}

export const SIGN_CHALLENGE_KEY_REGEX = /([A-Z-0-9]{5}-){5}[A-Z-0-9]{5}/g;

export const MIN_USERNAME_LENGTH = 6;

const Constants = {
    configAvailable: configAvailable,
    PENNEO_ORIGIN: APP_ORIGIN,

    // Token to locally persist JWT in localStorage or Cookies
    PENNEO_TOKEN_KEY: 'penneo-token',
    PENNEO_REFRESH_KEY: 'penneo-refresh-token',
    PENNEO_SIGNING_API: APP_ORIGIN + params.api.signing,
    PENNEO_FORMS_API: APP_ORIGIN + params.api.form,
    PENNEO_WORKFLOW_API: APP_ORIGIN + params.api.workflow,
    PENNEO_AUTH_API: APP_ORIGIN + params.api.auth,
    PENNEO_SIGNER_API: APP_ORIGIN + params.api.signer,
    PENNEO_CA_ENDPOINT: params.api.penneoCA,
    PENNEO_LANGUAGE_ENDPOINT: APP_ORIGIN + params.languageService,
    PENNEO_SILVERFIN_INTEGRATION: params.api.silverfin,
    CLA_API_PROXY_PROTOTYPE: APP_ORIGIN + params.api.claProxy,
    PENNEO_MEDIA_API: APP_ORIGIN + params.api.media,

    ENABLE_EXPERIMENTAL_FEATURES: storage.get('penneo-experimental-features'),

    QRSS_PROXY_ENDPOINT: params.api.qrss_proxy ?? 'localhost',

    /**
     * Controls whether the special wildcard character '*' can be used to enable all experimental EIDs.
     */
    EXPERIMENTAL_EID_WILDCARD_ENABLED: !!(params.eidEnableWildcard ?? false),

    userOptions: [
        {
            name: 'My Settings',
            path: 'profile',
        },
        // disabled for allowing a hard-redirect to take place on logout ,
        // {
        //     name: 'Logout',
        //     path: "login-route"
        // }
    ],

    validationRules: {
        username: [
            {
                rule: /^.+$/,
                message: 'Field cannot be empty',
            },
            {
                rule: /^\S+$/,
                message: 'Field cannot contain spaces',
            },
            {
                func: (value: string) =>
                    value && value.length >= MIN_USERNAME_LENGTH,
                message: `Field must contain at least ${MIN_USERNAME_LENGTH} characters`,
            },
        ],
        password: [
            {
                rule: /^.+$/,
                message: 'Field cannot be empty',
            },
            {
                rule: /^\S+$/,
                message: 'Field cannot contain spaces',
            },
            {
                rule: /^.{10,}$/,
                message: 'Field must contain at least 10 characters',
            },
        ],
        ssn: [
            {
                rule: /^.+$/,
                message: 'Please, enter an SSN',
            },
            {
                rule: /^\S+$/,
                message: 'Field cannot contain spaces',
            },
            {
                func: (aggregatedSsn) => {
                    const splitValue = splitAggregatedSSN(aggregatedSsn);

                    // If there is no SSN, there is no need to check the country
                    return !splitValue?.ssn || !isLegacySSN(splitValue?.typeId);
                },
                message: `Please select a country`,
            },
            {
                func: (aggregatedSsn) => {
                    const splitValue = splitAggregatedSSN(aggregatedSsn);

                    // There is no need to validate if no SSN has been given
                    // and no country has been selected either
                    const skipValidation =
                        !splitValue ||
                        (!splitValue.ssn && isLegacySSN(splitValue.typeId));

                    return (
                        skipValidation ||
                        isSSNValid(
                            splitValue?.ssn as string,
                            splitValue?.typeId
                        )
                    );
                },
                message: `Enter a valid SSN`,
            },
        ],
        vatin: [
            {
                rule: /^[0-9]{8}$/,
                message: 'Field must be 8 digits',
            },
        ],
        email: [
            {
                rule: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9_-]{2,63})+$/,
                type: 'warning',
                message: 'Enter a valid email',
            },
        ],
        required: [
            {
                func: (value) => value && value.length > 0,
                message: 'Field cannot be empty',
            },
        ],
        require: [
            {
                func: (str) => str && str.length > 0,
                message: 'Required',
            },
        ],
    },

    sites: {
        downloadDesktop:
            'https://www.support.penneo.com/hc/en-gb/articles/20502853066269-How-to-install-set-up-and-use-the-Desktop-Application-to-create-case-files',
        aboutPenneo: {
            en: 'https://penneo.com/about/',
            da: 'https://penneo.com/da/about/',
            sv: 'https://penneo.com/sv/about/',
            no: 'https://penneo.com/no/about/',
        },
        statusPage: 'http://status.penneo.com',
        support: 'https://www.support.penneo.com',
        supportRequest: 'https://www.support.penneo.com/hc/en-gb/requests/new',
        supportSigning:
            'https://www.support.penneo.com/hc/en-gb/articles/20450916545437-How-to-sign-a-document',
        zendesk: 'https://www.support.penneo.com',
        helpLinkForBankIdNorway: (language?: string) => {
            if (language === 'da') {
                return 'https://www.support.penneo.com/hc/da/articles/20505083590301-Kommer-ikke-videre-fra-engangskode-Norsk-BankID-p%C3%A5-mobil';
            }

            return 'https://www.support.penneo.com/hc/en-gb/articles/20505083590301-I-cannot-continue-past-the-one-time-code-when-signing-with-Norwegian-BankID-on-mobile-why';
        },
    },

    statusPageSummary: 'https://bbncbfddfgjb.statuspage.io/api/v2/summary.json',
    APPLICATION_LOADED: 'APPLICATION_LOADED',

    // Tutorial Constants
    VIEW_LIMIT_COUNT: 2,
};

export const Integrations = {
    Silverfin: {
        name: 'Silverfin',
        baseDomain: 'getsilverfin.com',
        getUrls: () => ({
            REDIRECT_URI: `${Constants.PENNEO_ORIGIN}/integrations/silverfin/callback`,
            INIT_AUTH_URL: `${Constants.PENNEO_SILVERFIN_INTEGRATION}/auth`,
            REGISTER_PENNEO_URL: `${Constants.PENNEO_SILVERFIN_INTEGRATION}/register`,
            UNREGISTER_PENNEO_URL: `${Constants.PENNEO_SILVERFIN_INTEGRATION}/unregister`,
            SET_TOKEN_PENNEO_URL: `${Constants.PENNEO_SILVERFIN_INTEGRATION}/import/cookie`,
            TARGET_URL: `${Constants.PENNEO_SILVERFIN_INTEGRATION}/import?penneo_origin=${Constants.PENNEO_ORIGIN}`,
            FETCH_URL: `${Constants.PENNEO_SILVERFIN_INTEGRATION}/fetch`,
        }),
    },
};

export let StorageKeys = {
    LOGIN_URL: 'penneo-login-url',
    DEBUG: 'penneo-debug',
    JWT_TOKEN: 'penneo-token',
    REFRESH_TOKEN: 'penneo-refresh-token',
    EXPERIMENTAL: 'penneo-experimental-features',
    OPENID_TOKEN: 'penneo-openid-token', // stores openID signing token
    COMPAT_REOPEN_SIGN_MODAL: 'penneo-compat-reopen-sign-modal',
    OPENID_STATE: 'penneo-openid-state',
    SIDEBAR_COLLAPSED: 'penneo-navigation-sidebar-collapsed',
    SILVERFIN_STATE: 'penneo-silverfin-state',
    CUSTOM_DEFAULT_EMAIL_TPL: 'custom-default-email-template.{group}.{type}',
    ENCRYPTED_NIN: 'encrypted-nin',
    ITSME_STATE: 'itsme-qes-state',
    SHOW_BETA_UPLOAD_BANNER: 'show-beta-upload-banner',
    IS_BETA_UPLOAD_ENABLED: 'is-beta-upload-enabled',
};

export let Routes = {
    login: `/login`,
    defaultV2RouteName: 'dashboard',
    defaultDesktopRoute: `casefile-details`,
    defaultSignersArchiveRoute: 'personal-archive',

    // Cannot be relative route because in development mode "app_dev.php"
    // will be removed from the path.
    defaultV1Route: `${Constants.PENNEO_ORIGIN}/archive`,
    logout: `${Constants.PENNEO_ORIGIN}/logout`,
};

export let validationRules = Constants.validationRules;

export enum Platforms {
    WEB = 'web',
    DESKTOP = 'desktop',
}

// Environment Variables
export let env = {
    electron: false,
    platform: Platforms.WEB,
    legacy: false,
};

export enum VIEWPORT_SIZES {
    ALL = 'all',
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
    TABLET = 'tablet',
}

export enum TOUR_IDS {
    DASHBOARD = 'dashboard',
    FORM = 'form',
}

export const ACCESS_READONLY = 'readonly';
export const ACCESS_READ_WRITE = 'read-write';
export const ACCESS_FULL = 'full';

export const ACCESS_RIGHTS_LABELS = {
    [ACCESS_READONLY]: {
        icon: 'eye',
        type: 'view',
        label: 'Can view',
    },
    [ACCESS_READ_WRITE]: {
        icon: 'edit',
        type: 'edit',
        label: 'Can edit',
    },
    [ACCESS_FULL]: {
        icon: 'wrench',
        type: 'manage',
        label: 'Can manage',
    },
};

// Main navigation items
const hasFeatureFlag = (flag: Flags) => launchDarkly.variation(flag);

export interface NavigationItem {
    title: string;
    props?: { id?: string; className?: string };
    icon?: string;
    id?: number;
    route?: string;
    show?: (
        user: UserEntity,
        customer: CustomerEntity,
        settings: Settings
    ) => boolean;
    children?: NavigationItem[];
    mobileOnly?: boolean;
    href?: {
        url: string;
        external?: boolean;
    };
    isWhatsNew?: boolean;
}

export const DASHBOARD_NAVIGATION: NavigationItem[] = [
    {
        id: 1,
        title: 'Dashboard',
        route: 'dashboard',
        icon: 'compass',
    },
];

export const TOP_NAVIGATION: NavigationItem[] = [
    {
        id: 2,
        title: 'Archive',
        route: 'archive',
        icon: 'briefcase',
    },
    {
        id: 3,
        title: 'Web forms',
        route: 'form-templates',
        icon: 'file-alt',
        show: (user) => hasFormsAccess(user),
    },
    {
        id: 4,
        title: 'Identity validation',
        route: 'manage-kyc',
        icon: 'id-card-alt',
        show: (user) =>
            hasKYCAccess(user) &&
            hasFeatureFlag(Flags.KYC_ACCESS) &&
            hasFeatureFlag(Flags.KYC_LIST_ENABLED),
    },
    {
        id: 5,
        title: 'Recycle bin',
        icon: 'trash-alt',
        route: 'trash',
    },
];

export const MIDDLE_NAVIGATION: NavigationItem[] = [
    {
        id: 6,
        title: 'Organization',
        icon: 'building',
        show: () =>
            hasFeatureFlag(Flags.CLIENTS) || hasFeatureFlag(Flags.TEAM_ACCESS),
        children: [
            {
                title: 'Clients',
                route: 'clients-main',
                show: () => hasFeatureFlag(Flags.CLIENTS),
            },
            {
                title: 'Teams',
                route: 'teams',
                show: () => hasFeatureFlag(Flags.TEAM_ACCESS),
            },
        ],
    },
    {
        id: 7,
        title: 'Configure',
        icon: 'cog',
        children: [
            {
                title: 'Profile',
                route: 'profile',
            },
            {
                title: 'Company',
                route: 'company',
                show: (user) =>
                    isPenneoAdministrator(user) ||
                    isCustomerAdministrator(user),
            },
            {
                title: 'E-mail templates',
                route: 'email-templates',
            },
            {
                title: 'Contacts',
                route: 'contacts',
                show: (user) =>
                    isCustomerAdministrator(user) &&
                    hasFeatureFlag(Flags.CONTACT_LIST_ACCESS),
            },
            {
                title: 'Analytics',
                href: { url: 'analytics', external: true },
                show: (user, customer, settings) =>
                    hasDataDashboardAccess(user, settings),
            },
        ],
    },
];

export const BOTTOM_NAVIGATION: NavigationItem[] = [
    {
        id: 8,
        title: 'Help center',
        href: { url: 'help-center', external: true },
        icon: 'question-circle',
    },
];

export const WHATS_NEW_NAVIGATION: NavigationItem[] = [
    {
        id: 9,
        title: 'What is new',
        icon: 'satellite-dish',
        isWhatsNew: true,
    },
];

export const SIGNER_NAVIGATION = [
    {
        id: 2,
        title: 'Archive',
        route: 'personal-archive',
        icon: 'briefcase',
    },
    {
        id: 5,
        title: 'Recycle bin',
        icon: 'trash-alt',
        route: 'personal-archive-trash',
    },
];

export const SIGNER_NAVIGATION_BOTOM: NavigationItem[] = [
    {
        id: 8,
        title: 'Help center',
        href: { url: 'https://www.support.penneo.com/', external: true },
        icon: 'question-circle',
    },
];

// Countries
// @TODO Should be figured out where to move this collection
const Denmark: Country = getCountryByCode('DK');
const Sweden: Country = getCountryByCode('SE');
const Norway: Country = getCountryByCode('NO');
const Finland: Country = getCountryByCode('FI');
const Belgium: Country = getCountryByCode('BE');

// SSNs
// @TODO Should be figured out where to move this collection
const LEGACY_SSN: SSN = {
    id: 'legacy',
    country: {} as any,
    isEnabled() {
        return true;
    },
    isValid(ssn) {
        const REGEX = /^[0-9A-Z]{10,12}$/;
        const strippedSSN = this.format(ssn);

        return REGEX.test(strippedSSN);
    },
    format(ssn) {
        const CHARS_TO_STRIP = [' ', '-', '\\.'];

        return CHARS_TO_STRIP.reduce(
            (prev, char) => prev.replace(new RegExp(char, 'g'), ''),
            ssn.toString()
        );
    },
};

// Det Centrale Personregister
const DK_CPR: SSN = {
    id: 'dk:cpr',
    country: Denmark,
    isEnabled: () => true,
    isValid: (ssn) => /^[\d]{6}-?[\d]{4}$/.test(ssn),
    format: (ssn) => ssn.toString().replace(/-/g, ''),
};

// Personal Identification Number or personnummer
const SE_PIN: SSN = {
    id: 'se:pin',
    country: Sweden,
    isEnabled: () => true,
    isValid: (ssn) => /^[\d]{8}-?[\d]{4}$/.test(ssn),
    format: (ssn) => ssn.toString().replace(/-/g, ''),
};

// National Identification Number or fødselsnummer
const NO_NIN: SSN = {
    id: 'no:nin',
    country: Norway,
    isEnabled: () => true,
    isValid: (ssn) => /^[\d]{6}[ -]?[\d]{3}[\d]{2}$/.test(ssn),
    format: (ssn) => ssn.toString().replace(/-/g, '').replace(/ /g, ''),
};

// Personal Identity Code or henkilötunnus (HETU)
const FI_PIC: SSN = {
    id: 'fi:pic',
    country: Finland,
    isEnabled: () => true,
    // @see https://en.wikipedia.org/wiki/National_identification_number#Finland
    isValid: (ssn) => /^[\d]{6}(\+|-|A)[\d]{3}[A-Z0-9]{1}$/.test(ssn),
    format: (ssn) => ssn,
};

// TODO: Clean and remove. Now that itsme QES is fully enabled, this cannot be used anymore
const BE_NRN: SSN = {
    id: 'be:nrn',
    country: Belgium,
    // BE_NRN is not enabled when the feature flag 'use-itsme-qes' is enabled
    isEnabled: () => false,
    isValid: (ssn) =>
        /^[\d]{2}\.?[\d]{2}\.?[\d]{2}-?[\d]{3}\.?[\d]{2}$/.test(ssn),
    format: (ssn) => ssn.toString().replace(/-/g, '').replace(/\./g, ''),
};

export const SSNs = {
    LEGACY_SSN,
    DK_CPR,
    SE_PIN,
    NO_NIN,
    FI_PIC,
    BE_NRN,
};

export enum API_RESPONSE_HEADERS {
    REQUEST_ID = 'x-penneo-request-id',
    TRACE_ID = 'x-b3-traceid',
}

export enum ENVIRONMENTS {
    PRODUCTION = 'production',
    SANDBOX = 'sandbox',
    STAGING = 'staging',
    DEVELOPMENT = 'development',
}

export const TEMPLATE_ENTITY_KIND = {
    COMPLETED_BODY: 'case-file-completed-email-message',
    COMPLETED_SUBJECT: 'case-file-completed-email-subject',
    REMINDER_BODY: 'signing-reminder-email-message',
    REMINDER_SUBJECT: 'signing-reminder-email-subject',
    REQUEST_BODY: 'signing-request-email-message',
    REQUEST_SUBJECT: 'signing-request-email-subject',
} as const;

export const TEMPLATE_ENTITY_NAME = {
    CASEFILE: 'case-file',
    SIGNING_REQUEST: 'signing-request',
} as const;

export const WHATS_NEW_WORDPRESS_PATH =
    params?.newsFeedUrl || 'https://penneo.com/wp-json/wp/v2';
export const WHATS_NEW_WORDPRESS_QUERY_PARAMS = 'categories=785';
export const WHATS_NEW_WORDPRESS_URL = `${WHATS_NEW_WORDPRESS_PATH}/posts?${WHATS_NEW_WORDPRESS_QUERY_PARAMS}`;
export const WHATS_NEW_WORDPRESS_MEDIA_URL = `${WHATS_NEW_WORDPRESS_PATH}/media/`;

export const EULA_URL = 'https://penneo.com/eula/';
export const PRIVACY_POLICY_URL =
    'https://penneo.com/penneo-sign-privacy-statement/';
export const MAX_FILE_SIZE = 25; // MB
export const MAX_FILE_SIZE_FORMS = 20; // MB

export default Constants;
