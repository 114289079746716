import classnames from 'classnames';
import DashboardHeader from 'Common/components/DashboardContainer/DashboardHeader';
import { env } from 'Constants';
import PropTypes from 'prop-types';
import React from 'react';

export default class HeaderLayout extends React.Component {
    static propTypes = {
        menu: PropTypes.array,
        children: PropTypes.object,
        user: PropTypes.object,
        branding: PropTypes.object,
    };

    static defaultProps = {
        user: {},
    };

    render() {
        // If we are on desktop, we will append a class to add custom styles
        const isDesktopApp = env.platform === 'desktop';

        return (
            <header
                className={classnames(
                    'penneo-header-layout',
                    isDesktopApp ? 'desktop' : ''
                )}>
                <DashboardHeader
                    user={this.props.user}
                    disableUserOptions={true}
                    disableLink={true}
                    branding={this.props.branding}
                />
                <div className="penneo-header-layout-main">
                    <div className="penneo-header-layout-main-content">
                        {React.cloneElement(this.props.children, {
                            user: this.props.user,
                        })}
                    </div>
                </div>
            </header>
        );
    }
}
