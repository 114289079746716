import { SigningAPI } from 'Api';
import { debug } from 'Core';
import { Client } from './types';

export const mapCasefileWithClient = async (
    caseFileId: number,
    client: Client
): Promise<void> => {
    try {
        const attributes = [
            {
                name: 'aa.penneo.com/audited-company-vatin',
                value: client.vatin,
            },
            {
                name: 'aa.penneo.com/audited-company-name',
                value:
                    (client?.name?.length > 0 && client?.name) ?? client?.vatin,
            },
            {
                name: 'aa.penneo.com/audited-company-country-code',
                value: client.countryCode,
            },
            {
                name: 'aa.penneo.com/audited-company-dirtydata',
                value: client.dirtyData?.toString() ?? 'false',
            },
            {
                name: 'aa.penneo.com/audited-type',
                value: client?.type ?? 'company',
            },
        ];

        const promises = attributes.map((attribute) => {
            return SigningAPI.post(
                `/casefiles/${caseFileId}/attributes`,
                attribute
            );
        });

        await Promise.all(promises);
    } catch (error) {
        debug.log('Failed to map client and casefile id');
    }
};
