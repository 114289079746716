import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import CasefileStore from '../../stores/CasefileStore';
import Button from 'Common/components/Button';

// Actions
import CasefileActions from '../../actions/CasefilesActionCreators';
import DocumentActions from '../../actions/DocumentActionCreators';
import SignerActions from '../../actions/SignerActionCreators';

export default class CasefileSuccess extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    async componentDidMount() {
        let casefile = CasefileStore.getCasefile();

        // If there hasn't been a casefile persisted (i.e. doesn't have an ID in the store)
        // redirect to casefile creator. Since this view shouldn't be loaded at this point.
        if (!casefile.id) {
            this.redirect();

            return;
        }

        await this.resetData();
    }

    resetData = async () => {
        await CasefileActions.clearStore();
        await SignerActions.clearStore();
        await DocumentActions.clearStore();
    };

    redirect = () => this.context.router.push({ name: 'casefile-details' });

    render() {
        return (
            <div className="casefile-success">
                <div className="success-text">
                    <h2 className="success-heading">
                        {i18n`Your case file has been sent`}!
                    </h2>
                    <p>
                        <i className="fa fa-check-circle"></i>
                    </p>
                    <h3>
                        {i18n`Emails will be arriving shortly to each recipient`}
                    </h3>
                    <br />

                    <div className="footer-links">
                        <Button theme="blue" onClick={this.redirect}>
                            {i18n`Done`}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
