import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import { i18n } from 'Language';

// State
import { ReduxState } from 'Store';
import { getCasesExpiringSoon } from 'Casefiles/Archive/redux/reducer';

// Components
import DashboardWidgetContainer, {
    DashboardWidget,
} from './DashboardWidgetContainer';
import { getSortedByExpiration, renderLoading } from './common';

import './expires-soon-widget.scss';
import { trackDashboardInteraction } from './utils';

export type Props = {
    dispatch: Function;
};

type State = {
    days: 14;
    activity: any;
    isLoaded: boolean;
};
export class ExpiresSoonWidget extends React.Component<Props, State> {
    mounted: boolean;

    constructor(props) {
        super(props);

        this.state = {
            days: 14,
            activity: {
                data: [],
            },
            isLoaded: false,
        };
        this.mounted = true;
    }

    componentDidMount() {
        this.init();
    }

    async init() {
        const { dispatch } = this.props;
        const activity = await dispatch(
            getCasesExpiringSoon(this.state.days, 10)
        );

        this.mounted && this.setState({ activity, isLoaded: true });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    renderItem(item) {
        return (
            <li className="widget-entry" key={item.id}>
                <p className="widget-entry-title">
                    <span className="widget-entry-metadata">
                        <Link
                            data-testid={`item`}
                            onClick={() => {
                                trackDashboardInteraction(
                                    'expiring widget clicked',
                                    {
                                        id: item.id,
                                        expireAt: item.expireAt,
                                        diff: item.daysToExpire,
                                    }
                                );
                            }}
                            to={{
                                name: 'archive-casefile-details',
                                params: {
                                    casefileId: item.id,
                                },
                            }}>
                            {item.title}
                        </Link>
                        <br aria-hidden={true} />
                        <span aria-describedby={`date-${item.id}`}>
                            {`${i18n`Expires`} ${moment
                                .unix(item.expireAt)
                                .fromNow()}`}
                        </span>
                    </span>
                </p>
                <p
                    id={`date-${item.id}`}
                    className="widget-entry-details"
                    aria-label={moment
                        .unix(item.expireAt)
                        .format('Do MMM YYYY')}>
                    <span className="widget-entry-date" aria-hidden={true}>
                        {moment.unix(item.expireAt).format('DD/MM/YYYY')}
                    </span>
                </p>
            </li>
        );
    }

    renderContent() {
        const { activity, isLoaded } = this.state;
        const results = getSortedByExpiration(activity.data);
        const isEmpty = !!activity && activity.count === 0;

        return (
            <DashboardWidget
                key={`AllSignatures`}
                title={i18n`Cases expiring soon`}
                footer={
                    <>
                        {!isEmpty && (
                            <Link
                                to={{
                                    name: 'archive-virtual',
                                    params: {
                                        tab: 'pending',
                                    },
                                }}
                                onClick={() =>
                                    trackDashboardInteraction('navigation', {
                                        source: 'expiring widget',
                                        to: 'archive',
                                    })
                                }>
                                {i18n`View all cases expiring soon`}
                            </Link>
                        )}
                    </>
                }>
                {isLoaded === false ? (
                    renderLoading()
                ) : (
                    <ul>
                        {isEmpty ? (
                            <li className="widget-empty">
                                <p>
                                    <i className="fal fa-ballot-check" />
                                    {i18n`No cases will expire in the next ${this.state.days} days`}
                                </p>
                                <p>
                                    <Link
                                        to={{
                                            name: 'archive-virtual',
                                            params: {
                                                tab: 'pending',
                                            },
                                        }}
                                        className="underline-link">
                                        {i18n`View cases waiting for signatures`}
                                    </Link>
                                </p>
                            </li>
                        ) : (
                            results.map((item) => this.renderItem(item))
                        )}
                    </ul>
                )}
            </DashboardWidget>
        );
    }

    render() {
        return (
            <DashboardWidgetContainer
                title={i18n`Cases expiring soon`}
                className="new-expires-soon-widget"
                content={[this.renderContent()]}
            />
        );
    }
}

export default connect((state: ReduxState) => ({}))(ExpiresSoonWidget);
