import { TabbedRoute } from 'types/TabbedRoute';

export const CUSTOMER_TABS: {
    own: TabbedRoute[];
    other: TabbedRoute[];
} = {
    /**
     * Tabs to show when managing your own company
     */
    own: [
        {
            path: 'company-settings',
            tab: {
                title: 'Details',
            },
        },
        {
            path: 'company-manage-users',
            tab: {
                title: 'Users',
            },
        },
        {
            path: 'company-account',
            tab: {
                title: 'Account',
            },
        },
        {
            path: 'company-branding',
            tab: {
                title: 'Branding',
            },
        },
        {
            path: 'company-email-templates',
            tab: {
                title: 'Email templates',
                noPadding: true,
            },
        },
        {
            path: 'company-email-signatures',
            tab: {
                title: 'Email signatures',
            },
        },
    ],
    /**
     * Tabs to show when managing someone else's company (for Penneo super admins)
     */
    other: [
        {
            path: 'admin-customer-settings',
            tab: {
                title: 'Details',
            },
        },
        {
            path: 'admin-customer-users',
            tab: {
                title: 'Users',
            },
        },
        {
            path: 'admin-company-account',
            tab: {
                title: 'Account',
            },
        },
        {
            path: 'admin-customer-branding',
            tab: {
                title: 'Branding',
            },
        },
    ],
};
