import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import Button from 'Common/components/Button';
import moment from 'moment';
import { Languages } from 'Language/Constants';
import { ClassicCredentialsData } from 'Auth/components/CredentialsManagement/AuthMethodsList';

type Props = {
    passwordData: ClassicCredentialsData | null;
    languageCode: Languages;
};

export default class ClassicCredentials extends React.Component<Props> {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    _configureClassic = () => {
        const { router } = this.context;

        router.push({ name: 'account-user-classic' });
    };

    _setMomentLocale = () => {
        const { languageCode } = this.props;

        if (languageCode === 'no') {
            return moment.locale('nb');
        }

        moment.locale(languageCode);
    };

    render() {
        const { passwordData } = this.props;

        this._setMomentLocale();

        if (!passwordData || !passwordData.id) {
            return (
                <div>
                    <p>
                        {i18n`You haven't added a username and password to your account.`}
                        <br />
                        {i18n`These credentials can be used to log in to Penneo
                        but they cannot be used to sign documents`}
                    </p>
                    <Button
                        icon="far fa-plus"
                        theme="green"
                        onClick={this._configureClassic}>
                        {i18n`Add username and password`}
                    </Button>
                </div>
            );
        }

        return (
            <div>
                <div className="username">
                    <span className="label">{i18n`Username`}</span>
                    <span className="value">
                        {passwordData.username}
                        <span className="last-updated">
                            {i18n`Password was last changed`}&nbsp;
                            {moment
                                .utc(passwordData.updatedAt)
                                .local()
                                .fromNow()}
                        </span>
                    </span>
                </div>
                <p>
                    {i18n`You can edit the username and password you use to login to Penneo`}
                </p>
                <Button
                    theme="blue"
                    variant="outline"
                    icon="far fa-edit"
                    onClick={this._configureClassic}
                    renderIconLeft={true}>
                    {i18n`Edit classic credentials`}
                </Button>
            </div>
        );
    }
}
