import React from 'react';
import { omit } from 'lodash';
interface BaseSelectProps {
    label: string;
    required?: boolean;
    onChange?: (
        value: string,
        event?: React.ChangeEvent<HTMLSelectElement>
    ) => void | unknown;
    value?: string;
    id: string;
}

type Option = Record<'label' | 'value', string | number>;
type SelectPropsWithChildren = {
    children: React.ReactNode[];
} & BaseSelectProps;

type SelectPropsWithOptions = {
    options: Option[];
} & BaseSelectProps;

//Used in unit tests
type CombinedProps = SelectPropsWithChildren | SelectPropsWithOptions;
export type SelectProps = CombinedProps &
    React.HTMLAttributes<HTMLSelectElement>;
const Select = ({ onChange, value, label, id, ...rest }: SelectProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.persist();
        e.preventDefault();

        let { value } = e.target;

        onChange?.(value, e);
    };

    const { options } = rest as SelectPropsWithOptions;

    const selectClasses =
        'peer h-full w-full rounded border !border-neutral-700 bg-transparent px-3 py-2.5 !font-sans text-sm font-normal !text-neutral-900 outline outline-0 transition-all empty:!bg-red-500 focus:!border-secondary-700 focus:!outline-0 disabled:!border-secondary-50';

    return (
        <div className="relative min-h-10 w-full min-w-[200px]">
            {(options && options?.length > 0 && (
                <select
                    {...omit(rest, 'options')}
                    id={id}
                    onChange={handleChange}
                    value={value}
                    className={selectClasses}>
                    {(options as Option[])?.map((option: Option) => (
                        <option
                            key={option?.label ?? option.value}
                            value={option.value}>
                            {option?.label ?? option.value}
                        </option>
                    ))}
                </select>
            )) ??
                ((rest as SelectPropsWithChildren)?.children?.length > 0 && (
                    <select
                        {...omit(rest, 'children')}
                        id={id}
                        onChange={handleChange}
                        value={value}
                        className={selectClasses}>
                        {(rest as SelectPropsWithChildren).children}
                    </select>
                ))}
            <label
                className="!absolute left-2.5 -top-2 px-1 !bg-white flex !select-none !text-xs !font-sans !font-semibold transition-all before:!pointer-events-none peer-placeholder-shown:!text-sm !text-neutral-700 peer-focus:!text-secondary-700"
                htmlFor={id}>
                {label}
            </label>
        </div>
    );
};

export default Select;
