import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { UploadedDocument } from 'types/CaseFile';
import { DocumentType, UploadingDocument } from 'types/Document';
import { UploadStatus } from './types';
import DocumentActions from 'Casefiles/actions/DocumentActionCreators';
import { DocumentListItem } from './DocumentListItem/DocumentListItem';

type Props = {
    index: number;
    availableDocumentTypes: DocumentType[];
    connectDragPreview?: Function;
    connectDragSource?: Function;
    connectDropTarget?: Function;
    edit?: () => void;
    file: UploadingDocument | UploadedDocument;
    onChangeDocumentOrder: (index: number, newIndex: number) => void;
    preview?: () => void;
    remove?: () => void;
    cancelUpload?: () => void;
    onUploadStatus: UploadStatus | undefined;
    percentageUploaded?: number;
};

class DocumentRow extends React.Component<Props> {
    node: HTMLElement | null = null;

    changeDocumentOrder = (index: number, newIndex: number) => {
        this.props.onChangeDocumentOrder(index, newIndex);
    };

    render() {
        let {
            edit,
            preview,
            file,
            availableDocumentTypes,
            remove,
            cancelUpload,
            onUploadStatus,
            percentageUploaded,
        } = this.props;

        // react-dnd
        let {
            connectDragSource,
            connectDragPreview,
            connectDropTarget,
        } = this.props;

        let documentType = availableDocumentTypes.filter((t) => {
            return file.documentTypeId === t.id;
        })[0];

        const isUploadingFile = !('id' in file);

        const handleCancelUpload = () => {
            if (isUploadingFile && (file as UploadingDocument)._id) {
                DocumentActions.abortDocument((file as UploadingDocument)._id);
            }
        };

        const effectiveCancelUpload = cancelUpload || handleCancelUpload;

        // can be set to true when enable-media-upload-endpoint is ready
        const showProgressBar = false;

        // Create a dragging container.
        return connectDragPreview?.(
            connectDropTarget?.(
                <li ref={(node) => (this.node = node)}>
                    <DocumentListItem
                        preview={preview}
                        edit={edit}
                        remove={remove}
                        uploadStatus={onUploadStatus}
                        cancelUpload={effectiveCancelUpload}
                        {...{
                            file,
                            documentType,
                            showProgressBar,
                            percentageUploaded,
                            isUploadingFile,
                            connectDragSource,
                        }}
                    />
                </li>
            )
        );
    }
}
// Row has two behaviours: it is a source and a target, because
// it is able to be dragged and to be reordered.

// Source callbacks:
const source = {
    // returns an object with useful properties when dragging event is over
    beginDrag(props) {
        return {
            index: props.index,
        };
    },
    canDrag(props: Props) {
        return !!props.file; // only allow drag if file exists
    },
};

// Target callbacks:
const target = {
    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // prevents replacing items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        // @see https://stackoverflow.com/a/40581747/781779
        const hoverBoundingRect = component.node.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleYDown =
            hoverBoundingRect.top - hoverBoundingRect.bottom;
        const hoverMiddleYUp = hoverBoundingRect.bottom - hoverBoundingRect.top;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed the border of the other card

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleYDown) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleYUp) {
            return;
        }

        // Perform reorder
        component.changeDocumentOrder(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

// Connect the callbacks
let Target = DropTarget('DocumentRow', target, (connect) => ({
    connectDropTarget: connect.dropTarget(),
}))(DocumentRow);

Target = DragSource('DocumentRow', source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
}))(Target);

export default Target;
