import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import DraggableFields from '../Form/DraggableFields';
import FormStore from '../../stores/FormStore';
import FormActions from '../../actions/FormActionCreators';
import Button from 'Common/components/Button';

export default class InputSelector extends React.Component {
    static propTypes = {
        document: PropTypes.object,
        fields: PropTypes.array,
        edit: PropTypes.func,
    };

    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    state = {
        editorSettings: FormStore.getEditorSettings(),
    };

    componentDidMount() {
        FormStore.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        FormStore.removeChangeListener(this.onChange);
    }

    onChange = () => {
        this.setState({
            editorSettings: FormStore.getEditorSettings(),
        });
    };

    done = () => {
        FormActions.editorDone();
    };

    updateSetting = (setting, event) => {
        let { checked } = event.target;

        FormActions.updateEditorSetting(setting, checked);
    };

    render() {
        let { fields, edit } = this.props;
        let { editorSettings } = this.state;

        return (
            <div className="form-sidebar">
                <div className="sidebar-content">
                    <h4>
                        <br />
                        {i18n('Drag and drop controls into the document')}
                    </h4>
                    <div>
                        <label className="custom-checkbox">
                            <span>{i18n('Show grid lines')}</span>
                            <input
                                name="required"
                                type="checkbox"
                                checked={editorSettings.showGrid}
                                onChange={this.updateSetting.bind(
                                    null,
                                    'showGrid'
                                )}
                            />
                            <span className="check">
                                <i className="fas fa-check" />
                            </span>
                        </label>
                        <br />
                        <label className="custom-checkbox">
                            <span>{i18n('Show field order')}</span>
                            <input
                                name="required"
                                type="checkbox"
                                checked={editorSettings.showOrder}
                                onChange={this.updateSetting.bind(
                                    null,
                                    'showOrder'
                                )}
                            />
                            <span className="check">
                                <i className="fas fa-check" />
                            </span>
                        </label>
                    </div>
                    <br />
                    <div className="inputs">
                        {fields.map((field, key) => (
                            <div key={key}>
                                <DraggableFields field={field} />
                            </div>
                        ))}
                    </div>
                    <hr />

                    <Button theme="green" fullWidth onClick={this.done}>
                        {i18n('Done')}
                    </Button>
                    <Button
                        theme="blue"
                        className="mt"
                        fullWidth
                        onClick={edit}
                        variant="outline">
                        {i18n('Edit template')}
                    </Button>
                </div>
            </div>
        );
    }
}
