import lodash from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { notify } from 'react-notify-toast';
import classnames from 'classnames';
import { i18n, TranslationStore } from 'Language';
import { AppDispatch } from 'Store';
import {
    UserEmailTemplate,
    TemplateType,
    EmailTemplateGroup,
} from 'types/EmailTemplates';
import TextInput from 'Common/components/TextInput';
import Message from 'Common/components/Message';
import Loading from 'Common/components/Loaders/LoadingData';
import { modal } from 'Common/components/Common/Modal';
import Button from 'Common/components/Button';
import InputValidation from 'Common/components/InputValidation';
import Tooltip from 'Common/components/Tooltip';
import ModalConfirm from 'Casefiles/Archive/ModalConfirm';
import {
    getUserEmailTemplates,
    updateUserEmailTemplate,
    deleteUserEmailTemplate,
    createUserEmailTemplate,
    createCustomerEmailTemplate,
} from 'EmailTemplates/redux/actions';
import DefaultTemplateSelector from 'EmailTemplates/components/DefaultTemplateSelector';
import { UserEntity as User, UserRoles } from 'types/User';
import './email-template-list.scss';

export type Props = {
    dispatch: AppDispatch;
    user: User;
    route: {
        forCustomer: boolean;
    };
};

type State = {
    templates: any[];
    isLoading: boolean;
    clicked: boolean;
    selectedTemplate: UserEmailTemplate & {
        id: number;
        default?: boolean;
        temporary?: boolean;
    };
    showMergeFields?: boolean;
};

export class EmailTemplateList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            templates: [],
            clicked: false,
            isLoading: true,
            selectedTemplate: {
                id: 0,
                title: '',
                subject: '',
                message: '',
            },
        };
    }

    async componentDidMount() {
        try {
            const language = TranslationStore.getLanguage();

            const templates = await this.props.dispatch(
                getUserEmailTemplates(EmailTemplateGroup.CASEFILE, language)
            );

            this.setState({
                // For now don't show any default templates other than
                // the one for the "Message to signers" type
                templates: templates.filter(
                    (template) =>
                        !template.default ||
                        template.type === TemplateType.SIGNER
                ),
                isLoading: false,
                // @todo: Selected template's type is not compatible with `EmailTemplate`,
                // This code still works with the type mismatch. `templates [0] as any` was added as part of PN-1152
                // which uncovered the typing issue after getting proper type definitions for redux state/actions.
                selectedTemplate: templates[0] as any,
            });
        } catch {
            notify.show(
                <span>{i18n('An error occurred, please try again')}</span>,
                'error',
                6000
            );
        }
    }

    handleOnTemplateClick = (selectedTemplate) => () =>
        this.setState({ selectedTemplate });

    handleUpdateTemplate = async () => {
        const { selectedTemplate } = this.state;

        try {
            await this.props.dispatch(
                updateUserEmailTemplate(selectedTemplate)
            );
            this.setState({
                templates: this.state.templates.map((item) => {
                    if (item.id === selectedTemplate.id) {
                        return {
                            ...item,
                            ...selectedTemplate,
                        };
                    }

                    return item;
                }),
            });

            notify.show(
                <span>{i18n('Changes successfully saved')}</span>,
                'success',
                3000
            );
        } catch {
            notify.show(
                <span>{i18n('An error occurred, please try again')}</span>,
                'error',
                6000
            );
        }
    };

    handleDeleteEmailTemplate = (templateId: number) => async () => {
        const { templates } = this.state;
        const findTemplate = (template) => template.id === templateId;
        const templateToDelete = templates.find(findTemplate);

        try {
            if (!templateToDelete.temporary) {
                await this.props.dispatch(deleteUserEmailTemplate(templateId));
            }

            this.setState({
                selectedTemplate: templates[0],
                templates: templates.filter(lodash.negate(findTemplate)),
            });

            notify.show(
                <span>{i18n('The selected template has been deleted')}</span>,
                'success',
                3000
            );
        } catch {
            notify.show(
                <span>{i18n('An error occurred, please try again')}</span>,
                'error',
                6000
            );
        }

        modal.hide();
    };

    handleDeleteEmailTemplateModal = (templateId: number) => () => {
        modal.show({
            body: (
                <ModalConfirm
                    message={i18n(
                        `Are you sure you want to delete this template?`
                    )}
                    confirmButtonText={i18n('Delete')}
                    onCancel={modal.hide}
                    onOk={this.handleDeleteEmailTemplate(templateId)}
                />
            ),
        });
    };

    handleInputOnChange = (
        _,
        { target }: React.ChangeEvent<HTMLInputElement>
    ) =>
        this.setState({
            selectedTemplate: {
                ...this.state.selectedTemplate,
                [target.name]: target.value,
            },
        });

    handleOnMessageChange = (e) =>
        this.setState({
            selectedTemplate: {
                ...this.state.selectedTemplate,
                message: e.target.value,
            },
        });

    handleCreateTemporaryTemplate = () => {
        const { templates } = this.state;

        const newTemplate = {
            id: templates.length + 2,
            title: `${i18n('My new email template')} - ${templates.length}`,
            subject: templates[0].subject,
            message: templates[0].message,
            temporary: true,
        };

        this.setState({
            selectedTemplate: newTemplate,
            templates: [...templates, newTemplate],
        });
    };

    handleCreateTemplate = async () => {
        try {
            const { dispatch } = this.props;
            const { templates, selectedTemplate } = this.state;

            const action = this.isCustomerMode()
                ? createCustomerEmailTemplate(selectedTemplate)
                : createUserEmailTemplate(selectedTemplate);

            // @todo: The return type from the actions aren't compatible with the `selectedTemplate`
            // This code still works with the type mismatch. The type cast to any (`: any`) was added as part of PN-1152
            // which uncovered the typing issue after getting proper type definitions for redux state/actions.
            const resp: any = await dispatch(action);

            // If we are creating a new template based on a selected template
            // that is not temporary (that is, it's already stored in the DB),
            // then it means that we are creating a copy off it that should be
            // appended to the list
            const isCopy = !selectedTemplate.temporary;

            this.setState({
                selectedTemplate: resp,
                templates: isCopy
                    ? [...templates, resp]
                    : templates.map((item) =>
                          item.id === selectedTemplate.id ? resp : item
                      ),
            });
            notify.show(
                <span>{i18n('Email template successfully created')}</span>,
                'success',
                3000
            );
        } catch {
            notify.show(
                <span>
                    {i18n('An error has occur, please try again later')}
                </span>,
                'error',
                6000
            );
        }
    };

    handleMergeFieldsContent = () =>
        this.setState({
            showMergeFields: !this.state.showMergeFields,
        });

    handleSubmit = async (e) => {
        e.preventDefault();

        const { selectedTemplate } = this.state;
        const canUpdate = this.canUserUpdateTemplate(selectedTemplate);

        await (!selectedTemplate.temporary && canUpdate
            ? this.handleUpdateTemplate()
            : this.handleCreateTemplate());
    };

    /**
     * When in customer mode, the only templates that can be listed and
     * created are the company templates
     *
     * @return {boolean}
     */
    isCustomerMode = (): boolean => {
        const { forCustomer } = this.props.route;

        return !!forCustomer;
    };

    /**
     * A user can update the given template if it's not a company template
     * or if it is a company template and we are in "customer mode"
     *
     * @param {object} template
     * @return {boolean}
     */
    canUserUpdateTemplate = (template): boolean => {
        const isCompanyTpl = this.isCompanyTemplate(template);

        return !isCompanyTpl || (isCompanyTpl && this.isCustomerMode());
    };

    isUserACustomerAdmin = (): boolean => {
        return this.props.user.role === UserRoles.CUSTOMER_ADMINISTRATOR;
    };

    /**
     * Checks if the given template is a company template
     *
     * A company template is a template that:
     *   * doesn't have a user id associated to it
     *   * is not the default template
     *   * is not being currently created (ie temporary)
     *
     * @param {object} template
     * @return {boolean}
     */
    isCompanyTemplate = (template): boolean => {
        const { temporary, userId } = template;

        return !temporary && !userId && !template.default;
    };

    renderTemplatesList = (): JSX.Element => {
        const { selectedTemplate, templates } = this.state;
        const tplsToShow = templates.filter(
            (template) =>
                template.default ||
                template.temporary ||
                (this.isCustomerMode()
                    ? this.isCompanyTemplate(template)
                    : template)
        );

        return (
            <ul className="panel-left-list">
                {tplsToShow.map((template, i) => {
                    const isActive = template.id === selectedTemplate.id;

                    return (
                        <li
                            key={template.title + i}
                            className={classnames({ active: isActive })}>
                            <div
                                className="email-title"
                                onClick={this.handleOnTemplateClick(template)}>
                                {template.temporary && (
                                    <sup className="new-temp">*</sup>
                                )}
                                {template.default
                                    ? i18n(template.title)
                                    : template.title}

                                {this.isCompanyTemplate(template) && (
                                    <Tooltip
                                        className="ml"
                                        text={i18n`Company template`}>
                                        <i className="fas fa-briefcase"></i>
                                    </Tooltip>
                                )}
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    };

    renderActions = (): JSX.Element => {
        const isCustomerMode = this.isCustomerMode();

        return (
            <div className="actions">
                <div className="actions-buttons mr"></div>
                <div className="actions-link pull-right">
                    {this.isUserACustomerAdmin() &&
                        (isCustomerMode ? (
                            <Link to="email-templates" className="text-blue mr">
                                {i18n`Manage your own templates`}{' '}
                                <i className="fas fa-arrow-right" />
                            </Link>
                        ) : (
                            <Link
                                to="company-email-templates"
                                className="text-blue mr">
                                {i18n`Manage company templates`}{' '}
                                <i className="fas fa-arrow-right" />
                            </Link>
                        ))}
                    <Button
                        className="ml"
                        variant="outline"
                        theme="blue"
                        icon="far fa-plus-circle"
                        data-testid="create-tpl"
                        onClick={this.handleCreateTemporaryTemplate}
                        renderIconLeft={true}>
                        {isCustomerMode
                            ? i18n`Create new company template`
                            : i18n`Create new template`}
                    </Button>
                </div>
            </div>
        );
    };

    renderMergeInstructions = (): JSX.Element => {
        const { showMergeFields } = this.state;

        return (
            <div
                className={classnames('merge-docs', {
                    active: showMergeFields,
                })}>
                <p>
                    {i18n`It is possible to customize email templates by using merge fields. You can use the following merge fields`}
                    <br />
                    {`{{recipient.name}} (${i18n`recipient name`})`}
                    <br />
                    {`{{recipient.email}} (${i18n`recipient email`})`}
                    <br />
                    {`{{sender.name}} (${i18n`sender name`})`}
                    <br />
                    {`{{sender.email}} (${i18n`sender email`})`}
                    <br />
                    {`{{casefile.id}} (${i18n`case file id`})`}
                    <br />
                    {`{{casefile.name}} (${i18n`case file name`})`}
                    <br />
                    {`{{casefile.expiry}} (${i18n`date and time when the case file can no longer be signed`})`}
                    <br />
                    {`{{signer.roles}} (${i18n`a comma separated list of signer roles`})`}
                    <br />
                    {`{{signers.list}} (${i18n`a comma separated list of the names of all signers`})`}
                    <br />
                    {`{{documents.list}} (${i18n`a comma separated list of the names of all documents`})`}
                    <br />
                    {`{{signature}} (${i18n`your email signature`})`}
                    <br />
                    {`{{link}} (${i18n`the link to the case file (if applicable)`})`}
                    <br />
                    <br />
                    <strong>{i18n`Example: "Dear {{recipient.name}}" becomes "Dear John Doe"`}</strong>
                </p>
            </div>
        );
    };

    renderMessages = (): JSX.Element => {
        const { selectedTemplate } = this.state;
        const { temporary } = selectedTemplate;
        const canUpdate = this.canUserUpdateTemplate(selectedTemplate);

        const DefaultTpl = () => (
            <Message type="info">
                {i18n`This is the default email template and you're not allowed to edit.`}
            </Message>
        );
        const UnsavedTpl = () => (
            <Message type="info">
                {i18n`This is a new email template that you have not saved yet.`}
            </Message>
        );
        const ReadOnlyTpl = () => (
            <Message type="info">
                <div>
                    {i18n`This template is read-only. When saved, a new personal template will be created from your changes.`}
                    {this.isUserACustomerAdmin() && (
                        <>
                            <br />
                            {i18n`As a company administrator, you can edit it`}
                            &nbsp;
                            <Link
                                to="company-email-templates"
                                className="penneo-message-link">{i18n`here`}</Link>
                            .
                        </>
                    )}
                </div>
            </Message>
        );

        return (
            <>
                {selectedTemplate.default && <DefaultTpl />}
                {temporary ? <UnsavedTpl /> : !canUpdate && <ReadOnlyTpl />}
            </>
        );
    };

    render() {
        const isDefaultTemplateSelected = this.state.selectedTemplate.default;
        const { isLoading, selectedTemplate, showMergeFields } = this.state;
        const isCustomerMode = this.isCustomerMode();
        const canUpdate = this.canUserUpdateTemplate(selectedTemplate);
        const { id, title, subject, message, temporary } = selectedTemplate;

        return (
            <div className="user-email-templates">
                {isLoading ? (
                    <div className="loading">
                        <Loading />
                    </div>
                ) : (
                    <>
                        {this.renderActions()}
                        <div className="panels-wrapper">
                            <div className="panel-left">
                                {this.renderTemplatesList()}
                            </div>
                            <div className="panel-right">
                                <Button
                                    className={'mb'}
                                    style={{ padding: 0, marginTop: 0 }}
                                    theme="blue"
                                    variant="text"
                                    onClick={this.handleMergeFieldsContent}
                                    icon={classnames('fas', {
                                        'fa-angle-up': showMergeFields,
                                        'fa-angle-down': !showMergeFields,
                                    })}>
                                    {i18n`Merge fields information`}
                                </Button>
                                {this.renderMergeInstructions()}
                                <form
                                    className="form-content"
                                    onSubmit={this.handleSubmit}>
                                    <div className="half-content-wrapper">
                                        <div className="half-content title">
                                            <InputValidation
                                                rules={[
                                                    {
                                                        error: {
                                                            message: i18n`Title cannot be empty`,
                                                        },
                                                        test: () => !title,
                                                    },
                                                ]}
                                                triggers={title}>
                                                <TextInput
                                                    name="title"
                                                    label={i18n('Title')}
                                                    value={
                                                        isDefaultTemplateSelected
                                                            ? i18n(title)
                                                            : title
                                                    }
                                                    onChange={
                                                        this.handleInputOnChange
                                                    }
                                                    required
                                                    disabled={
                                                        isDefaultTemplateSelected
                                                    }
                                                />
                                            </InputValidation>
                                        </div>
                                        <div className="half-content subject">
                                            <InputValidation
                                                rules={[
                                                    {
                                                        error: {
                                                            message: i18n`Subject cannot be empty`,
                                                        },
                                                        test: () => !subject,
                                                    },
                                                ]}
                                                triggers={[subject, title]}>
                                                <TextInput
                                                    name="subject"
                                                    label={i18n('Subject')}
                                                    value={subject}
                                                    onChange={
                                                        this.handleInputOnChange
                                                    }
                                                    required
                                                    disabled={
                                                        isDefaultTemplateSelected
                                                    }
                                                />
                                            </InputValidation>
                                        </div>
                                    </div>
                                    <label>{i18n('Message')}</label>
                                    <textarea
                                        value={message}
                                        onChange={this.handleOnMessageChange}
                                        disabled={
                                            isDefaultTemplateSelected
                                        }></textarea>

                                    {this.renderMessages()}

                                    <div className="pull-right-flex">
                                        <Button
                                            theme="red"
                                            type="button"
                                            variant="outline"
                                            disabled={
                                                isDefaultTemplateSelected ||
                                                !canUpdate
                                            }
                                            onClick={this.handleDeleteEmailTemplateModal(
                                                id
                                            )}>
                                            {i18n('Delete')}
                                        </Button>

                                        {!isDefaultTemplateSelected &&
                                            !temporary &&
                                            !isCustomerMode && (
                                                <DefaultTemplateSelector
                                                    template={selectedTemplate}
                                                />
                                            )}

                                        <Button
                                            theme="blue"
                                            className="ml"
                                            type="submit"
                                            disabled={
                                                isDefaultTemplateSelected
                                            }>
                                            {temporary || !canUpdate
                                                ? i18n('Create template')
                                                : i18n('Save changes')}
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default connect()(EmailTemplateList);
