import { Dispatcher } from 'Core';
import UserStore from '../stores/UserStore';
import { AuthAPI, SigningAPI } from 'Api';
import { translations } from 'Language/i18n';

const userActionCreators = {
    fetchUser(userId, customerId) {
        let userEndpoint = '/customers/' + customerId + '/users/' + userId;
        let roleEndpoint = userEndpoint + '/role';

        SigningAPI.get(userEndpoint).then((user) => {
            SigningAPI.get(roleEndpoint).then((response) => {
                // @todo: add roles per customerId
                user.role = response.role;
                Dispatcher.handleServerAction({
                    type: 'USER_LOADED',
                    user: user,
                });
            });
        });
    },

    fetchCurrentUser() {
        return AuthAPI.get('/user').then(async (user) => {
            let { customerIds } = user;
            let userId = user.id;

            await translations.changeLanguage(user.language);

            Dispatcher.handleServerAction({
                type: 'LANGUAGE_CHANGE',
                languageCode: user.language,
            });

            if (!customerIds?.length) {
                Dispatcher.handleServerAction({
                    type: 'CURRENT_USER_LOADED',
                    user: user,
                });

                return user;
            }

            let customerId = customerIds[0];
            let roleEndpoint =
                '/customers/' + customerId + '/users/' + userId + '/role';

            return SigningAPI.get(roleEndpoint).then((response) => {
                // @todo: add roles per customerId
                user.role = response.role;
                Dispatcher.handleServerAction({
                    type: 'CURRENT_USER_LOADED',
                    user: user,
                });

                return user;
            });
        });
    },

    updateUser(user, customerId, credentials, rights) {
        Dispatcher.handleViewAction({
            type: 'USER_UPDATE_REQUEST',
        });

        let _isCurrentUser = UserStore.isCurrentUser(user.id);
        let _isAdmin = user.admin || UserStore.isAdmin();

        // Update User Details
        let userDetails = {
            fullName: user.fullName,
            language: user.language,
            email: user.email,
            rights: rights,
            enabled: true,
        };

        let userUpdate;

        if (_isCurrentUser && !_isAdmin) {
            let userUpdatePayload = {
                fullName: user.fullName,
                language: user.language,
                email: user.email,
            };

            userUpdate = AuthAPI.patch('/user', userUpdatePayload);
        } else {
            let userEndpoint = '/customers/' + customerId + '/users/' + user.id;

            userUpdate = SigningAPI.put(userEndpoint, userDetails);
        }

        // Update User Role
        let roleEndpoint =
            '/customers/' + customerId + '/users/' + user.id + '/role';
        let roleUpdate;

        // Current user is not allowed to update his own role
        if (_isAdmin && !_isCurrentUser) {
            roleUpdate = SigningAPI.put(roleEndpoint, { role: user.role });
        } else {
            // return current user role (to avoid null value in User object)
            roleUpdate = Promise.resolve({ role: user.role });
        }

        // Update User Credentials
        let credentialUpdate;

        if (_isAdmin) {
            if (credentials.id) {
                credentialUpdate = AuthAPI.put(
                    '/credentials/' + credentials.id,
                    credentials
                );
            } else {
                credentialUpdate = AuthAPI.post('/credentials', {
                    userId: user.id,
                    customerId: customerId,
                    allowed: credentials.allowed,
                });
            }
        } else {
            credentialUpdate = Promise.resolve();
        }

        Dispatcher.handleServerAction({
            type: 'USER_UPDATE_REQUEST',
            status: 'request',
        });

        // Wait for all requests to finish
        Promise.all([userUpdate, roleUpdate, credentialUpdate])
            .then((response) => {
                if (_isAdmin) {
                    Dispatcher.handleServerAction({
                        type: 'USER_CREDENTIALS_LOADED',
                        userId: user.id,
                        credentials: response[2],
                    });
                }

                Dispatcher.handleServerAction({
                    type: 'USER_CREDENTIALS_UPDATED',
                });

                let userObject = response[0];

                userObject.role = response[1] ? response[1].role : null;

                Dispatcher.handleServerAction({
                    type: 'USER_UPDATE_SUCCESS',
                    user: userObject,
                    status: 'success',
                });

                // Update Current user in UserStore if it's updated.
                if (_isCurrentUser) {
                    Dispatcher.handleServerAction({
                        type: 'CURRENT_USER_LOADED',
                        user: userObject,
                    });
                }
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: 'USER_UPDATE_FAILURE',
                    status: 'failure',
                    error: error,
                });
            });
    },

    fetchUserRole(customerId, userId) {
        SigningAPI.get(`/customers/${customerId}/users/${userId}/role`).then(
            (response) => {
                Dispatcher.handleServerAction({
                    type: 'USER_ROLE_LOADED',
                    customerId: response.customerId,
                    userId: response.userId,
                    role: response.role,
                });
            }
        );
    },
};

export default userActionCreators;
