import { Dispatcher, BaseStore } from 'Core';
import assign from 'object-assign';

let _requestStatus = null;
let _request = null;

function updateRequestStatus(request, requestStatus) {
    _requestStatus = requestStatus;
    _request = request;
}

function clearRequestStatus() {
    _requestStatus = null;
    RequestStore.emitChange();
}

const RequestStore = assign({}, BaseStore, {
    getRequest() {
        return _request;
    },

    getRequestStatus() {
        return _requestStatus;
    },

    clearRequestStatus() {
        clearRequestStatus();
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case 'CREDENTIAL_REQUEST_REQUEST':
            case 'CREDENTIAL_REQUEST_SUCCESS':
            case 'CREDENTIAL_REQUEST_FAILURE':
                updateRequestStatus(action.request, action.status);
                break;
            default:
                return false;
        }
        RequestStore.emitChange();
    }),
});

export default RequestStore;
