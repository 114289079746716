import PropTypes from 'prop-types';
import React from 'react';
import Constants from 'Constants';
import scrollTo from '../../utils/scrollTo';

export default class DocumentNavigator extends React.Component {
    static propTypes = {
        viewerId: PropTypes.string,
        form: PropTypes.object,
        pages: PropTypes.array,
        timestamp: PropTypes.number,
    };

    state = {
        selectedIndex: 0,
    };

    pageSelector = () => {
        let { selectedIndex } = this.state;
        let { pages } = this.props;

        return (
            <div>
                {pages.map((page, index) => (
                    <div
                        key={index}
                        style={this.getPageContent(page)}
                        className={
                            'page ' + (selectedIndex === index && ' selected')
                        }
                        onClick={this.smoothScrollTo.bind(this, index)}>
                        <div className="page-number">{index + 1}</div>
                    </div>
                ))}
            </div>
        );
    };

    smoothScrollTo = (index) => {
        let elementId = 'page' + index;
        let page = document.getElementById(elementId).getBoundingClientRect();

        let viewer = document
            .getElementById('scalable')
            .getBoundingClientRect();

        let distanceY = page.top - viewer.top;

        scrollTo('#viewer', distanceY);

        this.setState({ selectedIndex: index });
    };

    /**
     * Renders the document page as a background image
     * @param page {object} Page object containing the URL reference to the page's image.
     *
     * @return {style} JSX-Formatted Inline Style to apply background image to current page
     */
    getPageContent = (page) => {
        let { timestamp } = this.props;
        let url = Constants.PENNEO_ORIGIN + '/form' + page + '?t=' + timestamp;

        return {
            backgroundImage: "url('" + url + "')",
            backgroundSize: 'cover',
        };
    };

    getDocumentTitle = (fileName) => {
        let extensionLength = 4;
        let displayName = fileName.substr(0, fileName.length - extensionLength);

        return displayName;
    };

    render() {
        let { form } = this.props;

        if (!form) {
            return false;
        }

        return (
            <div className="document-navigator">
                <div className="table-cell-content">
                    <h4 className="document-title">{form.title}</h4>
                    <div className="page-selector">{this.pageSelector()}</div>
                </div>
            </div>
        );
    }
}
