import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RegisteredLetterMessageValidation } from 'types/DataValidation';
import { Languages } from 'Language/Constants';
import { i18n } from 'Language';
import { ReduxState, AppDispatch } from 'Store';
import { getUserEmailTemplates } from 'EmailTemplates/redux/actions';
import {
    EmailTemplate,
    EmailTemplateGroup,
    EmailTemplates,
    TemplateType,
} from 'types/EmailTemplates';
import { emailTemplateUpdated } from 'RegisteredLetter/redux/reducer';
import PersonalizedMessageEditor, {
    Tab,
} from 'Common/components/PersonalizedMessageEditor';

export type Props = {
    dispatch: AppDispatch;
    language: string;
    isLoading: boolean;
    userEmailTemplates: EmailTemplate[];
    registeredLetterEmailTemplates: EmailTemplates;
    emailTemplatesValidation: RegisteredLetterMessageValidation;
};

export function RegisteredLetterEmailTemplate(props: Props) {
    useEffect(() => {
        const { dispatch } = props;

        async function setUpOnMount() {
            await dispatch(
                getUserEmailTemplates(
                    EmailTemplateGroup.REGISTERED_LETTER,
                    Languages.EN
                )
            );
        }

        setUpOnMount();
    }, []);

    const handleUpdateEmailTemplate = (
        type: TemplateType,
        template: EmailTemplate
    ) => {
        const { dispatch } = props;

        dispatch(emailTemplateUpdated({ type, template }));
    };

    const {
        userEmailTemplates = [],
        registeredLetterEmailTemplates,
        emailTemplatesValidation,
        language,
        isLoading,
    } = props;

    const tabs: Tab[] = [
        {
            id: TemplateType.INITIAL,
            valid: emailTemplatesValidation[TemplateType.INITIAL].valid,
            label: 'Initial message to recipients',
            tagline:
                'This message will be sent out to all recipients via email to notify them they have documents to read',
        },
    ];

    return (
        <div>
            <h4 className="section-header mb0">{i18n`Personalize email`}</h4>

            {!isLoading && (
                <PersonalizedMessageEditor
                    tabs={tabs}
                    language={language}
                    group={EmailTemplateGroup.REGISTERED_LETTER}
                    onTemplateChange={handleUpdateEmailTemplate}
                    availableTemplates={userEmailTemplates}
                    selectedTemplates={registeredLetterEmailTemplates}
                    templatesValidation={emailTemplatesValidation}
                    showSignatureNotice={false}
                />
            )}
        </div>
    );
}

export default connect((state: ReduxState) => ({
    emailTemplatesValidation:
        state.registeredLetter.view.validation.emailTemplates,
    isLoading: state.email.isLoading,
    language: state.registeredLetter.instance.language,
    registeredLetterEmailTemplates:
        state.registeredLetter.instance.emailTemplates,
    userEmailTemplates: state.email.templates || [],
}))(RegisteredLetterEmailTemplate);
