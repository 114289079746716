import { NewSignerAPI } from 'Api';
import launchDarkly, { Flags } from '../../Common/LaunchDarkly';

export const recordIdToken = async (
    challengeKey: string,
    idToken: string
): Promise<void> => {
    if (!launchDarkly.variation(Flags.OIDC_ID_TOKEN_RECORDING)) {
        return;
    }

    try {
        await NewSignerAPI.post(
            `/v2/signing-process/${challengeKey}/record-id-token`,
            {
                id_token: idToken,
            }
        );
    } catch (error) {
        console.error('Failed to record ID token');
    }
};
