import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { i18n } from 'Language';

// State
import { dispatch } from 'Store';
import { getCasesCompletedRecently } from 'Casefiles/Archive/redux/reducer';

// Components
import DashboardWidgetContainer, {
    DashboardWidget,
} from './DashboardWidgetContainer';
import { getSortedByExpiration, renderLoading } from './common';

import './completed-recently-widget.scss';
import { trackDashboardInteraction } from './utils';

type State = {
    activity: any;
    isLoaded: boolean;
};
export default class CompletedRecentlyWidget extends React.Component<
    {},
    State
> {
    days: number;
    mounted: boolean;

    constructor(props = {}) {
        super(props);

        this.state = {
            activity: {
                data: [],
            },
            isLoaded: false,
        };
        this.days = 14;
        this.mounted = true;
    }

    componentDidMount() {
        this.init();
    }

    async init() {
        const activity = await dispatch(
            getCasesCompletedRecently(this.days, 10)
        );

        this.mounted && this.setState({ activity, isLoaded: true });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    renderItem(item) {
        return (
            <li className="widget-entry" key={item.id}>
                <p className="widget-entry-title">
                    <span className="widget-entry-metadata">
                        <Link
                            data-testid={`item`}
                            to={{
                                name: 'archive-casefile-details',
                                params: {
                                    casefileId: item.id,
                                },
                            }}
                            onClick={() => {
                                trackDashboardInteraction(
                                    'Recently Completed widget clicked',
                                    {
                                        id: item.id,
                                        completedAt: item.completed,
                                    }
                                );
                            }}>
                            {item.title}
                        </Link>
                        <br aria-hidden={true} />
                        <span>
                            {i18n`Completed`}{' '}
                            {moment.unix(item.completed).fromNow()}
                        </span>
                    </span>
                </p>
                <p
                    className="widget-entry-details"
                    aria-label={moment
                        .unix(item.completed)
                        .format('Do MMM YYYY')}>
                    <span className="widget-entry-date" aria-hidden={true}>
                        {moment.unix(item.completed).format('DD/MM/YYYY')}
                    </span>
                </p>
            </li>
        );
    }

    renderContent() {
        const { activity, isLoaded } = this.state;
        const items = getSortedByExpiration(activity.data);
        const isEmpty = !items.length;

        return (
            <DashboardWidget
                title={i18n`Cases recently completed`}
                footer={
                    <>
                        {!isEmpty && (
                            <Link
                                to={{
                                    name: 'archive-virtual',
                                    params: {
                                        tab: 'completed',
                                    },
                                }}
                                onClick={() =>
                                    trackDashboardInteraction('navigation', {
                                        source: 'Recently Completed widget',
                                        to: 'archive',
                                    })
                                }>
                                {i18n`View all completed cases`}
                            </Link>
                        )}
                    </>
                }>
                {isLoaded === false ? (
                    renderLoading()
                ) : (
                    <ul>
                        {isEmpty ? (
                            <li className="widget-empty">
                                <p>
                                    <i className="fal fa-tasks" />
                                    {i18n`No cases have been completed in the last ${this.days} days`}
                                </p>
                                <p>
                                    <Link
                                        to={{
                                            name: 'archive-virtual',
                                            params: {
                                                tab: 'completed',
                                            },
                                        }}
                                        className="underline-link">
                                        {i18n`View all completed cases`}
                                    </Link>
                                </p>
                            </li>
                        ) : (
                            items.map((item) => this.renderItem(item))
                        )}
                    </ul>
                )}
            </DashboardWidget>
        );
    }

    render() {
        return (
            <DashboardWidgetContainer
                title={i18n`Cases recently completed`}
                className="new-completed-recently-widget"
                content={[this.renderContent()]}
            />
        );
    }
}
