import React from 'react';
import PropTypes from 'prop-types';

import { i18n, LanguageDropdown } from 'Language';

// Redux
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';

import FolderSelector from 'Common/components/FolderSelector';
import { TextInput, NumberInput } from 'Common/components';
import { fetchFolders } from 'Common/redux/Folder/actions';
import { FolderItem, FolderState } from 'Common/redux/Folder/types';

import {
    updateTitle,
    updateFolderId,
    updateReminderInterval,
} from 'KYC/redux/actions';

import './kyc-details.scss';

export type Props = {
    dispatch: AppDispatch;
    folders: FolderState;
    title: string;
    language: string;
    folderId: null | number;
    reminderInterval: number;
    onLanguageChange: (language: string) => void;
};

type State = {
    hasError: boolean;
};

export class KYCDetails extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidMount() {
        this.fetchFolders();
    }

    componentDidUpdate() {
        const { folderId, folders } = this.props;

        // Set a default folder
        if (!folderId && folders.defaultFolder) {
            this.handleFolderChange(folders.defaultFolder);
        }
    }

    handleTitleChange = (title: string) => {
        const { dispatch } = this.props;

        dispatch(updateTitle(title));
    };

    handleFolderChange = (folder: FolderItem) => {
        const { dispatch } = this.props;

        dispatch(updateFolderId(folder.id));
    };

    handleReminderIntervalChange = (reminderInterval: number) => {
        const { dispatch } = this.props;

        dispatch(updateReminderInterval(reminderInterval));
    };

    fetchFolders() {
        const { dispatch } = this.props;

        dispatch(fetchFolders());
    }

    render() {
        const {
            title,
            folders,
            folderId,
            reminderInterval,
            language,
            onLanguageChange,
        } = this.props;

        return (
            <div className="kyc-details">
                <div className="columns mb">
                    <div className="column-2">
                        <TextInput
                            label={i18n`Title`}
                            placeholder={i18n`Enter a title`}
                            required={true}
                            value={title}
                            onChange={this.handleTitleChange}
                        />
                    </div>
                    <div className="column-2">
                        <FolderSelector
                            label={i18n`Select a folder`}
                            folders={folders}
                            value={folderId}
                            onChange={this.handleFolderChange}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column-2">
                        <label>{i18n('Language')}</label>
                        <LanguageDropdown
                            type="standalone"
                            name="language"
                            value={language}
                            skipInitCallback={true}
                            callback={onLanguageChange}
                        />
                    </div>
                    <div className="column-2">
                        <label className="label">
                            {i18n`Automatic reminders`}
                        </label>
                        <span>
                            {i18n`Send reminders every
                            ${(
                                <NumberInput
                                    min={0}
                                    max={365}
                                    onChange={this.handleReminderIntervalChange}
                                    value={reminderInterval}
                                />
                            )} days`}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ReduxState) => {
    return {
        language: state.kyc.__new.language,
        title: state.kyc.__new.title,
        reminderInterval: state.kyc.__new.reminderInterval,
        folderId: state.kyc.__new.folderId,
        folders: state.folders,
    };
})(KYCDetails);
