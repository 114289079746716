import React from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import LocaleProvider from 'antd/lib/locale-provider';
import { i18n, TranslationStore } from 'Language';
import Button from 'Common/components/Button';

// dictionaries from antd dependency
import enUS from 'antd/lib/locale-provider/en_US';
import svSE from 'antd/lib/locale-provider/sv_SE';
import nl_BE from 'antd/lib/locale-provider/nl_BE';
import fr_FR from 'antd/lib/locale-provider/fr_FR';
import de_DE from 'antd/lib/locale-provider/de_DE';
// antd doesn't support Norwegian and Danish so those are made locally, based on antd implementation
import nbNO from '../../vendor/antd/nb_NO.js';
import daDK from '../../vendor/antd/da_DK.js';

export type Props = {
    onDateChange: (date: Moment, roleId?: number) => void;
    onDateClear: (roleId?: number) => void;
    activationDate?: Moment;
    roleIdToUpdate?: number;
};

export function ActivationDateModify(props: Props) {
    const { activationDate, roleIdToUpdate, onDateChange, onDateClear } = props;

    // Sets language of week days and months and the strings in the calendar
    const getCalendarLanguage = () => {
        const languageCode = TranslationStore.getLanguage();

        const languageMap = {
            da: daDK,
            no: nbNO,
            sv: svSE,
            fr: fr_FR,
            nl: nl_BE,
            de: de_DE,
        };

        return languageMap?.[languageCode] ?? enUS;
    };

    const disabledDate = (dateToPotentiallyDisable: Moment) => {
        if (moment().isSameOrBefore(dateToPotentiallyDisable, 'day')) {
            return false;
        }

        return true;
    };

    const disabledTime = (selectedDate: Moment) => {
        const range = (start: number, end: number) => {
            const result: number[] = [];

            for (let i = start; i < end; i++) {
                result.push(i);
            }

            return result;
        };

        const timeNow = moment();
        const hours = timeNow.hour();
        const minutes = timeNow.minute();

        // When selecting same day
        if (selectedDate?.isSame(moment(), 'day')) {
            // When selecting same hour
            if (selectedDate?.isSame(moment(), 'hour')) {
                // Disable all past hours and minutes
                return {
                    disabledHours: () => range(0, hours),
                    disabledMinutes: () => range(0, minutes),
                };
            }

            // Disable all past hours
            return {
                disabledHours: () => range(0, hours),
            };
        }

        // When not selecting same day, enable all times
        return {};
    };

    const handleOnDateChange = (date: Moment) => {
        if (!roleIdToUpdate) {
            return onDateChange(date);
        } else {
            return onDateChange(date, roleIdToUpdate);
        }
    };

    const handleOnDateClear = () => {
        if (!roleIdToUpdate) {
            return onDateClear();
        } else {
            return onDateClear(roleIdToUpdate);
        }
    };

    return (
        <div
            data-testid="activation-date-container"
            className="activation-date-container mb">
            <div className="activation-date-picker">
                <LocaleProvider locale={getCalendarLanguage()}>
                    <DatePicker
                        allowClear={false}
                        disabled={false}
                        disabledDate={disabledDate}
                        disabledTime={disabledTime}
                        format="YYYY-MM-DD HH:mm"
                        onChange={handleOnDateChange}
                        showTime={{
                            format: 'HH:mm',
                            hideDisabledOptions: true,
                        }}
                        showToday={true}
                        value={activationDate}
                    />
                </LocaleProvider>
                <Button
                    className="clear-date-btn"
                    onClick={handleOnDateClear}
                    size="small"
                    variant="outline">
                    {i18n`Clear date`}
                </Button>
            </div>
        </div>
    );
}
