import React, { useEffect, useState } from 'react';
import { i18n, TranslationActions } from 'Language';
import { Helmet } from 'react-helmet';
import { SigningProcess, SigningProcessValidation } from 'types/SigningProcess';
import { SigningHeader } from './SigningHeader';
import {
    getExpectedIdentifierType,
    getSignerLanguage,
    isV2Signing,
    setSignerLanguage,
    signingBranding,
    trackSigning,
    V2Validation,
} from './utils';
import { NewSignerAPI } from 'Api';
import { SigningValidationMethods } from './SigningValidationMethods';
import AuthActions from 'Auth/actions/AuthActionCreators';
import { clearOpenIdState } from 'OpenID/utils/openIdState';
import { notify } from 'react-notify-toast';

import './signing-validation.scss';
import { Languages } from 'Language/Constants';
import { clearStoredItsmeState } from 'ItsmeQES/storedState';
import { UserEntity } from '../types/User';
import launchDarkly from '../Common/LaunchDarkly';

export type ForbiddenSigningRequest = {
    status: number;
    data: SigningProcessValidation;
};

export type Props = {
    params: {
        challengeKey: string;
    };
};

const SigningValidation = ({ params: { challengeKey } }: Props) => {
    const [casefileData, setCasefileData] = useState<
        SigningProcessValidation
    >();
    // This is only set to force a re-render after the user selects a language.
    const [_, setUserSelectedLanguage] = useState(''); // eslint-disable-line

    /**
     * We store the signing url, so when there's a successful validation
     * we are sent back there.
     * NOTE: we remove the search parameters, to prevent appending the ?validate_error=true
     * of a previous failed validation
     */
    const currentUrl = window.location.href.replace(window.location.search, '');
    const successUrl = currentUrl.replace('/signing/validation/', '/signing/');
    const [translated, setTranslated] = useState(false);

    useEffect(() => {
        // cleanup first
        isV2Signing.clear();
        clearOpenIdState();
        clearStoredItsmeState();

        trackSigning('Validation page');

        AuthActions.setLoginSuccessUrl(successUrl);
    }, [successUrl]);

    useEffect(() => {
        (async () => {
            /**
             * This API request is meant to fail and return 403 Forbidden,
             * that's why we retrieve all data by handling the 'error'
             */
            try {
                const caseData: SigningProcess = await NewSignerAPI.get(
                    `/v2/signing-process/${challengeKey}`
                );

                /**
                 * If for some reason we end up in the Validation page but we are already validated
                 * we redirect the user to the signing page
                 */
                if (caseData?.caseFile) {
                    window.location.assign(successUrl);
                }
            } catch (error) {
                /**
                 * We check if the error is a validation one (403) and if it contains the expected data.
                 * If not, we delete the storage and refresh the page
                 */
                if (error?.status === 403) {
                    if (error?.data?.isSpecificSignerExpected !== undefined) {
                        const caseData = (error as ForbiddenSigningRequest)
                            .data;

                        if (caseData?.customer?.id) {
                            const user: Partial<UserEntity> = {
                                customerIds: [caseData.customer.id],
                            };

                            await launchDarkly.identify(user as UserEntity);
                        }

                        setCasefileData(caseData);
                    } else {
                        V2Validation.clear();
                        window.location.reload();
                    }
                }
            }
        })();
    }, [challengeKey, successUrl]);

    useEffect(() => {
        if (!casefileData) {
            return;
        }

        (async () => {
            if (!translated) {
                const language =
                    casefileData.signingRequest?.language ||
                    getSignerLanguage() ||
                    casefileData.language;

                await TranslationActions.changeLanguageView(language);

                // we need to use the boolean to force re-render after Flux translation
                setTranslated(true);
            }
        })();
    }, [casefileData, translated]);

    useEffect(() => {
        /**
         * Check if the user tried to validate their ID with a wrong ssn/vatin
         * If so, we show an alert.
         * NOTE: we trigger this error only AFTER the interface is translated
         * to prevent message always showing in English
         */
        const params = new URLSearchParams(window.location.search);
        const validationError = params.get('validate_error');

        if (
            validationError !== null &&
            validationError === 'true' &&
            translated
        ) {
            notify.show(
                i18n`It looks like you don't have access. You can contact the sender in case you think this is a mistake.`,
                'error',
                -1
            );
        }
    }, [translated]);

    async function changeLanguage(languageCode: Languages) {
        await TranslationActions.changeLanguageView(languageCode);

        setSignerLanguage(languageCode);

        setUserSelectedLanguage(languageCode);
    }

    if (!casefileData) {
        return null;
    }

    const {
        allowedSigningMethods,
        customer,
        language,
        encryptedNIN,
        maskedPhoneNumber,
        isRegisteredLetter,
    } = casefileData;

    const branding = signingBranding(customer.branding);

    const requestedIdentifierType = getExpectedIdentifierType(casefileData);

    return translated ? (
        <>
            <Helmet>
                <title>{i18n`Validate your identity`}</title>
            </Helmet>

            <SigningHeader
                branding={branding}
                changeLanguage={changeLanguage}
            />

            <div className="signing-validation-container">
                <div className="signing-validation">
                    <div className="signing-validation-background"></div>
                    <div className="signing-validation-content">
                        <div className="signing-validation-header">
                            <h1>
                                {i18n`The sender requests you to confirm your identity.`}
                            </h1>
                            <h3>
                                {i18n`We ask you to confirm your identity by logging in with your eID below.`}
                            </h3>
                        </div>

                        <SigningValidationMethods
                            signingMethodsAllowedByCustomer={
                                allowedSigningMethods
                            }
                            requestedIdentifierType={requestedIdentifierType}
                            languageCode={language}
                            challengeKey={challengeKey}
                            encryptedNIN={encryptedNIN}
                            maskedPhoneNumber={maskedPhoneNumber}
                            isRegisteredLetter={isRegisteredLetter}
                        />
                    </div>
                </div>
            </div>

            <div
                className="signing-footer"
                style={{ backgroundColor: branding.backgroundColor }}></div>
        </>
    ) : null;
};

export default SigningValidation;
