import { Dispatcher, BaseStore } from 'Core';
import Constants from '../Constants';
import assign from 'object-assign';

// Data Storage
let _messageTemplates = [];

function updateMessageTemplates(templates) {
    _messageTemplates = templates;
}

const MessageTemplateStore = assign({}, BaseStore, {
    getMessageTemplates() {
        return _messageTemplates;
    },

    getMessageTemplate(templateId) {
        return _messageTemplates[templateId];
    },

    clearStore() {
        _messageTemplates = [];
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.FETCH_MESSAGE_TEMPLATES_SUCCESS:
                if (action.templates) {
                    updateMessageTemplates(action.templates);
                }

                break;
            default:
                return;
        }
        MessageTemplateStore.emitChange();
    }),
});

export default MessageTemplateStore;
