import React from 'react';
import { images } from 'Constants';
import { SigningMethod } from 'EID';
import { i18n } from 'Language';

type EutlServiceWrapperProps = {
    method: SigningMethod;
    children: JSX.Element | JSX.Element[];
};

export const EutlServiceWrapper = ({
    method,
    children,
}: EutlServiceWrapperProps) => (
    <>
        <div
            className={`penneo-react-context signing-components ui-v2 ${
                method?.type ?? ''
            }`}>
            <div className="penneo-auth penneo-casefiles ui-v2">
                <div className="openid-sign">
                    <div className="openid-sign-container">
                        <img
                            className="openid-sign-container-logo"
                            width="90"
                            src={method.logo}
                            alt={method.title}
                        />
                        {children}
                    </div>
                </div>
                <br />

                <p className="text-center">
                    <a
                        href="https://penneo.com"
                        target="_blank"
                        rel="noopener noreferrer">
                        <span
                            style={{
                                opacity: 0.75,
                                fontSize: '12px',
                                color: '#333',
                            }}>
                            {i18n`Service provided by`}
                            &nbsp;
                        </span>
                        <img
                            style={{
                                position: 'relative',
                                top: '1px',
                                left: '2px',
                            }}
                            src={`${images}/penneo-blue.png`}
                            height="10"
                            alt="penneo.com"
                        />
                    </a>
                </p>
            </div>
        </div>
    </>
);

export default EutlServiceWrapper;
