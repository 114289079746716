import React from 'react';
import type { ItsmeErrorType } from '../errors';
import { invalidState } from '../errors';
import { i18n } from 'Language';
import Button from '../../Common/components/Button';
import Message from '../../Common/components/Message';
import { useAppDispatch, useAppSelector } from 'Store';
import { loadState, StateType } from 'ItsmeQES/redux/reducer';
import { clearStoredItsmeState } from 'ItsmeQES/storedState';

type Props = {
    error: ItsmeErrorType;
    redirectUrl?: string | null;
    onRetry?: Function;
};

export function ItsmeErrorFromRedux() {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.itsmeQESSigning);

    if (state.type !== StateType.Error) {
        // How did we even end up here?
        const invalidErrorStateError = invalidState(
            state.type,
            'trying to display another error',
            StateType.Error
        );

        return <ItsmeError error={invalidErrorStateError} />;
    }

    const { error } = state;

    const retry = () => {
        if (!state.base) {
            return;
        }

        const { challengeKey, language } = state.base;

        dispatch(loadState(challengeKey, language));
    };

    return <ItsmeError error={error} onRetry={retry} />;
}

export default function ItsmeError(props: Props) {
    const { error, redirectUrl, onRetry } = props;
    const urlPath = window.location.pathname;

    const traceId = error.headers ? error.headers['x-b3-traceid'] : null;
    const requestId = error.headers
        ? error.headers['x-penneo-request-id']
        : null;

    const handleRedirect = () => {
        if (!redirectUrl) return;

        clearStoredItsmeState();

        window.location.href = redirectUrl;
    };

    const handleRetry = () => {
        if (onRetry) {
            onRetry();
        }
    };

    return (
        <div className="text-center" data-testid="itsme-error-component">
            <h2 className="title">
                {error.message
                    ? i18n(error.message)
                    : i18n(
                          'There was a problem processing your signing request'
                      )}
            </h2>

            {error.details && (
                <Message type="error">
                    <div>
                        {error.details
                            ? i18n(error.details)
                            : i18n(
                                  'Please, return to sign your documents and try again. If the problem persists, contact support'
                              )}
                    </div>
                </Message>
            )}

            {redirectUrl && (
                <Button className="mt" theme="green" onClick={handleRedirect}>
                    {i18n('Return to documents')}
                </Button>
            )}

            {onRetry && (
                <Button
                    className="mt"
                    theme="green"
                    icon="far fa-sync"
                    onClick={handleRetry}>
                    {i18n('Try again')}
                </Button>
            )}

            <div>
                {traceId && (
                    <div className="mt openid-error-details-traceid">
                        {requestId}
                        {traceId && `:${traceId}`}
                    </div>
                )}
            </div>

            <div className="mt openid-error-details-traceid">
                {urlPath} <br />
                {error.type && error.type}
            </div>

            <div>
                {error.showResponse && (
                    <div className="mt openid-error-details-traceid">
                        {JSON.stringify(error)}
                    </div>
                )}
            </div>
        </div>
    );
}
