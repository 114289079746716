import React from 'react';
import CKEditor from 'ckeditor4-react';

// @NOTE The endpoint was also used in the old UI and will be
// eventually deprecated in favour of a REST-ful one
const IMAGE_UPLOAD_ENDPOINT = `/company/emailSignature/uploadImage`;

export const WYSIWYG_CONFIG = {
    toolbarGroups: [
        { name: 'basicstyles' },
        { name: 'styles' },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
        { name: 'links' },
        { name: 'insert' },
    ],
    height: 300,
    filebrowserUploadUrl: IMAGE_UPLOAD_ENDPOINT,
    // To disable height/wight styling using css. We do this
    // because outlook ignores height/width styles
    disallowedContent: 'img{width,height}',
    // To allow height/width resizing. This is required if
    // disallowedContent is used to disable height/width resizing
    // using css styles
    extraAllowedContent: 'img[width,height]',
};

/**
 * Wraps a `<CKEditor />` component, providing a shared default configuration
 *
 * Any given prop will be passed down to `<CKEditor />` as-is, except for
 * `[config]` which instead will be mixed with the default configuration
 *
 * @example
 *   const content = 'some content';
 *   // Sets a custom height, rest of the config is the default one
 *   const customConfig = { height: 100 }
 *   const onChange = () => { console.log('content changed') }
 *
 *   <HTMLEditor data={content} config={customConfig} onChange={onChange} />
 */
const HTMLEditor: React.FunctionComponent<any> = (props) => {
    const { config, ...rest } = props;
    const finalConfig = { ...WYSIWYG_CONFIG, ...config };

    return <CKEditor config={finalConfig} {...rest} />;
};

export default HTMLEditor;
