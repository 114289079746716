import { Country } from '../types/Country';
export {
    CountryCode,
    getCountryCallingCode,
    parsePhoneNumber,
    PhoneNumber,
} from 'libphonenumber-js/mobile';

const countries: Country[] = [
    {
        name: 'Afghanistan',
        code: 'AF',
        flag: '🇦🇫',
    },
    {
        name: 'Albania',
        code: 'AL',
        flag: '🇦🇱',
    },
    {
        name: 'Algeria',
        code: 'DZ',
        flag: '🇩🇿',
    },
    {
        name: 'American Samoa',
        code: 'AS',
        flag: '🇦🇸',
    },
    {
        name: 'Andorra',
        code: 'AD',
        flag: '🇦🇩',
    },
    {
        name: 'Angola',
        code: 'AO',
        flag: '🇦🇴',
    },
    {
        name: 'Anguilla',
        code: 'AI',
        flag: '🇦🇮',
    },
    {
        name: 'Antigua and Barbuda',
        code: 'AG',
        flag: '🇦🇬',
    },
    {
        name: 'Argentina',
        code: 'AR',
        flag: '🇦🇷',
    },
    {
        name: 'Armenia',
        code: 'AM',
        flag: '🇦🇲',
    },
    {
        name: 'Aruba',
        code: 'AW',
        flag: '🇦🇼',
    },
    {
        name: 'Australia',
        code: 'AU',
        flag: '🇦🇺',
    },
    {
        name: 'Austria',
        code: 'AT',
        flag: '🇦🇹',
    },
    {
        name: 'Azerbaijan',
        code: 'AZ',
        flag: '🇦🇿',
    },
    {
        name: 'Bahamas',
        code: 'BS',
        flag: '🇧🇸',
    },
    {
        name: 'Bahrain',
        code: 'BH',
        flag: '🇧🇭',
    },
    {
        name: 'Bangladesh',
        code: 'BD',
        flag: '🇧🇩',
    },
    {
        name: 'Barbados',
        code: 'BB',
        flag: '🇧🇧',
    },
    {
        name: 'Belarus',
        code: 'BY',
        flag: '🇧🇾',
    },
    {
        name: 'Belgium',
        code: 'BE',
        flag: '🇧🇪',
    },
    {
        name: 'Belize',
        code: 'BZ',
        flag: '🇧🇿',
    },
    {
        name: 'Benin',
        code: 'BJ',
        flag: '🇧🇯',
    },
    {
        name: 'Bermuda',
        code: 'BM',
        flag: '🇧🇲',
    },
    {
        name: 'Bhutan',
        code: 'BT',
        flag: '🇧🇹',
    },
    {
        name: 'Bolivia',
        code: 'BO',
        flag: '🇧🇴',
    },
    {
        name: 'Bosnia and Herzegovina',
        code: 'BA',
        flag: '🇧🇦',
    },
    {
        name: 'Botswana',
        code: 'BW',
        flag: '🇧🇼',
    },
    {
        name: 'Brazil',
        code: 'BR',
        flag: '🇧🇷',
    },
    {
        name: 'British Indian Ocean Territory',
        code: 'IO',
        flag: '🇮🇴',
    },
    {
        name: 'Brunei',
        code: 'BN',
        flag: '🇧🇳',
    },
    {
        name: 'Bulgaria',
        code: 'BG',
        flag: '🇧🇬',
    },
    {
        name: 'Burkina Faso',
        code: 'BF',
        flag: '🇧🇫',
    },
    {
        name: 'Burundi',
        code: 'BI',
        flag: '🇧🇮',
    },
    {
        name: 'Cambodia',
        code: 'KH',
        flag: '🇰🇭',
    },
    {
        name: 'Cameroon',
        code: 'CM',
        flag: '🇨🇲',
    },
    {
        name: 'Canada',
        code: 'CA',
        flag: '🇨🇦',
    },
    {
        name: 'Cape Verde',
        code: 'CV',
        flag: '🇨🇻',
    },
    {
        name: 'Cayman Islands',
        code: 'KY',
        flag: '🇰🇾',
    },
    {
        name: 'Central African Republic',
        code: 'CF',
        flag: '🇨🇫',
    },
    {
        name: 'Chad',
        code: 'TD',
        flag: '🇹🇩',
    },
    {
        name: 'Chile',
        code: 'CL',
        flag: '🇨🇱',
    },
    {
        name: 'China',
        code: 'CN',
        flag: '🇨🇳',
    },
    {
        name: 'Christmas Island',
        code: 'CX',
        flag: '🇨🇽',
    },
    {
        name: 'Cocos (Keeling) Islands',
        code: 'CC',
        flag: '🇨🇨',
    },
    {
        name: 'Colombia',
        code: 'CO',
        flag: '🇨🇴',
    },
    {
        name: 'Comoros',
        code: 'KM',
        flag: '🇰🇲',
    },
    {
        name: 'Congo',
        code: 'CG',
        flag: '🇨🇬',
    },
    {
        name: 'The Democratic Republic of Congo',
        code: 'CD',
        flag: '🇨🇩',
    },
    {
        name: 'Cook Islands',
        code: 'CK',
        flag: '🇨🇰',
    },
    {
        name: 'Costa Rica',
        code: 'CR',
        flag: '🇨🇷',
    },
    {
        name: 'Ivory Coast',
        code: 'CI',
        flag: '🇨🇮',
    },
    {
        name: 'Croatia',
        code: 'HR',
        flag: '🇭🇷',
    },
    {
        name: 'Cuba',
        code: 'CU',
        flag: '🇨🇺',
    },
    {
        name: 'Cyprus',
        code: 'CY',
        flag: '🇨🇾',
    },
    {
        name: 'Czech Republic',
        code: 'CZ',
        flag: '🇨🇿',
    },
    {
        name: 'Denmark',
        code: 'DK',
        flag: '🇩🇰',
    },
    {
        name: 'Djibouti',
        code: 'DJ',
        flag: '🇩🇯',
    },
    {
        name: 'Dominica',
        code: 'DM',
        flag: '🇩🇲',
    },
    {
        name: 'Dominican Republic',
        code: 'DO',
        flag: '🇩🇴',
    },
    {
        name: 'Ecuador',
        code: 'EC',
        flag: '🇪🇨',
    },
    {
        name: 'Egypt',
        code: 'EG',
        flag: '🇪🇬',
    },
    {
        name: 'El Salvador',
        code: 'SV',
        flag: '🇸🇻',
    },
    {
        name: 'Equatorial Guinea',
        code: 'GQ',
        flag: '🇬🇶',
    },
    {
        name: 'Eritrea',
        code: 'ER',
        flag: '🇪🇷',
    },
    {
        name: 'Estonia',
        code: 'EE',
        flag: '🇪🇪',
    },
    {
        name: 'Ethiopia',
        code: 'ET',
        flag: '🇪🇹',
    },
    {
        name: 'Falkland Islands',
        code: 'FK',
        flag: '🇫🇰',
    },
    {
        name: 'Faroe Islands',
        code: 'FO',
        flag: '🇫🇴',
    },
    {
        name: 'Fiji Islands',
        code: 'FJ',
        flag: '🇫🇯',
    },
    {
        name: 'Finland',
        code: 'FI',
        flag: '🇫🇮',
    },
    {
        name: 'France',
        code: 'FR',
        flag: '🇫🇷',
    },
    {
        name: 'French Guiana',
        code: 'GF',
        flag: '🇬🇫',
    },
    {
        name: 'French Polynesia',
        code: 'PF',
        flag: '🇵🇫',
    },
    {
        name: 'Gabon',
        code: 'GA',
        flag: '🇬🇦',
    },
    {
        name: 'Gambia',
        code: 'GM',
        flag: '🇬🇲',
    },
    {
        name: 'Georgia',
        code: 'GE',
        flag: '🇬🇪',
    },
    {
        name: 'Germany',
        code: 'DE',
        flag: '🇩🇪',
    },
    {
        name: 'Ghana',
        code: 'GH',
        flag: '🇬🇭',
    },
    {
        name: 'Gibraltar',
        code: 'GI',
        flag: '🇬🇮',
    },
    {
        name: 'Greece',
        code: 'GR',
        flag: '🇬🇷',
    },
    {
        name: 'Greenland',
        code: 'GL',
        flag: '🇬🇱',
    },
    {
        name: 'Grenada',
        code: 'GD',
        flag: '🇬🇩',
    },
    {
        name: 'Guadeloupe',
        code: 'GP',
        flag: '🇬🇵',
    },
    {
        name: 'Guam',
        code: 'GU',
        flag: '🇬🇺',
    },
    {
        name: 'Guatemala',
        code: 'GT',
        flag: '🇬🇹',
    },
    {
        name: 'Guinea',
        code: 'GN',
        flag: '🇬🇳',
    },
    {
        name: 'Guinea-Bissau',
        code: 'GW',
        flag: '🇬🇼',
    },
    {
        name: 'Guyana',
        code: 'GY',
        flag: '🇬🇾',
    },
    {
        name: 'Haiti',
        code: 'HT',
        flag: '🇭🇹',
    },
    {
        name: 'Holy See (Vatican City State)',
        code: 'VA',
        flag: '🇻🇦',
    },
    {
        name: 'Honduras',
        code: 'HN',
        flag: '🇭🇳',
    },
    {
        name: 'Hong Kong',
        code: 'HK',
        flag: '🇭🇰',
    },
    {
        name: 'Hungary',
        code: 'HU',
        flag: '🇭🇺',
    },
    {
        name: 'Iceland',
        code: 'IS',
        flag: '🇮🇸',
    },
    {
        name: 'India',
        code: 'IN',
        flag: '🇮🇳',
    },
    {
        name: 'Indonesia',
        code: 'ID',
        flag: '🇮🇩',
    },
    {
        name: 'Iran',
        code: 'IR',
        flag: '🇮🇷',
    },
    {
        name: 'Iraq',
        code: 'IQ',
        flag: '🇮🇶',
    },
    {
        name: 'Ireland',
        code: 'IE',
        flag: '🇮🇪',
    },
    {
        name: 'Israel',
        code: 'IL',
        flag: '🇮🇱',
    },
    {
        name: 'Italy',
        code: 'IT',
        flag: '🇮🇹',
    },
    {
        name: 'Jamaica',
        code: 'JM',
        flag: '🇯🇲',
    },
    {
        name: 'Japan',
        code: 'JP',
        flag: '🇯🇵',
    },
    {
        name: 'Jordan',
        code: 'JO',
        flag: '🇯🇴',
    },
    {
        name: 'Kazakhstan',
        code: 'KZ',
        flag: '🇰🇿',
    },
    {
        name: 'Kenya',
        code: 'KE',
        flag: '🇰🇪',
    },
    {
        name: 'Kiribati',
        code: 'KI',
        flag: '🇰🇮',
    },
    {
        name: 'Kuwait',
        code: 'KW',
        flag: '🇰🇼',
    },
    {
        name: 'Kyrgyzstan',
        code: 'KG',
        flag: '🇰🇬',
    },
    {
        name: 'Laos',
        code: 'LA',
        flag: '🇱🇦',
    },
    {
        name: 'Latvia',
        code: 'LV',
        flag: '🇱🇻',
    },
    {
        name: 'Lebanon',
        code: 'LB',
        flag: '🇱🇧',
    },
    {
        name: 'Lesotho',
        code: 'LS',
        flag: '🇱🇸',
    },
    {
        name: 'Liberia',
        code: 'LR',
        flag: '🇱🇷',
    },
    {
        name: 'Libyan Arab Jamahiriya',
        code: 'LY',
        flag: '🇱🇾',
    },
    {
        name: 'Liechtenstein',
        code: 'LI',
        flag: '🇱🇮',
    },
    {
        name: 'Lithuania',
        code: 'LT',
        flag: '🇱🇹',
    },
    {
        name: 'Luxembourg',
        code: 'LU',
        flag: '🇱🇺',
    },
    {
        name: 'Macao',
        code: 'MO',
        flag: '🇲🇴',
    },
    {
        name: 'North Macedonia',
        code: 'MK',
        flag: '🇲🇰',
    },
    {
        name: 'Madagascar',
        code: 'MG',
        flag: '🇲🇬',
    },
    {
        name: 'Malawi',
        code: 'MW',
        flag: '🇲🇼',
    },
    {
        name: 'Malaysia',
        code: 'MY',
        flag: '🇲🇾',
    },
    {
        name: 'Maldives',
        code: 'MV',
        flag: '🇲🇻',
    },
    {
        name: 'Mali',
        code: 'ML',
        flag: '🇲🇱',
    },
    {
        name: 'Malta',
        code: 'MT',
        flag: '🇲🇹',
    },
    {
        name: 'Marshall Islands',
        code: 'MH',
        flag: '🇲🇭',
    },
    {
        name: 'Martinique',
        code: 'MQ',
        flag: '🇲🇶',
    },
    {
        name: 'Mauritania',
        code: 'MR',
        flag: '🇲🇷',
    },
    {
        name: 'Mauritius',
        code: 'MU',
        flag: '🇲🇺',
    },
    {
        name: 'Mayotte',
        code: 'YT',
        flag: '🇾🇹',
    },
    {
        name: 'Mexico',
        code: 'MX',
        flag: '🇲🇽',
    },
    {
        name: 'Micronesia, Federated States of',
        code: 'FM',
        flag: '🇫🇲',
    },
    {
        name: 'Moldova',
        code: 'MD',
        flag: '🇲🇩',
    },
    {
        name: 'Monaco',
        code: 'MC',
        flag: '🇲🇨',
    },
    {
        name: 'Mongolia',
        code: 'MN',
        flag: '🇲🇳',
    },
    {
        name: 'Montserrat',
        code: 'MS',
        flag: '🇲🇸',
    },
    {
        name: 'Montenegro',
        code: 'ME',
        flag: '🇲🇪',
    },
    {
        name: 'Morocco',
        code: 'MA',
        flag: '🇲🇦',
    },
    {
        name: 'Mozambique',
        code: 'MZ',
        flag: '🇲🇿',
    },
    {
        name: 'Myanmar',
        code: 'MM',
        flag: '🇲🇲',
    },
    {
        name: 'Namibia',
        code: 'NA',
        flag: '🇳🇦',
    },
    {
        name: 'Nauru',
        code: 'NR',
        flag: '🇳🇷',
    },
    {
        name: 'Nepal',
        code: 'NP',
        flag: '🇳🇵',
    },
    {
        name: 'Netherlands',
        code: 'NL',
        flag: '🇳🇱',
    },
    {
        name: 'New Caledonia',
        code: 'NC',
        flag: '🇳🇨',
    },
    {
        name: 'New Zealand',
        code: 'NZ',
        flag: '🇳🇿',
    },
    {
        name: 'Nicaragua',
        code: 'NI',
        flag: '🇳🇮',
    },
    {
        name: 'Niger',
        code: 'NE',
        flag: '🇳🇪',
    },
    {
        name: 'Nigeria',
        code: 'NG',
        flag: '🇳🇬',
    },
    {
        name: 'Niue',
        code: 'NU',
        flag: '🇳🇺',
    },
    {
        name: 'Norfolk Island',
        code: 'NF',
        flag: '🇳🇫',
    },
    {
        name: 'North Korea',
        code: 'KP',
        flag: '🇰🇵',
    },
    {
        name: 'Northern Mariana Islands',
        code: 'MP',
        flag: '🇲🇵',
    },
    {
        name: 'Norway',
        code: 'NO',
        flag: '🇳🇴',
    },
    {
        name: 'Oman',
        code: 'OM',
        flag: '🇴🇲',
    },
    {
        name: 'Pakistan',
        code: 'PK',
        flag: '🇵🇰',
    },
    {
        name: 'Palau',
        code: 'PW',
        flag: '🇵🇼',
    },
    {
        name: 'Palestine',
        code: 'PS',
        flag: '🇵🇸',
    },
    {
        name: 'Panama',
        code: 'PA',
        flag: '🇵🇦',
    },
    {
        name: 'Papua New Guinea',
        code: 'PG',
        flag: '🇵🇬',
    },
    {
        name: 'Paraguay',
        code: 'PY',
        flag: '🇵🇾',
    },
    {
        name: 'Peru',
        code: 'PE',
        flag: '🇵🇪',
    },
    {
        name: 'Philippines',
        code: 'PH',
        flag: '🇵🇭',
    },
    {
        name: 'Poland',
        code: 'PL',
        flag: '🇵🇱',
    },
    {
        name: 'Portugal',
        code: 'PT',
        flag: '🇵🇹',
    },
    {
        name: 'Puerto Rico',
        code: 'PR',
        flag: '🇵🇷',
    },
    {
        name: 'Qatar',
        code: 'QA',
        flag: '🇶🇦',
    },
    {
        name: 'Reunion',
        code: 'RE',
        flag: '🇷🇪',
    },
    {
        name: 'Romania',
        code: 'RO',
        flag: '🇷🇴',
    },
    {
        name: 'Russian Federation',
        code: 'RU',
        flag: '🇷🇺',
    },
    {
        name: 'Rwanda',
        code: 'RW',
        flag: '🇷🇼',
    },
    {
        name: 'Saint Helena',
        code: 'SH',
        flag: '🇸🇭',
    },
    {
        name: 'Saint Kitts and Nevis',
        code: 'KN',
        flag: '🇰🇳',
    },
    {
        name: 'Saint Lucia',
        code: 'LC',
        flag: '🇱🇨',
    },
    {
        name: 'Saint Pierre and Miquelon',
        code: 'PM',
        flag: '🇵🇲',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        code: 'VC',
        flag: '🇻🇨',
    },
    {
        name: 'Samoa',
        code: 'WS',
        flag: '🇼🇸',
    },
    {
        name: 'San Marino',
        code: 'SM',
        flag: '🇸🇲',
    },
    {
        name: 'Sao Tome and Principe',
        code: 'ST',
        flag: '🇸🇹',
    },
    {
        name: 'Saudi Arabia',
        code: 'SA',
        flag: '🇸🇦',
    },
    {
        name: 'Senegal',
        code: 'SN',
        flag: '🇸🇳',
    },
    {
        name: 'Serbia',
        code: 'RS',
        flag: '🇷🇸',
    },
    {
        name: 'Seychelles',
        code: 'SC',
        flag: '🇸🇨',
    },
    {
        name: 'Sierra Leone',
        code: 'SL',
        flag: '🇸🇱',
    },
    {
        name: 'Singapore',
        code: 'SG',
        flag: '🇸🇬',
    },
    {
        name: 'Slovakia',
        code: 'SK',
        flag: '🇸🇰',
    },
    {
        name: 'Slovenia',
        code: 'SI',
        flag: '🇸🇮',
    },
    {
        name: 'Solomon Islands',
        code: 'SB',
        flag: '🇸🇧',
    },
    {
        name: 'Somalia',
        code: 'SO',
        flag: '🇸🇴',
    },
    {
        name: 'South Africa',
        code: 'ZA',
        flag: '🇿🇦',
    },
    {
        name: 'South Korea',
        code: 'KR',
        flag: '🇰🇷',
    },
    {
        name: 'South Sudan',
        code: 'SS',
        flag: '🇸🇸',
    },
    {
        name: 'Spain',
        code: 'ES',
        flag: '🇪🇸',
    },
    {
        name: 'Sri Lanka',
        code: 'LK',
        flag: '🇱🇰',
    },
    {
        name: 'Sudan',
        code: 'SD',
        flag: '🇸🇩',
    },
    {
        name: 'Suriname',
        code: 'SR',
        flag: '🇸🇷',
    },
    {
        name: 'Svalbard and Jan Mayen',
        code: 'SJ',
        flag: '🇸🇯',
    },
    {
        name: 'Swaziland',
        code: 'SZ',
        flag: '🇸🇿',
    },
    {
        name: 'Sweden',
        code: 'SE',
        flag: '🇸🇪',
    },
    {
        name: 'Switzerland',
        code: 'CH',
        flag: '🇨🇭',
    },
    {
        name: 'Syria',
        code: 'SY',
        flag: '🇸🇾',
    },
    {
        name: 'Tajikistan',
        code: 'TJ',
        flag: '🇹🇯',
    },
    {
        name: 'Tanzania',
        code: 'TZ',
        flag: '🇹🇿',
    },
    {
        name: 'Thailand',
        code: 'TH',
        flag: '🇹🇭',
    },
    {
        name: 'Timor-Leste',
        code: 'TL',
        flag: '🇹🇱',
    },
    {
        name: 'Togo',
        code: 'TG',
        flag: '🇹🇬',
    },
    {
        name: 'Tokelau',
        code: 'TK',
        flag: '🇹🇰',
    },
    {
        name: 'Tonga',
        code: 'TO',
        flag: '🇹🇴',
    },
    {
        name: 'Trinidad and Tobago',
        code: 'TT',
        flag: '🇹🇹',
    },
    {
        name: 'Tunisia',
        code: 'TN',
        flag: '🇹🇳',
    },
    {
        name: 'Turkey',
        code: 'TR',
        flag: '🇹🇷',
    },
    {
        name: 'Turkmenistan',
        code: 'TM',
        flag: '🇹🇲',
    },
    {
        name: 'Turks and Caicos Islands',
        code: 'TC',
        flag: '🇹🇨',
    },
    {
        name: 'Tuvalu',
        code: 'TV',
        flag: '🇹🇻',
    },
    {
        name: 'Uganda',
        code: 'UG',
        flag: '🇺🇬',
    },
    {
        name: 'Ukraine',
        code: 'UA',
        flag: '🇺🇦',
    },
    {
        name: 'United Arab Emirates',
        code: 'AE',
        flag: '🇦🇪',
    },
    {
        name: 'United Kingdom',
        code: 'GB',
        flag: '🇬🇧',
    },
    {
        name: 'United States',
        code: 'US',
        flag: '🇺🇸',
    },
    {
        name: 'Uruguay',
        code: 'UY',
        flag: '🇺🇾',
    },
    {
        name: 'Uzbekistan',
        code: 'UZ',
        flag: '🇺🇿',
    },
    {
        name: 'Vanuatu',
        code: 'VU',
        flag: '🇻🇺',
    },
    {
        name: 'Venezuela',
        code: 'VE',
        flag: '🇻🇪',
    },
    {
        name: 'Vietnam',
        code: 'VN',
        flag: '🇻🇳',
    },
    {
        name: 'Virgin Islands, British',
        code: 'VG',
        flag: '🇻🇬',
    },
    {
        name: 'Virgin Islands, U.S.',
        code: 'VI',
        flag: '🇻🇮',
    },
    {
        name: 'Wallis and Futuna',
        code: 'WF',
        flag: '🇼🇫',
    },
    {
        name: 'Yemen',
        code: 'YE',
        flag: '🇾🇪',
    },
    {
        name: 'Zambia',
        code: 'ZM',
        flag: '🇿🇲',
    },
    {
        name: 'Zimbabwe',
        code: 'ZW',
        flag: '🇿🇼',
    },
];

/**
 * Find a country by key and value.
 * In case the key contains a dot, it will search for a nested object.
 * @param key
 * @param value
 */
const getCountryBy = (key: string, value: string): Country => {
    const country = countries.find((country) => {
        const keys = key.split('.');
        let result: Country | string = country;

        for (const k of keys) {
            result = result[k];
        }

        return result === value;
    });

    if (!country) {
        throw new Error(`Country with ${key} ${value} not found`);
    }

    return country;
};

export const getCountryByName = (name: string): Country => {
    return getCountryBy('name', name);
};

export const getCountryByCode = (code: string): Country => {
    return getCountryBy('code', code.toUpperCase());
};

export const getCountryByFlag = (flag: string): Country => {
    return getCountryBy('flag', flag);
};

export const getCountryByPhoneCode = (phoneCode: string): Country => {
    return getCountryBy('phone.code', phoneCode);
};

export default countries;
