import { UserEntity, UserRights, UserRoles } from 'types/User';
import { Settings } from '../../Settings/redux/types';

type FnSignature = (user: UserEntity) => boolean;

const isCustomerAdministrator: FnSignature = (user) =>
    user.role && user.role === UserRoles.CUSTOMER_ADMINISTRATOR;

const isPenneoAdministrator: FnSignature = (user) => user.admin === true;

const hasCasefileSendAccess: FnSignature = (user) =>
    !!user?.rights?.includes(UserRights.SEND);

const hasFormsAccess: FnSignature = (user) =>
    !!user?.rights?.includes(UserRights.FORMS);

const hasKYCAccess: FnSignature = (user) =>
    !!user?.rights?.includes(UserRights.KYC);

const hasDataDashboardAccess: (
    user: UserEntity,
    settings: Settings
) => boolean = (user, settings) =>
    (settings.managed.tableauDashboardUsers || []).includes(user.id);

/**
 * Simple method that returns whether the user
 * can create/send anything in the app
 */
const userCanCreate: FnSignature = (user) => {
    if (!Array.isArray(user.rights)) {
        return false;
    }

    const creationRights = [UserRights.SEND, UserRights.FORMS, UserRights.KYC];
    const currentCreationRights = user.rights.filter((right) =>
        creationRights.includes(right)
    );

    return !!currentCreationRights.length;
};

export {
    isCustomerAdministrator,
    isPenneoAdministrator,
    hasCasefileSendAccess,
    hasFormsAccess,
    hasKYCAccess,
    hasDataDashboardAccess,
    userCanCreate,
};
