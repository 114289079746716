import { Dispatcher } from 'Core';
import Constants from '../Constants';
import { WorkflowAPI } from 'Api';

const WorkflowCommentActionCreators = {
    getComments(workflowId) {
        return WorkflowAPI.get(
            '/v2/workflows/' + workflowId + '/comments'
        ).then((response) => {
            Dispatcher.handleViewAction({
                type: Constants.ActionTypes.GET_COMMENTS,
                comments: response,
            });
        });
    },

    add(comment) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.COMMENT_ADDED,
            comment: comment,
        });
    },

    update(id, comment) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.COMMENT_UPDATED,
            id: id,
            comment: comment,
        });
    },

    updateCurrentComment(comment) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CURRENT_COMMENT_UPDATED,
            comment: comment,
        });
    },

    remove(id) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.COMMENT_REMOVED,
            id: id,
        });
    },

    save(workflowId, comment) {
        WorkflowAPI.post(
            '/v2/workflows/' + workflowId + '/comments',
            comment
        ).then((response) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.COMMENT_SAVED,
                comment: response,
            });
        });
    },
};

export default WorkflowCommentActionCreators;
