import React from 'react';
import classnames from 'classnames';
import { i18n } from 'Language';
import TextInput from 'Common/components/TextInput';
import './top-actions.scss';
import Button from 'Common/components/Button';

type Props = {
    dataCount: number;
    searchValue: string;
    onNewFolder: () => any;
    onMoveItems: () => any;
    onDeleteItems: () => any;
    selectedItemsLength: number;
    onSearchInputChange: (value: string, e: React.ChangeEvent) => void;
    clearSearch: () => void;
    isAllRouteActive: boolean;
};

const TopActions = ({
    onNewFolder,
    onMoveItems,
    searchValue,
    onDeleteItems,
    clearSearch,
    onSearchInputChange,
    selectedItemsLength,
    dataCount,
    isAllRouteActive,
}: Props) => (
    <div className="top-actions">
        <span className="top-actions-search">
            <div
                className={classnames('archive-search-bar-container', {
                    active: searchValue !== '',
                })}>
                <div className="archive-search-bar">
                    <div className="archive-search-bar-icon">
                        <i className="fas fa-search" />
                    </div>
                    <TextInput
                        className="archive-search-bar-input"
                        placeholder={i18n('Filter results')}
                        value={searchValue}
                        onChange={onSearchInputChange}
                    />
                    {searchValue !== '' && (
                        <div className="archive-search-bar-clear-button">
                            <span onClick={clearSearch}>
                                <i className="far fa-times" />
                                &nbsp;
                                <span className="archive-search-bar-clear-button-label">
                                    {i18n`clear`}
                                </span>
                            </span>
                        </div>
                    )}
                </div>
                <span className="archive-search-bar-label">
                    {dataCount === 0 && i18n`No results`}
                    {dataCount === 1 && i18n`1 item`}
                    {dataCount > 1 && i18n`${dataCount} items`}
                </span>
            </div>
        </span>
        {!selectedItemsLength && isAllRouteActive && (
            <Button
                theme="blue"
                variant="outline"
                icon="far fa-folder-plus"
                onClick={onNewFolder}
                renderIconLeft={true}>
                {i18n('New folder')}
            </Button>
        )}
        {!!selectedItemsLength && (
            <span>
                <span className="items-selected-label">
                    {selectedItemsLength}{' '}
                    {i18n(
                        `${selectedItemsLength > 1 ? 'items' : 'item'} selected`
                    )}
                </span>
                {isAllRouteActive && (
                    <Button
                        icon="far fa-arrow-right"
                        theme="gray"
                        className="mr"
                        variant="outline"
                        onClick={onMoveItems}>
                        {i18n('Move')}
                    </Button>
                )}
                <Button
                    icon="far fa-trash"
                    theme="red"
                    variant="outline"
                    onClick={onDeleteItems}>
                    {i18n('Delete')}
                </Button>
            </span>
        )}
    </div>
);

export default TopActions;
