import PropTypes from 'prop-types';
import React from 'react';
import ActionBar from '../Common/ActionBar';
import BankIDActions from '../../actions/BankIDNOServerActions';
import ViewActions from '../../actions/BankIDNOViewActions';
import BankIDStore from '../../stores/BankIDNOStore';
import BankIDWidget from './BankIDWidget';
import { TranslationStore, i18n } from 'Language';

import OpenIDActions from '../../actions/OpenIDActions';

import { Intent } from 'OpenID/Constants';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import { BANKID_NORWAY_EIDENT } from 'EID/Constants';

export default class ActivationContainer extends React.Component {
    static propTypes = {
        location: PropTypes.object,
        redirect: PropTypes.func,
        children: PropTypes.object,
        bankidNoCredentials: PropTypes.array,
        nextLink: PropTypes.object,
    };

    state = {
        params: null,
        isFetching: null,
        error: null,
        language: TranslationStore.getLanguage(),
    };

    timeout = null;

    componentDidMount() {
        if (launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED)) {
            this.timeout = setTimeout(() => {
                OpenIDActions.init(
                    BANKID_NORWAY_EIDENT.type,
                    Intent.ACTIVATION
                );
            }, 1000);

            return;
        }

        BankIDStore.addChangeListener(this.onChange);
        TranslationStore.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);

            return;
        }

        BankIDStore.removeChangeListener(this.onChange);
        TranslationStore.removeChangeListener(this.onChange);
    }

    componentDidUpdate(_, prevState) {
        // Reset the widget if language is changed
        if (prevState.language !== this.state.language) {
            this.retry();
        }
    }

    onChange = () => {
        this.setState({
            params: BankIDStore.getParams(),
            isFetching: BankIDStore.isFetching(),
            error: BankIDStore.getError(),
            language: TranslationStore.getLanguage(),
        });
    };

    onSuccess = (signatureRef) => {
        BankIDActions.addCredentials(signatureRef);
    };

    init = (languageCode = TranslationStore.getLanguage()) => {
        BankIDActions.initLogin(languageCode);
    };

    retry = () => {
        ViewActions.retry();
        this.init();
    };

    updateError = (error) => {
        this.setState({ error: error });
    };

    render() {
        if (launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED)) {
            return (
                <div className="auth-method-password">
                    <div className="auth-logo">
                        <img
                            height="120"
                            width="120"
                            src={BANKID_NORWAY_EIDENT.logo}
                        />
                    </div>
                    <p>{i18n(`Redirecting...`)}</p>
                </div>
            );
        }

        let { params, error, isFetching } = this.state;
        let { bankidNoCredentials } = this.props;

        if (bankidNoCredentials) {
            return (
                <div>
                    {i18n(`You have configured BankID successfully`)}
                    &nbsp;<i className="fa fa-check text-success"></i>
                    <br />
                    <br />
                    {this.props.nextLink}
                </div>
            );
        }

        return (
            <div className="auth-method-password">
                <ActionBar title="Add BankID to your Account" />
                <BankIDWidget
                    params={params}
                    error={error}
                    retry={this.retry}
                    isFetching={isFetching}
                    init={this.init}
                    onSuccess={this.onSuccess}
                    updateError={this.updateError}
                    onFailure={this.onFailure}
                />
            </div>
        );
    }
}
