import { ENVIRONMENTS } from 'Constants';
import { Languages } from 'Language/Constants';
import { TemplateType } from 'types/EmailTemplates';

export const RegisteredLetterDefaultEmailTemplates = {
    [TemplateType.INITIAL]: {
        subject: `Registered Letter: {{casefile.name}}`,
        message: `Dear {{recipient.name}},

A Registered Letter has been sent to you.`,
    },
};

export const REGISTERED_LETTER_SIGNING_FLOWS: Record<
    ENVIRONMENTS,
    Record<Languages, number>
> = {
    [ENVIRONMENTS.PRODUCTION]: {
        [Languages.DA]: 937,
        [Languages.EN]: 1393,
        [Languages.FI]: 2232,
        [Languages.FR]: 2231,
        [Languages.NL]: 2229,
        [Languages.NO]: 1445,
        [Languages.SV]: 1366,
        [Languages.DE]: 2392,
    },
    [ENVIRONMENTS.SANDBOX]: {
        [Languages.DA]: 74,
        [Languages.EN]: 208,
        [Languages.FI]: 209,
        [Languages.FR]: 210,
        [Languages.NL]: 211,
        [Languages.NO]: 212,
        [Languages.SV]: 207,
        [Languages.DE]: 257,
    },
    [ENVIRONMENTS.STAGING]: {
        [Languages.DA]: 66,
        [Languages.EN]: 52,
        [Languages.FI]: 65,
        [Languages.FR]: 67,
        [Languages.NL]: 68,
        [Languages.NO]: 69,
        [Languages.SV]: 64,
        [Languages.DE]: 86,
    },
    [ENVIRONMENTS.DEVELOPMENT]: {
        [Languages.DA]: 3,
        [Languages.EN]: 3,
        [Languages.FI]: 3,
        [Languages.FR]: 3,
        [Languages.NL]: 3,
        [Languages.NO]: 3,
        [Languages.SV]: 3,
        [Languages.DE]: 3,
    },
};
