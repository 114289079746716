import React from 'react';
import classNames from 'classnames';

type Props = {
    src: string;
    label: string;
    displayInline?: boolean;
};

export default function Flag(props: Props) {
    return (
        <div
            className={classNames(
                props.displayInline ? 'inline-flex' : 'flex'
            )}>
            <div className="flex justify-center items-center bg-gray-100 p-2">
                <img
                    className="flex w-[20px] h-[14px] justify-center items-center flex-shrink-0"
                    src={props.src}
                    alt="icon"
                />
                <span className="text-gray-700 font-sans text-[12px] font-semibold leading-4 ml-[0.4rem]">
                    {props.label}
                </span>
            </div>
        </div>
    );
}
