import React from 'react';
import { i18n } from 'Language';
import { images } from 'Constants';
import './new-account-benefits.scss';

const NewAccountBenefits = () => {
    return (
        <div className="new-account-benefits">
            <div className="box-blue">
                <h3 className="new-account-benefits-title">
                    {i18n`With a ${(
                        <span className="text-blue">{i18n`free`}</span>
                    )} Penneo account, you can:`}
                </h3>

                <ul>
                    <li>
                        <div>
                            <img
                                src={`${images}/graphics/600x600_compliance.png`}
                                height="60"
                            />
                        </div>
                        <p>{i18n`Protect your account with electronic IDs`}</p>
                    </li>
                    <li>
                        <div>
                            <img
                                src={`${images}/graphics/600x600_manage.png`}
                                height="60"
                            />
                        </div>
                        <p>{i18n`Store documents conveniently`}</p>
                    </li>
                    <li>
                        <div>
                            <img
                                src={`${images}/graphics/600x600_reminder.png`}
                                height="60"
                            />
                        </div>
                        <p>{i18n`Keep documents for more than 14 days`}</p>
                    </li>
                </ul>

                <p className="text-small">
                    <a
                        className="terms-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://penneo.com/terms">
                        {i18n`By clicking “Save and Complete” you agree to our terms and conditions`}
                    </a>
                </p>
            </div>
        </div>
    );
};

export default NewAccountBenefits;
