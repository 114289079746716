import React from 'react';
import { Link } from 'react-router';
import { i18n } from 'Language';
import { CaseFileItem, Type } from '../';
import { ACCESS_READONLY } from 'Constants';
import Tooltip from 'Common/components/Tooltip';
import Analytics from 'Common/Analytics';
import { CasefileFolderActions } from './CasefileFolderActions';
import { DocumentTextIcon, FolderIcon } from '@heroicons/react/24/outline';

type OnActionCaseFileItem = (item: CaseFileItem) => void;

export type Props = {
    item: CaseFileItem;
    onEditTitle: OnActionCaseFileItem;
    onShare: (item: CaseFileItem) => () => void;
    onUnshare: OnActionCaseFileItem;
    getItemRoute: (item: CaseFileItem) => any;
    onPreferred: OnActionCaseFileItem;
    clearSearch: () => void;
    router: any;
};

const trackLink = (item) => {
    if (item.type === Type.Folder) {
        return Analytics.track('archive - open folder', {
            id: item.id,
            default: item.folderType === Type.Unshareable,
            accessLevel: item.accessLevel,
            shared: item.shared,
        });
    }

    return Analytics.track('archive - open case', {
        id: item.id,
        status: item.status,
        // @todo: track case file type.
    });
};

const CaseFileTableTitleItem = ({
    item,
    onEditTitle,
    onPreferred,
    getItemRoute,
    onUnshare,
    clearSearch,
    router,
}: Props) => (
    <div className="title-content flex justify-between">
        <div className="flex items-center">
            <Link
                id={`link-${
                    item.type === Type.Folder ? 'folder' : 'casefile'
                }-${item.id}`}
                onClick={(event: React.MouseEvent) => {
                    // this will prevent from triggering this click twice
                    event.stopPropagation();
                    trackLink(item);
                    item.type === Type.Folder && clearSearch();
                }}
                to={getItemRoute(item)}
                className="case-file-item-link flex items-center">
                {item.type === Type.Folder ? (
                    <FolderIcon className="column-title-icon h-6 shrink-0"></FolderIcon>
                ) : (
                    <DocumentTextIcon className="column-title-icon h-6 shrink-0"></DocumentTextIcon>
                )}
                <span className="column-title-text">{item.title}</span>
            </Link>
            &nbsp;&nbsp;
            {/* If user can edit item */}
            {item.accessLevel !== ACCESS_READONLY && (
                <Tooltip
                    className="item-action-tooltip no-animations inline-flex justify-center items-center"
                    showArrow={true}
                    text={
                        <span>
                            {item.type === Type.Folder
                                ? i18n`Rename Folder`
                                : i18n`Rename`}
                        </span>
                    }>
                    <span
                        className="item-inline-action"
                        onClick={(event: React.MouseEvent) => {
                            event.stopPropagation();
                            onEditTitle(item);
                        }}>
                        <i className="far fa-pencil-alt"></i>
                    </span>
                </Tooltip>
            )}
        </div>
        {item.type === 'folder' && (
            <CasefileFolderActions
                item={item}
                onPreferred={onPreferred}
                onUnshare={onUnshare}
                router={router}
            />
        )}
    </div>
);

export default CaseFileTableTitleItem;
