import React from 'react';
import Button from 'Common/components/Button';
import { i18n } from 'Language';
import { modal } from 'Common/components/Common/Modal';
import { TextInput } from 'Common/components';
import { connect } from 'react-redux';
import { AppDispatch } from 'Store';
import { createContact, fetchContacts } from 'Contacts/redux/actions';
import { notify } from 'react-notify-toast';
import './contact-add-modal.scss';

type Props = {
    dispatch: AppDispatch;
};

type State = {
    name: string;
    email: string;
    onBehalfOf: string;
};

export default class ContactAddModal extends React.Component<Props, State> {
    state: State = {
        name: '',
        email: '',
        onBehalfOf: '',
    };

    handleCreate = async (e: React.FormEvent) => {
        e.preventDefault();

        const { dispatch } = this.props;
        const { name, email, onBehalfOf } = this.state;

        const response = await dispatch(createContact(name, email, onBehalfOf));

        if (response.isApiError) {
            notify.show(response.data.message, 'error', 5000);
        } else {
            dispatch(fetchContacts());
            notify.show(i18n`${name} was saved successfully`, 'success');
            modal.hide();
        }
    };

    render() {
        return (
            <form
                className="contact-add-modal text-left mr ml"
                onSubmit={this.handleCreate}>
                <h3>{i18n('Save new contact')}</h3>

                <div className="text-left">
                    <TextInput
                        value={this.state.name}
                        onChange={(value: string) =>
                            this.setState({ name: value })
                        }
                        label={i18n`Name`}
                        placeholder={i18n`Enter a name`}
                        required
                    />

                    <TextInput
                        value={this.state.email}
                        onChange={(value: string) =>
                            this.setState({ email: value })
                        }
                        label={i18n`Email`}
                        placeholder={i18n`Enter an email`}
                        type="email"
                        required
                    />

                    <TextInput
                        value={this.state.onBehalfOf}
                        onChange={(value: string) =>
                            this.setState({ onBehalfOf: value })
                        }
                        label={i18n`Company name`}
                        placeholder={i18n`Enter a company name (optional)`}
                    />
                </div>

                <div className="mt text-right">
                    <Button
                        type="button"
                        variant="outline"
                        onClick={modal.hide}>
                        {i18n`Cancel`}
                    </Button>

                    <Button className="ml" type="submit" theme="blue">
                        {i18n`Save contact`}
                    </Button>
                </div>
            </form>
        );
    }
}

const ConnectedModal = connect()(ContactAddModal);

export const showContactAddModal = () => {
    const config = {
        component: <ConnectedModal />,
    };

    modal.show(config);
};
