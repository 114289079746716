import { i18n } from 'Language';
import React from 'react';

export default function OTPValidationDisplay({
    isValid,
}: {
    isValid: boolean;
}) {
    const renderIcon = () => {
        if (isValid) {
            return (
                <svg
                    data-testid="valid-code-icon"
                    className="w-6 h-6"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM227.3 387.3l184-184c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0L216 308.1l-70.1-70.1c-6.2-6.2-16.4-6.2-22.6 0l-22.6 22.6c-6.2 6.2-6.2 16.4 0 22.6l104 104c6.2 6.2 16.4 6.2 22.6 0z" />
                </svg>
            );
        }

        return (
            <svg
                data-testid="invalid-code-icon"
                className="w-6 h-6"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512">
                <path d="M504 256c0 137-111 248-248 248S8 393 8 256C8 119.1 119 8 256 8s248 111.1 248 248zm-248 50c-25.4 0-46 20.6-46 46s20.6 46 46 46 46-20.6 46-46-20.6-46-46-46zm-43.7-165.3l7.4 136c.3 6.4 5.6 11.3 12 11.3h48.5c6.4 0 11.6-5 12-11.3l7.4-136c.4-6.9-5.1-12.7-12-12.7h-63.4c-6.9 0-12.4 5.8-12 12.7z" />
            </svg>
        );
    };

    const renderText = () => {
        if (isValid) {
            return i18n('Valid code!');
        }

        return i18n('Wrong code!');
    };

    return (
        <div
            className={`flex flex-col items-center text-md ${
                isValid ? 'text-green-600' : 'text-red-600'
            }`}>
            {renderIcon()} <p className="mt-0.5">{renderText()}</p>
        </div>
    );
}
