import React from 'react';
import { connect } from 'react-redux';
import { AppDispatch, ReduxState } from 'Store';
import { Contact } from 'types/Contact';
import {
    disableContactEdit,
    updateContact,
    updateContactDraft,
} from 'Contacts/redux/actions';
import { TextInput } from 'Common/components';

export type Props = {
    contact: Contact;
    property: string;
    dispatch: AppDispatch;
    onSaveRow: (rowIndex: number, response: any) => void;
    rowIndex?: number;
    defaultValue?: string;
    autoFocus?: boolean;
    contactDraft?: Contact | null;
};

export const ContactListRowEditableField: React.FunctionComponent<Props> = (
    props
) => {
    const {
        contact,
        contactDraft,
        property,
        defaultValue,
        autoFocus,
        dispatch,
        onSaveRow,
        rowIndex,
    } = props;

    const handleEnterKey = async (event) => {
        event.preventDefault();

        const key = event.keyCode || event.which;

        // Enter Key
        if (key === 13) {
            const response = await dispatch(updateContact());

            return onSaveRow(rowIndex as number, response);
        }

        // ESC Key
        if (key === 27) {
            dispatch(disableContactEdit());
        }
    };

    if (contactDraft) {
        return (
            <TextInput
                onKeyUp={handleEnterKey}
                autoFocus={autoFocus}
                className="contact-list-inline-textinput"
                onChange={(value: string) =>
                    dispatch(updateContactDraft(property, value))
                }
                value={contactDraft[property]}
            />
        );
    }

    return <span>{contact[property] || defaultValue}</span>;
};

export default connect((state: ReduxState, props: Props) => {
    const { contactDraft } = state.contacts.viewState;
    const isEditing = contactDraft && contactDraft.id === props.contact.id;

    if (!isEditing) {
        return {};
    }

    return {
        contactDraft,
        rowIndex: state.contacts.data.findIndex(
            (c) => c.id === props.contact.id
        ),
    };
})(ContactListRowEditableField);
