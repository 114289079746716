import { Dispatcher, BaseStore } from 'Core';
import assign from 'object-assign';
import { AuthMethodsActions as Actions } from '../ActionTypes';
import lodash from 'lodash';

let _default = {
    _fetching: false,
    credentials: [],
    error: null,
};

// Initialize Store Data
let _store = {};

resetState(); // sets initial values

function updateState(state) {
    _store = assign(_store, state, { _fetching: false });
}

function resetState() {
    _store = assign(_store, _default);
}

function updateCredentials(credentialList) {
    if (!credentialList) {
        return false;
    }

    updateState({ credentials: credentialList });
}

function addCreatedCredential(cred) {
    let credentials = _store.credentials;

    credentials.push(cred);

    updateState({ credentials: credentials });
}

function deleteCredentials(credentialId) {
    let credentials = _store.credentials;

    lodash.remove(credentials, {
        id: credentialId,
    });

    updateState({ credentials: credentials });
}

function updateError(error) {
    updateState({ error: error });
}

const Store = assign({}, BaseStore, {
    getError() {
        return _store.error;
    },

    getCredentials() {
        return _store.credentials;
    },

    isFetching() {
        return _store._fetching;
    },

    getCredentialById(id) {
        return _store.credentials.find((cred) => cred.id === id);
    },

    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Actions.FETCH_SUCCESS:
                updateCredentials(action.credentials);
                break;
            case Actions.REMOVE_SUCCESS:
                deleteCredentials(action.credentialId);
                break;
            case 'AUTH_METHODS_CREATE_SUCCESS':
                addCreatedCredential(action.credential);
                break;

            // Request Actions
            case Actions.FETCH_REQUEST:
            case Actions.REMOVE_REQUEST:
                _store._fetching = true;
                break;

            // Failure Actions
            case Actions.FETCH_FAILURE:
            case Actions.REMOVE_FAILURE:
                // _store._fetching = false;
                updateError(action.error);
                break;

            // View Actions
            case Actions.CLEAR_ERRORS:
                updateError(null);
                break;
            case Actions.CLEAR_STORE:
                resetState();
                break;
            default:
                return false;
        }
        Store.emitChange(action.type);
    }),
});

export default Store;
