import PropTypes from 'prop-types';
import React from 'react';

export default class FlagDropdown extends React.Component {
    static propTypes = {
        languages: PropTypes.object.isRequired,
        selected: PropTypes.string,
        changeLanguage: PropTypes.func.isRequired,
        close: PropTypes.func.isRequired,
        callback: PropTypes.func,
    };

    handleClick = (languageCode) => {
        this.props.changeLanguage(languageCode);
        this.props.close();

        if (this.props.callback) {
            this.props.callback(languageCode);
        }
    };

    mapObject = (object, callback) => {
        return Object.keys(object).map((key) => {
            return callback(key, object[key]);
        });
    };

    renderLanguageList = () => {
        let { languages } = this.props;

        return this.mapObject(languages, (code, language) => (
            <li
                className="dropdown-element"
                key={code}
                onClick={this.handleClick.bind(this, code)}>
                <a className="dropdown-link">
                    <img
                        width="16"
                        height="11"
                        src={language.flag}
                        className="dropdown-img"
                    />
                    <span>{language.name}</span>
                </a>
            </li>
        ));
    };

    render() {
        let { selected, languages } = this.props;

        return (
            <div className="language-dropdown">
                <div className="dropbtn">
                    <img
                        width="16"
                        height="11"
                        src={languages[selected].flag}
                    />
                    <div id="language-selector" className="dropdown-content">
                        <ul className="dropdown-list">
                            {this.renderLanguageList()}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
