import React from 'react';
import { isPenneoAdministrator } from 'Common/utils/userPermissions';
import AccessDenied from 'Common/components/AccessDenied';
import AuthStore from '../../stores/AuthStore';
import CustomerStore from '../../stores/CustomerStore';
import CustomerActions from '../../actions/CustomerActionCreators';
import { Link } from 'react-router';
import { i18n, translate } from 'Language';
import { UserEntity as User } from 'types/User';
import { CustomerEntity as Customer } from 'types/Customer';

import Griddle from 'griddle-react';

type Props = {
    user: User;
};

type State = {
    customers: Customer[];
    user: User;
    isAllowed: boolean;
};

export default class CustomerList extends React.Component<Props, State> {
    state: State = {
        customers: CustomerStore.getCustomers(),
        user: AuthStore.getUser(),
        isAllowed: false,
    };

    componentWillMount() {
        const { user } = this.props;

        this.setState({
            isAllowed: isPenneoAdministrator(user),
        });
    }

    componentDidMount() {
        CustomerStore.addChangeListener(this.onChange);
        this.loadData();
    }

    componentWillUnmount() {
        CustomerStore.removeChangeListener(this.onChange);
    }

    loadData = () => {
        CustomerActions.fetchCustomers();
    };

    onChange = () => {
        this.setState({
            customers: CustomerStore.getCustomers(),
        });
    };

    // @todo: This is only evaluated on first render.
    // On language change, column names are not updated.
    getColumnMetaData = () => {
        return [
            {
                columnName: 'ID',
                cssClassName: 'column-100',
            },
            {
                columnName: 'Name',
                displayName: i18n`Name`,
            },
            {
                columnName: 'Address',
                displayName: i18n`Address`,
            },
            {
                columnName: 'Manage',
                displayName: i18n`Actions`,
                cssClassName: 'column-150',
                customComponent: DetailsColumn,
            },
        ];
    };

    render() {
        const { user, customers, isAllowed } = this.state;

        const data = customers.map((customer) => {
            return {
                ID: customer.id,
                Name: customer.name,
                Address: `${customer.address}, ${customer.zip}, ${customer.city}`,
                Manage: JSON.stringify({
                    customerId: customer.id,
                    userId: user.id,
                }),
            };
        });

        return !isAllowed ? (
            <AccessDenied pageName="Manage Customers" />
        ) : (
            <div className="penneo-auth">
                <div className="user-list white-container">
                    <h3 className="title">{translate('Customers')}</h3>
                    <div className="content">
                        {data.length > 0 ? (
                            <Griddle
                                tableClassName="griddle-table"
                                results={data}
                                resultsPerPage={10}
                                showFilter={true}
                                noDataMessage={''}
                                useCustomFilterComponent={true}
                                initialSort={'Name'}
                                initialSortAscending={false}
                                customFilterComponent={CustomHeader}
                                useGriddleStyles={false}
                                nextText={i18n`Next`}
                                previousText={i18n`Previous`}
                                columnMetadata={this.getColumnMetaData()}
                            />
                        ) : (
                            <CustomNoDataComponent />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

class DetailsColumn extends React.Component<{ data: string }> {
    getUserLink = (customerId: string) => {
        const link = {
            name: 'admin-manage-customer',
            params: {
                customerId: customerId,
            },
        };

        return link;
    };

    render() {
        const parsedData: { customerId: string } = JSON.parse(this.props.data);
        const { customerId } = parsedData;

        return (
            <Link to={this.getUserLink(customerId)}>
                <i className="fa fa-edit"></i>&nbsp;
                {translate('Manage')}
            </Link>
        );
    }
}

class CustomNoDataComponent extends React.Component {
    render() {
        return (
            <div className="text-center text-medium">
                <h1>
                    <i className="far fa-lg fa-sync fa-spin"></i>
                    <p>{i18n`Loading...`}</p>
                </h1>
            </div>
        );
    }
}

type CustomHeaderProps = {
    query: string;
    changeFilter: Function;
    currentResults: Customer[];
};

class CustomHeader extends React.Component<CustomHeaderProps> {
    static defaultProps: Partial<CustomHeaderProps> = {
        query: '',
    };

    searchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.changeFilter(event.target.value);
    };

    render() {
        const { currentResults } = this.props;

        return (
            <div className="container-header" style={{ paddingBottom: 0 }}>
                <span className="search-bar">
                    <input
                        type="text"
                        style={{ width: 200 }}
                        onChange={this.searchChange}
                        placeholder={translate('Type to filter')}
                    />
                </span>

                <span className="text-bold pl">
                    {`${currentResults.length} ` + translate('Customers')}
                </span>
            </div>
        );
    }
}
