import createReducer from 'create-reducer';
import produce from 'immer';

// Types
import { ProfileState } from './types';
import moment from 'moment';

// Action Types
export const USER_PROFILES_FETCH_REQUEST = 'USER_PROFILES_FETCH_REQUEST';
export const USER_PROFILES_FETCH_SUCCESS = 'USER_PROFILES_FETCH_SUCCESS';
export const USER_PROFILES_FETCH_FAILURE = 'USER_PROFILES_FETCH_FAILURE';

// Initial state
const profileInitialState: ProfileState = {
    data: [],
    hasMultipleIdentities: false,
    isLoaded: false,
    isFetching: false,
    error: null,
};

// Reducer

export const profileReducer = createReducer<ProfileState>(profileInitialState, {
    [USER_PROFILES_FETCH_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [USER_PROFILES_FETCH_SUCCESS]: (state, profiles) =>
        produce(state, (draftState) => {
            if (profiles.length === 0) {
                draftState.data = [];
                draftState.isLoaded = true;
                draftState.isFetching = false;

                return draftState;
            }

            const { users, roles, customers, identities } = profiles;

            const profileData = users.map((user) => {
                const userRoles = roles.filter((r) => r.userId === user.id);

                // Aggregate role to customer array.
                const userCustomers = userRoles.map((r) => {
                    const [customer] = customers.filter(
                        (c) => r.customerId === c.id
                    );

                    return {
                        id: customer.id,
                        name: customer.name,
                        role: r.role,
                    };
                });

                const [customer] = userCustomers;
                const [oldestProfile] = identities
                    .filter((identity) => identity.userId === user.id)
                    .sort((a, b) => {
                        const { createdAt: createAtA } = a;
                        const { createdAt: createAtB } = b;
                        const dateDiff =
                            moment(createAtA).unix() - moment(createAtB).unix();

                        return dateDiff;
                    });

                const createdAt =
                    oldestProfile &&
                    moment(oldestProfile.createdAt).format('LL');

                const userData = {
                    id: user.id,
                    name: user.fullName,
                    createdAt,
                };

                if (customer) {
                    return {
                        ...userData,
                        role: customer.role,
                        customer: {
                            id: customer.id,
                            name: customer.name,
                        },
                    };
                }

                return userData;
            });

            /**
             * Following (business) logic might need a bit of explanation as it is not inherently clear what is going on here...
             * A migration from eIdent <-> bankidno etc. have made it so some users are being linked together
             * These are distinguished by a prefix to the serial number, but from which they are matching...
             *
             * We take all the userId' from which we do NOT have a customer attached (we only care for the end users, as they are presented to this anew)
             * We are filtering out ALL matching identities which have the provider (in the whitelist to accommodate more cases)
             * If they match, we loop through all the known prefixes (to acommodate more prefixes in the future)
             *
             *
             * NOTE: This can be made an object / dict later, per provider, if we really need to be able to distinguish the replacement factor
             * or MODIFY certain "match" cases...
             */

            const allUserIdsWithoutCustomers = profileData
                .filter((profile) => !profile.customer)
                .map((user) => user.id);

            const multipleIdentitiesProviderList = ['bankidno'];
            const prefixedSerialNumberValues = ['UN:NO-'];

            const allIdentitiesMatchingMultipleCriteria = identities
                .filter(
                    (identity) =>
                        multipleIdentitiesProviderList.includes(
                            identity.provider
                        ) &&
                        allUserIdsWithoutCustomers.includes(identity.userId)
                )
                .map((identity) => {
                    return prefixedSerialNumberValues.reduce((acc, current) => {
                        return acc.replace(current, '');
                    }, identity.serialNumber);
                });

            const hasMultipleIdentities =
                new Set(allIdentitiesMatchingMultipleCriteria).size !==
                allIdentitiesMatchingMultipleCriteria.length;

            draftState.data = profileData;
            draftState.isLoaded = true;
            draftState.isFetching = false;

            draftState.hasMultipleIdentities = hasMultipleIdentities;

            return draftState;
        }),
    [USER_PROFILES_FETCH_FAILURE]: (state, error) =>
        produce(state, (draftState) => {
            draftState.data = profileInitialState.data;
            draftState.error = error.message;
        }),
});

export default profileReducer;
