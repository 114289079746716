import PropTypes from 'prop-types';
import React from 'react';

export default class Mobile extends React.Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        branding: PropTypes.object,
        open: PropTypes.bool,
        toggleMenu: PropTypes.func,
    };

    render() {
        let { branding } = this.props;

        let logo =
            branding.imageUrl ||
            'https://penneo.com/wp-content/uploads/2015/11/logo-1.png';

        return (
            <div className="penneo-dashboard-header-mobile">
                <div
                    className="toggle-menu"
                    onClick={this.props.toggleMenu.bind(
                        null,
                        !this.props.open
                    )}>
                    {this.props.open ? (
                        <i className="far fa-times"></i>
                    ) : (
                        <i className="far fa-bars"></i>
                    )}
                </div>
                <div
                    className="dashboard-header-sidebar"
                    style={{ background: branding.backgroundColor }}>
                    <div className="logo">
                        <img src={logo} alt="" />
                    </div>
                </div>
                {/* <div className="dashboard-header-content">
                    <div className="dashboard-menu">
                        <DashboardMenuItem>
                            <LanguageDropdown />
                        </DashboardMenuItem>
                    </div>
                </div> */}
            </div>
        );
    }
}
