import { Languages } from 'Language/Constants';
import { InsecureSigningMethod } from 'types/Signer';
export enum RecipientType {
    Signer = 'signer',
    CopyRecipient = 'copyrecipient',
}

export type RecipientSignerRole = {
    id: number;
    enabled: boolean;
    customName?: string;
    activeAt?: number;
};

type Recipient = {
    name: string;
    email: string;
    type: RecipientType;
    language?: Languages;
};

export type RecipientSigner = Recipient & {
    type: RecipientType.Signer;
    accessControl: boolean;
    enableInsecureSigning: boolean;
    insecureSigningMethods: InsecureSigningMethod[];
    reminderInterval: number;
    role: RecipientSignerRole[];
    storeAsContact: boolean;
    // Used to differentiate which recipient gets which email message
    tempId?: string;
    onBehalfOf?: string;
    ssn?: string | number;
    vatin?: number;
    signingRequestId?: number;
};

export type RecipientCopy = Recipient & {
    type: RecipientType.CopyRecipient;
};
