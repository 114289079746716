import { Dispatcher, BaseStore } from 'Core';
import Constants from '../Constants';
import assign from 'object-assign';

// Data Storage
let _details = [];
let _statuses = {};
let _filterQuery = '';
let _refreshFlag = false;
let _formattedData = [];

function saveAll(workflows) {
    _details = workflows;
}

function getAsArray(workflows) {
    let results = [];

    for (let key in workflows) {
        if (!workflows.hasOwnProperty(key)) {
            continue;
        }

        let row = workflows[key];

        row.id = parseInt(key, 10);

        results.push(row);
    }

    return results;
}

function updateWorkflowDetails(data) {
    _details[data.workflow.id] = assign({}, data);
}

function updateWorkflow(workflow) {
    _details[workflow.id].workflow = workflow;
}

function updateStatuses(statuses) {
    _statuses = statuses;
}

function setFilterQuery(filter) {
    _filterQuery = filter;
}

function toggleRefreshFlag() {
    _refreshFlag = !_refreshFlag;
}

// Flux Store Creation
const WorkflowDetailStore = assign({}, BaseStore, {
    getAll() {
        return _details;
    },

    getAllArray(workflows = _details) {
        return getAsArray(workflows);
    },

    getStatuses() {
        return _statuses;
    },

    getFilter() {
        return _filterQuery;
    },

    getRefreshFlag() {
        return _refreshFlag;
    },

    getFormattedData() {
        return _formattedData;
    },

    emitDetailsListLoaded() {
        this.emit(Constants.ActionTypes.DETAIL_LIST_LOADED);
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.DETAIL_LIST_LOADED:
                WorkflowDetailStore.emitDetailsListLoaded();

                if (action.workflows) {
                    saveAll(action.workflows);
                }

                break;
            case Constants.ActionTypes.DETAIL_WORKFLOW_LOADED:
                if (action.workflow) {
                    updateWorkflowDetails(action.workflow);
                }

                break;
            case Constants.ActionTypes.DETAIL_CASEFILE_STATUSES_LOADED:
                if (action.statuses) {
                    updateStatuses(action.statuses);
                }

                break;
            case 'WORKFLOW_DETAIL_UPDATED':
                if (action.workflow) {
                    updateWorkflow(action.workflow);
                }

                break;
            case 'FILTER_UPDATED':
                setFilterQuery(action.data);
                break;
            case 'SKIP_REFRESH':
                toggleRefreshFlag();
                break;
            default:
                return false;
        }
        WorkflowDetailStore.emitChange();
    }),
});

export default WorkflowDetailStore;
