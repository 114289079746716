import React from 'react';
import { i18n } from 'Language';
import { CaseFileItem } from '..';

export type Props = {
    item: CaseFileItem;
    userId: number;
};

const CaseFileTypeItem = ({ item, userId }: Props) => {
    if (item.type === 'folder') {
        const sharedType =
            item.userId !== userId ? `Shared with me` : `Shared folder`;

        return <span>{item.shared ? i18n(sharedType) : i18n`Folder`}</span>;
    }

    if (!item.caseFileType) {
        return <span>- - -</span>;
    }

    return (
        <span className="no-wrap">
            <i className="far fa-tag" style={{ marginRight: '0.3em' }} />
            {item.caseFileType.name}
        </span>
    );
};

export default CaseFileTypeItem;
