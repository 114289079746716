import { exportPEM } from './csr';
import { OIDs, algorithm } from 'OpenID/Constants';
import { KeyPair, OpenIDSubjectIdentifiers } from 'OpenID/redux/types';
import { getCrypto, PenneoCrypto } from './crypto';
import { getOpenIDSubject } from 'OpenID/utils/openIdCertificate';

/**
 * We have to wrap the PKI.js certificate request class because it we need the resulting PEM string
 * to be DER encoded and PKI.js only supports BER encoding.
 *
 * To ensure PEMs are DER encoded we have to sort SEQUENCE OF and SET OF objects based on
 * their DER encoded byte code. Doing proper DER encoding means a lot of changes to asn1.js.
 * But since our CSRs always have the same structure, we be lazy and only implement DER encoding on
 * the part that actually trips BouncyCastle up: the order of SET OF and SEQUENCE OF elements.
 *
 * SET OF and SEQUENCE OF elements are ordered by their DER encoded bytes, but since **we only use
 * homogeneous sets and sequences**, the difference in order will always be made **the bytes that
 * encode the length of the element**.
 *
 * Going further, since asn1 gives us the opportunity to get a buffer of the proper size without
 * actually encoding values by using `.toBER(true)`, we can discriminate order just
 * by buffer byte length most of the time.
 */

type Constructor = {
    publicKey: KeyPair['publicKey'];
    privateKey: KeyPair['privateKey'];
    idToken: string;
    subject: any;
};

class CertificateRequestWrapper {
    private publicKey: KeyPair['publicKey'];
    private privateKey: KeyPair['privateKey'];
    private subject: OpenIDSubjectIdentifiers;
    private extraAttributes: any;
    private crypto: PenneoCrypto;
    private extensions: any;

    constructor({ publicKey, privateKey, idToken, subject }: Constructor) {
        this.publicKey = publicKey;
        this.privateKey = privateKey;
        this.subject = subject;

        this.extraAttributes = [{ oid: OIDs.OPENID_ID_TOKEN, value: idToken }];

        this.crypto = getCrypto();
        this.extensions = {};
    }

    async toPKCS10(): Promise<ArrayBuffer> {
        const pkcs10 = new this.crypto.CertificationRequest();

        pkcs10.version = 0;

        const [attributes, subject] = await Promise.all([
            this.makeAttributes(),
            this.makeSubject(),
        ]);

        pkcs10.attributes = attributes;
        pkcs10.subject = subject;

        await pkcs10.subjectPublicKeyInfo.importKey(this.publicKey);
        await pkcs10.sign(this.privateKey, algorithm.hash);

        return await pkcs10.toSchema().toBER(false);
    }

    makeSubject() {
        const typesAndValues = subjectToPKCS10(this.subject);

        typesAndValues.sort(compareAsn1Objects);

        return new this.crypto.RelativeDistinguishedNames({ typesAndValues });
    }

    makeAttributes() {
        return this.makeExtensions().then((result) => {
            const attributes = [
                result,
                ...this.extraAttributes.map(attributeToPKCS10),
            ];

            attributes.sort(compareAsn1Objects);

            return attributes;
        });
    }

    makeExtensions() {
        let {
            Attribute,
            Extension,
            Extensions,
            BitString,
            OctetString,
        } = this.crypto;
        const crypto = this.crypto.getCrypto();

        return crypto
            .exportKey('spki', this.publicKey)
            .then((key) => crypto.digest({ name: algorithm.hash }, key))
            .then((keySubjectIdentifier) => {
                // These need to be kept in proper order
                return new Attribute({
                    type: OIDs.PKCS10_EXTENSIONS, // pkcs-9-at-extensionRequest
                    values: [
                        new Extensions({
                            extensions: [
                                // x509v3 Key Usage: critical, Digital Signature, Non Repudiation
                                new Extension({
                                    extnID: OIDs.X509_V3_KEY_USAGE,
                                    critical: true,
                                    extnValue: new BitString({
                                        valueHex: new Uint8Array([0xc0]),
                                    }).toBER(),
                                }),
                                // x509v3 Basic Constraints: critical, CA = false
                                new Extension({
                                    extnID: OIDs.X509_V3_BASIC_CONSTRAINTS,
                                    critical: true,
                                    extnValue: Uint16Array.from([0x30]),
                                }),
                                new Extension({
                                    extnID: OIDs.X509_V3_SUBJECT_KEY_IDENTIFIER,
                                    critical: false,
                                    extnValue: new OctetString({
                                        valueHex: keySubjectIdentifier,
                                    }).toBER(false),
                                }),
                            ],
                        }).toSchema(),
                    ],
                });
            });
    }
}

const subjectToPKCS10 = (subject) => {
    const { AttributeTypeAndValue, Utf8String } = getCrypto();

    return Object.keys(subject).map(
        (oid) =>
            new AttributeTypeAndValue({
                type: oid,
                value: new Utf8String({ value: subject[oid] }),
            })
    );
};

const attributeToPKCS10 = ({ oid, value }) => {
    const { Attribute, Utf8String } = getCrypto();

    return new Attribute({
        type: oid,
        values: [new Utf8String({ value: value })],
    });
};

const compareAsn1Objects = (a, b) => {
    return (
        compareAsn1ObjectsByLength(a, b) ||
        compareAsn1ObjectsByDEREncoding(a, b)
    );
};

const compareAsn1ObjectsByLength = (a, b) => {
    const sizeOnly = false;
    const sizeDiff =
        a.toSchema().toBER(sizeOnly).byteLength -
        b.toSchema().toBER(sizeOnly).byteLength;

    return Math.sign(sizeDiff);
};

const compareAsn1ObjectsByDEREncoding = (a, b) => {
    const encodedA = new Uint8Array(a.toSchema().toBER());
    const encodedB = new Uint8Array(b.toSchema().toBER());

    const maxLength = Math.max(encodedA.byteLength, encodedB.byteLength);

    for (let i = 0; i < maxLength; i++) {
        let aByte = encodedA[i] || 0;
        let bByte = encodedB[i] || 0;

        if (aByte !== bByte) {
            return Math.sign(aByte - bByte);
        }
    }

    return 0;
};

export const generateCSR = async (idToken: string, keyPair: KeyPair) => {
    const certificate = new CertificateRequestWrapper({
        publicKey: keyPair.publicKey,
        privateKey: keyPair.privateKey,
        idToken: idToken,
        subject: getOpenIDSubject(idToken),
    });

    const pkcs10 = await certificate.toPKCS10();

    return exportPEM(pkcs10);
};
