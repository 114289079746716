import React from 'react';
import moment, { Moment } from 'moment';
import { Recipient } from 'Casefiles/components/casefiles2/RecipientModal';
import { i18n } from 'Language';
import { ActivationDateModify } from 'Casefiles/components/casefiles2/ActivationDateModify';
import { SignerTypeEntity } from 'types/Signer';

export type Props = {
    availableRoles: SignerTypeEntity[];
    handleClearActiveAtDateButton: (roleId?: number) => void;
    onActivationDateChange: (date: Moment, roleId?: number) => void;
    recipient: Recipient;
};

export function RolesActivationDateModify(props: Props) {
    const {
        availableRoles,
        handleClearActiveAtDateButton,
        onActivationDateChange,
        recipient,
    } = props;

    const selectedRoles = availableRoles.filter(
        (availableRole) =>
            recipient.role.filter((role) => role.id === availableRole.id)
                .length > 0
    );

    return (
        <div className="mb">
            <div>
                <label>{i18n`Activation date`}</label>
            </div>
            {selectedRoles.map((role, index) => {
                const recipientRole = recipient.role.find((recipientRole) => {
                    return recipientRole.id === role.id;
                });

                const activateRoleAtMoment = recipientRole!.activeAt
                    ? moment.unix(recipientRole!.activeAt)
                    : undefined;

                return (
                    <div key={index}>
                        <div className="activation-date-role-name">
                            {role.role}
                        </div>
                        <ActivationDateModify
                            activationDate={activateRoleAtMoment}
                            onDateChange={onActivationDateChange}
                            onDateClear={handleClearActiveAtDateButton}
                            roleIdToUpdate={role.id}
                        />
                    </div>
                );
            })}
        </div>
    );
}
