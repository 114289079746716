import {
    configureStore,
    combineReducers,
    ThunkAction,
    Action,
} from '@reduxjs/toolkit';

import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

// Reducers

import CaseFileDetailsReducer from 'Casefiles/CasefileDetails/redux/reducer';
import preferencesReducer from 'Common/redux/Preferences';
import archiveReducer from 'Casefiles/Archive/redux/reducer';
import emailReducer from 'EmailTemplates/redux/reducer';
import newCasefileReducer from 'Casefiles/redux/newCaseFile/reducer';
import kycReducer from 'KYC/redux/reducer';
import registeredLetterReducer from 'RegisteredLetter/redux/reducer';
import folderReducer from 'Common/redux/Folder/reducer';
import { profileReducer } from 'Auth/redux/profiles/reducer';
import { brandingReducer } from 'Auth/components/Customer/CustomerBranding/redux/reducer';
import { openIdReducer } from 'OpenID/redux/reducer';
import { settingsReducer } from 'Settings/redux/reducer';
import { customerReducer } from 'Auth/redux/customer/reducer';
import { contactsReducer } from 'Contacts/redux/reducer';
import contactPickerReducer from 'Casefiles/redux/contacts/reducer';
import clientReducer from 'AuditingAccounting/redux/reducer';
import { trashReducer } from 'Casefiles/components/TrashCan/redux/reducer';
import entitiesReducer from 'Entities/redux/reducer';
import signingReducer from 'Signing/redux/reducer';
import clientsReducer from 'Clients/redux/reducer';
import itsmeQESSigning from 'ItsmeQES/redux/reducer';
import smsValidation from 'Auth/components/SMS/redux/reducer';
import roundsReducer from 'Casefiles/components/casefiles2/CasefileRounds/redux/reducer';

// Middleware

import { batchDispatchMiddleware } from 'redux-batched-actions';
import crashReporter from './Middleware/crash-reporter';
import freezeState from './Middleware/freeze';
import * as api from 'Api';

// We need to use `combineReducers` at root reducer level to be able to
// get proper typing when inferring the Redux state from `store.getState`.
// @note: This is only a requirement if we keep the rootReducer declared
// outside of `configureStore(...)`
export const rootReducer = combineReducers({
    archive: archiveReducer,
    contacts: contactsReducer,
    folders: folderReducer,
    newCasefile: newCasefileReducer,
    email: emailReducer,
    entities: entitiesReducer,
    caseFileDetails: CaseFileDetailsReducer,
    preferences: preferencesReducer,
    kyc: kycReducer,
    openId: openIdReducer,
    customer: combineReducers({
        details: customerReducer,
        branding: brandingReducer,
    }),
    profiles: profileReducer,
    settings: settingsReducer,
    contactPicker: contactPickerReducer,
    auditingAccounting: clientReducer,
    trash: trashReducer,
    registeredLetter: registeredLetterReducer,
    signing: signingReducer,
    clients: clientsReducer,
    itsmeQESSigning: itsmeQESSigning,
    smsValidation: smsValidation,
    rounds: roundsReducer,
});

const thunkOptions = {
    extraArgument: {
        api,
    },
};

export const store = configureStore({
    reducer: rootReducer,
    // Customize built-in middleware + add extra middleware
    // @see: https://redux-toolkit.js.org/api/getDefaultMiddleware#customizing-the-included-middleware
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: thunkOptions,
            serializableCheck: false,
        })
            .concat(batchDispatchMiddleware)
            .concat(crashReporter)
            .concat(freezeState),
});

// A global type to access reducers types
export type ReduxState = ReturnType<typeof store.getState>;

// Infer the dispatch type from store
export type AppDispatch = typeof store.dispatch;

// Create a type alias for thunks, to avoid repetition
// @see: https://redux.js.org/usage/usage-with-typescript#type-checking-redux-thunks
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    ReduxState,
    typeof thunkOptions.extraArgument, // Make AppThunk type aware of thunk's extra arguments
    Action<string>
>;

// Typing for utility function to get the current redux state from actions.
export type GetState = () => ReduxState;

// Export the dispatch function to be able to programatically dispatch actions from outside
// React components and the Redux loop
export const dispatch = store.dispatch;

// Export a hook for functional components that knows about the enhanced AppDispatch types
// @see: https://redux.js.org/usage/usage-with-typescript#typing-the-usedispatch-hook
export const useAppDispatch = () => useDispatch<AppDispatch>(); //useDispatch hook with types.

//useSelector hook with types
export const useAppSelector: TypedUseSelectorHook<ReduxState> = useSelector;
