import PropTypes from 'prop-types';
import React from 'react';
import LoginUtils from 'Auth/utils/login';

export default class SessionHandler extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    componentDidMount() {
        let path = LoginUtils.getDefaultRoute();

        this.handleRedirect(path);
    }

    // @duplicate: CallbackContainer.jsx
    handleRedirect = (path) => {
        if (path.type === 'route') {
            return this.context.router.replace(path.route);
        }

        return window.location.replace(path.url);
    };

    render() {
        return false;
    }
}
