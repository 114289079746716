import { Languages } from 'Language/Constants';
import { getDictionaryByLanguage } from 'Language/utils';

export type LocalizedDefaultEmailMessage = {
    completedEmailSubject: string;
    completedEmailText: string;
    emailSubject: string;
    emailText: string;
    reminderEmailSubject: string;
    reminderEmailText: string;
    language: Languages;
};

export const fetchLanguage = async (language: Languages) => {
    const dict = await getDictionaryByLanguage(language);
    const template = {
        completedEmailSubject: dict['emails.completedEmailSubject'],
        completedEmailText: dict['emails.completedEmailText'],
        emailSubject: dict['emails.emailSubject'],
        emailText: dict['emails.emailText'],
        reminderEmailSubject: dict['emails.reminderEmailSubject'],
        reminderEmailText: dict['emails.reminderEmailText'],
        language: language,
    };

    return template;
};
