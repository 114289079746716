import { Contact } from 'types/Contact';
import { UserEntity } from 'types/User';
import { ContactsNormalized } from '../../State';

export const normalize = (contacts: any[]) =>
    contacts.reduce((obj, item) => {
        obj[item.id] = item;

        return obj;
    }, {});

export const denormalize = <T extends Contact | UserEntity>(
    contactsNormalized: ContactsNormalized
): T[] =>
    Object.keys(contactsNormalized).reduce((arr: T[], key) => {
        arr.push(contactsNormalized[key] as any);

        return arr;
    }, []);
