import createReducer from 'create-reducer';
import { ActionTypes } from './action-types';
import { TrashActions, TrashState } from './types';

export const trashInitialState: TrashState = {
    trashList: null,
    itemCount: 0,
    selectedRows: [],
    isFetching: false,
    action: null,
    error: null,
    operationId: null,
};

export const trashReducer = createReducer<TrashState>(trashInitialState, {
    [ActionTypes.TRASH_RESET]: () => trashInitialState,
    [ActionTypes.TRASH_API_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        error: null,
    }),
    [ActionTypes.TRASH_API_FAILURE]: (state, error) => ({
        ...state,
        isFetching: false,
        action: null,
        error,
    }),
    [ActionTypes.TRASH_FETCH_REQUEST]: (state) => ({
        ...state,
        trashList: [],
    }),
    [ActionTypes.TRASH_FETCH_SUCCESS]: (
        state,
        { trashList, selectedRows, itemCount }
    ) => ({
        ...state,
        trashList,
        selectedRows,
        itemCount,
        isFetching: false,
    }),
    [ActionTypes.TRASH_RESTORE_SUCCESS]: (state) => ({
        ...state,
        action: TrashActions.RESTORE,
        operationId: Date.now(),
    }),
    [ActionTypes.TRASH_RESTORE_ALL_SUCCESS]: () => ({
        ...trashInitialState,
        trashList: [],
        action: TrashActions.RESTORE,
        operationId: Date.now(),
    }),
    [ActionTypes.TRASH_DELETE_SUCCESS]: (state) => ({
        ...state,
        action: TrashActions.DELETE,
        operationId: Date.now(),
    }),
    [ActionTypes.TRASH_DELETE_ALL_SUCCESS]: () => ({
        ...trashInitialState,
        trashList: [],
        action: TrashActions.DELETE,
        operationId: Date.now(),
    }),
    [ActionTypes.TRASH_SELECTED_ROW]: (state, { selectedRows }) => ({
        ...state,
        selectedRows,
    }),
});
