import { CaseFileItem } from 'Casefiles/Archive';

export type Client = {
    id: number;
    name: string;
    reference?: string;
    created?: number;
    updated?: number;
    entities?: {
        type: ClientLinkEntityType;
        total: number;
        // TODO: extend to support Contacts with |
        list: Partial<CaseFileItem>[];
    };
};

export enum ClientLinkEntityType {
    'casefile' = 'casefile',
    'contact' = 'contact',
}

export type ClientLinkEntity = {
    entityType: ClientLinkEntityType;
    entityId?: number;
};

export type ClientLinks = {
    linkEntity: ClientLinkEntity;
    saved: Client[];
    new: Client[];
    dirty: boolean;
};
