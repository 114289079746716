import deepFreeze from 'deep-freeze';

const freezeStoreState = (store) => deepFreeze(store.getState());

export default function freeze(store) {
    freezeStoreState(store);

    return (next) => (action) => {
        freezeStoreState(store);

        return next(action);
    };
}
