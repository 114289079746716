import { createComponent } from '@lit/react';
import { DatePicker } from '@penneo/vega-ui';
import React from 'react';

const UIDatePicker = createComponent({
    tagName: 'vega-date-picker',
    elementClass: DatePicker,
    react: React,
});

export default UIDatePicker;
