import React from 'react';
import { i18n } from 'Language';
import Tooltip from 'Common/components/Tooltip';

type Props = {
    width?: number;
    text: string;
    icon?: string;
};

const HelpTooltip = ({
    width = 200,
    text,
    icon = 'fa-question-circle',
}: Props) => (
    <Tooltip
        showArrow={true}
        text={
            <div
                className="text-left"
                style={{ width: `${width}px`, padding: '0.5em' }}>
                {i18n(text)}
            </div>
        }>
        <i className={`far ${icon} text-blue`} />
    </Tooltip>
);

export default HelpTooltip;
