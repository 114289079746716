import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import { modal } from 'Common/components/Common/Modal.jsx';
import UserPicker from 'Common/components/UserPicker';
import lodash from 'lodash';
import Button from 'Common/components/Button';

export default class UserPickerModal extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        users: PropTypes.array.isRequired,
        successButtonLabel: PropTypes.string,
        successButton: PropTypes.func,
    };

    static defaultProps = {
        title: '',
        users: [],
        successButtonLabel: 'Save',
    };

    state = {
        users: lodash.cloneDeep(this.props.users),
        selectedUsers: [],
    };

    toggleSelection = (user) => {
        let { users, selectedUsers } = this.state;
        let newSelectedUsers = selectedUsers.map((su) => su);
        let newUsers = users.map((u) => u);

        user.selected = !user.selected;

        if (!user.selected) {
            newSelectedUsers = selectedUsers.filter(
                (selectedUser) => selectedUser.id !== user.id
            );
        } else {
            newSelectedUsers.push(user);
        }

        this.setState({
            users: newUsers,
            selectedUsers: newSelectedUsers,
        });
    };

    successButton = (selectedUsers) => () =>
        this.props.successButton(selectedUsers);

    render() {
        let { title, successButtonLabel } = this.props;
        let { selectedUsers, users } = this.state;

        return (
            <div className="casefile-modal-v2">
                <h3 className="casefile-modal-v2-title">{title}</h3>

                <div className="form-v2">
                    <UserPicker
                        users={users}
                        onChange={this.updateState}
                        toggleSelection={this.toggleSelection}
                    />

                    <Button
                        theme="gray"
                        variant="outline"
                        className="pull-left"
                        onClick={modal.hide}>
                        {i18n`Cancel`}
                    </Button>
                </div>

                <div className="footer-content text-right form-v2">
                    <Button
                        theme="blue"
                        className="pull-right"
                        onClick={this.successButton(selectedUsers)}>
                        {i18n(successButtonLabel)}
                    </Button>
                </div>
            </div>
        );
    }
}
