import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';

export default class Checkbox extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        className: PropTypes.string,
        label: PropTypes.node,
        name: PropTypes.string,
        style: PropTypes.object,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        checked: PropTypes.bool,
        labelBefore: PropTypes.bool,
    };

    static defaultProps = {
        labelBefore: false,
        checked: false,
    };

    handleChange = (event) => {
        let { checked } = event.target;

        return this.props.onChange(checked, event);
    };

    render() {
        // HTML Properties
        let { checked, style, name, disabled, required } = this.props;

        // Data
        let { label, labelBefore } = this.props;

        const className = classnames('custom-checkbox', this.props.className, {
            required: this.props.required,
            checked: this.props.checked,
            disabled: this.props.disabled,
        });

        return (
            <label className={className} style={style}>
                {labelBefore && label && <span>{label}</span>}
                <input
                    type="checkbox"
                    name={name}
                    disabled={disabled}
                    checked={checked}
                    required={required}
                    onChange={this.handleChange}
                />
                <span className="check">
                    <i className="fas fa-check" />
                </span>
                {!labelBefore && label && <span>{label}</span>}
            </label>
        );
    }
}
