// Extend global Window type with intercom properties. (@types/intercom-web)
/// <reference types="intercom-web" />

import { Platforms, env, parameters } from 'Constants';
import { CustomerEntity } from 'types/Customer';
import { UserEntity } from 'types/User';
import launchDarkly, { Flags } from './LaunchDarkly';
import { debug } from 'Core';

/**
 * Extends global Window type with Intercom dependency on window.attachEvent.
 */
interface WindowWithIntercom extends Window {
    /**
     * Global Window object doesn't contain `.attachEvent` because it's a proprietary
     * Microsoft alternative to addEventListener. The intercom script adds it for compatibility with IE6+.
     *
     * @see: https://developer.mozilla.org.cach3.com/en-US/docs/Web/API/EventTarget/attachEvent
     *
     * We could drop that part of the script since we don't need that level of browser
     * compatibility, but it's safer to avoid modifying 3rd party scripts.
     * */
    attachEvent: any;
}

/**
 * Type to map user properties in Penneo Sign to Intercom built-in and custom properties
 * @see: https://app.eu.intercom.com/a/apps/jcwl7tbd/settings/people-data
 */
type IntercomSettings = {
    /**
     * Intercom Application ID
     * Specified in /parameters/{environment}.js
     *
     * @note Should only added in development (for testing purposes) and production environment.
     * We don't have different stages for Intercom and we want to avoid clashes between users from multiple Penneo environments.
     *
     * @intercom built-in required property.
     */
    app_id: string;

    /**
     * API endpoint base. Required for EU data residency.
     * @see: https://developers.intercom.com/installing-intercom/web/installation/#installing-the-messenger-for-regional-data-hosted-customers
     *
     * @intercom built-in property
     */
    api_base: string;

    /**
     * Current logged in user.
     * @intercom built-in property
     */
    user_id: string;

    /**
     * Penneo Sign User role (user, administrator)
     * @intercom custom property
     */
    user_role: string;

    /**
     * User language
     * @intercom custom property
     */
    user_language: string;

    /**
     * Penneo Customer ID
     * @intercom custom property
     */
    customer_id: string;

    /**
     * Penneo Customer Name
     * @intercom custom property
     */
    customer_name: string;

    /**
     * Penneo Customer Language
     * @intercom custom property
     */
    customer_language: string;

    /**
     * Track the Penneo Product to be able to segment traffic to only this application.
     * @intercom custom property
     */
    'Penneo Product': 'Penneo Sign';
};

/**
 * Creates Intercom client, attaches Intercom scripts and
 * enhances the global window object with window.Intercom.
 */
function attachIntercom(intercomSettings: IntercomSettings) {
    var _w = window;

    // Attach intercom script to html document and enhance window object.
    // @copied from: https://developers.intercom.com/installing-intercom/web/installation/
    // Slightly updated for typescript compatibility (denoted with comments)
    (function () {
        var w = _w;
        var ic = w.Intercom;

        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', intercomSettings);
        } else {
            var d = document;

            // Added any type to supress typescript error
            var i: any = function () {
                i.c(arguments);
            };

            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;

            var l = function () {
                var s = d.createElement('script');

                s.type = 'text/javascript';
                s.async = true;
                s.src =
                    'https://widget.intercom.io/widget/' +
                    intercomSettings.app_id;
                var x = d.getElementsByTagName('script')[0];

                // Supress error with parentNode being potentially undefined if the script tag is not added.
                x.parentNode?.insertBefore(s, x);
            };

            if (document.readyState === 'complete') {
                l();
            } else if (((w as Window) as WindowWithIntercom).attachEvent) {
                // Use extended window type to support .attachEvent
                ((w as Window) as WindowWithIntercom).attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    })();
}

/**
 * This method to clears any user data and cookies associated with intercom.
 * Otherwise, the intercom tracking cookie will be active for a week.
 *
 * @note This method should ideally be called when a user logs out of the application.
 */
function shutdown() {
    invokeIntercomMethod('shutdown');
}

/**
 * Trigger a survey programmatically. You need to call this method with a valid id of a survey.
 *
 * @note Surveys must be published and the “Use survey everywhere” section must be turned on.
 * If this is called with an invalid ID, nothing will happen
 */
function startSurvey(surveyId: number) {
    invokeIntercomMethod('startSurvey', surveyId);
}

/**
 * Trigger a tour programmatically. You need to call this method with a valid id of a tour.

 * @note Tours must be published and the “Use tour everywhere” section must be turned on.
 * If this is called with an invalid tour ID, nothing will happen
 */
function startTour(tourId: number) {
    invokeIntercomMethod('startTour', tourId);
}

/**
 * Proxy to execute intercom methods only if intercom is initialized.
 */
function invokeIntercomMethod(
    command: keyof Intercom_.IntercomCommandSignature,
    arg?: any
) {
    // Avoid running commands if Intercom is not initialized.
    if (!window.Intercom) {
        return false;
    }

    debug.info(`[Intercom] ${command}`, arg);

    try {
        window.Intercom(command, arg);
    } catch (error) {
        debug.error(error);
    }
}

/**
 * Starts intercom and identifies user/company properties.
 *
 * @note This should only be used after a successful login or when user/customer details are updated.
 */
function init(user: UserEntity, customer?: CustomerEntity) {
    // Only initialize if Intercom's App ID is set in parameters
    // /parameters/{environment}.js
    if (!parameters.intercomAppId) {
        return false;
    }

    // Only allow intercom to be initialized on Web client.
    if (env.platform !== Platforms.WEB) {
        return false;
    }

    // Control Intercom with LaunchDarkly feature flag.
    if (!launchDarkly.variation(Flags.INTERCOM_ENABLED)) {
        return false;
    }

    // Only allow customer users to be targeted via Intercom
    if (!customer) {
        return false;
    }

    // Create intercom configuration
    const intercomSettings: IntercomSettings = {
        // Built-in properties
        app_id: parameters.intercomAppId,
        user_id: user.id.toString(),

        // EU Data residency
        api_base: 'https://api-iam.eu.intercom.io',

        // Custom Properties
        user_role: user.role,
        user_language: user.language,
        customer_id: customer.id.toString(),
        customer_name: customer.name,
        customer_language: customer.language,

        /**
         * @note Do not change or remove unless you know what you're doing
         *
         * As of December 2023, we are sharing accounts in Intercom for Penneo Sign and KYC.
         * This is a way to avoid overlapping segments.
         *
         * @see: https://www.notion.so/penneo/How-to-use-Intercom-in-multiple-products-18f4d180e1fc4f3b936d9fb43e0f33de?pvs=4
         */
        'Penneo Product': 'Penneo Sign',
    };

    // Attach script with Intercom configuration.
    attachIntercom(intercomSettings);

    // Initialize Intercom
    // @see: https://developers.intercom.com/installing-intercom/web/installation/#step-2-launch-messenger
    invokeIntercomMethod('boot', intercomSettings);
}

/** Utilities to interact with intercom */
export const intercom = {
    init,
    shutdown,
    startSurvey,
    startTour,
};
