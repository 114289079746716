/* eslint camelcase: 0 */
import { OIDs } from 'OpenID/Constants';
import {
    OpenIDObj,
    OpenIDCertificate,
    OpenIDToken,
    OpenIDSubjectIdentifiers,
} from 'OpenID/redux/types';

export type GoogleToken = OpenIDToken & {
    iss: string;
    azp: string;
    email: string;
    email_verified: boolean;
    at_hash: string;
    nonce: string;
    name: string;
    picture: string;
    given_name: string;
    family_name: string;
    locale: string;
};

export type GoogleSubjectIdentifiers = OpenIDSubjectIdentifiers & {
    [OIDs.OPENID_EMAIL]: string;
    [OIDs.OPENID_FULL_NAME_V2]: string;
};

export type GoogleCertificate = OpenIDCertificate<GoogleSubjectIdentifiers>;

export const google: OpenIDObj<GoogleToken, GoogleCertificate> = {
    /**
     * @see https://developers.google.com/identity/protocols/oauth2/openid-connect#an-id-tokens-payload
     */
    issuers: ['https://accounts.google.com', 'accounts.google.com'],
    getSubject(token) {
        return {
            [OIDs.OPENID_ISSUER]: token.iss,
            [OIDs.OPENID_ID]: token.sub,
            [OIDs.OPENID_FULL_NAME_V2]: token.name,
            [OIDs.OPENID_EMAIL]: token.email,
        };
    },
    isOwnToken(token): token is GoogleToken {
        return this.issuers.includes(token.iss);
    },
    isOwnCertificate(certificate): certificate is GoogleCertificate {
        const { subject } = certificate;

        return (
            this.issuers.includes(subject[OIDs.OPENID_ISSUER]) &&
            !!(subject[OIDs.OPENID_FULL_NAME_V2] && subject[OIDs.OPENID_EMAIL])
        );
    },
    getCertificateDisplayInfo({ subject, issuer }) {
        const {
            [OIDs.OPENID_FULL_NAME_V2]: fullName,
            [OIDs.OPENID_EMAIL]: email,
        } = subject;

        const title = `${fullName} (${email})`;

        return { title, issuer: issuer[OIDs.OPENID_ORG_NAME_V2] };
    },
};
