import { PDFDocumentProxy } from 'pdfjs-dist';
import React, { useCallback, useEffect, useState } from 'react';
import { PdfPreviewError } from '.';

type Props = {
    pdfData: Blob;
};

export const PdfPreviewer = ({ pdfData }: Props) => {
    const [data, setData] = useState<string>();
    const [error, setError] = useState<Error>();

    const renderPage = useCallback(
        (pageNumber: number, pdf: PDFDocumentProxy) => {
            const totalPages = pdf.numPages;
            const nextPage = pageNumber + 1;

            pdf.getPage(pageNumber).then((page) => {
                const scale = 1.5;
                const viewport = page.getViewport({
                    scale: scale,
                });

                // pdf container
                const pdfViewer = document.getElementById('pdf-viewer')!;
                // Prepare canvas using PDF page dimensions
                const canvas = document.createElement('canvas');
                const context = canvas.getContext(
                    '2d'
                ) as CanvasRenderingContext2D;

                canvas.height = viewport.height;
                canvas.width = viewport.width;

                pdfViewer.appendChild(canvas);

                // Render PDF page into canvas context
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                const renderTask = page.render(renderContext);

                // wait until the current page is rendered and then render the following page
                renderTask.promise.then(() => {
                    if (nextPage <= totalPages) {
                        renderPage(nextPage, pdf);
                    }
                });
            });
        },
        []
    );

    useEffect(() => {
        const reader = new FileReader();
        const readyFn = () => {
            try {
                const result = reader.result as string;
                const base64 = result.replace(/^data:.+;base64,/, '');

                setData(atob(base64));
            } catch (error) {
                setError(error);
            }
        };

        reader.addEventListener('load', readyFn);
        reader.readAsDataURL(pdfData);

        return () => reader.removeEventListener('load', readyFn);
    }, [pdfData]);

    useEffect(() => {
        if (!data) {
            return;
        }

        (async () => {
            const pdfjs = await import(
                /* webpackChunkName: "pdfjs-dist" */ 'pdfjs-dist/legacy/build/pdf'
            );
            const pdfjsWorker = await import(
                /* webpackChunkName: "pdfjs-worker" */ 'pdfjs-dist/legacy/build/pdf.worker.entry'
            );

            pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

            const loadingTask = pdfjs.getDocument({ data });

            loadingTask.promise.then(
                (pdf) => {
                    // Start rendering from page 1. The function will take care of rendering the rest of them
                    renderPage(1, pdf);
                },
                (reason) => {
                    setError(reason);
                }
            );
        })();
    }, [data, renderPage]);

    if (!error && !data) {
        return null;
    }

    return error ? (
        <PdfPreviewError error={error} />
    ) : (
        <div id="pdf-viewer" className="document-preview-pdf-canvas" />
    );
};

export default PdfPreviewer;
