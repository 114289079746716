import PropTypes from 'prop-types';
import React from 'react';
import Header from '../Header/Header.jsx';
import BrandingStore from '../../stores/BrandingStore';

export default class HeaderContainer extends React.Component {
    static propTypes = {
        children: PropTypes.object,
    };

    componentWillMount() {
        BrandingStore.addChangeListener(this.brandingChange);
    }

    componentWillUnmount() {
        BrandingStore.removeChangeListener(this.brandingChange);
    }

    brandingChange = () => {
        this.setState({
            branding: BrandingStore.getBranding(),
            brandingLoaded: true,
        });
    };

    render() {
        return (
            <div className="header-container">
                <Header {...this.props} {...this.state} />
                <div className="header-layout-content">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
