export const CASEFILE_FETCH_REQUEST = 'CASEFILE_FETCH_REQUEST';
export const CASEFILE_FETCH_SUCCESS = 'CASEFILE_FETCH_SUCCESS';
export const CASEFILE_FETCH_FAILURE = 'CASEFILE_FETCH_FAILURE';

export const CASEFILE_ATTACHMENTS_FETCH_SUCCESS =
    'CASEFILE_ATTACHMENTS_FETCH_SUCCESS';

export const CASEFILE_PERSIST_REQUEST = 'CASEFILE_PERSIST_REQUEST';
export const CASEFILE_PERSIST_SUCCESS = 'CASEFILE_PERSIST_SUCCESS';
export const CASEFILE_PERSIST_FAILURE = 'CASEFILE_PERSIST_FAILURE';

export const CASEFILE_UPDATE_LOCAL = 'CASEFILE_UPDATE_LOCAL';

export const EVENTLOG_FETCH_REQUEST = 'EVENTLOG_FETCH_REQUEST';
export const EVENTLOG_FETCH_SUCCESS = 'EVENTLOG_FETCH_SUCCESS';
export const EVENTLOG_FETCH_FAILURE = 'EVENTLOG_FETCH_FAILURE';

export const DOCUMENT_FETCH_REQUEST = 'DOCUMENT_FETCH_REQUEST';
export const DOCUMENT_FETCH_SUCCESS = 'DOCUMENT_FETCH_SUCCESS';
export const DOCUMENT_FETCH_FAILURE = 'DOCUMENT_FETCH_FAILURE';

export const CASEFILE_FOLDER_FETCH_REQUEST = 'CASEFILE_FOLDER_FETCH_REQUEST';
export const CASEFILE_FOLDER_FETCH_SUCCESS = 'CASEFILE_FOLDER_FETCH_SUCCESS';
export const CASEFILE_FOLDER_FETCH_FAILURE = 'CASEFILE_FOLDER_FETCH_FAILURE';
export const CASEFILE_FOLDER_RESET = 'CASEFILE_FOLDER_RESET';

export const DOCUMENTS_BACKUP = 'DOCUMENTS_BACKUP';
export const DOCUMENTS_BACKUP_RESTORE = 'DOCUMENTS_BACKUP_RESTORE';

export const SIGNER_BACKUP_REQUEST = 'SIGNER_BACKUP_REQUEST';
export const SIGNER_BACKUP_SUCCESS = 'SIGNER_BACKUP_SUCCESS';
export const SIGNER_BACKUP_FAILURE = 'SIGNER_BACKUP_FAILURE';
export const SIGNER_RESTORE_LOCAL = 'SIGNER_RESTORE_LOCAL';
export const SIGNER_UPDATE_LOCAL = 'SIGNER_UPDATE_LOCAL';

export const UPDATE_SIGNATURE_LINES_ACTIVE_AT =
    'UPDATE_SIGNATURE_LINES_ACTIVE_AT';
export const CLEAR_SIGNATURE_LINES_ACTIVE_AT =
    'CLEAR_SIGNATURE_LINES_ACTIVE_AT';

export const PERSIST_SIG_LINE_REQUEST = 'PERSIST_SIG_LINE_REQUEST';
export const PERSIST_SIG_LINE_SUCCESS = 'PERSIST_SIG_LINE_SUCCESS';
export const PERSIST_SIG_LINE_FAILURE = 'PERSIST_SIG_LINE_FAILURE';

export const SIGNER_PERSIST_REQUEST = 'SIGNER_PERSIST_REQUEST';
export const SIGNER_PERSIST_SUCCESS = 'SIGNER_PERSIST_SUCCESS';

export const CASEFILE_REFRESH_SUCCESS = 'CASEFILE_REFRESH_SUCCESS';

export const SIGNER_LOG_FETCH_REQUEST = 'SIGNER_LOG_FETCH_REQUEST';
export const SIGNER_LOG_FETCH_SUCCESS = 'SIGNER_LOG_FETCH_SUCCESS';
export const SIGNER_LOG_FETCH_FAILURE = 'SIGNER_LOG_FETCH_FAILURE';

export const EXTEND_EXPIRATION_REQUEST = 'EXTEND_EXPIRATION_REQUEST';
export const EXTEND_EXPIRATION_SUCCESS = 'EXTEND_EXPIRATION_SUCCESS';
export const EXTEND_EXPIRATION_FAILURE = 'EXTEND_EXPIRATION_FAILURE';

export const CLEAR_CASEFILE_DETAILS_REDUCER = 'CLEAR_CASEFILE_DETAILS_REDUCER';
