import React from 'react';
import ChromePicker from 'react-color/lib/Chrome';
import { i18n } from 'Language';
import Button from 'Common/components/Button';

type Props = {
    /**
     * The selected color
     */
    color: string;
    /**
     * The label that is going to be displayed above the color picker
     * Needs to be translated before passing to this component
     */
    label?: string;
    /**
     * Function that triggers on color change.
     * Takes in a string as a parameter
     *
     * @example
     *  <ColorPicker
     *    ...
     *    onChange={(color: string) => this.doSomething(color)}
     *  />
     *
     */
    onChange: (color: string) => void;
    /**
     * Optional validation options
     */
    validation?: {
        message: string;
        showError: boolean;
    };
};

type State = {
    open: boolean;
};

export default class ColorPicker extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    open = () => {
        this.setState({ open: true });
    };

    close = () => {
        this.setState({ open: false });
    };

    onChange = (color) => {
        this.props.onChange(color.hex);
    };

    render() {
        const { color, label, validation } = this.props;
        const { open } = this.state;

        return (
            <div className="color-picker">
                {label && <label>{i18n(label)}</label>}
                <Button
                    className="color-picker-button"
                    variant="outline"
                    theme="gray"
                    onClick={this.open}
                    style={{
                        ...(validation?.showError && {
                            borderColor: '#fad470',
                        }),
                    }}>
                    {color}
                    <div
                        className="color-picker-preview"
                        style={{ background: color }}
                    />
                </Button>
                {validation?.showError && (
                    <div className="input-validation">
                        <span className="input-validation-message">
                            {validation?.message}
                        </span>
                    </div>
                )}
                {open && (
                    <>
                        <div
                            className="color-picker-cover"
                            onClick={this.close}
                        />
                        <div className="color-picker-container color-picker-popover">
                            <ChromePicker
                                color={color}
                                onChange={this.onChange}
                                disableAlpha={true}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }
}
