import React from 'react';
import { API_RESPONSE_HEADERS } from 'Constants';
import { ApiError } from './types';
import Message from '../Message';
import { i18n } from 'Language';

type Props = {
    error: ApiError | Error;
};

export const PdfPreviewError = ({ error }: Props) => {
    const { headers } = error as ApiError;

    const traceId = !!headers && headers[API_RESPONSE_HEADERS.REQUEST_ID];
    const requestId = !!headers && headers[API_RESPONSE_HEADERS.TRACE_ID];

    return (
        <Message type="error">
            <div>
                {i18n`An error occured while rendering the document. Please try
                again or contact our support.`}
                {requestId && (
                    <div className="mt">
                        Error:{' '}
                        <i>
                            {requestId}
                            {traceId && `:${traceId}`}
                        </i>
                    </div>
                )}
            </div>
        </Message>
    );
};
