import React from 'react';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';

type Props = {
    flag: Flags;
    on?: JSX.Element;
    off?: JSX.Element;
};

export default class FeatureFlag extends React.Component<Props> {
    static defaultProps = {
        off: null,
    };

    isFlagEnabled = false;

    constructor(props: Props) {
        super(props);

        // Initialize variation on component mount.
        // @note: This avoids flag evaluations per render cycle
        this.isFlagEnabled = launchDarkly.variation(this.props.flag);
    }

    render() {
        const { on, off } = this.props;

        // Support passing JSX as children if we don't need to render two things conditionally on props.on and props.off.
        if (!on && !off) {
            return this.isFlagEnabled ? this.props.children : null;
        }

        return this.isFlagEnabled ? this.props.on : this.props.off;
    }
}
