import React from 'react';
import { i18n } from 'Language';
import moment from 'moment';
import classnames from 'classnames';
import { EventLogState } from './redux/types';
import EmailSubjectLink from './EmailSubjectLink';
import './casefile-eventlog.scss';
import {
    EventLogCasefile,
    EventLogData,
    EventLogSignerEvent,
} from 'types/EventLog';

type Props = {
    events: EventLogState;
    casefileId: number;
};

const CASEFILE_LOGS = {
    0: {
        name: 'created',
        color: 'blue',
        icon: 'fas fa-sparkles',
        text: ({ user }) => (
            <span>
                {i18n`Case file was created by
                ${(
                    <span>
                        <span className="user">{user.fullName}</span>&nbsp;
                        <span className="email">&lt;{user.email}&gt;</span>
                    </span>
                )}`}
            </span>
        ),
    },
    1: {
        name: 'modified',
        icon: 'far fa-pencil',
        text: ({ user }) =>
            user ? (
                <span>
                    {i18n`Case file was modified by
                    ${(
                        <span>
                            <span className="user">{user.fullName}</span>&nbsp;
                            <span className="email">&lt;{user.email}&gt;</span>
                        </span>
                    )}`}
                </span>
            ) : (
                <span>{i18n`Case file was modified`}</span>
            ),
    },
    2: {
        name: 'activated',
        icon: 'far fa-fast-forward',
        text: ({ user }) => (
            <span>
                {i18n`Case file was activated by ${(
                    <span>
                        <span className="user">{user.fullName}</span>&nbsp;
                        <span className="email">&lt;{user.email}&gt;</span>
                    </span>
                )}`}
            </span>
        ),
    },
    /**
     * NOTE: If the casefile has been automatically deleted
     * due to customer settings, there is no user attached to
     * this event. Therefore, we will show a more adequate message
     */
    3: {
        name: 'deleted',
        icon: 'far fa-trash-alt',
        text: ({ user }) => (
            <span>
                {user
                    ? i18n`Case file was deleted by
                ${(
                    <span>
                        <span className="user">{user.fullName}</span>&nbsp;
                        <span className="email">&lt;{user.email}&gt;</span>
                    </span>
                )}`
                    : i18n`Case file was automatically deleted`}
            </span>
        ),
    },
    4: {
        name: 'completed',
        color: 'green',
        icon: 'fas fa-check',
        text: () => (
            <span>
                {i18n`Case file was processed by ${(
                    <b className="user">Penneo</b>
                )}`}{' '}
                <i className="far fa-check-circle text-green"></i>
            </span>
        ),
    },
    5: {
        name: 'failed',
        color: 'red',
        icon: 'far fa-times',
        text: () => <span>{i18n`Case file processing failed`}</span>,
    },
    6: {
        name: 'restored',
        icon: 'far fa-trash-undo-alt',
        text: ({ user }) => (
            <span>
                {i18n`Case file was restored by
                ${(
                    <span>
                        <span className="user">{user.fullName}</span>&nbsp;
                        <span className="email">&lt;{user.email}&gt;</span>
                    </span>
                )}`}
            </span>
        ),
    },
    7: {
        name: 'force_deleted',
        color: 'red',
        icon: 'far fa-trash-undo-alt',
        text: ({ user }) => (
            <span>
                {i18n`Case file was force-deleted by
                ${(
                    <span>
                        <span className="user">{user.fullName}</span>&nbsp;
                        <span className="email">&lt;{user.email}&gt;</span>
                    </span>
                )}`}
            </span>
        ),
    },
    8: {
        name: 'signed',
        icon: 'far fa-pen-nib',
        text: () => (
            <span>{i18n`The case file was signed by all signers`}</span>
        ),
    },
    9: {
        name: 'opened',
        icon: 'far fa-envelope-open-text',
        text: ({ user }) => (
            <span>
                {i18n`Case file was opened by
                ${(
                    <span>
                        <span className="user">{user.fullName}</span>&nbsp;
                        <span className="email">&lt;{user.email}&gt;</span>
                    </span>
                )}`}
            </span>
        ),
    },
    10: {
        name: 'delayed',
        icon: 'far fa-hourglass-half',
        text: () => <span>{i18n`Case file processing has been delayed`}</span>,
    },
    11: {
        name: 'expired',
        color: 'red',
        icon: 'far fa-calendar-times',
        text: () => <span>{i18n`Case file has expired`}</span>,
    },
    12: {
        name: 'rejected',
        color: 'red',
        icon: 'far fa-ban',
        text: () => <span>{i18n`Case file was rejected`}</span>,
    },
    13: {
        name: 'anonymized',
        icon: 'far fa-user-secret',
        text: () => <span>{i18n`Case file data has been anonymized`}</span>,
    },
    14: {
        name: 'SIGNER_COPY_REMOVED',
        icon: 'far fa-info',
        text: () => (
            <span>
                {i18n`The case has been removed from the recipient's archive`}
            </span>
        ),
    },
    99: {
        name: 'unknown',
        icon: 'far fa-question',
        text: () => <span>{i18n`Unknown event`}</span>,
    },
};

// const TYPE_REQUEST_SENT      = 0;
// const TYPE_REQUEST_OPENED    = 1;
// const TYPE_OPENED            = 2;
// const TYPE_SIGNED            = 3;
// const TYPE_REJECTED          = 4;
// const TYPE_EMAIL_SENT        = 5;
// const TYPE_UNDELIVERABLE     = 6;
// const TYPE_REQUEST_ACTIVATED = 7;
// const TYPE_FINALIZED         = 8;
// const TYPE_UNKNOWN           = 20;

const EMAIL_LOGS = {
    0: {
        name: 'TYPE_REQUEST_SENT',
        icon: 'far fa-envelope',
        text: (
            { name, recipient, subject, emailLogId },
            casefileId: number
        ) => (
            <span>
                {i18n`Email was sent to
                    ${(
                        <span>
                            <span className="user">
                                {recipient ? recipient : name}
                            </span>
                            &nbsp;
                        </span>
                    )}`}
                {emailLogId && casefileId && (
                    <EmailSubjectLink
                        casefileId={casefileId}
                        emailLogId={emailLogId}
                        subject={subject}
                    />
                )}
            </span>
        ),
    },
    1: {
        name: 'TYPE_REQUEST_OPENED',
        icon: 'far fa-envelope-open-text',
        text: ({ name }) => (
            <span>
                {i18n`Email was opened by
                ${(
                    <span>
                        <span className="user">{name}</span>&nbsp;
                    </span>
                )}`}
            </span>
        ),
    },
    2: {
        name: 'TYPE_OPENED',
        icon: 'far fa-envelope-open-text',
        text: ({ name }) => (
            <span>
                {i18n`Case file was opened by
                ${(
                    <span>
                        <span className="user">{name}</span>&nbsp;
                    </span>
                )}`}
            </span>
        ),
    },
    3: {
        name: 'TYPE_SIGNED',
        icon: 'far fa-pen-nib',
        text: ({ name }) => (
            <span>
                {i18n`Case file was signed by
                ${(
                    <span>
                        <span className="user">{name}</span>&nbsp;
                    </span>
                )}`}
            </span>
        ),
    },
    4: {
        name: 'TYPE_REJECTED',
        icon: 'far fa-ban',
        text: ({ name }) => (
            <span>
                {i18n`${(
                    <span className="user">{name}</span>
                )} has rejected to sign`}
            </span>
        ),
    },
    5: {
        // @NOTE name is erroneously TYPE_REMINDER_SENT on the backend, even if
        // id = 5 covers other email communication than reminders
        name: 'TYPE_EMAIL_SENT',
        icon: 'far fa-envelope',
        text: (
            { name, recipient, subject, emailLogId },
            casefileId: number
        ) => (
            <span>
                {i18n`Email was sent to
                    ${(
                        <span>
                            <span className="user">
                                {recipient ? recipient : name}
                            </span>
                            &nbsp;
                        </span>
                    )}`}
                {emailLogId && casefileId && (
                    <EmailSubjectLink
                        casefileId={casefileId}
                        emailLogId={emailLogId}
                        subject={subject}
                    />
                )}
            </span>
        ),
    },
    6: {
        name: 'TYPE_UNDELIVERABLE',
        icon: 'far fa-times',
        text: ({ name }) => (
            <span>
                {i18n`We could not deliver an email to
                ${(
                    <span>
                        <span className="user">{name}</span>
                    </span>
                )}`}
            </span>
        ),
    },
    7: {
        name: 'TYPE_REQUEST_ACTIVATED',
        icon: 'far fa-calendar-check',
        text: ({ name }) => (
            <span>
                {i18n`Signing link for
                ${(
                    <span>
                        <span className="user">{name}</span>
                    </span>
                )}
                has been activated`}
            </span>
        ),
    },
    8: {
        name: 'TYPE_FINALIZED',
        icon: 'far fa-check',
        text: ({ name }) => (
            <span>
                {i18n`${(<span className="user">{name}</span>)}
                has finalized the casefile`}
            </span>
        ),
    },
    99: {
        name: 'TYPE_UNKNOWN',
        icon: 'far fa-question',
        text: () => <span>{i18n`Unknown event`}</span>,
    },
};

const normalizeEvents = (logs: EventLogData) => {
    let events: Array<
        | (EventLogCasefile & { type: 'casefile' })
        | (EventLogSignerEvent & { type: 'signer'; name: string })
    > = [];

    if (logs.caseFile) {
        /**
         * The current logs return several duplicated/irrelevant events
         * so we will clean some of them up.
         * TODO: check all duplicates/unnecesary logs. Perhaps introduce
         * a time range to check as well, since some events are exactly the same
         * but only happen a few seconds apart
         */
        const filteredCasefileLogs: EventLogCasefile[] = [];

        // Simple method to check for event duplicate
        const sameTimeAndEvent = (log: EventLogCasefile) =>
            !!filteredCasefileLogs.find(
                (newLog) =>
                    newLog.eventTime === log.eventTime &&
                    newLog.eventType === log.eventType
            );

        logs.caseFile.forEach((log) => {
            // Deleted logs
            if (log.eventType === 3) {
                /**
                 * Remove deleted casefile log if already there's
                 * one at the exact same time.
                 * Note that we also check for a user id, to make sure
                 * that if the casefile was automatically deleted,
                 * it will ignore the notification that assigns a
                 * user to that action.
                 */

                if (log.user?.id && sameTimeAndEvent(log)) {
                    return;
                }
            }

            // "Modified" when restored
            if (log.eventType === 1) {
                /**
                 * If the casefile was deleted
                 * and now it's being restored,
                 * we ignore the "Modified" log as it is just
                 * signaling that we changed the updatedAt date
                 */

                if (
                    logs.caseFile?.find(
                        (oldLog) =>
                            oldLog.eventTime === log.eventTime &&
                            oldLog.eventType === 6
                    )
                ) {
                    return;
                }
            }

            // catch all for other repeated events
            if (sameTimeAndEvent(log)) {
                return;
            }

            filteredCasefileLogs.push(log);
        });

        const casefileLogs = filteredCasefileLogs.map(
            (log): EventLogCasefile & { type: 'casefile' } => ({
                ...log,
                type: 'casefile',
            })
        );

        // Add casefile events
        events = events.concat(casefileLogs);
    }

    if (logs.signers) {
        // Format signer events
        logs.signers.forEach((signer) => {
            const signerLogs = signer.events.map((log): EventLogSignerEvent & {
                type: 'signer';
                name: string;
            } => ({
                ...log,
                type: 'signer',
                name: signer.name,
            }));

            events = events.concat(signerLogs);
        });
    }

    return events
        .filter(
            (e) =>
                !(e.type === 'signer' && e.eventType > 0 && e.eventType !== 5)
        )
        .sort((a, b) => a.eventTime - b.eventTime)
        .reverse();
};

const getEventData = (event) => {
    const UNKNOWN_TYPE = 99;

    if (event.type === 'casefile') {
        return CASEFILE_LOGS[event.eventType] || CASEFILE_LOGS[UNKNOWN_TYPE];
    }

    return EMAIL_LOGS[event.eventType] || EMAIL_LOGS[UNKNOWN_TYPE];
};

const CasefileEventLogEntry = ({ event, casefileId }) => {
    const data = getEventData(event);

    return (
        <div className={classnames('casefile-eventlog-entry', data.color)}>
            <div className="casefile-eventlog-entry-timestamp">
                {moment.unix(event.eventTime).format('DD-MM-YYYY HH:mm')}
            </div>

            <div className="casefile-eventlog-entry-icon">
                <i className={data.icon} />
            </div>

            <div className="casefile-eventlog-entry-details">
                <div className="casefile-eventlog-entry-time-relative">
                    {moment.unix(event.eventTime).fromNow()}
                </div>

                <div className="casefile-eventlog-entry-description">
                    {data.text(event, casefileId)}
                </div>
            </div>
        </div>
    );
};

const CasefileEventLog = ({ events, casefileId }: Props) => {
    if (events.isFetching) {
        return null;
    }

    const logs = normalizeEvents(events.data);

    return (
        <div>
            <h4 className="casefile-details-section-header">
                {i18n`Event Log`}
            </h4>

            <div className="casefile-eventlog-container">
                <div className="casefile-eventlog-headers">
                    <div className="column-1 row">
                        {i18n`Date`}
                        &nbsp;
                        <i className="far fa-calendar-day"></i>
                    </div>
                    <div className="row">{i18n`Event`}</div>
                </div>
                <div className="casefile-eventlog">
                    {logs.map((log: any, key: number) => (
                        <CasefileEventLogEntry
                            event={log}
                            key={key}
                            casefileId={casefileId}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CasefileEventLog;
