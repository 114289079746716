/* eslint max-len:0*/

import React from 'react';
import { Route, Redirect, IndexRedirect } from 'react-router';

import AuthActions from './actions/AuthActionCreators';

// Password Components
import PasswordResetForm from './components/Password/PasswordResetForm';
import PasswordActivation from './components/UserActivation/PasswordActivation';

// OAuth Callback Containers
import OpenIDCallbackContainer from 'OpenID/components/OpenIDCallback';
import CustomerIntegrationsCallbackContainer from './components/CustomerIntegrations/CallbackContainer';

// BankID NO Components
import BankIDNOAddContainer from './components/BankID_NO/AddContainer';
import BankIDNOActivationContainer from './components/BankID_NO/ActivationContainer';
import BankIDNOLoginContainer from './components/BankID_NO/LoginContainer';
import BankIDNOValidateContainer from './components/BankID_NO/ValidateContainer';

// Admin Views
import CredentialsManagement from './components/CredentialsManagement/CredentialsManagement';
import ManageUserDetails from './components/CredentialsManagement/ManageUserDetails';
import ManageIntegrationCredentials from './components/CredentialsManagement/ManageIntegrationCredentials';
import ManageUserCredentials from './components/CredentialsManagement/ManageUserCredentials';
import AuthMethodsList from './components/CredentialsManagement/AuthMethodsList';
import AuthMethodsOptions from './components/CredentialsManagement/AuthMethodsOptions';

// Integration
import IntegrationActivation from './components/UserActivation/IntegrationActivation';

// Wrapper Pages
import LoginPage from './components/LoginPage';

// Admin Pages
import UserList from './components/Admin/UserList';
import UserCreation from './components/Admin/UserCreation';
import CustomerList from './components/Admin/CustomerList';
import CustomerManagement from './components/Admin/CustomerManagement';

import TeamManagement from '../Teams/components/TeamManagement';
import TeamEditor from '../Teams/components/TeamEditor';

// User Activation
import ActivateUser from './components/UserActivation/ActivateUser';

// Customer Management
import CustomerContainer from './components/Customer/CustomerContainer';
import CustomerDetails from './components/Customer/CustomerDetails';
import CustomerBranding from 'Auth/components/Customer/CustomerBranding';
import CustomerIntegrationsModal from 'Auth/components/CustomerIntegrations/CustomerIntegrationsModal';
import CustomerEmailSignatures from './components/Customer/CustomerEmailSignatures';
import CustomerStats from './components/Customer/CustomerStats';
import { EmailTemplateList } from 'EmailTemplates';

import Auth from './components/Pages/Auth';
import UserProfile from './components/UserProfile/UserProfile';
import UserPreferences from './components/UserProfile/UserPreferences';
import TeamList from '../Teams/components/TeamList';

import PresharedRedirector from './components/Preshared/PresharedRedirector';

import { storage } from 'Core';

import { CUSTOMER_TABS } from './Constants';
import CustomerFeaturesModal from './components/CustomerFeatures/CustomerFeaturesModal';
import { sanitizeUrl } from 'utils';

// Itsme QES
import ItsmeServiceCallback from '../ItsmeQES/components/Callback/ItsmeServiceCallback';
import ItsmeCredentialCallback from '../ItsmeQES/components/Callback/ItsmeCredentialCallback';
import ReclaimNemIDAccountPage from '../OpenID/components/ReclaimNemIDAccountPage';

let createLink = (prefix = '') => (name, params) => ({
    name: prefix + name,
    params: params,
});

let userManagementRoutes = (context = '') => (
    <Route>
        <IndexRedirect to="details" />
        <Route
            name={context + 'user-details'}
            path="details"
            component={ManageUserDetails}
        />
        <Route
            name={context + 'user-auth-methods'}
            path="credentials/reset"
            component={ManageUserCredentials}
        />
    </Route>
);

let getUserRoutes = () => (
    <Route component={Auth}>
        {/* Customers Management */}
        <Route name="manage" path="manage">
            <IndexRedirect to="customers" />
            <Route
                name="admin-manage-customers"
                path="customers"
                components={CustomerList}
            />

            <Route name="admin-manage-customer" path="customer/:customerId">
                <IndexRedirect to="details" />
                <Route
                    name="admin-create-user"
                    path="create/user"
                    component={UserCreation}
                />

                <Route
                    component={CustomerManagement}
                    tabs={CUSTOMER_TABS.other}>
                    <Route
                        name="admin-customer-settings"
                        path="details"
                        component={CustomerContainer}>
                        <Route path="general" component={CustomerDetails} />
                        <Route
                            path="features"
                            components={{ modal: CustomerFeaturesModal }}
                        />
                    </Route>
                    <Route
                        name="admin-company-account"
                        path="account"
                        components={CustomerStats}
                    />
                    <Route
                        name="admin-customer-branding"
                        path="branding"
                        component={CustomerBranding}
                    />
                    <Route
                        name="admin-customer-users"
                        path="users"
                        createLink={createLink('admin-')}
                        component={UserList}
                    />
                </Route>

                {/* User Management */}
                <Route
                    name="admin-manage-user"
                    path="user/:userId"
                    createLink={createLink('admin-')}
                    layout="embed"
                    components={CredentialsManagement}>
                    {userManagementRoutes('admin-')}
                </Route>
            </Route>
        </Route>

        <Route name="create-user" path="create/user" component={UserCreation} />

        <Route name="settings" path="settings">
            <IndexRedirect to="profile" />

            <Route path="customer/:customerId">
                {/* User List / User Creation */}
                <Route
                    name="company-create-user"
                    path="users/create"
                    component={UserCreation}
                />

                {/* User Management */}
                <Route
                    name="company-manage-user"
                    path="user/:userId"
                    createLink={createLink('company-')}
                    components={CredentialsManagement}>
                    {userManagementRoutes('company-')}
                </Route>

                {/* Teams Management */}
                <Route name="teams" path="teams">
                    <IndexRedirect to="list" />
                    <Route
                        name="company-manage-teams"
                        path="list"
                        createLink={createLink('company-')}
                        components={TeamManagement}
                    />
                    <Route
                        name="company-create-team"
                        path="create"
                        createLink={createLink('company-')}
                        component={TeamEditor}
                    />
                    <Route
                        name="company-team-details"
                        path="team/:teamId/details"
                        createLink={createLink('company-')}
                        component={TeamEditor}
                    />
                </Route>
            </Route>

            {/* Company Settings */}
            <Route
                name="company"
                path="company"
                component={CustomerManagement}
                tabs={CUSTOMER_TABS.own}>
                <IndexRedirect to="details" />
                <Route
                    name="company-settings"
                    path="details"
                    component={CustomerContainer}>
                    <Route
                        path="general"
                        name="company-general"
                        component={CustomerDetails}
                    />
                    <Route
                        path="integrations"
                        name="company-integrations"
                        components={{ modal: CustomerIntegrationsModal }}
                    />
                    <Route
                        path="features"
                        components={{ modal: CustomerFeaturesModal }}
                    />
                </Route>
                <Route
                    name="company-email-templates"
                    path="email-templates"
                    forCustomer={true}
                    components={EmailTemplateList}
                />
                <Route
                    name="company-email-signatures"
                    path="email-signatures"
                    components={CustomerEmailSignatures}
                />
                <Route
                    name="company-manage-users"
                    path="users"
                    createLink={createLink('company-')}
                    components={UserList}
                />
                <Route
                    path="branding"
                    name="company-branding"
                    component={CustomerBranding}
                />
                <Route
                    name="company-account"
                    path="account"
                    components={CustomerStats}
                />
            </Route>

            {/* User Profile */}
            <Route name="profile" path="profile">
                <IndexRedirect to="user" />
                <Route path="user" component={UserProfile}>
                    <IndexRedirect to="details" />

                    {/* Redirections to support legacy reset requests */}
                    <Redirect from="all" to="credentials" />
                    <Redirect from="classic" to="credentials" />
                    <Redirect from="bankid_no" to="credentials" />

                    <Route
                        name="account-user-details"
                        path="details"
                        component={ManageUserDetails}
                    />

                    {/* Authentication Methods List route */}
                    <Route
                        name="account-auth-methods"
                        path="credentials"
                        component={AuthMethodsList}>
                        <Route path="google" />
                        <Route path="microsoft" />

                        {/* Routes for managing Norwegian BankID integrations */}
                        <Route
                            path="bankid_no"
                            name="bankid-no-credential-list">
                            <IndexRedirect to="add" />

                            <Route
                                name="account-user-add-bankid-no"
                                path="add"
                                component={BankIDNOAddContainer}
                            />
                        </Route>

                        <Route
                            name="account-auth-methods-providers"
                            path="providers"
                            component={AuthMethodsOptions}
                        />

                        <Route
                            name="account-user-classic"
                            path="classic"
                            component={PasswordResetForm}
                        />
                    </Route>

                    <Route
                        name="account-user-teams"
                        path="teams"
                        component={TeamList}
                    />
                    <Route
                        name="account-user-integration"
                        path="integration"
                        component={ManageIntegrationCredentials}
                    />

                    <Route
                        name="account-preferences"
                        path="preferences"
                        component={UserPreferences}
                    />
                </Route>
            </Route>
        </Route>

        {/* Integrations Callback */}
        <Route
            path="integrations/silverfin/callback"
            component={CustomerIntegrationsCallbackContainer}
        />
    </Route>
);

let getPresharedRoutes = () => (
    <Route component={Auth}>
        <Route path="invite/:token" name="invite" component={ActivateUser}>
            <Route
                name="activate-classic-credentials"
                path="classic"
                component={PasswordActivation}
            />
            <Route
                name="activate-integration"
                path="integration"
                component={IntegrationActivation}
            />

            <Route
                name="activate-bankid-no"
                path="bankid_no"
                component={BankIDNOActivationContainer}
            />
        </Route>
    </Route>
);

function handleLoginRoute(nextState, replace, callback) {
    // End any current session.
    AuthActions.endCurrentSession();

    let { location } = nextState;

    // React router uri decodes the url by default which conflicts with the
    // Silverfin integration when logging in. We need to use the raw redirect
    // query param.
    if (location.query.redirect && location.search) {
        const redirect = location.search.match(/redirect=([^&]*)/)[1];

        const sanitizedRedirectUrl = sanitizeUrl(redirect);

        AuthActions.setLoginSuccessUrl(
            decodeURIComponent(sanitizedRedirectUrl)
        );

        return callback();
    }

    // Remove remember method localStorage entries. The login with password
    // method is now located in the main login page.
    if (nextState.location.pathname === '/login/password') {
        storage.clear('penneo-remember-method');
        storage.clear('penneo-remember-method-active');
    }

    // Only do this for /login, not child routes
    if (nextState.location.pathname === '/login') {
        let method = storage.get('penneo-remember-method');
        let active = storage.get('penneo-remember-method-active');

        if (method && active && active !== '/login/password') {
            replace(method);
        }
    }

    // @todo: ensure the store was cleared.
    setTimeout(callback, 500);
}

let getPublicRoutes = () => (
    <Route component={Auth}>
        {/* IF logout route is missing in signing-service, this will redirect to /login page */}
        <Redirect from="logout" to="login" />

        {/* Authentication Routes */}
        <Route
            name="login-route"
            path="login"
            component={LoginPage}
            onEnter={handleLoginRoute}>
            <Redirect from="password" to="/login" />
            <Route
                path="bankid_no"
                name="bankid-no-login"
                component={BankIDNOLoginContainer}
            />
            <Route
                path="bankid-no-validate-id"
                name="bankid-no-validate-id"
                component={BankIDNOValidateContainer}
            />
        </Route>

        <Route
            path="openid/:serviceId/callback"
            component={OpenIDCallbackContainer}
        />

        <Route
            path="sign/csc/qes.itsme.be/service-callback"
            component={ItsmeServiceCallback}
        />

        <Route
            path="sign/csc/qes.itsme.be/credential-callback"
            component={ItsmeCredentialCallback}
        />

        <Route path="go/:token/:redirect" component={PresharedRedirector} />

        <Route
            path="reclaim-nemid-account"
            component={ReclaimNemIDAccountPage}
        />
    </Route>
);

const AuthRoutes = { getUserRoutes, getPublicRoutes, getPresharedRoutes };

export default AuthRoutes;
