import React, { useCallback, useState } from 'react';
import { CaseFileEntity } from 'types/CaseFile';
import CasefileStore from 'Casefiles/stores/CasefileStore';
import { SidePanel } from 'Common/components/SidePanel';
import { BetterFlowPage } from 'Labs/BetterFlowOverview/views/BetterFlowPage';

type Props = {
    casefile: CaseFileEntity;
};

const CasefileRoundsFlowPreview = ({ casefile }: Props) => {
    const casefileType = CasefileStore.getCaseTypeById(casefile.caseFileTypeId);
    const [showFlowDetails, setShowFlowDetails] = useState<boolean>(false);

    const handleShowFlowDetails = useCallback(
        () => setShowFlowDetails(!showFlowDetails),
        [showFlowDetails]
    );

    return casefileType.name ? (
        <>
            <div className="casefile-flow-previewer">
                <div className="inline-block">
                    <span
                        className="underline font-bold text-secondary-600 cursor-pointer"
                        onClick={handleShowFlowDetails}>
                        {casefileType.name}
                    </span>

                    <i
                        className="far fa-arrow-right fa-lg text-secondary-600 cursor-pointer ml-4"
                        onClick={handleShowFlowDetails}
                    />
                </div>
            </div>

            {showFlowDetails && (
                <SidePanel onClose={handleShowFlowDetails}>
                    <BetterFlowPage
                        casefileType={casefileType}
                        onClose={handleShowFlowDetails}
                    />
                </SidePanel>
            )}
        </>
    ) : null;
};

export default CasefileRoundsFlowPreview;
