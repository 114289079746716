import PropTypes from 'prop-types';
import React from 'react';
import AuthStore from '../../stores/AuthStore';
import AuthActions from '../../actions/AuthActionCreators';
import PresharedActions from '../../actions/PresharedActionCreators';
import { debug } from 'Core';
import { jwtDecode } from 'jwt-decode';

export default class AuthenticatedComponent extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object,
        params: PropTypes.object,
    };

    state = {
        isAuthenticated: AuthStore.isAuthenticated(),
        error: AuthStore.getAuthError(),
        user: AuthStore.getUser() || {},
    };

    componentWillMount() {
        let { params } = this.props;
        let { location } = this.props;
        let { isAuthenticated } = this.state;

        let cachedToken = AuthStore.getCachedToken();
        let refreshToken = AuthStore.getRefreshToken();

        if (location.query && location.query.token) {
            this.requestPresharedAuth(location.query.token);

            return;
        }

        // Preshared Token Authentication
        // Re-request authentication even if the app is already authenticated.
        if (params.token) {
            PresharedActions.authenticate(params.token);

            return;
        }

        // If the application is authenticated, skip all other authentication methods.
        if (isAuthenticated) {
            return;
        }

        let isExpired = false;

        if (cachedToken) {
            try {
                const { exp } = jwtDecode(cachedToken);

                if (exp) {
                    const expiryDate = new Date(exp * 1000);
                    const currentTime = new Date();

                    // Check if the token expired within 35 seconds (in miliseconds)
                    const expiresSoon =
                        expiryDate.valueOf() - currentTime.valueOf() <= 35000;

                    if (expiresSoon) {
                        isExpired = true;
                    }
                }
            } catch (e) {
                debug.log('Error in JWT parsing', e);
                cachedToken = null;
            }
        }

        // Reauthenticate if a cached token is stored.
        if (cachedToken && !isExpired) {
            AuthActions.reauthenticate({ token: cachedToken });

            return;
        }

        if (refreshToken) {
            AuthActions.refresh(refreshToken);

            return;
        }

        let error = {
            code: 'AUTHENTICATION_FAILURE',
        };

        // If there is no method of authentication
        AuthActions.handleAuthenticationError(error, error.code);
    }

    componentDidMount() {
        // Attach Store Listeners
        AuthStore.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        // Remove Store Listeners
        AuthStore.removeChangeListener(this.onChange);
    }

    onChange = () => {
        this.setState({
            isAuthenticated: AuthStore.isAuthenticated(),
            error: AuthStore.getAuthError(),
            user: AuthStore.getUser(),
        });
    };

    render() {
        return false;
    }
}
