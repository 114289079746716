import { createComponent } from '@lit/react';
import { Overlay } from '@penneo/vega-ui';
import React from 'react';

const UIModalOverlay = createComponent({
    tagName: 'vega-modal-overlay',
    elementClass: Overlay,
    react: React,
});

export default UIModalOverlay;
