const ComponentId = {
    LOGIN_BANKID_NO: 'bsgvxdv8q7cd',
    LOGIN_BANKID_SE: '34t5wj09zfxs',
    LOGIN_CLASSIC: '58kg9cyj85b7',
    LOGIN_GOOGLE: '8x0kpflzq3hp',
    LOGIN_MICROSOFT: 'dl0rcqz6n8c6',
    // Signing Components (group id: wyq7jz4gb2l3)
    SIGN_BANKID_NO: 'sn0sbqtwtpzt',
    SIGN_BANKID_SE: 'qkqk5fj22l7l',
    SIGN_TOUCH: 'mtnyt9fs6fnq',
    // SIGN_GOOGLE: 'xxx',
    // SIGN_MICROSOFT: 'xxx',
    WEB_ARCHIVE: 'djfnjchpngfh',
    WEB_FORMS: 'bmgkpcgcjkbc',
    API_SERVICES: 'mffhmdnjcnpb', // signing service API
    DOCUMENT_SIGNATURE_PROCESSING: 'mffhmdnjcnpb', // signing service API
    EMAIL_DELIVERY: 'pfl1844vhh21',
    // Third party services
    // THIRD_PARTY_NETS_BANKID_NO: 'xxx',
    // THIRD_PARTY_NETS_BANKID_SE: 'xxx',
    // THIRD_PARTY_AWS: 'xxx',
    // THIRD_PARTY_GLOBALSIGN: 'xxx'
};

export default ComponentId;

// -- Structure in statuspage.io --

// Web Application
//     - Archive Portal
//     - Forms Portal

// Login Components
//     - BankID - Sweden
//     - BankID - Norway
//     - Classic Credentials
//     - Google
//     - Microsoft

// Signing Components
//     - BankID - Sweden
//     - BankID - Norway
//     - Touch Signatures

// Document Processing

// API Services

// Email Delivery

// Third Party Services
//     - BankID - Nets
//     - Amazon Web Services
//     - Timestamp Service
