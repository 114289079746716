import React from 'react';
import { translate } from 'Language';

export default class FormRejectedPage extends React.Component {
    render() {
        return (
            <div className="form-rejected-page error-page-widget">
                <h1 className="title">
                    {translate('You have rejected to sign')}
                </h1>
                <br />
                <div className="error-header-image">
                    <i className="fa fa-exclamation-circle"></i>
                </div>

                <p>
                    {translate(
                        'You have declined to fill out and sign this document'
                    )}
                </p>
                <p>
                    Please,&nbsp;
                    <a
                        href="https://penneo.force.com/support"
                        target="_blank"
                        rel="noopener noreferrer">
                        {translate('Contact Support')}
                    </a>
                    &nbsp;{translate('if this is a mistake')}
                </p>
                <br />
            </div>
        );
    }
}
