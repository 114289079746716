import React, { useEffect, useState } from 'react';
import { LanguageDropdown } from 'Language';
import UserDropdown from './UserDropdown';
import Constants, { env } from 'Constants';
import StatusNotification from 'Common/components/Common/StatusNotification';
import DashboardMenuItem from './DashboardMenuItem';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import LogoHeader from 'resources/images/logo-header.png';
import { Link } from 'react-router';
import { V2_STATUS } from 'Settings/redux/types';
import { UserEntity as User } from 'types/User';
import { Incident } from 'Common/components/StatusPageMessage/StatusPageMessage';
import {
    fetchSummary,
    fetchIncidentIntervalDuration,
} from 'Common/components/StatusPageMessage/status-page-api';
import { i18n } from 'Language';
import launchDarkly from 'Common/LaunchDarkly';

type Props = {
    user: User;
    branding: any;
    disableUserOptions?: boolean;
    disableLink?: boolean;
};

const DashboardHeader: React.FunctionComponent<Props> = (props) => {
    const { user, branding, disableUserOptions, disableLink } = props;
    const logo = branding.imageUrl || LogoHeader;
    let fetchInterval: any;

    const canUserSeeUIv2 = LaunchDarkly.variationIncludes(Flags.UI_V2, [
        V2_STATUS.DEMO,
        V2_STATUS.PEEK,
        V2_STATUS.ENABLED,
    ]);
    const isDesktopApp = env.platform === 'desktop';
    const [incidents, setIncidents] = useState<Incident[]>([]);

    useEffect(() => {
        fetchIncidents();
        fetchInterval = setInterval(
            fetchIncidents,
            fetchIncidentIntervalDuration
        );

        return () => {
            clearInterval(fetchInterval);
        };
    }, []);

    const fetchIncidents = async () => {
        const incidentData = await fetchSummary();

        setIncidents(incidentData);
    };

    // @todo: Dashboard link is only available to web application.
    const dashboardV2 = canUserSeeUIv2 && !isDesktopApp;
    const signerArchiveEnabled =
        !user.customerIds?.length &&
        launchDarkly.variation(Flags.ENABLE_SIGNERS_ARCHIVE);

    return (
        <nav
            aria-label={i18n`Dashboard header`}
            className="penneo-dashboard-header">
            <div
                className="dashboard-header-sidebar penneo-header-branding"
                style={{ background: branding.backgroundColor }}>
                <div className="logo">
                    {dashboardV2 || signerArchiveEnabled ? (
                        <Link
                            to={
                                signerArchiveEnabled
                                    ? 'personal-archive'
                                    : 'dashboard'
                            }
                            style={{ backgroundImage: `url(${logo})` }}
                            aria-label={
                                signerArchiveEnabled ? 'Archive' : `Dashboard`
                            }
                        />
                    ) : (
                        <a
                            href={
                                disableLink
                                    ? undefined
                                    : Constants.PENNEO_ORIGIN + '/archive'
                            }
                            style={{ backgroundImage: `url(${logo})` }}></a>
                    )}
                </div>
            </div>
            <div className="dashboard-header-content">
                <div className="dashboard-menu">
                    <StatusNotification
                        offset={-30}
                        incidents={incidents ?? []}
                    />
                    {isDesktopApp && (
                        <DashboardMenuItem
                            controls={'language-menu'}
                            name={i18n`Language menu`}
                            branding={branding}>
                            <LanguageDropdown persist />
                        </DashboardMenuItem>
                    )}
                    <DashboardMenuItem
                        controls={'user-menu'}
                        name={i18n`User menu`}
                        branding={branding}>
                        <UserDropdown
                            user={user}
                            items={
                                disableUserOptions ? [] : Constants.userOptions
                            }
                        />
                    </DashboardMenuItem>
                </div>
            </div>
        </nav>
    );
};

export default DashboardHeader;
