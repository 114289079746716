/* eslint camelcase: 0 */
import { OIDs } from 'OpenID/Constants';
import {
    OpenIDObj,
    OpenIDCertificate,
    OpenIDToken,
    OpenIDSubjectIdentifiers,
} from 'OpenID/redux/types';
import { EIDENT_AMRS, makeEIdentCertProcessor } from './eident';

export type BankIDNOToken = OpenIDToken & {
    iss: string;
    amr: 'no_bankid';
    given_name: string;
    ssn: string;
    no_ssn: string;
    family_name: string;
    name: string;
};

export type BankIDNOSubjectIdentifiers = OpenIDSubjectIdentifiers & {
    [OIDs.OPENID_FULL_NAME_V2]: string;
};

export type BankIDNOCertificate = OpenIDCertificate<BankIDNOSubjectIdentifiers>;

export const bankidNo: OpenIDObj<
    BankIDNOToken,
    BankIDNOCertificate
> = makeEIdentCertProcessor(EIDENT_AMRS.BANKID_NO);
