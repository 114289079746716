import type {
    OpenIDObj,
    OpenIDToken,
    OpenIDCertificate,
} from 'OpenID/redux/types';

import { google } from 'OpenID/services/google';
import { ftn } from 'OpenID/services/ftn';
import { itsme } from 'OpenID/services/itsme';
import { mitID } from 'OpenID/services/mitid';
import { mitIDErhverv } from 'OpenID/services/mitidErhverv';
import { bankidSe } from 'OpenID/services/bankidse';
import { bankidNo } from 'OpenID/services/bankidno';
import { NetsIdVerifier } from './netsIdVerifier';
import * as EidBelgiumBe from 'OpenID/services/eidBelgiumBe';

/** This is a *prioritized* list of Open ID service providers.
 * Earlier entries take precedence. */
export const services: OpenIDObj<OpenIDToken, OpenIDCertificate>[] = [
    google,
    bankidSe,
    bankidNo,
    ftn,
    itsme,
    mitID,
    mitIDErhverv,
    NetsIdVerifier,
    EidBelgiumBe.service,
];
