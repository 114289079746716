import Base64 from 'crypto-js/enc-base64';
import CryptoJS from 'crypto-js';

/**
 * RFC 4648 §4 base64 encode.
 */
export function base64Encode(input: string): string {
    return Base64.stringify(CryptoJS.enc.Utf8.parse(input));
}

/**
 * RFC 4648 §4 base64 decode.
 */
export function base64Decode(input: string): string {
    return Base64.parse(input).toString(CryptoJS.enc.Utf8);
}
