//React
import React, { useEffect, useState } from 'react';

//Components
import LoadingData from 'Common/components/Loaders/LoadingData';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { init } from 'OpenID/redux/actions';

import { ReduxState } from 'Store';
import EutlErrorHandler from './EutlErrorHandler';
//Constants
import { SigningMethod } from 'EID';
import { ServiceIDs, Intent } from 'OpenID/Constants';

type EutlOpenIdAuthProps = {
    serviceId: ServiceIDs;
    method: SigningMethod;
};

const EutlOpenIdAuth = ({ serviceId, method }: EutlOpenIdAuthProps) => {
    const dispatch = useDispatch();
    const { sign, error, intent } = useSelector(
        (state: ReduxState) => state?.openId
    );
    const [errorMessage, setErrorMessage] = useState(error);
    const authenticate = () => {
        try {
            ((dispatch(
                init(serviceId, Intent.SIGN, {
                    legacyServiceName: serviceId,
                })
            ) as unknown) as Promise<void>).catch((e) => {
                setErrorMessage(e);
                throw e;
            });
        } catch (e) {
            setErrorMessage(e);
        }
    };

    useEffect(() => {
        if (!method) return;

        if (intent.isFetching || intent.isLoaded) return;

        if (sign.isFetching || sign.isLoaded) return;

        if (error) {
            setErrorMessage(error);

            return;
        }

        authenticate();
    }, [sign, authenticate, intent, error, method]);

    //TODO: Maybe an error message here could be nice?
    if (!method || sign?.isLoaded) return null;

    const renderContent = () => {
        const { isLoaded } = sign;

        if (!isLoaded) {
            return (
                <>
                    <br />
                    <br />
                    <LoadingData />
                </>
            );
        }

        if ((isLoaded && error) || error) {
            throw error;
        }

        return null;
    };

    return (
        <EutlErrorHandler error={errorMessage}>
            <>{renderContent()}</>
        </EutlErrorHandler>
    );
};

export default EutlOpenIdAuth;
