import React from 'react';
import { i18n } from 'Language';
import { OpenIDClientError } from 'OpenID/Errors';
import Message from 'Common/components/Message';
import moment from 'moment';
import './openid-error-details.scss';

type Props = {
    error: OpenIDClientError;
};

export default class OpenIDErrorDetails extends React.Component<Props> {
    render() {
        const { error } = this.props;
        const errorContainsTraceId = error.requestId || error.traceId;

        return (
            <div className="openid-error-details">
                <Message type="error">
                    <div className="match-parent">
                        <div className="openid-error-details-message">
                            {i18n(error.message)}
                        </div>

                        {error.details && (
                            <div className="openid-error-details-explanation">
                                {i18n(error.details)}
                            </div>
                        )}

                        <div className="openid-error-details-footer">
                            {error.code && (
                                <div className="openid-error-details-code">
                                    <span>
                                        {error.code}
                                        {error.status && (
                                            <span className="openid-error-details-status">
                                                {error.status}
                                            </span>
                                        )}
                                    </span>
                                </div>
                            )}
                            <span className="openid-error-details-timestamp">
                                {moment().format('DD/MM')}
                                <span className="openid-error-details-timestamp-time">
                                    {moment().format('hh:mm')}
                                </span>
                            </span>
                        </div>
                    </div>
                </Message>
                <div>
                    {errorContainsTraceId && (
                        <div className="openid-error-details-traceid">
                            {error.requestId}
                            {error.traceId && `:${error.traceId}`}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
