import { Dispatcher, BaseStore } from 'Core';
import Constants, { CUSTOM_EMAIL_TEMPLATE_ID } from '../Constants';
import assign from 'object-assign';
import { translationsPreservedSpaces } from 'Language';
import lodash from 'lodash';

// Casefile Store
import CasefileStore from './CasefileStore';

let _messageTemplates = [];
let _selectedTemplateId = 0;
let _cachedSelectedId = null;

let _messageTemplate = null;

function updateMessageTemplates(templates) {
    let casefileLanguage = CasefileStore.getCasefile().language;

    _messageTemplates = [getDefaultMessage(casefileLanguage)].concat(templates);

    if (_cachedSelectedId) {
        updateSelectedTemplateId(_cachedSelectedId);
    }
}

function updateMessageTemplate(template) {
    const casefileLanguage = CasefileStore.getCasefile().language;
    const baseTemplate =
        _messageTemplate || getDefaultMessage(casefileLanguage);
    const customTemplateProperties = {
        id: CUSTOM_EMAIL_TEMPLATE_ID,
        title: null,
        customerId: null,
        userId: null,
    };

    _messageTemplate = assign(
        {},
        baseTemplate,
        template,
        customTemplateProperties
    );
}

function updateSelectedTemplateId(templateId) {
    // Clear message template when templateId value is 0.
    if (templateId === 0) {
        _messageTemplate = null;

        return false;
    }

    if (_messageTemplates.length === 0) {
        _cachedSelectedId = templateId;

        return false; // Skip selecting template.
    }

    // If ID is not a number, match by title.
    let match = !isNaN(templateId) ? 'id' : 'title';

    if (match === 'id') {
        templateId = Number(templateId);
    }

    // Match existing folder
    let template = lodash.find(_messageTemplates, [match, templateId]);

    // Default template if match is not found
    _selectedTemplateId = template ? template.id : 0;

    // Get message by id
    _messageTemplate = getMessageTemplateById(_selectedTemplateId);
}

function getMessageTemplateById(templateId) {
    for (let i = 0; i < _messageTemplates.length; i++) {
        if (_messageTemplates[i].id === templateId) {
            return _messageTemplates[i];
        }
    }
}

function getDefaultMessage(casefileLanguage) {
    translationsPreservedSpaces.changeLanguage(casefileLanguage);

    const { i18n } = translationsPreservedSpaces;

    return {
        id: 0,
        title: i18n`Standard template`,
        subject: i18n`Please sign {{casefile.name}}`,
        message: i18n`Hi {{recipient.name}},

Please take a moment to review and sign {{casefile.name}}.
Once the document is signed by everyone, you'll be notified via email.`,
    };
}

function clearStore() {
    _messageTemplates = [];
    _messageTemplate = null;
}

/* Flux Store */
const MessageTemplateStore = assign({}, BaseStore, {
    getMessageTemplate(casefileLanguage) {
        if (!_messageTemplate) {
            return getDefaultMessage(casefileLanguage);
        }

        return _messageTemplate;
    },

    getSelectedTemplateId() {
        return _selectedTemplateId;
    },

    getMessageTemplates() {
        return _messageTemplates;
    },

    isDefaultTemplate(message, casefileLanguage) {
        let defaultMessage = getDefaultMessage(casefileLanguage);

        let result = {};

        for (let key in message) {
            if (message[key] === defaultMessage[key]) {
                result[key] = true;
                continue;
            }

            result[key] = false;
        }

        return result;
    },

    clearStore() {
        _messageTemplates = [];
        _messageTemplate = null;

        MessageTemplateStore.emitChange();
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.FETCH_MESSAGE_TEMPLATES_SUCCESS:
                if (action.messageTemplates) {
                    updateMessageTemplates(action.messageTemplates);
                }

                break;
            case Constants.ActionTypes.MESSAGE_TEMPLATE_SELECTED:
                updateSelectedTemplateId(action.messageTemplateId);
                break;
            case Constants.ActionTypes.MESSAGE_TEMPLATE_UPDATED:
                if (action.messageTemplate) {
                    updateMessageTemplate(action.messageTemplate);
                }

                break;
            case Constants.ActionTypes.MESSAGE_TEMPLATE_STORE_CLEARED:
                clearStore();
                break;
            case Constants.ActionTypes.MESSAGE_TEMPLATE_URI_UPDATED:
                if (action.templateId) {
                    updateSelectedTemplateId(action.templateId);
                    break;
                }

                updateMessageTemplate(action.messageTemplate);
                break;
            default:
                return true;
        }
        // If action was responded to, emit change event
        MessageTemplateStore.emitChange(action.type);

        return true;
    }),
});

export default MessageTemplateStore;
