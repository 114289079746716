import { Dispatcher, BaseStore } from 'Core';
import assign from 'object-assign';
import { TouchActions as Actions } from '../ActionTypes';
import amplitude from 'Common/Amplitude';
import analytics from 'Common/Analytics';

let _default = {
    _fetching: false,
    signText: null,
    base64image: null,
    method: 'draw', // default method
    error: null,
    allowedInsecureSigningMethods: ['draw', 'image', 'text'],
};

// Analytics event properties per widget type.
const analyticsProps = {
    draw: {
        method: 'Global - Draw Signature',
    },
    image: {
        method: 'Global - Image Signature',
    },
    text: {
        method: 'Global - Text Signature',
    },
};

// Initialize Store Data
let _store = {};

resetState(); // sets initial values

function updateState(state) {
    _store = assign(_store, state, { _fetching: false });
}

function resetState() {
    _store = assign(_store, _default);
}

function updateSignText(signText) {
    updateState({ signText: signText });
}

function updateAllowedInsecureSigningMethods(methods) {
    updateState({ allowedInsecureSigningMethods: methods });
}

function updateSignatureImage(base64image) {
    updateState({ base64image: base64image });
}

function updateError(error) {
    updateState({ error: error });
}

const Store = assign({}, BaseStore, {
    getSignText() {
        return _store.signText;
    },

    getAllowedSigningMethods() {
        return _store.allowedInsecureSigningMethods;
    },

    getSignatureImage() {
        return _store.base64image;
    },

    getError() {
        return _store.error;
    },

    isFetching() {
        return _store._fetching;
    },

    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Actions.PARAMS_SUCCESS:
                updateSignText(action.signText);
                updateAllowedInsecureSigningMethods(
                    action.allowedInsecureSigningMethods
                );
                break;
            case Actions.SIGN_SUCCESS:
                analytics.track('sign success', {
                    withNAP: action.withNap === true,
                    ...analyticsProps[_store.method],
                });
                amplitude.incrementUserProperty('casefiles signed');

                _store._fetching = false;
                Store.emit(Actions.SIGN_SUCCESS);
                break;

            // Request Actions
            case Actions.PARAMS_REQUEST:
            case Actions.COLLECT_REQUEST:
            case Actions.LOGIN_REQUEST:
            case Actions.SIGN_REQUEST:
            case Actions.ADD_REQUEST:
            case Actions.FETCH_REQUEST:
            case Actions.RESET_REQUEST:
                _store._fetching = true;
                break;

            // Failure Actions
            case Actions.PARAMS_FAILURE:
            case Actions.COLLECT_FAILURE:
            case Actions.LOGIN_FAILURE:
            case Actions.ADD_FAILURE:
            case Actions.FETCH_FAILURE:
            case Actions.DELETE_FAILURE:
            case Actions.RESET_FAILURE:
                updateError(action.error);
                break;

            case Actions.SIGN_FAILURE:
                analytics.track('sign error', analyticsProps[_store.method]);
                updateError(action.error);
                break;

            // View Actions
            case Actions.WIDGET_LOADED:
                _store.method = action.method;
                analytics.track(
                    'sign widget loaded',
                    analyticsProps[_store.method]
                );
                break;

            case Actions.RETRY:
                analytics.track('sign retry', analyticsProps[_store.method]);
                resetState();
                break;
            case Actions.CLEAR_STORE:
                resetState();
                break;
            case Actions.SIGNATURE_IMAGE_UPDATED:
                updateSignatureImage(action.base64image);
                break;
            case Actions.SWITCH_SIGNING_METHOD:
                _store.method = action.index;
                break;
            default:
                return false;
        }

        Store.emitChange(action.type);
    }),
});

export default Store;
