import { createComponent } from '@lit/react';
import { LoadingSpinner } from '@penneo/vega-ui';
import React from 'react';

const UILoadingSpinner = createComponent({
    tagName: 'vega-loading-spinner',
    elementClass: LoadingSpinner,
    react: React,
});

export default UILoadingSpinner;
