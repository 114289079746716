import PropTypes from 'prop-types';
import React from 'react';
import FormStore from '../../stores/FormStore';
import Constants from '../../Constants';
import { DragSource } from 'react-dnd';

let _startX = null;
let _startWidth = null;

let fieldSource = {
    // return variables to DocumentDrop.jsx as drag begins
    beginDrag(props) {
        let { mapping } = props;
        let data = FormStore.getPreviewMapping(mapping);

        return {
            top: data.y,
            left: data.x,
            id: mapping.id,
            width: data.width,
            height: data.height,
        };
    },
};

// Makes this component draggable with React DnD
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

// A new function reference is created after .bind() is called. So we need a variable
// to store the bound function that is used in document.addEventListener('mouseup').
// to be able to remove it after execution.
// @see: http://stackoverflow.com/a/22870717/781779
let boundEventFunction;

export default DragSource(
    Constants.ItemTypes.FIELD,
    fieldSource,
    collect
)(
    class extends React.Component {
        static propTypes = {
            index: PropTypes.number,
            field: PropTypes.object,
            mapping: PropTypes.object,
            initialPosition: PropTypes.object,
            connectDragSource: PropTypes.func.isRequired,
            hideSourceOnDrag: PropTypes.bool.isRequired,
            isDragging: PropTypes.bool.isRequired,
            save: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
            moveEventStart: PropTypes.func.isRequired,
            resizeMapping: PropTypes.func.isRequired,
            showOrder: PropTypes.bool,
        };

        state = {
            showControls: false,
            resizing: false,
        };

        mouseDownHandler = (event) => {
            let { mapping } = this.props;
            let { moveEventStart } = this.props;

            let element = document.getElementById('mapping-id-' + mapping.id);
            let rect = element.getBoundingClientRect();

            _startWidth = rect.width;
            _startX = event.clientX;

            moveEventStart(mapping, event, _startX, _startWidth);
        };

        removeFieldHandler = (event) => {
            event.preventDefault();
            event.stopPropagation();
            let { mapping } = this.props;

            this.props.remove(mapping.id);
        };

        showControls = () => {
            this.setState({ showControls: true });
        };

        hideControls = () => {
            this.setState({ showControls: false });
        };

        attachResizeHandler = (event) => {
            // A new function reference is created after .bind() is called. So there is a component
            // variable to store the bound function that is used in
            // document.addEventListener('mouseup'). to be able to remove it after execution.
            // @see: http://stackoverflow.com/a/22870717/781779

            let { target } = event;

            boundEventFunction = this.resizeFieldHandler.bind(this, target);

            this.setState({
                resizing: true,
            });

            document.addEventListener('mouseup', boundEventFunction);
        };

        fixResizeDimensions = (event) => {
            if (!this.state.resizing) {
                return false;
            }

            let { mapping } = this.props;

            event.target.style.width = '20px';
            let minHeight =
                FormStore.convertPointsToPreviewPixels(10, mapping.page) +
                2 +
                'px';

            event.target.style.height = minHeight;

            this.setState({
                resizing: false,
            });
        };

        resizeFieldHandler = (eventTarget) => {
            // console.log(eventTarget); // get size from item where the event initiated
            let { mapping, resizeMapping } = this.props;

            // New Dimensions
            let bounds = eventTarget.getBoundingClientRect();

            let dimensions = {
                width: bounds.width - 4,
                height: bounds.height - 4,
            };

            resizeMapping(mapping, dimensions);

            // Event firing removes its own handler
            this.removeResizeHandler();
        };

        removeResizeHandler = () => {
            document.removeEventListener('mouseup', boundEventFunction);
            boundEventFunction = null;

            this.setState({
                resizing: false,
            });
        };

        /**
         * Renders field on page based on fieldMap state elements.
         * @param data {object} fieldMap object containing field mapping and field type data
         *
         * @return {DOM} Returns the JSX for the Input to be rendered by React's render() function
         */
        renderField = () => {
            let { field, mapping, index, connectDragSource } = this.props;
            let data = FormStore.getPreviewMapping(mapping);

            // Base Style for rendered element.
            const style = {
                width: data.width + 4 + 'px',
                height: data.height + 4 + 'px',
                fontSize:
                    FormStore.convertPointsToPreviewPixels(10, mapping.page) +
                    'px',
                minHeight:
                    FormStore.convertPointsToPreviewPixels(10, mapping.page) +
                    2 +
                    'px',
            };

            const divStyle = {
                position: 'absolute',
                top: data.y,
                left: data.x,
                transform: 'translate3d(0,0,0)',
            };

            let fieldJSX = (
                <div
                    className="input-editable"
                    style={divStyle}
                    id={'fieldmap-' + field.id}>
                    {connectDragSource(
                        <div
                            className="bounding-box"
                            style={{
                                position: 'absolute',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: '#0080BF',
                                top: '2px',
                                left: '2px',
                                right: '2px',
                                bottom: '4px',
                            }}></div>
                    )}
                    {this.props.showOrder && (
                        <div
                            className="order"
                            style={{
                                fontSize:
                                    FormStore.convertPointsToPreviewPixels(
                                        10,
                                        mapping.page
                                    ) + 'px',
                            }}>
                            {this.props.index + 1}
                        </div>
                    )}
                    <textarea
                        key={index}
                        id={'mapping-id-' + mapping.id}
                        style={style}
                        // onMouseUp={this.removeResizeHandler}
                        onMouseMove={this.fixResizeDimensions}
                        onMouseDown={this.attachResizeHandler}
                        className="editor-field"
                        data-fd={field.id}
                        data-map-id={mapping.id}
                        placeholder={field.label}
                        readOnly></textarea>

                    <div
                        className="resize-handle"
                        onMouseDown={this.removeFieldHandler}>
                        <i className="fa fa-times"></i>
                    </div>
                </div>
            );

            return fieldJSX;
        };

        render() {
            let { field, isDragging, hideSourceOnDrag } = this.props;

            if (!field) {
                return false;
            }

            if (isDragging && hideSourceOnDrag) {
                return null;
            }

            return this.renderField();
        }
    }
);
