import React from 'react';
import { AppDispatch } from 'Store';
import { connect } from 'react-redux';
import { TextInput } from 'Common/components';
import { i18n } from 'Language';
import { CaseFileEntity } from 'types/CaseFile';
import Button from 'Common/components/Button';

type Props = {
    dispatch: AppDispatch;
    inputLabel: string;
    closeModal: () => any;
    parentId?: number;
    item?: CaseFileEntity;
    saveChange: (any) => void;
};

type State = {
    title: string;
};

export class TitleModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { item } = this.props;

        this.state = {
            title: item ? item.title : '',
        };
    }

    handleOnChange = (title: string) => this.setState({ title });

    handleOnCreateUpdateItem = (event: React.FormEvent) => {
        event.preventDefault();

        const { title } = this.state;

        this.props.closeModal();

        if (this.props.item) {
            this.props.saveChange({ title });
        }
    };

    handleOnCancel = (event: React.FormEvent) => this.props.closeModal();

    render() {
        return (
            <div className="case-files-item-modal">
                <form onSubmit={this.handleOnCreateUpdateItem}>
                    <TextInput
                        label={this.props.inputLabel}
                        value={this.state.title}
                        onChange={this.handleOnChange}
                        autoFocus
                    />
                    <div className="text-right">
                        <Button
                            type="button"
                            className="pull-left"
                            onClick={this.handleOnCancel}>
                            {i18n('Cancel')}
                        </Button>
                        <Button
                            theme="blue"
                            type="submit"
                            onClick={this.handleOnCreateUpdateItem}>
                            {i18n('Update')}
                        </Button>
                    </div>
                </form>
            </div>
        );
    }
}

export default connect()(TitleModal);
