import React from 'react';

export default class Menu extends React.Component {
    render() {
        return (
            <ul>
                <li></li>
            </ul>
        );
    }
}
