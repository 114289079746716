import React, { useEffect, useRef, useState } from 'react';
import { UploadingDocument } from 'types/Document';
import { UploadedDocument } from 'types/CaseFile';
import { getDocumentIdentifier } from 'Common/utils/caseFile';
import { UploadStatus } from '../types';

type Props = {
    percentageUploaded: number | undefined;
    file: UploadingDocument | UploadedDocument;
    uploadStatus: UploadStatus | undefined;
};

const completedDocumentIds = new Set<string>();

export const ProgressBar = ({
    percentageUploaded,
    file,
    uploadStatus,
}: Props) => {
    const [isVisible, setIsVisible] = useState(false);
    const timer = useRef<number>();
    const documentId = getDocumentIdentifier(file);

    useEffect(() => {
        if (!documentId) {
            setIsVisible(false);

            return;
        }

        const shouldBeVisible =
            !completedDocumentIds.has(documentId) &&
            percentageUploaded !== undefined &&
            uploadStatus !== undefined;

        setIsVisible(shouldBeVisible);

        if (
            percentageUploaded === 100 &&
            uploadStatus === 'completed' &&
            !completedDocumentIds.has(documentId)
        ) {
            completedDocumentIds.add(documentId);
            timer.current = window.setTimeout(() => {
                setIsVisible(false);
            }, 3000);
        }

        return () => {
            if (timer.current !== undefined) {
                window.clearTimeout(timer.current);
            }
        };
    }, [percentageUploaded, uploadStatus, documentId]);

    if (!isVisible) return null;

    return (
        <div className="flex w-full items-center mt-2 pl-5 relative">
            <div className="w-[calc(100%-50px)]">
                <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                        className="bg-blue-500 h-2 rounded-full"
                        style={{
                            width: `${percentageUploaded}%`,
                        }}
                    />
                </div>
            </div>
            <div className="ml-2 px-2 absolute right-0">
                <span>{percentageUploaded}%</span>
            </div>
        </div>
    );
};

export default ProgressBar;
