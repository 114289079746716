import React from 'react';
import Button from 'Common/components/Button';
import { i18n } from 'Language';
import { modal } from 'Common/components/Common/Modal';
import { connect } from 'react-redux';
import { AppDispatch } from 'Store';
import { Contact } from 'types/Contact';
import { deleteContact, fetchContacts } from 'Contacts/redux/actions';
import { notify } from 'react-notify-toast';
import './contact-delete-modal.scss';

type Props = {
    dispatch: AppDispatch;
    contact: Contact;
};
export default class ContactDeleteModal extends React.Component<Props> {
    handleDelete = async () => {
        const { contact } = this.props;

        const { dispatch } = this.props;
        const response = await dispatch(deleteContact(contact.id));

        if (response.isApiError) {
            notify.show(response.data.message, 'error', 5000);
        } else {
            dispatch(fetchContacts());
            notify.show(i18n`${contact.name} was deleted`, 'success');
            modal.hide();
        }
    };

    render() {
        const { contact } = this.props;

        return (
            <div className="contact-add-modal text-left mr ml">
                <h3>{i18n('Are you sure you want to delete this contact?')}</h3>
                <div className="text-left box-gray">
                    <span>{contact.name}&nbsp;-&nbsp;</span>
                    <span className="text-blue">
                        <i className="far fa-envelope" /> {contact.email}
                    </span>
                    <br />
                    {contact.onBehalfOf && (
                        <span>
                            <i className="far fa-building" />{' '}
                            {contact.onBehalfOf}
                        </span>
                    )}
                </div>
                <p className="text-warning mr">
                    <i className="far fa-exclamation-triangle" />
                    &nbsp;
                    {i18n`This action cannot be undone`}
                </p>

                <div className="mt text-right">
                    <Button
                        type="button"
                        variant="outline"
                        onClick={modal.hide}>
                        {i18n`Cancel`}
                    </Button>

                    <Button
                        className="ml"
                        type="submit"
                        theme="red"
                        onClick={this.handleDelete}>
                        {i18n`Yes, delete`}
                    </Button>
                </div>
            </div>
        );
    }
}

const ConnectedModal = connect()(ContactDeleteModal);

export const showContactDeleteModal = (contact: Contact) => {
    const config = {
        component: <ConnectedModal contact={contact} />,
    };

    modal.show(config);
};
