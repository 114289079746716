import cookie from 'react-cookie';

let storage = {
    set: (key, value) => {
        try {
            localStorage.setItem(key, value);
        } catch (e) {
            cookie.save(key, value, { path: '/', secure: true });
        }
    },

    get: (key) => {
        try {
            let value = localStorage.getItem(key) || cookie.load(key);

            return storage._fixValue(key, value);
        } catch (e) {
            let value = cookie.load(key);

            return storage._fixValue(key, value);
        }
    },

    clear: (key) => {
        try {
            localStorage.removeItem(key);
            cookie.remove(key, { path: '/' });
        } catch (e) {
            cookie.remove(key, { path: '/' });
        }
    },

    // @deprecated
    // This fix was implemented on Jul 3, 2017 and all users should by now, have their localStorage
    // entries converted.
    //
    // @note: local-storage npm module adds entries surrounded in quotes
    //
    // This is not the default localStorage behaviour and creates conflicts
    // when extracting data with this Storage module. Some parts of the
    // application have been updated to use this new Storage module and
    // those entries need to be fixed to remove the quotes around the
    // entries that are already saved in the local machine
    _fixValue: (key, value) => {
        if (typeof value === 'undefined') {
            return null; // Make undefined values null, so they can be safely JSON parsed.
        }

        if (!value) {
            return value;
        }

        switch (key) {
            case 'penneo-language':
            case 'penneo-debug':
            case 'penneo-remember-method':
            case 'penneo-remember-method-active':
            case 'penneo-banner-collapsed':
                // Remove quotes around values
                if (typeof value === 'string' && /^".+"$/.test(value)) {
                    let fixed = value.substring(1, value.length - 1);

                    // Store the new unquoted value
                    storage.set(key, fixed);

                    return fixed;
                }

                return value;
            default:
                return value;
        }
    },
};

export default storage;
