import PropTypes from 'prop-types';
import React from 'react';
import WorkflowStore from '../../stores/WorkflowStore';
import { StorageKeys } from 'Constants';
import { storage } from 'Core';

export default class RedirectionHandler extends React.Component {
    static propTypes = {
        id: PropTypes.number,
        key: PropTypes.string,
        params: PropTypes.object.isRequired,
    };

    componentDidMount() {
        let { id } = this.props.params;

        WorkflowStore.getURL(id)
            .then((url) => {
                this.redirect(url);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    redirect = (url) => {
        // Do Redirect.
        setTimeout(() => {
            if (storage.get(StorageKeys.DEBUG)) {
                return console.log(url);
            }

            document.location.replace(url);
        }, 2000);
    };

    render() {
        return (
            <div className="batch-process-complete content">
                <div className="success-text">
                    <h2 style={{ width: 300 }} className="success-heading">
                        {/* Loading... */}
                        Retrieving data
                    </h2>
                    <br />
                    <br />
                    <div className="loader-wrapper">
                        <div className="loader"></div>
                    </div>
                    {/* <h3>Please wait...</h3> */}
                    <h3>Please wait...</h3>
                </div>
            </div>
        );
    }
}
