import { env, Platforms, SIGN_CHALLENGE_KEY_REGEX } from 'Constants';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { debug, storage } from 'Core';
import { getEnvironment } from 'utils';

let enabled: boolean = false;

const config = {
    key: '399441f9c6bae3c0851cd4d99c24cea2',
    project: '4507447207329872',
};
const configureScope = (
    callback: Parameters<typeof Sentry.configureScope>[0]
) => {
    if (!enabled) {
        return false;
    }

    Sentry.configureScope(callback);
};

// @see: https://docs.sentry.io/clients/javascript/tips/
// Community compiled list of errors that should be whitelisted in Sentry to
// avoid polluting data with external errors.
const options = {
    release: process.env.APP_VERSION, // Replaced by webpack
    environment: getEnvironment(),
    blacklistUrls: [
        /api\.amplitude\.com/i,
        /app\.launchdarkly\.com/i,
        /statuspage.io/i,

        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
};

// To enable logging, set an "enable-sentry" = true entry in localStorage
// Sentry is disabled on debug mode because it overrides console.log and the sourcemaps stop
// reporting the path to files since all console output is routed through sentry.js
function install(overrideOptions: Sentry.BrowserOptions = {}) {
    if (
        (!Sentry || getEnvironment() === 'development') &&
        storage.get('enable-sentry') !== 'true'
    ) {
        debug.warn(
            'Sentry is disabled in development mode. Enable it by setting `enable-sentry` to `true` in localStorage'
        );

        return false;
    }

    try {
        Sentry.init({
            beforeSend: cleanEvent,
            beforeBreadcrumb: cleanBreadcrumb,
            dsn: `https://${config.key}@o4507447189766144.ingest.de.sentry.io/${config.project}`,
            ignoreErrors: [
                // @see https://shorturl.at/ayIOV
                'Object Not Found Matching Id',
            ],
            integrations: [
                new Integrations.BrowserTracing({
                    // The BrowserTracing extension uses "window.location.pathname" as name for the current transaction.
                    // As "window.location.pathname" may contain sensitive information, we need to obfuscate it.
                    // This replaces all challenge keys from path with "[challenge-key]"
                    beforeNavigate: (context) => {
                        const { pathname } = window.location;

                        return {
                            ...context,
                            name: obfuscateChallengeKey(pathname),
                        };
                    },
                }),
            ],
            // Set tracesSampleRate to 0.05 to capture only 5% of transactions for performance monitoring.
            // @see: https://docs.sentry.io/platforms/javascript/performance/#verify
            tracesSampleRate: 0.05,
            ...options,
            ...overrideOptions,
        });

        enabled = true;

        // Decide which platform the errors will be set to
        configureScope((scope) => {
            scope.setTag(
                'platform',
                // If the application is running in embedded into V1 (legacy), we set
                // the platform to "legacy-web" to be able to filter events incoming from V1 in sentry.io
                // The possible values at the time of this comment being added are `web` | `desktop` | `legacy-web`
                env.legacy ? `legacy-${Platforms.WEB}` : env.platform
            );
        });
    } catch (e) {
        console.warn('Sentry failed to initialize', e);
    }
}

type ExtraParameters = {
    [key: string]: any;
};

// Cleans preshared tokens from URLs. This prevents user authentication data from being
// posted to #sentry in slack or reaching sentry.io servers.
// These tokens are used to give authentication to forms and signing pages.
function cleanEvent(event: Sentry.Event) {
    const { request } = event;

    if (request?.url && SIGN_CHALLENGE_KEY_REGEX.test(request.url)) {
        request.url = obfuscateChallengeKey(request.url);
    }

    return event;
}

// Cleans challenge keys from urls in all breadcrumbs
function cleanBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
    const { data } = breadcrumb;

    if (data?.url && SIGN_CHALLENGE_KEY_REGEX.test(data.url)) {
        data.url = obfuscateChallengeKey(data.url);
    }

    return breadcrumb;
}

function obfuscateChallengeKey(textToObfuscate: string) {
    return textToObfuscate.replace(SIGN_CHALLENGE_KEY_REGEX, '[challenge-key]');
}

const track = (error, extra: ExtraParameters = {}) => {
    if (!enabled) {
        return false;
    }

    Sentry.withScope((scope) => {
        scope.setContext('additional data', { ...extra });
        Sentry.captureException(error);
    });
};

const sentry = {
    install: install,
    track: track,
    configureScope: configureScope,
};

// Export default Sentry object
export default Sentry;
export { sentry };
