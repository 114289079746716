import PropTypes from 'prop-types';
import React from 'react';
import PresharedActions from '../../actions/PresharedActionCreators';
import AuthStore from '../../stores/AuthStore';
import Constants, { StorageKeys } from 'Constants';
import { storage } from 'Core';
import Loader from 'Common/components/Common/Loader';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { V2_STATUS } from 'Settings/redux/types';

export default class PresharedRedirector extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    static propTypes = {
        id: PropTypes.number,
        params: PropTypes.object.isRequired,
    };

    componentDidMount() {
        AuthStore.addChangeListener(this.onAuthentication);

        const { token } = this.props.params;

        this.authenticate(token);
    }

    onAuthentication = () => {
        if (!AuthStore.isAuthenticated) {
            return false;
        }

        const { redirect } = this.props.params;

        const decodedURI = decodeURIComponent(redirect);
        const redirectURL = atob(decodedURI);

        if (
            LaunchDarkly.variationIncludes(Flags.UI_V2, [
                V2_STATUS.DEMO,
                V2_STATUS.ENABLED,
            ])
        ) {
            // V2 dashboard redirection
            return this.redirectV2(redirectURL);
        }

        const baseURL = Constants.PENNEO_ORIGIN; // redirect inside symfony.

        // Signing service redirection
        return this.redirect(baseURL + redirectURL);
    };

    /**
     * Handle redirections to reset credentials via the new UI if the
     * user has the right set of feature flags
     * */
    redirectV2 = (redirectURL) => {
        const { router } = this.context;

        switch (redirectURL) {
            case '/user/profile#/all':
                return router.replace({ name: 'account-auth-methods' });
            default:
                return router.replace({ name: 'account-auth-methods' });
        }
    };

    redirect = (redirectURL) => {
        if (storage.get(StorageKeys.DEBUG)) {
            return console.log(redirectURL);
        }

        document.location.replace(redirectURL);
    };

    authenticate = (token) => {
        PresharedActions.authenticate(token);
    };

    render() {
        return <Loader />;
    }
}
