import axios from 'axios';
import Constants from 'Constants';
import { Incident } from './StatusPageMessage';
import lodash from 'lodash';

//TODO: We should potentially use ms (npm lib)
export const fetchIncidentIntervalDuration = 600000;

export const fetchSummary = (
    excludedComponents = [
        'rl3dqkctn2bt', // "Web portal - Sandbox",
        'ydbfd32tkfb3', // "Forms service frontend - Sandbox",
        'z2bs9cwwx5nk', // "Signing service API - Sandbox",
        '9j86959m85bz', // "Forms service API - Sandbox",
        'zt72s82vg980', // "Workflow service API - Sandbox",
        'yvdyhtblq6mx', // "Penneo Sandbox",
        'ndpcgmpcmpbh', // "Penneo Website"
        '0p5p6wnv4w7z', // "Penneo KYC"
        'h0cllwj7ywtm', // "Reach Frontend"
        'n7l0bfljs8md', // "Backend API"
        '6ykt3cjwv788', // "Avo Frontend"
    ]
): Promise<Incident[]> =>
    axios
        .get(Constants.statusPageSummary)
        .then((res: any) => {
            const { incidents } = res.data;

            return (incidents as Incident[]).filter((incident: Incident) => {
                const affectedComponentIds = incident.components.map(
                    (c: { id: string }) => c.id
                );

                // Return incidents where the IDs requested don't match the affected component IDs
                return (
                    lodash.intersection(
                        excludedComponents,
                        affectedComponentIds
                    ).length === 0
                );
            });
        })
        .catch(() => []);
