import React from 'react';
import { i18n } from 'Language';
import Tooltip from 'Common/components/Tooltip';
import './status.scss';
import { Status as StatusType } from 'Casefiles/CasefileDetails/statusCodes';

type Props = {
    status: StatusType;
};

const Status: React.FunctionComponent<Props> = ({ status }: Props) => {
    return (
        <div className={`casefile-status ${status.color}`}>
            <Tooltip
                className="wrap no-animations"
                showArrow={true}
                text={<span>{i18n(status.details)}</span>}>
                <span className="casefile-status-name no-wrap">
                    {i18n(status.name)}&nbsp;
                    <i className={`far fa-${status.icon}`} />
                </span>
            </Tooltip>
        </div>
    );
};

export default Status;
