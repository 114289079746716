import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'Common/components/Tooltip';

export default class ToggleInput extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        label: PropTypes.string,
        name: PropTypes.string,
        inline: PropTypes.bool,
        required: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

        // {<option index>: <disabled message [string | boolean]>}
        disabledOptions: PropTypes.object,

        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.node,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            })
        ),
    };

    handleChange = (option, event) => {
        this.props.onChange(option.value, event);
    };

    isDisabled = (index) => {
        let { disabledOptions } = this.props;

        // If option is defined
        if (disabledOptions && typeof disabledOptions[index] !== 'undefined') {
            return true;
        }

        return false;
    };

    getDisabledText = (index) => {
        let disabled = this.isDisabled(index);

        if (!disabled) {
            return false;
        }

        let content = this.props.disabledOptions[index];

        // `true` value will be skipped as no message
        if (content === true) {
            return false;
        }

        return content;
    };

    render() {
        let { label, options, value, inline } = this.props;

        return (
            <div className={'input ' + (inline ? 'inline-block' : '')}>
                <label>{label}</label>

                <div className="toggle-input">
                    {options.map((option, index) => (
                        <div
                            key={option.value}
                            className={
                                'toggle-input-option' +
                                (value === option.value ? ' active' : '') +
                                (this.isDisabled(index) ? ' disabled' : '')
                            }
                            style={{ width: 100 / options.length + '%' }}
                            onClick={
                                this.isDisabled(index)
                                    ? null
                                    : this.handleChange.bind(null, option)
                            }>
                            {this.isDisabled(index) &&
                            this.getDisabledText(index) ? (
                                <Tooltip
                                    direction="up"
                                    showArrow={true}
                                    text={this.getDisabledText(index)}>
                                    <div className="toggle-input-option-content">
                                        {option.label}
                                    </div>
                                </Tooltip>
                            ) : (
                                <div className="toggle-input-option-content">
                                    {option.label}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
