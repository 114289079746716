import React from 'react';
import { i18n, translationsPreservedSpaces } from 'Language';
import lodash from 'lodash';
import classnames from 'classnames';
import analytics from 'Common/Analytics';
import {
    getCustomEmailTemplateStorageKey,
    getCustomDefaultTemplate,
} from 'Common/utils/customDefaultEmailTemplate';
import {
    EmailTemplates,
    EmailTemplate,
    TemplateType,
    DefaultEmailTemplate,
    EmailTemplateGroup,
    isDefaultEmailTemplate,
} from 'types/EmailTemplates';
import { Checkbox } from 'Common/components';
import storage from 'Core/Storage';
import {
    KYCMessageValidation,
    CasefileMessageValidation,
    RegisteredLetterMessageValidation,
} from 'types/DataValidation';
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import { UserSettingKeys } from 'Settings/redux/types';
import { updateUserSettings } from 'Settings/redux/actions';

export interface Tab {
    id: TemplateType;
    valid: boolean;
    label: string;
    tagline: string;
}

export type Props = {
    dispatch: AppDispatch;
    defaultEmailTemplates: any;
    tabs: Tab[];
    /**
     * An array of email templates that are available to be selected from the dropdown menu
     */
    availableTemplates: EmailTemplate[];
    /**
     * Templates that are currently selected or defined,
     * their subject and message is being displayed in the component
     */
    selectedTemplates: EmailTemplates;
    /**
     * Validation for the selected email templates
     */
    templatesValidation:
        | KYCMessageValidation
        | CasefileMessageValidation
        | RegisteredLetterMessageValidation;
    /**
     * function that is being called on every template update,
     * whether it is a custom change made to the template
     * or whether the template has been selected from the available templates
     */
    onTemplateChange: (type: TemplateType, payload: EmailTemplate) => void;
    /**
     * Defines whether the component should be collapsible
     */
    collapsible?: boolean;
    /**
     * What email templates group the component is being used for
     *
     * Used to customize where in local storage the custom default
     * templates will be stored and retrieved from
     */
    group: EmailTemplateGroup;
    /**
     * The current language, used for translating the default email templates
     */
    language: string;
    /**
     * Whether the editor should show the footer notice about the
     * user's signature being added automatically to the email templates
     */
    showSignatureNotice?: boolean;
};

type State = {
    activeTab: Tab;
    isCollapsed: boolean;
    isActiveTplCustomDefault: boolean;
    dirtyMessageTypes: TemplateType[];
};

export class PersonalizedMessageEditor extends React.Component<Props, State> {
    static defaultProps: Partial<Props> = {
        showSignatureNotice: true,
    };
    private translations: any;
    constructor(props: Props) {
        super(props);
        this.translations = translationsPreservedSpaces;
        this.state = {
            activeTab: this.props.tabs[0],
            isCollapsed: this.props.collapsible ? false : true,
            isActiveTplCustomDefault: false,
            dirtyMessageTypes: [],
        };
    }

    /**
     * Translates a default template in the language passed as prop
     */
    translateDefaultEmailTemplate = (
        template: DefaultEmailTemplate
    ): DefaultEmailTemplate => {
        const i18n = this.getTranslationFn();
        const originalTemplate = this.getOriginalDefaultTemplate(template);

        return {
            ...template,
            title: i18n(originalTemplate ? originalTemplate.title : ''),
            subject: i18n(originalTemplate ? originalTemplate.subject : ''),
            message: i18n(originalTemplate ? originalTemplate.message : ''),
        };
    };

    /**
     * Automatically selects a template for the given types (initial, reminder, etc)
     *
     * The template is either a default template (translated) or a custom
     * default template previously selected by the user
     */
    selectTemplates(types: string[]) {
        const {
            availableTemplates,
            group,
            language,
            onTemplateChange,
            defaultEmailTemplates,
        } = this.props;
        const hasNeededData = !!(availableTemplates.length && language);

        // This method can run only if the list of possible templates
        // and the current language are available to the component
        if (!hasNeededData) {
            return;
        }

        const defaultTemplates = availableTemplates
            .filter(isDefaultEmailTemplate)
            .filter((template) => types.includes(template.type));
        const translatedDefaultTemplates = defaultTemplates.map((template) =>
            this.translateDefaultEmailTemplate(template)
        );

        translatedDefaultTemplates.forEach((originalDefault) => {
            const customDefault = getCustomDefaultTemplate(
                availableTemplates,
                group,
                originalDefault.type,
                defaultEmailTemplates
            );

            const template = customDefault || {
                ...originalDefault,
                id: null,
                custom: false,
            };

            onTemplateChange(originalDefault.type, template);
        });
    }

    async componentDidMount() {
        const { language } = this.props;
        const { activeTab } = this.state;

        this.setState({
            isActiveTplCustomDefault: this.isTabTemplateCustomDefault(
                activeTab
            ),
        });

        if (language) {
            await this.translations.changeLanguage(language);
        }
    }

    async componentDidUpdate(prevProps: Props, prevState: State) {
        const hasLanguageChanged = this.props.language !== prevProps.language;
        const typesWithoutSelectedTemplates = this.getTypesWithoutASelectedTemplate();

        if (hasLanguageChanged && this.props.language) {
            await this.translations.changeLanguage(this.props.language);
        }

        if (typesWithoutSelectedTemplates.length) {
            this.selectTemplates(typesWithoutSelectedTemplates);
        } else if (
            this.doSelectedDefaultTemplatesNeedTranslation() ||
            hasLanguageChanged
        ) {
            this.translateSelectedDefaultTemplates();
        }

        if (this.hasCurrentlyActiveTemplateChanged(prevProps, prevState)) {
            const { activeTab } = this.state;

            this.setState({
                isActiveTplCustomDefault: this.isTabTemplateCustomDefault(
                    activeTab
                ),
            });
        }
    }

    /**
     * Returns the list of template types that currently do not have a
     * template assigned to them
     */
    getTypesWithoutASelectedTemplate(): string[] {
        const { selectedTemplates } = this.props;

        return Object.keys(selectedTemplates)
            .map((type) => {
                const template = selectedTemplates[type];

                const isCustom = template.custom === true;
                const isDefault =
                    template.id === null && template.default === true;
                const isUserCreated = template.id !== null;

                return isDefault || isUserCreated || isCustom ? null : type;
            })
            .filter(Boolean) as string[]; // compact
    }

    /**
     * Checks if the currently active template (= the template selected in
     * the currently visible tab) has changed
     */
    hasCurrentlyActiveTemplateChanged(
        prevProps: Props,
        prevState: State
    ): boolean {
        const { selectedTemplates: currentSelectedTemplates } = this.props;
        const { selectedTemplates: prevSelectedTemplates } = prevProps;
        const { activeTab: currentActiveTab } = this.state;
        const { activeTab: prevActiveTab } = prevState;

        const currentActiveTpl = currentSelectedTemplates[currentActiveTab.id];
        const prevActiveTpl = prevSelectedTemplates[prevActiveTab.id];

        return currentActiveTpl.id !== prevActiveTpl.id;
    }

    /**
     * Builds a custom translation function using the language passed as prop,
     * rather than the current language of the app
     */
    getTranslationFn(): Function {
        return this.translations.i18n as Function;
    }

    /**
     * Given a default template that might be translated in a different language,
     * it will find its original version in english among the `availableTemplates` list
     *
     * The original template in english is necessary to translate the template
     * from a non-english language to another non-english language
     */
    getOriginalDefaultTemplate(
        template: DefaultEmailTemplate
    ): DefaultEmailTemplate {
        const { availableTemplates } = this.props;
        const originalDefaultTemplates = availableTemplates.filter(
            isDefaultEmailTemplate
        );

        return originalDefaultTemplates.find(
            (tpl) => tpl.type === template.type
        ) as DefaultEmailTemplate;
    }

    /**
     * Among the templates currently selected, it returns the ones that are
     * also the default ones
     */
    getSelectedDefaultTemplates(): DefaultEmailTemplate[] {
        const { selectedTemplates } = this.props;

        return Object.keys(selectedTemplates)
            .map((type) => selectedTemplates[type])
            .filter(isDefaultEmailTemplate);
    }

    /**
     * Checks if any of the currently selected default template are not
     * translated in the current language
     */
    doSelectedDefaultTemplatesNeedTranslation(): boolean {
        const i18n = this.getTranslationFn();

        return this.getSelectedDefaultTemplates().some((template) => {
            const originalTemplate = this.getOriginalDefaultTemplate(template);

            return (
                i18n(originalTemplate?.title) !== template.title ||
                i18n(originalTemplate?.subject) !== template.subject ||
                i18n(originalTemplate?.message) !== template.message
            );
        });
    }

    /**
     * Translates all the currently selected default templates
     */
    translateSelectedDefaultTemplates() {
        const { onTemplateChange } = this.props;

        this.getSelectedDefaultTemplates()
            .map((template) => this.translateDefaultEmailTemplate(template))
            .forEach((template) => {
                onTemplateChange(template.type, template);
            });
    }

    /**
     * Sets the currently active tab, and checks if its currently selected
     * template is a custom default template for the current user
     *
     * @param {Tab} tab
     */
    setActiveTab = (tab) => {
        this.setState({
            activeTab: tab,
            isActiveTplCustomDefault: this.isTabTemplateCustomDefault(tab),
        });
    };

    onTemplateSelect = (event) => {
        const { availableTemplates } = this.props;
        const id = Number(event.target.value);
        const activeTemplate = lodash.find(availableTemplates, { id });

        analytics.track('email message editor - selected a template');

        let payload: EmailTemplate = {
            id,
            custom: false,
            ...activeTemplate,
        };

        // If the selected email template is a default one,
        // it needs to be translated with the current language
        payload = payload.default
            ? this.translateDefaultEmailTemplate(
                  payload as DefaultEmailTemplate
              )
            : payload;

        this.updateTemplate(payload);
    };

    onCustomTemplateUpdate = (event) => {
        const { name, value } = event.target;
        const { selectedTemplates } = this.props;
        const { activeTab, dirtyMessageTypes } = this.state;
        const activeMessage = selectedTemplates[activeTab.id];

        if (!dirtyMessageTypes.includes(activeTab.id)) {
            analytics.track(
                'email message editor - the subject or body of a selected message was edited',
                { messageType: activeTab.id }
            );

            this.setState({
                dirtyMessageTypes: [...dirtyMessageTypes, activeTab.id],
            });
        }

        // Merge event data with active template
        const payload: EmailTemplate = {
            ...activeMessage,
            id: null,
            title: null,
            custom: true,
            default: false,
            [name]: value,
        };

        this.updateTemplate(payload);
    };

    updateTemplate = (payload: EmailTemplate) => {
        const { activeTab } = this.state;
        const { onTemplateChange } = this.props;

        onTemplateChange(activeTab.id, payload);
    };

    isEmailTemplatesValid = () => {
        const { templatesValidation } = this.props;

        return Object.keys(templatesValidation).every((key) => {
            return templatesValidation[key].valid;
        });
    };

    /**
     * Toggles whether the currently active template is a custom default
     * template for the current user
     *
     * @param {boolean} isCustomDefault
     */
    toggleActiveTemplateAsCustomDefault = (isCustomDefault: boolean) => {
        const {
            dispatch,
            group,
            defaultEmailTemplates,
            selectedTemplates,
        } = this.props;
        const { activeTab } = this.state;
        const activeTemplate = selectedTemplates[activeTab.id];

        if (isCustomDefault) {
            analytics.track('email templates - remember as default', {
                templateId: activeTemplate.id,
                source: group,
                type: activeTab.id,
            });

            // This will overwrite any previously saved custom default
            // template for the currently active tab
            const updated = lodash.set(
                lodash.cloneDeep(defaultEmailTemplates),
                `${group}.${activeTab.id}`,
                activeTemplate.id
            );

            dispatch(
                updateUserSettings(
                    UserSettingKeys.defaultEmailTemplates,
                    updated
                )
            );
        } else {
            analytics.track('email templates - forget default', {
                templateId: activeTemplate.id,
                source: group,
                type: activeTab.id,
            });

            const updated = lodash.set(
                lodash.cloneDeep(defaultEmailTemplates),
                `${group}.${activeTab.id}`,
                false
            );

            dispatch(
                updateUserSettings(
                    UserSettingKeys.defaultEmailTemplates,
                    updated
                )
            );
        }

        this.setState({
            isActiveTplCustomDefault: isCustomDefault,
        });
    };

    /**
     * Checks if the selected template of the given tab is a custom default
     * template for the current user
     *
     * @param {Tab} tab
     * @return {boolean}
     */
    isTabTemplateCustomDefault = (tab: Tab): boolean => {
        const { selectedTemplates, group } = this.props;
        const storageKey = getCustomEmailTemplateStorageKey(group, tab.id);
        const activeTemplateId = selectedTemplates[tab.id].id;
        const legacyCustomDefaultTemplateId = parseInt(storage.get(storageKey));

        const defaultTemplateId = lodash.get(
            this.props.defaultEmailTemplates,
            `${group}.${tab.id}`,
            false
        );
        const customDefaultTemplateId =
            defaultTemplateId || legacyCustomDefaultTemplateId;

        return activeTemplateId === customDefaultTemplateId;
    };

    handleCollapse = () => {
        analytics.track('email message editor - collapse/uncollapse');

        if (this.props.collapsible) {
            this.setState({ isCollapsed: !this.state.isCollapsed });
        }
    };

    handleOnTabClick = (tab: Tab) => {
        this.setActiveTab(tab);

        analytics.track('email message editor - tab was selected', {
            pickedTab: tab.label,
        });
    };

    render() {
        const { activeTab, isCollapsed } = this.state;
        const {
            tabs,
            selectedTemplates,
            availableTemplates = [],
            collapsible,
            templatesValidation,
            showSignatureNotice,
        } = this.props;
        const activeMessage = selectedTemplates[activeTab.id];
        const isEmailTemplatesValid = this.isEmailTemplatesValid();

        // The option to set the selected template as a custom default is
        // is not available for the original default template and for
        // custom templates
        const canSetTemplateAsCustomDefault =
            !activeMessage.custom && !activeMessage.default;

        const collapsingBoxHeaderClasses = [
            'collapsing-box-header',
            'text-blue',
            'text-semibold',
        ];

        collapsible && collapsingBoxHeaderClasses.push('cursor-pointer');

        return (
            <div
                className={classnames('collapsing-box', {
                    collapsed: collapsible && !isCollapsed,
                })}>
                <div
                    className={classnames(...collapsingBoxHeaderClasses)}
                    onClick={this.handleCollapse}>
                    {!isEmailTemplatesValid && (
                        <span>
                            <i className="fas fa-exclamation-triangle text-warning" />
                            &nbsp;
                        </span>
                    )}

                    <span className="collapsing-box-header-title">
                        {i18n`Personalize messages for recipients`}
                        &nbsp;
                        <i className="far fa-envelope"></i>
                    </span>

                    {collapsible && (
                        <div className="pull-right">
                            {isCollapsed ? (
                                <span>
                                    {i18n`Collapse`}
                                    &nbsp;
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            ) : (
                                <span>
                                    {i18n`Expand`}
                                    &nbsp;
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                            )}
                        </div>
                    )}
                </div>

                <div className="collapsing-box-content">
                    <div
                        className={classnames('casefile-messages', {
                            invalid: !templatesValidation[activeTab.id].valid,
                        })}>
                        <div className="casefile-messages-tabs">
                            <ul>
                                {tabs.map((tab) => (
                                    <li
                                        key={tab.id}
                                        onClick={() =>
                                            this.handleOnTabClick(tab)
                                        }
                                        className={classnames({
                                            'cursor-pointer': tabs.length > 1,
                                            active: tab.id === activeTab.id,
                                            invalid: !tab.valid,
                                        })}>
                                        {i18n(tab.label)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>{i18n(activeTab.tagline)}</div>

                        <div>
                            <h4 className="casefile-messages-header">
                                {i18n`Edit message`}&nbsp;{i18n`or`}&nbsp;
                                <label className="underline-link">
                                    {activeMessage.id ? (
                                        <span>
                                            {i18n`change template`}&nbsp;(
                                            {activeMessage.title})
                                        </span>
                                    ) : (
                                        <span>
                                            {i18n`select from a template`}
                                        </span>
                                    )}
                                    <span>
                                        &nbsp;
                                        <i className="far fa-chevron-down"></i>
                                    </span>

                                    <select
                                        name="id"
                                        className="hidden cursor-pointer"
                                        onChange={this.onTemplateSelect}
                                        value={activeMessage.id || -1}>
                                        {availableTemplates
                                            .filter(
                                                (template) =>
                                                    template.type ===
                                                        activeTab.id ||
                                                    !template.type
                                            )
                                            .map((template, index) => (
                                                <option
                                                    key={index}
                                                    value={
                                                        template.id as number
                                                    }>
                                                    {template.default
                                                        ? i18n(template.title)
                                                        : template.title}
                                                </option>
                                            ))}
                                    </select>
                                </label>
                            </h4>

                            {canSetTemplateAsCustomDefault && (
                                <div className="custom-template-default mb">
                                    <Checkbox
                                        checked={
                                            this.state.isActiveTplCustomDefault
                                        }
                                        onChange={
                                            this
                                                .toggleActiveTemplateAsCustomDefault
                                        }
                                        label={i18n`Remember this selection for next time`}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="casefile-messages-subject">
                            <label>{i18n`Subject`}</label>
                            <input
                                type="text"
                                name="subject"
                                aria-label="subject"
                                onChange={this.onCustomTemplateUpdate}
                                value={activeMessage.subject || ''}
                            />
                        </div>

                        <div
                            className={classnames('casefile-messages-message', {
                                'with-footer-notice': showSignatureNotice,
                            })}>
                            <label>{i18n`Message`}</label>
                            <textarea
                                name="message"
                                aria-label="message"
                                rows={8}
                                className="match-parent"
                                onChange={this.onCustomTemplateUpdate}
                                value={activeMessage.message || ''}
                            />

                            {showSignatureNotice && (
                                <div className="casefile-message-signature">
                                    <i className="far fa-info-circle"></i>&nbsp;
                                    {i18n`Your signature will be added automatically`}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ReduxState) => ({
    defaultEmailTemplates: state.settings.data.user.defaultEmailTemplates,
}))(PersonalizedMessageEditor);
