import React from 'react';
import { Route, IndexRedirect, IndexRoute } from 'react-router';

/* Recycle Bin */
import TrashCan from './components/TrashCan/TrashCan';

import Casefiles from './components/Pages/Casefiles';
import CasefileCreator from './components/casefiles/CasefileCreator';
import CasefileSummary from './components/casefiles/CasefileSummary';
import CasefileContainer from './components/casefiles/CasefileContainer';
import CasefileSuccess from './components/casefiles/CasefileSuccess';

import CasefileDetails from './CasefileDetails';
import SignerDetails from './CasefileDetails/CasefileSignerDetails';

import CasefileDocumentDetails from './CasefileDetails/CasefileDocumentDetails';

// Casefile Creation Flow v2
import CasefileContainer2 from 'Casefiles/components/casefiles2/CasefileContainer';
import CasefileDetailStep from 'Casefiles/components/casefiles2/CasefileDetailStep';
import CasefileDocumentStep from 'Casefiles/components/casefiles2/CasefileDocumentStep';
import CasefileRecipientStep from 'Casefiles/components/casefiles2/CasefileRecipientStep';
import CasefileSummaryStep from 'Casefiles/components/casefiles2/CasefileSummaryStep';

import Archive from 'Casefiles/Archive';
import ArchiveFolderShare from 'Casefiles/Archive/Share';

// Redux actions
import { dispatch } from 'Store';
import { deleteBreadcrumbs } from 'Casefiles/Archive/redux/reducer';

// Reset breadcrumbs state when going away from routes where they are supported
const resetBreadcrumbState = () => {
    dispatch(deleteBreadcrumbs());
};

const clearFilters = () => {
    window?.sessionStorage?.removeItem('archive-search');
    window?.sessionStorage?.removeItem('archive-last-page');
};

const userRoutes = (
    <Route name="casefiles" path="casefiles" component={Casefiles}>
        <IndexRedirect to="create" />
        {/** Desktop case file wizard UI */}
        <Route component={CasefileContainer}>
            <Route
                name="casefile-details"
                path="create"
                component={CasefileCreator}
            />
            <Route
                name="casefile-summary"
                path="summary"
                component={CasefileSummary}
            />
        </Route>

        <Route
            name="casefile-success"
            path="success"
            component={CasefileSuccess}
        />

        <Route name="trash" path="deleted" component={TrashCan} />

        {/* Breadcrumbs are currently only available in archive routes */}
        <Route
            onLeave={() => {
                resetBreadcrumbState();
                clearFilters();
            }}>
            <Route name="archive" path="manage">
                <IndexRoute name="archive-all" component={Archive} />
                <Route
                    name="archive-folder"
                    path="folder/:folderId"
                    component={Archive}>
                    <Route
                        name="archive-folder-share"
                        path="share"
                        components={{ modal: ArchiveFolderShare }}
                    />
                </Route>
                <Route
                    name="archive-search"
                    path="search"
                    component={Archive}
                />
                <Route name="archive-virtual" path=":tab" component={Archive} />
            </Route>

            {/* Casefile details */}
            <Route
                name="archive-casefile-details"
                path="manage/:casefileId/details"
                component={CasefileDetails}>
                <Route
                    name="signer-details"
                    path="signer/:signerId"
                    components={{ modal: SignerDetails }}
                />
                <Route
                    name="document-details"
                    path="document/:documentId"
                    components={{ modal: CasefileDocumentDetails }}
                />
            </Route>
        </Route>

        {/** Web case file wizard UI */}
        <Route
            name="casefile-create"
            component={CasefileContainer2}
            path=":action(/:casefileId)/">
            <IndexRedirect to="details" />
            <Route
                name="casefile2-details"
                path="details"
                component={CasefileDetailStep}
            />
            <Route
                name="casefile-documents"
                path="documents"
                component={CasefileDocumentStep}
            />
            <Route
                name="casefile-recipients"
                path="recipients"
                component={CasefileRecipientStep}
            />
            <Route
                name="casefile-review"
                path="review"
                component={CasefileSummaryStep}
            />
        </Route>
    </Route>
);

const routing = { userRoutes };

export default routing;
