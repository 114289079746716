/* eslint camelcase: 0 */
import { Intent, OIDs } from 'OpenID/Constants';
import {
    OpenIDCertificate,
    OpenIDCertificateDisplayInfo,
    OpenIdLocalStorageState,
    OpenIDObj,
    OpenIDSubjectIdentifiers,
    OpenIDToken,
} from 'OpenID/redux/types';
import { parseCertificate } from '../utils/certificate';
import { getCertificateDisplayInfo } from '../utils/openIdCertificate';
import launchDarkly, { Flags } from '../../Common/LaunchDarkly';

export type MitIDToken = OpenIDToken & {
    iss: string;
    acr: string;
    nbf: number;
    amr: string[];
    loa: string;
    identity_type: string;
    idp: string;
    auth_time: string;
    transaction_id: string;
    'mitid.uuid': string;
    'mitid.age': string;
    'mitid.identity_name': string;
    'mitid.date_of_birth': string;
};

export type MitIDSubjectIdentifiers = OpenIDSubjectIdentifiers & {
    [OIDs.OPENID_FULL_NAME_V2]: string;
};

export type MitIDCertificate = OpenIDCertificate<MitIDSubjectIdentifiers>;

/**
 * We have introduced this method because we needed to do the Name and Protection check earlier
 * to fix a bug flagged by QA, which led to a small delay where the Signing modal was visible
 * before the user was redirected.
 */
export const isMitIDInfoProtected = (
    openIdState: OpenIdLocalStorageState,
    certificate: string
) => {
    const certificateData = parseCertificate(certificate);
    const displayInfo = getCertificateDisplayInfo(certificateData);

    return (
        launchDarkly.variation(Flags.ENABLE_NAP_INTERVENTION_SCREEN) &&
        openIdState.intent !== Intent.NAP &&
        displayInfo?.title === 'NAVNE & ADRESSEBESKYTTET'
    );
};

export const mitID: OpenIDObj<MitIDToken, MitIDCertificate> = {
    issuers: ['https://netseidbroker.dk/op', 'https://pp.netseidbroker.dk/op'],
    getSubject(token) {
        return {
            [OIDs.OPENID_ISSUER]: token.iss,
            [OIDs.OPENID_ID]: token['mitid.uuid'],
            [OIDs.OPENID_FULL_NAME_V2]: token['mitid.identity_name'],
        };
    },
    isOwnToken(token): token is MitIDToken {
        return (
            this.issuers.includes(token.iss) && 'mitid.identity_name' in token
        );
    },
    isOwnCertificate(certificate): certificate is MitIDCertificate {
        const { subject } = certificate;

        return (
            this.issuers.includes(subject[OIDs.OPENID_ISSUER]) &&
            !!subject[OIDs.OPENID_FULL_NAME_V2] &&
            !subject[OIDs.OPENID_ORG_IDENTIFIER_TYPE]
        );
    },
    getCertificateDisplayInfo(
        { subject, issuer },
        intent = Intent.SIGN
    ): OpenIDCertificateDisplayInfo {
        return {
            title: subject[OIDs.OPENID_FULL_NAME_V2],
            issuer: issuer[OIDs.OPENID_ORG_NAME_V2],
        };
    },
};
