import { PublicSigningAPI as SigningAPI } from 'Api';
import { defaultBranding } from 'Branding/Constants';

const brandingAPI = {
    getDefaultBranding() {
        return defaultBranding;
    },

    _fetchLogo(customerId, imageId) {
        return SigningAPI.get(`/customers/${customerId}/images/${imageId}`);
    },

    getBranding(customerId) {
        if (!customerId) {
            return Promise.resolve(defaultBranding);
        }

        return SigningAPI.get(`/customers/${customerId}/branding`)
            .then((branding) => {
                if (!branding.imageId) {
                    return Promise.resolve(branding);
                }

                return this._fetchLogo(customerId, branding.imageId).then(
                    (image = {}) => {
                        branding.imageUrl = image.url; // Add image URL to branding object

                        return Promise.resolve(branding);
                    }
                );
            })
            .then((branding) => {
                // Apply customer branding to default branding.
                return {
                    ...defaultBranding,
                    ...branding,
                    highlightColor: undefined,
                };
            })
            .catch(() => {
                return defaultBranding;
            });
    },
};

export default brandingAPI;
