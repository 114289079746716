import React from 'react';
import TransitionGroup from 'react-addons-css-transition-group';
import classnames from 'classnames';
import { env } from 'Constants';
import FocusTrap from 'focus-trap-react';
let showModal;
let hideModal;
let getVisibility;

function show(config) {
    showModal(config);
}

function hide() {
    hideModal();
}

function isVisible() {
    return getVisibility();
}

export let modal = {
    show: show,
    hide: hide,
    isVisible: isVisible,
};

export default class Modal extends React.Component {
    state = {
        visible: false,
        config: null,
    };

    keyboardEvent = false;

    componentDidMount() {
        showModal = this.show;
        hideModal = this.hide;
        getVisibility = this.getVisibility;
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.config && !nextState.config.preventClose) {
            return this.enableEscKey();
        }

        return this.disableEscKey();
    }

    enableEscKey = () => {
        if (this.keyboardEvent === true) {
            return false;
        }

        this.keyboardEvent = true;
        document.addEventListener('keyup', this.escKeyHandler);
    };

    disableEscKey = () => {
        this.keyboardEvent = false;
        document.removeEventListener('keyup', this.escKeyHandler);
    };

    escKeyHandler = (event) => {
        if (event.keyCode === 27) {
            this.hide();
        }
    };

    getVisibility = () => {
        return this.state.visible;
    };

    show = (config) => {
        this.setState({
            config: config,
            visible: true,
        });
    };

    hide = () => {
        let { config } = this.state;

        if (config && config.onClose) {
            config.onClose();
        }

        this.setState({
            visible: false,
            config: null,
        });
    };

    close = (event) => {
        event.preventDefault();
        this.hide();
    };

    transition = (transitionName, component) => {
        return (
            <TransitionGroup
                transitionName={transitionName}
                transitionAppear={true}
                transitionLeave={true}
                transitionEnter={true}
                transitionEnterTimeout={200}
                transitionAppearTimeout={200}
                transitionLeaveTimeout={200}>
                {component}
            </TransitionGroup>
        );
    };

    focusTrapOptions = () => {
        let { config } = this.state;

        /**
         * We must create a promise that will resolve when we
         * want to activate the focus trap. We can wait for a specific element
         * to be available in the DOM (so we know it's safe to proceed) or,
         * if no element is provided,
         * we default to a simple promise with a timeout.
         */
        const activationChecker = config.focusTrapWaitForElement
            ? new Promise((res) => {
                  /**
                   * We create a MutationObserver to listen to changes in the DOM
                   * and detect if our desired target element is present.
                   * Once we find the element, we stop listening and resolve the Promise.
                   *
                   * NOTE: document.querySelector use is very lax, to not make the search
                   * too resource intensive. This means that the selector must be very specific,
                   * as this search will stop the second it finds a match.
                   */
                  const observer = new MutationObserver(() => {
                      if (
                          document.querySelector(config.focusTrapWaitForElement)
                      ) {
                          observer.disconnect();
                          res();
                      }
                  });

                  /**
                   * Since the modal goes through animations and some DOM changes,
                   * we listen to the whole document instead and its subtree
                   */
                  observer.observe(document.body, {
                      subtree: true,
                      childList: true,
                  });
              })
            : new Promise((res) => {
                  return setTimeout(res, config?.focusTrapTimeout ?? 500); //make sure transitions are done!
              });

        return {
            active: !config.focusTrapDeactivate || (!config && true),
            focusTrapOptions: {
                checkCanFocusTrap: () => activationChecker,
                clickOutsideDeactivates: true,
            },
        };
    };

    renderModalContent = () => {
        let { config, visible } = this.state;

        if (!visible) {
            return false;
        }

        if (config.component) {
            return (
                <section className="react-modal-v2">
                    <FocusTrap {...this.focusTrapOptions()}>
                        <div className="react-modal-v2-container">
                            <div className="react-modal-v2-content">
                                {config.component}
                            </div>
                        </div>
                    </FocusTrap>
                </section>
            );
        }

        // If we are on desktop, we will append a class to add custom styles
        const isDesktopApp = env.platform === 'desktop';

        return (
            <FocusTrap {...this.focusTrapOptions()}>
                <section
                    className={classnames(
                        'react-modal',
                        isDesktopApp ? 'desktop' : ''
                    )}
                    onClick={this.close}>
                    <div
                        className={
                            'react-modal-content ' +
                            (config.className ? config.className : '')
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                        }}>
                        <div className="react-modal-header">
                            <div className="react-modal-header-title">
                                {config.title}
                            </div>
                        </div>

                        {/* Allow content to flow over modal header */}
                        <div
                            className="react-modal-body"
                            style={!config.title ? { marginTop: 0 } : {}}>
                            {config.body}
                        </div>

                        {config.buttons && (
                            <div className="react-modal-buttons">
                                <div>{config.buttons}</div>
                            </div>
                        )}
                    </div>
                </section>
            </FocusTrap>
        );
    };

    renderModalBackdrop = () => {
        let { visible } = this.state;

        if (!visible) {
            return false;
        }

        return (
            <div
                className="react-modal-backdrop"
                onClick={(e) => this.close(e)}></div>
        );
    };

    render() {
        let { renderModalContent, renderModalBackdrop } = this;

        return (
            <div>
                <TransitionGroup
                    transitionName={'fadeModalContent'}
                    transitionAppear={true}
                    transitionLeave={true}
                    transitionEnter={true}
                    transitionEnterTimeout={100}
                    transitionAppearTimeout={100}
                    transitionLeaveTimeout={100}>
                    {renderModalContent()}
                </TransitionGroup>

                <TransitionGroup
                    transitionName={'fadeModalBackdrop'}
                    transitionAppear={true}
                    transitionLeave={true}
                    transitionEnter={true}
                    transitionEnterTimeout={100}
                    transitionAppearTimeout={100}
                    transitionLeaveTimeout={300}>
                    {renderModalBackdrop()}
                </TransitionGroup>
            </div>
        );
    }
}
