import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';

type Props = {
    connectDragSource?: Function;
    connectDragPreview?: Function;
    connectDropTarget?: Function;
};

// @see: https://github.com/react-dnd/react-dnd/issues/442#issuecomment-215312398
class DummyDocumentRow extends React.Component<Props> {
    render() {
        return <li style={{ display: 'none' }}></li>;
    }
}

// Row has two behaviours: it is a source and a target, because
// it is able to be dragged and to be reordered.

// Source callbacks:
const source = {
    // returns an object with useful properties when dragging event is over
    beginDrag: () => false,
};

// Target callbacks:
const target = {
    hover: () => false,
};

// Connect the callbacks
let Target = DropTarget('DummyDocumentRow', target, (connect) => ({
    connectDropTarget: connect.dropTarget(),
}))(DummyDocumentRow);

Target = DragSource('DummyDocumentRow', source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
}))(Target);

export default Target;
