import { Dispatcher, BaseStore } from 'Core';
import Constants from '../Constants';
import assign from 'object-assign';
import { WorkflowAPI } from 'Api';

// Data Storage
let _workflowId = 0;
let _coSigner = {};
let _workflowPrototypes = [];
let _currentWorkflow = {};
let _currentWorkflowPrototype = {};
let _workflows = [];
let _attachments = [];
let _formattedData = [];

// Private Functions

/**
 * @todo: make a common scripts module
 * Sorts array by selected key. (Doesn't clone array)
 * @param arr {Array}  Array to be shifted
 * @param key {string} Key to do the sorting by
 */
function sortArrayByKey(arr, key) {
    arr.sort(function (a, b) {
        let keyA = a[key];
        let keyB = b[key];

        // Compare the 2 values
        if (keyA < keyB) {
            return -1;
        } else if (keyA > keyB) {
            return 1;
        }

        return 0;
    });
}

function updateFormattedData(data) {
    _formattedData = data;
}

function getStoreObjectIndex(id, array) {
    for (let i = array.length - 1; i >= 0; i--) {
        if (array[i].id === id) {
            return i;
        }
    }

    return -1;
}

/**
 * Updates the attached document object.
 * @param file {File} file object from file input's event target.
 *
 * Emits changes from store.
 */
function updateAttachments(attachments) {
    _attachments = _attachments.concat(attachments);
}

function removeAttachment(index) {
    _attachments.splice(index, 1);
}

function updateWorkflowPrototype(workflow) {
    let index = getStoreObjectIndex(workflow.id, _workflowPrototypes);

    if (index !== -1) {
        _workflowPrototypes[index] = assign({}, workflow);
    }
}

function setCurrentWorkflowPrototype(workflowPrototype) {
    _currentWorkflowPrototype = workflowPrototype;
}

/**
 * Resumes workflow and gets next URL
 * @param  {int} id workflow ID
 *
 * @return {Promise}
 */
function resumeWorkflow(id) {
    return WorkflowAPI.get('/v2/workflows/' + id + '/resume')
        .then((response) => {
            if (response) {
                return id;
            }
        })
        .then((workflowId) => {
            return WorkflowStore.getURL(workflowId);
        })
        .then((url) => {
            redirectTo(url);
        })
        .catch((error) => {
            WorkflowStore.emitResumeError(error);
        });
}

/**
 * Rejects workflow
 * @param  {int} id workflow ID
 *
 * @return {Promise}
 */
function rejectWorkflow(id) {
    return WorkflowAPI.patch('/v2/workflows/' + id + '/reject').then(
        (response) => {
            if (response) {
                WorkflowStore.emitRejected();
            }
        }
    );
}

/* Redirects to the specified URL
 * @param url {string} defines where the application will be redirected.
 */
function redirectTo(url) {
    document.location.replace(url);
}

function setCurrentWorkflow(workflow) {
    _currentWorkflow = workflow;
    _workflowId = workflow.id;
    WorkflowStore.emitChange();
}

function tagUpdated(workflowId) {
    WorkflowStore.emitTagUpdated(workflowId);
}

function instantiateWorkflow(workflow) {
    WorkflowStore.emitWorkflowInstantiated(workflow);
}

function instantiateFailed(error) {
    WorkflowStore.emitWorkflowInstantiateFailed(error);
}

const WorkflowStore = assign({}, BaseStore, {
    Events: {
        WORKFLOW_REJECTED: 'rejected',
        WORKFLOW_RESUME_ERROR: 'resume-error',
        WORKFLOW_TAG_UPDATED: 'tag-updated',
        WORKFLOW_INSTANTIATED: 'workflow-instantiated',
        WORKFLOW_INSTANTIATE_ERROR: 'instantiate-error',
        WORKFLOW_PROTOTYPE_NOT_FOUND: 'prototype-not-found',
    },

    getURL(id) {
        if (!id) {
            throw new Error(
                "Cannot get next URL, workflow ID wasn't specified"
            );
        }

        return WorkflowAPI.get('/v2/workflows/' + id + '/next').then(
            (response) => {
                return response.url;
            }
        );
    },

    /**
     * Returns Current workflow ID set in store.
     * @return {int} Current Workflow ID
     */
    getWorkflowId() {
        return _workflowId;
    },

    getAttachments() {
        return _attachments;
    },

    /**
     * Updates Co-signer object by assigning changes and emitting changes.
     * @param name  {string} Co-Signer's Name
     */
    setCoSignerName(name) {
        let data = {
            name: name,
        };

        assign(_coSigner, data);

        WorkflowStore.emitChange();
    },

    /**
     * Updates Co-signer object by assigning changes and emitting changes.
     * @param email {string} Co-Signer's Email
     */
    setCoSignerEmail(email) {
        let data = {
            email: email,
        };

        assign(_coSigner, data);

        WorkflowStore.emitChange();
    },

    /**
     * retrieves Co Signer's current data.
     * @return {object} Co Signer object: {email: "example@example.com", name: "example"}
     */
    getCoSigner() {
        return _coSigner;
    },

    loadWorkflowPrototypes(filter) {
        let queryParams = {
            prototype: true,
        };

        if (filter) {
            assign(queryParams, filter);
        }

        WorkflowAPI.get('/v2/workflows', queryParams).then((prototypes) => {
            if (prototypes && prototypes.length > 0) {
                sortArrayByKey(prototypes, 'id');
                _workflowPrototypes = prototypes;
                // Emit Change when form prototypes are loaded.
                WorkflowStore.emitChange();
            }
        });
    },

    /**
     * Returns specific field from field array based on ID
     * @param id {integer} ID that references each field in the field types collection.
     *
     * @return {object} Field from field types collection.
     */
    getWorkflowPrototypeById(id, workflows = this.getWorkflowPrototypes()) {
        let workflowId = parseInt(id, 10);

        for (let i = 0; i < workflows.length; i++) {
            if (workflowId === workflows[i].id) {
                return workflows[i];
            }
        }
    },

    getWorkflowPrototypes() {
        return _workflowPrototypes;
    },

    updateCurrentWorkflow(workflow) {
        _currentWorkflow = workflow;
    },

    getCurrentWorkflow() {
        return _currentWorkflow;
    },

    getCurrentWorkflowPrototype() {
        return _currentWorkflowPrototype;
    },

    getWorkflows() {
        return _workflows;
    },

    loadWorkflows() {
        return WorkflowAPI.get('/v2/workflows?prototype=false').then(
            (workflows) => {
                sortArrayByKey(workflows, 'id');
                _workflows = workflows;
                // Emit Change when form prototypes are loaded.
                WorkflowStore.emitChange();
            }
        );
    },

    emitRejected() {
        this.emit(WorkflowStore.Events.WORKFLOW_REJECTED);
    },

    emitTagUpdated(workflowId) {
        this.emit(WorkflowStore.Events.WORKFLOW_TAG_UPDATED, workflowId);
    },

    emitWorkflowInstantiated(workflow) {
        this.emit(WorkflowStore.Events.WORKFLOW_INSTANTIATED, workflow);
    },

    emitWorkflowInstantiateFailed(error) {
        this.emit(WorkflowStore.Events.WORKFLOW_INSTANTIATE_ERROR, error);
    },

    emitWorkflowPrototypeNotFound(error) {
        this.emit(WorkflowStore.Events.WORKFLOW_PROTOTYPE_NOT_FOUND, error);
    },

    emitResumeError(error) {
        console.log(error);
        try {
            this.emit(WorkflowStore.Events.WORKFLOW_RESUME_ERROR, error);
        } catch (e) {
            console.log(e);
        }
    },

    clearStore() {
        _workflowId = 0;
        _coSigner = {};
        _workflowPrototypes = [];
        _currentWorkflow = {};
        _currentWorkflowPrototype = {};
        _workflows = [];
        _attachments = [];
    },

    getFormattedData() {
        return _formattedData;
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.WORKFLOW_RESUMED:
                if (action.workflowId) {
                    resumeWorkflow(action.workflowId);
                }

                break;
            case Constants.ActionTypes.WORKFLOW_REJECT:
                if (action.workflowId) {
                    rejectWorkflow(action.workflowId);
                }

                break;
            case Constants.ActionTypes.WORKFLOW_FETCH_BY_ID:
            case 'WORKFLOW_REACTIVATE_SUCCESS':
                if (action.workflow) {
                    setCurrentWorkflow(action.workflow);
                }

                break;
            case 'WORKFLOW_REACTIVATE_FAILURE':
                console.log(action.error);
                break;
            case Constants.ActionTypes.WORKFLOW_TAGGED:
                if (action.workflow) {
                    tagUpdated(action.workflow.id);
                }

                break;
            case Constants.ActionTypes.WORKFLOW_UNTAGGED:
                if (action.workflow) {
                    tagUpdated(action.workflow.id);
                }

                break;
            case Constants.ActionTypes.WORKFLOW_INSTANTIATED:
                if (action.workflow) {
                    instantiateWorkflow(action.workflow);
                }

                break;
            case Constants.ActionTypes.WORKFLOW_INSTANTIATE_FAILED:
                if (action.error) {
                    instantiateFailed(action.error);
                }

                break;
            case Constants.ActionTypes.WORKFLOW_PROTOTYPE_PUBLISHED:
                if (action.workflow) {
                    updateWorkflowPrototype(action.workflow);
                }

                break;
            case Constants.ActionTypes.WORKFLOW_PROTOTYPE_UNPUBLISHED:
                if (action.workflow) {
                    updateWorkflowPrototype(action.workflow);
                }

                break;
            case Constants.ActionTypes.WORKFLOW_PROTOTYPE_LOADED:
                if (action.workflow) {
                    setCurrentWorkflowPrototype(action.workflow);
                    WorkflowStore.emit(action.type, action.workflow);
                }

                break;
            case Constants.ActionTypes.WORKFLOW_PROTOTYPE_NOT_FOUND:
                if (action.error) {
                    WorkflowStore.emitWorkflowPrototypeNotFound(action.error);
                }

                break;
            case Constants.ActionTypes.WORKFLOW_PROTOTYPE_CREATED:
            case Constants.ActionTypes.WORKFLOW_PROTOTYPE_UPDATED:
                if (action.workflow) {
                    setCurrentWorkflowPrototype(action.workflow);
                    WorkflowStore.emit(action.type, action.workflow);
                }

                break;
            case 'UPDATE_FORM_ATTACHMENTS':
                updateAttachments(action.attachments);
                break;
            case 'REMOVE_FORM_ATTACHMENT':
                removeAttachment(action.index);
                break;
            case 'WORKFLOW_FORM_SAVED':
            case 'WORKFLOW_FORM_SAVE_ERROR':
                WorkflowStore.emit(action.type);

                return;
            case 'FORMATTED_DATA_LOADED':
                updateFormattedData(action.data);
                break;
            default:
                return;
        }

        WorkflowStore.emitChange(action.type);
    }),
});

export default WorkflowStore;
