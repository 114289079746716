import { createComponent } from '@lit/react';
import { Header } from '@penneo/vega-ui';
import React from 'react';

const UIModalHeader = createComponent({
    tagName: 'vega-modal-header',
    elementClass: Header,
    react: React,
});

export default UIModalHeader;
