import React from 'react';
import { Checkbox } from '../../../../Common/components';
import { i18n } from '../../../../Language';
import { SigningMethod } from '../../../../EID';
import Message from '../../../../Common/components/Message';

type Props = {
    method: SigningMethod;
    checked: boolean;
    onChange: (newValue: boolean) => void;
    usedForAccessControl: boolean;
    usedForSigning: boolean;
    disabled?: boolean;
    message?: JSX.Element;
};

export default class MethodCheckbox extends React.Component<Props> {
    public render() {
        const {
            method,
            checked,
            onChange,
            usedForAccessControl: access,
            usedForSigning: signing,
            disabled,
            message,
        } = this.props;

        const { title, tooltip, description } = method.configurationInfo ?? {};

        const hasInfo = !!title && !!tooltip;

        return (
            <div className="method">
                <div className="method-setting" key={method.credentialType()}>
                    <Checkbox
                        onChange={onChange}
                        disabled={disabled === true}
                        label={this.getLabel(method)}
                        checked={checked}
                    />
                    {access && (
                        <div className="method-type">{i18n`Access`}</div>
                    )}
                    {signing && (
                        <div className="method-type">{i18n`Signing`}</div>
                    )}
                    {signing || (
                        <div className="method-type spacer">{i18n`Signing`}</div>
                    )}
                </div>
                {description && (
                    <span className="pl-[65px] text-neutral-600">
                        {i18n(description)}
                    </span>
                )}
                {checked && message}
                {checked && hasInfo && !message && (
                    <Message type="info">
                        <div>
                            <strong>{i18n(title)}</strong>
                            <br />
                            {(typeof tooltip === 'string' && i18n(tooltip)) ??
                                tooltip}
                        </div>
                    </Message>
                )}
            </div>
        );
    }

    private getLabel(method: SigningMethod) {
        const logo = method.configurationInfo?.logo;

        return (
            <span className="method-label">
                {logo && (
                    <img
                        className={`logo logo-${method.credentialType()}`}
                        alt={`${method.title} logo`}
                        src={logo}
                    />
                )}
                {i18n(method.title)}
            </span>
        );
    }
}
