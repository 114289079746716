import { Dispatcher, debug } from 'Core';
import { PublicSignerAPI, SigningAPI } from 'Api';
import lodash from 'lodash';
import BrandingAPI from 'Branding/utils/BrandingAPI';
import CustomerUtils from 'Auth/utils/customer';

const CustomerActionCreators = {
    fetchUserCustomers(userId) {
        return SigningAPI.get(`/users/${userId}/customers`).then(
            (customers) => {
                Dispatcher.handleServerAction({
                    type: 'CUSTOMERS_FETCH_SUCCESS',
                    customers: customers,
                });

                return customers;
            }
        );
    },

    fetchCustomers() {
        return SigningAPI.get(`/customers`).then((customers) => {
            Dispatcher.handleServerAction({
                type: 'CUSTOMERS_FETCH_SUCCESS',
                customers: customers,
            });

            return customers;
        });
    },

    updateCustomer(customer) {
        Dispatcher.handleServerAction({
            type: 'CUSTOMER_UPDATED',
            customer: customer,
        });
    },

    resetCustomerChanges(customerId) {
        Dispatcher.handleServerAction({
            type: 'CUSTOMER_RESET_CHANGES',
            customerId,
        });
    },

    persistCustomer(customerId, customerData) {
        Dispatcher.handleServerAction({
            type: 'CUSTOMER_UPDATE_REQUEST',
        });

        // Remove properties
        let payload = lodash.omit(customerData, [
            // Read only
            'id',
            'sdkClassName',

            // Store properties
            'branding',
        ]);

        if (typeof payload.status !== 'undefined') {
            payload.status = CustomerUtils.parseCustomerStatus(payload.status);
        }

        SigningAPI.put(`/v2/customers/${customerId}`, payload)
            .then((data) => {
                data.allowedSigningMethods = CustomerUtils.fixAllowedSigningMethods(
                    data.allowedSigningMethods
                );
                Dispatcher.handleServerAction({
                    type: 'CUSTOMER_UPDATE_SUCCESS',
                    customer: data,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: 'CUSTOMER_UPDATE_ERROR',
                    error: error,
                });
            });
    },

    resetError() {
        Dispatcher.handleViewAction({
            type: 'CUSTOMER_RESET_ERROR',
        });
    },

    fetchCustomer(customerId) {
        Dispatcher.handleServerAction({
            type: 'CUSTOMER_FETCH_REQUEST',
        });

        Promise.all([
            SigningAPI.get(`/v2/customers/${customerId}`),
            BrandingAPI.getBranding(customerId),
        ])
            .then((response) => {
                let [customer, branding] = response;

                // Add branding property to customer object.
                customer.branding = branding;
                customer.allowedSigningMethods = CustomerUtils.fixAllowedSigningMethods(
                    customer.allowedSigningMethods
                );

                Dispatcher.handleServerAction({
                    type: 'CUSTOMER_FETCH_SUCCESS',
                    customer: customer,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: 'CUSTOMER_FETCH_FAILURE',
                    error: error,
                });
            });
    },

    fetchCustomerUsers(customerId) {
        SigningAPI.get(`/customers/${customerId}/users`).then((users) => {
            Dispatcher.handleServerAction({
                type: 'CUSTOMER_USERS_FETCH_SUCCESS',
                customerId: customerId,
                users: users || [],
            });
        });
    },

    fetchTemporalStorageDefault: async (challengeKey) => {
        try {
            const response = await PublicSignerAPI.get(
                `/customer/preferences/${challengeKey}`
            );

            Dispatcher.handleServerAction({
                type: 'CUSTOMER_STORAGE_DEFAULT_FETCH_SUCCESS',
                temporalStorageDefault: response.temporalStorageDefault,
                signerArchiveEnabled: response.signerArchiveEnabled,
            });
        } catch (e) {
            Dispatcher.handleServerAction({
                type: 'CUSTOMER_STORAGE_DEFAULT_FETCH_FAILURE',
                temporalStorageDefault: false,
                signerArchiveEnabled: true,
            });

            debug.log(e);
        }
    },
};

export default CustomerActionCreators;
