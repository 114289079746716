import { useEffect, useRef, EffectCallback, DependencyList } from 'react';

/**
 * Instead this custom hook use: https://github.com/streamich/react-use/blob/master/docs/useUpdateEffect.md once project is updated to React 18
 * @param effect Callback function to run on updates
 * @param dependencies Array of dependencies that trigger the effect
 */
const useUpdateEffect = (
    effect: EffectCallback,
    dependencies?: DependencyList
) => {
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;

            return;
        }

        return effect();
    }, dependencies);
};

export default useUpdateEffect;
