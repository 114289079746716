import PropTypes from 'prop-types';
import React from 'react';
import FormStore from '../../stores/FormStore';
import DocumentField from './DocumentField.jsx';

export default class DocumentPreview extends React.Component {
    static propTypes = {
        fields: PropTypes.array,
        getCoords: PropTypes.func,
        index: PropTypes.number,
        dimensions: PropTypes.object,
        mapping: PropTypes.array,
        doc: PropTypes.array,
        editMode: PropTypes.bool,
        updateField: PropTypes.func,
        highlightedField: PropTypes.number,
        zoom: PropTypes.number,
        highlightField: PropTypes.func,
        removeHighlight: PropTypes.func,
        disableEdit: PropTypes.bool,
    };

    renderField = (fieldMap, index) => {
        let { dimensions } = this.props;
        let pageDimensions = dimensions[fieldMap.page];

        if (!pageDimensions) {
            return false;
        }

        if (this.props.editMode) {
            return this.renderEditorField(fieldMap, index, pageDimensions);
        }

        return this.renderMergedField(fieldMap, index, pageDimensions);
    };

    renderMergedField = (fieldMap, index, pageDimensions) => {
        let { zoom, updateField, disableEdit, highlightedField } = this.props;
        let { highlightField, removeHighlight } = this.props;

        return (
            <DocumentField
                key={index}
                zoom={zoom}
                dimensions={pageDimensions}
                mapping={fieldMap}
                field={FormStore.getFieldById(fieldMap.fieldId)}
                highlightField={highlightField}
                removeHighlight={removeHighlight}
                highlightedField={highlightedField}
                index={index}
                updateField={updateField}
                disableEdit={disableEdit}
            />
        );
    };

    render() {
        let { mapping, fields, dimensions, getCoords, index } = this.props;

        return (
            <div
                className="page-field-overlay"
                data-page={index}
                onClick={getCoords}>
                {fields.length > 0 && dimensions[index + 1]
                    ? mapping.map((fieldMap, mappingIndex) =>
                          // Only render fields that are mapped to current page
                          fieldMap.page === index + 1
                              ? this.renderField(fieldMap, mappingIndex)
                              : // else, render nothing
                                false
                      )
                    : // Don't render mappings if there are no fields
                      false}
            </div>
        );
    }
}
