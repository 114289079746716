import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client, ClientLinks } from '../types';
import { ReduxState } from 'Store';

export type ClientsState = {
    loading: boolean;
    error: Error | null;
    lastSavedClient: Client | null;
    clientList: Client[];
    totalClients: number;
    currentClient: Client | null;
    lastDeletedClient: string | null;
    currentLinks: ClientLinks | null;
    newClientCreation: string | null;
};

export const initialState: ClientsState = {
    loading: false,
    error: null,
    lastSavedClient: null,
    clientList: [],
    totalClients: 0,
    currentClient: null,
    lastDeletedClient: null,
    currentLinks: null,
    newClientCreation: null,
};

export const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        setLoadingStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        setLoadingEnd: (state) => {
            state.loading = false;
        },
        setError: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
        },
        resetLastSavedClient: (state) => {
            state.lastSavedClient = null;
        },
        setLastSavedClient: (state, action: PayloadAction<Client>) => {
            state.lastSavedClient = action.payload;
        },
        fetchedClients: (
            state,
            action: PayloadAction<{ clients: Client[]; total: number }>
        ) => {
            const { clients, total } = action.payload;

            state.clientList = clients;
            state.totalClients = total;
        },
        setCurrentClient: (state, action: PayloadAction<Client>) => {
            state.currentClient = action.payload;
        },
        resetCurrentClient: (state) => {
            state.currentClient = null;
        },
        setLastDeletedClient: (state, action: PayloadAction<string>) => {
            state.lastDeletedClient = action.payload;
        },
        resetLastDeletedClient: (state) => {
            state.lastDeletedClient = null;
        },
        setCurrentLinks: (state, action: PayloadAction<ClientLinks>) => {
            state.currentLinks = action.payload;
        },
        resetCurrentLinks: (state) => {
            state.currentLinks = null;
        },
        setNewClientCreation: (state, action: PayloadAction<string>) => {
            state.newClientCreation = action.payload;
        },
        resetNewClientCreation: (state) => {
            state.newClientCreation = null;
        },
    },
});

export const {
    setLoadingStart,
    setLoadingEnd,
    setError,
    resetLastSavedClient,
    setLastSavedClient,
    fetchedClients,
    setCurrentClient,
    resetCurrentClient,
    setLastDeletedClient,
    resetLastDeletedClient,
    setCurrentLinks,
    resetCurrentLinks,
    setNewClientCreation,
    resetNewClientCreation,
} = clientsSlice.actions;

export const clientsState = (state: ReduxState) => state.clients;

export default clientsSlice.reducer;
