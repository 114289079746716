import PropTypes from 'prop-types';
import React from 'react';
import SignerRoleRow from './SignerRoleRow';
import SignerActions from '../../actions/SignerActionCreators';

export default class SignerModal extends React.Component {
    static propTypes = {
        index: PropTypes.number,
        check: PropTypes.func,
        rules: PropTypes.object,
        availableRoles: PropTypes.array,
        selectedRoles: PropTypes.array,
        refreshModal: PropTypes.func,
    };

    getSelectedRole = (role) => {
        let { selectedRoles } = this.props;

        return selectedRoles.filter((r) => r.id === role.id)[0];
    };

    /**
     * Roles can have a custom name per signer. This returns the custom name if set.
     * @return {[type]} [description]
     */
    getRoleTitle = (role) => {
        let selectedRole = this.getSelectedRole(role);

        if (!selectedRole) {
            return role.role;
        }

        let { name } = selectedRole;

        if (name || name === '') {
            return selectedRole.name;
        }

        return role.role;
    };

    getRoleStatus = (role) => {
        let selectedRole = this.getSelectedRole(role);

        if (selectedRole) {
            return selectedRole.enabled;
        }

        return false;
    };

    updateSignerRole = (role) => {
        let { index } = this.props;

        SignerActions.updateSignerRole(index, role);

        this.props.refreshModal();
    };

    changeRoleTitle = (role, name) => {
        let payload = {
            id: role.id,
            name: name,
        };

        this.updateSignerRole(payload);
    };

    toggleSignerRole = (role, enabled) => {
        let payload = {
            id: role.id,
            enabled: enabled,
        };

        this.updateSignerRole(payload);
    };

    render() {
        let { availableRoles, index } = this.props;

        return (
            <div>
                <ul className="signer-role-modal">
                    {availableRoles.map((role, key) => (
                        <li key={key}>
                            <SignerRoleRow
                                id={role.id}
                                title={this.getRoleTitle(role)}
                                toggle={this.toggleSignerRole.bind(null, role)}
                                changeTitle={this.changeRoleTitle.bind(
                                    null,
                                    role
                                )}
                                enabled={this.getRoleStatus(role)}
                                index={index}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
