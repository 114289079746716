/* eslint-disable */
'use strict';

Object.defineProperty(exports, '__esModule', {
    value: true,
});
let locale = {
    placeholder: 'Select time',
};
exports['default'] = locale;
module.exports = exports['default'];
