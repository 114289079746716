import keyMirror from 'keymirror';

const constants = {
    // Event name triggered from store, listened to by views
    CHANGE_EVENT: 'change',
    // Action Sources
    ActionSources: keyMirror({
        SERVER_ACTION: null,
        VIEW_ACTION: null,
    }),
};

export default constants;
