import PropTypes from 'prop-types';
import React from 'react';

export default class WidgetFooter extends React.Component {
    static propTypes = {
        children: PropTypes.node,
    };

    render() {
        return <div className="widget-footer">{this.props.children}</div>;
    }
}
