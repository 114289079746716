import React, { useEffect, useRef, useState } from 'react';
import { i18n } from 'Language';
import { CaseFileFromStore } from '../types';
import { CaseFileType } from 'types/CaseFile';
import { SimpleFolderEntity } from 'types/Folder';
import { Language } from 'Language/types';
import Tooltip from 'Common/components/Tooltip';
import classNames from 'classnames';

type Props = {
    casefile: CaseFileFromStore;
    casefileType: CaseFileType;
    folder: SimpleFolderEntity;
    language: Language;
    handleShowFlowDetails: () => void;
};

const CasefileRoundsInfo = ({
    casefile,
    casefileType,
    folder,
    language,
    handleShowFlowDetails,
}: Props) => {
    const tooltipTrigger = useRef<HTMLElement | null>(null);
    const [tooltipShow, setTooltipShow] = useState<boolean>(false);

    useEffect(() => {
        if (tooltipTrigger.current) {
            setTooltipShow(
                tooltipTrigger.current.scrollWidth >
                    tooltipTrigger.current.offsetWidth
            );
        }
    }, [tooltipTrigger]);

    return (
        <>
            <h4 className="casefile-details-section-header mt-0">
                {i18n`Details`}
            </h4>

            <div className="casefile-info-section">
                <div>
                    <label>{i18n`Title`}</label>
                    {casefile.title || (
                        <span className="text-warning">
                            <i className="fas fa-exclamation-triangle fa-lg" />
                            &nbsp;{i18n`You must add a title`}
                        </span>
                    )}
                </div>

                <div>
                    <label>{i18n`Folder`}</label>
                    <div>
                        <i className="far fa-folder fa-lg" />
                        &nbsp;
                        {folder ? folder.title : '--'}
                    </div>
                </div>

                <div>
                    <label>{i18n`Sensitive data protection`}</label>
                    <div>
                        {casefile.sensitiveData ? (
                            <span>
                                <i className="far fa-lock-alt fa-lg text-secondary-600" />
                                &nbsp;
                                {i18n`Enabled`}
                            </span>
                        ) : (
                            <span>
                                <i className="far fa-lock-open-alt fa-lg text-secondary-600" />
                                &nbsp;
                                {i18n`Disabled`}
                            </span>
                        )}
                    </div>
                </div>

                <div>
                    <label>{i18n`Signing flow`}</label>

                    <div className="flex">
                        <Tooltip
                            className={classNames('max-w-[calc(100%-2rem)]', {
                                'no-show': !tooltipShow,
                            })}
                            showArrow={true}
                            text={
                                <span className="inline-block w-[300px]">
                                    {casefileType.name}
                                </span>
                            }>
                            <span
                                ref={tooltipTrigger}
                                className="w-full inline-block truncate underline font-bold text-secondary-600 cursor-pointer"
                                onClick={handleShowFlowDetails}>
                                {casefileType.name}
                            </span>
                        </Tooltip>

                        <i
                            className="far fa-arrow-right fa-lg text-secondary-600 cursor-pointer ml-auto mr-0"
                            onClick={handleShowFlowDetails}
                        />
                    </div>
                </div>

                <div>
                    <label>{i18n`Language`}</label>
                    <div>
                        <img width="16" height="11" src={language.flag} />
                        &nbsp;
                        {language.name}
                    </div>
                </div>

                <div>
                    <label style={{ marginTop: 0 }}>
                        {i18n`Document visibility`}
                    </label>
                    <div>
                        {casefile.visibilityMode === 0 ? (
                            <span>
                                <i className="far fa-eye fa-lg text-secondary-600" />
                                &nbsp;
                                {i18n`Recipients can see all documents when signing`}
                            </span>
                        ) : (
                            <span>
                                <i className="far fa-eye-slash fa-lg text-secondary-600" />
                                &nbsp;
                                {i18n`Recipients can only see documents they have to sign`}
                            </span>
                        )}
                    </div>
                </div>

                {casefile.reference && (
                    <div>
                        <label>{i18n`Reference`}</label>
                        {casefile.reference}
                    </div>
                )}
            </div>
        </>
    );
};

export default CasefileRoundsInfo;
