import React from 'react';
import { i18n } from 'Language';
import EIDWidget from 'Auth/components/Common/EIDWidget';
import { isMethodAvailableToLogIn, LOGIN_METHODS } from 'EID';
import { Intent } from 'OpenID/Constants';

type Props = {
    credentials: {
        allowed: string[];
    };
};

const AuthMethodsOptions = (props: Props) => {
    const methods = LOGIN_METHODS.filter((method) =>
        isMethodAvailableToLogIn(method)
    );

    return (
        <div>
            <div className="react-modal-header-title">
                {i18n('Choose an electronic identity provider')}
            </div>

            <EIDWidget
                intent={Intent.ADD}
                methods={methods}
                allowed={props.credentials.allowed}
            />
        </div>
    );
};

export default AuthMethodsOptions;
