import React, {
    MouseEvent,
    ChangeEvent,
    ClipboardEvent,
    FocusEvent,
    KeyboardEvent,
} from 'react';
import { i18n, LanguageDropdown } from 'Language';
import { Checkbox } from 'Common/components';
import Button from 'Common/components/Button';
import { AUTH_METHODS, AuthMethod } from 'Constants';
import { LOGIN_METHODS, LoginMethod, isMethodAvailableToLogIn } from 'EID';

import {
    UserSettings,
    UserSettingKeys,
    ManagedSettings,
} from 'Settings/redux/types';
import {
    UserEntity as User,
    PrivateUserEntity as PrivateUser,
    UserRights,
    UserRoles,
    Credentials,
    isNotPrivateUserEntity,
} from 'types/User';
import { UserSettingsHandler } from './ManageUserDetails';
import classnames from 'classnames';
import {
    changeEmailRequest,
    completeChangeEmailRequest,
    Countdown,
} from './utils';
import { notify } from 'react-notify-toast';

import './user-details-form.scss';
import { uniqBy } from 'lodash';
import { CustomerSigningMethod } from 'types/Customer';
import { isMethodAvailableToConfigureForLogin } from 'EID';
import { connect } from 'react-redux';
import { ReduxState } from 'Store';

type ChangeHandler<T> = (event: React.ChangeEvent<T>) => void;

export type Props = {
    user: User | PrivateUser;
    userSettings: UserSettings;
    rights: UserRights[];
    credentials: Credentials;
    isAccountManager: boolean;
    isCurrentUser: boolean;
    currentUser: any;
    status: string;
    dirty: boolean;
    usersCanStoreContacts: boolean;
    managedSettings: ManagedSettings;
    handleInputChange: ChangeHandler<HTMLInputElement>;
    handleRightsChange: ChangeHandler<HTMLInputElement>;
    handleCredentialsChange: ChangeHandler<HTMLInputElement>;
    handleRoleChange: ChangeHandler<HTMLSelectElement>;
    handleUserSettingsChange: UserSettingsHandler;
    saveUserDetails: (event: React.FormEvent<HTMLFormElement>) => void;
};

type State = {
    email: string;
    changingEmail: boolean;
    confirmingEmail: boolean;
    code: string[];
};

export class UserDetailsForm extends React.Component<Props, State> {
    state: State = {
        email: this.props.user.email,
        changingEmail: false,
        confirmingEmail: false,
        code: ['', '', '', ''],
    };

    isApiUser() {
        const { user } = this.props;

        return user.role === UserRoles.API_SUPER_USER;
    }

    renderLoginMethods() {
        const { credentials, handleCredentialsChange } = this.props;

        const isApiUser = this.isApiUser();

        const filteredEIDMethods = LOGIN_METHODS.filter((method) =>
            isMethodAvailableToLogIn(method)
        ).filter((method) =>
            isMethodAvailableToConfigureForLogin(
                method,
                this.props.managedSettings
            )
        );

        const methods = uniqBy(
            (AUTH_METHODS as (AuthMethod | LoginMethod)[]).concat(
                filteredEIDMethods
            ),
            (method) => method.credentialType()
        )
            // TODO: Filter out SMS with a proper filter https://linear.app/penneo/issue/IDT-456
            .filter((method) => method.type !== CustomerSigningMethod.SMS);

        return (
            <>
                <label className="mt">{i18n('Login methods')}</label>
                <div className="checkbox-group" data-testid="login-methods">
                    {methods.map((method) => (
                        <label
                            className="custom-checkbox"
                            key={method.credentialType()}>
                            <input
                                type="checkbox"
                                name={method.credentialType()}
                                checked={
                                    credentials.allowed.indexOf(
                                        method.credentialType()
                                    ) !== -1
                                }
                                onChange={handleCredentialsChange}
                                disabled={isApiUser}
                            />
                            <span className="check">
                                <i className="fas fa-check" />
                            </span>
                            <span>{i18n(method.title)}</span>
                        </label>
                    ))}
                </div>
            </>
        );
    }

    renderUserRights() {
        const { currentUser, rights, handleRightsChange } = this.props;
        const isApiUser = this.isApiUser();

        return (
            <>
                <label>{i18n('Rights')}</label>
                <div className="checkbox-group">
                    <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            name="send"
                            checked={rights.indexOf(UserRights.SEND) !== -1}
                            onChange={handleRightsChange}
                            disabled={isApiUser}
                        />
                        <span className="check">
                            <i className="fas fa-check" />
                        </span>
                        <span>{i18n('Send documents')}</span>
                    </label>

                    <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            name="kyc"
                            checked={rights.indexOf(UserRights.KYC) !== -1}
                            onChange={handleRightsChange}
                            disabled={isApiUser}
                        />
                        <span className="check">
                            <i className="fas fa-check" />
                        </span>
                        <span>{i18n('Identity validation')}</span>
                    </label>

                    {currentUser.admin && (
                        <label className="custom-checkbox">
                            <input
                                type="checkbox"
                                name="mass-send"
                                checked={
                                    rights.indexOf(UserRights.FORMS) !== -1
                                }
                                onChange={handleRightsChange}
                                disabled={isApiUser}
                            />
                            <span className="check">
                                <i className="fas fa-check" />
                            </span>
                            <span>{i18n('Forms')}</span>
                        </label>
                    )}
                </div>
            </>
        );
    }

    renderUserSettings() {
        const {
            userSettings,
            handleUserSettingsChange,
            usersCanStoreContacts,
        } = this.props;

        const { storeNewContactsByDefault } = UserSettingKeys;
        const { active: isStoreNewContactsActive } = userSettings[
            storeNewContactsByDefault
        ];

        return usersCanStoreContacts ? (
            <div className="manage-user-settings">
                <label>{i18n('Custom settings')}</label>
                <Checkbox
                    checked={isStoreNewContactsActive}
                    name={storeNewContactsByDefault}
                    label={i18n`Store contacts by default`}
                    onChange={(checked) => {
                        handleUserSettingsChange(storeNewContactsByDefault, {
                            active: !!checked,
                        });
                    }}
                />
            </div>
        ) : null;
    }

    handleUnlockEmail(event: MouseEvent) {
        event.preventDefault();
        this.setState({ changingEmail: true });
    }

    handleLockEmail(event: MouseEvent) {
        event.preventDefault();
        this.confirmEmailReset();
        this.resetEmail();
    }

    async handleConfirmSendEmail(event: MouseEvent) {
        event.preventDefault();

        try {
            await changeEmailRequest(this.state.email, this.props.user.id);

            this.setState({ confirmingEmail: true });
        } catch (error) {
            notify.show(
                i18n(`We couldn't process your request. Please, try again.`),
                'error',
                5000
            );
        }
    }

    async handleConfirmEmail(event: MouseEvent) {
        event.preventDefault();

        try {
            await completeChangeEmailRequest(
                this.state.code.join(''),
                this.props.user.id
            );

            this.confirmEmailReset({
                message: 'The user details were updated successfully',
                type: 'success',
            });
        } catch (error) {
            notify.show(
                i18n(
                    `We couldn't process your request. Please make sure your code is correct and try again.`
                ),
                'error',
                5000
            );
        }
    }

    resetEmail = () =>
        this.setState({
            email: this.props.user.email,
        });

    confirmEmailReset(notification?: { message: string; type: string }) {
        this.setState({
            changingEmail: false,
            confirmingEmail: false,
            code: ['', '', '', ''],
        });

        if (notification) {
            notify.show(i18n(notification.message), notification.type, 5000);
        }
    }

    handleConfirmEmailCancel(event: MouseEvent) {
        event.preventDefault();
        this.confirmEmailReset();
        this.resetEmail();
    }

    codeId = (index: number) => `code-${index}`;

    handleEmailCodeInputFocus = (event: FocusEvent<HTMLInputElement>) =>
        event.target.select();

    handleEmailCodeInputKey(
        event: KeyboardEvent<HTMLInputElement>,
        index: number
    ) {
        const { code } = this.state;
        const valid = /^[\w]$/.test(event.key);

        if (valid && index < code.length - 1) {
            document.getElementById(this.codeId(index + 1))?.focus();
        }
    }

    handleEmailCodeDeleteKey(
        event: KeyboardEvent<HTMLInputElement>,
        index: number
    ) {
        const { code } = this.state;

        if (
            (event.key === 'Delete' || event.key === 'Backspace') &&
            index > 0 &&
            code[index] === ''
        ) {
            document.getElementById(this.codeId(index - 1))?.focus();
        }
    }

    handleEmailCodeInput(event: ChangeEvent<HTMLInputElement>, index: number) {
        const { code } = this.state;
        const newCode = [...code];

        newCode[index] = event.target.value.toUpperCase();
        this.setState({ code: newCode });
    }

    handleEmailCodePaste(event: ClipboardEvent) {
        event.preventDefault();

        const { code } = this.state;
        const content = event.clipboardData.getData('Text');
        let split: string[] = [];

        if (content.length) {
            split = content.split('').slice(0, code.length);

            for (let i = 0; i < code.length; i++) {
                /**
                 * we need to check that the new code will have the correct length
                 * if an element is missing, we add an empty string
                 */
                if (!split[i]) {
                    split[i] = '';
                } else {
                    // if the element is present, we convert it to uppercase
                    split[i] = split[i].toUpperCase();
                }
            }

            this.setState({ code: split });
        }
    }

    handleNewEmailChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({ email: event.target.value });
    }

    render() {
        const {
            user,
            currentUser,
            isAccountManager,
            isCurrentUser,
            status,
            dirty,
            handleRoleChange,
            handleInputChange,
            saveUserDetails,
        } = this.props;
        const { email, changingEmail, confirmingEmail, code } = this.state;

        const isApiUser = this.isApiUser();

        const hasAccessToAdminControls = isAccountManager || currentUser.admin;

        const showUserSettings = isCurrentUser && isNotPrivateUserEntity(user);

        return (
            <form className="form" onSubmit={saveUserDetails}>
                <div>
                    <label htmlFor="name">{i18n('Name')}</label>
                    {isCurrentUser || isAccountManager || currentUser.admin ? (
                        <input
                            id="name"
                            name="fullName"
                            onChange={handleInputChange}
                            type="text"
                            value={user.fullName || ''}
                            disabled={isApiUser}
                        />
                    ) : (
                        <input
                            id="name"
                            name="fullName"
                            readOnly={true}
                            type="text"
                            value={user.fullName || ''}
                            disabled={isApiUser}
                        />
                    )}
                    <label htmlFor="email">{i18n('Email address')}</label>

                    <div>
                        <div
                            className={classnames('email-edit', {
                                'changing-email': changingEmail,
                                'confirming-email': confirmingEmail,
                            })}>
                            <input
                                id="email"
                                name="email"
                                type="text"
                                value={email || ''}
                                onChange={(e) => this.handleNewEmailChange(e)}
                                disabled={!changingEmail}
                                readOnly={confirmingEmail}
                            />
                            {changingEmail && (
                                <Button
                                    theme="blue"
                                    variant="outline"
                                    data-testid="confirm-email"
                                    onClick={(e) =>
                                        this.handleConfirmSendEmail(e)
                                    }>
                                    {i18n`Confirm email`}
                                </Button>
                            )}
                        </div>

                        {confirmingEmail ? (
                            <>
                                <p className="email-confirmation-label">
                                    {i18n`Write the code we sent to ${email}`}
                                </p>
                                <div className="email-confirmation changing-email">
                                    <div
                                        className="email-codes"
                                        onPaste={(e) =>
                                            this.handleEmailCodePaste(e)
                                        }>
                                        {code.map((value, i: number) => {
                                            const id = this.codeId(i);

                                            return (
                                                <input
                                                    key={id}
                                                    id={id}
                                                    name={id}
                                                    className="email-code"
                                                    type="text"
                                                    maxLength={1}
                                                    value={value}
                                                    onFocus={
                                                        this
                                                            .handleEmailCodeInputFocus
                                                    }
                                                    onKeyDown={(e) =>
                                                        this.handleEmailCodeDeleteKey(
                                                            e,
                                                            i
                                                        )
                                                    }
                                                    onKeyUp={(e) =>
                                                        this.handleEmailCodeInputKey(
                                                            e,
                                                            i
                                                        )
                                                    }
                                                    onChange={(e) =>
                                                        this.handleEmailCodeInput(
                                                            e,
                                                            i
                                                        )
                                                    }
                                                />
                                            );
                                        })}
                                    </div>
                                    <div className="email-confirmation-ctas">
                                        <Button
                                            theme="blue"
                                            disabled={
                                                code.join('').length !== 4
                                            }
                                            data-testid="confirm-code"
                                            onClick={(e) =>
                                                this.handleConfirmEmail(e)
                                            }>
                                            {i18n`Confirm`}
                                        </Button>
                                        <Button
                                            theme="blue"
                                            variant="text"
                                            onClick={(e) =>
                                                this.handleConfirmEmailCancel(e)
                                            }>
                                            {i18n`Cancel`}
                                        </Button>
                                    </div>
                                </div>
                                <p className="email-confirmation-counter">
                                    <Countdown
                                        minutesToCount={15}
                                        callbackOnEnd={() =>
                                            this.confirmEmailReset({
                                                message: `Your confirmation code expired. Please, try again.`,
                                                type: 'warning',
                                            })
                                        }
                                    />{' '}
                                    {i18n`until code expires`}
                                </p>
                            </>
                        ) : (
                            (isCurrentUser || currentUser.admin) && (
                                <p>
                                    {!changingEmail ? (
                                        <Button
                                            theme="blue"
                                            variant="text"
                                            icon="far fa-lock"
                                            renderIconLeft
                                            data-testid="change-email"
                                            onClick={(e) =>
                                                this.handleUnlockEmail(e)
                                            }>
                                            {i18n`Change email address`}
                                        </Button>
                                    ) : (
                                        <Button
                                            theme="blue"
                                            variant="text"
                                            icon="far fa-lock-open"
                                            renderIconLeft
                                            onClick={(e) =>
                                                this.handleLockEmail(e)
                                            }>
                                            {i18n`Write new email address`}
                                        </Button>
                                    )}
                                </p>
                            )
                        )}
                    </div>
                </div>

                {isCurrentUser && (
                    <div>
                        {/*
                        @todo: enable when social security patch is supported in action creator
                        <label>{i18n("Social Security Number")}</label>
                        <input type="text" name="ssn" value={user.ssn}
                            placeholder={i18n("Social Security Number")}/>
                        */}

                        <label>{i18n('Language')}</label>
                        <LanguageDropdown
                            type="standalone"
                            name="language"
                            persist={false}
                            value={user.language}
                            skipInitCallback={true}
                            callback={(_, event) => handleInputChange(event)}
                        />
                    </div>
                )}

                {hasAccessToAdminControls && (
                    <>
                        <label htmlFor="role">{i18n('Role')}</label>
                        <select
                            id="role"
                            name="role"
                            value={user.role}
                            onChange={handleRoleChange}
                            disabled={isApiUser}>
                            <option value="user">{i18n('User')}</option>
                            <option value="administrator">
                                {i18n('Administrator')}
                            </option>
                            <option value="api_super_user" disabled>
                                {i18n('API user')}
                            </option>
                        </select>
                    </>
                )}

                {showUserSettings && this.renderUserSettings()}

                {hasAccessToAdminControls && (
                    <>
                        {this.renderUserRights()}
                        {this.renderLoginMethods()}
                    </>
                )}

                <br />

                <div className="pull-right-flex">
                    <Button
                        theme="blue"
                        disabled={status === 'request' || !dirty}>
                        {status !== 'request'
                            ? i18n('Update user details')
                            : i18n('Saving')}
                    </Button>
                </div>
            </form>
        );
    }
}

export default connect((state: ReduxState) => ({
    managedSettings: state.settings.data.managed,
}))(UserDetailsForm);
