import { debug } from 'Core';
import { sentry } from 'Common/SentryLogger';

let historyActions: string[] = [];

const pushToHistoryActions = (action: any) => {
    if (action && action.type) {
        if (historyActions.length < 20) {
            historyActions.push(action.type);
        } else {
            historyActions = [...historyActions.slice(0, 19), action.type];
        }
    }
};

const crashReporter = (store) => (next) => (action) => {
    pushToHistoryActions(action);

    try {
        return next(action);
    } catch (err) {
        debug.error(err);
        debug.log(`Action ${JSON.stringify(action)}`);

        sentry.track(err, {
            actions: historyActions,
            state: store.getState(),
        });

        throw err;
    }
};

export default crashReporter;
