import createReducer from 'create-reducer';

const CHANGE_ARCHIVE_LIMIT = 'CHANGE_ARCHIVE_LIMIT';
const PREFERENCES_REPLACE_STATE = 'PREFERENCES_REPLACE_STATE';

export type Preferences = {
    archive: {
        limit: number;
    };
};

export const initialState: Preferences = {
    archive: {
        limit:
            (window.sessionStorage?.getItem('archive-limit') &&
                JSON.parse(
                    window.sessionStorage?.getItem('archive-limit') as string
                )) ??
            10,
    },
};

const preferencesReducer = createReducer<Preferences>(initialState, {
    [PREFERENCES_REPLACE_STATE]: (state, newState) => ({
        ...state,
        ...newState,
    }),
    [CHANGE_ARCHIVE_LIMIT]: (state, limit) => ({
        ...state,
        archive: {
            ...state.archive,
            limit,
        },
    }),
});

export default preferencesReducer;

export const preferencesReplaceStateAction = (state?: Preferences) => ({
    type: PREFERENCES_REPLACE_STATE,
    payload: state,
});

export const changeArchiveLimitAction = (limit: number) => {
    window.sessionStorage.setItem('archive-limit', JSON.stringify(limit));

    return {
        type: CHANGE_ARCHIVE_LIMIT,
        payload: limit,
    };
};
