import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';

export default class Widget extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node,
        size: PropTypes.string,
    };

    render() {
        const { className, size } = this.props;

        return (
            <div className={classnames('widget', className, size)}>
                {this.props.children}
            </div>
        );
    }
}
