import { isFirefox, isIOS } from 'Common/utils/browser';

export const checkPDFSupport = () => {
    if (process.env.JEST_WORKER_ID) {
        //TODO:
        // Currently PDFJS for canvas cannot use our mock-canvas lib, why? I dont know
        // We have to investigate this separately!
        return ((window as unknown) as any).pdfbypass ?? false;
    }

    /**
     * Latest versions of IOS give a "false" positive
     * on native PDF support. In our tests, iOS devices
     * only render the first page of a PDF document.
     * Hence, if it's an IOS device,
     * we force using PDFjs.
     */
    if (isIOS()) {
        return false;
    }

    /**
     * EDGE CASE:
     * Although Firefox Android supposedly supports PDF
     * QA tests revealed that it breaks, specially with base64 embeds.
     * So we will not use native PDF embeds there, at all
     */
    const isFirefoxAndroid = isFirefox(true);

    if (isFirefoxAndroid) {
        return false;
    }

    /**
     * navigator.plugins is deprecated (officially)
     * Modern browsers have a new way of checking for PDF support
     * (Read more: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/pdfViewerEnabled)
     * We'll use this method as the primary one, and default to the old one
     * in older or incompatible browsers (mobile)
     */
    // @ts-ignore
    const pdfSupport = navigator?.pdfViewerEnabled;

    if (pdfSupport != null) {
        return pdfSupport;
    }

    /**
     * Check if legacy plugins object exists.
     * If not, assume there's no support for PDF
     */
    if (!navigator?.plugins) {
        return false;
    }

    const getNavigatorPlugin = (name: string) => {
        for (const key in navigator.plugins) {
            const plugin = navigator.plugins[key];

            if (plugin.name === name) {
                return true;
            }
        }

        return false;
    };

    return (
        getNavigatorPlugin('Adobe Acrobat') ||
        getNavigatorPlugin('PDF Viewer') ||
        getNavigatorPlugin('Chrome PDF Viewer') ||
        getNavigatorPlugin('WebKit built-in PDF')
    );
};
