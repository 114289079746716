import React from 'react';
import classnames from 'classnames';

import WhiteLogo from 'resources/images/eid/itsme_assets/itsme_logo_small_white.png';
import TangerineLogo from 'resources/images/eid/itsme_assets/itsme_logo_small_tangerine.png';
import './itsme-button.scss';

export type Props = {
    /**
     * Determines the color of the button
     *  - Defaults to orange
     */
    theme?: 'white' | 'tangerine' | 'black';
    /**
     * Determines the size of the button
     *  - If `undefined`. It renders the button with the default size
     */
    size?: 'small' | 'large';
    /**
     * Match the parent's width. (100% width)
     */
    fullWidth?: boolean;
} & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>;

export type State = {
    isSending: boolean;
    success: boolean | null;
    error: any | null;
};

const ItsmeButton: React.FC<Props> = (props) => {
    const {
        theme,
        size,
        fullWidth,
        className,
        children,
        ...jsxProps // Forward all the standard JSX props to the element
    } = props;

    const classes = classnames(
        'button',
        'itsme-button',
        `itsme-button-${theme}`,
        className,
        {
            'button-small': size === 'small',
            'button-large': size === 'large',
            'button-full-width': fullWidth,
        }
    );

    return (
        <button className={classes} {...jsxProps}>
            <img
                height="26"
                className="itsme-button-icon"
                src={theme === 'tangerine' ? WhiteLogo : TangerineLogo}
                alt="Itsme Logo"
            />
            <span className="itsme-button-text">{children}</span>
        </button>
    );
};

ItsmeButton.defaultProps = {
    theme: 'tangerine',
    disabled: false,
};

export default ItsmeButton;
