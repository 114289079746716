import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActivationDates, RolesAndActivation } from '../types';
import { ReduxState } from 'Store';

export type RoundsState = {
    activationDates: ActivationDates;
    dateErrors: boolean[];
    rolesAndActivation: RolesAndActivation[];
};

export const initialState: RoundsState = {
    activationDates: {},
    dateErrors: [],
    rolesAndActivation: [],
};

export const roundsSlice = createSlice({
    name: 'rounds',
    initialState,
    reducers: {
        resetRounds: () => initialState,
        setActivationDates: (
            state,
            action: PayloadAction<{
                round: number;
                date: ActivationDates[keyof ActivationDates];
            }>
        ) => {
            const { round, date } = action.payload;
            const newDates = {
                ...state.activationDates,
                ...{
                    [`round-${round}`]: date,
                },
            };

            state.activationDates = newDates;
        },
        setDateErrors: (state, action: PayloadAction<boolean[]>) => {
            state.dateErrors = action.payload;
        },
        setRolesAndActivations: (
            state,
            action: PayloadAction<RolesAndActivation[]>
        ) => {
            state.rolesAndActivation = action.payload;
        },
    },
});

export const {
    resetRounds,
    setActivationDates,
    setDateErrors,
    setRolesAndActivations,
} = roundsSlice.actions;

export const roundsState = (state: ReduxState) => state.rounds;

export default roundsSlice.reducer;
